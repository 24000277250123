import { useEffect, useState } from 'react';
import { Card, CircularProgress } from '@mui/material';
import { Check } from '@mui/icons-material';
import MDButton from 'components/MDButton';
import { SideMenu } from 'features/common/components';
import { ToastType, notice, useBoolean } from '../../common';
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import { CompanyWaitingListTable, TemplatesWrapper } from '../components';
import { CompanyInfoCard } from '../components';
import { CompanyResponse } from '../services';
import { useCompanyList } from '../hooks';

export const CompanyWaitingListTemplate = (): JSX.Element => {
  const [isActivating, setIsActivating] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [selectedCompany, setSelectedCompany] = useState<CompanyResponse>(null);

  const [isCompanyInfoModalOpen, openCompanyInfoModal, closeCompanyInfoModal] = useBoolean(false);

  const {
    getCompanyById,
    patchCompanyByIdActive,
    companiesList,
    companiesTotal,
    isLoading,
    entriesPerPage,
    currentPage,
    onChangeEntriesPerPageHandler,
    onDeleteItem,
    getItemsHandler,
    onChangePageHandler,
  } = useCompanyList();

  const onApproveCompany = async () => {
    try {
      const nextPage = onDeleteItem();
      setIsActivating(true);
      await patchCompanyByIdActive(selectedCompanyId, true);
      await getItemsHandler({ page: nextPage });
      setIsActivating(false);
      closeCompanyInfoModal();
      notice(ToastType.SUCCESS, 'Company successfully approved!');
    } catch (error) {
      setIsActivating(false);
      notice(ToastType.ERROR, 'Something went wrong with approving company');
    }
  };

  const connectSelectedCompany = async () => {
    setSelectedCompany(null);
    try {
      if (selectedCompanyId) {
        const company = await getCompanyById(selectedCompanyId);
        setSelectedCompany(company.data);
      }
    } catch (error) {
      notice(ToastType.ERROR, 'Something went wrong with getting additional company info.');
    }
  };

  useEffect(() => {
    connectSelectedCompany();
  }, [selectedCompanyId]);

  return (
    <TemplatesWrapper>
      <Card>
        <MDBox p={3} lineHeight={1} pb={0}>
          <MDTypography variant="h5" fontWeight="medium">
            Waiting for approval
          </MDTypography>
          <MDTypography variant="button" color="text">
            Here you can see current unupproved companies.
          </MDTypography>
        </MDBox>
        <CompanyWaitingListTable
          companiesList={companiesList}
          totalItems={companiesTotal}
          onChangePage={onChangePageHandler}
          onChangeEntriesPerPage={onChangeEntriesPerPageHandler}
          isLoading={isLoading}
          entriesPerPage={entriesPerPage}
          currentPage={currentPage}
          onMoreCompanyInfo={id => {
            setSelectedCompanyId(id);
            openCompanyInfoModal();
          }}
        />
      </Card>

      <SideMenu
        isOpen={isCompanyInfoModalOpen}
        onClose={closeCompanyInfoModal}
        title="Company info"
        titleDescription="More info about company is here."
        customWidth={500}
      >
        <MDBox py={4} display="flex" flexDirection="column" height={1} justifyContent="center">
          {selectedCompany === null ? (
            <CircularProgress sx={{ mx: 'auto', mb: '16px' }} />
          ) : (
            <CompanyInfoCard
              info={{
                fullName: selectedCompany?.fullName,
                shortName: selectedCompany?.shortName,
                owner: `${selectedCompany?.owner?.userProfile?.firstName} ${selectedCompany?.owner?.userProfile?.lastName}`,
                phone: selectedCompany?.phone,
                email: selectedCompany?.email,
                site: selectedCompany?.site,
                description: selectedCompany?.description,
                zip: selectedCompany?.headquarterAddress.zipCode,
                country: selectedCompany?.headquarterAddress?.country?.name,
                state: selectedCompany?.headquarterAddress?.state?.name,
                town: selectedCompany?.headquarterAddress.town,
              }}
            />
          )}
          {selectedCompany && (
            <MDButton
              variant="outlined"
              color="success"
              startIcon={isActivating ? null : <Check />}
              onClick={onApproveCompany}
              sx={{ marginTop: 4 }}
              isLoading={isActivating}
              disabled={isActivating}
            >
              Activate
            </MDButton>
          )}
        </MDBox>
      </SideMenu>
    </TemplatesWrapper>
  );
};
