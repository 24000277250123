import { FC, useEffect } from 'react';
import { Autocomplete, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import { BaseFormField, ToastType, notice, useClioEnums } from 'features/common';
import { PracticeAreaCategory } from 'features/clio-settings/ClioSettingsApiService';
import { ClioCaseLawType, clioSettingsApiService } from 'features/settings/ClioSettingsApiService';
import { SelectClioLawTypesFormValues, selectClioLawTypesValidationFormSchema } from './form.config';

type LawTypeMenuContentProps = {
  selectedLawTypeId: string;
  selectedLawTypeTitle: string;
  matchedClioLawTypes: PracticeAreaCategory[];
  onCloseMenuHandler: () => void;
  setClioCaseLawTypes: (value: React.SetStateAction<ClioCaseLawType[]>) => void;
};

export const LawTypeMenuContent: FC<LawTypeMenuContentProps> = ({
  selectedLawTypeTitle,
  selectedLawTypeId,
  matchedClioLawTypes,
  onCloseMenuHandler,
  setClioCaseLawTypes,
}) => {
  const { updateClioCaseLawType, getClioCaseLawTypes } = clioSettingsApiService();

  const {
    onGetClioMappingPracticeAreaCategoriesEnums,
    clioMappingPracticeAreaCategoriesEnum: { data, isLoading },
  } = useClioEnums();

  useEffect(() => {
    onGetClioMappingPracticeAreaCategoriesEnums();
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting, isDirty },
  } = useForm<SelectClioLawTypesFormValues>({
    mode: 'onChange',
    resolver: yupResolver(selectClioLawTypesValidationFormSchema),
    defaultValues: { clioCaseLawTypes: matchedClioLawTypes },
  });

  const isSubmitButtonDisabled = !isValid || !isDirty || isSubmitting;

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      const practiceAreaCategories = formData.clioCaseLawTypes.map(el => el.id);
      await updateClioCaseLawType({ caseLawType: selectedLawTypeId, practiceAreaCategories });
      const { data } = await getClioCaseLawTypes();
      setClioCaseLawTypes(data['hydra:member']);
      onCloseMenuHandler();
    } catch (error) {
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  });

  return (
    <Stack height={1} spacing={1.5} my={2}>
      <MDTypography variant="body2">{`Select Clio law type which matched to ${selectedLawTypeTitle}`}</MDTypography>

      <MDBox component="form" onSubmit={onFormSubmitHandler} sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Stack
          spacing={1}
          sx={{
            height: 1,
          }}
        >
          <Controller
            control={control}
            name="clioCaseLawTypes"
            render={({ field: { value, onChange } }) => {
              const onChangeHandler = async (
                _: React.SyntheticEvent<Element, Event>,
                options: PracticeAreaCategory[]
              ) => onChange(options);
              return (
                <Autocomplete
                  value={value}
                  getOptionLabel={option => option?.value}
                  options={data}
                  loading={isLoading}
                  isOptionEqualToValue={(option, value) => option?.['@id'] === value?.['@id']}
                  onChange={onChangeHandler}
                  multiple
                  sx={{ width: 1, flex: 1 }}
                  freeSolo={false}
                  disableClearable
                  renderInput={params => (
                    <BaseFormField
                      formInputProps={{
                        ...params,
                        label: 'Clio case law type',
                        fullWidth: true,
                      }}
                      errorValue={errors?.clioCaseLawTypes?.message}
                    />
                  )}
                />
              );
            }}
          />
        </Stack>

        <MDButton
          variant="gradient"
          color="info"
          type="submit"
          fullWidth
          disabled={isSubmitButtonDisabled}
          isLoading={isSubmitting}
        >
          Save
        </MDButton>
      </MDBox>
    </Stack>
  );
};
