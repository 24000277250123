import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { CompanyResponse } from '../services';

export type CompanyInfoCardProps = {
  title?: string;
  description?: string;
  info: Partial<
    Omit<
      CompanyResponse,
      | 'logo'
      | 'rating'
      | 'caseLawTypes'
      | 'workingHours'
      | 'userPermissions'
      | 'headquarterAddress'
      | 'active'
      | 'owner'
    > & {
      country: string;
      state: string;
      town: string;
      zip: string;
      owner: string;
    }
  >;
  shadow?: boolean;
  action?: { route: string; tooltip: string };
};

export const CompanyInfoCard: React.FC<CompanyInfoCardProps> = ({
  title,
  description,
  info,
  action,
  shadow = false,
}) => {
  const labels: string[] = [];
  const values: string[] = [];

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach(el => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find(i => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach(el => values.push(el));

  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" sx={{ flex: 1 }}>
        {label}: &nbsp;
      </MDTypography>
      <MDTypography
        variant="button"
        fontWeight="regular"
        color="text"
        sx={{
          flex: 3,
          whiteSpace: 'initial',
          maxWidth: 300,
        }}
      >
        {values[key]}
      </MDTypography>
    </MDBox>
  ));

  return (
    <Card sx={{ height: '100%', boxShadow: !shadow && 'none' }}>
      <MDBox>{renderItems}</MDBox>
    </Card>
  );
};
