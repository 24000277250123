import { FC } from 'react';
import { Grid } from '@mui/material';
import { MailSettings } from '../Components';

export const MailSettingsTemplate: FC = () => {
  return (
    <Grid container spacing={2}>
      <MailSettings />
    </Grid>
  );
};
