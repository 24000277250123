import { backendApiClient, BaseData, ListOfItems } from '..';

const BASE_FILE_SIGN_API_URL = '/file_sign';

export type FileSignProvider = 'docu_sign' | 'adobe_sign';

export type FileSignTemplate = BaseData<'IntegrationLegalCaseDto'> & {
  id: string;
  name: string;
};

export type FileSignTemplateFieldType = 'text' | 'list' | 'numerical' | 'boolean';

export type FileSignListItem = BaseData<'KeyValueDto'> & {
  id: string;
  value: string;
};

export type FileSignTemplateField = BaseData<'DocuSignTemplateCustomFieldDto'> & {
  id: string;
  list: null | FileSignListItem[];
  name: string;
  required: boolean;
  type: FileSignTemplateFieldType;
  value?: string | null;
  label: string;
  autofill: boolean;
  documentNumber: string;
};

export type DocusignAuthURL = BaseData<'DocuSignAuthDto'> & {
  authUrl: string;
};

export type TemplateToSignFieldValue = {
  id: string;
  value: string;
  documentNumber: string;
};

export type SendToSignEnvelopeTemplatePayload = {
  templateId?: string;
  redirectUrl?: string;
  participant: string;
  object?: string;
  generateSignUrl?: boolean;
  fields?: TemplateToSignFieldValue[];
  file?: string;
};

export type SignFileTempaltePayload = {
  file: string;
};

export type SignFileTemplateResponse = BaseData<''> & {
  signUrl: string;
  id: string;
};

export const fileSignApiService = () => {
  const getSignProviderTemplates = async (provider: FileSignProvider) =>
    backendApiClient({}).get<ListOfItems<FileSignTemplate[]>>(`${BASE_FILE_SIGN_API_URL}/${provider}/templates`);

  const getSignProviderTemplateFields = async (provider: FileSignProvider, templateId: string) =>
    backendApiClient({}).get<ListOfItems<FileSignTemplateField[]>>(
      `${BASE_FILE_SIGN_API_URL}/${provider}/templates/${templateId}/fields`
    );

  const sendEnvelopeFileToSign = async (provider: FileSignProvider, payload: SendToSignEnvelopeTemplatePayload) =>
    backendApiClient({}).post(`${BASE_FILE_SIGN_API_URL}/${provider}/envelope/sent_to_sign`, payload, {
      headers: { 'Content-Type': 'application/ld+json' },
    });

  const signEnvelope = async (payload: SignFileTempaltePayload) =>
    backendApiClient({}).post<SignFileTemplateResponse>(`${BASE_FILE_SIGN_API_URL}/envelope/create_view`, payload);

  const cancelSignEnvelopeRequest = async (file: string) =>
    backendApiClient({}).post(`${BASE_FILE_SIGN_API_URL}/envelope/cancellation_sign`, {
      file,
    });

  return {
    getSignProviderTemplates,
    getSignProviderTemplateFields,
    sendEnvelopeFileToSign,
    signEnvelope,
    cancelSignEnvelopeRequest,
  };
};
