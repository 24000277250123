import { FC, useState } from 'react';
import { Card, CardContent, Stack } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { BaseAvatar } from 'features/common/components/BaseAvatar';
import { CompanyInvitationItemProps } from './types';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { useBoolean } from 'features/common';

export const CompanyInvitationItem: FC<CompanyInvitationItemProps> = ({
  invitorFirstName,
  invitorLastName,
  invitorLogoURL,
  companyFullName,
  lastElementRef,
  offerId,
  approveOfferHandler,
  declineOfferHandler,
}) => {
  const [isApproving, setIsApproving] = useState(false);
  const [isRejection, setIsRejection] = useState(false);
  const [isAcceptInvitationDialogOpen, openAcceptInvitationDialog, closeAcceptInvitationDialog] = useBoolean(false);
  const [isDeclineInvitationDialogOpen, openDeclineInvitationDialog, closeDeclineInvitationDialog] = useBoolean(false);

  const onAcceptOfferHandler = async () => {
    setIsApproving(true);
    closeAcceptInvitationDialog();
    await approveOfferHandler(offerId);
    setIsApproving(false);
  };

  const onDeclineOfferHandler = async () => {
    setIsRejection(true);
    closeDeclineInvitationDialog();
    await declineOfferHandler(offerId);
    setIsRejection(false);
  };

  return (
    <Card
      sx={{
        height: 1,
        boxShadow: theme => theme.boxShadows.md,
        border: theme => `1px solid ${theme.borders.borderColor}`,
      }}
      ref={lastElementRef}
    >
      <CardContent sx={{ p: 2, pb: '16px !important', height: 1 }}>
        <Stack spacing={2} height={1}>
          <Stack spacing={0.5}>
            <Stack direction="row" spacing={1.5}>
              <BaseAvatar src={invitorLogoURL} userFirstName={invitorFirstName} userLastName={invitorLastName} />
              <Stack>
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  sx={{ whiteSpace: 'initial', wordBreak: 'break-all' }}
                >
                  {`${invitorFirstName} ${invitorLastName}`}
                </MDTypography>

                <MDTypography
                  variant="button"
                  sx={{
                    fontWeight: 400,
                    textAlign: 'center',
                    flex: 1,
                    whiteSpace: 'initial',
                  }}
                >
                  {`Accept the offer to join ${companyFullName} company?`}
                </MDTypography>
              </Stack>
            </Stack>
          </Stack>

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ justifyContent: 'center', width: 1 }}
            spacing={{ xs: 1.5, sm: 3, md: 1.5 }}
          >
            <MDButton
              variant="outlined"
              color="info"
              isLoading={isApproving}
              onClick={openAcceptInvitationDialog}
              sx={{
                width: 1,
                minHeight: 35,
                textTransform: 'none',
              }}
            >
              Accept
            </MDButton>
            <MDButton
              variant="outlined"
              color="dark"
              isLoading={isRejection}
              onClick={openDeclineInvitationDialog}
              sx={{
                width: 1,
                minHeight: 35,
                textTransform: 'none',
              }}
            >
              Decline
            </MDButton>
          </Stack>
        </Stack>
      </CardContent>

      <DialogWindow
        onCloseDialogHandler={closeAcceptInvitationDialog}
        onApproveDialogHandler={onAcceptOfferHandler}
        isDialogOpen={isAcceptInvitationDialogOpen}
        dialogTitle="Are you sure want to accept the invitation?"
      />

      <DialogWindow
        onCloseDialogHandler={closeDeclineInvitationDialog}
        onApproveDialogHandler={onDeclineOfferHandler}
        isDialogOpen={isDeclineInvitationDialogOpen}
        dialogTitle="Are you sure want to decline the invitation?"
      />
    </Card>
  );
};
