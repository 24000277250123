import { useCallback, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Stack, alpha } from '@mui/material';
import { Link } from 'react-router-dom';
import * as process from 'process';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import { createBackendAuthService } from 'features/auth';
import { ROUTES } from 'features/common/variables/routes';
import { ToastType, adobesignApiService, notice, useBoolean, useUser } from 'features/common';
import { docusignApiService } from 'features/common/services/docusignApiService';
import ClioLogo from 'assets/images/icons/logo-clio.svg';
import DocusignLogo from 'assets/images/icons/logo-docusign.png';
import AdobesignLogo from 'assets/images/icons/adobe-sign-logo.svg';
import { FileSignProvider } from 'features/common/services/fileSignApiService';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { IntegrationItem } from './IntegrationItem';

const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL;

const dialogTitlesMap: Record<FileSignProvider, string> = {
  docu_sign: 'Are you sure you want to disconnect your Docusign account?',
  adobe_sign: 'Are you sure you want to disconnect your Adobe Sign account?',
};

export const Integrations = (): JSX.Element => {
  const { isAdobeSignActive, isClioActive, isDocuSignActive, backendUser, connectBackendUserHandler } = useUser();
  const { disconnectDocusignFromProfile } = createBackendAuthService();
  const { generateDocusignAuthURL } = docusignApiService();
  const { disconnectAdobeSign, generateAdobeSignAuthURL } = adobesignApiService();

  const [isDialogOpen, onOpenDialog, onCloseDialog] = useBoolean(false);
  const [isLoading, setIsLoading] = useState(false);
  const [provider, setProvider] = useState<null | FileSignProvider>(null);

  const onDisconnectDocusingHandler = async () => {
    try {
      setIsLoading(true);
      await disconnectDocusignFromProfile();
      await connectBackendUserHandler();
      onCloseDialog();
      setIsLoading(false);
      notice(ToastType.SUCCESS, 'Successfully disconnected DocuSign from your account');
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  };

  const onDisconnectAdobeSignHandler = async () => {
    try {
      setIsLoading(true);
      await disconnectAdobeSign();
      await connectBackendUserHandler();
      onCloseDialog();
      setIsLoading(false);
      notice(ToastType.SUCCESS, 'Successfully disconnected AdobeSign from your account');
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  };

  const disconnectHandlersMap: Record<FileSignProvider, () => Promise<void>> = {
    docu_sign: onDisconnectDocusingHandler,
    adobe_sign: onDisconnectAdobeSignHandler,
  };

  const onToggleDocusignHandler = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        if (isDocuSignActive) {
          setProvider(event.target.id as FileSignProvider);
          onOpenDialog();
          return;
        }
        const { data } = await generateDocusignAuthURL(`${BASE_APP_URL}/${ROUTES.docusignRedirect}`);
        window.location.href = data.authUrl || '';
      } catch (error) {
        console.error(error);
        notice(ToastType.ERROR, 'Something went wrong, please try again!');
      }
    },
    [isDocuSignActive]
  );

  const onToggleAdobeSignHandler = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        if (isAdobeSignActive) {
          setProvider(event.target.id as FileSignProvider);
          onOpenDialog();
          return;
        }
        const { data } = await generateAdobeSignAuthURL(`${BASE_APP_URL}/${ROUTES.adobeSignRedirect}`);

        window.location.href = data.authUrl || '';
      } catch (error) {
        console.error(error);
        notice(ToastType.ERROR, 'Something went wrong, please try again!');
      }
    },
    [isAdobeSignActive]
  );

  useEffect(() => {
    connectBackendUserHandler();
  }, []);

  return (
    <Card>
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Accounts</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          Here you can setup and manage your integration settings.
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={3} px={3}>
        <IntegrationItem
          logoURL={DocusignLogo}
          title="DocuSign"
          description="Make your business faster, simpler and more cost-efficient with electronic agreements. Agree with
          confidence, with intuitive signing experiences across virtually any device."
          isChecked={isDocuSignActive}
          onToggleHandler={onToggleDocusignHandler}
          provider="docu_sign"
        />

        <Divider sx={{ my: '10px' }} />

        <IntegrationItem
          logoURL={AdobesignLogo}
          title="AdobeSign"
          description="Cloud-based electronic signature service that allows users to send, sign, track, and manage signature."
          isChecked={isAdobeSignActive}
          onToggleHandler={onToggleAdobeSignHandler}
          provider="adobe_sign"
          isDisabled={process.env.REACT_APP_ENVIRONMENT === 'production'}
          label={
            <Stack sx={{ p: 1, bgcolor: theme => alpha(theme.palette.warning.light, 0.6), borderRadius: 2 }}>
              <MDTypography variant="body2" fontSize={14}>
                <MDTypography variant="caption" fontWeight="medium" mr={0.5} fontSize={14}>
                  Beta.
                </MDTypography>
                AdobeSign Integration is in Beta. Status updates for signed files may be delayed.
              </MDTypography>
            </Stack>
          }
        />

        {backendUser?.roles.includes('ROLE_ATTORNEY') && (
          <>
            <Divider sx={{ my: '10px' }} />
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              flexDirection={{ xs: 'column', sm: 'row' }}
              component={Link}
              to={ROUTES.clioSettings}
              sx={{
                p: 1,
                borderRadius: '6px',
                ':hover': {
                  backgroundColor: ({ palette }) => palette.grey[200],
                },
              }}
            >
              <Stack spacing={2} width={1}>
                <Stack flexDirection="row">
                  <MDBox display="flex" alignItems="center" flex={1}>
                    <MDAvatar src={ClioLogo} variant="rounded" alt="Clio logo" sx={{ width: 30, height: 30 }} />
                    <MDBox ml={2} lineHeight={0}>
                      <MDTypography variant="h5" fontWeight="medium">
                        Clio
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <MDTypography
                      sx={{ ml: 1, fontWeight: '500' }}
                      variant="button"
                      color={isClioActive ? 'success' : 'error'}
                    >
                      {isClioActive ? 'Connected' : 'Not connected'}
                    </MDTypography>

                    <ChevronRightRoundedIcon fontSize="medium" />
                  </MDBox>
                </Stack>
                <MDTypography variant="button">
                  Build strong relationships with current and potential new clients with efficient and organized
                  processes. Make sure no client, communication, or opportunity to grow falls through the cracks with
                  powerful legal CRM software.
                </MDTypography>
              </Stack>
            </MDBox>
          </>
        )}
      </MDBox>

      <DialogWindow
        onCloseDialogHandler={onCloseDialog}
        onApproveDialogHandler={disconnectHandlersMap[provider]}
        isDialogOpen={isDialogOpen}
        dialogTitle={dialogTitlesMap[provider]}
        isLoading={isLoading}
      />
    </Card>
  );
};
