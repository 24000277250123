import { useAsync } from '../../common';
import { useEffect, useMemo, useState } from 'react';
import {
  ContentCategoryResponse,
  GetContentCategoriesRequest,
  createContentCategoryService,
} from '../services/contentCategoriesService';

type UseContentCategoriesProps = GetContentCategoriesRequest;

export const useContentCategories = ({ page = 1, itemsPerPage = 10 }: UseContentCategoriesProps) => {
  const { getContentCategories, patchContentCategoryById, deleteContentCategoryById, createContentCategory } =
    createContentCategoryService();

  const [contentCategoriesList, setContentCategoriesList] = useState<ContentCategoryResponse[]>([]);
  const [contentCategoriesTotal, setContentCategoriesTotal] = useState(0);
  const [contentCategoriesPage, setContentCategoriesPage] = useState(1);
  const [loadContentCategoriesState, loadContentCategoriesHandler] = useAsync(async () => {
    const {
      data: { ['hydra:member']: contentCategories, ['hydra:totalItems']: totalItems },
    } = await getContentCategories({ page: contentCategoriesPage, itemsPerPage });
    setContentCategoriesList(contentCategories);
    if (totalItems !== contentCategoriesTotal) {
      setContentCategoriesTotal(Number(totalItems));
    }

    return contentCategories;
  });

  const isLoading = loadContentCategoriesState.isPending();
  const contentCategoriesTotalPages = useMemo(
    () => Math.ceil(contentCategoriesTotal / itemsPerPage),
    [contentCategoriesTotal, itemsPerPage]
  );

  useEffect(() => {
    loadContentCategoriesHandler();
  }, [page, itemsPerPage, contentCategoriesPage]);

  return {
    loadContentCategoriesState,
    loadContentCategoriesHandler,
    contentCategoriesList,
    isLoading,
    contentCategoriesPage,
    contentCategoriesTotal,
    createContentCategory,
    setContentCategoriesPage,
    patchContentCategoryById,
    deleteContentCategoryById,
    contentCategoriesTotalPages,
  };
};
