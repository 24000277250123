import { GetWorkingHoursResponse } from 'features/common';

type DayKey = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

type GetWorkingHoursKeys = keyof GetWorkingHoursResponse;

export const getWorkingHours = <T extends GetWorkingHoursResponse>(workingHours: T) =>
  workingHours
    ? Object.keys(workingHours || {}).reduce((acc, key) => {
        const workingHoursKey = key as GetWorkingHoursKeys;
        if (workingHoursKey.endsWith('Enabled') && workingHours[workingHoursKey] === true) {
          const day = workingHoursKey.replace('Enabled', '') as DayKey;
          acc.push({ day, fromValue: workingHours[`${day}From`], toValue: workingHours[`${day}To`] });
        }
        return acc;
      }, [] as { day: string; fromValue: string; toValue: string }[])
    : [];
