import Card from '@mui/material/Card';
import { match } from 'ts-pattern';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { Loader } from 'features/common/components/Loader';
import { useNotificationsSettings } from '../hooks';
import { PatchUserSettingsPayload } from '../services/userSettingsService';
import { NotificationItem } from './NotificationItem';

type PlatformSettingsProps = {
  settingId: string;
};

type NotificationItem = {
  id: keyof PatchUserSettingsPayload;
  title: string;
  subtitle?: string;
};

const getNotificationItems = (isAttorney: boolean): NotificationItem[] => {
  const items: NotificationItem[] = [
    {
      id: 'allowChat',
      title: 'Allow chat',
    },
    {
      id: 'allowCallReminder',
      title: 'Allow call reminder',
      subtitle: `   ( reminder for scheduled call. )`,
    },
    {
      id: 'allowCaseStatus',
      title: 'Allow case status updates notifications',
      subtitle: `   ( when the case status changes. )`,
    },
    {
      id: 'allowCaseUpdates',
      title: 'Allow case update notifications',
      subtitle: `   ( any changes in the case, such as: description, notes, etc. )`,
    },
    {
      id: 'allowEmailNotification',
      title: 'Allow Emails',
    },
    {
      id: 'allowMissedCall',
      title: 'Allow missed call notifications',
      subtitle: ``,
    },
  ];

  return isAttorney ? [...items, { id: 'allowNewLead', title: 'Allow New Leads' }] : items;
};

export const PlatformSettings: React.FC<PlatformSettingsProps> = ({ settingId }): JSX.Element => {
  const { isAttorney, loadSettingsState, currentSettings, patchSettingsHandler } = useNotificationsSettings(settingId);

  const onSettingsChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const fieldName = event.currentTarget.id as keyof PatchUserSettingsPayload;
    patchSettingsHandler({ [fieldName]: checked });
  };

  const notificationItems = getNotificationItems(isAttorney);

  return (
    <Card sx={{ boxShadow: 'none', minHeight: 300 }}>
      <MDBox py={2} px={3}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Notification settings
        </MDTypography>
      </MDBox>
      {match(loadSettingsState.status)
        .with('pending', () => <Loader />)
        .with('successful', () => (
          <MDBox pt={1} pb={2} px={3} lineHeight={1.25}>
            {notificationItems?.map(el => (
              <NotificationItem
                key={el.id}
                id={el.id}
                title={el.title}
                subtitle={el?.subtitle}
                isChecked={currentSettings[el.id]}
                onChangehandler={onSettingsChangeHandler}
              />
            ))}
          </MDBox>
        ))
        .otherwise(() => null)}
    </Card>
  );
};
