import { FC } from 'react';
import { MenuItem } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import MDTypography from 'components/MDTypography';

type PopoverMenuItemProps = {
  title: string;
  onClickHandler?: (event?: React.MouseEvent<HTMLLIElement>) => void;
  titleVariant?: Variant;
  icon?: JSX.Element;
  isDisabled?: boolean;
  isActive?: boolean;
  itemId?: string;
};

export const PopoverMenuItem: FC<PopoverMenuItemProps> = ({
  title,
  icon,
  onClickHandler,
  titleVariant = 'subtitle2',
  isDisabled,
  itemId,
  isActive,
}) => {
  return (
    <MenuItem
      disabled={isDisabled}
      sx={{
        justifyContent: 'space-between',
        ...(isActive && {
          backgroundColor: 'grey.200',
        }),
      }}
      onClick={onClickHandler}
      id={itemId}
    >
      <MDTypography variant={titleVariant} sx={{ mr: '2px' }}>
        {title}
      </MDTypography>
      {icon && icon}
    </MenuItem>
  );
};
