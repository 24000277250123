import { useCallback, useState } from 'react';
import { SIGN_SERVICE_NOT_INTEGRATED_MESSAGE } from 'features/case-details/components/Documents/DocumentItem';
import {
  FileSignTemplate,
  SendToSignEnvelopeTemplatePayload,
  fileSignApiService,
} from '../services/fileSignApiService';
import { ToastType, notice } from '../modules';
import { paymentRequiredErrorHandler } from '../errorHanders';
import { useBoolean } from './useBoolean';
import { FilePermissionProvider } from '../services/filesService';
import { useRouter } from './useRouter';
import { useUser } from './useUser';
import { SIGN_PROVIDERS } from '../components/FileSignProviderDialogWindow';
import { useCookiesFileSignData } from './useCookiesFileSignData';

export type RequestToSignDocumentPayload = Pick<SendToSignEnvelopeTemplatePayload, 'fields' | 'templateId'> & {
  fileId?: string;
  userSignerFirebaseId: string;
  caseId?: string;
};

type FileSignData = {
  isSigned: boolean;
  isRequestedToSign: boolean;
  isNeedToSign: boolean;
  requestedToSignUserFirebaseId: string;
};

type SignFilePaylod = {
  fileId: string;
  isOpenSignTabWindow: boolean;
  pathName?: string;
};

type UseFileSignProps = {
  fileSignData?: FileSignData | null;
  fileSignProviders?: FilePermissionProvider[];
};

const getDefaultActiveSignProvider = ({
  isAdobeSignActive,
  isDocuSignActive,
  providers,
}: {
  providers?: FilePermissionProvider[];
  isDocuSignActive: boolean;
  isAdobeSignActive: boolean;
}): FilePermissionProvider => {
  if (providers?.length) return providers?.[0];

  if ((isDocuSignActive && isAdobeSignActive) || isDocuSignActive) return SIGN_PROVIDERS[0];

  if (isAdobeSignActive) return SIGN_PROVIDERS[1];

  return null;
};

export const useFileSign = ({ fileSignData, fileSignProviders }: UseFileSignProps) => {
  const { signEnvelope, cancelSignEnvelopeRequest, sendEnvelopeFileToSign } = fileSignApiService();

  const { isDocuSignActive, isAdobeSignActive } = useUser();

  const { setSignDataToCookiesHandler } = useCookiesFileSignData();

  const pathname = useRouter().pathname;

  const isFileSignServicesActive = isAdobeSignActive && isDocuSignActive;

  const isSomeFileSignServiceActive = isAdobeSignActive || isDocuSignActive;

  const isSeveralSignProviders = fileSignProviders?.length > 1 ? true : isFileSignServicesActive;
  const fileSignProvidersToMap = fileSignProviders?.length
    ? fileSignProviders
    : isFileSignServicesActive
    ? SIGN_PROVIDERS
    : [];

  const defaultFileSignProvider = getDefaultActiveSignProvider({
    providers: fileSignProviders,
    isAdobeSignActive,
    isDocuSignActive,
  });

  const [fileSignProvider, setFileSignProvider] = useState<FilePermissionProvider | null>(defaultFileSignProvider);

  const resetFileSignProvider = useCallback(() => {
    const provider = getDefaultActiveSignProvider({
      providers: fileSignProviders,
      isAdobeSignActive,
      isDocuSignActive,
    });
    setFileSignProvider(provider);
  }, [fileSignProviders, isAdobeSignActive, isDocuSignActive]);

  const [isSendingSignReq, setIsSendingSignReq] = useState(false);

  const [isDialogOpen, onOpenDialog, onCloseDialog] = useBoolean(false);

  //Sign document
  const onSignDocumentHandler = useCallback(
    async ({ fileId, isOpenSignTabWindow = false, pathName: redirectPathName = pathname }: SignFilePaylod) => {
      try {
        setIsSendingSignReq(true);

        const { data } = await signEnvelope({
          file: `/files/${fileId}`,
        });

        setSignDataToCookiesHandler({ fileId, path: redirectPathName });
        isOpenSignTabWindow ? window.open(data?.signUrl, '_blank') : (window.location.href = data?.signUrl);

        setIsSendingSignReq(false);
      } catch (error: any) {
        setIsSendingSignReq(false);
        console.error(error);
        if (error?.response?.status === 422) {
          notice(ToastType.ERROR, error?.response?.data?.['hydra:description']);
        } else paymentRequiredErrorHandler(error);
      }
    },
    [pathname]
  );

  //Send to sign
  const onSendRequestToSignDocumentHandler = useCallback(
    async ({ caseId, fileId, userSignerFirebaseId, fields, templateId }: RequestToSignDocumentPayload) => {
      if (!fileSignProvider) return;

      try {
        setIsSendingSignReq(true);
        await sendEnvelopeFileToSign(fileSignProvider?.provider, {
          ...(fileId && {
            file: `/files/${fileId}`,
          }),
          participant: `/users/${userSignerFirebaseId}`,
          ...(caseId && {
            object: `/legal_cases/${caseId}`,
          }),
          ...(templateId && {
            templateId,
          }),
          ...(fields?.length && {
            fields,
          }),
        });

        notice(ToastType.SUCCESS, 'Successfully sent request to sign the document!');
        setIsSendingSignReq(false);
      } catch (error: any) {
        setIsSendingSignReq(false);
        const errorStatus = error?.response?.status;
        if (errorStatus === 403 || errorStatus === 422) {
          const message: string = error?.response?.data?.['hydra:description'];

          if (errorStatus === 422 && message.includes('System was unable to convert this document')) {
            const messageToDisplay = message.split('.')?.[0];
            return notice(ToastType.ERROR, messageToDisplay);
          }

          notice(ToastType.ERROR, message);
        } else {
          paymentRequiredErrorHandler(error);
        }
        console.error(error);
      }
    },
    [fileSignProvider]
  );

  // Decline signing
  const onDeclineSignEnvelopeHandler = useCallback(async (fileId: string) => {
    try {
      setIsSendingSignReq(true);
      await cancelSignEnvelopeRequest(`/files/${fileId}`);
      setIsSendingSignReq(false);
      notice(ToastType.SUCCESS, 'Successfully canceled request to sign!');
    } catch (error) {
      setIsSendingSignReq(false);
      paymentRequiredErrorHandler(error);
      console.error(error);
    }
  }, []);

  const isCancelRequestToSign = !fileSignData?.isSigned && fileSignData?.isRequestedToSign;

  const signLabelData = fileSignData?.isSigned
    ? 'Signed'
    : fileSignData?.isNeedToSign
    ? 'Need to sign'
    : fileSignData?.isRequestedToSign
    ? 'Requested to sign'
    : '';

  const onChangeRadioButtonHandler = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
      const selectedSignProvider = fileSignProvidersToMap?.find(el => el.provider === value);
      setFileSignProvider(selectedSignProvider || null);
    },
    [fileSignProvidersToMap]
  );

  const [
    isNotConnectedFileSignServicesOpen,
    openNotConnectedFileSignServicesModal,
    closeNotConnectedFileSignServicesModal,
  ] = useBoolean(false);

  const [isRequestToSignDocumentsModalOpen, openRequestToSignDocumentsModalOpen, closeRequestToSignDocumentsModalOpen] =
    useBoolean(false);

  const [isSelectDocumentsFromCaseMenuOpen, openSelectDocumentsFromCaseMenu, closeSelectDocumentsFromCaseMenu] =
    useBoolean(false);

  const [isFillTemplateMenuOpen, openFillTemplateMenu, closeFillTemplateMenu] = useBoolean(false);

  const [isSelectTemplateDocumentModalOpen, openSelectTemplateDocumentModal, closeSelectTemplateDocumentModal] =
    useBoolean(false);

  const [selectedTemplate, setSelectedTemplate] = useState<null | FileSignTemplate>(null);

  const onClickSelectTemplateItemHandler = useCallback((template: FileSignTemplate) => {
    setSelectedTemplate(template);
    closeSelectTemplateDocumentModal();
    openFillTemplateMenu();
  }, []);

  const onOpenSelectTemplateDocumentModalHandler = useCallback(() => {
    if (!isSomeFileSignServiceActive) {
      notice(ToastType.WARNING, SIGN_SERVICE_NOT_INTEGRATED_MESSAGE);
      return;
    }

    openSelectTemplateDocumentModal();
    closeRequestToSignDocumentsModalOpen();
  }, [isSomeFileSignServiceActive]);

  return {
    isSeveralSignProviders,
    fileSignProvider,
    isDialogOpen,
    signLabelData,
    isCancelRequestToSign,
    isSendingSignReq,
    fileSignProvidersToMap,
    isFileSignServicesActive,
    resetFileSignProvider,
    onOpenDialog,
    onCloseDialog,
    isSomeFileSignServiceActive,
    onChangeRadioButtonHandler,
    onSendRequestToSignDocumentHandler,
    onDeclineSignEnvelopeHandler,
    onSignDocumentHandler,
    isSelectTemplateDocumentModalOpen,
    closeSelectTemplateDocumentModal,
    isFillTemplateMenuOpen,
    openFillTemplateMenu,
    closeFillTemplateMenu,
    selectedTemplate,
    onClickSelectTemplateItemHandler,
    isSelectDocumentsFromCaseMenuOpen,
    openRequestToSignDocumentsModalOpen,
    openSelectDocumentsFromCaseMenu,
    closeSelectDocumentsFromCaseMenu,
    isRequestToSignDocumentsModalOpen,
    closeRequestToSignDocumentsModalOpen,
    onOpenSelectTemplateDocumentModalHandler,
    isNotConnectedFileSignServicesOpen,
    openNotConnectedFileSignServicesModal,
    closeNotConnectedFileSignServicesModal,
    openSelectTemplateDocumentModal,
  };
};
