import { useEffect, useState } from 'react';
import { usePagination } from 'features/common/hooks/usePagination';
import { abortRequestErrorHandler } from 'features/common/errorHanders';
import { CaseLawTypeResponse, Pagination, createCaseLawTypesService } from '../services';

export const useSuggestions = () => {
  const { getCaseLawTypeSuggestions, patchCaseLawTypeSuggestions, deleteCaseLawTypeById } = createCaseLawTypesService();

  const [suggestionsList, setSuggestionsList] = useState<ReadonlyArray<CaseLawTypeResponse>>([]);
  const [suggestionsTotal, setSuggestionsTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { currentPage, entriesPerPage, setCurrentPage, onChangeEntriesPerPageHandler, onDeleteItem } = usePagination({
    itemsLength: suggestionsList?.length,
    totalItems: suggestionsTotal,
  });

  const getItemsHandler = async (params?: Pagination) => {
    try {
      const { data } = await getCaseLawTypeSuggestions({
        page: currentPage,
        itemsPerPage: entriesPerPage,
        ...params,
      });

      setSuggestionsList(data['hydra:member']);
      setSuggestionsTotal(Number(data['hydra:totalItems']));
    } catch (error) {
      abortRequestErrorHandler(error);
    }
  };

  const loadSuggestionsHandler = async (page: number = currentPage) => {
    setIsLoading(true);
    await getItemsHandler({ page });
    setIsLoading(false);
  };

  const onChangePageHandler = async (page: number) => {
    setCurrentPage(page);
    await loadSuggestionsHandler(page);
  };

  useEffect(() => {
    loadSuggestionsHandler();
  }, [entriesPerPage]);

  return {
    suggestionsTotal,
    currentPage,
    entriesPerPage,
    suggestionsList,
    isLoading,
    deleteCaseLawTypeById,
    setIsLoading,
    getItemsHandler,
    onDeleteItem,
    onChangePageHandler,
    onChangeEntriesPerPageHandler,
    loadSuggestionsHandler,
    patchCaseLawTypeSuggestions,
  };
};
