import { createContext, useContext, useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import { useSettings } from 'features/store';
import { ConfigMapAccummulator } from '../types';

type ConfigSettingsContextContextValue = {
  configMap: ConfigMapAccummulator;
};

const ConfigSettingsContext = createContext<ConfigSettingsContextContextValue>({
  configMap: {} as ConfigMapAccummulator,
});

export const ConfigSettingsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { settings } = useSettings(
    state => ({
      settings: state.settings,
    }),
    shallow
  );

  const configMap = useMemo(
    () =>
      settings?.reduce((acc: ConfigMapAccummulator, el) => {
        acc[el['id']] = el;
        return acc;
      }, {} as ConfigMapAccummulator),
    [settings]
  );

  const contextValue = useMemo(
    () => ({
      configMap,
    }),
    [configMap]
  );

  return <ConfigSettingsContext.Provider value={contextValue}>{children}</ConfigSettingsContext.Provider>;
};

export const useConfigSettingsContextState = (): ConfigSettingsContextContextValue => {
  const context = useContext(ConfigSettingsContext);

  if (context === undefined) {
    throw new Error('useConfigSettingsContextState was used outside of its Provider');
  }

  return context;
};
