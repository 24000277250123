import { FC, useCallback, useRef, useState } from 'react';
import { Card, CardContent, CardHeader, CircularProgress, ListItemIcon, MenuItem, Stack } from '@mui/material';
import { FileUpload, TextSnippetOutlined } from '@mui/icons-material';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { SideMenu, ToastType, notice, useBoolean, useUser } from 'features/common';
import { useDocument, useFileSign, useRouter } from 'features/common/hooks';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { CaseFile } from 'features/my-legal-cases/MyLegalCasesApiService';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';
import { SelectFileSignTemplateModal } from 'features/common/components/SelectFileSignTemplateModalWindow';
import { FillingTemplateSideMenu } from 'features/common/components/FillingTemplateSideMenuContent/FillingTemplateSideMenu';
import { usePopover } from 'features/common/hooks/usePopover';
import { MenuPopover } from 'features/common/components/MenuPopover';
import { ROUTES } from 'features/common/variables/routes';
import { FileSignProviderDialogWindow } from 'features/common/components/FileSignProviderDialogWindow';
import { AllDocumentsMenuContent } from './AllDocumentsMenuContent';
import { SIGN_SERVICE_NOT_INTEGRATED_MESSAGE, DocumentItem } from './DocumentItem';
import { CardAddButton } from '../CardAddButton';

type DocumentsProps = {
  documents: CaseFile[];
  isClosedCase: boolean;
  isCaseOnConsultation: boolean;
  isCaseForDependant: boolean;
  uploadDocumentHandler: (formData: FormData) => Promise<void>;
  deleteDocumentHandler: (documentId: string) => Promise<void>;
  userFirebaseId: string;
  caseId: string;
  isInvitationCase: boolean;
};

export const Documents: FC<DocumentsProps> = ({
  documents,
  isCaseForDependant,
  isCaseOnConsultation,
  isClosedCase,
  deleteDocumentHandler,
  uploadDocumentHandler,
  caseId,
  userFirebaseId,
  isInvitationCase,
}) => {
  const [errorMesasage, setErrorMessage] = useState('');
  const [isViewAllModalOpen, openViewAllModal, closeViewAllModal] = useBoolean(false);
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const [isAllDocumentsLoading, setIsAllDocumentsLoading] = useState(false);

  const { isDocuSignActive, isAdobeSignActive, isAttorney } = useUser();
  const {
    closeDeleteDialogHandler,
    documentId,
    isDeleteDialogOpen,
    onClickDeleteIconByIdHandler,
    createFormDataHandler,
    downloadButtonRef,
    onClickDownloadDocumentHandler,
  } = useDocument();

  const onInputFileUploadHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];

    if (Number(file.size) / 1000 ** 2 > 25) {
      setErrorMessage('The document size should be equal or less than 25 MB');
      return;
    }

    try {
      const documentFormData = createFormDataHandler(file);
      setIsDocumentLoading(true);

      await uploadDocumentHandler(documentFormData);

      setIsDocumentLoading(false);
      errorMesasage && setErrorMessage('');
      event.target.value = '';
    } catch (error: any) {
      setIsDocumentLoading(false);

      if (error?.response?.status === 422) {
        notice(ToastType.ERROR, error?.response?.data?.['hydra:description']);
      } else {
        paymentRequiredErrorHandler(error, 'Failed to upload document, try again!');
      }
      console.error(error);
    }
  };

  const onClickDeleteFileHandler = async () => {
    if (isViewAllModalOpen && documents?.length > 3) {
      const document = documents?.slice(0, 3).find(document => document.id === documentId);
      document && setIsDocumentLoading(true);
      setIsAllDocumentsLoading(true);
    } else {
      setIsDocumentLoading(true);
    }

    closeDeleteDialogHandler();
    await deleteDocumentHandler(documentId);

    setIsDocumentLoading(false);
    setIsAllDocumentsLoading(false);
    errorMesasage && setErrorMessage('');
  };

  const isViewAllButton = isCaseForDependant ? documents?.length > 6 : documents?.length > 3;

  const isAddDocumentsButtonDisabled = isAttorney && isCaseOnConsultation ? true : isDocumentLoading || isClosedCase;
  const isAllowToSendSignDocumentRequest =
    (isDocuSignActive || isAdobeSignActive) && !isCaseOnConsultation && !!userFirebaseId;

  const navigate = useRouter().push;

  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  const fileInputRef = useRef<null | HTMLInputElement>(null);

  const onClickUploadFileMenuItemHandler = () => {
    handleClosePopover();
    fileInputRef.current && fileInputRef.current.click();
  };

  const {
    isSeveralSignProviders,
    isDialogOpen,
    onCloseDialog,
    onOpenDialog,
    fileSignProvider,
    onChangeRadioButtonHandler,
    fileSignProvidersToMap,
    isSelectTemplateDocumentModalOpen,
    isFillTemplateMenuOpen,
    selectedTemplate,
    closeSelectTemplateDocumentModal,
    closeFillTemplateMenu,
    onClickSelectTemplateItemHandler,
    openSelectTemplateDocumentModal,
  } = useFileSign({
    fileSignData: null,
  });

  const onClickCreateDocFromTemplateMenuItemHandler = () => {
    if (!isAllowToSendSignDocumentRequest) {
      navigate(ROUTES.integrations).then(() => notice(ToastType.WARNING, SIGN_SERVICE_NOT_INTEGRATED_MESSAGE));
      handleClosePopover();
      return;
    }
    if (isSeveralSignProviders) {
      onOpenDialog();
      handleClosePopover();
      return;
    }

    handleClosePopover();
    openSelectTemplateDocumentModal();
  };

  const onChooseSignProviderHandler = useCallback(() => {
    openSelectTemplateDocumentModal();
  }, [fileInputRef]);

  return (
    <Card sx={{ width: 1, height: 1 }}>
      <CardHeader
        title="Documents"
        action={<CardAddButton onClickButtonHandler={isAddDocumentsButtonDisabled ? null : handleOpenPopover} />}
      />

      {isDocumentLoading ? (
        <Stack alignItems="center" flex={1} justifyContent="center" minHeight={150}>
          <CircularProgress />
        </Stack>
      ) : !documents?.length ? (
        <Stack flex={1} justifyContent="center" alignItems="center" minHeight={150}>
          <MDTypography sx={{ textAlign: 'center' }}>No documents were found...</MDTypography>
        </Stack>
      ) : (
        <>
          <CardContent sx={{ px: 2, pb: '8px !important', flex: 1 }}>
            <Stack
              sx={{
                maxHeight: isCaseForDependant ? 600 : 400,
                overflowY: 'auto',
              }}
            >
              {documents?.slice(0, isCaseForDependant ? 6 : 3)?.map(file => (
                <DocumentItem
                  key={file.id}
                  title={file?.title}
                  id={file.id}
                  fileSize={Number(file?.fileSize)}
                  ownerFirstName={file?.owner?.userProfile?.firstName}
                  ownerLastName={file?.owner?.userProfile?.lastName}
                  fileType={file?.type}
                  onClickDeleteIconHandler={onClickDeleteIconByIdHandler}
                  onClickDownloadDocumentHandler={onClickDownloadDocumentHandler}
                  downloadButtonRef={downloadButtonRef}
                  isClosedCase={isClosedCase}
                  filePermission={file?.filePermission}
                  userSignerFirebaseId={userFirebaseId}
                  caseId={caseId}
                />
              ))}
              {errorMesasage && (
                <MDTypography variant="body2" sx={{ color: theme => theme.palette.error.main, fontSize: '12px' }}>
                  {errorMesasage}
                </MDTypography>
              )}
            </Stack>
          </CardContent>
          {isViewAllButton && (
            <Stack alignItems="center" py={1} pb={1.5} px={2}>
              <MDButton
                variant="outlined"
                color="info"
                onClick={openViewAllModal}
                sx={{
                  width: { xs: 1, sm: 120 },
                  textTransform: 'none',
                }}
              >
                View all
              </MDButton>
            </Stack>
          )}
        </>
      )}
      <DialogWindow
        onCloseDialogHandler={closeDeleteDialogHandler}
        onApproveDialogHandler={onClickDeleteFileHandler}
        isDialogOpen={isDeleteDialogOpen}
        dialogTitle="Are you sure want to delete the document?"
      />
      <SideMenu isOpen={isViewAllModalOpen} onClose={closeViewAllModal} title="All documents" customWidth={450}>
        <AllDocumentsMenuContent
          documents={documents}
          onClickDeleteIconHandler={onClickDeleteIconByIdHandler}
          downloadButtonRef={downloadButtonRef}
          onClickDownloadDocumentHandler={onClickDownloadDocumentHandler}
          isAllDocumentsLoading={isAllDocumentsLoading}
          userSignerFirebaseId={userFirebaseId}
        />
      </SideMenu>

      <MenuPopover open={openPopover} onClose={handleClosePopover}>
        {!isInvitationCase && isAttorney && (
          <MenuItem sx={{ px: 1 }} onClick={onClickCreateDocFromTemplateMenuItemHandler}>
            <ListItemIcon>
              <TextSnippetOutlined fontSize="medium" />
            </ListItemIcon>

            <MDTypography variant="body2">Create document from template</MDTypography>
          </MenuItem>
        )}

        <MenuItem sx={{ px: 1 }} onClick={onClickUploadFileMenuItemHandler}>
          <ListItemIcon>
            <FileUpload fontSize="medium" />
          </ListItemIcon>
          <MDTypography variant="body2">Upload document</MDTypography>
        </MenuItem>
      </MenuPopover>
      <input
        disabled={isDocumentLoading}
        onChange={onInputFileUploadHandler}
        ref={fileInputRef}
        type="file"
        hidden
        accept="image/*,audio/*,video/*,application/*,text/*"
      />

      <SelectFileSignTemplateModal
        selectedFileSignProvider={fileSignProvider}
        isOpen={isSelectTemplateDocumentModalOpen}
        onCloseWindowHandler={closeSelectTemplateDocumentModal}
        onClickSelectTemplateItemHandler={onClickSelectTemplateItemHandler}
      />

      <FillingTemplateSideMenu
        isOpen={isFillTemplateMenuOpen}
        closeMenuHandler={closeFillTemplateMenu}
        title={selectedTemplate?.name || ''}
        templateId={selectedTemplate?.id || ''}
        remoteParticipantFirebaseId={userFirebaseId}
        legalCaseId={`/legal_cases/${caseId}`}
        selectedFileSignProvider={fileSignProvider?.provider}
      />

      <FileSignProviderDialogWindow
        selectedSignProvider={fileSignProvider?.provider}
        isOpen={isDialogOpen}
        fileSignProviders={fileSignProvidersToMap}
        onCloseDialogWindow={onCloseDialog}
        onChooseHandler={onChooseSignProviderHandler}
        onChangeRadioButtonHandler={onChangeRadioButtonHandler}
      />
    </Card>
  );
};
