import { useMemo } from 'react';
import * as yup from 'yup';
import { useCaseLawTypes } from 'features/common/hooks/useCaseLawTypes';
import { useGeoData } from 'features/common';
import { useCaseDetails } from 'features/store';
import { useForm } from 'react-hook-form';
import {
  CaseInformationSchema,
  DependantInformationScheme,
  caseInformationFormSchema,
  dependantInformationFormSchema,
} from 'features/my-legal-cases/components/CreateLegalCase/form.config';
import { yupResolver } from '@hookform/resolvers/yup';
import { PatchLegelCasePayload } from '../CaseDetailsApiService';
import { CaseInfoEditContentFormProps } from '../types';

export const useCaseInfoEditContentForm = ({
  caseCounty,
  caseCountryId,
  caseLawType,
  caseState,
  description,
  dependantInfo: {
    dependantFirstName,
    dependantLastName,
    dependantStateOfResidence,
    dependantUnder18,
    isCaseForDependant,
  },
  closeEditingModeHandler,
}: CaseInfoEditContentFormProps) => {
  const { caseLawTypesList, isCaseLawTypesLoading } = useCaseLawTypes();
  const {
    getStateListHandler,
    countryStateList,
    isStateListLoading,
    getCountiesHandler,
    isCountyListLoading,
    countyList,
  } = useGeoData();

  const updateLegalCaseDetailsHandler = useCaseDetails(state => state.updateLegalCaseDetails);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<DependantInformationScheme & CaseInformationSchema>({
    resolver: yupResolver(
      yup.object().shape({ ...caseInformationFormSchema.fields, ...dependantInformationFormSchema.fields })
    ),
    mode: 'onTouched',
    defaultValues: {
      caseCounty,
      caseLawType,
      caseState,
      description,
      dependantFirstName,
      dependantLastName,
      dependantCaseState: dependantStateOfResidence.id,
      isUnder18: dependantUnder18,
      isCaseForDependant,
    },
  });

  const isSaveButtonDisabled = !isValid || isSubmitting || !isDirty;

  const onFormSubmitHandler = handleSubmit(async formData => {
    const editLegalCasePayload: PatchLegelCasePayload = {
      country: caseCountryId,
      state: formData.caseState,
      county: formData.caseCounty,
      caseLawType: formData.caseLawType,
      description: formData.description,
      ...(formData.isCaseForDependant
        ? {
            caseForDependant: formData.isCaseForDependant,
            dependantFirstName: formData.dependantFirstName,
            dependantLastName: formData.dependantLastName,
            dependantStateOfResidence: formData.dependantCaseState,
            dependantUnder18: formData.isUnder18,
          }
        : {
            caseForDependant: false,
            dependantFirstName: null,
            dependantLastName: null,
            dependantStateOfResidence: null,
            dependantUnder18: false,
          }),
    };

    await updateLegalCaseDetailsHandler(editLegalCasePayload);
    closeEditingModeHandler();
  });

  const getStateValue = useMemo(
    () => (stateId: string) => {
      return stateId
        ? countryStateList.find(option => {
            return stateId === option['@id'];
          }) ?? null
        : null;
    },
    [countryStateList]
  );

  const currentCaseState = watch('caseState');

  return {
    isSaveButtonDisabled,
    onFormSubmitHandler,
    getStateValue,
    caseLawTypesList,
    isCaseLawTypesLoading,
    getStateListHandler,
    countryStateList,
    isStateListLoading,
    getCountiesHandler,
    isCountyListLoading,
    countyList,
    errors,
    caseState,
    control,
    isSubmitting,
    currentCaseState,
    register,
    watch,
    setValue,
  };
};
