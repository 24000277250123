import { BackendUserResponse, BaseData, backendApiClient } from 'features/common';

export type GetProfileResponse = {
  '@context': string;
  '@id': string;
  '@type': string;
  firebaseUser: string;
  email: string;
  phone: string;
  userProfile: {
    '@context': string;
    '@id': string;
    '@type': string;
    personTitle: string;
    firstName: string;
    lastName: string;
    countryCode: string;
    stateCode: string;
    town: string;
    defaultPostalAddress: {
      '@context': string;
      '@id': string;
      '@type': string;
      title: string;
      type: string;
      nameOfAddressee: string;
      zipCode: string;
      town: string;
      stateCode: string;
      countryCode: string;
    };
  };
  attorneyProfile: string;
  setting: string;
  roles: [string];
};

export type PatchProfilePayload = {
  userProfile: {
    personTitle: string;
    firstName: string;
    lastName: string;
    country: string;
    state: string;
    town: string;
    defaultPostalAddress?: string;
  };
};

export const userProfileService = () => {
  const getProfile = async () => {
    try {
      const { data: user } = await backendApiClient({}).get<BackendUserResponse>(`/security/profile`);

      return user;
    } catch (error) {
      throw error;
    }
  };

  const patchProfile = async (patchPagePayload: PatchProfilePayload) => {
    try {
      return await backendApiClient({
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }).patch<PatchProfilePayload>('/security/profile', patchPagePayload);
    } catch (error) {
      throw error;
    }
  };

  return {
    getProfile,
    patchProfile,
  };
};
