import { useEffect, useRef, useState } from 'react';
import { BaseFormField, DashboardLayout, notice, RichTextEditor, ToastType, useRouter } from 'features/common';
import { Card, Grid, InputAdornment, Tooltip } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { EditorState } from 'draft-js';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Loader } from 'features/common/components/Loader';
import { ButtonsFooter } from 'features/static-pages/components';
import { convertEditorStateValueToHTML, getEditorStateFromContent } from 'features/common/helpers/textEditor';
import { ROUTES } from 'features/common/variables/routes';
import { FailedLoadContent } from 'features/common/components/FailedLoadContent';
import { BaseAutocompleteField } from 'features/common/components/BaseAutocompleteField';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { baseBackendErrorHandler } from '../../common/errorHanders';
import { useContentCategories, useEditContentItem } from '../hooks';
import { ContentCategoryResponse } from '../services';
import { EditContentItemFormSchema, createContentItemFormSchema } from './formSchema';

export const EditContentItemTemplate = (): JSX.Element => {
  const isConnected = useRef(false);
  const router = useRouter();

  const [selectedCategory, setSelectedCategory] = useState<null | ContentCategoryResponse>(null);
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  const contentItemId = router.params.contentItemId;

  const { contentCategoriesList, isLoading: isCategoriesLoading } = useContentCategories({ itemsPerPage: 100 });
  const { isFailed, contentItemData, patchContentItemHandler, isLoading } = useEditContentItem(contentItemId);

  const {
    handleSubmit,
    formState: { isValid, isDirty, isSubmitting, errors },
    register,
    setError,
    setValue,
    reset,
  } = useForm<EditContentItemFormSchema>({
    resolver: yupResolver(createContentItemFormSchema),
    mode: 'onChange',
    values: {
      category: contentItemData?.category,
      name: contentItemData?.name,
      htmlContent: contentItemData?.htmlContent,
    },
  });

  const onSubmitFormHandler = async (data: EditContentItemFormSchema) => {
    try {
      if (!editorState) return;
      await patchContentItemHandler({
        name: data.name,
        category: data.category,
        htmlContent: data.htmlContent,
      });
      await router.push(ROUTES.contentItems);
      notice(ToastType.SUCCESS, `Successfully edited content item with name - ${data.name}`);
    } catch (error) {
      console.error(error);
      baseBackendErrorHandler(error, { formError: { formData: data, setError } });
    }
  };

  const initializeContentItemData = () => {
    const categoryName = contentCategoriesList?.find(category => category['@id'] === contentItemData?.category);
    const initializeEditorState = () => {
      const editorState = getEditorStateFromContent(contentItemData.htmlContent);
      setEditorState(editorState);
    };

    if (contentItemData) {
      initializeEditorState();
      setSelectedCategory(categoryName);
    }
  };

  useEffect(() => {
    if (!isConnected.current) {
      if (!!contentItemData && !!contentCategoriesList.length) {
        initializeContentItemData();
        isConnected.current = true;
      }
    } else {
      isConnected.current = false;
    }
  }, [isCategoriesLoading, isLoading]);

  const onClickDiscardButtonHandler = () => {
    initializeContentItemData();
    reset();
  };

  const onEditorStateChangeHandler = (editorState: EditorState) => {
    setEditorState(editorState);
    setValue('htmlContent', convertEditorStateValueToHTML(editorState), { shouldDirty: true });
  };

  const isSaveButtonDisabled = !isValid || !isDirty || isSubmitting;

  return (
    <DashboardLayout isNavigationDisabled>
      {isLoading && !isSubmitting ? (
        <Loader />
      ) : contentItemData ? (
        <form onSubmit={handleSubmit(onSubmitFormHandler)}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Edit content item below
              </MDTypography>
              <MDBox mt={1} mb={2}>
                <MDTypography variant="body2" color="text">
                  You’re able to edit your own content for user to see.
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12}>
              <Card sx={{ padding: 3 }}>
                <MDTypography variant="body2" color="text" sx={{ paddingBottom: 2 }}>
                  Assign name to your content item to better understanding what is it used for.
                </MDTypography>
                <BaseFormField
                  formInputProps={{
                    ...register('name'),
                    placeholder: 'Name',
                    type: 'text',
                    defaultValue: '',
                    fullWidth: true,
                    color: 'white',
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start" sx={{ cursor: 'pointer' }}>
                          <Tooltip
                            title="Attention! Any changes to the name field may lead to incorrect display of content on the landing page"
                            sx={{
                              color: theme => theme.palette.warning.main,
                            }}
                          >
                            <ErrorOutlineOutlinedIcon fontSize="medium" />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    },
                  }}
                  errorValue={errors['name']?.message}
                />
                <MDBox mb={3}>
                  <MDBox mb={1.625} display="inline-block">
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize"
                    >
                      Category
                    </MDTypography>
                  </MDBox>
                  <BaseAutocompleteField
                    value={selectedCategory || null}
                    options={contentCategoriesList}
                    isDisabled
                    getOptionLabel={option => option.name}
                    onChangeHandler={value => {
                      if (!value) return;
                      setValue('category', value['@id'], { shouldDirty: true });
                      setSelectedCategory(value);
                    }}
                    errorMessage={errors['category']?.message}
                    isLoading={isCategoriesLoading}
                    formInputProps={{
                      variant: 'standard',
                    }}
                  />
                </MDBox>
                <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <MDTypography component="label" variant="body2" color="text" sx={{ paddingBottom: 2 }}>
                    Item content&nbsp;&nbsp;
                    <MDTypography variant="caption" fontWeight="regular" color="text">
                      (optional)
                    </MDTypography>
                  </MDTypography>
                </MDBox>
                <RichTextEditor editorState={editorState} onEditorStateChange={onEditorStateChangeHandler} />
              </Card>
            </Grid>

            <ButtonsFooter
              isDiscardButtonDisabled={!isDirty || isSubmitting}
              onClickDiscardButtonHandler={onClickDiscardButtonHandler}
              isSaveButtonDisabled={isSaveButtonDisabled}
              isLoading={isSubmitting}
            />
          </Grid>
        </form>
      ) : (
        isFailed && <FailedLoadContent />
      )}
    </DashboardLayout>
  );
};
