import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { UserCredential } from '@firebase/auth';
import { BaseFormField, createBackendUserService, notice, ToastType, useRouter } from 'features/common';
import { createFirebaseService, OAUTH_PROVIDER_TYPES } from 'features/auth';
import { ROUTES } from 'features/common/variables/routes';
import { FirebaseSignUpFormSchema, firebaseSignUpFormValidationSchema } from 'features/auth/formSchema';
import { AUTH_FLOW_STEPS, useAuth } from 'features/store';
import MDBox from 'components/MDBox';
import { AuthProvidersButtons } from '../AuthProvidersButtons';
import { RedirectButton } from '../RedirectButton';
import { BasePasswordFormField } from 'features/common/components/BasePasswordFormField';
import { useResponsive } from 'features/common/hooks/useResponsive';

export const FirebaseSignUpWithEmail: FC = () => {
  const { push, params } = useRouter();
  const { setBackendUser, resetAllStepsData, toStep, acceptAdminInvitationHandler } = useAuth(state => ({
    setBackendUser: state.setBackendUser,
    toStep: state.toStep,
    acceptAdminInvitationHandler: state.acceptAdminInvitationHandler,
    resetAllStepsData: state.resetAllStepsData,
  }));

  const { getBackendUser } = createBackendUserService();
  const { signUpWithEmail, authWithPopupByProvider, verifyUsersEmail } = createFirebaseService();

  const {
    register,
    formState: { isDirty, isSubmitting, isValid, errors },
    handleSubmit,
  } = useForm<FirebaseSignUpFormSchema>({
    resolver: yupResolver(firebaseSignUpFormValidationSchema),
    mode: 'onTouched',
  });

  const onFirebaseUserConnected = async () => {
    try {
      const backendUser = await getBackendUser();

      if (backendUser) {
        const isAdmin = backendUser?.roles?.includes('ROLE_ADMIN');
        setBackendUser(backendUser);
        resetAllStepsData();
        push(isAdmin ? ROUTES.contentItems : ROUTES.myLegalCases).then(() => {
          notice(ToastType.SUCCESS, `Welcome!`);
        });
      } else {
        toStep(AUTH_FLOW_STEPS.WORK_TYPE);
      }
    } catch (error) {
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  };

  const verifyUsersEmailHandler = async (user: UserCredential['user']) => {
    try {
      await verifyUsersEmail(user, 'Verification link has been sent to your email. Please check your inbox.');
      await push(ROUTES.signIn);
    } catch (error) {
      throw error;
    }
  };

  const onFormSubmitHandler = handleSubmit(async (formData: FirebaseSignUpFormSchema) => {
    const { email, password } = formData;

    try {
      const userCredential = await signUpWithEmail({ email, password });
      if (userCredential) {
        await acceptAdminInvitationHandler(params?.accessCode);

        userCredential.user.emailVerified
          ? await onFirebaseUserConnected()
          : await verifyUsersEmailHandler(userCredential?.user);
      }
    } catch (error) {
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  });

  const onAuthProviderButtonClickHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      const userCredential = await authWithPopupByProvider(event.currentTarget.id as OAUTH_PROVIDER_TYPES);

      if (userCredential) {
        await acceptAdminInvitationHandler(params?.accessCode);

        await onFirebaseUserConnected();
      }
    } catch (error) {
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  };

  const isNextButtonDisabled = !isValid || isSubmitting || !isDirty;

  const isSmallScreen = useResponsive('between', 'xs', 'sm');
  const isLgScreens = useResponsive('up', 'md');

  return (
    <form onSubmit={onFormSubmitHandler}>
      <MDBox display="flex" flexDirection="column" gap="8px">
        <BaseFormField
          formInputProps={{
            ...register('email'),
            type: 'email',
            label: 'Email',
            fullWidth: true,
          }}
          errorValue={errors['email']?.message}
        />

        <BasePasswordFormField
          formFieldProps={{
            pb:
              isSmallScreen && errors?.['password']?.type === 'matches'
                ? '45px'
                : isLgScreens && errors?.['password']?.type === 'matches'
                ? '30px'
                : '18px',
          }}
          formInputProps={{
            ...register('password'),
            label: 'New password',
            fullWidth: true,
          }}
          errorValue={errors?.['password']?.message}
        />

        <BasePasswordFormField
          formInputProps={{
            ...register('passwordConfirmation'),
            label: 'Confirm Password',
            fullWidth: true,
          }}
          errorValue={errors?.['passwordConfirmation']?.message}
        />

        <AuthProvidersButtons
          isNextButtonDisabled={isNextButtonDisabled}
          isProviderButtonDisabled={isSubmitting}
          onAuthProviderButtonClickHandler={onAuthProviderButtonClickHandler}
        />
        <RedirectButton routeTo={ROUTES.signIn} />
      </MDBox>
    </form>
  );
};
