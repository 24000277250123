import * as yup from 'yup';
import { PracticeAreaCategory } from 'features/clio-settings/ClioSettingsApiService';
import { PracticeAreaCategoriesValidationSchema } from 'features/settings/Components/Clio/form.config';

export type CreateCaseLawTypeFormSchema = EditCaseLawTypeFormSchema;
export type EditCaseLawTypeFormSchema = { title: string; clioLawTypes: PracticeAreaCategory[] };

const titleSchema = yup.string().required().trim().label('Case Law Title');

export const createCaseLawTypeFormSchema = yup
  .object({
    title: titleSchema,
    clioLawTypes: PracticeAreaCategoriesValidationSchema,
  })
  .required();

export const editCaseLawTypeFormSchema = yup
  .object({
    title: titleSchema,
  })
  .required();
