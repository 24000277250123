import { FC } from 'react';
import { Dialog } from '@mui/material';
import { SelectFileSignTemplateModalContent } from './SelectDocusignTemplateModalContent';
import { SelectFileSignTemplateModalProps } from './types';

export const SelectFileSignTemplateModal: FC<SelectFileSignTemplateModalProps> = ({
  isOpen,
  onCloseWindowHandler,
  onClickSelectTemplateItemHandler,
  selectedFileSignProvider,
}) => {
  return (
    <Dialog open={isOpen} onClose={onCloseWindowHandler} PaperProps={{ sx: { width: 1 } }}>
      <SelectFileSignTemplateModalContent
        selectedFileSignProvider={selectedFileSignProvider}
        onCloseWindowHandler={onCloseWindowHandler}
        onClickSelectTemplateItemHandler={onClickSelectTemplateItemHandler}
      />
    </Dialog>
  );
};
