import { useEffect, useRef } from 'react';
import { AxiosError } from 'axios';
import { ROUTES } from 'features/common/variables/routes';
import { axiosInstance, controller, resetAbortContoller, useRouter, useUser } from 'features/common';

const allowedRoutes = [ROUTES.profile, ROUTES.firmProfile];

export const SubscriptionGuard = (): null => {
  const { navigate, pathname } = useRouter();

  const { isNavigationBlocked, backendUser, setStateData, isAttorney, isAuthorized } = useUser();

  const interceptorId = useRef<number | null>(null);

  useEffect(() => {
    if (!isAuthorized && !isAttorney && !backendUser) return;
    interceptorId.current = axiosInstance.interceptors.response.use(
      response => {
        return response;
      },
      (error: AxiosError) => {
        if (!allowedRoutes.includes(pathname as ROUTES) && error.response?.status === 402) {
          controller?.abort();
          if (!isNavigationBlocked) {
            setStateData('isNavigationBlocked', true);
          }
          navigate(ROUTES.subscription, { replace: true });
          resetAbortContoller();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.response.eject(interceptorId.current as number);
    };
  }, [navigate]);

  return null;
};
