import { FC } from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { UsersTable } from '../components';
import { TemplateWrapper } from '../TemplateWrapper';
import { Add } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { SideMenu, useBoolean } from 'features/common';
import { CreateInviteContentModal } from './CreateInviteContentModal';

export const AdminsManagementTemplate: FC = () => {
  const [isInviteModalOpen, openInviteModal, closeInviteModal] = useBoolean(false);

  return (
    <TemplateWrapper>
      <MDBox
        p={3}
        lineHeight={1}
        pb={0}
        sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center' }}
        justifyContent="space-between"
      >
        <MDTypography variant="button" color="text">
          Here you can manage admins.
        </MDTypography>
        <Fab color="info" aria-label="add" size="medium" onClick={openInviteModal}>
          <Add sx={{ width: 25, height: 25 }} />
        </Fab>
      </MDBox>
      <UsersTable isAdminPage emptyStateMessage="No admins" />

      <SideMenu isOpen={isInviteModalOpen} onClose={closeInviteModal} title="Invite admin" customWidth={450}>
        <CreateInviteContentModal onCloseMenuHandler={closeInviteModal} />
      </SideMenu>
    </TemplateWrapper>
  );
};
