import { useEffect } from 'react';
import { useRouter, useUser } from 'features/common';
import { useDeviceDetection } from 'features/common/hooks/useDeviceDetection';
import { MOBILE_APP_DEEP_LINK } from 'features/reset-password';
import { setLayout, useMaterialUIController } from 'context';
import { useSearchParams } from 'react-router-dom';
import { getPath } from './helpers';

export const OpenAppTemplate = (): null => {
  const device = useDeviceDetection();
  const isAuthorized = useUser().isAuthorized;

  const router = useRouter();

  const [_, dispatch] = useMaterialUIController();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    setLayout(dispatch, 'auth');
  }, [dispatch]);

  const handleDeviceNavigation = () => {
    return setTimeout(async () => {
      await new Promise(res => {
        res(router.navigate(getPath(searchParams, isAuthorized)));
      }).then(() => {
        if (device === 'Mobile') window.location.href = MOBILE_APP_DEEP_LINK;
      });
    }, 10);
  };

  useEffect(() => {
    if (!device) return;
    handleDeviceNavigation();
  }, [device]);

  return null;
};
