import { FC, useState } from 'react';
import { DeleteOutline, MoreVert, FileDownloadOutlined, AssignmentTurnedInOutlined } from '@mui/icons-material';
import { Chip, CircularProgress, Divider, Stack, alpha } from '@mui/material';
import { MenuPopover } from 'features/common/components/MenuPopover';
import { PopoverMenuItem } from 'features/common/components/PopoverMenuItem';
import { usePopover } from 'features/common/hooks/usePopover';
import { ToastType, notice, useFileSign, useRouter } from 'features/common';
import MDBox from 'components/MDBox';
import { DocumentItemProps } from 'features/case-details/types';
import { FileSignProviderDialogWindow } from 'features/common/components/FileSignProviderDialogWindow';
import { ROUTES } from 'features/common/variables/routes';
import { DocumentIcon } from './DocumentIcon';
import { DocumentMainInfo } from './DocumentMainInfo';

export const SIGN_SERVICE_NOT_INTEGRATED_MESSAGE = `To take advantage of this feature, first, you’ll need to add integrations in the settings`;

export const DocumentItem: FC<DocumentItemProps> = ({
  isChatDocument,
  userSignerFirebaseId,
  isOpenSignTabWindow,
  id,
  title,
  fileSize,
  ownerFirstName,
  ownerLastName,
  fileType,
  downloadButtonRef,
  isDisableActions,
  isClosedCase,
  messageId,
  filePermission,
  requestedToSignUserFirebaseId,
  onClickDeleteMessageFileIconHandler,
  onClickDeleteIconHandler,
  onClickDownloadDocumentHandler,
  caseId,
  lastElementRef,
}) => {
  const {
    fileSignProvider,
    isCancelRequestToSign,
    isDialogOpen,
    onCloseDialog,
    onDeclineSignEnvelopeHandler,
    onOpenDialog,
    onSendRequestToSignDocumentHandler,
    signLabelData,
    onSignDocumentHandler,
    isSeveralSignProviders,
    onChangeRadioButtonHandler,
    isSendingSignReq,
  } = useFileSign({
    fileSignProviders: filePermission?.providers,
    fileSignData: {
      requestedToSignUserFirebaseId,
      isNeedToSign: filePermission?.needToSign,
      isRequestedToSign: filePermission?.requestedToSign,
      isSigned: filePermission?.signed,
    },
  });
  const [isDownloading, setIsDownLoading] = useState(false);
  const navigate = useRouter().push;

  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  const onDeleteMenuItemClickHandler = () => {
    handleClosePopover();
    messageId ? onClickDeleteMessageFileIconHandler(messageId, id) : onClickDeleteIconHandler?.(id);
  };

  const onClickDownloadMenuItemHandler = async () => {
    setIsDownLoading(true);
    handleClosePopover();
    await onClickDownloadDocumentHandler?.(id, title);
    setIsDownLoading(false);
  };

  const onClickRequestToSignButtonHandler = async () => {
    if (!filePermission?.activeProvider) {
      navigate(ROUTES.integrations).then(() => notice(ToastType.WARNING, SIGN_SERVICE_NOT_INTEGRATED_MESSAGE));
      return;
    }

    if (isSeveralSignProviders && !isDialogOpen) {
      onOpenDialog();
      handleClosePopover();
      return;
    }
    openPopover && handleClosePopover();
    await onSendRequestToSignDocumentHandler({ caseId, fileId: id, userSignerFirebaseId });
  };

  const onClickSignDocumentHandler = async () => {
    handleClosePopover();
    await onSignDocumentHandler({ fileId: id, isOpenSignTabWindow });
  };

  const onClickCancelRequestForSignButtonHandler = async () => {
    handleClosePopover();
    await onDeclineSignEnvelopeHandler(id);
  };

  return (
    <Stack ref={lastElementRef}>
      <Stack direction="row" spacing={1} alignItems="center">
        <DocumentIcon fileType={fileType} />

        <Stack sx={{ flex: 1, ml: '16px !important' }}>
          <DocumentMainInfo
            title={title}
            fileSize={fileSize}
            ownerFirstName={ownerFirstName}
            ownerLastName={ownerLastName}
          />
          {(filePermission?.signed || filePermission?.needToSign || filePermission?.requestedToSign) && (
            <div>
              <Chip
                label={signLabelData}
                sx={{
                  '& span': {
                    px: 1,
                  },
                  color: 'white !important',
                  bgcolor: ({ palette }) =>
                    filePermission?.signed
                      ? palette.success.main
                      : filePermission?.needToSign || filePermission?.requestedToSign
                      ? alpha(palette.warning.light, 0.9)
                      : 'none',
                }}
              />
            </div>
          )}
        </Stack>

        {isDownloading || isSendingSignReq ? (
          <MDBox sx={{ padding: '2px', display: 'flex' }}>
            <CircularProgress size={20} sx={{ animation: 'none' }} />
          </MDBox>
        ) : (
          <MDBox
            sx={{
              padding: '3px',
              display: 'flex',
              ...(!isDisableActions && {
                borderRadius: '50%',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: theme => theme.palette.grey[100],
                },
              }),
            }}
            onClick={isDisableActions ? undefined : handleOpenPopover}
          >
            <MoreVert fontSize="medium" color={isDisableActions ? 'disabled' : 'inherit'} />
          </MDBox>
        )}
      </Stack>
      <Divider sx={{ my: '8px !important' }} />

      <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="top-right">
        {filePermission?.canBeDownload && (
          <PopoverMenuItem
            onClickHandler={onClickDownloadMenuItemHandler}
            title="Download"
            icon={<FileDownloadOutlined fontSize="medium" />}
          />
        )}

        {filePermission?.canBeRequestToSign && (
          <PopoverMenuItem
            onClickHandler={onClickRequestToSignButtonHandler}
            title="Request to sign"
            icon={<AssignmentTurnedInOutlined fontSize="medium" />}
          />
        )}

        {filePermission?.needToSign && (
          <PopoverMenuItem
            onClickHandler={onClickSignDocumentHandler}
            title="Sign the document"
            icon={<AssignmentTurnedInOutlined fontSize="medium" />}
          />
        )}

        {isCancelRequestToSign && isChatDocument && (
          <PopoverMenuItem
            onClickHandler={onClickCancelRequestForSignButtonHandler}
            title="Cancel sign request"
            icon={<AssignmentTurnedInOutlined fontSize="medium" />}
          />
        )}
        {filePermission?.canBeDelete && (
          <PopoverMenuItem
            onClickHandler={onDeleteMenuItemClickHandler}
            title="Delete"
            icon={<DeleteOutline fontSize="medium" />}
            isDisabled={isClosedCase}
          />
        )}
      </MenuPopover>

      <MDBox component="a" ref={downloadButtonRef} sx={{ display: 'none' }} />

      <FileSignProviderDialogWindow
        selectedSignProvider={fileSignProvider?.provider}
        isOpen={isDialogOpen}
        onCloseDialogWindow={onCloseDialog}
        fileSignProviders={filePermission?.providers}
        onChooseHandler={onClickRequestToSignButtonHandler}
        onChangeRadioButtonHandler={onChangeRadioButtonHandler}
      />
    </Stack>
  );
};
