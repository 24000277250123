import { AgoraEventType } from 'features/common/services/agoraEventApiService';

export type EventTypeOption = {
  title: string;
  type: AgoraEventType;
};

export type ReminderOption = Pick<EventTypeOption, 'title'>;

export const REMINDER_OPTIONS: ReminderOption[] = [
  {
    title: '00:10',
  },
  {
    title: '00:20',
  },
  {
    title: '00:30',
  },
  {
    title: '01:00',
  },
];

export const EVENT_TYPE_OPTIONS: EventTypeOption[] = [
  {
    title: 'Video Call',
    type: 'video_call',
  },
  {
    title: 'Voice Call',
    type: 'voice_call',
  },
];
