import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Stack, Tooltip } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ClearIcon from '@mui/icons-material/Clear';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { ToastType, notice, useBoolean } from 'features/common';
import CustomPaginationTable from 'features/content-items/components/CustomPaginationTable';
import { TablePaginationFooter } from 'features/common/components/TablePaginationFooter/TablePaginationFooter';
import { formatDate } from 'features/common/helpers/date';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { CreateContentItemResponse } from '../services';
import { useContentItems } from '../hooks';
import {
  ALL_REQUIRED_CONTENT_ITEMS_NAMES,
  CONTENT_CATEGORY_SLUGS,
  REQUIRED_CONTENT_ITEMS_NAMES,
  getAllRequiredContentItems,
  getRequiredContentItems,
} from './helpers';

type ContentItemTableProps = {
  selectedCategoryData: {
    categoryId: string;
    categorySlug: string;
    categoryName: string;
  };
};

export const ContentItemTable: React.FC<ContentItemTableProps> = ({ selectedCategoryData }: ContentItemTableProps) => {
  const navigation = useNavigate();

  const {
    contentItemsList,
    entriesPerPage: currentEntriesPerPage,
    currentPage,
    deleteContentItemById,
    onChangeEntriesPerPageHandler,
    onChangePageHandler,
    contentItemsTotal,
    isLoading,
    isLastItem,
    setIsLoading,
    onDeleteItem,
    getItemsHandler,
  } = useContentItems({
    category: selectedCategoryData.categoryId || undefined,
  });

  const [missedItemsNames, setMissedItemsNames] = useState<{ slug?: string; items: string[] }[]>([]);

  useEffect(() => {
    if (contentItemsList.length) {
      const itemNamesSet = new Set(contentItemsList.map(obj => obj.name));

      if (selectedCategoryData.categorySlug === 'all') {
        const missingItemsDataByAllCategory = getAllRequiredContentItems(
          ALL_REQUIRED_CONTENT_ITEMS_NAMES,
          itemNamesSet
        );
        setMissedItemsNames(missingItemsDataByAllCategory);
      } else {
        const missingItems =
          REQUIRED_CONTENT_ITEMS_NAMES[selectedCategoryData.categorySlug as CONTENT_CATEGORY_SLUGS] || {};

        const missingItemsData = getRequiredContentItems(missingItems, itemNamesSet);
        setMissedItemsNames(missingItemsData);
      }
    }
  }, [contentItemsList]);

  const [selectedContentItemId, setSelectedContentItemId] = useState('');
  const [isContentItemDeleteModalOpen, openContentItemDeleteModal, closeContentItemDeleteModal] = useBoolean(false);

  const onDeleteItemHandler = async () => {
    try {
      const nextPage = isLastItem ? onDeleteItem() : currentPage;
      setIsLoading(true);

      closeContentItemDeleteModal();

      await deleteContentItemById(selectedContentItemId.replace('/content_items', ''));
      await getItemsHandler({ page: nextPage });
      notice(ToastType.SUCCESS, 'Successfully deleted!');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong!');
    }
  };

  return (
    <MDBox justifyItems="center" alignItems="center" display="flex" minHeight={300}>
      {isLoading ? (
        <CircularProgress sx={{ mx: 'auto', mb: '16px' }} />
      ) : contentItemsList.length > 0 ? (
        <MDBox width={'100%'}>
          {missedItemsNames.length ? (
            <Stack mx={3} mt={1}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <ErrorOutlineOutlinedIcon color="error" fontSize="medium" />
                <MDTypography variant="body2" fontWeight={500}>
                  Attention. You do not have the following content items. The display of content on the landing page may
                  be broken:
                </MDTypography>
              </Stack>
              <Stack component="ul" ml={1}>
                {missedItemsNames.map((el, index) => (
                  <Stack key={el?.slug || index}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      {el?.slug && (
                        <>
                          <MDTypography variant="body2">Slug - </MDTypography>

                          <MDTypography variant="body2" fontWeight={500}>
                            {el.slug}
                          </MDTypography>
                        </>
                      )}
                    </Stack>
                    <MDTypography variant="body2">Content items:</MDTypography>
                    {el.items.map(el => (
                      <Stack direction="row" component="li" key={el} ml={1}>
                        <MDTypography variant="body2">{el}</MDTypography>
                      </Stack>
                    ))}
                  </Stack>
                ))}
              </Stack>
            </Stack>
          ) : null}
          <CustomPaginationTable
            entriesPerPage={{ defaultValue: currentEntriesPerPage, entries: [100, 200] }}
            onChangeEntriesPerPage={onChangeEntriesPerPageHandler}
            isSorted={false}
            table={{
              columns: [
                { Header: 'Name', accessor: 'name' },
                { Header: 'Creation date', accessor: 'createdAt' },
                { Header: 'Last Update', accessor: 'lastUpdate' },
                { Header: '', accessor: 'actionBtns' },
              ],
              rows: (contentItemsList as CreateContentItemResponse[]).map(contentItem => ({
                name: contentItem.name,
                createdAt: contentItem.createdAt ? formatDate(contentItem.createdAt) : '-',
                lastUpdate: contentItem.updatedAt ? formatDate(contentItem.updatedAt) : '-',
                actionBtns: (
                  <MDBox
                    sx={{
                      display: 'flex',
                      gap: '5px',
                      width: '100%',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip title="Edit item">
                      <MDButton
                        variant="outlined"
                        size="small"
                        color="secondary"
                        iconOnly
                        onClick={e => {
                          e.stopPropagation();
                          setSelectedContentItemId(contentItem['@id']);
                          navigation('/content-items' + contentItem['@id'].replace('/content_items', ''));
                        }}
                      >
                        <ModeEditIcon />
                      </MDButton>
                    </Tooltip>
                    <Tooltip title="Delete item">
                      <MDButton
                        color="secondary"
                        size="small"
                        variant="outlined"
                        iconOnly
                        onClick={() => {
                          setSelectedContentItemId(contentItem['@id']);
                          openContentItemDeleteModal();
                        }}
                      >
                        <ClearIcon />
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                ),
              })),
            }}
          />
          <TablePaginationFooter
            page={currentPage}
            currentItemsPerPage={currentEntriesPerPage}
            totalItems={contentItemsTotal}
            onChangePage={onChangePageHandler}
          />
        </MDBox>
      ) : (
        <MDTypography sx={{ mx: 'auto' }}>No content items were found...</MDTypography>
      )}

      <DialogWindow
        onCloseDialogHandler={closeContentItemDeleteModal}
        onApproveDialogHandler={onDeleteItemHandler}
        isDialogOpen={isContentItemDeleteModalOpen}
        dialogTitle="Are you sure want to delete Content Item?"
      />
    </MDBox>
  );
};
