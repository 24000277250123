import { FC } from 'react';
import { Stack } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { DocumentItemProps } from 'features/case-details/types';
import { getFormattedFileSize } from 'features/common/helpers/utilities';

export const DocumentMainInfo: FC<
  Pick<DocumentItemProps, 'title' | 'ownerFirstName' | 'ownerLastName' | 'fileSize'>
> = ({ fileSize, ownerFirstName, ownerLastName, title }) => {
  const formattedFileSize = getFormattedFileSize(fileSize);
  return (
    <Stack spacing={0.5} sx={{ flex: 1 }}>
      <MDTypography variant="subtitle2" noWrap sx={{ whiteSpace: 'initial', wordBreak: 'break-all' }}>
        {title}
      </MDTypography>

      <MDTypography variant="button" color="text" sx={{ whiteSpace: 'initial', wordBreak: 'break-all' }}>
        {`${ownerFirstName} ${ownerLastName} - ${formattedFileSize}`}
      </MDTypography>
    </Stack>
  );
};
