import { useState } from 'react';
import { usePagination } from 'features/common/hooks/usePagination';
import { useUsersManagement } from 'features/store/usersManagement/usersManagement';
import { AttorneyProfileCaseLawType, ToastType, createAttorneyService, notice, useBoolean } from 'features/common';
import { GetUserByIdResponse, usersManagementApiService } from '../UsersManagementApiService';
import { UsersTableProps } from '../components';

export const userManagementState = ({ fetchParams, isAdminPage, isAttorneyPage }: UsersTableProps) => {
  const { getUserById } = usersManagementApiService();

  const [currentUser, setCurrentUser] = useState<null | GetUserByIdResponse>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isMoreInfoMenuOpen, onOpenMoreInfoMenuHandler, onCloseMoreInfoMenuHandler] = useBoolean(false);

  const { getUsers, users, totalItems } = useUsersManagement();

  const { currentPage, entriesPerPage, setCurrentPage, onChangeEntriesPerPageHandler } = usePagination({
    totalItems,
    itemsLength: users?.length,
  });

  const onMoreInfoIconClickHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    onOpenMoreInfoMenuHandler();
    await onGetCurrentUserHandler(event.currentTarget.id);
  };

  const onGetUsersHandler = async (page: number = currentPage) => {
    try {
      setIsLoading(true);
      await getUsers({ ...fetchParams, page, itemsPerPage: entriesPerPage }, isAdminPage);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, try again!');
    }
  };

  const onSearchUsersHandler = async (value?: string) => {
    try {
      setIsSearching(true);
      currentPage > 1 && setCurrentPage(1);
      setSearchValue(value);
      await getUsers(
        {
          ...fetchParams,
          itemsPerPage: entriesPerPage,
          search: value,
        },
        isAdminPage
      );
      setIsSearching(false);
    } catch (error) {
      setIsSearching(false);
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, try again!');
    }
  };

  const onChangePageHandler = async (page: number) => {
    setCurrentPage(page);
    await onGetUsersHandler(page);
  };

  const { getAttorneyProfileCaseLawTypesByAdmin } = createAttorneyService();

  const [attorneyProfileCaseLawType, setAttorneyProfileCaseLawType] = useState<AttorneyProfileCaseLawType[]>([]);

  const onGetCurrentUserHandler = async (userId: string) => {
    try {
      const { data } = await getUserById(userId);
      if (isAttorneyPage) {
        const { data: attorneyProfileCaseLawType } = await getAttorneyProfileCaseLawTypesByAdmin(
          data?.attorneyProfile?.id,
          {
            page: 1,
            itemsPerPage: 500,
          }
        );
        setAttorneyProfileCaseLawType(attorneyProfileCaseLawType?.['hydra:member'] || []);
      }
      setCurrentUser(data);
    } catch (error) {
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  };

  const onCloseInfoMenuHandler = () => {
    setCurrentUser(null);
    onCloseMoreInfoMenuHandler();
  };

  return {
    onCloseInfoMenuHandler,
    onSearchUsersHandler,
    onMoreInfoIconClickHandler,
    onChangePageHandler,
    onChangeEntriesPerPageHandler,
    onGetUsersHandler,
    totalItems,
    users,
    entriesPerPage,
    isMoreInfoMenuOpen,
    currentPage,
    currentUser,
    isLoading,
    isSearching,
    searchValue,
    attorneyProfileCaseLawType,
  };
};
