import { BaseFormField, IllustrationLayout, notice, ToastType, useRouter } from 'features/common';
import { sendPasswordResetEmail } from 'firebase/auth';
import { baseFirebaseAuthErrorHandler, firebaseAuth } from '../index';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FirebaseError } from '@firebase/app';
import { AuthFlowBackgrounds } from '../variables/illustartion';
import { resetPasswordFormSchema, ResetPasswordFormSchema } from '../formSchema';
import { Illustrationtitle } from 'features/common/components/IllustatrionLogo';

export const RecoveryPasswordTemplate = () => {
  const router = useRouter();

  const { register, handleSubmit, formState } = useForm<ResetPasswordFormSchema>({
    resolver: yupResolver(resetPasswordFormSchema),
    mode: 'onSubmit',
  });

  const resetPasswordSubmitHandler = async (data: ResetPasswordFormSchema) => {
    try {
      await sendPasswordResetEmail(firebaseAuth, data.email.trim().toLowerCase());
      notice(ToastType.SUCCESS, 'We’ll send an email to this address to reset your password', {
        autoClose: 7000,
        hideProgressBar: false,
      });
      router.back();
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/user-not-found') {
          notice(ToastType.SUCCESS, 'We’ll send an email to this address to reset your password', {
            autoClose: 7000,
            hideProgressBar: false,
          });
          router.back();
          return;
        }
        baseFirebaseAuthErrorHandler(error);
      }
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  };

  return (
    <IllustrationLayout
      title="Password recovery"
      description="Check if you entered the email correctly "
      onClickBack={router.back}
      illustration={AuthFlowBackgrounds.AUTH_ONE}
      illustrationLogo={<Illustrationtitle />}
    >
      <form onSubmit={handleSubmit(resetPasswordSubmitHandler)}>
        <BaseFormField
          formInputProps={{
            type: 'email',
            label: 'Email',
            fullWidth: true,
            ...register('email'),
          }}
          errorValue={formState.errors['email']?.message}
        />
        <MDBox mt={4} mb={1}>
          <MDButton variant="gradient" color="info" type="submit" fullWidth sx={{ textTransform: 'none' }}>
            Next
          </MDButton>
        </MDBox>
      </form>
    </IllustrationLayout>
  );
};
