import { useCallback, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { debounce } from '@mui/material';
import { GetListOfChatsParams, GetUsersChatsParams } from 'features/common/services/chatApiService';
import { useMyChats } from 'features/store';
import { useUser, useDebounce, MercureLinkMap, BaseData } from 'features/common';
import { useMercure } from 'features/common/hooks/useMercure';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';
import { ITEMS_PER_PAGE } from '../MyChatsTemplate';

type MercureMessage = BaseData<'LegalCaseChatMessage'> & {
  id: string;
  message: string;
};

export const useMyChatsState = () => {
  const {
    getUsersChats,
    listOfUserChatsData,
    selectedUserChat,
    selectedUser,
    usersData,
    resetState,
    getMoreListOfChats,
    setSelectedUser,
    setSelectedUserChat,
    getListOfChats,
  } = useMyChats(
    state => ({
      getListOfChats: state.getListOfChats,
      setSelectedUser: state.setSelectedUser,
      getUsersChats: state.getUsersChats,
      setSelectedUserChat: state.setSelectedUserChat,
      getMoreListOfChats: state.getMoreListOfChats,
      resetState: state.resetState,
      selectedUserChat: state.selectedUserChat,
      listOfUserChatsData: state.listOfUserChatsData,
      selectedUser: state.selectedUser,
      usersData: state.usersData,
    }),
    shallow
  );

  const { isAttorney, backendUser, backendUserFirebaseId } = useUser();
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [isMessagessLoading, setIsMessagessLoading] = useState(false);

  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const onChangeSearchInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setIsSearching(true);
  };
  const debounceValue = useDebounce(searchValue);

  const onFetchListOfChatsHandler = async (params?: GetListOfChatsParams) => {
    setIsChatListLoading(true);
    await getListOfChats(params);
    setIsChatListLoading(false);
  };

  const onGetUsersHandler = async (params?: GetUsersChatsParams) => {
    await getUsersChats(params);
    isSearching && setIsSearching(false);
    (isUsersLoading || !searchValue) && setIsUsersLoading(false);
  };

  const [isChatListOpen, setIsChatListOpen] = useState(!!selectedUser?.firebaseUser || false);
  const [isChatListLoading, setIsChatListLoading] = useState(false);

  const onBackButtonClickHandler = () => setIsChatListOpen(false);

  const onUserItemClickHandler = async (event: React.MouseEvent<HTMLDivElement>) => {
    const userId = event.currentTarget.id;
    const params: GetListOfChatsParams = {
      [isAttorney ? 'client' : 'attorney']: userId,
      itemsPerPage: ITEMS_PER_PAGE,
      page: 1,
    };
    setIsChatListOpen(true);
    setSelectedUser(userId);

    await onFetchListOfChatsHandler(params);
  };

  const onSelectedUserChatItemClickHandler = async (event: React.MouseEvent<HTMLDivElement>) => {
    const chatId = event.currentTarget.id;
    const selectedChatWithUser = listOfUserChatsData.listOfUserChats.find(chat => chat?.id === chatId);

    setIsMessagessLoading(true);
    setSelectedUserChat(selectedChatWithUser);
    setIsMessagessLoading(false);
  };

  useEffect(() => {
    onGetUsersHandler({
      page: 1,
      itemsPerPage: ITEMS_PER_PAGE,
      ...(debounceValue && {
        page: null,
        itemsPerPage: null,
        search: debounceValue,
      }),
    });
  }, [debounceValue]);

  useEffect(() => {
    if (selectedUserChat && selectedUserChat) {
      const userId = selectedUserChat?.[isAttorney ? 'client' : 'attorney']?.firebaseUser;
      const params: GetListOfChatsParams = {
        [isAttorney ? 'client' : 'attorney']: userId,
        itemsPerPage: 30,
      };
      onFetchListOfChatsHandler(params);
    }
  }, []);

  const onReceiveNewEventMessageToUpdateUserChatList = useCallback(
    debounce(async (event: MessageEvent<string>) => {
      try {
        const message: MercureMessage = JSON.parse(event.data);

        if (message?.id) {
          const userId = selectedUserChat?.[isAttorney ? 'client' : 'attorney']?.firebaseUser;
          const params: GetListOfChatsParams = {
            [isAttorney ? 'client' : 'attorney']: userId,
            itemsPerPage: 30,
          };
          await getListOfChats(params);
        }
      } catch (error) {
        console.error(error);
        paymentRequiredErrorHandler(error);
      }
    }, 1000),
    [selectedUserChat?.id, isAttorney]
  );

  const onReceiveNewEventMessageToUpdateChatList = useCallback(
    debounce(async (event: MessageEvent<string>) => {
      try {
        const message: MercureMessage = JSON.parse(event.data);

        if (message?.id) {
          await getUsersChats({ page: 1, itemsPerPage: ITEMS_PER_PAGE });
        }
      } catch (error) {
        console.error(error);
        paymentRequiredErrorHandler(error);
      }
    }, 1000),
    [selectedUserChat?.id, isAttorney]
  );

  useMercure({
    topicSubURL: 'legal_case_chats/listener',
    eventId: selectedUser?.firebaseUser ? `${backendUserFirebaseId}/${selectedUser.firebaseUser}` : '',
    mercureSubscribeURL: MercureLinkMap[process.env.REACT_APP_BASE_APP_URL || 'http://localhost:3000'],
    onReceiveEventHandler: onReceiveNewEventMessageToUpdateUserChatList,
  });

  useMercure({
    topicSubURL: 'legal_case_chats/listener',
    eventId: backendUserFirebaseId,
    mercureSubscribeURL: MercureLinkMap[process.env.REACT_APP_BASE_APP_URL || 'http://localhost:3000'],
    onReceiveEventHandler: onReceiveNewEventMessageToUpdateChatList,
  });

  return {
    onSelectedUserChatItemClickHandler,
    onBackButtonClickHandler,
    onUserItemClickHandler,
    onChangeSearchInputHandler,
    getMoreListOfChats,
    resetState,
    usersData,
    isChatListLoading,
    isChatListOpen,
    isMessagessLoading,
    isAttorney,
    backendUser,
    selectedUser,
    searchValue,
    isSearching,
    isUsersLoading,
    selectedUserChat,
    listOfUserChatsData,
  };
};
