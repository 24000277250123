import { FC, useState } from 'react';
import { Dialog, DialogTitle, Stack } from '@mui/material';
import MDButton from 'components/MDButton';
import { useAudioVideoChat } from 'features/store';
import { ToastType, notice, useRouter, useUser } from 'features/common';
import { AudioVideoRoomProps } from 'features/video-calling/types';
import { legalCaseConsultationApiService } from 'features/common/services/legalCaseConsultationApiService';

type RepresentationRequestModalProps = Pick<AudioVideoRoomProps, 'legalCaseId' | 'setRepresentationId'>;

export const RepresentationRequestModal: FC<RepresentationRequestModalProps> = ({
  setRepresentationId,
  legalCaseId,
}) => {
  const { state, navigate } = useRouter();
  const token = useAudioVideoChat(state => state.RTCprops.token);
  const { declineConsultation } = legalCaseConsultationApiService();

  const isAttorney = useUser()?.isAttorney;

  const dialogTitle = 'Do you want to decline offer representation and move case back to queue?';

  const [isLoading, setIsLoading] = useState(false);

  const isOpenDialog = !isAttorney && !!legalCaseId && !token;

  const onClickDeclineReperesentationButtonHandler = async () => {
    try {
      setIsLoading(true);
      await declineConsultation(legalCaseId?.replace('/legal_cases/', ''));
      setRepresentationId('', true);
      setIsLoading(false);
      notice(ToastType.SUCCESS, 'Successfully declined representation offer!');
      navigate(state?.prevRoute, { replace: true });
    } catch (error) {
      setIsLoading(false);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
      console.error(error);
    }
  };

  const onClickNoButtonHandler = () => {
    setRepresentationId('');
    navigate(state?.prevRoute, { replace: true });
  };

  return (
    <Dialog open={isOpenDialog} onClose={onClickNoButtonHandler}>
      <DialogTitle sx={{ textAlign: 'center' }}>{dialogTitle}</DialogTitle>

      <Stack direction="row" spacing={2} sx={{ p: 2, width: 1, justifyContent: 'center', alignItems: 'center' }}>
        <MDButton onClick={onClickNoButtonHandler} variant="outlined" color="dark">
          No
        </MDButton>
        <MDButton
          disabled={isLoading}
          isLoading={isLoading}
          onClick={onClickDeclineReperesentationButtonHandler}
          variant="outlined"
          color="info"
        >
          Yes
        </MDButton>
      </Stack>
    </Dialog>
  );
};
