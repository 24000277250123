import { FC } from 'react';
import { Add } from '@mui/icons-material';
import MDBox from 'components/MDBox';

type CardAddButtonProps = {
  onClickButtonHandler?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export const CardAddButton: FC<CardAddButtonProps> = ({ onClickButtonHandler }) => {
  return (
    <MDBox
      sx={{
        display: 'flex',
        p: 1,
        ...(onClickButtonHandler && {
          borderRadius: 5,
          cursor: 'pointer',
          ':hover': {
            backgroundColor: theme => theme.palette.grey[200],
          },
        }),
      }}
      onClick={onClickButtonHandler}
    >
      <Add fontSize="medium" color={onClickButtonHandler ? 'inherit' : 'disabled'} />
    </MDBox>
  );
};
