import { Stack } from '@mui/material';
import MDButton from 'components/MDButton';
import { FC } from 'react';

type ButtonsBoxProps = {
  isAddNewButtonDisabled: boolean;
  isFindClientButtonDisabled: boolean;
  isAddNewButton: boolean;
  isFindButton: boolean;
  isLoading: boolean;
  onClickAddNewUserHandler: () => Promise<void>;
  onClickFindClientButtonHandler: () => Promise<void>;
  onClickTryAgainButtonHandler: () => void;
};
export const ButtonsBox: FC<ButtonsBoxProps> = ({
  isAddNewButtonDisabled,
  isFindClientButtonDisabled,
  isLoading,
  isFindButton,
  isAddNewButton,
  onClickTryAgainButtonHandler,
  onClickAddNewUserHandler,
  onClickFindClientButtonHandler,
}) => {
  return (
    <>
      {isAddNewButton ? (
        <Stack direction="row" justifyContent="space-between" gap={{ xs: 3, sm: 2.5, md: 3 }}>
          <MDButton
            type="button"
            variant="outlined"
            color="success"
            disabled={isAddNewButtonDisabled}
            sx={{ textTransform: 'none', width: 1, height: 44, mb: '18px', fontSize: 14 }}
            onClick={onClickAddNewUserHandler}
          >
            Add new
          </MDButton>
          <MDButton
            type="button"
            variant="outlined"
            color="info"
            isLoading={isLoading}
            disabled={isLoading}
            sx={{ textTransform: 'none', width: 1, height: 44, mb: '18px', fontSize: 14 }}
            onClick={onClickTryAgainButtonHandler}
          >
            Try again
          </MDButton>
        </Stack>
      ) : isFindButton ? (
        <MDButton
          type="button"
          variant="gradient"
          color="info"
          disabled={isFindClientButtonDisabled}
          isLoading={isLoading}
          sx={{ textTransform: 'none', width: 1, height: 44, mb: '18px', fontSize: 14 }}
          onClick={onClickFindClientButtonHandler}
        >
          Find the client information
        </MDButton>
      ) : null}
    </>
  );
};
