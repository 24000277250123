import { FC } from 'react';
import { Card, Grid } from '@mui/material';
import { DashboardLayout } from 'features/common';
import { Sidenav } from './components';

type TemplateWrapperProps = {
  children: React.ReactNode;
};

export const TemplateWrapper: FC<TemplateWrapperProps> = ({ children }) => {
  return (
    <DashboardLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3}>
          <Sidenav />
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Card>{children}</Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};
