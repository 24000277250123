// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastType, notice, useRouter } from 'features/common';
import { EmailAuthProvider, User, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { baseFirebaseAuthErrorHandler, firebaseAuth } from 'features/auth';
import { FirebaseError } from 'firebase/app';
import { BasePasswordFormField } from 'features/common/components/BasePasswordFormField';
import { useResponsive } from 'features/common/hooks/useResponsive';
import { RequirementsList } from './RequirementsList';
import { ChangePasswordSchema, changePasswordFormSchema } from '../templates';

const passwordRequirements = [
  'At least 8 characters',
  'One uppercase character',
  'One lowercase character',
  'One special character',
  'One number (2 are recommended)',
  'Change it often (recommended)',
];

export const ChangePassword: React.FC = () => {
  const router = useRouter();
  const { register, formState, handleSubmit, setError } = useForm<ChangePasswordSchema>({
    resolver: yupResolver(changePasswordFormSchema),
    mode: 'onTouched',
  });

  const onSubmit = async (data: ChangePasswordSchema) => {
    const provider = EmailAuthProvider.credential(firebaseAuth.currentUser.email, data.currentPassword);
    await reauthenticateWithCredential(firebaseAuth.currentUser, provider)
      .catch(error => {
        if (error.code === 'auth/wrong-password')
          setError('currentPassword', { message: 'Wrong password' }, { shouldFocus: true });
      })
      .then(async response => {
        if (data.currentPassword.trim().length <= 0) {
          setError('currentPassword', { message: 'Current password field is required' }, { shouldFocus: true });
          return;
        }
        if (data.newPassword.trim().length <= 0) {
          setError('newPassword', { message: 'New password field is required' }, { shouldFocus: true });
          return;
        }
        if (response) {
          onPasswordUpdate(firebaseAuth.currentUser, data.newPassword);
        }
      });
  };
  const onPasswordUpdate = async (firebaseUser: User, newPassword: string) => {
    try {
      await updatePassword(firebaseUser, newPassword);
      notice(ToastType.SUCCESS, 'Password has been changed!');
      router.push('/profile');
    } catch (error) {
      if (error instanceof FirebaseError) baseFirebaseAuthErrorHandler(error);
    }
  };

  const isSmallScreen = useResponsive('down', 'sm');

  return (
    <Card id="change-password">
      <MDBox p={3}>
        <MDTypography variant="h5">Change Password</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BasePasswordFormField
              formInputProps={{
                ...register('currentPassword'),
                label: 'Current password',
                fullWidth: true,
              }}
              errorValue={formState.errors?.['currentPassword']?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <BasePasswordFormField
              formFieldProps={{
                pb: isSmallScreen ? '30px' : '18px',
              }}
              formInputProps={{
                ...register('newPassword'),
                label: 'New password',
                fullWidth: true,
              }}
              errorValue={formState.errors?.['newPassword']?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <BasePasswordFormField
              formInputProps={{
                ...register('passwordConfirmation'),
                label: 'Confirm new password',
                fullWidth: true,
              }}
              errorValue={formState.errors?.['passwordConfirmation']?.message}
            />
          </Grid>
        </Grid>
        <MDBox mt={6} mb={1}>
          <MDTypography variant="h5">Password requirements</MDTypography>
        </MDBox>
        <MDBox mb={1}>
          <MDTypography variant="body2" color="text">
            Please follow this guide for a strong password
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            <RequirementsList requirements={passwordRequirements} />
          </MDBox>
          <MDBox ml="auto">
            <MDButton variant="gradient" color="info" size="small" type="submit">
              update password
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
};
