import { FC } from 'react';
import { Stack } from '@mui/material';
import MDButton from 'components/MDButton';
import { RegularityButtonsBoxProps } from './types';

export const RegularityButtonsBox: FC<RegularityButtonsBoxProps> = ({
  currentValue,
  onChangeRegularityValue,
  regularities,
}) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" width={1} flexWrap="wrap">
      {regularities.map(el => (
        <MDButton
          key={el.value}
          id={el.value}
          variant="gradient"
          color={currentValue === el.value ? 'success' : 'info'}
          onClick={onChangeRegularityValue}
          sx={{
            maxWidth: '47%',
            width: 1,
            mb: 2,
          }}
        >
          {el.title}
        </MDButton>
      ))}
    </Stack>
  );
};
