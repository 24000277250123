import { FC, useEffect } from 'react';
import { Grid } from '@mui/material';
import { DashboardLayout, SideMenu, useApiSecurity } from 'features/common';
import { Loader } from 'features/common/components/Loader';
import { MenuPopover } from 'features/common/components/MenuPopover';
import { PopoverMenuItem } from 'features/common/components/PopoverMenuItem';
import { Documents } from './components/Documents';
import { MyNotes, Notes } from './components/Notes';
import { CaseInfo } from './components/CaseInfo';
import { CaseEvents } from './components/CaseEvents';
import { useCaseDetailsState } from './hooks/useCaseDetailsState';
import { AllConsultationsOffersMenuContent } from './components/CaseInfo/AllConsultationsOffersMenuContent';
import { CaseInfoEditContentForm } from './components/CaseInfo/CaseInfoEditContentForm';
import { DelayedNotification } from './components/DelayedNotification';

export const CaseDetailsTemplate: FC = () => {
  const {
    consultations,
    notes,
    myNotes,
    selectedNote,
    createMyLegaCaseNoteHandler,
    getMySelectedNote,
    fetchMyLegalCaseNotesHandler,
    fetchLegalCaseNotesHandler,
    fetchMoreMyLegalCaseNotesHandler,
    fetchMoreLegalCaseNotesHandler,
    updateMyLegalCaseNoteHandler,
    deleteMyLegalCaseNoteHandler,
    getConsultationsListHandler,
    updateLegalCase,
    declineAcceptedConsultationHandler,
    approveRepresentationsHandler,
    declineRepresentationsHandler,
    getLegalCaseStatusesHandler,
    uploadDocumentHandler,
    deleteDocumentHandler,
    approveConsultationHandler,
    declineConsultationHandler,
    getMoreConsultationsHandler,
    caseId,
    isAttorney,
    isAttorneyView,
    isClosedCase,
    isFeedView,
    isAllowToOpenCaseStatusesMenu,
    isViewAllConsultationsButton,
    sortedCaseStatuses,
    representationId,
    myLegalCase,
    closeEditFormMenu,
    closeViewAllMenu,
    handleClosePopover,
    handleOpenPopover,
    isCaseLoading,
    isCaseOffersLoading,
    isEditFormMenuOpen,
    isStatusUpdating,
    isViewAllMenuOpen,
    onClickCaseStatusMenuItemHandler,
    onFetchCaseDetailsHandler,
    onViewAllButtonHandler,
    openEditFormMenu,
    openPopover,
    isDelayedNotificationCard,
    fetchLegalCaseDelayedNotificationsHandler,
    createLegaCaseDelayedNotificationHandler,
    delayedNotifications,
    getSelectedDelayedNotification,
    deleteLegalCaseDelayedNotificationHandler,
    updateLegalCaseDelayedNotificationHandler,
  } = useCaseDetailsState();

  useEffect(() => {
    onFetchCaseDetailsHandler();
  }, [caseId]);

  useEffect(() => {
    isAttorneyView && getLegalCaseStatusesHandler({});
  }, [caseId, isAttorneyView]);

  const { aesDecrypt, aesEncrypt } = useApiSecurity();

  return (
    <DashboardLayout>
      <Grid container spacing={2} flex={isCaseLoading ? 1 : 0}>
        {isCaseLoading ? (
          <Loader />
        ) : (
          <>
            <CaseInfo
              consultations={consultations?.consultationList}
              legalCase={myLegalCase}
              isAttorney={isAttorney}
              caseId={caseId}
              getConsultationsListHandler={getConsultationsListHandler}
              declineAcceptedConsultationHandler={declineAcceptedConsultationHandler}
              updateLegalCaseHandler={updateLegalCase}
              approveRepresentationsHandler={approveRepresentationsHandler}
              declineRepresentationsHandler={declineRepresentationsHandler}
              representationId={representationId}
              isFeedView={isFeedView}
              isClosedCase={isClosedCase}
              isAttorneyView={isAttorneyView}
              isViewAllConsultationsButton={isViewAllConsultationsButton}
              approveConsultationHandler={approveConsultationHandler}
              declineConsultationHandler={declineConsultationHandler}
              onViewAllButtonHandler={onViewAllButtonHandler}
              openEditFormMenu={openEditFormMenu}
              isStatusUpdating={isStatusUpdating}
              handleOpenPopover={handleOpenPopover}
              openPopover={openPopover}
              isAllowToOpenCaseStatusesMenu={isAllowToOpenCaseStatusesMenu}
            />

            {!isFeedView && caseId === myLegalCase?.id && (
              <>
                <Grid item xs={12} md={5} lg={4}>
                  <Documents
                    userFirebaseId={myLegalCase?.[isAttorney ? 'client' : 'attorney']?.firebaseUser}
                    caseId={caseId}
                    documents={myLegalCase?.files}
                    isClosedCase={isClosedCase}
                    isCaseOnConsultation={myLegalCase?.onConsultation}
                    isCaseForDependant={myLegalCase?.caseForDependant}
                    uploadDocumentHandler={uploadDocumentHandler}
                    deleteDocumentHandler={deleteDocumentHandler}
                    isInvitationCase={!!myLegalCase?.invitation?.id}
                  />
                </Grid>

                <Grid item xs={12} md={isDelayedNotificationCard ? 4 : 6}>
                  <MyNotes
                    fetchLegalCaseNotesHandler={fetchMyLegalCaseNotesHandler}
                    updateLegalCaseNoteHandler={updateMyLegalCaseNoteHandler}
                    deleteLegalCaseNoteHandler={deleteMyLegalCaseNoteHandler}
                    fetchMoreMyLegalCaseNotesHandler={fetchMoreMyLegalCaseNotesHandler}
                    getSelectedNote={getMySelectedNote}
                    createLegaCaseNoteHandler={createMyLegaCaseNoteHandler}
                    selectedNote={selectedNote}
                    caseNotes={myNotes?.caseNotes}
                    totalItems={myNotes?.totalItems}
                    isClosedCase={isClosedCase}
                    isCaseOnConsultation={myLegalCase?.onConsultation}
                    isAttorney={isAttorney}
                    caseId={caseId}
                    aesDecrypt={aesDecrypt}
                    aesEncrypt={aesEncrypt}
                  />
                </Grid>

                <Grid item xs={12} md={isDelayedNotificationCard ? 4 : 6}>
                  <Notes
                    isAllowToFetchNotes={
                      !isAttorney ||
                      (isAttorney && !myLegalCase?.onConsultation) ||
                      (isAttorney && !!myLegalCase.attorney && !myLegalCase?.onConsultation)
                    }
                    caseId={caseId}
                    isAttorney={isAttorney}
                    notes={notes?.caseNotes}
                    totalItems={notes?.totalItems}
                    isNotesLoading={notes?.isLoading}
                    fetchLegalCaseNotesHandler={fetchLegalCaseNotesHandler}
                    fetchMoreLegalCaseNotesHandler={fetchMoreLegalCaseNotesHandler}
                    aesDecrypt={aesDecrypt}
                  />
                </Grid>

                {isDelayedNotificationCard && (
                  <Grid item xs={12} md={4}>
                    <DelayedNotification
                      caseId={caseId}
                      getSelectedDelayedNotification={getSelectedDelayedNotification}
                      selectedDelayedNotification={delayedNotifications?.selectedNotification}
                      delayedNotifications={delayedNotifications?.notificationList}
                      fetchLegalCaseDelayedNotificationsHandler={fetchLegalCaseDelayedNotificationsHandler}
                      createLegaCaseDelayedNotificationHandler={createLegaCaseDelayedNotificationHandler}
                      deleteLegalCaseDelayedNotificationHandler={deleteLegalCaseDelayedNotificationHandler}
                      updateLegalCaseDelayedNotificationHandler={updateLegalCaseDelayedNotificationHandler}
                    />
                  </Grid>
                )}
                {!myLegalCase?.invitation?.id && <CaseEvents />}
              </>
            )}
          </>
        )}
      </Grid>

      <SideMenu isOpen={isEditFormMenuOpen} onClose={closeEditFormMenu} title="Edit case details" customWidth={400}>
        <CaseInfoEditContentForm
          caseCounty={myLegalCase?.county?.['@id']}
          caseLawType={myLegalCase?.caseLawType?.['@id']}
          caseState={myLegalCase?.state?.['@id']}
          description={myLegalCase?.description}
          dependantInfo={{
            dependantFirstName: myLegalCase?.dependantFirstName,
            dependantLastName: myLegalCase?.dependantLastName,
            dependantStateOfResidence: {
              id: myLegalCase?.dependantStateOfResidence?.['@id'],
              title: myLegalCase?.dependantStateOfResidence?.name,
            },
            dependantUnder18: myLegalCase?.dependantUnder18,
            isCaseForDependant: myLegalCase?.caseForDependant,
          }}
          caseCountryId={myLegalCase?.country?.['@id']}
          closeEditingModeHandler={closeEditFormMenu}
        />
      </SideMenu>

      <SideMenu isOpen={isViewAllMenuOpen} onClose={closeViewAllMenu} title="All case offers" customWidth={450}>
        <AllConsultationsOffersMenuContent
          consultationsOffers={consultations?.consultationList}
          approveLegalCaseOfferHandler={approveConsultationHandler}
          declineLegalCaseOfferHandler={declineConsultationHandler}
          totalItems={consultations?.totalItems}
          fetchMoreOffersHandler={getMoreConsultationsHandler}
          isLoading={isCaseOffersLoading}
        />
      </SideMenu>

      <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="top-right">
        {sortedCaseStatuses.map(status => (
          <PopoverMenuItem
            key={status['@id']}
            onClickHandler={onClickCaseStatusMenuItemHandler}
            title={status.title}
            itemId={status['@id']}
            isActive={status['@id'] === myLegalCase?.status?.['@id']}
          />
        ))}
      </MenuPopover>
    </DashboardLayout>
  );
};
