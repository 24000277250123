import { FC } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { InputAdornment } from '@mui/material';
import { Clear, EventOutlined } from '@mui/icons-material';
import { useBoolean } from 'features/common/hooks';
import { BaseDatePickerProps } from './types';
import { parseISO } from 'date-fns';

export const BaseDatePicker: FC<BaseDatePickerProps> = ({
  isError,
  value,
  slotProps,
  sx,
  onChange,
  onClickClearButtonHandler,
  onErrorHandler,
  ...rest
}) => {
  const [isOpen, onClickOpenIconHandler, onCloseHandler] = useBoolean(false);

  return (
    <DatePicker
      {...rest}
      onError={onErrorHandler}
      onClose={onCloseHandler}
      open={isOpen}
      slots={{
        inputAdornment: props => (
          <>
            {onClickClearButtonHandler ? (
              <InputAdornment
                {...props}
                disableTypography={!value}
                sx={{ cursor: value ? 'pointer' : 'default' }}
                onClick={onClickClearButtonHandler}
              >
                <Clear fontSize="medium" color={!value ? 'disabled' : 'action'} />
              </InputAdornment>
            ) : null}
            <InputAdornment {...props} sx={{ cursor: 'pointer' }} onClick={onClickOpenIconHandler}>
              <EventOutlined fontSize="medium" color="action" />
            </InputAdornment>
          </>
        ),
      }}
      slotProps={{
        mobilePaper: {
          sx: {
            '.MuiDateCalendar-root, .MuiYearCalendar-root': {
              width: 290,
            },
            '.MuiPickersToolbar-root, .MuiPickersLayout-toolbar': {
              p: 1,
            },
            '.MuiDialogActions-root': {
              justifyContent: 'center',
            },
            '.MuiClockPointer-thumb': {
              backgroundColor: theme => theme.palette.info.main,
              borderColor: theme => theme.palette.info.main,
            },
            '.MuiPickersArrowSwitcher-root': {
              top: 0,
            },
            mx: 1,
            minWidth: '290px !important',
            '.MuiClockPointer-root, .MuiClock-pin': {
              backgroundColor: theme => `${theme.palette.info.main}`,
            },
          },
        },
        layout: {
          sx: {
            '& .MuiPickersDay-today': {
              borderColor: ({ palette }) => palette.info.main,
            },
            '& .MuiPickersDay-root': {
              ':hover': {
                backgroundColor: ({ palette }) => palette.info.light,
                color: 'white !important',
              },
            },
            '& .Mui-selected': {
              backgroundColor: ({ palette }) => `${palette.info.main} !important`,
            },
          },
        },
        ...slotProps,
      }}
      sx={{
        ...(isError
          ? {
              '& .MuiOutlinedInput-notchedOutline, &:after': {
                borderColor: theme => `${theme.palette.error.main} !important`,
              },
              '& .Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline, &:after': {
                  borderColor: theme => `${theme.palette.error.main} !important`,
                  ':hover': {
                    borderColor: theme => `${theme.palette.error.main} !important`,
                  },
                },
              },

              '& .MuiInputLabel-root.Mui-focused': {
                color: theme => `${theme.palette.error.main} !important`,
              },
            }
          : {
              '& .Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline, &:after': {
                  borderColor: theme => `${theme.palette.info.main} !important`,
                },
              },

              '& .MuiInputLabel-root.Mui-focused': {
                color: theme => `${theme.palette.info.main} !important`,
              },
            }),
        ...sx,
      }}
      value={value}
      onChange={onChange}
    />
  );
};
