import { useState, useEffect, useCallback } from 'react';

export const useCountdown = (seconds: number) => {
  const [countdown, setCountdown] = useState(seconds);
  const [isFinished, setIsFinished] = useState(false);
  const [isRunning, setIsRunning] = useState(false);

  const startCountdown = useCallback(() => {
    setCountdown(seconds);
    setIsFinished(false);
    setIsRunning(true);
  }, [seconds]);

  useEffect(() => {
    let interval: number | NodeJS.Timer | null;

    if (isRunning) {
      interval = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
    }

    if (countdown === 0) {
      setIsFinished(true);
      setIsRunning(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [countdown, isRunning]);

  return { countdown, isFinished, startCountdown };
};
