import * as React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { MoreVert } from '@mui/icons-material';

type CategoryDropdownProps = {
  onAdd?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
};

export const CategoryDropdown = ({ onAdd, onEdit, onDelete }: CategoryDropdownProps) => {
  const options = React.useMemo(
    () => [
      { label: 'Add item', icon: <AddIcon sx={{ marginX: '4px' }} />, onClick: onAdd },
      { label: 'Edit category', icon: <EditIcon sx={{ marginX: '4px' }} />, onClick: onEdit },
      { label: 'Delete category', icon: <ClearIcon sx={{ marginX: '4px' }} />, onClick: onDelete },
    ],
    [onAdd, onEdit, onDelete]
  );
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (index: number, mainEvent: () => void) => {
    mainEvent();
    setOpen(false);
  };

  const handleToggle = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" sx={{ boxShadow: 0 }}>
        <MoreVert onClick={handleToggle} fontSize="small" />
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem sx={{ p: 1 }}>
                  {options.map((option, index) => (
                    <MenuItem key={option.label} onClick={() => handleMenuItemClick(index, option.onClick)}>
                      {option.icon}
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
