import { FC } from 'react';
import { Card, Divider, Fab, Stack } from '@mui/material';
import { Add } from '@mui/icons-material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { SideMenu, useBoolean } from 'features/common';
import { useAttorneyStatesLawTypes } from 'features/common/hooks';
import { Loader } from 'features/common/components/Loader';
import { StateLawTypeItem } from 'features/auth/components/signUp/SelectLawTypesStateStep/StateLawTypeItem';
import { StatesMenuContent } from 'features/auth/components/signUp/SelectLawTypesStateStep/StatesMenuContent';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { SuggestLawTypeContentMenu } from './SuggestLawTypeContentMenu';

export const AttorneyStatesLawTypes: FC = () => {
  const {
    isSelectStateMenuOpen,
    onCloseSelectLawTypeAndResetStateHandler,
    onClickLawTypeItemHandler,
    selectedAttorneyLawType,
    isLoading,
    attoreyProfileCaseLawTypes,
    setAttoreyProfileCaseLawTypes,
    countryStateList,
    isStateListLoading,
    onOpenDeleteCaseLawTypeDialogHandler,
    isDeleting,
    isDeleteCaseLawTypeDialogOpen,
    onClickDeleteAttorneyCaseLawTypeButton,
    onCloseDeleteCaseLawTypeDialogHandler,
  } = useAttorneyStatesLawTypes();

  const [isSuggestLawTypeMenuOpen, onOpenSuggestLawTypeMenuHandler, onCloseSuggestLawTypeMenuHandler] =
    useBoolean(false);

  return (
    <Card>
      <MDBox
        p={3}
        lineHeight={1}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <MDBox>
          <MDTypography variant="h5">Practice Area's</MDTypography>
        </MDBox>

        <Fab
          color="info"
          aria-label="add"
          size="medium"
          sx={{ mt: { xs: 2, sm: 0 } }}
          onClick={onOpenSuggestLawTypeMenuHandler}
        >
          <Add sx={{ width: 25, height: 25 }} />
        </Fab>
      </MDBox>
      <MDBox pt={1} pb={3} px={3}>
        {isLoading || isStateListLoading ? (
          <MDBox minHeight={200} justifyContent="center" alignItems="center" display="flex">
            <Loader />
          </MDBox>
        ) : attoreyProfileCaseLawTypes.length ? (
          <Stack>
            <Stack component="ul" divider={<Divider sx={{ my: '7px' }} />}>
              {attoreyProfileCaseLawTypes?.map(attorneyLawType => (
                <StateLawTypeItem
                  key={attorneyLawType?.['@id']}
                  lawTypeId={attorneyLawType?.['@id']}
                  lawTypeTitle={attorneyLawType?.caseLawType?.title}
                  states={attorneyLawType?.states}
                  isSelected={selectedAttorneyLawType?.['@id'] === attorneyLawType?.['@id']}
                  onClickLawTypeItemHandler={onClickLawTypeItemHandler}
                />
              ))}
            </Stack>
          </Stack>
        ) : (
          <Stack justifyContent="center" flex={1}>
            <MDTypography sx={{ mx: 'auto' }}>No practice area's were found...</MDTypography>
          </Stack>
        )}
      </MDBox>

      <SideMenu
        isOpen={isSelectStateMenuOpen}
        onClose={onCloseSelectLawTypeAndResetStateHandler}
        title={`${selectedAttorneyLawType?.caseLawType?.title} practice area states`}
        customWidth={450}
      >
        <StatesMenuContent
          selectedAttroneyLawTypeId={selectedAttorneyLawType?.['@id']}
          states={countryStateList}
          matchedStates={selectedAttorneyLawType?.states}
          onCloseMenuHandler={onCloseSelectLawTypeAndResetStateHandler}
          setAttoreyProfileCaseLawTypes={setAttoreyProfileCaseLawTypes}
          onOpenDeleteCaseLawTypeDialogHandler={onOpenDeleteCaseLawTypeDialogHandler}
          isDeleting={isDeleting}
        />
      </SideMenu>

      <SideMenu
        isOpen={isSuggestLawTypeMenuOpen}
        onClose={onCloseSuggestLawTypeMenuHandler}
        title="Choose practice area's"
        customWidth={450}
      >
        <SuggestLawTypeContentMenu
          setAttoreyProfileCaseLawTypes={setAttoreyProfileCaseLawTypes}
          onCloseMenuHandler={onCloseSuggestLawTypeMenuHandler}
        />
      </SideMenu>

      <DialogWindow
        onCloseDialogHandler={onCloseDeleteCaseLawTypeDialogHandler}
        onApproveDialogHandler={onClickDeleteAttorneyCaseLawTypeButton}
        isDialogOpen={isDeleteCaseLawTypeDialogOpen}
        dialogTitle="Are you sure you want to delete practice area?"
      />
    </Card>
  );
};
