import { FC, memo, useCallback, useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, CircularProgress, Stack } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { SideMenu, useBoolean } from 'features/common';
import { Pagination } from 'features/case-law-types';
import { GetCaseDetailsNotes } from 'features/case-details/CaseDetailsApiService';
import { NotesCard } from './NotesCard';
import { AllNotesMenuContent } from './AllNotesMenuContent';
import { NOTES_PER_PAGE } from './MyNotes';

type NotesTestProps = {
  caseId: string;
  isAttorney: boolean;
  fetchLegalCaseNotesHandler: (caseId: string, params: Pagination) => Promise<void>;
  notes: GetCaseDetailsNotes[];
  totalItems: number | null;
  fetchMoreLegalCaseNotesHandler: (params: Pagination) => Promise<void>;
  isNotesLoading: boolean;
  isAllowToFetchNotes: boolean;
  aesDecrypt: (payload: string) => string;
};

export const Notes: FC<NotesTestProps> = memo(
  ({
    fetchLegalCaseNotesHandler,
    fetchMoreLegalCaseNotesHandler,
    isAllowToFetchNotes,
    isNotesLoading,
    notes,
    totalItems,
    caseId,
    isAttorney,
    aesDecrypt,
  }) => {
    const [isAllNotesLoading, setIsAllNotesLoading] = useState(false);
    const [isViewAllModalOpen, openViewAllModal, closeViewAllModal] = useBoolean(false);

    const [currentPage, setCurrentPage] = useState(1);
    const pages: Readonly<number> = Math.ceil(totalItems / NOTES_PER_PAGE);

    const onViewAllButtonClickHandler = useCallback(async () => {
      openViewAllModal();
      setIsAllNotesLoading(true);
      await fetchLegalCaseNotesHandler(caseId, { page: 1, itemsPerPage: NOTES_PER_PAGE });
      setIsAllNotesLoading(false);
    }, [caseId]);

    const onCloseViewAllModalWindowHandler = useCallback(() => {
      closeViewAllModal();
      setCurrentPage(1);
    }, []);

    useEffect(() => {
      if (isAllowToFetchNotes) {
        fetchLegalCaseNotesHandler(caseId, { page: 1, itemsPerPage: 3 });
      }
    }, [caseId]);

    const title = isAttorney ? 'Client' : 'Attorney';

    return (
      <Card sx={{ width: 1, height: 1 }}>
        <CardHeader sx={{ px: 2 }} title={`${title} Notes`} />
        {isNotesLoading ? (
          <Stack alignItems="center" flex={1} justifyContent="center" minHeight={200}>
            <CircularProgress />
          </Stack>
        ) : !notes?.length ? (
          <Stack flex={1} justifyContent="center" alignItems="center" minHeight={200}>
            <MDTypography>No notes were found...</MDTypography>
          </Stack>
        ) : (
          <CardContent sx={{ px: 2, pb: '10px !important' }}>
            <Stack
              spacing={2}
              sx={{
                pb: 1,
                pr: 0.5,
                maxHeight: 400,
                overflowY: 'auto',
              }}
            >
              {notes?.slice(0, 3).map(note => (
                <NotesCard
                  key={note?.['@id']}
                  visible={note?.visible}
                  title={note?.title}
                  id={note?.['@id']}
                  createdAt={note?.createdAt}
                  description={aesDecrypt(note?.description) || ''}
                  isNotMyNote
                  isAttorneyView={isAttorney}
                />
              ))}
            </Stack>

            {totalItems > 3 && (
              <Stack alignItems="center" pt={1}>
                <MDButton
                  variant="outlined"
                  color="info"
                  onClick={onViewAllButtonClickHandler}
                  sx={{
                    width: { xs: 1, sm: 120 },
                    textTransform: 'none',
                  }}
                >
                  View all
                </MDButton>
              </Stack>
            )}
          </CardContent>
        )}
        <SideMenu
          isOpen={isViewAllModalOpen}
          onClose={onCloseViewAllModalWindowHandler}
          title={`All ${title} notes`}
          customWidth={450}
        >
          <AllNotesMenuContent
            caseNotes={notes}
            isFetchMore={currentPage < pages}
            currentPage={currentPage}
            fetchMoreLegalCaseNotesHandler={fetchMoreLegalCaseNotesHandler}
            setCurrentPage={setCurrentPage}
            isLoading={isAllNotesLoading}
            isNotMyNote
            isAttorneyView={isAttorney}
            aesDecrypt={aesDecrypt}
          />
        </SideMenu>
      </Card>
    );
  }
);
