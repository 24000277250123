import { AxiosError } from 'axios';
import { UseFormSetError } from 'react-hook-form';
import { PAYMENT_ERROR_MESSAGE } from './paymentRequiredErrorHandler';
import { notice, ToastType } from '../modules';

export type BackendError = {
  '@context': string;
  '@type': string;
  'hydra:description': string;
  'hydra:title': string;
  violations?: {
    code: string;
    message: string;
    propertyPath: string;
  }[];
};

type BaseBackendErrorHandlerConfig =
  | {
      customErrorHandler?: (error: unknown) => void;
      formError?: { setError?: UseFormSetError<unknown>; formData?: object };
    }
  | undefined;

export const baseBackendErrorHandler = (error: unknown, config?: BaseBackendErrorHandlerConfig) => {
  const { customErrorHandler, formError } = config || {};
  const { setError, formData } = formError || { setError: () => undefined, formData: null };

  if (customErrorHandler) return customErrorHandler(error);

  if (error instanceof AxiosError) {
    if (error?.response?.status === 402) {
      notice(ToastType.ERROR, PAYMENT_ERROR_MESSAGE);
      return;
    }
    const errorResponse = error.response.data as BackendError;

    if (errorResponse && 'violations' in errorResponse) {
      return errorResponse.violations.forEach(({ message, propertyPath }) => {
        if (formData && setError && propertyPath.includes('.')) {
          const formattedPath = propertyPath.split('.').at(-1);
          setError(formattedPath as keyof typeof formData, { message });
          return;
        }
        if (formData && setError && propertyPath in formData) {
          setError(propertyPath as keyof typeof formData, { message });
          return;
        } else {
          notice(ToastType.ERROR, message);
        }
      });
    }
  }

  return notice(ToastType.ERROR, 'Something went wrong!');
};
