import { GetMyClientsParams, GetMyClientsResponse, myClientsApiService } from 'features/my-clients/MyClientsApiService';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders/paymentRequiredErrorHandler';
import { GetMyLegalCasesResponse } from 'features/my-legal-cases/MyLegalCasesApiService';
import { createStore } from '../createStore';

export type ClientsState = {
  clients: GetMyClientsResponse[];
  totalItems: number | null;
  invitations: GetMyLegalCasesResponse[];
  invitationsTotalItems: number | null;
};

export type ClientsActions = {
  fetchMyClients: (tabValue: number, params?: GetMyClientsParams) => Promise<void>;
  fetchMoreClients: (tabValue: number, params: GetMyClientsParams) => Promise<void>;
  fetchInvitations: (params?: GetMyClientsParams) => Promise<void>;
  fetchMoreInvitations: (params: GetMyClientsParams) => Promise<void>;
};

const { getMyClientsClosedCases, getMyClientsOpenedCases, getInvitations } = myClientsApiService();

const initialState: ClientsState = {
  clients: [],
  totalItems: null,
  invitationsTotalItems: null,
  invitations: [],
};

export const useMyClients = createStore<ClientsState & ClientsActions>(
  set => ({
    ...initialState,
    fetchMyClients: async (tabValue, params) => {
      try {
        const { data } = tabValue === 1 ? await getMyClientsClosedCases(params) : await getMyClientsOpenedCases(params);

        set({ clients: data['hydra:member'], totalItems: data['hydra:totalItems'] });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch clients, please try again!');
        console.error(error);
      }
    },
    fetchMoreClients: async (tabValue, params) => {
      try {
        const { data } = tabValue === 1 ? await getMyClientsClosedCases(params) : await getMyClientsOpenedCases(params);

        set(state => ({ clients: [...state.clients, ...data['hydra:member']] }));
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch clients, please try again!');
        console.error(error);
      }
    },
    fetchInvitations: async params => {
      try {
        const { data } = await getInvitations(params);

        set({ invitations: data['hydra:member'], invitationsTotalItems: data['hydra:totalItems'] });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch invitations, please try again!');
        console.error(error);
      }
    },
    fetchMoreInvitations: async params => {
      try {
        const { data } = await getInvitations(params);

        set(state => ({ invitations: [...state.invitations, ...data['hydra:member']] }));
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch invitations, please try again!');
        console.error(error);
      }
    },
  }),
  'myClients',
  initialState
);
