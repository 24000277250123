import { FC } from 'react';
import { Stack } from '@mui/material';
import MDTypography from 'components/MDTypography';

type DependantInfoItemProps = {
  title: string;
  titleValue: string;
};

export const DependantInfoItem: FC<DependantInfoItemProps> = ({ title, titleValue }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        borderBottom: theme => `1px solid ${theme.borders.borderColor}`,
        py: 1,
      }}
    >
      <MDTypography variant="subtitle2" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="subtitle2">{titleValue}</MDTypography>
    </Stack>
  );
};
