import { useState } from 'react';
import { ToastType, notice } from '../modules';

type CopiedValue = string | null;
export type CopyFn = (text: string) => Promise<boolean>;

export function useCopyToClipboard() {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = async text => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      notice(ToastType.SUCCESS, 'Successfully copied');
      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      setCopiedText(null);
      return false;
    }
  };

  return { copiedText, copy };
}
