import { useCallback, useState } from 'react';

type UsePaginationProps = {
  totalItems?: number;
  itemsPerPage?: number;
  itemsLength: number;
};

export const usePagination = ({ totalItems, itemsPerPage = 10, itemsLength }: UsePaginationProps) => {
  const [entriesPerPage, setEntriesPerPage] = useState(itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(Math.ceil(totalItems / itemsPerPage));

  const isLastItem = itemsLength === 1;

  const onChangeEntriesPerPageHandler = useCallback((value: number) => {
    setCurrentPage(1);
    setEntriesPerPage(value);
  }, []);

  const onChangePageHandler = useCallback((page: number) => setCurrentPage(page), []);

  const onChangeTotalPagesAmount = useCallback((itemsPerPageValue: number, currentItemsValue: number) => {
    if (!currentItemsValue) return;
    setTotalPages(Math.ceil(currentItemsValue / itemsPerPageValue));
  }, []);

  const onDeleteItem = useCallback((): number => {
    if (isLastItem && totalItems === 1) {
      const nextPage = 1;
      setCurrentPage(nextPage);
      return nextPage;
    }

    if (isLastItem && totalItems !== 1) {
      const nextPage = currentPage > 1 ? currentPage - 1 : currentPage;
      setCurrentPage(nextPage);
      return nextPage;
    }

    return currentPage;
  }, [totalItems, isLastItem]);

  return {
    currentPage,
    totalPages,
    entriesPerPage,
    isLastItem,
    setCurrentPage,
    onDeleteItem,
    setEntriesPerPage,
    onChangeEntriesPerPageHandler,
    onChangeTotalPagesAmount,
    onChangePageHandler,
  };
};
