import { FC, useEffect, useState } from 'react';
import { Chip, Divider, Rating, Stack } from '@mui/material';
import {
  LocalPhoneOutlined,
  EmailOutlined,
  MapOutlined,
  LanguageOutlined,
  BeenhereOutlined,
  AccessTimeOutlined,
} from '@mui/icons-material';
import { CompanyResponse, CompanyReview, CompanyUser, createCompanyService } from 'features/company-list';
import { ToastType, notice } from 'features/common';
import { Loader } from 'features/common/components/Loader';
import MDAvatar from 'components/MDAvatar';
import MDTypography from 'components/MDTypography';
import { getAvatarCharacters } from 'features/common/helpers/utilities';
import { dateToCustomFormat, formatDate } from 'features/common/helpers/date';
import { getWorkingHours } from 'features/case-details/components/CaseInfo/UserOverviewContent/helpers';
import { InformationItem } from './InformationItem';

type CompanyInfoSideMenuContentProps = {
  companyId: string;
};

export const CompanyInfoSideMenuContent: FC<CompanyInfoSideMenuContentProps> = ({ companyId }) => {
  const { getCompanyById, getCompanyReviews, getCompanyUsers } = createCompanyService();

  const id = companyId?.replace('/companies/', '');
  const [companyInfo, setCompanyInfo] = useState<null | CompanyResponse>(null);
  const [companyReviews, setCompanyReviews] = useState<CompanyReview[]>([]);
  const [companyUsers, setCompanyUsers] = useState<CompanyUser[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const onGetCompanyInfoHandler = async () => {
    try {
      const { data } = await getCompanyById(id);
      setCompanyInfo(data);

      const { data: companyReviews } = await getCompanyReviews(id, { active: true, page: 1, itemsPerPage: 30 });
      setCompanyReviews(companyReviews['hydra:member']);

      const { data: companyUsers } = await getCompanyUsers(id, { page: 1, itemsPerPage: 999 });

      setCompanyUsers(companyUsers['hydra:member']);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      notice(ToastType.ERROR, 'Failed to fetch company profile, please try again!');
    }
  };

  useEffect(() => {
    onGetCompanyInfoHandler();
  }, []);

  const workingHoursArray = getWorkingHours(companyInfo?.workingHours);

  return (
    <Stack flex={1} sx={{ py: 2 }}>
      {isLoading ? (
        <Loader />
      ) : (
        <Stack spacing={1}>
          <Stack>
            <Stack direction="row" spacing={1}>
              <MDAvatar
                size="md"
                src={companyInfo?.logo?.publicUrl}
                alt="company logo"
                bgColor={companyInfo?.logo?.publicUrl ? 'transparent' : 'info'}
              >
                <BeenhereOutlined fontSize="medium" />
              </MDAvatar>

              <Stack>
                <MDTypography>{companyInfo?.fullName}</MDTypography>
                <MDTypography variant="body2">Rating {companyInfo?.rating || 0}</MDTypography>
              </Stack>
            </Stack>
            <MDTypography sx={{ whiteSpace: 'initial' }}>{companyInfo?.description}</MDTypography>
          </Stack>

          <Stack spacing={1}>
            <MDTypography>Information</MDTypography>
            <InformationItem
              iconElement={<LocalPhoneOutlined fontSize="medium" />}
              title="Phone number"
              value={companyInfo?.phone}
            />
            <InformationItem
              iconElement={<EmailOutlined fontSize="medium" />}
              title="Email"
              value={companyInfo?.email}
            />
            <InformationItem
              iconElement={<LanguageOutlined fontSize="medium" />}
              title="Site"
              value={companyInfo?.site}
            />
            <InformationItem
              iconElement={<MapOutlined fontSize="medium" />}
              title="Adress"
              value={`${companyInfo?.headquarterAddress.zipCode}, ${companyInfo?.headquarterAddress?.town}, ${companyInfo?.headquarterAddress?.addressLine1}, ${companyInfo?.headquarterAddress?.addressLine2}`}
            />
            <Stack direction="row" alignItems="flex-start" width={1} spacing={1} justifyContent="space-between">
              <Stack alignItems="center" spacing={0.5} direction="row">
                <AccessTimeOutlined fontSize="medium" />
                <MDTypography variant="body2">Working hours</MDTypography>
              </Stack>

              <Stack alignItems="end">
                {workingHoursArray?.map(el => (
                  <Stack key={el.day} spacing={1} direction="row" alignItems="center" justifyContent="flex-start">
                    <MDTypography variant="button" sx={{ fontWeight: 500 }}>
                      {dateToCustomFormat(el.fromValue, 'h:mm a')}
                    </MDTypography>
                    <MDTypography variant="button" sx={{ fontWeight: 500 }}>
                      -
                    </MDTypography>
                    <MDTypography variant="button" sx={{ fontWeight: 500 }}>
                      {dateToCustomFormat(el.toValue, 'h:mm a')}
                    </MDTypography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Stack>

          {companyInfo?.caseLawTypes?.length ? (
            <Stack>
              <MDTypography fontWeight="medium">Law types</MDTypography>
              <Stack direction="row" flexWrap="wrap" gap="5px" mb={1}>
                {companyInfo.caseLawTypes?.map(lawType => (
                  <Chip key={lawType['@id']} variant="filled" size="medium" label={lawType?.title} />
                ))}
              </Stack>
            </Stack>
          ) : null}

          {companyUsers?.length ? (
            <Stack>
              <MDTypography fontWeight="medium">Attorneys</MDTypography>

              <Stack spacing={1}>
                {companyUsers?.map(user => (
                  <Stack key={user?.['@id']} direction="row" alignItems="center" spacing={1}>
                    <MDAvatar src={user?.user?.logo?.publicUrl} alt="user logo" bgColor="info">
                      {getAvatarCharacters(user?.user?.userProfile?.firstName, user?.user?.userProfile?.lastName)}
                    </MDAvatar>
                    <Stack>
                      <MDTypography variant="body2" sx={{ fontWeight: 500 }}>
                        {user?.user?.userProfile?.firstName} {user?.user?.userProfile?.lastName}
                      </MDTypography>
                      <MDTypography variant="body2">{user?.user?.rating || 0} Rating</MDTypography>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          ) : null}

          {companyReviews?.length ? (
            <Stack pb={1}>
              <MDTypography fontWeight="medium">Rating</MDTypography>
              <Stack spacing={1} divider={<Divider orientation="horizontal" />}>
                {companyReviews.map(rating => (
                  <Stack key={rating?.['@id']}>
                    <Stack direction="row" alignItems="flex-start" spacing={1.5} mb={0.5}>
                      <MDAvatar
                        src={rating?.createdBy?.logo?.publicUrl}
                        alt="user avatar"
                        bgColor="info"
                        sx={{
                          '& img': {
                            height: 1,
                          },
                        }}
                      >
                        {getAvatarCharacters(
                          rating?.createdBy?.userProfile?.firstName,
                          rating?.createdBy?.userProfile?.lastName
                        )}
                      </MDAvatar>
                      <Stack flex={1}>
                        <MDTypography
                          variant="button"
                          fontWeight="medium"
                        >{`${rating?.createdBy?.userProfile?.firstName} ${rating?.createdBy?.userProfile?.lastName}`}</MDTypography>
                        <Rating
                          value={rating.rating}
                          precision={0.1}
                          readOnly
                          sx={{ color: theme => theme.palette.grey[700] }}
                        />
                      </Stack>
                      <MDTypography variant="body2" fontSize={14}>
                        {rating?.createdAt ? formatDate(rating?.createdAt) : '-'}
                      </MDTypography>
                    </Stack>

                    <MDTypography variant="button">{rating?.body}</MDTypography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      )}
    </Stack>
  );
};
