import { FC, useEffect } from 'react';
import { CircularProgress, Grid, Stack } from '@mui/material';
import { DashboardLayout, SideMenu } from 'features/common';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import { FiltersBar, LegalCaseFeedCard } from './components';
import { FiltersMenuContent } from './components/FiltersBar/FIltersMenuContent';
import { useLegalCasesFeedState } from './hooks';
import { ITEMS_PER_PAGE } from './LegalCasesFeedApiService';

export const LegalCasesFeedTemplate: FC = () => {
  const {
    currentPage,
    debounceValue,
    isFetchMoreLoading,
    isFiltersMenuOpen,
    isLoading,
    isOnScreen,
    legalCasesFeed,
    pages,
    lastElementRef,
    filtersData,
    filtersCount,
    onOpenFiltersMenuHandler,
    setCurrentPage,
    fetchLegalCasesFeedHandler,
    fetchMoreLegalCasesFeedHandler,
    onCloseFiltersMenuHandler,
    onChangeSearchInputHandler,
  } = useLegalCasesFeedState();

  useEffect(() => {
    fetchLegalCasesFeedHandler({
      page: currentPage,
      itemsPerPage: ITEMS_PER_PAGE,
      search: debounceValue,
      'status.type[]': ['todo', 'in_progress'],
      ...filtersData,
    });
  }, [debounceValue, filtersData]);

  useEffect(() => {
    if (isOnScreen && currentPage < pages) {
      const nextPage = currentPage + 1;
      fetchMoreLegalCasesFeedHandler({
        page: nextPage,
        itemsPerPage: ITEMS_PER_PAGE,
        search: debounceValue,
        ...filtersData,
      });
      setCurrentPage(nextPage);
    }
  }, [isOnScreen]);

  return (
    <DashboardLayout>
      <FiltersBar
        onOpenFiltersMenuHandler={onOpenFiltersMenuHandler}
        onChangeSearchInputHandler={onChangeSearchInputHandler}
        filtersCount={filtersCount}
      />
      {isLoading && (
        <Stack flex={1} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      {!isLoading && !legalCasesFeed.length && (
        <Stack flex={1} justifyContent="center" alignItems="center">
          <MDTypography>No cases were found...</MDTypography>
        </Stack>
      )}

      {!isLoading && (
        <>
          <Grid container spacing={2}>
            {legalCasesFeed?.map((caseFeed, index) => (
              <Grid item key={caseFeed.id} xs={12} md={6} lg={4}>
                <LegalCaseFeedCard
                  lastElementRef={index === legalCasesFeed.length - 1 ? lastElementRef : null}
                  caseLawType={caseFeed?.caseLawType?.title}
                  caseNumber={caseFeed?.caseNumber}
                  firstName={caseFeed?.client?.userProfile?.firstName}
                  lastName={caseFeed?.client?.userProfile?.lastName}
                  avatarURL={caseFeed?.client?.logo?.publicUrl}
                  description={caseFeed?.description}
                  createdAt={caseFeed?.createdAt}
                  county={caseFeed?.county?.shortName}
                  state={caseFeed?.state?.name}
                  country={caseFeed?.country?.id}
                  caseId={caseFeed.id}
                  userFirebaseId={caseFeed?.client?.firebaseUser}
                />
              </Grid>
            ))}
          </Grid>
          {isFetchMoreLoading && (
            <MDBox sx={{ position: 'absolute', bottom: 25, left: '50%', transform: 'translateY(-50%)' }}>
              <CircularProgress />
            </MDBox>
          )}
        </>
      )}

      <SideMenu isOpen={isFiltersMenuOpen} onClose={onCloseFiltersMenuHandler} title="Filters" customWidth={400}>
        <FiltersMenuContent onCloseFiltersMenuHandler={onCloseFiltersMenuHandler} />
      </SideMenu>
    </DashboardLayout>
  );
};
