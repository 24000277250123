/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { FC } from 'react';
import Card from '@mui/material/Card';
import { Stack } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { OS } from '../UrgentVersionsApiService';

type ComplexStatisticsCardProps = {
  color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'light' | 'dark';
  os: OS;
  version: string;
  icon: React.ReactNode;
};

export const UrgentVersionCard: FC<ComplexStatisticsCardProps> = ({ color, os, version, icon }) => {
  return (
    <Card sx={{ px: 2 }}>
      <Stack direction="row" justifyContent="space-between" pt={1}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === 'light' ? 'dark' : 'white'}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          minWidth={62}
          height={62}
          mt={{ xs: -2, sm: -3 }}
          mr={1}
        >
          {icon}
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25} mb={1}>
          <MDTypography variant="button" fontWeight="light" color="text" textTransform="uppercase">
            {os}
          </MDTypography>
          <MDTypography variant="h4" noWrap maxWidth={145}>
            {version}
          </MDTypography>
        </MDBox>
      </Stack>
    </Card>
  );
};
