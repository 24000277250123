import MDBox from 'components/MDBox';
import { FC } from 'react';
import { ReviewAvatarCell } from './ReviewAvatarCell';
import MDTypography from 'components/MDTypography';
import { Stack } from '@mui/material';
import { ROLES } from 'features/common';
import { getAvatarCharacters } from 'features/common/helpers/utilities';
import { UserData } from './reviewTable.config';

type FeedbackCellProps = Omit<UserData, 'roles'> & {
  role: string;
  body?: string;
};
export const FeedbackCell: FC<FeedbackCellProps> = ({ firstName, lastName, src, companyName, body, role }) => {
  const avatarCharacters = getAvatarCharacters(firstName, lastName);
  return (
    <MDBox>
      <Stack direction="row" alignItems="center" mb={1}>
        <ReviewAvatarCell image={src} avatarCharacters={avatarCharacters} />

        <Stack spacing={1}>
          <MDTypography variant="button" fontWeight="medium">
            {`${firstName} ${lastName}`}
          </MDTypography>
          <Stack direction="row" spacing={0.4}>
            <MDTypography variant="caption" fontWeight="medium">
              {role}
            </MDTypography>
            {role === ROLES.ROLE_ATTORNEY && companyName && (
              <MDTypography variant="caption" fontWeight="medium">
                at {companyName}
              </MDTypography>
            )}
          </Stack>
        </Stack>
      </Stack>
      {body && (
        <MDTypography fontSize={14} maxWidth="475px" minWidth="100px">
          {body}
        </MDTypography>
      )}
    </MDBox>
  );
};
