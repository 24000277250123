import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { BaseFormField, useUser } from 'features/common';
import { CREATE_CASE_FORM_STEPS, useCreateCase } from 'features/store';
import MDButton from 'components/MDButton';
import { ClientSchema, clientFormSchema } from './form.config';

export const ClientInformation: FC = () => {
  const { backendUser } = useUser();

  const { saveFormDataHandler, activeStep } = useCreateCase(state => ({
    clientInformationData: state.clientData,
    activeStep: state.activeStep,
    saveFormDataHandler: state.setData,
  }));

  const { register, handleSubmit } = useForm<ClientSchema>({
    resolver: yupResolver(clientFormSchema),
    defaultValues: {
      firstName: backendUser?.userProfile?.firstName,
      lastName: backendUser?.userProfile?.lastName,
      state: backendUser?.userProfile?.state?.['@id'],
      country: backendUser?.userProfile?.country?.['@id'],
      email: backendUser?.email,
    },
  });

  const onFormSubmitHandler = handleSubmit(formData => {
    return saveFormDataHandler({ step: activeStep, data: formData, activeStep: CREATE_CASE_FORM_STEPS.CASE_INFO });
  });

  return (
    <form onSubmit={onFormSubmitHandler}>
      <Card>
        <CardHeader title="Client Information" />
        <CardContent>
          <Stack direction={{ xs: 'column', sm: 'row' }} gap={{ xs: 0, sm: 3 }} width={1}>
            <Stack width={1}>
              <BaseFormField
                formInputProps={{
                  ...register('email'),
                  type: 'text',
                  label: 'Email',
                  fullWidth: true,
                  disabled: true,
                  autoComplete: 'new-email',
                }}
              />
              <BaseFormField
                formInputProps={{
                  ...register('firstName'),
                  type: 'text',
                  label: 'First name',
                  fullWidth: true,
                  disabled: true,
                }}
              />
              <BaseFormField
                formInputProps={{
                  ...register('lastName'),
                  type: 'text',
                  label: 'Last name',
                  fullWidth: true,
                  disabled: true,
                }}
              />
            </Stack>
            <Stack width={1}>
              <BaseFormField
                formInputProps={{
                  label: 'Country',
                  value: backendUser?.userProfile?.country?.name,
                  InputLabelProps: { shrink: true },
                  fullWidth: true,
                  disabled: true,
                }}
              />

              <BaseFormField
                formInputProps={{
                  value: backendUser?.userProfile?.state?.name,
                  label: 'State',
                  fullWidth: true,
                  InputLabelProps: { shrink: true },
                  disabled: true,
                }}
              />
            </Stack>
          </Stack>
          <Stack alignItems="flex-end">
            <MDButton type="submit" variant="gradient" color="dark">
              Next
            </MDButton>
          </Stack>
        </CardContent>
      </Card>
    </form>
  );
};
