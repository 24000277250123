import { Controller, Path, UseFormReturn } from 'react-hook-form';
import { Stack, Switch } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { BaseTimePickerField } from 'features/common/components/BaseTimePickerField';

type WorkingHoursFormFieldProps<T> = Pick<
  UseFormReturn<T>,
  'watch' | 'clearErrors' | 'control' | 'register' | 'formState' | 'setError'
> & {
  watchFromTimeInputValue: Path<T>;
  watchSwitcherValue: Path<T>;
  watchToTimeInputValue: Path<T>;
  switchTitle: string;
  onSetSwitcherValue: (
    checked: boolean,
    switcherFieldName: Path<T>,
    fromFieldName: Path<T>,
    toFieldName: Path<T>
  ) => void;
  isDisabled?: boolean;
};

export function WorkingHoursFormField<T>({
  clearErrors,
  control,
  watchFromTimeInputValue,
  watchSwitcherValue,
  watchToTimeInputValue,
  switchTitle,
  watch,
  register,
  setError,
  formState,
  onSetSwitcherValue,
  isDisabled,
}: WorkingHoursFormFieldProps<T>) {
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 1, sm: 2 }}
      alignItems="center"
      sx={{ justifyContent: { xs: 'space-between', sm: 'flex-start' }, mb: 2.8 }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{
          minWidth: 150,
          width: { xs: 1, sm: 'auto' },
          justifyContent: { xs: 'space-between', sm: 'flex-start' },
        }}
      >
        <MDTypography variant="button" color="text" sx={{ maxWidth: 100, width: 1 }}>
          {switchTitle}
        </MDTypography>

        <Switch
          {...register(watchSwitcherValue)}
          checked={!!watch(watchSwitcherValue)}
          onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
            onSetSwitcherValue(checked, watchSwitcherValue, watchFromTimeInputValue, watchToTimeInputValue)
          }
          sx={{
            ...(isDisabled && {
              pointerEvents: 'none',
              cursor: 'initial',
            }),
          }}
        />
      </Stack>
      {watch(watchSwitcherValue) && (
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          sx={{
            width: 1,
          }}
          spacing={{ xs: 3.2, sm: 3 }}
        >
          <Stack position="relative" width={1}>
            <Controller
              name={watchFromTimeInputValue}
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <BaseTimePickerField
                    field={field}
                    clearErrors={clearErrors}
                    setError={setError}
                    error={error}
                    watch={watch}
                    watchSwitcherValue={watchSwitcherValue}
                    watchTimeInputValue={watchFromTimeInputValue}
                    label="From"
                    isDisabled={isDisabled}
                  />
                );
              }}
            />
            {formState.errors[watchFromTimeInputValue]?.message && (
              <MDTypography
                variant="body2"
                fontSize="10px"
                color="error"
                sx={{ position: 'absolute', bottom: '-18px', left: 5 }}
              >
                {String(formState?.errors?.[watchFromTimeInputValue]?.message || '')}
              </MDTypography>
            )}
          </Stack>
          <Stack position="relative" width={1}>
            <Controller
              name={watchToTimeInputValue}
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <BaseTimePickerField
                    field={field}
                    clearErrors={clearErrors}
                    setError={setError}
                    error={error}
                    watch={watch}
                    watchSwitcherValue={watchSwitcherValue}
                    watchTimeInputValue={watchToTimeInputValue}
                    label="To"
                    isDisabled={isDisabled}
                  />
                );
              }}
            />
            {formState.errors[watchToTimeInputValue]?.message && (
              <MDTypography
                variant="body2"
                fontSize="10px"
                color="error"
                sx={{ position: 'absolute', bottom: '-17px', left: 5 }}
              >
                {String(formState?.errors?.[watchToTimeInputValue]?.message || '')}
              </MDTypography>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
