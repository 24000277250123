import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { BaseFormField } from 'features/common';
import { Note } from 'features/my-legal-cases/components/CreateLegalCase/form.config';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { NoteFormProps } from 'features/case-details/types';
import { editNoteFormValidationSchema } from './editNoteForm.config';

export const NoteForm: FC<NoteFormProps> = ({
  updateLegalCaseNoteHandler,
  noteBody,
  noteTitle,
  isVisible,
  isCreateNoteMode,
  isAttorney,
  closeEditMenu,
  createLegaCaseNoteHandler,
  aesEncrypt,
  aecDecrypt,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isDirty },
    watch,
    reset,
  } = useForm<Note>({
    resolver: yupResolver(editNoteFormValidationSchema),
    mode: 'onTouched',
    values: {
      noteBody: isCreateNoteMode ? '' : aecDecrypt(noteBody),
      noteTitle: isCreateNoteMode ? '' : noteTitle,
      isVisible: isCreateNoteMode ? false : isVisible,
    },
  });

  const isSubmitButtonDisabled = !isValid || isSubmitting || !isDirty;
  const onSubmitFormHandler = handleSubmit(async formData => {
    const payload = {
      description: aesEncrypt(formData.noteBody),
      title: formData.noteTitle,
      visible: formData.isVisible,
    };

    if (isCreateNoteMode) {
      await createLegaCaseNoteHandler(payload);
    } else {
      await updateLegalCaseNoteHandler(payload);
    }
    closeEditMenu();
    reset();
  });

  return (
    <MDBox py={4} display="flex" flexDirection="column" height={1} justifyContent="center" alignItems="center">
      <MDBox component="form" width={1} height={1} display="flex" flexDirection="column" onSubmit={onSubmitFormHandler}>
        <Stack spacing={1} width={1} flex={1}>
          <BaseFormField
            formInputProps={{
              ...register('noteTitle'),
              type: 'text',
              label: 'Title',
              fullWidth: true,
              InputLabelProps: { shrink: true },
            }}
            errorValue={errors['noteTitle']?.message}
          />
          <BaseFormField
            formInputProps={{
              ...register('noteBody'),
              type: 'text',
              label: 'Note',
              fullWidth: true,
              multiline: true,
              rows: 4,
              InputLabelProps: { shrink: true },
            }}
            errorValue={errors?.noteBody?.message}
          />
          <Stack direction="row" justifyContent="space-between">
            <FormControlLabel
              control={<Checkbox {...register('isVisible')} checked={watch('isVisible')} />}
              sx={{
                display: 'flex',
                mt: '0 !important',
              }}
              label={
                <MDTypography variant="button" color="text">
                  {`Visible to the ${isAttorney ? 'client' : 'attorney'}`}
                </MDTypography>
              }
            />
          </Stack>
        </Stack>
        <MDButton
          type="submit"
          variant="gradient"
          color="info"
          isLoading={isSubmitting}
          disabled={isSubmitButtonDisabled}
          fullWidth
          sx={{
            width: { xs: 1, sm: 'auto' },
            textTransform: 'none',
          }}
        >
          {isCreateNoteMode ? 'Create' : 'Save'}
        </MDButton>
      </MDBox>
    </MDBox>
  );
};
