import { useRef, useState } from 'react';
import { useAppStatisticContextState } from 'features/contexts/AppStatisticsContextProvider';
import { useIsOnScreen } from 'features/common/hooks/useIsOnScreen';
import { useCreateCase, useMyClients } from 'features/store';
import { ROUTES } from 'features/common/variables/routes';
import { useBoolean, useRouter, useUser } from 'features/common';
import { ITEMS_PER_PAGE, fetchParams } from '../MyClientsApiService';
import { useSubscriptionModalsContextState } from 'features/contexts/SubscriptionModalsContextProvider';

export const useMyClientsState = () => {
  const {
    clients,
    fetchMyClients,
    totalItems,
    fetchMoreClients,
    fetchInvitations,
    invitations,
    invitationsTotalItems,
    fetchMoreInvitations,
  } = useMyClients();
  const route = useRouter();
  const { isClioActive, isCanSendRepresentationRequest } = useUser();
  const { userStatistic } = useAppStatisticContextState();

  const [isLoading, setIsLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [isFetchMoreLoading, setIsFetchMoreLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const pages: Readonly<number> = Math.ceil((tabValue === 2 ? invitationsTotalItems : totalItems) / ITEMS_PER_PAGE);

  const lastElementRef = useRef<HTMLDivElement | null>(null);
  const isOnScreen = useIsOnScreen(isLoading ? null : lastElementRef);

  const handleSetTabValue = (_: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setTabValue(newValue);
    setCurrentPage(1);
  };

  const onFetchMyClientsHandler = async () => {
    setIsLoading(true);
    await fetchMyClients(tabValue, fetchParams[tabValue]);
    setIsLoading(false);
  };

  const onFetchMoreMyClientsHandler = async () => {
    if (isOnScreen && currentPage < pages) {
      setIsFetchMoreLoading(true);
      const nextPage = currentPage + 1;
      await fetchMoreClients(tabValue, { page: nextPage, itemsPerPage: ITEMS_PER_PAGE });
      setCurrentPage(nextPage);
      setIsFetchMoreLoading(false);
    }
  };

  //CRM logic
  const setIsImportFromCRM = useCreateCase().setIsImportFromCRM;
  const [isCreateCaseModalOpen, openCreateCaseModal, closeCreateCaseModal] = useBoolean(false);
  const [isImportFromCRMModalOpen, openImportFromCRMModal, closeImportFromCRMModal] = useBoolean(false);

  // TODO: conference_hack
  const { openBuySubscriptionModal } = useSubscriptionModalsContextState();
  const onClickCreateCaseHandler = () => {
    if (isCanSendRepresentationRequest) {
      openCreateCaseModal();
      return;
    }
    openBuySubscriptionModal();
  };

  const onCreateCaseButtonClickHandler = () => route.push(ROUTES.createLegalCase);

  const onImportFromCRMButtonClickHandler = () => {
    closeCreateCaseModal();
    if (!isClioActive) {
      openImportFromCRMModal();
    } else {
      setIsImportFromCRM(true);
      route.push(ROUTES.importCRMCase);
    }
  };

  const onAddIntegrationButtonClickHandler = () => route.push(ROUTES.integrations);

  const [isSearchCRMCaseModalOpen, openSearchCRMCaseModal, closeSearchCRMCaseModal] = useBoolean(false);

  const onFetchInvitationHandler = async () => {
    setIsLoading(true);
    await fetchInvitations(fetchParams[tabValue]);
    setIsLoading(false);
  };

  const onFetchMoreInvitationsHandler = async () => {
    if (isOnScreen && currentPage < pages) {
      setIsFetchMoreLoading(true);
      const nextPage = currentPage + 1;
      await fetchMoreInvitations({ page: nextPage, itemsPerPage: ITEMS_PER_PAGE });
      setCurrentPage(nextPage);
      setIsFetchMoreLoading(false);
    }
  };

  const FETCH_FUNCTION: Record<number, () => Promise<void>> = {
    0: onFetchMyClientsHandler,
    1: onFetchMyClientsHandler,
    2: onFetchInvitationHandler,
  };

  const FETCH_MORE_FUNCTION: Record<number, () => Promise<void>> = {
    0: onFetchMoreMyClientsHandler,
    1: onFetchMoreMyClientsHandler,
    2: onFetchMoreInvitationsHandler,
  };

  return {
    onImportFromCRMButtonClickHandler,
    onFetchMyClientsHandler,
    openCreateCaseModal,
    closeCreateCaseModal,
    onAddIntegrationButtonClickHandler,
    isCreateCaseModalOpen,
    onFetchMoreMyClientsHandler,
    onCreateCaseButtonClickHandler,
    isImportFromCRMModalOpen,
    openImportFromCRMModal,
    closeImportFromCRMModal,
    isFetchMoreLoading,
    isLoading,
    handleSetTabValue,
    isOnScreen,
    clients,
    statistic: userStatistic,
    tabValue,
    lastElementRef,
    isSearchCRMCaseModalOpen,
    openSearchCRMCaseModal,
    closeSearchCRMCaseModal,
    FETCH_FUNCTION,
    FETCH_MORE_FUNCTION,
    invitations,
    invitationsTotalItems,
    onClickCreateCaseHandler,
    route,
  };
};
