import { memo, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Card, CardContent, Stack } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { LAST_MOBILE_ALERT_POPUP_TIME_KEY } from 'features/common/cookies';

export const MobileNotificationAlert = memo((): JSX.Element | null => {
  const [isShowPopup, setIsShowPopup] = useState(false);

  const isIOSHandler = () => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      (navigator.userAgent.includes('Macintosh') && navigator.maxTouchPoints >= 1);
    return isIOS;
  };

  const handleOpenPopup = () => {
    const isIOS = isIOSHandler();
    if (isIOS) {
      const lastPopupTime = Cookies.get(LAST_MOBILE_ALERT_POPUP_TIME_KEY);
      const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;

      if (!lastPopupTime || Date.now() - parseInt(lastPopupTime, 10) >= oneWeekInMilliseconds) {
        setIsShowPopup(true);
      }
    }
  };

  useEffect(() => {
    handleOpenPopup();
  }, []);

  const onCloseButtonClickHandler = () => {
    setIsShowPopup(false);
    Cookies.set(LAST_MOBILE_ALERT_POPUP_TIME_KEY, Date.now().toString(), { expires: 7 });
  };

  // TODO after the release
  const onClickDownloadAppButtonHandler = () => {};

  return (
    <>
      {isShowPopup ? (
        <Card>
          <CardContent sx={{ px: 2 }}>
            <Stack spacing={1}>
              <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                <MDTypography variant="body1" fontWeight="medium">
                  Important notification
                </MDTypography>

                <MDButton
                  iconOnly
                  size="small"
                  color="dark"
                  sx={{
                    borderRadius: '50%',
                  }}
                  onClick={onCloseButtonClickHandler}
                >
                  <CloseOutlinedIcon />
                </MDButton>
              </Stack>

              <MDTypography variant="body2" color="secondary">
                Our platform's push notifications are not supported on mobile browsers. To ensure you don't miss any
                alerts, please download our app or add our site to your home screen for full notification functionality.
              </MDTypography>

              <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={1.5} width={1}>
                {/* TODO after the mobile app release */}
                {/* <MDButton variant="gradient" color="info" onClick={onClickDownloadAppButtonHandler}>
                  Download App
                </MDButton> */}

                <MDButton variant="gradient" color="dark" onClick={onCloseButtonClickHandler}>
                  Close
                </MDButton>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      ) : null}
    </>
  );
});
