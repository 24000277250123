import { FC, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Stack, Switch } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { BaseFormField, CountryState, County } from 'features/common';
import { BaseAutocompleteField } from 'features/common/components/BaseAutocompleteField';
import { CaseInfoEditContentFormProps } from 'features/case-details/types';
import { useCaseInfoEditContentForm } from 'features/case-details/hooks/useCaseInfoEditContentForm';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import { CaseLawTypeResponse } from 'features/case-law-types';

export const CaseInfoEditContentForm: FC<CaseInfoEditContentFormProps> = props => {
  const {
    caseLawTypesList,
    countryStateList,
    countyList,
    getCountiesHandler,
    getStateListHandler,
    getStateValue,
    isCaseLawTypesLoading,
    isCountyListLoading,
    isStateListLoading,
    register,
    setValue,
    watch,
    isSaveButtonDisabled,
    onFormSubmitHandler,
    errors,
    caseState,
    control,
    currentCaseState,
    isSubmitting,
  } = useCaseInfoEditContentForm(props);

  useEffect(() => {
    getStateListHandler();
  }, []);

  useEffect(() => {
    countryStateList.length && getCountiesHandler({ 'state.isoCode': getStateValue(caseState)?.isoCode });
  }, [countryStateList]);

  return (
    <MDBox
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
      onSubmit={onFormSubmitHandler}
    >
      <Stack spacing={2.6} mt={3} height={1}>
        <BaseFormField
          formFieldProps={{
            pb: 0,
          }}
          formInputProps={{
            ...register('description'),
            label: 'Description',
            fullWidth: true,
            InputLabelProps: { shrink: true },
            multiline: true,
            rows: 3,
          }}
          errorValue={errors['description']?.message}
        />
        <Controller
          control={control}
          name="caseLawType"
          render={({ field: { onChange, value } }) => {
            const onChangeHandler = (value: CaseLawTypeResponse) => {
              onChange(value ? value['@id'] : '');
            };
            return (
              <BaseAutocompleteField
                value={
                  value
                    ? caseLawTypesList.find(option => {
                        return value === option['@id'];
                      }) ?? null
                    : null
                }
                onChangeHandler={onChangeHandler}
                getOptionLabel={option => option.title}
                options={caseLawTypesList}
                errorMessage={errors['caseLawType']?.message}
                isLoading={isCaseLawTypesLoading}
                formFieldProps={{
                  pb: 0,
                }}
                formInputProps={{
                  label: 'Law type',
                  '.MuiInputBase-input': {
                    pr: '20px',
                  },
                }}
                placeholder="Select a case law type"
              />
            );
          }}
        />

        <Controller
          control={control}
          name="caseState"
          render={({ field: { onChange, value } }) => {
            const onChangeHandler = (value: CountryState) => {
              onChange(value ? value['@id'] : '');
              setValue('caseCounty', '');
              value && getCountiesHandler({ 'state.isoCode': value?.isoCode });
            };

            return (
              <BaseAutocompleteField
                value={getStateValue(value)}
                onChangeHandler={onChangeHandler}
                getOptionLabel={option => option.name}
                options={countryStateList}
                errorMessage={errors['caseState']?.message}
                isLoading={isStateListLoading}
                formFieldProps={{
                  pb: 0,
                }}
                formInputProps={{
                  label: 'Case state',
                  '.MuiInputBase-input': {
                    pr: '20px',
                  },
                }}
                placeholder="Select a case state"
              />
            );
          }}
        />

        <Controller
          control={control}
          name="caseCounty"
          render={({ field: { onChange, value } }) => {
            const onChangeHandler = (value: County) => {
              onChange(value ? value['@id'] : '');
            };
            return (
              <BaseAutocompleteField
                value={
                  value
                    ? countyList.find(option => {
                        return value === option['@id'];
                      }) ?? null
                    : null
                }
                onChangeHandler={onChangeHandler}
                getOptionLabel={option => option.shortName}
                options={countyList}
                errorMessage={errors['caseCounty']?.message}
                isLoading={isCountyListLoading}
                formFieldProps={{
                  pb: 0,
                }}
                formInputProps={{
                  label: 'Case county',
                }}
                isDisabled={!currentCaseState}
                placeholder={!currentCaseState ? 'Select a case state first' : 'Select a case county'}
              />
            );
          }}
        />

        <Stack spacing={1}>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
            <MDTypography variant="button" fontWeight="medium">
              Representing a dependant
            </MDTypography>

            <Switch {...register('isCaseForDependant')} checked={watch('isCaseForDependant')} />
          </Stack>
          {watch('isCaseForDependant') && (
            <Stack spacing={2.6}>
              <BaseFormField
                formFieldProps={{
                  pb: 0,
                  width: 1,
                }}
                formInputProps={{
                  ...register('dependantFirstName'),
                  placeholder: 'Enter dependant first name',
                  type: 'text',
                  fullWidth: true,
                  label: 'First name',
                  InputLabelProps: { shrink: true },
                }}
                errorValue={errors['dependantFirstName']?.message}
              />

              <BaseFormField
                formFieldProps={{
                  pb: 0,
                  width: 1,
                }}
                formInputProps={{
                  ...register('dependantLastName'),
                  placeholder: 'Enter dependant last name',
                  label: 'Last name',
                  type: 'text',
                  fullWidth: true,
                  InputLabelProps: { shrink: true },
                }}
                errorValue={errors['dependantLastName']?.message}
              />

              <Controller
                control={control}
                name="dependantCaseState"
                render={({ field: { onChange, value } }) => {
                  const onChangeHandler = (value: CountryState) => {
                    onChange(value ? value['@id'] : '');
                  };
                  return (
                    <BaseAutocompleteField
                      value={getStateValue(value)}
                      onChangeHandler={onChangeHandler}
                      getOptionLabel={option => option.name}
                      options={countryStateList}
                      errorMessage={errors['dependantCaseState']?.message}
                      isLoading={isStateListLoading}
                      formFieldProps={{
                        pb: 0,
                      }}
                      formInputProps={{
                        label: 'State',
                      }}
                      placeholder="Select a dependant state"
                    />
                  );
                }}
              />

              <Stack mt={-1} direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <MDTypography variant="button" fontWeight="medium">
                  Under 18?
                </MDTypography>

                <Switch {...register('isUnder18')} checked={watch('isUnder18')} />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack spacing={2} pt={1} pb={3} justifyContent="flex-end">
        <MDButton
          type="submit"
          variant="gradient"
          color="info"
          isLoading={isSubmitting}
          disabled={isSaveButtonDisabled}
          sx={{
            width: 1,
            textTransform: 'none',
          }}
        >
          Save
        </MDButton>
      </Stack>
    </MDBox>
  );
};
