export type ObjectWithValues<T> = { readonly [key: string]: T };
export type StaticStringArray<T extends ReadonlyArray<string>> = T extends ReadonlyArray<infer V> ? V[] : never;

export type PaginationParams = {
  page?: number;
  itemsPerPage?: number;
};

export type DateFilterParams = {
  startedAt?: {
    before?: string;
    after?: string;
    strictly_before?: string;
    strictly_after?: string;
  };
};

export type ListOfItems<T> = {
  'hydra:member': T;
  'hydra:totalItems': number;
};

export enum ROLES {
  'ROLE_ATTORNEY' = 'Attorney',
  'ROLE_ADMIN' = 'Admin',
  'ROLE_CLIENT' = 'Client',
  'ROLE_USER' = 'User',
}

export type BaseData<T> = {
  '@id': string;
  '@type': T;
};
