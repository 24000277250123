import { immer } from 'zustand/middleware/immer';
import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';
import { ToastType, notice } from 'features/common';
import { AdminStatistic, appStatisticApiService, UserStatistic } from 'features/common/services/appStatisticApiService';
import { createStore } from '../createStore';
import { abortRequestErrorHandler, paymentRequiredErrorHandler } from 'features/common/errorHanders';

export type UserStatisticState = {
  adminHeaderLink: string;
  userHeaderLink: string;
  userStatistic: Partial<UserStatistic>;
  adminStatistic: Partial<AdminStatistic>;
};

export type UserStatisticActions = {
  getUserStatisticHandler: (userFirebaseId: string) => Promise<void>;
  getAdminStatisticHandler: () => Promise<void>;
  onReceiveEventHandler: (
    message: MessageEvent,
    key: keyof Pick<UserStatisticState, 'adminStatistic' | 'userStatistic'>
  ) => void;
  setHeaderLink: (
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders,
    field: keyof Pick<UserStatisticState, 'adminHeaderLink' | 'userHeaderLink'>
  ) => void;
};

const initialState: UserStatisticState = {
  userHeaderLink: '',
  adminHeaderLink: '',
  userStatistic: {
    applicationBadge: 0,
    newChatMessageCount: 0,
    clientsCount: 0,
    closedLegalCaseCount: 0,
    newUserFeedCount: 0,
    openLegalCaseCount: 0,
  },
  adminStatistic: { caseLawTypeWaitingForSuggestion: 0, companiesWaitingForApproval: 0, reviewsWaitingForApproval: 0 },
};

const { getUserStatistic, getAdminStatistic } = appStatisticApiService();

export const useUserStatistic = createStore<UserStatisticState & UserStatisticActions>(
  immer((set, get) => ({
    ...initialState,
    getUserStatisticHandler: async userFirebaseId => {
      try {
        const { data, headers } = await getUserStatistic(userFirebaseId);
        set(state => {
          state.userStatistic = data;
        });
        get().setHeaderLink(headers, 'userHeaderLink');
      } catch (error) {
        paymentRequiredErrorHandler(error);
        console.error(error);
      }
    },
    getAdminStatisticHandler: async () => {
      try {
        const { data, headers } = await getAdminStatistic();
        set(state => {
          state.adminStatistic = data;
        });
        get().setHeaderLink(headers, 'adminHeaderLink');
      } catch (error) {
        abortRequestErrorHandler(error);
      }
    },
    setHeaderLink: (headers, key) => {
      const state = get();
      if (state?.[key] !== headers?.['link']) {
        state[key] = headers?.['link'] || '';
      }
    },
    onReceiveEventHandler: (message, key) => {
      const data: UserStatistic | AdminStatistic = JSON.parse(message.data);

      set(state => {
        state[key] = data;
      });
    },
  })),
  'userStatistic',
  initialState
);
