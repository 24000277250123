import { FC } from 'react';
import { Card, Grid } from '@mui/material';
import { DashboardLayout } from 'features/common';
import { TableHeader } from 'features/common/components/TableHeader';
import { ReviewTable } from './components';

export const ReviewTemplate: FC = () => {
  return (
    <DashboardLayout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ minWidth: '300px' }}>
            <TableHeader title="Waiting for approval" subtitle="Here you can see current unapproved reviews." />
            <ReviewTable />
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};
