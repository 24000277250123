import { format, formatDistanceToNow, parseISO, isPast } from 'date-fns';

type InputValue = Date | string | number | null;

export const formatDate = (date: string): string => new Date(date).toDateString().split(' ').slice(1).join(' ');

export function dateToCustomFormat(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'hh:mm a MM.dd.yyyy';

  return date ? format(new Date(date), fm) : '';
}

export const formateDistanceToNow = (date: string) => {
  if (!date) return;
  const parsedDate = parseISO(date);
  const formattedDistance = formatDistanceToNow(parsedDate, { addSuffix: true });

  return formattedDistance.replace(/ minute(s)?/, 'min').replace(/ hour(s)?/, 'h');
};
export const isDatePast = (dateToCheck: InputValue) => isPast(new Date(dateToCheck));
