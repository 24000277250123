import { FC } from 'react';
import { Divider, Rating, Stack } from '@mui/material';
import { CaseLawType, GetWorkingHoursResponse, useUser } from 'features/common';
import { Loader } from 'features/common/components/Loader';
import { GetUserReviewsResponse } from 'features/review/services';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import MDAvatar from 'components/MDAvatar';
import { getAvatarCharacters } from 'features/common/helpers/utilities';
import { dateToCustomFormat, formatDate } from 'features/common/helpers/date';
import { ContentItem } from './ContentItem';
import MDButton from 'components/MDButton';
import { getWorkingHours } from './helpers';
import { BeenhereOutlined } from '@mui/icons-material';

type CompanyInfo = {
  companyFullName: string;
  companyLogo?: string;
};

type UserOverviewContentProps = {
  attorneyDescription: string;
  country: string;
  state: string;
  lawTypes: CaseLawType[] | null;
  isLoading: boolean;
  userRatings: GetUserReviewsResponse[] | null;
  workingHours?: GetWorkingHoursResponse | null;
  companyInfo?: CompanyInfo | null;
  onClickViewCompanyProfileButtonHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const UserOverviewContent: FC<UserOverviewContentProps> = ({
  attorneyDescription,
  country,
  state,
  lawTypes,
  isLoading,
  userRatings,
  workingHours,
  companyInfo,
  onClickViewCompanyProfileButtonHandler,
}) => {
  const workingHoursArray = getWorkingHours(workingHours);

  const isClient = useUser()?.isClient;

  return (
    <Stack spacing={1} p={0.5} maxHeight={450} width={1} maxWidth={400} overflow="auto">
      {isLoading ? (
        <Stack width={200} height={200}>
          <Loader />
        </Stack>
      ) : (
        <>
          {attorneyDescription && (
            <MDTypography variant="button" fontWeight="medium">
              {attorneyDescription}
            </MDTypography>
          )}
          <Stack>
            <MDTypography fontWeight="medium">Information</MDTypography>

            <ContentItem title="Country" value={country} />
            <ContentItem title="State" value={state} />
          </Stack>
          {companyInfo?.companyFullName && isClient && (
            <Stack>
              <MDTypography fontWeight="medium" alignItems="center">
                Company
              </MDTypography>
              <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                  <MDAvatar
                    size="sm"
                    src={companyInfo?.companyLogo}
                    alt="company logo"
                    bgColor={companyInfo?.companyLogo ? 'transparent' : 'info'}
                  >
                    <BeenhereOutlined fontSize="medium" />
                  </MDAvatar>
                  <MDTypography variant="button">{companyInfo?.companyFullName}</MDTypography>
                </Stack>

                <MDButton
                  onClick={onClickViewCompanyProfileButtonHandler}
                  sx={{ px: 1, py: '6px', textTransform: 'none' }}
                  size="small"
                  variant="gradient"
                  color="info"
                >
                  View company
                </MDButton>
              </Stack>
            </Stack>
          )}

          {lawTypes?.length ? (
            <Stack>
              <MDTypography fontWeight="medium">Law types</MDTypography>
              <Stack direction="row" flexWrap="wrap" gap="4px" mb={1}>
                {lawTypes?.map(lawType => (
                  <MDBox
                    key={lawType?.['@id']}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      p: '3px 6px',
                      mr: '5px',
                      borderRadius: '12px',
                      backgroundColor: ({ palette }) => palette.grey[100],
                    }}
                  >
                    <MDTypography variant="button">{lawType?.title}</MDTypography>
                  </MDBox>
                ))}
              </Stack>
            </Stack>
          ) : null}

          {workingHoursArray?.length ? (
            <Stack pb={1} divider={<Divider orientation="horizontal" />}>
              <MDTypography fontWeight="medium">Hours of Operation</MDTypography>
              <Stack spacing={1}>
                {workingHoursArray?.map(el => (
                  <Stack key={el.day}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5} mb={0.5}>
                      <MDTypography sx={{ textTransform: 'capitalize', fontWeight: 500, fontSize: 16 }}>
                        {el.day}
                      </MDTypography>
                      <Stack spacing={1} direction="row" alignItems="center" justifyContent="flex-start">
                        <MDTypography variant="button">{dateToCustomFormat(el.fromValue, 'h:mm a')}</MDTypography>
                        <MDTypography variant="button">-</MDTypography>
                        <MDTypography variant="button">{dateToCustomFormat(el.toValue, 'h:mm a')}</MDTypography>
                      </Stack>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          ) : null}

          {userRatings?.length ? (
            <Stack pb={1}>
              <MDTypography fontWeight="medium">Rating</MDTypography>
              <Stack spacing={1} divider={<Divider orientation="horizontal" />}>
                {userRatings.map(rating => (
                  <Stack key={rating?.['@id']}>
                    <Stack direction="row" alignItems="flex-start" spacing={1.5} mb={0.5}>
                      <MDAvatar
                        src={rating?.createdBy?.logo?.publicUrl}
                        alt="user avatar"
                        bgColor="info"
                        sx={{
                          '& img': {
                            height: 1,
                          },
                        }}
                      >
                        {getAvatarCharacters(
                          rating?.createdBy?.userProfile?.firstName,
                          rating?.createdBy?.userProfile?.lastName
                        )}
                      </MDAvatar>
                      <Stack flex={1}>
                        <MDTypography
                          variant="button"
                          fontWeight="medium"
                        >{`${rating?.createdBy?.userProfile?.firstName} ${rating?.createdBy?.userProfile?.lastName}`}</MDTypography>
                        <Rating
                          value={rating.rating}
                          precision={0.1}
                          readOnly
                          sx={{ color: theme => theme.palette.grey[700] }}
                        />
                      </Stack>
                      <MDTypography variant="body2" fontSize={14}>
                        {rating?.createdAt ? formatDate(rating?.createdAt) : '-'}
                      </MDTypography>
                    </Stack>

                    <MDTypography variant="button">{rating?.body}</MDTypography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          ) : null}
        </>
      )}
    </Stack>
  );
};
