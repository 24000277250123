import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import MDButton from 'components/MDButton';

export const FailedLoadContent: FC = () => {
  const refreshPage = () => window.location.reload();

  return (
    <Stack sx={{ flex: 1, justifyContent: 'center', alignItems: 'center' }} spacing={2}>
      <Typography fontWeight="medium">Sorry, we couldn`t complete your request, please try again!</Typography>
      <MDButton variant="gradient" color="info" onClick={refreshPage}>
        Try again
      </MDButton>
    </Stack>
  );
};
