import { FC, useEffect } from 'react';
import { setLayout, useMaterialUIController } from 'context';
import { useCookiesFileSignData, useRouter } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';

export const SignEnd: FC = (): null => {
  const [_, dispatch] = useMaterialUIController();

  const { removeSignDataItem } = useCookiesFileSignData();

  const router = useRouter();

  useEffect(() => {
    setLayout(dispatch, 'page');

    const redirectRoute = removeSignDataItem({ fileId: router?.params?.id });

    if (redirectRoute) {
      router.navigate(redirectRoute);
    } else {
      router.navigate(ROUTES.myLegalCases);
    }
  }, []);

  return null;
};
