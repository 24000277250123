import { Pagination } from 'features/case-law-types';
import {
  Country,
  CountryState,
  GetWorkingHoursResponse,
  ListOfItems,
  Logo,
  PATCH_HEADERS,
  backendApiClient,
} from '../../common';
import { GetUserCompanyResponse } from 'features/my-company/services';
import { User } from 'features/case-details/CaseDetailsApiService';
import { CaseLawType } from 'features/auth';

export type GetCompanyParams = Pagination & {
  fullName?: string;
  shortName?: string;
  active?: boolean;
  caseLawTypes?: string | string[];
  'headquarterAddress.stateCode'?: string;
};
export type UserPermissions = {
  adminCanAddAttorney: boolean;
  adminCanEditCompanyInfo: boolean;
  adminCanDeleteAttorney: boolean;
  adminCanPromoteAdmin: boolean;
};

export type HeadquarterAddress = BaseData & {
  addressLine1: string;
  addressLine2: string;
  title: string;
  zipCode: string;
  town: string;
  id: string;
  country: Country;
  state: CountryState;
};

export type CreateCompanyRequest = CompanyInfo & {
  headquarterAddress: string;
  userPermissions?: UserPermissions;
};

type BaseData = {
  '@id': string;
  '@type': string;
};
export type UserProfile = BaseData & {
  firstName: string;
  lastName: string;
  personTitle: string;
};
export type CompanyOwner = BaseData & {
  firebaseUser: string;
  userProfile: UserProfile;
  shortName: string;
};
export type CompanyResponse = CompanyInfo & {
  '@context': string;
  '@id': string;
  '@type': string;
  headquarterAddress: HeadquarterAddress;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  owner: CompanyOwner;
  userPermissions: UserPermissions & {
    '@id': string;
    '@type': 'CompanyUserPermission';
  };
  logo?: Logo | null;
  rating: number | null;
  caseLawTypes?: CaseLawType[];
  workingHours: GetWorkingHoursResponse | null;
};

export type CompanyInfo = {
  fullName: string;
  shortName: string;
  email: string;
  phone: string;
  site?: string;
  description?: string;
};
export type PatchCompanyByIdPayload = CompanyInfo & {
  headquarterAddress: string;
  userPermissions: UserPermissions;
};
export type GetCompanyResponse = {
  'hydra:member': ReadonlyArray<CompanyResponse>;
  'hydra:totalItems': number;
};

export type PutLogoPayload = {
  logo: string;
};

export type PutLawTypesPayload = {
  caseLawTypes: string[];
};

export type UpdateCompanyInfoPayload = Partial<
  Pick<GetUserCompanyResponse, 'fullName' | 'shortName' | 'site' | 'description' | 'phone'>
>;

export type CompanyUser = {
  '@id': string;
  '@type': 'CompanyUser';
  company: string;
  role: COMPANY_ROLES;
  user: User & {
    legalCaseCount: number;
    rating: number;
  };
};

export type CompanyReview = BaseData & {
  body: string;
  rating: number;
  createdBy: BaseData & {
    firebaseUser: string;
    userProfile: UserProfile;
    roles: ['string'];
    logo?: Logo;
  };
  active: boolean;
  createdAt: string;
};

export type GetCompanyReviewsParams = Pagination & { active: boolean };

export type COMPANY_ROLES = 'ROLE_COMPANY_ATTORNEY' | 'ROLE_COMPANY_ADMIN';

export const createCompanyService = () => {
  const getCompanies = async (params: GetCompanyParams) => {
    return await backendApiClient({}).get<GetCompanyResponse>('/companies', { params });
  };

  const getCompanyReviews = async (id: string, params: GetCompanyReviewsParams) => {
    return await backendApiClient({}).get<ListOfItems<CompanyReview[]>>(`companies/${id}/reviews`, { params });
  };

  const createCompany = async (createContentItemPayload: CreateCompanyRequest) => {
    return await backendApiClient({}).post<CompanyResponse, CreateCompanyRequest>(
      '/companies',
      createContentItemPayload
    );
  };

  const getCompanyById = async (id: string) => {
    return await backendApiClient({}).get<CompanyResponse>(`/companies/${id}`);
  };

  const patchCompanyById = async (id: string, patchPagePayload: Partial<CreateCompanyRequest>) => {
    return await backendApiClient({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch<CompanyResponse>(`/companies/${id}`, patchPagePayload);
  };
  const patchCompanyByIdActive = async (id: string, active: boolean) => {
    return await backendApiClient({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch<CompanyResponse>(`/companies/${id}/active`, { active });
  };
  const patchCompanyByIdInfo = async (id: string, patchPagePayload: CompanyInfo) => {
    return await backendApiClient({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch<CompanyResponse>(`/companies/${id}/info`, patchPagePayload);
  };

  const deleteCompanyById = async (id: string) => {
    return await backendApiClient({}).delete(`/companies/${id}`);
  };

  const attachCompanyLogo = async (id: string, payload: PutLogoPayload) =>
    await backendApiClient({
      headers: PATCH_HEADERS,
    }).patch<GetUserCompanyResponse>(`/companies/${id}/logo`, payload);

  const updateCompanyInfo = async (id: string, payload: UpdateCompanyInfoPayload) => {
    return await backendApiClient({
      headers: PATCH_HEADERS,
    }).patch<GetUserCompanyResponse>(`/companies/${id}/info`, payload);
  };

  const updateCompanyCaseLawTypes = async (id: string, payload: PutLawTypesPayload) =>
    await backendApiClient({ headers: PATCH_HEADERS }).patch<GetUserCompanyResponse>(
      `/companies/${id}/case_law_types`,
      payload
    );

  const getCompanyUsers = async (companyId: string, params: Pagination) =>
    await backendApiClient({}).get<ListOfItems<CompanyUser[]>>(`/companies/${companyId}/users`, { params });

  const deleteCompanyUser = async (userId: string) => await backendApiClient({}).delete(`/company_users/${userId}`);

  const updateCompanyUser = async (userId: string, role: COMPANY_ROLES) =>
    await backendApiClient({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch<CompanyUser>(`/company_users/${userId}`, { role });

  return {
    attachCompanyLogo,
    updateCompanyInfo,
    getCompanies,
    createCompany,
    getCompanyById,
    patchCompanyById,
    deleteCompanyById,
    patchCompanyByIdActive,
    patchCompanyByIdInfo,
    updateCompanyCaseLawTypes,
    getCompanyUsers,
    updateCompanyUser,
    deleteCompanyUser,
    getCompanyReviews,
  };
};
