import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardContent, CardHeader, InputAdornment, Stack } from '@mui/material';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import MDButton from 'components/MDButton';
import { BaseFormField, Country, CountryState, useGeoData } from 'features/common';
import { CREATE_CASE_FORM_STEPS, useCreateCase } from 'features/store';
import { ClientSchema, clientFormSchema } from 'features/my-legal-cases/components/CreateLegalCase/form.config';
import { myClientsApiService } from 'features/my-clients/MyClientsApiService';
import { BaseAutocompleteField } from 'features/common/components/BaseAutocompleteField';
import { getOptionById } from 'features/common/helpers/utilities';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';
import { ButtonsBox } from './ButtonsBox';

export const AttoneyClientInformation: FC = () => {
  const {
    saveFormDataHandler,
    setClientInvitationData,
    activeStep,
    setStateData,
    clientInformationData: {
      country,
      email,
      firstName,
      lastName,
      state,
      clientId,
      clientLogoURL,
      clientCaseCount,
      clientRating,
    },
  } = useCreateCase(state => ({
    clientInformationData: state.clientData,
    activeStep: state.activeStep,
    saveFormDataHandler: state.setData,
    setClientInvitationData: state.setClientInvitationData,
    setStateData: state.setStateData,
  }));

  const {
    isStateListLoading,
    countryList,
    countryStateList,
    onGetCountryListHandler,
    getStateListHandler,
    onGetCountryListHandlerState,
  } = useGeoData(false);

  const [isAddNewUser, setIsAddNewUser] = useState(email && !clientId);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm<ClientSchema>({
    resolver: yupResolver(clientFormSchema),
    mode: 'onTouched',
    values: {
      firstName,
      lastName,
      state,
      country,
      email,
    },
  });

  const onFormSubmitHandler = handleSubmit(formData => {
    saveFormDataHandler({
      step: activeStep,
      data: {
        ...formData,
        clientId,
        clientLogoURL,
        clientCaseCount,
        clientRating,
      },
      activeStep: CREATE_CASE_FORM_STEPS.CASE_INFO,
    });

    !clientId && setClientInvitationData({ country: formData.country, state: formData.state });
  });

  const [isSearching, setIsSearching] = useState(false);
  const [isNotFoundClient, setIsNotFoundClient] = useState(false);

  const [notFoundMessage, setNotFoundMessage] = useState('');

  const { searchClientByEmail } = myClientsApiService();

  const onClickAddNewUserHandler = async () => {
    setIsAddNewUser(true);
    setIsNotFoundClient(false);
    setNotFoundMessage('');
    await onGetCountryListHandler();
  };

  const onClickFindClientButtonHandler = async () => {
    try {
      setIsSearching(true);
      const { data } = await searchClientByEmail(watch('email'));

      setIsSearching(false);

      setStateData('clientData', {
        clientId: data?.['@id'],
        clientLogoURL: data?.logo?.publicUrl,
        clientCaseCount: data?.legalCaseCount,
        clientRating: data?.rating,
        firstName: data?.userProfile?.firstName,
        lastName: data?.userProfile?.lastName,
        country: data?.userProfile?.country?.['@id'],
        email: data?.email || watch('email'),
        state: data?.userProfile?.state?.['@id'],
      });

      isNotFoundClient && setIsNotFoundClient(false);
      isAddNewUser && setIsAddNewUser(false);
      notFoundMessage && setNotFoundMessage('');
    } catch (error: any) {
      setIsSearching(false);
      if (error?.response?.status === 404) {
        setIsNotFoundClient(true);
        setNotFoundMessage('We did not find this client`s email in our system');
      } else {
        paymentRequiredErrorHandler(error);
      }
      console.error(error);
    }
  };

  const onClickTryAgainButtonHandler = () => {
    setIsAddNewUser(false);
    setIsNotFoundClient(false);
    setNotFoundMessage('');
    setValue('email', '');
  };

  const countryCurrentValue = watch('country');
  const stateCurrentValue = watch('state');

  const countryValue = getOptionById(countryCurrentValue, countryList);
  const stateValue = getOptionById(stateCurrentValue, countryStateList);

  useEffect(() => {
    if (state && country) {
      onGetCountryListHandler();
      getStateListHandler(country);
    }
  }, [clientId]);

  return (
    <form onSubmit={onFormSubmitHandler}>
      <Card>
        <CardHeader title="Client Information" />
        <CardContent>
          <Stack direction={{ xs: 'column', sm: 'row' }} gap={{ xs: 0, sm: 3 }} width={1}>
            <Stack width={1}>
              <BaseFormField
                formInputProps={{
                  ...register('email'),
                  type: 'text',
                  label: 'Email',
                  fullWidth: true,
                  autoComplete: 'new-email',
                  InputLabelProps: { shrink: true },
                  disabled: !!clientId || isAddNewUser || isNotFoundClient,
                  InputProps: {
                    sx: {
                      'input:-webkit-autofill, input:-webkit-autofill:focus': {
                        transition: 'background-color 600000s 0s, color 600000s 0s',
                      },
                    },
                    endAdornment: (
                      <>
                        {!!clientId ? (
                          <InputAdornment position="end">
                            <DoneOutlinedIcon fontSize="medium" color="success" />
                          </InputAdornment>
                        ) : null}
                      </>
                    ),
                  },
                }}
                errorValue={errors['email']?.message || notFoundMessage}
              />

              {(!!clientId || isAddNewUser) && (
                <>
                  <BaseFormField
                    formInputProps={{
                      ...register('firstName'),
                      type: 'text',
                      label: 'First name',
                      placeholder: 'Enter first name',
                      InputLabelProps: { shrink: true },
                      fullWidth: true,
                      disabled: !!clientId,
                    }}
                  />
                  <BaseFormField
                    formInputProps={{
                      ...register('lastName'),
                      type: 'text',
                      label: 'Last name',
                      placeholder: 'Enter last name',
                      InputLabelProps: { shrink: true },
                      fullWidth: true,
                      disabled: !!clientId,
                    }}
                  />
                </>
              )}
            </Stack>
            <Stack width={1}>
              <ButtonsBox
                isAddNewButtonDisabled={isSearching || !!errors['email']?.message}
                isFindClientButtonDisabled={isSearching || !!errors['email']?.message || !watch('email')}
                isLoading={isSearching}
                isAddNewButton={isNotFoundClient && !isAddNewUser}
                isFindButton={!clientId && !isAddNewUser}
                onClickAddNewUserHandler={onClickAddNewUserHandler}
                onClickFindClientButtonHandler={onClickFindClientButtonHandler}
                onClickTryAgainButtonHandler={onClickTryAgainButtonHandler}
              />

              {(!!clientId || isAddNewUser || (state && country)) && (
                <>
                  <Controller
                    control={control}
                    name="country"
                    render={({ field: { onChange } }) => {
                      const onChangeHandler = async (value: Country) => {
                        onChange(value ? value['@id'] : '');
                        value && (await getStateListHandler(value['@id']));
                      };
                      return (
                        <BaseAutocompleteField
                          isDisabled={!!clientId}
                          options={countryList}
                          getOptionLabel={option => option.name}
                          value={countryValue}
                          onChangeHandler={onChangeHandler}
                          errorMessage={errors['country']?.message}
                          isLoading={onGetCountryListHandlerState.isPending()}
                          autocompleSxProps={{
                            width: 1,
                            '.MuiInputBase-root': {
                              py: '4.5px',
                            },
                          }}
                          formInputProps={{
                            label: 'Country',
                          }}
                          placeholder="Select a country"
                        />
                      );
                    }}
                  />

                  <Controller
                    control={control}
                    name="state"
                    render={({ field: { onChange } }) => {
                      const onChangeHandler = async (value: CountryState) => {
                        onChange(value ? value['@id'] : '');
                      };
                      return (
                        <BaseAutocompleteField
                          isDisabled={!!clientId || (!stateCurrentValue && !countryCurrentValue)}
                          options={countryStateList}
                          getOptionLabel={option => option.name}
                          value={stateValue || null}
                          onChangeHandler={onChangeHandler}
                          errorMessage={errors['country']?.message}
                          isLoading={isStateListLoading}
                          autocompleSxProps={{
                            width: 1,
                            '.MuiInputBase-root': {
                              py: '4.5px',
                            },
                          }}
                          formInputProps={{
                            label: 'State',
                          }}
                          placeholder={!countryCurrentValue ? 'Select a country first' : 'Select a state'}
                        />
                      );
                    }}
                  />
                </>
              )}
            </Stack>
          </Stack>
          <Stack alignItems="flex-end">
            <MDButton type="submit" variant="gradient" color="dark" disabled={!isValid}>
              Next
            </MDButton>
          </Stack>
        </CardContent>
      </Card>
    </form>
  );
};
