import { useEffect, useState } from 'react';
import { usePagination } from 'features/common/hooks/usePagination';
import { ToastType, notice } from '../../common';
import { ContentItemResponse, GetContentItemRequest, createContentItemService } from '../services';
import { abortRequestErrorHandler } from 'features/common/errorHanders';

type UseContentItems = GetContentItemRequest;

export const useContentItems = ({ category }: UseContentItems) => {
  const { getContentItems, patchContentItemById, createContentItem, deleteContentItemById, getContentItemStaticById } =
    createContentItemService();

  const [contentItemsList, setContentItemsList] = useState<ContentItemResponse[]>([]);
  const [contentItemsTotal, setContentItemsTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getItemsHandler = async (params?: GetContentItemRequest) => {
    try {
      const { data } = await getContentItems({
        page: currentPage,
        itemsPerPage: entriesPerPage,
        category: category ? '/content_item_categories/' + category : undefined,
        ...params,
      });
      setContentItemsList(data['hydra:member']);
      setContentItemsTotal(Number(data['hydra:totalItems']));
    } catch (error) {
      abortRequestErrorHandler(error);
    }
  };

  const loadContentItemsHandler = async (page?: number) => {
    setIsLoading(true);
    await getItemsHandler({ page });
    setIsLoading(false);
  };

  const { currentPage, entriesPerPage, isLastItem, setCurrentPage, onChangeEntriesPerPageHandler, onDeleteItem } =
    usePagination({
      itemsLength: contentItemsList?.length,
      totalItems: contentItemsTotal,
      itemsPerPage: 100,
    });

  const onChangePageHandler = async (page: number) => {
    setCurrentPage(page);
    await loadContentItemsHandler(page);
  };

  useEffect(() => {
    loadContentItemsHandler();
  }, [category, entriesPerPage]);

  return {
    loadContentItemsHandler,
    getItemsHandler,
    contentItemsList,
    currentPage,
    isLastItem,
    setIsLoading,
    createContentItem,
    onChangeEntriesPerPageHandler,
    contentItemsTotal,
    onChangePageHandler,
    onDeleteItem,
    isLoading,
    entriesPerPage,
    patchContentItemById,
    deleteContentItemById,
    getContentItemStaticById,
  };
};
