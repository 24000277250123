import { FC } from 'react';
import { AgoraVideoPlayer, ICameraVideoTrack } from 'agora-rtc-react';
import MDBox from 'components/MDBox';
import { getAvatarCharacters } from 'features/common/helpers/utilities';
import { EventParticipant } from 'features/common/services/agoraEventApiService';
import MDAvatar from 'components/MDAvatar';

type LocalParticipantProps = {
  isRemoteParticipantJoined: boolean;
  localParticipantCameraVideoTrack: ICameraVideoTrack;
  localParticipant: EventParticipant;
};

export const LocalParticipant: FC<LocalParticipantProps> = ({
  localParticipantCameraVideoTrack,
  isRemoteParticipantJoined,
  localParticipant,
}) => {
  const avatarCharacters = getAvatarCharacters(
    localParticipant?.user?.userProfile?.firstName,
    localParticipant?.user?.userProfile?.lastName
  );

  return (
    <>
      {!isRemoteParticipantJoined ? (
        <MDBox
          sx={{
            minHeight: '200px',
            width: '100%',
            display: 'flex',
            flex: 1,
            position: 'relative',
            borderRadius: '10px',
          }}
        >
          <MDBox position="relative" sx={{ width: 1, height: 1, borderRadius: '10px', overflow: 'hidden' }}>
            <MDBox
              sx={{
                width: 1,
                height: 1,
                display: localParticipantCameraVideoTrack?.muted ? 'flex' : 'none',
                position: 'absolute',
                backgroundColor: ({ palette }) => palette.grey[700],
                top: '0',
                left: '0',
                right: 0,
                bottom: 0,
                zIndex: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <MDAvatar
                src={localParticipant?.user?.logo?.publicUrl}
                sx={{
                  height: '100px',
                  borderRadius: '50%',
                  fontSize: '18px',
                  width: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white !important',
                  border: `2px solid white`,
                }}
              >
                {avatarCharacters}
              </MDAvatar>
            </MDBox>
            <MDBox
              sx={{
                height: 1,
                width: 1,
                'div > div': {
                  borderRadius: '10px',
                  backgroundColor: 'transparent !important',
                },
              }}
            >
              <AgoraVideoPlayer
                style={{
                  height: '100%',
                  width: '100%',
                  borderRadius: '10px',
                  backgroundColor: 'none',
                }}
                videoTrack={localParticipantCameraVideoTrack}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      ) : (
        <MDBox
          sx={{
            width: { xs: '100px', sm: '200px' },
            height: { xs: '100px', sm: '200px' },
            fontSize: '16px',
            display: 'flex',
            position: 'absolute',
            top: 10,
            right: 10,
            m: 0,
            justifyContent: 'center',
            zIndex: 20,
            video: {
              borderRadius: '10px',
            },
          }}
        >
          <MDBox
            position="relative"
            sx={{ width: 1, height: 1, display: localParticipantCameraVideoTrack?.muted ? 'flex' : 'none' }}
          >
            <MDBox
              sx={{
                width: 1,
                height: 1,
                display: 'flex',
                position: 'absolute',
                backgroundColor: ({ palette }) => palette.grey[600],
                top: '0',
                left: '0',
                right: 0,
                bottom: 0,
                zIndex: 10,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '12px',
              }}
            >
              <MDAvatar
                src={localParticipant?.user?.logo?.publicUrl}
                sx={{
                  borderRadius: '50%',
                  fontSize: '18px',
                  width: { xs: '70px', sm: '100px' },
                  height: { xs: '70px', sm: '100px' },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white !important',
                  border: '2px solid white',
                }}
              >
                {avatarCharacters}
              </MDAvatar>
            </MDBox>
          </MDBox>
          {!localParticipantCameraVideoTrack?.muted && (
            <MDBox
              sx={{
                height: 1,
                width: 1,
                'div > div': {
                  borderRadius: '10px',
                  backgroundColor: 'transparent !important',
                },
              }}
            >
              <AgoraVideoPlayer
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'none',
                }}
                videoTrack={localParticipantCameraVideoTrack || null}
              />
            </MDBox>
          )}
        </MDBox>
      )}
    </>
  );
};
