import { useCallback, useEffect, useState } from 'react';
import { usePagination } from 'features/common/hooks/usePagination';
import { ToastType, notice } from '../../common';
import { GetQuestionsRequest, QuestionResponse, createFAQService } from '../services';
import { abortRequestErrorHandler } from 'features/common/errorHanders';

export const useFAQ = (isImmediatelyCall: boolean = true) => {
  const { getQuestions } = createFAQService();

  const [questionsList, setQuestionsList] = useState<QuestionResponse[]>([]);
  const [questionsTotal, setQuestionsTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { currentPage, entriesPerPage, setCurrentPage, onChangeEntriesPerPageHandler, onDeleteItem } = usePagination({
    itemsLength: questionsList?.length,
    totalItems: questionsTotal,
  });

  const getItemsHandler = useCallback(
    async (params?: GetQuestionsRequest) => {
      try {
        const { data } = await getQuestions({
          page: currentPage,
          itemsPerPage: entriesPerPage,
          ...params,
        });
        setQuestionsList(data['hydra:member']);
        setQuestionsTotal(Number(data['hydra:totalItems']));
      } catch (error) {
        abortRequestErrorHandler(error);
      }
    },
    [currentPage, entriesPerPage]
  );

  const loadQuestionsHandler = useCallback(
    async (page: number = currentPage) => {
      setIsLoading(true);

      await getItemsHandler({ page });
      setIsLoading(false);
    },
    [currentPage, entriesPerPage]
  );

  useEffect(() => {
    if (isImmediatelyCall) loadQuestionsHandler();
  }, [entriesPerPage]);

  const onChangePageHandler = useCallback(
    async (page: number) => {
      setCurrentPage(page);
      await loadQuestionsHandler(page);
    },
    [currentPage, entriesPerPage]
  );

  return {
    questionsTotal,
    questionsList,
    isLoading,
    currentPage,
    entriesPerPage,
    setIsLoading,
    getItemsHandler,
    onDeleteItem,
    loadQuestionsHandler,
    onChangePageHandler,
    onChangeEntriesPerPageHandler,
  };
};
