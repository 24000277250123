import { FC } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { SignProvidersRadioGroupProps } from './types';

export const SignProvidersRadioGroup: FC<SignProvidersRadioGroupProps> = ({
  onChangeRadioButtonHandler,
  selectedSignProvider,
  fileSignProviders,
}) => {
  return (
    <RadioGroup
      row
      onChange={onChangeRadioButtonHandler}
      sx={{ flexDirection: { xs: 'column', sm: 'row' }, width: 1, justifyContent: 'center', mt: '2px' }}
    >
      {fileSignProviders.map((el, index) => {
        const isChecked = selectedSignProvider === el?.provider;
        return (
          <FormControlLabel
            key={el?.provider}
            control={<Radio />}
            labelPlacement="start"
            value={el?.provider}
            label={el?.label}
            checked={selectedSignProvider === el?.provider}
            sx={{
              fontSize: '16px',
              display: 'flex',
              p: 1,
              m: 0,
              ml: { xs: 0, sm: index ? 2 : 0 },
              mb: { xs: !index ? 2 : 0, sm: 0 },
              borderRadius: '6px',
              justifyContent: { xs: 'center', sm: 'flex-start' },
              outline: theme => `1px solid ${theme.palette.grey[isChecked ? 500 : 300]}`,
            }}
          />
        );
      })}
    </RadioGroup>
  );
};
