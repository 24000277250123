import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRouter } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';
import { getPath } from './helpers';

export const FirebaseSecurityTemplate = (): null => {
  const router = useRouter();
  const [searchParams, _] = useSearchParams();

  const handleNavigation = () => {
    const mode = searchParams.get('mode');
    const oobCode = searchParams.get('oobCode');

    switch (mode) {
      case 'resetPassword':
        return router.push(ROUTES.resetPassword + '?oobCode=' + oobCode);
      case 'verifyEmail':
        return router.push(ROUTES.verifyUserEmail + '?oobCode=' + oobCode);
      case 'openApp':
        return router.push(getPath(searchParams));
      default:
        return router.push(ROUTES.home);
    }
  };

  useEffect(() => {
    handleNavigation();
  }, []);

  return null;
};
