import { FC, useEffect } from 'react';
import { AppBar, CircularProgress, Dialog, Fab, Grid, Link, Stack, Toolbar } from '@mui/material';
import { Add } from '@mui/icons-material';
import { DashboardLayout } from 'features/common';
import MDTypography from 'components/MDTypography';
import { TABS_DATA } from 'features/my-legal-cases/components/Toolbar/toolbar.config';
import { Tabs } from 'features/common/components/Tabs';
import MDBox from 'components/MDBox';
import { ClientCard, CreateCaseModalContent } from './components';
import { useMyClientsState } from './hooks/useMyClientsState';
import MDButton from 'components/MDButton';
import { InvitationCard } from './components/InvitationCard';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from 'features/common/variables/routes';

export const MyClientsTemplate: FC = () => {
  const {
    clients,
    isOnScreen,
    handleSetTabValue,
    isFetchMoreLoading,
    isLoading,
    onCreateCaseButtonClickHandler,
    statistic,
    tabValue,
    lastElementRef,
    closeCreateCaseModal,
    closeImportFromCRMModal,
    isCreateCaseModalOpen,
    isImportFromCRMModalOpen,
    onImportFromCRMButtonClickHandler,
    openCreateCaseModal,
    onAddIntegrationButtonClickHandler,
    FETCH_FUNCTION,
    FETCH_MORE_FUNCTION,
    invitations,
    onClickCreateCaseHandler,
  } = useMyClientsState();

  useEffect(() => {
    FETCH_FUNCTION[tabValue]();
  }, [tabValue]);

  useEffect(() => {
    FETCH_MORE_FUNCTION[tabValue]();
  }, [isOnScreen]);

  const renderCard = () => {
    switch (tabValue) {
      case 2:
        return invitations.map((caseInvitation, index) => (
          <Grid item key={caseInvitation.id} xs={12} md={6} lg={4}>
            <InvitationCard
              lastElementRef={index === invitations.length - 1 ? lastElementRef : null}
              caseNumber={caseInvitation.caseNumber}
              description={caseInvitation.description}
              createdAt={caseInvitation.createdAt}
              stateName={caseInvitation.state.name}
              countryId={caseInvitation.country.id}
              firstName={caseInvitation?.invitation?.firstName}
              lastName={caseInvitation?.invitation?.lastName}
              email={caseInvitation?.invitation?.email}
              statusTitle={caseInvitation.status.title}
              statusBgColor={caseInvitation.status.lightThemeBgColor}
              countyName={caseInvitation.county.shortName}
              caseId={caseInvitation.id}
            />
          </Grid>
        ));

      default:
        return clients?.map((client, index) => (
          <Grid key={client.firebaseUser} item xs={12} sm={6}>
            <ClientCard
              clientFirebaseId={client?.firebaseUser}
              clientCountry={client?.userProfile?.country?.id}
              clientState={client?.userProfile?.state?.name}
              clientFirstName={client?.userProfile?.firstName}
              clientLastName={client?.userProfile?.lastName}
              legalCases={client?.legalCases}
              clientLogoURL={client?.logo?.publicUrl}
              legalCaseCount={client?.legalCaseCount}
              rating={client?.rating}
              lastElementRef={index === clients.length - 1 ? lastElementRef : null}
            />
          </Grid>
        ));
    }
  };

  return (
    <DashboardLayout>
      <Grid container>
        <Grid item xs={12} mb={3}>
          <AppBar position="static">
            <Toolbar
              sx={{
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                '.MuiTabs-root': {
                  maxWidth: 350,
                  width: { xs: 1 },
                },
              }}
            >
              <Tabs
                tabsData={statistic?.invitationCasesCount ? TABS_DATA : TABS_DATA?.slice(0, 2)}
                bagesValues={[
                  statistic?.openLegalCaseCount,
                  statistic?.closedLegalCaseCount,
                  statistic?.invitationCasesCount,
                ]}
                tabValue={tabValue}
                orientation="horizontal"
                handleSetTabValue={handleSetTabValue}
              />
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={onClickCreateCaseHandler}
                sx={{ mt: { xs: 1, sm: 0 }, ml: 1 }}
              >
                <Add sx={{ width: 20, height: 20, mr: '2px', mb: '2px' }} />
                Create case
              </MDButton>
            </Toolbar>
          </AppBar>
        </Grid>
      </Grid>

      {!isLoading && (tabValue === 2 ? !invitations.length : !clients.length) && (
        <Stack flex={1} justifyContent="center" alignItems="center">
          <MDTypography sx={{ textAlign: 'center' }}>
            No clients yet? Head over to the{' '}
            <Link component={RouterLink} sx={{ color: ({ palette }) => palette.info.main }} to={ROUTES.legalCasesFeed}>
              Case Feed
            </Link>{' '}
            to discover new opportunities and start building your client list.
          </MDTypography>
        </Stack>
      )}
      {isLoading ? (
        <Stack flex={1} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Grid container spacing={3} position="relative">
            {renderCard()}

            {isFetchMoreLoading && (
              <MDBox sx={{ position: 'absolute', bottom: 25, left: '50%', transform: 'translateY(-50%)' }}>
                <CircularProgress />
              </MDBox>
            )}
          </Grid>
        </>
      )}

      <Dialog open={isCreateCaseModalOpen} onClose={closeCreateCaseModal}>
        <CreateCaseModalContent
          contentTitle="You can create a case manually filling in all the information, or import it through CRM that you have connected
          in the settings."
          title="Begin a Case"
        >
          <MDButton variant="gradient" color="info" onClick={onCreateCaseButtonClickHandler}>
            Create case
          </MDButton>
          <MDButton variant="gradient" color="dark" onClick={onImportFromCRMButtonClickHandler}>
            Import from CRM
          </MDButton>
        </CreateCaseModalContent>
      </Dialog>

      <Dialog open={isImportFromCRMModalOpen} onClose={closeImportFromCRMModal}>
        <CreateCaseModalContent
          contentTitle="To take advantage of this feature, first, you'll need to add integrations in the settings."
          title="You don't have integrations"
        >
          <MDButton variant="gradient" color="info" onClick={onAddIntegrationButtonClickHandler}>
            Add integrations
          </MDButton>
          <MDButton variant="gradient" color="dark" onClick={closeImportFromCRMModal}>
            Close
          </MDButton>
        </CreateCaseModalContent>
      </Dialog>
    </DashboardLayout>
  );
};
