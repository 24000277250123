import { FC } from 'react';
import { InputAdornment } from '@mui/material';
import MDTypography from 'components/MDTypography';

type EndInputAdornmentProps = {
  title?: string;
};

export const EndInputAdornment: FC<EndInputAdornmentProps> = ({ title = 'minutes' }) => {
  return (
    <InputAdornment position="end">
      <MDTypography variant="body2" sx={{ fontWeight: 400 }}>
        {title}
      </MDTypography>
    </InputAdornment>
  );
};
