import { Pagination } from 'features/case-law-types';
import { BackendUserResponse, BaseData, CaseLawType, ListOfItems, backendApiClient } from 'features/common';
import { GetMyLegalCasesResponse, User } from 'features/my-legal-cases/MyLegalCasesApiService';

export type ClientLegalCase = Pick<
  GetMyLegalCasesResponse,
  '@id' | 'id' | '@type' | 'caseNumber' | 'country' | 'createdAt' | 'description' | 'state' | 'updatedAt' | 'county'
> & {
  caseLawType: CaseLawType;
  docuSignRequest: boolean;
};

export type GetMyClientsResponse = BaseData<'User'> &
  User & {
    legalCases: ClientLegalCase[];
    rating: number;
    legalCaseCount: number;
  };

export type SearchClientByEmailResponse = Omit<BackendUserResponse, 'roles' | 'attorneyProfile' | 'setting' | 'phone'>;

export type GetMyClientsParams = Pagination &
  Partial<{
    search: string;
    'attorneyProfile.active': boolean;
    'attorneyProfile.active[]': boolean;
    'attorneyProfile.caseLawTypes.title': string;
    'attorneyProfile.caseLawTypes.title[]': string[];
    'clientLegalCases.caseLawType': string;
    'clientLegalCases.caseLawType[]': string[];
    'clientLegalCases.state.isoCode': string;
    'clientLegalCases.state.isoCode[]': string[];
    'clientLegalCases.country': string;
    'clientLegalCases.country[]': string[];
    'clientLegalCases.status.type': string;
    'clientLegalCases.status.type[]': string[];
    'clientLegalCases.county': string[];
    'clientLegalCases.county[]': string[];
    'attorneyLegalCases.status.type': string;
    'attorneyLegalCases.status.type[]': string[];
    'exists[attorneyProfile]': boolean;
  }>;

export const myClientsApiService = () => {
  const getMyClientsOpenedCases = async (params?: GetMyClientsParams) => {
    const data = await backendApiClient({}).get<ListOfItems<GetMyClientsResponse[]>>(
      'users/my_clients/opened_legal_case',
      {
        params,
      }
    );
    return data;
  };

  const getMyClientsClosedCases = async (params?: GetMyClientsParams) => {
    const data = await backendApiClient({}).get<ListOfItems<GetMyClientsResponse[]>>(
      'users/my_clients/closed_legal_case',
      {
        params,
      }
    );
    return data;
  };

  const getInvitations = async (params?: GetMyClientsParams) =>
    await backendApiClient({}).get<ListOfItems<GetMyLegalCasesResponse[]>>('/legal_cases/invitations', {
      params,
    });

  const searchClientByEmail = async (email: string) =>
    await backendApiClient({}).get<SearchClientByEmailResponse>(`/search/clients/${email}`);

  return {
    getMyClientsOpenedCases,
    getMyClientsClosedCases,
    searchClientByEmail,
    getInvitations,
  };
};

export const ITEMS_PER_PAGE = 30;

const FETCH_OPENED_PARAMS = {
  itemsPerPage: ITEMS_PER_PAGE,
  'clientLegalCases.status.type[]': ['in_progress', 'todo'],
};

export const fetchParams: Record<number, GetMyClientsParams> = {
  0: FETCH_OPENED_PARAMS,
  1: {
    itemsPerPage: ITEMS_PER_PAGE,
    'clientLegalCases.status.type': 'done',
  },
  2: FETCH_OPENED_PARAMS,
};
