import { FC } from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { UsersTable } from './components';
import { TemplateWrapper } from './TemplateWrapper';

export const AttorneysManagementTemplate: FC = () => {
  return (
    <TemplateWrapper>
      <MDBox p={3} lineHeight={1} pb={0}>
        <MDTypography variant="button" color="text">
          Here you can manage attorneys.
        </MDTypography>
      </MDBox>
      <UsersTable
        fetchParams={{
          'attorneyProfile.active[]': [true, false],
          'exists[attorneyProfile]': true,
        }}
        emptyStateMessage="No attorneys"
        isAttorneyPage
      />
    </TemplateWrapper>
  );
};
