import { emailRegExp } from 'features/auth';
import { COMPANY_ROLES } from 'features/company-list';
import * as yup from 'yup';

export type AddAttorneyToCompanySchema = {
  email: string;
  role: COMPANY_ROLES;
};

export const addAttorneyToCompanyValidationSchema: yup.ObjectSchema<AddAttorneyToCompanySchema> = yup
  .object()
  .shape({
    email: yup.string().required('Email field is required').matches(emailRegExp, 'Please enter a valid email'),
    role: yup.string<COMPANY_ROLES>().required(),
  })
  .required();

export type RoleOption = {
  title: string;
  value: COMPANY_ROLES;
};

export const ROLES_OPTIONS: RoleOption[] = [
  {
    title: 'Company admin',
    value: 'ROLE_COMPANY_ADMIN',
  },
  {
    title: 'Company attorney',
    value: 'ROLE_COMPANY_ATTORNEY',
  },
];
