import { ToastType, notice } from 'features/common';
import {
  GetUsersParams,
  GetUsersResponse,
  usersManagementApiService,
} from 'features/users-management/UsersManagementApiService';
import { createStore } from '../createStore';
import { abortRequestErrorHandler } from 'features/common/errorHanders';

export const ERROR_MESSAGE = 'Failed to fetch users, please try again!';

export type UserReviewsState = {
  users: GetUsersResponse[];
  totalItems: number | null;
};

type ActionHandler = (params: GetUsersParams, isAdminPage: boolean) => Promise<void>;

export type UserReviewsActions = {
  getUsers: ActionHandler;
  searchUsers: ActionHandler;
};

const { getUsers, getAdmins } = usersManagementApiService();

const initialState: UserReviewsState = {
  users: [],
  totalItems: null,
};

export const useUsersManagement = createStore<UserReviewsState & UserReviewsActions>(
  set => ({
    ...initialState,
    getUsers: async (params: GetUsersParams, isAdminPage = false) => {
      try {
        const { data } = isAdminPage ? await getAdmins(params) : await getUsers(params);

        set({ users: data['hydra:member'], totalItems: data['hydra:totalItems'] });
      } catch (error) {
        abortRequestErrorHandler(error, ERROR_MESSAGE);
      }
    },
    searchUsers: async (params: GetUsersParams, isAdminPage = false) => {
      try {
        const { data } = isAdminPage ? await getAdmins(params) : await getUsers(params);
        set({ users: data['hydra:member'] });
      } catch (error) {
        notice(ToastType.ERROR, ERROR_MESSAGE);
        console.error(error);
      }
    },
  }),
  'userReviews',
  initialState
);
