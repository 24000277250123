import { FC, useEffect } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import { useRouter } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';

export const DocusignSignProcessCallbackController: FC = () => {
  const router = useRouter();

  useEffect(() => {
    router.navigate(ROUTES.conversations, { replace: true });
  }, []);

  return (
    <Stack sx={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </Stack>
  );
};
