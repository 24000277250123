import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardContent, Divider, Grid, Stack } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { CaseLawType, SideMenu, createAttorneyService, useBoolean, useUser } from 'features/common';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';
import { Loader } from 'features/common/components/Loader';
import { ClioCaseLawType, PracticeAreaCategory, clioUserSettingsApiService } from '../ClioSettingsApiService';
import { LawTypeItem, LawTypeMenuContent } from '../components';

type MatchedAttorneyLawTypesToClioLawTypesAccummulator = Array<{
  attorneyLawType: CaseLawType;
  matchedClioLawTypes: PracticeAreaCategory[];
}>;

export const ClioCaseLawTypesMappingTemplate: FC = () => {
  const { backendUser, isAttorney } = useUser();
  const { getClioCaseLawTypes } = clioUserSettingsApiService();
  const { getAttorneyProfileById } = createAttorneyService();

  const [isSelectLawTypeMenuOpen, onOpenSelectLawTypeMenuHandler, onCloseSelectLawTypeMenuHandler] = useBoolean(false);

  const [isAttorneyLawTypesLoading, setIsAttorneyLawTypesLoading] = useState(true);
  const [isClioLawTypesLoading, setIsClioLawTypesLoading] = useState(true);
  const [attorneyCaseLawTypes, setAttorneyCaseLawTypes] = useState<CaseLawType[]>([]);
  const [clioCaseLawTypes, setClioCaseLawTypes] = useState<ClioCaseLawType[]>([]);
  const [selectedAttorneyLawType, setSelectedAttorneyLawType] = useState<
    MatchedAttorneyLawTypesToClioLawTypesAccummulator[0] | null
  >(null);

  const onGetAttorneyCaseLawTypes = async () => {
    try {
      if (!isAttorney) return;
      const attorneyProfile = await getAttorneyProfileById(
        // TODO conference hack
        backendUser?.attorneyProfile?.['@id']?.replace('/attorney_profiles/', '')
      );
      setIsAttorneyLawTypesLoading(false);
      setAttorneyCaseLawTypes(attorneyProfile.caseLawTypes);
    } catch (error) {
      setIsAttorneyLawTypesLoading(false);
      console.error(error);
      paymentRequiredErrorHandler(error);
    }
  };

  const onGetClioCaseLawTypes = async () => {
    try {
      if (!isAttorney) return;
      const { data } = await getClioCaseLawTypes();
      setClioCaseLawTypes(data['hydra:member']);
      setIsClioLawTypesLoading(false);
    } catch (error) {
      setIsClioLawTypesLoading(false);
      console.error(error);
      paymentRequiredErrorHandler(error);
    }
  };

  useEffect(() => {
    onGetAttorneyCaseLawTypes();
    onGetClioCaseLawTypes();
  }, []);

  const matchedAttorneyLawTypesToClioLawTypes = useMemo(
    () =>
      attorneyCaseLawTypes?.reduce<MatchedAttorneyLawTypesToClioLawTypesAccummulator>((acc, attorneyLawType, index) => {
        acc[index] = { attorneyLawType, matchedClioLawTypes: [] };

        clioCaseLawTypes?.forEach(clioLawType => {
          if (clioLawType.caseLawType?.['@id'] === attorneyLawType?.['@id']) {
            acc[index].matchedClioLawTypes.push(clioLawType.practiceArea);
          }
        });
        return acc;
      }, []),
    [clioCaseLawTypes, attorneyCaseLawTypes]
  );

  const onClickLawTypeItemHandler = useCallback(
    (event: React.MouseEvent<HTMLLIElement>) => {
      const selectedLawTypeData = matchedAttorneyLawTypesToClioLawTypes?.find(
        lawType => lawType.attorneyLawType['@id'] === event.currentTarget.id
      );
      setSelectedAttorneyLawType(selectedLawTypeData);
      onOpenSelectLawTypeMenuHandler();
    },
    [matchedAttorneyLawTypesToClioLawTypes]
  );

  const onCloseSelectLawTypeAndResetStateHandler = () => {
    setSelectedAttorneyLawType(null);
    onCloseSelectLawTypeMenuHandler();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ minHeight: 400, height: 1 }}>
          <CardContent sx={{ p: 3, height: 1 }}>
            <Stack
              spacing={2}
              sx={{
                height: 1,
              }}
            >
              <MDTypography variant="h5" fontWeight="medium">
                Case law types mapping
              </MDTypography>
              {isClioLawTypesLoading || isAttorneyLawTypesLoading ? (
                <Loader />
              ) : matchedAttorneyLawTypesToClioLawTypes.length ? (
                <Stack component="ul" divider={<Divider sx={{ my: '7px' }} />}>
                  {matchedAttorneyLawTypesToClioLawTypes?.map(({ attorneyLawType, matchedClioLawTypes }) => (
                    <LawTypeItem
                      key={attorneyLawType?.['@id']}
                      lawTypeId={attorneyLawType?.['@id']}
                      lawTypeTitle={attorneyLawType?.title}
                      clioLawTypes={matchedClioLawTypes}
                      onClickLawTypeItemHandler={onClickLawTypeItemHandler}
                      isSelected={attorneyLawType?.['@id'] === selectedAttorneyLawType?.attorneyLawType?.['@id']}
                    />
                  ))}
                </Stack>
              ) : (
                <Stack justifyContent="center" flex={1}>
                  <MDTypography sx={{ textAlign: 'center' }}>No law types were found...</MDTypography>
                </Stack>
              )}
            </Stack>
          </CardContent>
        </Card>
        <SideMenu
          isOpen={isSelectLawTypeMenuOpen}
          onClose={onCloseSelectLawTypeAndResetStateHandler}
          title="Law type"
          customWidth={450}
        >
          <LawTypeMenuContent
            selectedAttroneyLawTypeId={selectedAttorneyLawType?.attorneyLawType?.['@id']}
            selectedAttorneyLawTypeTitle={selectedAttorneyLawType?.attorneyLawType?.title}
            matchedClioLawTypes={selectedAttorneyLawType?.matchedClioLawTypes || []}
            onCloseMenuHandler={onCloseSelectLawTypeAndResetStateHandler}
            onGetClioCaseLawTypes={onGetClioCaseLawTypes}
          />
        </SideMenu>
      </Grid>
    </Grid>
  );
};
