import { FC } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import { AllDocumentsMenuContentProps } from 'features/case-details/types';
import { DocumentItem } from './DocumentItem';

export const AllDocumentsMenuContent: FC<AllDocumentsMenuContentProps> = ({
  documents,
  onClickDeleteIconHandler,
  downloadButtonRef,
  onClickDownloadDocumentHandler,
  isAllDocumentsLoading,
  userSignerFirebaseId,
}) => {
  return (
    <Stack
      spacing={1}
      sx={{
        mt: 3,
        pb: 1.5,
        height: 1,
      }}
    >
      {isAllDocumentsLoading && (
        <Stack flex={1} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      {!isAllDocumentsLoading &&
        documents?.map(document => (
          <DocumentItem
            key={document.id}
            title={document?.title}
            id={document.id}
            fileSize={Number(document?.fileSize)}
            ownerFirstName={document?.owner?.userProfile?.firstName}
            ownerLastName={document?.owner?.userProfile?.lastName}
            fileType={document?.type}
            onClickDeleteIconHandler={onClickDeleteIconHandler}
            downloadButtonRef={downloadButtonRef}
            onClickDownloadDocumentHandler={onClickDownloadDocumentHandler}
            filePermission={document?.filePermission}
            userSignerFirebaseId={userSignerFirebaseId}
          />
        ))}
    </Stack>
  );
};
