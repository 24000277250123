import { useEffect, useState } from 'react';
import { ToastType, notice } from 'features/common';
import { EventParticipant, ParticipantCase, myEventsApiService } from 'features/my-events/myEventsApiService';
import { UseUsersStateProps } from '../types';

export const useUsersState = ({ isAttorney, legalCaseId, eventParticipants, userFirebaseId }: UseUsersStateProps) => {
  const { getParticipantCases, getParticipants } = myEventsApiService();

  const [userList, setUserList] = useState<EventParticipant[]>([]);

  const [legalCases, setLegalCases] = useState<ParticipantCase[]>([]);
  const [isUsersFetching, setIsUsersFetching] = useState(false);
  const [isCasesFetching, setIsCasesFetching] = useState(false);

  const currentUser = userFirebaseId ? eventParticipants?.find(el => el?.user?.firebaseUser !== userFirebaseId) : null;

  const onFetchClientsHandler = async () => {
    try {
      setIsUsersFetching(true);
      const { data } = await getParticipants({
        itemsPerPage: 999,
        page: 1,
        'clientLegalCases.status.type[]': ['in_progress', 'todo'],
      });

      setIsUsersFetching(false);
      setUserList(data['hydra:member']);
    } catch (error) {
      setIsUsersFetching(false);
      notice(ToastType.ERROR, 'Failed to fetch clients, please try again!');
      console.error(error);
    }
  };

  const onFetchAttorneysHandler = async () => {
    try {
      setIsUsersFetching(true);
      const { data } = await getParticipants({
        itemsPerPage: 999,
        page: 1,
        'attorneyLegalCases.status.type[]': ['todo', 'in_progress'],
      });
      setUserList(data['hydra:member']);
      setIsUsersFetching(false);
    } catch (error) {
      setIsUsersFetching(false);
      notice(ToastType.ERROR, 'Failed to fetch attorneys, please try again!');
      console.error(error);
    }
  };

  const onFetchParticipantCasesHandler = async (participant: string) => {
    try {
      setIsCasesFetching(true);
      const { data } = await getParticipantCases({
        itemsPerPage: 999,
        page: 1,
        participant,
      });
      setLegalCases(data['hydra:member']);
      setIsCasesFetching(false);
    } catch (error) {
      setIsCasesFetching(false);
      notice(ToastType.ERROR, 'Failed to fetch cases, please try again!');
      console.error(error);
    }
  };

  useEffect(() => {
    if (!legalCaseId) {
      isAttorney ? onFetchClientsHandler() : onFetchAttorneysHandler();
    }
  }, []);

  return {
    setLegalCases,
    onFetchParticipantCasesHandler,
    isCasesFetching,
    isUsersFetching,
    userList,
    currentUser,
    legalCases,
  };
};
