import { FC } from 'react';
import { Grid } from '@mui/material';
import { DashboardLayout, useUser } from 'features/common';
import { CREATE_CASE_FORM_STEPS, STEPS } from 'features/store';
import MDBox from 'components/MDBox';
import { AttoneyClientInformation } from 'features/my-clients/components/AttorneyCreateCase';
import { ClientInformation } from './components/CreateLegalCase/ClientInformation';
import { CaseInformation } from './components/CreateLegalCase/CaseInformation';
import { DependantInformation } from './components/CreateLegalCase/DependantInformation';
import { Notes } from './components/CreateLegalCase/Notes';
import { Documents } from './components/CreateLegalCase/Documents/Documents';
import { CreateCaseStepper } from './components/CreateLegalCase/CreateCaseStepper';
import { ReviewMyLegalCaseTemplate } from './ReviewMyLegalCaseTemplate';
import { useCreateMyLegalCaseState } from './hooks/useCreateMyLegalCaseState';

export function getStepContent(stepIndex: CREATE_CASE_FORM_STEPS, isAttorneyView: boolean): JSX.Element {
  switch (stepIndex) {
    case CREATE_CASE_FORM_STEPS.CLIENT_INFO:
      return isAttorneyView ? <AttoneyClientInformation /> : <ClientInformation />;
    case CREATE_CASE_FORM_STEPS.CASE_INFO:
      return <CaseInformation isAttorneyView={isAttorneyView} />;
    case CREATE_CASE_FORM_STEPS.DEPENDANT_INFO:
      return <DependantInformation />;
    case CREATE_CASE_FORM_STEPS.DOCUMENTS:
      return <Documents />;
    case CREATE_CASE_FORM_STEPS.NOTES:
      return <Notes isAttorneyView={isAttorneyView} />;
    default:
      return null;
  }
}

export const CreateMyLegalCaseTemplate: FC = () => {
  const { activeStep, currentStepIndex } = useCreateMyLegalCaseState();

  const isAttorneyRole = useUser()?.isAttorney;
  return (
    <>
      {activeStep === CREATE_CASE_FORM_STEPS.CASE_REVIEW ? (
        <ReviewMyLegalCaseTemplate isAttorneyView={isAttorneyRole} />
      ) : (
        <DashboardLayout>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CreateCaseStepper activeStep={currentStepIndex} steps={STEPS} />
              <MDBox p={3}>
                <MDBox>{getStepContent(activeStep, isAttorneyRole)}</MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </DashboardLayout>
      )}
    </>
  );
};
