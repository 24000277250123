import { FC } from 'react';
import { Add } from '@mui/icons-material';
import { AppBar, Toolbar as MUIToolbar } from '@mui/material';
import { useResponsive } from 'features/common/hooks/useResponsive';
import { Tabs } from 'features/common/components/Tabs';
import { useAppStatisticContextState } from 'features/contexts/AppStatisticsContextProvider';
import MDButton from 'components/MDButton';
import { TABS_DATA } from './toolbar.config';

type ToolbarProps = {
  tabValue?: number;
  onAddIconClickHandler: () => void;
  handleSetTabValue: (event: React.SyntheticEvent<Element, Event>, newValue: number) => void;
};

export const Toolbar: FC<ToolbarProps> = ({ tabValue, handleSetTabValue, onAddIconClickHandler }) => {
  const isSmallScreen = useResponsive('down', 'sm');
  const { userStatistic } = useAppStatisticContextState();

  return (
    <AppBar position="static">
      <MUIToolbar
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          '.MuiTabs-root': {
            maxWidth: 350,
            width: { xs: 1 },
          },
        }}
      >
        <Tabs
          tabsData={TABS_DATA.slice(0, 2)}
          bagesValues={[userStatistic?.openLegalCaseCount, userStatistic?.closedLegalCaseCount]}
          tabValue={tabValue}
          orientation={isSmallScreen ? 'vertical' : 'horizontal'}
          handleSetTabValue={handleSetTabValue}
        />
        <MDButton
          sx={{ mt: { xs: 1, sm: 0 }, ml: 1 }}
          variant="gradient"
          color="info"
          size="small"
          onClick={onAddIconClickHandler}
        >
          <Add sx={{ width: 20, height: 20, mr: '2px', mb: '2px' }} />
          Create case
        </MDButton>
      </MUIToolbar>
    </AppBar>
  );
};
