import { FC, useCallback, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { shallow } from 'zustand/shallow';
import { ToastType, controller, notice, useRouter, useUser } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';
import { useLockScreen } from 'features/store';
import { firebaseAuth } from 'features/auth';
import { resetters } from 'features/store/createStore';

const DEFAULT_IDLE_TIMEOUT_VALUE = 300000;

const NOT_ALLOWED_ROUTES = [
  ROUTES.lockScreen,
  ROUTES.signIn,
  ROUTES.signUp,
  ROUTES.recoveryPassword,
  ROUTES.resetPassword,
  ROUTES.firebaseSecurity,
  ROUTES.verifyUserEmail,
  ROUTES.adminSignUp,
  ROUTES.docusignSignProccessCallback,
  ROUTES.docusignRedirect,
  ROUTES.subscription,
  ROUTES.successPayment,
  ROUTES.cancelPayment,
  ROUTES.videoRoom,
  ROUTES.audioRoom,
  ROUTES.incominCallPush,
];

const USERS_STATE_NAMES = [
  'audioVideoChat',
  'myLegalCases',
  'myClients',
  'caseDetails',
  'myChats',
  'myCompany',
  'myEvents',
  'legalCasesFeed',
];
const ADMIN_STATE_NAMES = ['adminSettings', 'userReviews'];

const ONE_DAY_IN_MILLISEC = 1 * 24 * 60 * 60 * 1000;

export const LockScreenGuard: FC = (): null => {
  const router = useRouter();

  const { isPinCodeActive: isShouldUseLockScreen, isAdmin } = useUser();

  const {
    lastIdleConfigExecutionTime,
    onGetTimeToIdleHandler,
    registerDeviceHandler,
    setIsRedirectToLockScreen,
    isRedirectToLockScreen,
    idleConfigValue,
    deviceId,
    isStopIdleTimer,
    setIsDeactivateIdleTimer,
  } = useLockScreen(
    state => ({
      isRedirectToLockScreen: state.isRedirectToLockScreen,
      setIsRedirectToLockScreen: state.setIsRedirectToLockScreen,
      idleConfigValue: state.idleConfigData?.configValue,
      deviceId: state.deviceId,
      onGetTimeToIdleHandler: state.onGetTimeToIdleHandler,
      registerDeviceHandler: state.registerDeviceHandler,
      lastIdleConfigExecutionTime: state.lastIdleConfigExecutionTime,
      isStopIdleTimer: state.isDeactivateIdleTimer,
      setIsDeactivateIdleTimer: state.setIsDeactivateIdleTimer,
    }),
    shallow
  );

  const idleValueInMS = Number(idleConfigValue) * 1000 || DEFAULT_IDLE_TIMEOUT_VALUE;

  const checkIsRouteValid = () => !NOT_ALLOWED_ROUTES.includes(router?.pathname as ROUTES);

  const onIdleHandler = useCallback(async () => {
    await firebaseAuth.signOut();

    const stateNames = isAdmin ? ADMIN_STATE_NAMES : USERS_STATE_NAMES;

    resetters.forEach(resetter => {
      if (stateNames.includes(resetter?.name)) resetter?.resetFunction();
    });

    setIsRedirectToLockScreen(true);
  }, [isAdmin]);

  const { start, pause, getRemainingTime } = useIdleTimer({
    onIdle: onIdleHandler,
    timeout: idleValueInMS,
    throttle: 200,
    crossTab: true,
    name: 'lockScreenGuard',
    startManually: true,
    stopOnIdle: true,
  });

  const onGetTimerData = async () => {
    if (!checkIsRouteValid()) return;

    const promises: Promise<void>[] = [];

    if (!idleConfigValue && isShouldUseLockScreen) {
      promises.push(onGetTimeToIdleHandler());
    }

    if (
      idleConfigValue &&
      isShouldUseLockScreen &&
      lastIdleConfigExecutionTime &&
      Date.now() - parseInt(lastIdleConfigExecutionTime, 10) >= ONE_DAY_IN_MILLISEC
    ) {
      promises.push(onGetTimeToIdleHandler());
    }
    if (!deviceId && isShouldUseLockScreen) promises.push(registerDeviceHandler());

    try {
      if (promises.length) await Promise.allSettled(promises);
    } catch (error) {
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  };

  useEffect(() => {
    onGetTimerData();
  }, [router.pathname]);

  useEffect(() => {
    if (!isShouldUseLockScreen) return;

    if (isRedirectToLockScreen && checkIsRouteValid()) {
      controller.abort();
    }
  }, [router.pathname, isShouldUseLockScreen]);

  // START / PAUSE TIMER and Redirect back to lock screen
  const isStartTimer = checkIsRouteValid() && isShouldUseLockScreen && !isRedirectToLockScreen;
  const isPauseTimer = !isRedirectToLockScreen && isShouldUseLockScreen && !checkIsRouteValid() && getRemainingTime();
  const isNavigateToLockScreen = checkIsRouteValid() && isRedirectToLockScreen && isShouldUseLockScreen;

  useEffect(() => {
    if (isStartTimer) {
      start();
    }

    if (isNavigateToLockScreen) router.navigate(ROUTES.lockScreen, { state: { prevRoute: router.pathname } });

    if (isPauseTimer) pause();
  }, [isStartTimer, isPauseTimer, router.pathname]);

  useEffect(() => {
    if (!isShouldUseLockScreen && isStopIdleTimer) {
      pause();
      setIsDeactivateIdleTimer(false);
    }
  }, [isStopIdleTimer, isShouldUseLockScreen]);

  return null;
};
