import { FC } from 'react';
import { DashboardLayout } from 'features/common';
import { Card, CardContent, CardHeader, Grid, Stack } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDBadge from 'components/MDBadge';
import { DependantInfoItem } from 'features/case-details/components/DependantInfoItem';
import { DependantInfo } from 'features/case-details/components/DependantInfo';
import { dateToCustomFormat } from 'features/common/helpers/date';
import { AddDocumentActionButton } from 'features/case-details/components/Documents';
import { DocumentItem } from 'features/case-details/components/Documents/DocumentItem';
import { NotesCard } from 'features/case-details/components/Notes/NotesCard';
import { CRMIntegrationCaseInfo, CaseInfoHeader } from 'features/case-details/components/CaseInfo';
import { CardAddButton } from 'features/case-details/components/CardAddButton';
import { useReviewState } from './hooks/useReviewState';
import { ButtonsFooter } from './components/ReviewLegalCase/ButtonsFooter';
import ClioLogo from 'assets/images/icons/logo-clio.svg';

type ReviewMyLegalCaseTemplateProps = {
  isAttorneyView?: boolean;
};

export const ReviewMyLegalCaseTemplate: FC<ReviewMyLegalCaseTemplateProps> = ({ isAttorneyView }) => {
  const {
    caseStatus,
    dependantData,
    documentsData,
    isLoading,
    caseInformationData,
    notesData,
    backendUser,
    clientData,
    CRMCaseDocuments,
    CRMCasenotes,
    CRMCaseData,
    onCreateCaseHandler,
    onGoBackHandler,
  } = useReviewState(isAttorneyView);

  return (
    <DashboardLayout>
      <Grid container spacing={2}>
        {isAttorneyView && (
          <Grid item xs={12}>
            <Card>
              <CardContent sx={{ pt: 2, px: 1, pb: 0 }}>
                <CaseInfoHeader
                  userData={{
                    firstName: clientData.firstName,
                    lastName: clientData.lastName,
                    rating: clientData.clientRating || 0,
                    caseCount: clientData.clientCaseCount || 0,
                    logoURL: clientData?.clientLogoURL,
                  }}
                  isDisableActions
                />
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={7} lg={8}>
          <Card sx={{ boxShadow: 'none', width: 1 }}>
            <CardHeader
              title="Case Details"
              action={
                <MDBox
                  sx={{
                    p: 0.5,
                    '.MuiCardHeader-action': {
                      alignSelf: 'center',
                    },
                    display: 'flex',
                  }}
                >
                  <ModeEditIcon color="disabled" />
                </MDBox>
              }
            />
            <CardContent sx={{ pb: '8px important', px: 2 }}>
              <Stack
                spacing={1}
                sx={{ ...(!dependantData.isCaseForDependant && { maxHeight: 310, overflowY: 'auto' }) }}
              >
                <Stack direction="row" justifyContent="space-between">
                  <MDTypography fontWeight="medium" noWrap width="90%">
                    {caseInformationData?.caseNumber || 123456789}
                  </MDTypography>
                  <MDBadge
                    size="xs"
                    sx={{
                      '.MuiBadge-badge': {
                        background: caseStatus.bgColor,
                      },
                    }}
                    badgeContent={caseStatus.title}
                    container
                  />
                </Stack>

                {CRMCaseData && (
                  <CRMIntegrationCaseInfo logoSrc={ClioLogo} originalCaseNumber={CRMCaseData?.caseNumber} />
                )}

                <Stack spacing={3}>
                  <MDTypography fontWeight="light">{caseInformationData?.description}</MDTypography>
                  <Stack>
                    <DependantInfoItem title="Law type" titleValue={caseInformationData?.caseLawTypeTitle} />
                    <DependantInfoItem title="Case state" titleValue={caseInformationData?.caseStateTitle} />
                    <DependantInfoItem title="Case county" titleValue={caseInformationData?.caseCountyTitle} />
                    <DependantInfoItem title="Created At" titleValue={dateToCustomFormat(new Date())} />
                  </Stack>
                  {dependantData?.isCaseForDependant && (
                    <Stack>
                      <MDTypography fontWeight="medium">Dependant information</MDTypography>
                      <DependantInfo
                        dependantFirstName={dependantData?.dependantFirstName}
                        dependantLastName={dependantData?.dependantLastName}
                        dependantStateOfResidence={dependantData?.dependantCaseStateTitle}
                        dependantUnder18={dependantData.isUnder18}
                      />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={5} lg={4}>
          <Card sx={{ width: 1, height: 1 }}>
            <CardHeader title="Documents" action={<AddDocumentActionButton />} />
            {!documentsData?.documents?.length && !documentsData.isDocuments && !CRMCaseDocuments.length ? (
              <Stack flex={1} justifyContent="center" alignItems="center" minHeight={150}>
                <MDTypography sx={{ textAlign: 'center' }}>No documents were found...</MDTypography>
              </Stack>
            ) : (
              <CardContent sx={{ px: 2, pb: '8px !important', flex: 1 }}>
                <Stack
                  sx={{
                    maxHeight: dependantData.isCaseForDependant ? 440 : 400,
                    overflowY: 'auto',
                  }}
                >
                  {documentsData?.documents.map(file => (
                    <DocumentItem
                      key={file.id}
                      title={file?.title}
                      id={String(file.id)}
                      fileSize={Number(file.fileSize)}
                      ownerFirstName={backendUser?.userProfile?.firstName}
                      ownerLastName={backendUser?.userProfile?.lastName}
                      fileType={file?.type}
                      isDisableActions
                    />
                  ))}

                  {CRMCaseDocuments?.map(file => (
                    <DocumentItem
                      key={file.id}
                      title={file?.title}
                      id={String(file.id)}
                      fileSize={Number(file?.size)}
                      ownerFirstName={backendUser?.userProfile?.firstName}
                      ownerLastName={backendUser?.userProfile?.lastName}
                      fileType="document"
                      isDisableActions
                    />
                  ))}
                </Stack>
              </CardContent>
            )}
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ width: 1, height: 1, minHeight: 200 }}>
            <CardHeader sx={{ px: 2 }} title="My Notes" action={<CardAddButton />} />

            {!notesData?.isAddNotes && !CRMCasenotes.length ? (
              <Stack flex={1} justifyContent="center" alignItems="center">
                <MDTypography>No notes were found...</MDTypography>
              </Stack>
            ) : (
              <CardContent sx={{ px: 2, pb: '10px !important' }}>
                <Stack
                  spacing={2}
                  sx={{
                    pb: 1,
                    pr: 0.5,
                    maxHeight: 400,
                    overflowY: 'auto',
                  }}
                >
                  {notesData?.isAddNotes
                    ? notesData?.notes?.map(note => (
                        <NotesCard
                          key={note.noteTitle}
                          visible={note?.isVisible}
                          title={note?.noteTitle}
                          createdAt={new Date().toISOString()}
                          description={note?.noteBody}
                          isDisabledActionButton
                          isAttorneyView={isAttorneyView}
                        />
                      ))
                    : null}

                  {CRMCasenotes?.length
                    ? CRMCasenotes?.map(note => (
                        <NotesCard
                          key={note.id}
                          visible={false}
                          title={note?.title || ''}
                          createdAt={note?.createdAt}
                          description={note?.description}
                          isDisabledActionButton
                          isAttorneyView={isAttorneyView}
                        />
                      ))
                    : null}
                </Stack>
              </CardContent>
            )}
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ width: 1, height: 1, minHeight: 200 }}>
            <CardHeader sx={{ px: 2 }} title={`${isAttorneyView ? 'Client' : 'Attorney'} Notes`} />

            <Stack flex={1} justifyContent="center" alignItems="center">
              <MDTypography>No notes were found...</MDTypography>
            </Stack>
          </Card>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          position: 'sticky',
          bottom: '15px',
          zIndex: theme => theme.zIndex.fab,
        }}
      >
        <ButtonsFooter
          isLoading={isLoading}
          onGoBackHandler={onGoBackHandler}
          onCreateCaseHandler={onCreateCaseHandler}
        />
      </Grid>
    </DashboardLayout>
  );
};
