import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Card, CardContent, CardHeader, CircularProgress, Stack, Switch } from '@mui/material';
import { BaseFormField, CountryState, ToastType, createGeoDataService, notice } from 'features/common';
import MDTypography from 'components/MDTypography';
import { CREATE_CASE_FORM_STEPS, useCreateCase } from 'features/store';
import MDBox from 'components/MDBox';
import { DependantInformationScheme, dependantInformationFormSchema } from './form.config';
import { ButtonsFooter } from './ButtonsFooter';

export const DependantInformation: FC = () => {
  const { saveFormDataHandler, activeStep, dependantInformationData } = useCreateCase(state => ({
    dependantInformationData: state.dependantData,
    activeStep: state.activeStep,
    saveFormDataHandler: state.setData,
  }));

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    watch,
    getValues,
  } = useForm<DependantInformationScheme>({
    resolver: yupResolver(dependantInformationFormSchema),
    mode: 'onTouched',
    defaultValues: {
      ...dependantInformationData,
    },
  });

  const onFormSubmitHandler = handleSubmit(formData => {
    return saveFormDataHandler({
      step: activeStep,
      data: { ...formData, dependantCaseStateTitle },
      activeStep: CREATE_CASE_FORM_STEPS.DOCUMENTS,
    });
  });

  const [stateList, setStateList] = useState<CountryState[]>([]);
  const [isStatesLoading, setIsStatesLoading] = useState(false);
  const [dependantCaseStateTitle, setDependantCaseStateTitle] = useState(
    dependantInformationData.dependantCaseStateTitle
  );

  const { getStates } = createGeoDataService();

  const getStateListHandler = async () => {
    try {
      setIsStatesLoading(true);
      const { data } = await getStates({});
      setStateList(data['hydra:member']);
      setIsStatesLoading(false);
    } catch (error) {
      setIsStatesLoading(false);
      notice(ToastType.ERROR, 'Failed to fetch states, please try again!');
      console.error(error);
    }
  };

  useEffect(() => {
    getStateListHandler();
  }, []);

  const onClickBackButtonHandler = () => {
    saveFormDataHandler({
      ...(isDirty && {
        step: activeStep,
        data: { ...getValues(), dependantCaseStateTitle },
      }),
      activeStep: CREATE_CASE_FORM_STEPS.CASE_INFO,
    });
  };

  return (
    <form onSubmit={onFormSubmitHandler}>
      <Card>
        <CardHeader sx={{ px: 3, pb: 0 }} title="Is This Case for a Dependent?" />
        <CardContent>
          <MDTypography variant="subtitle2">
            Indicate whether you're acting as a legal guardian, executor, or representative for the party involved in
            this case.
          </MDTypography>
          <Stack spacing={1}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
            >
              <MDTypography variant="button" color="text" sx={{ width: 170 }}>
                Representing a dependant
              </MDTypography>

              <Switch {...register('isCaseForDependant')} checked={watch('isCaseForDependant')} />
            </Stack>
            {watch('isCaseForDependant') && (
              <Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} gap={{ xs: 0, sm: 3 }} width={1}>
                  <Stack width={1} gap={1}>
                    <BaseFormField
                      formInputProps={{
                        ...register('dependantFirstName'),
                        placeholder: 'Enter dependent’s first name',
                        type: 'text',
                        label: `Dependent's first name`,
                        fullWidth: true,
                        InputLabelProps: { shrink: true },
                      }}
                      errorValue={errors['dependantFirstName']?.message}
                    />
                    <BaseFormField
                      formFieldProps={{
                        mb: { xs: 1, sm: 0 },
                      }}
                      formInputProps={{
                        ...register('dependantLastName'),
                        placeholder: 'Enter dependent’s last name',
                        type: 'text',
                        label: `Dependent's last name`,
                        fullWidth: true,
                        InputLabelProps: { shrink: true },
                      }}
                      errorValue={errors['dependantLastName']?.message}
                    />
                  </Stack>
                  <Controller
                    control={control}
                    name="dependantCaseState"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Autocomplete
                          value={
                            value
                              ? stateList.find(option => {
                                  return value === option['@id'];
                                }) ?? null
                              : null
                          }
                          sx={{
                            width: 1,
                            '.MuiInputBase-root': {
                              py: '4.5px',
                            },
                          }}
                          onChange={(_, value) => {
                            onChange(value ? value['@id'] : '');
                            setDependantCaseStateTitle(value?.name || '');
                          }}
                          getOptionLabel={option => option.name}
                          options={stateList}
                          freeSolo={false}
                          loading={isStatesLoading}
                          isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                          disableClearable={false}
                          renderInput={params => (
                            <BaseFormField
                              formFieldProps={{ pb: { xs: 1, sm: 0 } }}
                              formInputProps={{
                                ...params,
                                label: 'State of residence',
                                placeholder: `Select dependent's state`,
                                InputLabelProps: { shrink: true },
                                fullWidth: true,
                                InputProps: {
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {isStatesLoading ? (
                                        <MDBox pr={0} display="flex">
                                          <CircularProgress color="inherit" size={20} />
                                        </MDBox>
                                      ) : null}
                                      {errors['dependantCaseState']?.message ? null : params.InputProps.endAdornment}
                                    </>
                                  ),
                                },
                              }}
                              errorValue={errors['dependantCaseState']?.message}
                            />
                          )}
                        />
                      );
                    }}
                  />
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
                >
                  <MDTypography variant="button" color="text" sx={{ width: 170 }}>
                    Is the dependent a minor (under 18)?
                  </MDTypography>

                  <Switch {...register('isUnder18')} checked={watch('isUnder18')} />
                </Stack>
                <MDTypography variant="subtitle2" pb={1}>
                  Note: If the dependent is a minor, your presence is required for all communications with an attorney.
                </MDTypography>
              </Stack>
            )}
          </Stack>

          <ButtonsFooter isSubmitButtonDisabled={!isValid} onClickBackButtonHandler={onClickBackButtonHandler} />
        </CardContent>
      </Card>
    </form>
  );
};
