import { ToastType, notice } from 'features/common';
import { GetMyLegalCasesParams } from 'features/my-legal-cases/MyLegalCasesApiService';
import {
  GetLegalCasesFeedResponse,
  legalCasesFeedApiService,
} from 'features/legal-cases-feed/LegalCasesFeedApiService';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders/paymentRequiredErrorHandler';
import { createStore } from '../createStore';

export type FiltersData = Pick<GetMyLegalCasesParams, 'caseLawType[]'> & {
  county?: string | null;
  'state.isoCode'?: string | null;
};
export type LegalCasesFeed = {
  isLoading: boolean;
  isFetchMoreLoading: boolean;
  legalCasesFeed: GetLegalCasesFeedResponse[];
  totalItems: number | null;
  filtersData?: FiltersData;
};

export type LegalCasesFeedActions = {
  fetchLegalCasesFeedHandler: (params?: GetMyLegalCasesParams) => Promise<void>;
  fetchMoreLegalCasesFeedHandler: (params?: GetMyLegalCasesParams) => Promise<void>;
  setFiltersDataHandler: (params?: GetMyLegalCasesParams) => void;
};

const { getLegalCasesFeeds } = legalCasesFeedApiService();

const initialState: LegalCasesFeed = {
  isLoading: false,
  isFetchMoreLoading: false,
  legalCasesFeed: [],
  totalItems: null,
  filtersData: {
    'caseLawType[]': [],
    county: null,
    'state.isoCode': null,
  },
};

export const useLegalCasesFeed = createStore<LegalCasesFeed & LegalCasesFeedActions>(
  set => ({
    ...initialState,
    setFiltersDataHandler: filtersData => {
      set({ filtersData });
    },
    fetchLegalCasesFeedHandler: async params => {
      try {
        set({
          isLoading: true,
        });
        const { data } = await getLegalCasesFeeds(params);
        set({ legalCasesFeed: data['hydra:member'], totalItems: data['hydra:totalItems'], isLoading: false });
      } catch (error) {
        set({
          isLoading: false,
        });
        paymentRequiredErrorHandler(error, 'Failed to fetch legal cases feed, please try again!');

        console.error(error);
      }
    },
    fetchMoreLegalCasesFeedHandler: async params => {
      try {
        set({
          isFetchMoreLoading: true,
        });
        const { data } = await getLegalCasesFeeds(params);
        set(state => ({
          legalCasesFeed: [...state.legalCasesFeed, ...data['hydra:member']],
          isFetchMoreLoading: false,
        }));
      } catch (error) {
        set({
          isFetchMoreLoading: false,
        });
        paymentRequiredErrorHandler(error, 'Failed to fetch legal cases feed, please try again!');
        console.error(error);
      }
    },
  }),
  'legalCasesFeed',
  initialState
);
