import { useCallback, useEffect, useState } from 'react';
import { AttorneyProfileCaseLawType, createAttorneyService } from '../services';
import { useBoolean } from './useBoolean';
import { useGeoData } from './useGeoData';
import { paymentRequiredErrorHandler } from '../errorHanders';
import { ToastType, notice } from '../modules';

export const useAttorneyStatesLawTypes = () => {
  const { getAttorneyProfileCaseLawTypes, deleteAttorneyProfileCaseLawTypesById } = createAttorneyService();

  const [attoreyProfileCaseLawTypes, setAttoreyProfileCaseLawTypes] = useState<AttorneyProfileCaseLawType[]>([]);

  const [selectedAttorneyLawType, setSelectedAttorneyLawType] = useState<AttorneyProfileCaseLawType | null>(null);

  const [isSelectStateMenuOpen, onOpenSelectStateMenuHandler, onCloseSelectStateMenuHandler] = useBoolean(false);

  const [isDeleteCaseLawTypeDialogOpen, onOpenDeleteCaseLawTypeDialogHandler, onCloseDeleteCaseLawTypeDialogHandler] =
    useBoolean(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);

  const onClickDeleteAttorneyCaseLawTypeButton = async () => {
    try {
      onCloseDeleteCaseLawTypeDialogHandler();
      setIsDeleting(true);
      await deleteAttorneyProfileCaseLawTypesById(
        selectedAttorneyLawType['@id'].replace('/attorney_profile_case_law_types/', '')
      );
      const { data } = await getAttorneyProfileCaseLawTypes({ page: 1, itemsPerPage: 100 });
      setAttoreyProfileCaseLawTypes(data['hydra:member']);
      onCloseSelectStateMenuHandler();
      notice(ToastType.SUCCESS, 'Case law type has been successfully deleted!');
      setIsDeleting(false);
    } catch (error) {
      setIsDeleting(false);
      console.error(error);
      paymentRequiredErrorHandler(error);
    }
  };

  const { getStateListHandler, isStateListLoading, countryStateList } = useGeoData(false);

  const onGetAttorneyProfileCaseLawTypes = async () => {
    try {
      const { data } = await getAttorneyProfileCaseLawTypes({ page: 1, itemsPerPage: 500 });
      setAttoreyProfileCaseLawTypes(data['hydra:member']);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      paymentRequiredErrorHandler(error);
    }
  };

  const onClickLawTypeItemHandler = useCallback(
    (event: React.MouseEvent<HTMLLIElement>) => {
      const selectedLawTypeData = attoreyProfileCaseLawTypes?.find(
        lawType => lawType?.['@id'] === event.currentTarget.id
      );
      setSelectedAttorneyLawType(selectedLawTypeData);
      onOpenSelectStateMenuHandler();
    },
    [attoreyProfileCaseLawTypes]
  );

  const onCloseSelectLawTypeAndResetStateHandler = () => {
    setSelectedAttorneyLawType(null);
    onCloseSelectStateMenuHandler();
  };

  useEffect(() => {
    onGetAttorneyProfileCaseLawTypes();
    getStateListHandler();
  }, []);

  return {
    attoreyProfileCaseLawTypes,
    isLoading,
    selectedAttorneyLawType,
    isSelectStateMenuOpen,
    isStateListLoading,
    countryStateList,
    onClickLawTypeItemHandler,
    onCloseSelectLawTypeAndResetStateHandler,
    setAttoreyProfileCaseLawTypes,
    onClickDeleteAttorneyCaseLawTypeButton,
    isDeleteCaseLawTypeDialogOpen,
    onOpenDeleteCaseLawTypeDialogHandler,
    isDeleting,
    onCloseDeleteCaseLawTypeDialogHandler,
  };
};
