import { useMemo, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { useBoolean } from 'features/common';
import { useDebounce } from 'features/common/hooks/useDebounce';
import { useIsOnScreen } from 'features/common/hooks/useIsOnScreen';
import { useLegalCasesFeed } from 'features/store';
import { ITEMS_PER_PAGE } from '../LegalCasesFeedApiService';

export const useLegalCasesFeedState = () => {
  const [isFiltersMenuOpen, onOpenFiltersMenuHandler, onCloseFiltersMenuHandler] = useBoolean(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');

  const {
    fetchLegalCasesFeedHandler,
    fetchMoreLegalCasesFeedHandler,
    legalCasesFeed,
    isLoading,
    totalItems,
    isFetchMoreLoading,
    filtersData,
  } = useLegalCasesFeed(
    state => ({
      legalCasesFeed: state.legalCasesFeed,
      fetchLegalCasesFeedHandler: state.fetchLegalCasesFeedHandler,
      isLoading: state.isLoading,
      totalItems: state.totalItems,
      isFetchMoreLoading: state.isFetchMoreLoading,
      fetchMoreLegalCasesFeedHandler: state.fetchMoreLegalCasesFeedHandler,
      filtersData: state.filtersData,
    }),
    shallow
  );

  const lastElementRef = useRef<HTMLDivElement | null>(null);
  const isOnScreen = useIsOnScreen(isLoading ? null : lastElementRef);

  const pages: Readonly<number> = Math.ceil(totalItems / ITEMS_PER_PAGE);

  const onChangeSearchInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value);
  const debounceValue = useDebounce(searchValue);

  const filtersCount = useMemo(
    () =>
      Object.values(filtersData).reduce((acc, el) => {
        if (!Array.isArray(el) && el) {
          acc += 1;
        } else {
          acc += el?.length || 0;
        }

        return acc;
      }, 0),
    [filtersData]
  );

  return {
    pages,
    debounceValue,
    isOnScreen,
    isFetchMoreLoading,
    isLoading,
    legalCasesFeed,
    currentPage,
    isFiltersMenuOpen,
    lastElementRef,
    filtersData,
    filtersCount,
    onOpenFiltersMenuHandler,
    fetchMoreLegalCasesFeedHandler,
    fetchLegalCasesFeedHandler,
    setCurrentPage,
    onChangeSearchInputHandler,
    onCloseFiltersMenuHandler,
  };
};
