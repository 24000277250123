import { FC, memo } from 'react';
import { Stack } from '@mui/material';
import MDBadge from 'components/MDBadge';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { BaseAvatar } from 'features/common/components/BaseAvatar';
import { CardFooter } from 'features/my-legal-cases/components/CardFooter';
import { LegalCaseCardProps } from 'features/my-legal-cases/components/types';
import { useRouter } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';

export const InvitationCard: FC<
  Pick<
    LegalCaseCardProps,
    | 'caseNumber'
    | 'createdAt'
    | 'description'
    | 'firstName'
    | 'lastName'
    | 'stateName'
    | 'lastElementRef'
    | 'statusBgColor'
    | 'statusTitle'
    | 'countyName'
    | 'countryId'
    | 'caseId'
  > & { email: string }
> = memo(
  ({
    caseNumber,
    createdAt,
    description,
    firstName,
    lastName,
    stateName,
    lastElementRef,
    statusBgColor,
    statusTitle,
    email,
    countyName,
    countryId,
    caseId,
  }) => {
    const router = useRouter();
    const onClickOnCardHandler = () => router.push(`${ROUTES.myLegalCases}/${caseId}`);

    return (
      <MDBox
        ref={lastElementRef}
        borderRadius="xl"
        p={1.5}
        pr={1.6}
        bgColor="white"
        sx={{
          height: 1,
          position: 'relative',
          fontSize: ({ typography: { size } }) => size.md,
          ':hover': {
            cursor: 'pointer',
          },
        }}
        onClick={onClickOnCardHandler}
      >
        <Stack spacing={1.5} height={1}>
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <BaseAvatar userFirstName={firstName} userLastName={lastName} />
            <Stack>
              <MDTypography variant="button" fontWeight="medium">
                {!firstName && !lastName ? 'Unrepresented' : `${firstName} ${lastName}`}
              </MDTypography>
              <MDTypography variant="button">{email}</MDTypography>
            </Stack>
          </Stack>

          <Stack direction="row" spacing={1.5}>
            <MDTypography
              variant="button"
              sx={{
                fontWeight: 800,
              }}
            >
              {caseNumber}
            </MDTypography>
            <MDBadge
              size="xs"
              sx={{
                '.MuiBadge-badge': {
                  background: statusBgColor,
                },
              }}
              badgeContent={statusTitle}
              container
            />
          </Stack>

          <MDTypography
            variant="button"
            sx={{
              fontWeight: 400,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 4,
              MozLineClamp: 4,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flex: 1,
            }}
          >
            {description}
          </MDTypography>

          <CardFooter
            createdAt={createdAt}
            stateName={stateName}
            countryId={countryId}
            countyName={countyName}
            isShowDependant={false}
          />
        </Stack>
      </MDBox>
    );
  }
);
