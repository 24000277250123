import { FC } from 'react';
import { Switch } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

type NotificationItemProps = {
  title: string;
  isChecked: boolean;
  id: string;
  subtitle?: string;
  onChangehandler: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export const NotificationItem: FC<NotificationItemProps> = ({ onChangehandler, id, isChecked, title, subtitle }) => {
  return (
    <MDBox
      display="flex"
      flexDirection={{ xs: 'column-reverse', sm: 'row' }}
      alignItems={{ xs: 'flex-start', sm: 'center' }}
      mb={0.5}
      ml={-1.5}
    >
      <MDBox mt={0.5}>
        <Switch id={id} checked={isChecked} onChange={onChangehandler} />
      </MDBox>
      <MDBox width="80%" ml={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          {title}
        </MDTypography>
        {subtitle && (
          <MDTypography variant="button" fontWeight={400} color="text">
            {subtitle}
          </MDTypography>
        )}
      </MDBox>
    </MDBox>
  );
};
