import * as yup from 'yup';
import { UpdateUserHorizontalMappingPayload } from 'features/clio-settings/ClioSettingsApiService';
import { ClioEnum } from 'features/common';

export type ClioUserSettingsSchema = UpdateUserHorizontalMappingPayload;

export const clioUserSettingsValidationSchema: yup.ObjectSchema<ClioUserSettingsSchema> = yup
  .object()
  .shape({
    legalCaseNumberPosition: yup.string().required(),
    legalCaseAddressPosition: yup.string().required(),
    clientEmailPosition: yup.string().required(),
    clientPhonePosition: yup.string().required(),
    clientAddressPosition: yup.string().required(),
  })
  .required();

type FormFieldItem = {
  formFieldKeyValue: keyof UpdateUserHorizontalMappingPayload;
  label: string;
  options: ClioEnum[];
  isLoading: boolean;
};

type GetSettingsFieldsProps = {
  [Property in keyof UpdateUserHorizontalMappingPayload as `${Property}Data`]: {
    options: ClioEnum[];
    isLoading: boolean;
  };
};

export const getSettingsFields = ({
  clientAddressPositionData,
  clientEmailPositionData,
  clientPhonePositionData,
  legalCaseAddressPositionData,
  legalCaseNumberPositionData,
}: GetSettingsFieldsProps): FormFieldItem[] => [
  {
    formFieldKeyValue: 'legalCaseNumberPosition',
    label: 'Legal case number position',
    options: legalCaseNumberPositionData.options,
    isLoading: legalCaseNumberPositionData.isLoading,
  },
  {
    formFieldKeyValue: 'legalCaseAddressPosition',
    label: 'Legal case address position',
    options: legalCaseAddressPositionData.options,
    isLoading: legalCaseAddressPositionData.isLoading,
  },
  {
    formFieldKeyValue: 'clientEmailPosition',
    label: 'Client email position',
    options: clientEmailPositionData.options,
    isLoading: clientEmailPositionData.isLoading,
  },
  {
    formFieldKeyValue: 'clientPhonePosition',
    label: 'Client phone position',
    options: clientPhonePositionData.options,
    isLoading: clientPhonePositionData.isLoading,
  },
  {
    formFieldKeyValue: 'clientAddressPosition',
    label: 'Client address position',
    options: clientAddressPositionData.options,
    isLoading: clientAddressPositionData.isLoading,
  },
];
