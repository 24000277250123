import { FC } from 'react';
import { Dialog, DialogActions, DialogTitle, Stack } from '@mui/material';
import { Search } from '@mui/icons-material';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { BaseFormField, CRMCasesSearch, useRouter } from 'features/common';
import { CRMCaseItem } from '../../../my-clients/components/AttorneyCreateCase/CRMCaseItem';

type ImportFromCRMDialogWindowProps = {
  isCRMModalWindowOpen: boolean;
  searchValue: string;
  isLoading: boolean;
  onClickNextButtonHandler: () => Promise<void>;
  onChangeInputHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickFindButtonHandler: () => Promise<void>;
  CRMCases: CRMCasesSearch[];
  isSentReq: boolean;
  onChangeRadioButtonHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isFetchingCRMCaseData: boolean;
  selectedCRMCaseId: string;
};

export const ImportFromCRMDialogWindow: FC<ImportFromCRMDialogWindowProps> = ({
  searchValue,
  isCRMModalWindowOpen,
  isLoading,
  CRMCases,
  isSentReq,
  isFetchingCRMCaseData,
  selectedCRMCaseId,
  onClickFindButtonHandler,
  onClickNextButtonHandler,
  onChangeInputHandler,
  onChangeRadioButtonHandler,
}) => {
  const back = useRouter().back;

  return (
    <Dialog
      open={isCRMModalWindowOpen}
      PaperProps={{
        sx: {
          width: 1,
          maxWidth: 470,
        },
      }}
    >
      <Stack sx={{ p: 1.5 }}>
        <DialogTitle sx={{ textAlign: 'center' }}>Import from CRM</DialogTitle>
        <Stack spacing={2} width={1} p={1.5}>
          <Stack spacing={0.5}>
            <Stack direction="row" spacing={2} justifyContent="space-between" width={1}>
              <BaseFormField
                formFieldProps={{
                  pb: 0,
                  width: 1,
                }}
                formInputProps={{
                  label: 'Search',
                  placeholder: 'Find the cases',
                  width: '100%',
                  fullWidth: true,
                  value: searchValue,
                  onChange: onChangeInputHandler,
                  InputProps: {
                    startAdornment: <Search fontSize="medium" />,
                  },
                }}
              />
              <MDButton
                isLoading={isLoading}
                disabled={isLoading || !searchValue.trim()}
                variant="gradient"
                color="info"
                onClick={onClickFindButtonHandler}
              >
                Find
              </MDButton>
            </Stack>
            <MDTypography variant="button">You can search by client or case number.</MDTypography>
          </Stack>
        </Stack>
        <Stack direction="column" spacing={1.5} sx={{ width: 1, pt: 1, maxHeight: 250, overflowY: 'auto' }}>
          {CRMCases?.map(crmCase => (
            <CRMCaseItem
              key={crmCase?.id}
              caseNumber={crmCase?.caseNumber}
              displayName={crmCase?.displayName}
              id={crmCase?.id}
              isChecked={selectedCRMCaseId === crmCase?.id}
              onChange={onChangeRadioButtonHandler}
            />
          ))}
          {!CRMCases.length && isSentReq && (
            <MDTypography my={2} textAlign="center">
              No cases were found
            </MDTypography>
          )}
        </Stack>
        <DialogActions>
          <MDButton variant="outlined" color="dark" fullWidth onClick={back}>
            Cancel
          </MDButton>
          <MDButton
            disabled={!selectedCRMCaseId || isFetchingCRMCaseData || isLoading}
            isLoading={isFetchingCRMCaseData}
            variant="outlined"
            color="success"
            fullWidth
            onClick={onClickNextButtonHandler}
            sx={{ ml: '16px !important' }}
          >
            Next
          </MDButton>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};
