import { FC } from 'react';
import { Stack } from '@mui/material';
import { BaseAvatar } from 'features/common/components/BaseAvatar';
import { getFormattedCreatedAtTime } from 'features/my-chats/helpers';
import MDTypography from 'components/MDTypography';
import { ListOfChatsItemProps } from './types';
import { UnwatchedMessagesCounter } from '../UnwatchedMessagesCounter';

export const ListOfChatsItem: FC<ListOfChatsItemProps> = ({
  messageText,
  caseNumber,
  userLogoURL,
  userFirstName,
  userLastName,
  countUnwatchedMessages,
  messageCreatedAt,
  chatId,
  isChatSelected,
  lastElementRef,
  onItemClickHandler,
}) => {
  const formattedCreatedAt = getFormattedCreatedAtTime(messageCreatedAt);
  return (
    <Stack
      ref={lastElementRef}
      direction="row"
      spacing={1}
      alignItems="flex-start"
      id={chatId}
      onClick={onItemClickHandler}
      sx={{
        p: 1,
        borderRadius: '6px',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: ({ palette }) => palette.grey[200],
        },
        ...(isChatSelected && {
          backgroundColor: ({ palette }) => palette.grey[200],
        }),
      }}
    >
      <Stack direction="row" spacing={1} width={1} sx={{ overflowX: 'auto' }}>
        <BaseAvatar userFirstName={userFirstName} userLastName={userLastName} src={userLogoURL} />
        <Stack sx={{ maxWidth: 180, flex: 1 }}>
          <MDTypography
            variant="button"
            sx={{
              fontWeight: 500,
            }}
          >
            {caseNumber}
          </MDTypography>

          <MDTypography
            variant="button"
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              MozLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {messageText || 'No messages yet'}
          </MDTypography>
        </Stack>

        <Stack
          sx={{
            flex: 1,
            alignItems: 'flex-end',
          }}
        >
          <MDTypography variant="button">{formattedCreatedAt}</MDTypography>
          {countUnwatchedMessages ? <UnwatchedMessagesCounter unwatchedMessages={countUnwatchedMessages} /> : null}
        </Stack>
      </Stack>
    </Stack>
  );
};
