import { emailRegExp, phoneRegExp } from 'features/auth';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';

const MAX_CHARACTERS_VALIDATION_MESSAGE = 'This value is too long. It should have 255 characters or less';

export type RegisterCompanySchema = {
  fullName: string;
  shortName: string;
  companyEmail: string;
  companyPhone: string;
  country: string;
  state: string;
  town: string;
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  site: string;
  description: string;
};

export const registerCompanyFormDefaultValues: RegisterCompanySchema = {
  fullName: '',
  shortName: '',
  companyEmail: '',
  companyPhone: '',
  country: '',
  state: '',
  town: '',
  addressLine1: '',
  addressLine2: '',
  zipCode: '',
  description: '',
  site: '',
};

export const registerCompanyValidationSchema: ObjectSchema<RegisterCompanySchema> = yup.object().shape({
  fullName: yup
    .string()
    .trim()
    .required('Company full name is required')
    .max(225, 'This value is too long. It should have 225 characters or less'),
  shortName: yup.string().trim().required('Company short name is required').max(255, MAX_CHARACTERS_VALIDATION_MESSAGE),
  companyEmail: yup
    .string()
    .required('Company email is required')
    .matches(emailRegExp, 'Please enter a valid email')
    .max(255, MAX_CHARACTERS_VALIDATION_MESSAGE),
  companyPhone: yup.string().required('Company phone number is required').label('Company phone'),
  country: yup.string().required('Country is required'),
  state: yup.string().required('State is required'),
  town: yup.string().trim().required('Town/City is required'),
  zipCode: yup
    .string()
    .required('Zip code is required')
    .matches(/^(?:\d{5}|\d{5}-\d{4})$/, 'Zip code should be in a format 12345 or 12345-6789'),
  addressLine1: yup.string().trim().required('Address line 1 is required').max(255, MAX_CHARACTERS_VALIDATION_MESSAGE),
  addressLine2: yup.string().trim().required('Address line 2 is required').max(255, MAX_CHARACTERS_VALIDATION_MESSAGE),
  site: yup.string().trim().required('Site is required').max(255, MAX_CHARACTERS_VALIDATION_MESSAGE),
  description: yup.string().trim().required('Description is required').max(255, MAX_CHARACTERS_VALIDATION_MESSAGE),
});
