import { FC, useEffect } from 'react';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { PricingCard } from 'features/subscriptions/templates/AttorneyProSubscriptionTemplate/PricingCard';
import { useMyCompany } from 'features/store';
import { useSubscription } from 'features/common/hooks/useSubscription';
import { ToastType, notice, useRouter, useUser } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';

export const CompanySubscriptionTemplate: FC = () => {
  const myCompany = useMyCompany(state => state.myCompany);
  const { push } = useRouter();

  const { backendUserFirebaseId } = useUser();

  const { onGenerateSubscriptionUrlHandler, isLoading } = useSubscription();

  const redirectToCheckout = async () => await onGenerateSubscriptionUrlHandler({ enterprise: true });

  const { onGetUserSubscriptionStatus, isCheckingSubscriptionStatus, subscriptionStatus } = useSubscription();

  const isCompanyOwner = myCompany?.owner?.firebaseUser === backendUserFirebaseId;

  useEffect(() => {
    if (!isCompanyOwner) {
      push(ROUTES.firmProfile).then(() => notice(ToastType.WARNING, 'You do not have access to this page'));
      return;
    }

    onGetUserSubscriptionStatus();
  }, []);

  const isSubscriptionActivated = subscriptionStatus.isSubscriptionActive && subscriptionStatus.status === 'enterprise';

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader title="Company subscription" />
        <CardContent>
          <PricingCard
            color="dark"
            badge={{ color: 'info', label: 'Enterprise Subscription' }}
            price={{ currency: '$', value: '1999.99', type: 'mo' }}
            specifications={[{ label: 'Unlimited access to the application', includes: true }]}
            action={{
              isLoading: isLoading || isCheckingSubscriptionStatus,
              color: 'info',
              label: 'Buy',
              onClickHandler: redirectToCheckout,
              isDisabled: !myCompany?.active,
              badgeTitle: !myCompany?.active
                ? 'You can`t buy enterprise subscription until the application admin activates your company!'
                : '',
            }}
            isActiveSubscription={isSubscriptionActivated}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};
