import { FC } from 'react';
import { Dialog, DialogActions, DialogTitle, SxProps, Theme } from '@mui/material';
import MDButton from 'components/MDButton';

export type DialogWindowProps = {
  onCloseDialogHandler: () => void;
  onApproveDialogHandler: () => Promise<void> | void;
  isDialogOpen: boolean;
  dialogTitle: string;
  paperPropsSX?: SxProps<Theme>;
  isLoading?: boolean;
};

export const DialogWindow: FC<DialogWindowProps> = ({
  isDialogOpen,
  dialogTitle,
  onApproveDialogHandler,
  onCloseDialogHandler,
  paperPropsSX,
  isLoading,
}) => {
  return (
    <Dialog PaperProps={{ sx: paperPropsSX }} open={isDialogOpen} onClose={onCloseDialogHandler}>
      <DialogTitle sx={{ textAlign: 'center' }}>{dialogTitle}</DialogTitle>

      <DialogActions sx={{ justifyContent: 'center' }}>
        <MDButton onClick={onCloseDialogHandler} color="dark" variant="outlined" sx={{ mr: 1 }}>
          No
        </MDButton>
        <MDButton onClick={onApproveDialogHandler} autoFocus variant="outlined" color="info" isLoading={isLoading}>
          Yes
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};
