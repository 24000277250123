import { FC, memo } from 'react';
import { Card, Stack } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { Loader } from 'features/common/components/Loader';
import { ChatContent } from './ChatContent';
import { ChatContentCardProps } from './types';

export const ChatContentCard: FC<ChatContentCardProps> = memo(
  ({
    approveRepresentationRequest,
    declineRepresentationRequest,
    currentUserFirebaseId,
    isLoading,
    firstMessageRef,
    isFetchMoreLoading,
    messages,
    messagesWrapperRef,
    isAttorney,
    aesDecrypt,
  }) => {
    return (
      <Card sx={{ flex: 1, p: 2, overflow: 'hidden', minHeight: 300 }}>
        {isLoading ? (
          <Loader />
        ) : !messages.length && !isLoading ? (
          <Stack justifyContent="center" alignItems="center" flex={1}>
            <MDTypography>No messages yet...</MDTypography>
          </Stack>
        ) : (
          <ChatContent
            messages={messages}
            currentUserFirebaseId={currentUserFirebaseId}
            messagesWrapperRef={messagesWrapperRef}
            firstMessageRef={firstMessageRef}
            isFetchMoreLoading={isFetchMoreLoading}
            approveRepresentationRequest={approveRepresentationRequest}
            declineRepresentationRequest={declineRepresentationRequest}
            isAttorney={isAttorney}
            aesDecrypt={aesDecrypt}
          />
        )}
      </Card>
    );
  }
);
