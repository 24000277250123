import { FC, memo } from 'react';
import { useTheme } from '@mui/material';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './RichTextEditor.css';
import { baseEditorStyles, toolbarConfig } from './RichTextEditor.config';

type RichTextEditorProps = {
  editorState: EditorState;
  onEditorStateChange: (state: EditorState) => void;
  placeholder?: string;
  editorStyles?: React.CSSProperties;
};

export const RichTextEditor: FC<RichTextEditorProps> = memo(
  ({ placeholder, editorState, editorStyles, onEditorStateChange }) => {
    const theme = useTheme();
    return (
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={toolbarConfig}
        placeholder={placeholder}
        editorStyle={{
          ...baseEditorStyles,
          border: `1px solid ${theme.palette.inputBorderColor}`,
          ...editorStyles,
        }}
      />
    );
  }
);
