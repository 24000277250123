import { FC, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import { ClioEnum, ToastType, notice, useClioEnums, useUser } from 'features/common';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';
import { BaseAutocompleteField } from 'features/common/components/BaseAutocompleteField';
import { UserHorizontalMapping, clioUserSettingsApiService } from 'features/clio-settings/ClioSettingsApiService';
import { getOptionById } from 'features/common/helpers/utilities';
import { ClioUserSettingsSchema, clioUserSettingsValidationSchema, getSettingsFields } from './form.config';

export const SettingsFields: FC = () => {
  const { backendUserFirebaseId } = useUser();
  const {
    clioMappingLegalCaseAddressPositionEnum,
    clioMappingClientAddressPositionEnum,
    clioMappingClientEmailPositionEnum,
    clioMappingClientPhonePositionEnum,
    clioMappingLegalCaseNumberPositionEnum,
    onGetClioMappingLegalCaseAddressPositionEnum,
    onGetClioMappingClientAddressPositionEnum,
    onGetClioMappingClientEmailPositionEnum,
    onGetClioMappingClientPhonePositionEnum,
    onGetClioMappingLegalCaseNumberPositionEnum,
  } = useClioEnums();
  const { getClioUserHorizontalMapping, updateClioUserHorizontalMapping } = clioUserSettingsApiService();

  const [horizontalMapping, setHorizontalMapping] = useState<null | UserHorizontalMapping>(null);
  const [isHorizontalMappingLoading, setIsHorizontalMappingLoading] = useState(true);

  const onGetHorizontalMapping = async () => {
    try {
      const { data } = await getClioUserHorizontalMapping(backendUserFirebaseId);
      setHorizontalMapping(data);
      setIsHorizontalMappingLoading(false);
    } catch (error) {
      setIsHorizontalMappingLoading(false);
      console.error(error);
      paymentRequiredErrorHandler(error);
    }
  };

  useEffect(() => {
    onGetHorizontalMapping();
    onGetClioMappingLegalCaseAddressPositionEnum();
    onGetClioMappingClientAddressPositionEnum();
    onGetClioMappingClientEmailPositionEnum();
    onGetClioMappingClientPhonePositionEnum();
    onGetClioMappingLegalCaseNumberPositionEnum();
  }, []);

  const {
    formState: { errors, isSubmitting, isDirty, isValid },
    handleSubmit,
    control,
  } = useForm<ClioUserSettingsSchema>({
    resolver: yupResolver(clioUserSettingsValidationSchema),
    mode: 'onTouched',

    values: {
      clientAddressPosition: horizontalMapping?.clientAddressPosition?.id,
      clientEmailPosition: horizontalMapping?.clientEmailPosition?.id,
      clientPhonePosition: horizontalMapping?.clientPhonePosition?.id,
      legalCaseAddressPosition: horizontalMapping?.legalCaseAddressPosition?.id,
      legalCaseNumberPosition: horizontalMapping?.legalCaseNumberPosition?.id,
    },
  });

  const onFormSudmitHandler = handleSubmit(async formData => {
    try {
      const { data } = await updateClioUserHorizontalMapping(backendUserFirebaseId, {
        clientAddressPosition: formData.clientAddressPosition,
        clientEmailPosition: formData.clientEmailPosition,
        clientPhonePosition: formData.clientPhonePosition,
        legalCaseAddressPosition: formData.legalCaseAddressPosition,
        legalCaseNumberPosition: formData.legalCaseNumberPosition,
      });

      setHorizontalMapping(data);
      notice(ToastType.SUCCESS, 'Successfully updated!');
    } catch (error) {
      console.error(error);
      paymentRequiredErrorHandler(error);
    }
  });

  const isSubmitButtonDisabled = !isValid || !isDirty || isSubmitting;

  const formFields = getSettingsFields({
    clientAddressPositionData: {
      options: clioMappingClientAddressPositionEnum.data,
      isLoading: clioMappingClientAddressPositionEnum.isLoading,
    },
    clientEmailPositionData: {
      options: clioMappingClientEmailPositionEnum.data,
      isLoading: clioMappingClientEmailPositionEnum.isLoading,
    },
    legalCaseAddressPositionData: {
      options: clioMappingLegalCaseAddressPositionEnum.data,
      isLoading: clioMappingLegalCaseAddressPositionEnum.isLoading,
    },
    clientPhonePositionData: {
      options: clioMappingClientPhonePositionEnum.data,
      isLoading: clioMappingClientPhonePositionEnum.isLoading,
    },
    legalCaseNumberPositionData: {
      options: clioMappingLegalCaseNumberPositionEnum.data,
      isLoading: clioMappingLegalCaseNumberPositionEnum.isLoading,
    },
  });

  return (
    <Stack
      spacing={2}
      sx={{
        height: 1,
      }}
    >
      <MDTypography fontWeight="medium">Settings</MDTypography>
      <form onSubmit={onFormSudmitHandler}>
        <Stack flex={1} width={1}>
          {formFields.map(field => (
            <Controller
              key={field.formFieldKeyValue}
              control={control}
              name={field.formFieldKeyValue}
              render={({ field: { onChange, value } }) => {
                const onChangeHandler = async (value: ClioEnum) => onChange(value?.id);
                return (
                  <BaseAutocompleteField
                    options={field.options}
                    getOptionLabel={option => option.value}
                    value={getOptionById(value, field.options, 'id')}
                    onChangeHandler={onChangeHandler}
                    errorMessage={errors[field.formFieldKeyValue]?.message}
                    isLoading={isHorizontalMappingLoading || field.isLoading}
                    autocompleSxProps={{
                      width: 1,
                      '.MuiInputBase-root': {
                        py: '4.5px',
                      },
                    }}
                    formInputProps={{
                      label: field.label,
                    }}
                  />
                );
              }}
            />
          ))}
        </Stack>
        <MDBox>
          <MDButton
            sx={{
              width: { xs: 1, sm: 'auto' },
            }}
            variant="gradient"
            color="info"
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitButtonDisabled}
          >
            Apply
          </MDButton>
        </MDBox>
      </form>
    </Stack>
  );
};
