import { FC } from 'react';
import { Stack } from '@mui/material';
import { MailOutlineOutlined, ArchiveOutlined, PersonOutline } from '@mui/icons-material';
import MDTypography from 'components/MDTypography';
import { GetUsersChats } from 'features/common/services/chatApiService';
import MDAvatar from 'components/MDAvatar';
import { getAvatarCharacters } from 'features/common/helpers/utilities';
import { getFormattedCreatedAtTime } from 'features/my-chats/helpers';
import { UnwatchedMessagesCounter } from '../UnwatchedMessagesCounter';

type UsersChatsItemProps = Pick<GetUsersChats, 'chatsCount' | 'chatsMessagesCount' | 'unwatchedChatsMessagesCount'> & {
  userFirstName: string;
  userLastName: string;
  userLogoURL?: string;
  messageText: string | null;
  createdAt: string;
  userId: string;
  onUserChatItemClickHandler: (event: React.MouseEvent<HTMLDivElement>) => Promise<void>;
  isSelectedUser: boolean;
};

export const UsersChatsItem: FC<UsersChatsItemProps> = ({
  unwatchedChatsMessagesCount,
  chatsCount,
  chatsMessagesCount,
  userFirstName,
  userLastName,
  userLogoURL,
  messageText,
  createdAt,
  userId,
  isSelectedUser,
  onUserChatItemClickHandler,
}) => {
  const formattedCreatedAt = getFormattedCreatedAtTime(createdAt);
  return (
    <Stack
      id={userId}
      onClick={onUserChatItemClickHandler}
      direction="row"
      alignItems="flex-start"
      sx={{
        flexWrap: 'wrap',
        p: 1,
        width: 1,
        justifyContent: 'space-between',
        borderRadius: '6px',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: ({ palette }) => palette.grey[200],
        },
        ...(isSelectedUser && {
          backgroundColor: ({ palette }) => palette.grey[200],
        }),
      }}
    >
      <Stack direction="row" spacing={1} alignItems="flex-start" mb="2px">
        <MDAvatar
          src={userLogoURL || ''}
          size="md"
          alt="user avatar"
          sx={{
            backgroundColor: theme => theme.palette.info.main,
            '& img': {
              height: 1,
            },
          }}
        >
          {!userFirstName && !userLastName ? (
            <PersonOutline fontSize="medium" />
          ) : (
            getAvatarCharacters(userFirstName, userLastName)
          )}
        </MDAvatar>

        <Stack sx={{ maxWidth: 180, flex: 1 }}>
          <MDTypography
            variant="button"
            sx={{
              fontWeight: 500,
            }}
          >
            {userFirstName} {userLastName}
          </MDTypography>

          <MDTypography
            variant="button"
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              MozLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {messageText || 'No messages yet'}
          </MDTypography>
        </Stack>
      </Stack>

      <Stack
        sx={{
          alignItems: 'flex-end',
        }}
      >
        <MDTypography variant="button">{formattedCreatedAt}</MDTypography>
        <Stack direction="row" spacing={1}>
          <Stack direction="row" alignItems="center">
            <ArchiveOutlined fontSize="small" />
            <MDTypography variant="caption" sx={{ alignSelf: 'end' }}>
              {chatsCount}
            </MDTypography>
          </Stack>

          <Stack direction="row" alignItems="center">
            <MailOutlineOutlined fontSize="small" />
            {unwatchedChatsMessagesCount ? (
              <UnwatchedMessagesCounter unwatchedMessages={unwatchedChatsMessagesCount} />
            ) : (
              <MDTypography variant="caption" sx={{ alignSelf: 'end' }}>
                {chatsMessagesCount}
              </MDTypography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
