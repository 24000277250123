import { CaseLawTypeResponse, Pagination } from 'features/case-law-types';
import { BaseData, ListOfItems, PATCH_HEADERS, backendApiClient } from 'features/common';

const BASE_CONFIG_API_URL: Readonly<string> = '/clio/mapping/user';

export type GetEmailTemplatesResponse = BaseData<'MailgunTemplateDto'> & {
  description: string;
  name: string;
};

export type CreateClioCaseLawTypePayload = {
  caseLawType: string;
  practiceAreaId: string;
};

export type ClioCaseLawTypeValue = Pick<CaseLawTypeResponse, '@id' | '@type'> & { value: string; id: string };

export type PracticeAreaCategory = BaseData<'KeyValueDto'> & {
  id: string;
  value: string;
};

export type ClioCaseLawType = BaseData<'KeyValueDto'> & {
  caseLawType: ClioCaseLawTypeValue;
  practiceArea: PracticeAreaCategory;
};

export type UpdateUserHorizontalMappingPayload = {
  [Property in keyof Omit<UserHorizontalMapping, '@id' | '@type'>]: string;
};

export type HorizontalMappingValue = BaseData<'KeyValueDto'> & {
  id: string;
  value: string;
};

export type UserHorizontalMapping = BaseData<'ClioMappingUserHorizontal'> & {
  legalCaseNumberPosition: HorizontalMappingValue;
  legalCaseAddressPosition: HorizontalMappingValue;
  clientEmailPosition: HorizontalMappingValue;
  clientPhonePosition: HorizontalMappingValue;
  clientAddressPosition: HorizontalMappingValue;
};

export type UpdateClioCaseLawType = Pick<CreateClioCaseLawTypePayload, 'caseLawType'> & {
  practiceAreas: string[];
};

export const clioUserSettingsApiService = () => {
  const getClioCaseLawTypes = async () =>
    await backendApiClient({}).get<ListOfItems<ClioCaseLawType[]>>(`${BASE_CONFIG_API_URL}/case_law_types`);

  const updateClioCaseLawType = async (payload: UpdateClioCaseLawType) =>
    await backendApiClient({ headers: PATCH_HEADERS }).patch(`${BASE_CONFIG_API_URL}/case_law_types`, payload);

  const getClioMappingProxyPracticeArea = async () =>
    await backendApiClient({}).get<ListOfItems<ClioCaseLawTypeValue[]>>('/clio/mapping/proxy/practice_area');

  const getClioUserHorizontalMapping = async (userFirebaseId: string) =>
    await backendApiClient({}).get<UserHorizontalMapping>(`${BASE_CONFIG_API_URL}/horizontal/${userFirebaseId}`);

  const updateClioUserHorizontalMapping = async (
    userFirebaseId: string,
    payload: Partial<UpdateUserHorizontalMappingPayload>
  ) =>
    await backendApiClient({ headers: PATCH_HEADERS }).patch<UserHorizontalMapping>(
      `${BASE_CONFIG_API_URL}/horizontal/${userFirebaseId}`,
      payload
    );

  const createClioCaseLawType = async (payload: CreateClioCaseLawTypePayload) =>
    await backendApiClient({}).post<ClioCaseLawType>(`${BASE_CONFIG_API_URL}/case_law_types`, payload);

  const getClioCaseLawTypeById = async (id: string) =>
    await backendApiClient({}).get<ClioCaseLawType>(`${BASE_CONFIG_API_URL}/case_law_types/${id}`);

  const deleteClioCaseLawTypeById = async (id: string) =>
    await backendApiClient({}).delete(`${BASE_CONFIG_API_URL}/case_law_types/${id}`);

  return {
    getClioCaseLawTypes,
    createClioCaseLawType,
    getClioCaseLawTypeById,
    deleteClioCaseLawTypeById,
    updateClioCaseLawType,
    getClioMappingProxyPracticeArea,
    getClioUserHorizontalMapping,
    updateClioUserHorizontalMapping,
  };
};
