import { FirebaseError } from '@firebase/app';
import { notice, ToastType } from '../../common';
import { OAUTH_PROVIDER_TYPES } from './firebaseService';

export enum FIREBASE_PROVIDERS {
  GOOGLE = 'google.com',
  EMAIL = 'password',
  APPLE = 'apple.com',
  MICROSOFT = 'microsoft.com',
}

export const baseFirebaseAuthErrorHandler = (error: FirebaseError, providerHost?: OAUTH_PROVIDER_TYPES) => {
  switch (error.code) {
    case 'auth/invalid-email':
      notice(ToastType.ERROR, 'Invalid email');
      break;
    case 'auth/services-disabled':
      notice(ToastType.ERROR, 'User is disabled');
      break;
    case 'auth/services-not-found':
      notice(ToastType.ERROR, 'User not found');
      break;
    case 'auth/user-not-found':
      notice(ToastType.ERROR, 'Email or password is incorrect.');
      break;
    case 'auth/wrong-password':
      notice(ToastType.ERROR, 'Email or password is incorrect.');
      break;
    case 'auth/invalid-login-credentials':
      notice(ToastType.ERROR, 'Email or password is incorrect.');
      break;
    case 'auth/email-already-in-use':
      notice(ToastType.ERROR, 'The email address is already in use by another account.');
      break;
    case 'auth/email-not-found':
      notice(ToastType.ERROR, 'Email not found!');
      break;
    case 'auth/operation-not-allowed':
      notice(ToastType.ERROR, 'Sorry, this operation temporary not available!');
      break;
    case 'auth/popup-closed-by-services':
      notice(ToastType.WARNING, 'Popup has been closed by services');
      break;
    case 'auth/popup-closed-by-user':
      notice(ToastType.ERROR, `${providerHost} authentication window closed`);
      break;
    case 'auth/too-many-requests':
      notice(ToastType.ERROR, 'Too many requests, try latter!');
      break;
    case 'auth/too-many-attempts':
      notice(ToastType.ERROR, 'Too many attempts, try latter!');
      break;
    case 'auth/invalid-phone-number':
      notice(ToastType.ERROR, 'Invalid phone number format, please try another one!');
      break;
    case 'auth/invalid-verification-code':
      notice(ToastType.ERROR, 'Invalid verification code, please try another one!');
      break;
    case 'auth/credential-already-in-use':
      notice(ToastType.ERROR, 'User with this credentials already exist, please try another one!');
      break;
    case 'auth/expired-action-code':
      notice(ToastType.ERROR, 'Action code has been expired!');
      break;
    case 'auth/invalid-action-code':
      notice(ToastType.ERROR, 'Invalid action code!');
      break;
    default:
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
      console.error({ error });
      break;
  }
};

// TODO: Move to new feature LBUser
export const LBUserRole = {
  CLIENT: 'ROLE_CLIENT',
  ATTORNEY: 'ROLE_ATTORNEY',
  ADMIN: 'ROLE_ADMIN',
};
