import { FC, memo, useState } from 'react';
import { CircularProgress, Switch, Tooltip } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ClearIcon from '@mui/icons-material/Clear';
import { createSortingService } from 'features/common/services/sortingService';
import { TablePaginationFooter } from 'features/common/components/TablePaginationFooter';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { formatDate } from 'features/common/helpers/date';
import { SideMenu, ToastType, notice, useBoolean } from 'features/common';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { DndDataTable } from './DndDataTable';
import { EditFAQItemForm } from './EditFAQItemForm';
import { GetQuestionsRequest, QuestionResponse, createFAQService } from '../services';

type FAQTableProps = {
  questionsList: QuestionResponse[];
  questionsTotal: number;
  currentPage: number;
  entriesPerPage: number;
  isLoading: boolean;
  getItemsHandler: (params?: GetQuestionsRequest) => Promise<void>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onDeleteItem: () => number;
  onChangeEntriesPerPageHandler: (value: number) => void;
  onChangePageHandler: (page: number) => Promise<void>;
  loadQuestionsHandler: (page?: number) => Promise<void>;
};

export const FAQTable: FC<FAQTableProps> = memo(
  ({
    questionsList,
    questionsTotal,
    currentPage,
    entriesPerPage,
    isLoading,
    getItemsHandler,
    setIsLoading,
    onDeleteItem,
    onChangeEntriesPerPageHandler,
    onChangePageHandler,
    loadQuestionsHandler,
  }) => {
    const { deleteQuestionById, patchQuestionById } = createFAQService();

    const [selectedQuestionId, setSelectedQuestionId] = useState('');
    const [isEditSidebarOpen, openEditSideBar, closeEditSideBar] = useBoolean(false);
    const [faqItemId, setFaqItemId] = useState('');

    const onEditButtonClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
      setFaqItemId(event.currentTarget.id.replace('/frequently_asked_questions/', ''));
      openEditSideBar();
    };

    const { patchSorting } = createSortingService();

    const [isQuestionDeleteModalOpen, openQuestionDeleteModal, closeQuestionDeleteModal] = useBoolean(false);

    const onDeleteQuestion = async () => {
      try {
        const nextPage = onDeleteItem() || currentPage;
        setIsLoading(true);
        closeQuestionDeleteModal();

        await deleteQuestionById(selectedQuestionId);
        await getItemsHandler({ page: nextPage });

        setIsLoading(false);
        notice(ToastType.SUCCESS, 'Question successfully deleted!');
      } catch (error) {
        setIsLoading(false);
        console.error(error);
        notice(ToastType.ERROR, 'Something went wrong!');
      }
    };
    const onReorder = async ({ id, direction, steps }: { id: string; direction: string; steps: number }) => {
      try {
        setIsLoading(true);
        await patchSorting({ object: id, direction, step: steps });
        await getItemsHandler();
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
        notice(ToastType.ERROR, 'Error with reordering!');
      }
    };

    return (
      <>
        <MDBox justifyItems="center" alignItems="center" display="flex" minHeight={200}>
          {isLoading ? (
            <CircularProgress sx={{ mx: 'auto', mb: '16px' }} />
          ) : questionsList.length > 0 ? (
            <MDBox width="100%">
              <DndDataTable
                entriesPerPage={{ defaultValue: entriesPerPage }}
                onChangeEntriesPerPage={onChangeEntriesPerPageHandler}
                isSorted={false}
                showTotalEntries={true}
                onReorder={onReorder}
                table={{
                  columns: [
                    { Header: '', accessor: 'dragAndDrop', width: '50px' },
                    { Header: 'Question', accessor: 'question', align: 'left' },
                    { Header: 'Public visibility', accessor: 'publicVisibility', width: '100px', align: 'left' },
                    { Header: 'Creation date', accessor: 'createdAt', align: 'left' },
                    { Header: 'Last Update', accessor: 'updatedAt', align: 'left' },
                    { Header: '', accessor: 'actionBtns' },
                  ],
                  rows: questionsList.map(questionItem => ({
                    id: questionItem['@id'],
                    question: (
                      <MDTypography noWrap={true} fontSize={14} maxWidth="350px" minWidth="100px">
                        {questionItem.question}
                      </MDTypography>
                    ),
                    createdAt: questionItem.createdAt ? formatDate(questionItem.createdAt) : '-',
                    updatedAt: questionItem.updatedAt ? formatDate(questionItem.updatedAt) : '-',
                    publicVisibility: (
                      <Switch
                        onChange={() =>
                          patchQuestionById(questionItem['@id'].replace('/frequently_asked_questions/', ''), {
                            publicVisibility: !questionItem.publicVisibility,
                          }).then(async () => {
                            await loadQuestionsHandler();
                            notice(ToastType.SUCCESS, 'Successfully switched public visibility!');
                          })
                        }
                        checked={questionItem.publicVisibility}
                      />
                    ),
                    actionBtns: (
                      <MDBox sx={{ display: 'flex', gap: '5px', width: '100%', justifyContent: 'flex-start' }}>
                        <Tooltip title="Edit selected question">
                          <MDButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            iconOnly
                            id={questionItem['@id']}
                            onClick={onEditButtonClickHandler}
                          >
                            <ModeEditIcon />
                          </MDButton>
                        </Tooltip>
                        <Tooltip title="Delete selected question">
                          <MDButton
                            color="secondary"
                            variant="outlined"
                            size="small"
                            iconOnly
                            onClick={() => {
                              setSelectedQuestionId(questionItem['@id'].replace('/frequently_asked_questions/', ''));
                              openQuestionDeleteModal();
                            }}
                          >
                            <ClearIcon />
                          </MDButton>
                        </Tooltip>
                      </MDBox>
                    ),
                  })),
                }}
              />
              <TablePaginationFooter
                page={currentPage}
                currentItemsPerPage={entriesPerPage}
                totalItems={questionsTotal}
                onChangePage={onChangePageHandler}
              />
            </MDBox>
          ) : (
            <MDTypography sx={{ mx: 'auto' }}>No questions were found...</MDTypography>
          )}

          <DialogWindow
            dialogTitle="Are you sure want to delete this Question?"
            isDialogOpen={isQuestionDeleteModalOpen}
            onCloseDialogHandler={closeQuestionDeleteModal}
            onApproveDialogHandler={onDeleteQuestion}
          />
        </MDBox>
        <SideMenu
          isOpen={isEditSidebarOpen}
          onClose={closeEditSideBar}
          title="Edit Frequently asked question"
          titleDescription="Here you can edit question and add an answer to it by yourself."
        >
          <EditFAQItemForm
            faqItemId={faqItemId}
            closeSideBarHandler={closeEditSideBar}
            loadQuestionsHandler={loadQuestionsHandler}
          />
        </SideMenu>
      </>
    );
  }
);
