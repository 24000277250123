import { FC } from 'react';
import { Stack } from '@mui/material';
import { BaseFormField, ToastType, notice } from 'features/common';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AdminInvitationSchema, createAdminInvitationValidationFormSchema } from './form.config';
import { BaseDatePicker } from 'features/common/components/BaseTimePicker';
import { DateValidationError } from '@mui/x-date-pickers';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import { usersManagementApiService } from '../UsersManagementApiService';
import { isToday } from 'date-fns';
import { baseBackendErrorHandler } from 'features/common/errorHanders';

export const CreateInviteContentModal: FC<{ onCloseMenuHandler: () => void }> = ({ onCloseMenuHandler }) => {
  const { createAdminInvitation } = usersManagementApiService();

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    control,
    formState: { errors, isValid, isSubmitting, isDirty },
  } = useForm<AdminInvitationSchema>({
    resolver: yupResolver(createAdminInvitationValidationFormSchema),
    mode: 'onTouched',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      expiredAt: null,
    },
  });

  const isSubmitButtonDisabled = isSubmitting || !isValid || !isDirty;

  const onErrorTimePickerHandler = (error: DateValidationError) => {
    error
      ? setError('expiredAt', {
          message: 'Enter a valid date format',
        })
      : clearErrors('expiredAt');
  };

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      await createAdminInvitation({
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        expiredAt: formData.expiredAt?.toISOString(),
      });
      notice(ToastType.SUCCESS, 'Successfully create invitation');
      onCloseMenuHandler();
    } catch (error) {
      baseBackendErrorHandler(error, { formError: { formData, setError } });
    }
  });

  return (
    <MDBox
      component="form"
      onSubmit={onFormSubmitHandler}
      my={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 1,
      }}
    >
      <>
        <Stack
          spacing={1}
          sx={{
            mt: 3,
            pb: 1.5,
            height: 1,
            flex: 1,
          }}
        >
          <BaseFormField
            formInputProps={{
              ...register('email'),
              type: 'text',
              label: 'Email',
              fullWidth: true,
            }}
            errorValue={errors['email']?.message}
          />

          <BaseFormField
            errorValue={errors['firstName']?.message}
            formInputProps={{
              ...register('firstName'),
              type: 'text',
              label: 'First name',
              fullWidth: true,
              InputLabelProps: { shrink: true },
            }}
          />

          <BaseFormField
            errorValue={errors['lastName']?.message}
            formInputProps={{
              ...register('lastName'),
              type: 'text',
              label: 'Last name',
              fullWidth: true,
              InputLabelProps: { shrink: true },
            }}
          />
          <Controller
            name="expiredAt"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              const onChangeHandler = (newValue: Date) => onChange(newValue);
              const onClickClearButtonHandler = () => onChange(null);

              return (
                <BaseDatePicker
                  onClickClearButtonHandler={onClickClearButtonHandler}
                  isError={!!error?.message}
                  onError={onErrorTimePickerHandler}
                  disablePast
                  shouldDisableDate={day => isToday(day)}
                  value={value}
                  onChange={onChangeHandler}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      variant: 'outlined',
                      error: !!error,
                      helperText: error?.message,
                      label: 'Expired at',
                    },
                    actionBar: {
                      actions: ['clear', 'accept'],
                    },
                  }}
                  sx={{
                    mb: 1,
                    '& .MuiFormHelperText-root': {
                      position: 'absolute',
                      bottom: '-17px',
                      left: '5px',
                      m: 0,
                      fontSize: 10,
                    },
                  }}
                />
              );
            }}
          />
        </Stack>
        <MDButton
          type="submit"
          variant="gradient"
          color="info"
          isLoading={isSubmitting}
          disabled={isSubmitButtonDisabled}
          sx={{
            width: 1,
            mt: 1.5,
            textTransform: 'none',
          }}
        >
          Send invite
        </MDButton>
      </>
    </MDBox>
  );
};
