import { Column } from 'react-table';
import { Visibility } from '@mui/icons-material';
import { PersonOutline } from '@mui/icons-material';
import { Chip, Stack, Tooltip } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDAvatar from 'components/MDAvatar';
import MDTypography from 'components/MDTypography';
import { ROLES, UserRoles } from 'features/common';
import { getAvatarCharacters } from 'features/common/helpers/utilities';
import { dateToCustomFormat, formatDate } from 'features/common/helpers/date';
import { SubscriptionStatus } from 'features/subscriptions/services/subscriptionsApiService';
import { SUBSCRIPTION_PLAN_LABEL_MAP } from 'features/common/hooks/useSubscription';

export type UserData = {
  logoURL: string;
  firstName: string;
  lastName: string;
  roles?: UserRoles;
  companyName?: string;
  id: string;
};

type UserTableColumn = Column<AttorneyColumnData>;

type ActionButtons = {
  onMoreInfoIconClickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  id: string;
};

type AttorneyColumnData = {
  userData: UserData;
  legalCaseCount: number;
  subscription?: string;
  actionBtns: ActionButtons;
  email: string;
  phoneNumber: string;
  createdAt: string;
  subscriptionStatus: SubscriptionStatus;
};

export const USERS_TABLE_COLUMNS: UserTableColumn[] = [
  {
    Header: 'User',
    accessor: 'userData',
    align: 'left',
    Cell: cellData => {
      const { firstName, lastName, logoURL, companyName, roles } = cellData.value;
      const avatarCharacters = getAvatarCharacters(firstName, lastName);
      const isUserFullname = firstName && lastName;
      return (
        <MDBox>
          <Stack direction="row" alignItems="center" mb={1} spacing={1}>
            <MDAvatar
              src={logoURL || ''}
              alt="user avatar"
              bgColor="info"
              sx={{
                '& img': {
                  height: 1,
                },
              }}
            >
              {!isUserFullname ? <PersonOutline fontSize="medium" /> : avatarCharacters}
            </MDAvatar>

            <Stack spacing={1}>
              <MDTypography variant="button" fontWeight="medium">
                {`${firstName} ${lastName}`}
              </MDTypography>
              <Stack direction="row" spacing={0.4}>
                <MDTypography variant="caption" fontWeight="medium">
                  {ROLES[roles[0]]}
                </MDTypography>
                {ROLES[roles[0]] === ROLES.ROLE_ATTORNEY && companyName && (
                  <MDTypography variant="caption" fontWeight="medium">
                    at {companyName}
                  </MDTypography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </MDBox>
      );
    },
  },
  {
    Header: 'Email',
    accessor: 'email',
    align: 'left',
    Cell: cellData => <MDTypography variant="button">{cellData.value}</MDTypography>,
  },
  {
    Header: 'Phone number',
    accessor: 'phoneNumber',
    align: 'left',
    Cell: cellData => <MDTypography variant="button">{cellData.value}</MDTypography>,
  },
  {
    Header: 'Case amount',
    accessor: 'legalCaseCount',
    align: 'left',
    Cell: cellData => <MDTypography variant="button">{cellData.value}</MDTypography>,
  },
  {
    Header: 'Creation date',
    accessor: 'createdAt',
    align: 'left',
    Cell: cellData => <MDTypography variant="button">{formatDate(cellData.value) || '-'}</MDTypography>,
  },

  {
    Header: 'Action',
    accessor: 'actionBtns',
    Cell: cellData => {
      return (
        <Tooltip title="View user info">
          <MDButton
            id={cellData.value.id}
            size="small"
            variant="outlined"
            color="secondary"
            iconOnly
            onClick={cellData.value?.onMoreInfoIconClickHandler}
          >
            <Visibility />
          </MDButton>
        </Tooltip>
      );
    },
  },
];
