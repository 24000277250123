import { FC, useEffect, useRef, useState } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import { useNotificationsCenter } from 'features/store';
import { Loader } from 'features/common/components/Loader';
import { useIsOnScreen } from 'features/common/hooks/useIsOnScreen';
import MDBox from 'components/MDBox';
import { NotificationModalItem } from './Components/NotificationModalItem';
import { ITEMS_PER_PAGE } from './NotificationCenterApiService';

export const AllNotificationsSideMenuContent: FC<{ closeMenuHandler: () => void }> = ({ closeMenuHandler }) => {
  const {
    getNotificationsHandler,
    fetchMoreNotificationsHandler,
    markNotificationAsReadHandler,
    isLoading,
    notifications,
    isFetchMoreLoading,
    totalItems,
  } = useNotificationsCenter();

  const [currentPage, setCurrentPage] = useState(1);

  const pages = Math.ceil(totalItems / ITEMS_PER_PAGE);

  const lastElementRef = useRef<HTMLDivElement | null>(null);
  const isOnScreen = useIsOnScreen(isLoading ? null : lastElementRef);

  useEffect(() => {
    getNotificationsHandler();
  }, []);

  useEffect(() => {
    if (isOnScreen && currentPage < pages) {
      const nextPage = currentPage + 1;
      fetchMoreNotificationsHandler({
        page: nextPage,
        itemsPerPage: ITEMS_PER_PAGE,
      });
      setCurrentPage(nextPage);
    }
  }, [isOnScreen]);

  return (
    <Stack
      spacing={2}
      sx={{
        mt: 3,
        pb: 1.5,
        height: 1,
      }}
    >
      <Stack position="relative" height={1} spacing={2}>
        {isLoading && <Loader />}

        {!isLoading &&
          notifications?.map(
            (
              { id, allowTransition, transitionType, createdAt, viewedAt, legalCase, message, type, owner, opponent },
              index
            ) => (
              <MDBox
                key={id}
                ref={notifications.length - 1 === index ? lastElementRef : null}
                borderRadius="xl"
                bgColor="white"
                sx={{
                  position: 'relative',
                  fontSize: ({ typography: { size } }) => size.md,
                  boxShadow: theme => theme.boxShadows.md,
                  border: theme => `1px solid ${theme.borders.borderColor}`,
                }}
              >
                <NotificationModalItem
                  id={id}
                  message={message}
                  transitionType={transitionType}
                  logoURL={(opponent ? opponent : owner)?.logo?.publicUrl}
                  caseNumber={legalCase?.caseNumber}
                  createdAt={createdAt || ''}
                  firstName={(opponent ? opponent : owner)?.userProfile?.firstName}
                  lastName={(opponent ? opponent : owner)?.userProfile?.lastName}
                  legalCaseId={legalCase?.['@id']}
                  opponentFirebaseId={opponent?.firebaseUser}
                  onCloseMenuHandler={closeMenuHandler}
                  viewedAt={viewedAt}
                  markNotificationAsReadHandler={markNotificationAsReadHandler}
                  isAllowTransition={allowTransition}
                />
              </MDBox>
            )
          )}

        {isFetchMoreLoading && (
          <MDBox sx={{ position: 'absolute', bottom: 15, left: '50%', transform: 'translateX(-50%)' }}>
            <CircularProgress />
          </MDBox>
        )}
      </Stack>
    </Stack>
  );
};
