import { FC } from 'react';
import { CircularProgress } from '@mui/material';
import MDBox from 'components/MDBox';

export const FetchMoreLoader: FC = () => {
  return (
    <MDBox sx={{ position: 'absolute', bottom: 15, left: '50%', transform: 'translateX(-50%)' }}>
      <CircularProgress />
    </MDBox>
  );
};
