import jsCookie from 'js-cookie';

export const SIGN_DATA_KEY = 'sign_data';
export const LAST_MOBILE_ALERT_POPUP_TIME_KEY = 'lastPopupTime';

type SignDataItem = { fileId: string; path: string };

export const getSignDataFromCookies = (): SignDataItem[] => {
  const signData = jsCookie.get(SIGN_DATA_KEY);

  return signData ? JSON.parse(signData) : [];
};

export const setSignDataToCookies = (signData: SignDataItem[], options?: jsCookie.CookieAttributes) => {
  jsCookie.set(SIGN_DATA_KEY, JSON.stringify(signData), options);
};
