import { AxiosError } from 'axios';
import { ToastType, notice } from '../modules';

export const PAYMENT_ERROR_MESSAGE = 'Subscription is not activated!';
export const BASE_ERROR_MESSAGE = 'Something went wrong, please try again!';

export const paymentRequiredErrorHandler = (error: unknown, baseErrorMessage: string = BASE_ERROR_MESSAGE) => {
  if (error instanceof AxiosError) {
    if (error?.response?.status === 402) {
      return notice(ToastType.ERROR, PAYMENT_ERROR_MESSAGE);
    } else {
      return error.code === 'ERR_CANCELED' ? null : notice(ToastType.ERROR, baseErrorMessage);
    }
  } else {
    return notice(ToastType.ERROR, baseErrorMessage);
  }
};
