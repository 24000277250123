import { FC, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, Stack, Switch } from '@mui/material';
import { NotificationsNoneOutlined, StickyNote2Outlined } from '@mui/icons-material';
import MDTypography from 'components/MDTypography';
import { BaseFormField } from 'features/common';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { BaseDatePicker, BaseTimePicker } from 'features/common/components/BaseTimePicker';
import { Loader } from 'features/common/components/Loader';
import { BaseAutocompleteField } from 'features/common/components/BaseAutocompleteField';
import { EventParticipant, ParticipantCase } from 'features/my-events/myEventsApiService';
import { EVENT_TYPE_OPTIONS, EventTypeOption, REMINDER_OPTIONS, ReminderOption } from '../caseEvents.constants';
import { CreateEventModalContentProps } from '../types';
import { useUsersState } from '../hooks/useUsersState';
import { useCreateEventState } from '../hooks/useCreateEventState';
import { CreateEventModalContentStyles as Styled } from './CreateEventModalContent.styles';

export const CreateEventModalContent: FC<CreateEventModalContentProps> = ({
  selectedDate,
  caseNumber,
  userFullName,
  isAttorney,
  isEventDataLoading,
  legalCaseId,
  userFirebaseId,
  currentEventData,
  closeAddEventModal,
  createEventHandler,
  patchEventHandler,
}) => {
  const { isCasesFetching, onFetchParticipantCasesHandler, isUsersFetching, currentUser, legalCases, userList } =
    useUsersState({
      eventParticipants: currentEventData?.participants,
      isAttorney,
      userFirebaseId,
      legalCaseId,
    });

  const {
    formValueFirebaseUserId,
    isAddNote,
    isCreateButtonDisabled,
    isReminder,
    control,
    isSubmitting,
    errors,
    onErrorHandler,
    onErrorTimePickerHandler,
    onFormSubmitHandler,
    register,
  } = useCreateEventState({
    currentEventData,
    selectedDate,
    userFirebaseId,
    legalCaseId,
    createEventHandler,
    closeAddEventModal,
    patchEventHandler,
    currentUser,
  });

  useEffect(() => {
    if (currentUser && userList.length && formValueFirebaseUserId.replace('/users/', '')) {
      onFetchParticipantCasesHandler(formValueFirebaseUserId);
    }
  }, [currentUser, userList, formValueFirebaseUserId]);

  return (
    <MDBox
      component="form"
      onSubmit={onFormSubmitHandler}
      my={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      {isEventDataLoading ? (
        <Stack flex={1}>
          <Loader />
        </Stack>
      ) : (
        <>
          <Stack spacing={2.5} flex={1}>
            {legalCaseId ? (
              <>
                <BaseFormField
                  formFieldProps={{
                    pb: 0,
                  }}
                  formInputProps={{
                    type: 'text',
                    label: isAttorney ? 'Client' : 'Attorney',
                    value: userFullName,
                    fullWidth: true,
                    disabled: true,
                    InputLabelProps: { shrink: true },
                  }}
                />
                <BaseFormField
                  formFieldProps={{
                    pb: 0,
                  }}
                  formInputProps={{
                    type: 'text',
                    label: 'Case',
                    value: caseNumber,
                    fullWidth: true,
                    disabled: true,
                    InputLabelProps: { shrink: true },
                  }}
                />
              </>
            ) : (
              <>
                <Controller
                  control={control}
                  name="firebaseUserId"
                  render={({ field: { onChange, value } }) => {
                    const onChangeHandler = async (value: EventParticipant) => {
                      onChange(value?.['@id']);
                      await onFetchParticipantCasesHandler(value?.['@id']);
                    };

                    return (
                      <BaseAutocompleteField
                        value={
                          value
                            ? userList.find(option => {
                                return value === option['@id'];
                              }) ?? null
                            : null
                        }
                        isDisabled={!!currentEventData}
                        disableClearable
                        onChangeHandler={onChangeHandler}
                        isOptionEqualToValue={(option, value) => option?.['@id'] === value?.['@id']}
                        getOptionLabel={option => `${option?.userProfile?.firstName} ${option?.userProfile?.lastName}`}
                        options={userList}
                        errorMessage={errors['firebaseUserId']?.message}
                        isLoading={isUsersFetching}
                        autocompleSxProps={{
                          '.MuiInputBase-root': {
                            py: '4.5px',
                          },
                        }}
                        formFieldProps={{
                          pb: 0,
                        }}
                        formInputProps={{
                          label: isAttorney ? 'Client' : 'Attorney',
                          '.MuiInputBase-input': {
                            pr: '20px',
                          },
                        }}
                        placeholder={`Select ${isAttorney ? 'client' : 'attorney'}`}
                      />
                    );
                  }}
                />

                <Controller
                  control={control}
                  name="legalCaseId"
                  render={({ field: { onChange, value } }) => {
                    const onChangeHandler = (value: ParticipantCase) => onChange(value['@id']);

                    return (
                      <BaseAutocompleteField
                        value={
                          value
                            ? legalCases.find(option => {
                                return value === option['@id'];
                              }) ?? null
                            : null
                        }
                        isLoading={isCasesFetching}
                        disableClearable
                        isDisabled={!legalCases?.length || !!currentEventData}
                        onChangeHandler={onChangeHandler}
                        getOptionLabel={option => option?.caseNumber}
                        options={legalCases}
                        autocompleSxProps={{
                          '.MuiInputBase-root': {
                            py: '4.5px',
                          },
                        }}
                        formFieldProps={{
                          pb: 0,
                        }}
                        formInputProps={{
                          label: 'Case',
                          '.MuiInputBase-input': {
                            pr: '20px',
                          },
                        }}
                        placeholder={
                          !legalCases?.length ? `Select ${isAttorney ? 'client' : 'attorney'} first` : 'Select case'
                        }
                      />
                    );
                  }}
                />
              </>
            )}

            <Controller
              name="date"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                const onChangeHandler = (newValue: Date) => onChange(newValue);
                const onClickClearButtonHandler = () => onChange(null);

                return (
                  <BaseDatePicker
                    onClickClearButtonHandler={onClickClearButtonHandler}
                    isError={!!error?.message}
                    onError={onErrorHandler}
                    disablePast
                    value={value}
                    onChange={onChangeHandler}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        variant: 'outlined',
                        error: !!error,
                        helperText: error?.message,
                        label: 'Date',
                      },
                      actionBar: {
                        actions: ['clear', 'accept'],
                      },
                    }}
                    sx={Styled.TimePickerSX}
                  />
                );
              }}
            />

            <Controller
              name="time"
              control={control}
              render={({ field, fieldState: { error } }) => {
                const onChangeHandler = (newValue: Date) => field.onChange(newValue);
                const onClickClearButtonHandler = () => field.onChange(null);

                return (
                  <BaseTimePicker
                    label="Time"
                    isError={!!error?.message}
                    value={field?.value}
                    onClickClearButtonHandler={onClickClearButtonHandler}
                    onErrorHandler={onErrorTimePickerHandler}
                    onChange={onChangeHandler}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        variant: 'outlined',
                        error: !!error,
                        helperText: error?.message,
                        label: 'Time',
                      },
                    }}
                    sx={Styled.TimePickerSX}
                  />
                );
              }}
            />

            <Controller
              name="eventType"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                const onChangeHandler = (_: React.SyntheticEvent<Element, Event>, newValue: EventTypeOption) =>
                  onChange(newValue?.type);

                return (
                  <Autocomplete
                    options={EVENT_TYPE_OPTIONS}
                    getOptionLabel={option => option.title}
                    value={
                      value
                        ? EVENT_TYPE_OPTIONS.find(option => {
                            return value === option.type;
                          }) ?? null
                        : null
                    }
                    isOptionEqualToValue={(option, value) => option.title === value.title}
                    freeSolo={false}
                    onChange={onChangeHandler}
                    disabled={!!currentEventData}
                    disableClearable={true}
                    sx={{ ...Styled.AutocompleteSX, mt: 2.5 }}
                    renderInput={params => (
                      <BaseFormField
                        formFieldProps={{
                          pb: 0,
                        }}
                        formInputProps={{
                          ...params,
                          label: 'Event type',
                          InputLabelProps: { shrink: true },
                          fullWidth: true,
                        }}
                      />
                    )}
                  />
                );
              }}
            />

            <Stack spacing={1}>
              <MDTypography variant="button" fontWeight="medium">
                Notification
              </MDTypography>

              <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1}>
                  <NotificationsNoneOutlined />
                  <MDTypography
                    variant="button"
                    fontWeight="medium"
                    sx={{
                      maxWidth: '70%',
                      width: 1,
                      whiteSpace: 'wrap',
                    }}
                  >
                    Do you need a reminder of a scheduled call?
                  </MDTypography>
                </Stack>
                <Switch {...register('isReminder')} checked={isReminder} />
              </Stack>

              {isReminder && (
                <Controller
                  name="reminderValue"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    const onChangeHandler = (_: React.SyntheticEvent<Element, Event>, newValue: ReminderOption) =>
                      onChange(newValue?.title);

                    return (
                      <Autocomplete
                        options={REMINDER_OPTIONS}
                        getOptionLabel={option => option.title}
                        value={
                          value
                            ? REMINDER_OPTIONS.find(option => {
                                return value === option.title;
                              }) ?? null
                            : null
                        }
                        isOptionEqualToValue={(option, value) => option.title === value.title}
                        freeSolo={false}
                        onChange={onChangeHandler}
                        disableClearable
                        sx={Styled.AutocompleteSX}
                        renderInput={params => (
                          <BaseFormField
                            formFieldProps={{
                              pb: 0,
                            }}
                            formInputProps={{
                              ...params,
                              label: 'Reminder',
                              InputLabelProps: { shrink: true },
                              fullWidth: true,
                            }}
                          />
                        )}
                      />
                    );
                  }}
                />
              )}
            </Stack>

            <Stack>
              <MDTypography variant="button" fontWeight="medium">
                Notes
              </MDTypography>

              <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1}>
                  <StickyNote2Outlined />
                  <MDTypography variant="button" fontWeight="medium">
                    Add note?
                  </MDTypography>
                </Stack>
                <Switch {...register('isAddNote')} checked={isAddNote} />
              </Stack>

              {isAddNote && (
                <Stack spacing={1} mt={1.5}>
                  <BaseFormField
                    formInputProps={{
                      ...register('note.noteBody'),
                      placeholder: 'Enter note description',
                      type: 'text',
                      label: 'Note',
                      fullWidth: true,
                      multiline: true,
                      rows: 4,
                      InputLabelProps: { shrink: true },
                    }}
                    errorValue={errors?.note?.noteBody?.message}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
          <MDButton
            type="submit"
            variant="gradient"
            color="info"
            isLoading={isSubmitting}
            disabled={isCreateButtonDisabled}
            sx={{
              width: 1,
              mt: 1.5,
              textTransform: 'none',
            }}
          >
            {`${currentEventData ? 'Edit' : 'Create'} event`}
          </MDButton>
        </>
      )}
    </MDBox>
  );
};
