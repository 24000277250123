import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useFirebaseUser } from 'features/auth/contexts/FirebaseUserContextProvider';
import { ChangePassword, Accounts } from '../components';
import { TemplateWrapper } from './TemplateWrapper';
import { PinCode } from '../components/PinCode/PinCode';

export const SecurityTemplate = (): JSX.Element => {
  const { firebaseUser } = useFirebaseUser();
  const [isChangePasswordVisible, setIsChangePassowordVisible] = useState(false);

  useEffect(() => {
    const isPasswordAvailiable = firebaseUser?.providerData?.find(provider => provider.providerId === 'password');
    setIsChangePassowordVisible(!!isPasswordAvailiable);
  }, []);

  return (
    <TemplateWrapper>
      <Stack spacing={3}>
        {isChangePasswordVisible && <ChangePassword />}
        <PinCode />
        <Accounts />
      </Stack>
    </TemplateWrapper>
  );
};
