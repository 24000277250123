import { useUser } from '../../common';
import { PlatformSettings } from '../components/PlatformSettings';
import { TemplateWrapper } from './TemplateWrapper';

export const NotificationsControlTemplate = (): JSX.Element => {
  const { backendUser } = useUser();
  const settingsId = backendUser?.setting ? backendUser?.setting.replace('/user_settings/', '') : '';

  return <TemplateWrapper>{settingsId && <PlatformSettings settingId={settingsId} />}</TemplateWrapper>;
};
