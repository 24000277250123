import { FC, memo, useEffect, useRef, useState } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import { AllNotesMenuContentProps } from 'features/case-details/types';
import { useIsOnScreen } from 'features/common/hooks/useIsOnScreen';
import MDBox from 'components/MDBox';
import { NotesCard } from './NotesCard';
import { NOTES_PER_PAGE } from './MyNotes';

export const AllNotesMenuContent: FC<AllNotesMenuContentProps> = memo(
  ({
    caseNotes,
    isLoading,
    isFetchMore,
    currentPage,
    isAttorneyView,
    isNotMyNote,
    setCurrentPage,
    onDeleteIconClickHandler,
    onEditIconClickHandler,
    fetchMoreLegalCaseNotesHandler,
    aesDecrypt,
  }) => {
    const [isFetchMoreLoading, setIsFetchMoreLoading] = useState(false);

    const lastElementRef = useRef<HTMLDivElement | null>(null);
    const isOnScreen = useIsOnScreen(isLoading ? null : lastElementRef);

    const onFetchMoreMyLegalCaseNotesHandler = async () => {
      setIsFetchMoreLoading(true);

      const nextPage = currentPage + 1;
      await fetchMoreLegalCaseNotesHandler({ itemsPerPage: NOTES_PER_PAGE, page: nextPage });
      setCurrentPage(nextPage);

      setIsFetchMoreLoading(false);
    };

    useEffect(() => {
      if (isOnScreen && isFetchMore) {
        onFetchMoreMyLegalCaseNotesHandler();
      }
    }, [isOnScreen]);

    return (
      <Stack
        spacing={2}
        sx={{
          mt: 3,
          pb: 1.5,
          height: 1,
        }}
      >
        <Stack position="relative" height={1} spacing={2}>
          {isLoading && (
            <Stack flex={1} justifyContent="center" alignItems="center">
              <CircularProgress />
            </Stack>
          )}

          {!isLoading &&
            caseNotes?.map((note, index) => {
              return (
                <NotesCard
                  key={note?.['@id']}
                  lastElementRef={index === caseNotes.length - 1 ? lastElementRef : null}
                  visible={note?.visible}
                  onEditIconClickHandler={onEditIconClickHandler}
                  onDeleteIconClickHandler={onDeleteIconClickHandler}
                  title={note?.title}
                  id={note?.['@id']}
                  createdAt={note?.createdAt}
                  description={aesDecrypt(note?.description) || ''}
                  isAttorneyView={isAttorneyView}
                  isNotMyNote={isNotMyNote}
                />
              );
            })}

          {isFetchMoreLoading && (
            <MDBox sx={{ position: 'absolute', bottom: 15, left: '50%', transform: 'translateX(-50%)' }}>
              <CircularProgress />
            </MDBox>
          )}
        </Stack>
      </Stack>
    );
  }
);
