import { useCallback, useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { useUser } from './useUser';

const APP_SECRET = process.env.REACT_APP_APP_SECRET;

export type ApiSecurityFunction = (payload: string) => string;

export const useApiSecurity = () => {
  const [secretKey, setSecretKey] = useState<CryptoJS.lib.WordArray | null>(null);
  const [iv, setIv] = useState<CryptoJS.lib.WordArray | null>(null);

  const { backendUser } = useUser();

  const aesEncrypt: ApiSecurityFunction = useCallback(
    (payload: string) => {
      if ((!secretKey && !iv) || !payload) return;
      try {
        const cipher = CryptoJS.AES.encrypt(payload, secretKey, {
          iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        return btoa(cipher.toString());
      } catch (error) {
        console.error('Error encrypting payload:', error);
      }
    },
    [secretKey, iv]
  );

  const aesDecrypt: ApiSecurityFunction = useCallback(
    (payload: string) => {
      if ((!secretKey && !iv) || !payload) return;

      try {
        const cipher = CryptoJS.AES.decrypt(atob(payload), secretKey, {
          iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        return cipher.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        console.error('Error decrypting payload:', error);
        return payload;
      }
    },
    [secretKey, iv]
  );

  useEffect(() => {
    const generateIV = () => {
      const ivHex = CryptoJS.SHA256(APP_SECRET).toString().substring(0, 16);
      const iv = CryptoJS.enc.Utf8.parse(ivHex);
      return iv;
    };

    const convertSecretKey = () => {
      const secretKeyHex = CryptoJS.SHA256(backendUser?.firebaseUser).toString().substring(0, 32);
      const parsedSecretKey = CryptoJS.enc.Utf8.parse(secretKeyHex);
      return parsedSecretKey;
    };

    const iv = generateIV();
    const secretKey = convertSecretKey();

    setSecretKey(secretKey);
    setIv(iv);
  }, []);

  return { aesEncrypt, aesDecrypt };
};
