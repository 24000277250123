import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardContent, CardHeader, Divider, Stack } from '@mui/material';
import { ClioCaseLawType, clioSettingsApiService } from 'features/settings/ClioSettingsApiService';
import { LawTypeItem } from 'features/clio-settings/components';
import { useBoolean } from 'features/common/hooks';
import { Loader } from 'features/common/components/Loader';
import { SideMenu, ToastType, notice } from 'features/common';
import { LawTypeMenuContent } from './LawTypeMenuContent';
import { CombinedClioCaseLawTypesAccum } from './types';

export const CaseLawTypeMapping: FC = () => {
  const { getClioCaseLawTypes } = clioSettingsApiService();

  const [clioCaseLawTypes, setClioCaseLawTypes] = useState<ClioCaseLawType[]>([]);

  const [isClioCaseLawTypesLoading, setIsClioCaseLawTypesLoading] = useState(true);

  const onGetClioCaseLawTypes = async () => {
    try {
      const { data } = await getClioCaseLawTypes();
      setClioCaseLawTypes(data['hydra:member']);
      setIsClioCaseLawTypesLoading(false);
    } catch (error) {
      setIsClioCaseLawTypesLoading(false);
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  };

  useEffect(() => {
    onGetClioCaseLawTypes();
  }, []);

  const combinedClioCaseLawTypes = useMemo(
    () =>
      clioCaseLawTypes.reduce<CombinedClioCaseLawTypesAccum>((acc, { caseLawType, practiceAreaCategory }, index) => {
        const existingIndex = acc.findIndex(el => el?.caseLawType?.['@id'] === caseLawType?.['@id']);

        if (existingIndex === -1) {
          acc.push({ caseLawType, practiceAreaCategories: [practiceAreaCategory] });
        } else {
          acc[existingIndex].practiceAreaCategories.push(practiceAreaCategory);
        }

        return acc;
      }, []),

    [clioCaseLawTypes]
  );

  const [selectedLawType, setSelectedLawType] = useState<CombinedClioCaseLawTypesAccum[0] | null>(null);
  const [isSelectLawTypeMenuOpen, onOpenSelectLawTypeMenuHandler, onCloseSelectLawTypeMenuHandler] = useBoolean(false);

  const onClickLawTypeItemHandler = useCallback(
    (event: React.MouseEvent<HTMLLIElement>) => {
      const selectedLawTypeData = combinedClioCaseLawTypes?.find(
        lawType => lawType.caseLawType['@id'] === event.currentTarget.id
      );
      setSelectedLawType(selectedLawTypeData);
      onOpenSelectLawTypeMenuHandler();
    },
    [combinedClioCaseLawTypes]
  );

  const onCloseSelectLawTypeAndResetStateHandler = () => {
    setSelectedLawType(null);
    onCloseSelectLawTypeMenuHandler();
  };

  return (
    <Card sx={{ height: 1, minHeight: 400 }}>
      <CardHeader title="Case law type mapping" />
      <CardContent sx={{ height: 1 }}>
        <Stack height={1}>
          {isClioCaseLawTypesLoading ? (
            <Loader />
          ) : (
            <Stack component="ul" divider={<Divider sx={{ my: 1 }} />}>
              {combinedClioCaseLawTypes?.map(({ caseLawType, practiceAreaCategories }) => (
                <LawTypeItem
                  key={caseLawType?.['@id']}
                  lawTypeId={caseLawType?.['@id']}
                  lawTypeTitle={caseLawType?.title}
                  clioLawTypes={practiceAreaCategories}
                  onClickLawTypeItemHandler={onClickLawTypeItemHandler}
                  isSelected={caseLawType?.['@id'] === selectedLawType?.caseLawType?.['@id']}
                />
              ))}
            </Stack>
          )}
        </Stack>

        <SideMenu
          isOpen={isSelectLawTypeMenuOpen}
          onClose={onCloseSelectLawTypeAndResetStateHandler}
          title="Law type"
          customWidth={450}
        >
          <LawTypeMenuContent
            selectedLawTypeId={selectedLawType?.caseLawType?.['@id']}
            selectedLawTypeTitle={selectedLawType?.caseLawType?.title}
            matchedClioLawTypes={selectedLawType?.practiceAreaCategories}
            onCloseMenuHandler={onCloseSelectLawTypeAndResetStateHandler}
            setClioCaseLawTypes={setClioCaseLawTypes}
          />
        </SideMenu>
      </CardContent>
    </Card>
  );
};
