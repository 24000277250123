import React, { useState } from 'react';
import { AuthMethodType } from 'features/auth/types';
import { IllustrationLayout } from 'features/common';
import { AuthenticationMethodTabs } from '../AuthenticationMethodTabs';
import { FirebaseSignUpWithEmail } from './FirebaseSignUpWithEmail';
import { FirebaseAuthWithPhone } from '../FirebaseAuthWithPhone';
import { AuthFlowBackgrounds } from 'features/auth/variables/illustartion';
import { Illustrationtitle } from 'features/common/components/IllustatrionLogo';

export const SignUpStep = () => {
  const [authMethodType, setAuthMethodType] = useState(AuthMethodType.EMAIL);

  const handleSetTabValue = (_: React.SyntheticEvent<Element, Event>, newValue: AuthMethodType) =>
    setAuthMethodType(newValue);

  return (
    <IllustrationLayout
      title="Sign Up"
      illustration={AuthFlowBackgrounds.AUTH_ONE}
      illustrationLogo={<Illustrationtitle />}
    >
      <AuthenticationMethodTabs authMethodType={authMethodType} handleSetTabValue={handleSetTabValue} />
      {authMethodType === AuthMethodType.EMAIL && <FirebaseSignUpWithEmail />}
      {authMethodType === AuthMethodType.PHONE && <FirebaseAuthWithPhone />}
    </IllustrationLayout>
  );
};
