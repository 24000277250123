import { useState, useEffect } from 'react';

type Device = 'Mobile' | 'Desktop';
export const useDeviceDetection = () => {
  const [device, setDevice] = useState<Device | ''>('');

  useEffect(() => {
    const handleDeviceDetection = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
      const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

      if (isMobile || isTablet) {
        setDevice('Mobile');
      } else {
        setDevice('Desktop');
      }
    };

    handleDeviceDetection();
  }, []);

  return device;
};
