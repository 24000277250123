import { FC } from 'react';
import { Divider, Stack, Tooltip } from '@mui/material';
import { Clear } from '@mui/icons-material';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { getFormattedFileSize } from 'features/common/helpers/utilities';
import { SupportedFileTypes } from '../form.config';
import { DocumentTypeIcon } from './DocumentTypeIcon';

type DocumentItemProps = {
  fileOwnerFirstName: string;
  fileOwnerLastName: string;
  fileType: SupportedFileTypes;
  id: string;
  size: number | string;
  title: string;
  onClickDeleteIconHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const DocumentItem: FC<DocumentItemProps> = ({
  onClickDeleteIconHandler,
  id,
  fileType,
  size,
  title,
  fileOwnerFirstName,
  fileOwnerLastName,
}) => {
  const fileSize = getFormattedFileSize(Number(size));

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1} alignItems="center">
        <DocumentTypeIcon fileType={fileType} />
        <Stack spacing={0.5} sx={{ flex: 1 }}>
          <MDTypography variant="subtitle2" noWrap sx={{ width: 350 }}>
            {title}
          </MDTypography>

          <MDTypography variant="button" color="text">
            {`${fileOwnerFirstName} ${fileOwnerLastName} - ${fileSize}`}
          </MDTypography>
        </Stack>
        <Tooltip title="Delete file">
          <MDButton
            color="secondary"
            size="small"
            variant="outlined"
            iconOnly
            id={id}
            sx={{ mr: '4px !important' }}
            onClick={onClickDeleteIconHandler}
          >
            <Clear />
          </MDButton>
        </Tooltip>
      </Stack>
      <Divider />
    </Stack>
  );
};
