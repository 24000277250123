import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { set } from 'date-fns';
import { DateValidationError, TimeValidationError } from '@mui/x-date-pickers';
import { CreateEventValidationScheme, createEventValidatationSchema } from '../form.config';
import { UseCreateEventStateProps } from '../types';
import { REMINDER_OPTIONS } from '../caseEvents.constants';

export const useCreateEventState = ({
  closeAddEventModal,
  currentEventData,
  selectedDate,
  createEventHandler,
  patchEventHandler,
  userFirebaseId,
  legalCaseId,
  currentUser,
}: UseCreateEventStateProps) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    clearErrors,
    setError,
    getValues,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<CreateEventValidationScheme>({
    resolver: yupResolver(createEventValidatationSchema),
    mode: 'onTouched',
    values: {
      firebaseUserId:
        userFirebaseId && legalCaseId ? `/users/${userFirebaseId}` : `/users/${currentUser?.user?.firebaseUser}` || '',
      legalCaseId: legalCaseId ? legalCaseId : currentEventData?.legalCase || '',
      date: currentEventData ? new Date(currentEventData?.startedAt) : selectedDate || null,
      time: currentEventData ? new Date(currentEventData?.startedAt) : null,
      isReminder: currentEventData?.notificationEnabled || false,
      isAddNote: currentEventData?.noteEnabled || false,
      reminderValue: currentEventData?.notificationTime
        ? currentEventData?.notificationTime
        : REMINDER_OPTIONS[0].title,
      eventType: currentEventData?.type || 'video_call',
      note: {
        noteBody: currentEventData?.note || '',
      },
    },
  });

  const onErrorHandler = (error: DateValidationError) => {
    error
      ? setError('date', {
          message: 'Enter a valid date format',
        })
      : clearErrors('date');
  };

  const onErrorTimePickerHandler = (error: TimeValidationError) => {
    error
      ? setError('time', {
          message: 'Enter a valid time format',
        })
      : clearErrors('time');
  };

  const isAddNote = watch('isAddNote');
  const isReminder = watch('isReminder');
  const formValueFirebaseUserId = watch('firebaseUserId');

  const onFormSubmitHandler = handleSubmit(async formData => {
    const selectedDate = formData.date;
    const selectedTime = formData.time;

    const combinedDateTime = set(selectedDate, {
      hours: selectedTime.getHours(),
      minutes: selectedTime.getMinutes(),
    }).toISOString();

    !currentEventData
      ? await createEventHandler({
          legalCase: formData.legalCaseId,
          user: `${formData.firebaseUserId}`,
          type: formData.eventType,
          startedAt: combinedDateTime,
          ...(formData.isAddNote && {
            noteEnabled: true,
            note: formData.note.noteBody,
          }),
          ...(formData.isReminder && {
            notificationEnabled: true,
            notificationTime: formData.reminderValue,
          }),
        })
      : await patchEventHandler(currentEventData?.id, {
          startedAt: combinedDateTime,
          type: formData.eventType,
          ...(formData.isAddNote
            ? {
                note: formData.note.noteBody,
                noteEnabled: true,
              }
            : {
                note: null,
                noteEnabled: false,
              }),
          ...(formData.isReminder
            ? {
                notificationEnabled: true,
                notificationTime: formData.reminderValue,
              }
            : {
                notificationEnabled: false,
                notificationTime: null,
              }),
        });
    closeAddEventModal();
  });

  const isCreateButtonDisabled = isSubmitting || !isValid || !isDirty;

  return {
    isAddNote,
    isCreateButtonDisabled,
    isReminder,
    control,
    isSubmitting,
    errors,
    formValueFirebaseUserId,
    register,
    onErrorHandler,
    onErrorTimePickerHandler,
    onFormSubmitHandler,
  };
};
