import { InputAdornment } from '@mui/material';
import { TimePicker, TimeValidationError } from '@mui/x-date-pickers';
import ClearIcon from '@mui/icons-material/Clear';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { isValid, parseISO } from 'date-fns';
import {
  ControllerRenderProps,
  UseFormClearErrors,
  UseFormSetError,
  UseFormReturn,
  FieldError,
  Path,
} from 'react-hook-form';
import { useState } from 'react';

type BaseTimePickerFieldProps<T> = Pick<UseFormReturn<T>, 'watch'> & {
  field: ControllerRenderProps<T>;
  clearErrors: UseFormClearErrors<T>;
  setError: UseFormSetError<T>;
  error: FieldError;
  watchTimeInputValue: Path<T>;
  watchSwitcherValue: Path<T>;
  label: string;
  isDisabled?: boolean;
};

export function BaseTimePickerField<T>({
  field,
  clearErrors,
  setError,
  watch,
  error,
  watchTimeInputValue,
  watchSwitcherValue,
  label,
  isDisabled,
}: BaseTimePickerFieldProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const onClickOpenIconHandler = () => setIsOpen(true);
  const onCloseHandler = () => setIsOpen(false);

  const onClickClearButtonHandler = () => field.onChange('');

  const onChangeHandler = (newValue: Date) => {
    const isValidValue = isValid(newValue);
    field.onChange(isValidValue ? newValue.toISOString() : '');
    isValidValue && error && clearErrors(watchTimeInputValue);
  };

  const onErrorHandler = (error: TimeValidationError) => {
    if (watch(watchSwitcherValue) && error) {
      setError(watchTimeInputValue, {
        message: 'Enter a valid time format',
      });
    }
  };

  return (
    <TimePicker
      {...field}
      disabled={isDisabled}
      label={label}
      open={isOpen}
      onOpen={onClickOpenIconHandler}
      onClose={onCloseHandler}
      slots={{
        inputAdornment: props => (
          <>
            <InputAdornment
              {...props}
              disableTypography={!field.value}
              sx={{ cursor: field.value ? 'pointer' : 'default' }}
              disablePointerEvents={isDisabled}
              onClick={onClickClearButtonHandler}
            >
              <ClearIcon fontSize="medium" color={!field.value || isDisabled ? 'disabled' : 'action'} />
            </InputAdornment>

            <InputAdornment
              {...props}
              disablePointerEvents={isDisabled}
              sx={{ cursor: 'pointer' }}
              onClick={onClickOpenIconHandler}
            >
              <AccessTimeIcon fontSize="medium" color={isDisabled ? 'disabled' : 'action'} />
            </InputAdornment>
          </>
        ),
      }}
      slotProps={{
        mobilePaper: {
          sx: {
            '.MuiTimeClock-root': {
              width: 290,
            },
            '.MuiDialogActions-root': {
              justifyContent: 'center',
            },
            '.MuiClockPointer-thumb': {
              backgroundColor: theme => theme.palette.info.main,
              borderColor: theme => theme.palette.info.main,
            },
            '.MuiPickersArrowSwitcher-root': {
              top: 0,
            },
            mx: 1,
            minWidth: '290px !important',
            '.MuiClockPointer-root, .MuiClock-pin': {
              backgroundColor: theme => `${theme.palette.info.main}`,
            },
          },
        },
        desktopPaper: {
          sx: {
            '.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClock-root': {
              width: 'auto !important',
              overflowY: 'scroll !important',
            },
            '.MuiDialogActions-root': {
              padding: '5px',
            },
          },
        },
        digitalClockSectionItem: {
          sx: {
            minWidth: 'auto !important',
            ':hover': {
              backgroundColor: theme => theme.palette.grey[200],
            },
            '&.Mui-selected': {
              backgroundColor: theme => `${theme.palette.info.main} !important`,
              ':focus': {
                color: 'white !important',
              },
              ':hover': {
                backgroundColor: theme => `${theme.palette.info.light} !important`,
              },
            },
          },
        },
      }}
      disableOpenPicker={false}
      sx={{
        ...(error
          ? {
              '& .MuiOutlinedInput-notchedOutline, &:after': {
                borderColor: theme => `${theme.palette.error.main} !important`,
              },
              '& .Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline, &:after': {
                  borderColor: theme => `${theme.palette.error.main} !important`,
                  ':hover': {
                    borderColor: theme => `${theme.palette.error.main} !important`,
                  },
                },
              },

              '& .MuiInputLabel-root.Mui-focused': {
                color: theme => `${theme.palette.error.main} !important`,
              },
            }
          : {
              '& .Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline, &:after': {
                  borderColor: theme => `${theme.palette.info.main} !important`,
                },
              },

              '& .MuiInputLabel-root.Mui-focused': {
                color: theme => `${theme.palette.info.main} !important`,
              },
            }),
      }}
      value={parseISO(field.value?.toString())}
      onChange={onChangeHandler}
      onError={onErrorHandler}
    />
  );
}
