import * as yup from 'yup';
import { PracticeAreaCategory } from 'features/clio-settings/ClioSettingsApiService';

export type DefaultAddressSchema = {
  defaultAddress: string;
};

export const defaultValidationFormSchema: yup.ObjectSchema<DefaultAddressSchema> = yup
  .object({
    defaultAddress: yup.string().required(),
  })
  .required();

export const PracticeAreaCategoriesValidationSchema = yup
  .array()
  .of(
    yup.object().shape({
      '@id': yup.string().required(),
      '@type': yup.string().required(),
      id: yup.string().required(),
      value: yup.string().required(),
    })
  )
  .min(1, 'You should choose at least one law type')
  .required('Practice area categories are required');

export type SelectClioLawTypesFormValues = {
  clioCaseLawTypes: PracticeAreaCategory[];
};

export const selectClioLawTypesValidationFormSchema = yup
  .object({
    clioCaseLawTypes: PracticeAreaCategoriesValidationSchema,
  })
  .required();
