import { FC } from 'react';
import { Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import { BaseDatePicker, BaseTimePicker } from 'features/common/components/BaseTimePicker';
import { BaseFormField } from 'features/common';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { REGULARITIES } from './form.config';
import { RegularityButtonsBox } from './RegularityButtonsBox';
import { CreateDelayedNotificationMenuContentProps } from './types';
import { useDelayedNotificationForm } from './hooks/useDelayedNotificationForm';
import { CreateEventModalContentStyles as Styled } from '../CaseEvents/components/CreateEventModalContent.styles';

export const CreateDelayedNotificationMenuContent: FC<CreateDelayedNotificationMenuContentProps> = ({
  onCloseMenuHandler,
  createLegaCaseDelayedNotificationHandler,
  updateLegalCaseDelayedNotificationHandler,
  message,
  regularity,
  startedAt,
  isCreateMode,
}) => {
  const {
    errors,
    onErrorHandler,
    onErrorTimePickerHandler,
    onFormSubmitHandler,
    control,
    isCreateButtonDisabled,
    isSubmitting,
    register,
    onChangeRegularityValue,
    regularityValue,
  } = useDelayedNotificationForm({
    message,
    regularity,
    startedAt,
    isCreateMode,
    onCloseMenuHandler,
    createLegaCaseDelayedNotificationHandler,
    updateLegalCaseDelayedNotificationHandler,
  });

  return (
    <MDBox
      component="form"
      onSubmit={onFormSubmitHandler}
      my={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <Stack spacing={2.5} flex={1}>
        <Controller
          name="startingDate"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const onChangeHandler = (newValue: Date) => onChange(newValue);
            const onClickClearButtonHandler = () => onChange(null);

            return (
              <BaseDatePicker
                onClickClearButtonHandler={onClickClearButtonHandler}
                isError={!!error?.message}
                onError={onErrorHandler}
                disablePast
                value={value}
                onChange={onChangeHandler}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: 'outlined',
                    error: !!error,
                    helperText: error?.message,
                    label: 'Date',
                  },
                  actionBar: {
                    actions: ['clear', 'accept'],
                  },
                }}
                sx={Styled.TimePickerSX}
              />
            );
          }}
        />

        <Controller
          name="time"
          control={control}
          render={({ field, fieldState: { error } }) => {
            const onChangeHandler = (newValue: Date) => field.onChange(newValue);
            const onClickClearButtonHandler = () => field.onChange(null);

            return (
              <BaseTimePicker
                label="Time"
                isError={!!error?.message}
                value={field?.value}
                onClickClearButtonHandler={onClickClearButtonHandler}
                onErrorHandler={onErrorTimePickerHandler}
                onChange={onChangeHandler}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: 'outlined',
                    error: !!error,
                    helperText: error?.message,
                    label: 'Time',
                  },
                }}
                sx={Styled.TimePickerSX}
              />
            );
          }}
        />

        <BaseFormField
          formFieldProps={{
            pb: 0,
          }}
          formInputProps={{
            ...register('message'),
            type: 'text',
            label: 'Message',
            fullWidth: true,
            multiline: true,
            rows: 4,
            InputLabelProps: { shrink: true },
          }}
          errorValue={errors['message']?.message}
        />

        <Stack spacing={1.5}>
          <MDTypography fontSize={16} variant="button" fontWeight="medium">
            What regularity
          </MDTypography>
          <Stack spacing={1.5}>
            <RegularityButtonsBox
              regularities={REGULARITIES}
              currentValue={regularityValue}
              onChangeRegularityValue={onChangeRegularityValue}
            />
          </Stack>
        </Stack>
      </Stack>
      <MDButton
        type="submit"
        variant="gradient"
        color="info"
        isLoading={isSubmitting}
        disabled={isCreateButtonDisabled}
      >
        {isCreateMode ? 'Create' : 'Save'}
      </MDButton>
    </MDBox>
  );
};
