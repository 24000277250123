import { useCallback, useEffect, useState } from 'react';
import { notice, ToastType, useRouter, useUser } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';
import { useFirebaseUser } from '../contexts/FirebaseUserContextProvider';

const publicRoutes: ReadonlyArray<string> = [
  ROUTES.signIn,
  ROUTES.signUp,
  ROUTES.recoveryPassword,
  ROUTES.resetPassword,
  ROUTES.firebaseSecurity,
  ROUTES.verifyUserEmail,
  ROUTES.openApp,
  '/docusign/callback',
];

const adminRequiredRoutes: ReadonlyArray<ROUTES | string> = [
  ROUTES.staticPages,
  ROUTES.staticCreatePage,
  ROUTES.contentItems,
  ROUTES.faq,
  ROUTES.companyList,
  ROUTES.caseLawTypes,
  ROUTES.caseLawTypesSuggestions,
  ROUTES.reviews,
  ROUTES.settingsGeneral,
  ROUTES.settingsClioIntegration,
  ROUTES.settingsMail,
  ROUTES.lockScreen,
];

const userRequiredRoutes: ReadonlyArray<ROUTES | string> = [
  ROUTES.conversations,
  ROUTES.eventCalendar,
  ROUTES.myLegalCases,
  ROUTES.createLegalCase,
  ROUTES.legalCaseChat,
  ROUTES.caseDetails,
  ROUTES.notifications,
  ROUTES.lockScreen,
  ROUTES.incominCallPush,
  ROUTES.openApp,
  ROUTES.firebaseSecurity,
];

const attorneyRequiredRoutes: ReadonlyArray<ROUTES | string> = [
  ROUTES.firmProfile,
  ROUTES.myCompanyCaseLawTypes,
  ROUTES.myCompanyPermissions,
  ROUTES.myCompanySubscriptions,
  ROUTES.myCompanySubscriptions,
  ROUTES.myCompanyUsers,
  ROUTES.myCompanyWorkingHours,
  ROUTES.importCRMCase,
  ROUTES.subscription,
  ROUTES.successPayment,
  ROUTES.cancelPayment,
  ROUTES.legalCasesFeed,
  ROUTES.clioSettings,
  ROUTES.clioCaseLawTypesMapping,
  ROUTES.attorneyLawTypes,
  ROUTES.integrations,
  ...userRequiredRoutes,
];

export const AccessGuard = ({ children }: React.PropsWithChildren): JSX.Element | null => {
  const { isAuthorized, backendUser, isAdmin, isClient, isAttorney, logout } = useUser();
  const { firebaseUser } = useFirebaseUser();

  const { navigate, push, pathname, back } = useRouter();

  const isPublicRoute = publicRoutes.includes(pathname) || pathname.startsWith('/authentication/admin-sign-up');
  const isAdminRoute = adminRequiredRoutes.includes(pathname);
  const isClientRoute = userRequiredRoutes.includes(pathname);
  const isAttorneyRoute = attorneyRequiredRoutes.includes(pathname);

  const isUndefinedRoute = !isPublicRoute && !isAdminRoute && !isClientRoute && !isAttorneyRoute;

  const isUserAuthorized = !!firebaseUser && !!backendUser && isAuthorized;

  const [isForbiddenRoute, setIsForbiddenRoute] = useState(false);

  const redirectUserHandler = useCallback(async () => {
    setIsForbiddenRoute(true);
    push(ROUTES.signIn)
      .then(async () => {
        await logout();
        setIsForbiddenRoute(false);
      })
      .then(() => {
        notice(ToastType.WARNING, 'You don`t have access to this page!');
      });
  }, []);

  // Validate firebase user routes that is not authorized
  useEffect(() => {
    if (!firebaseUser && ((!backendUser && !isAuthorized) || (backendUser && !isAuthorized)) && !isPublicRoute) {
      push(ROUTES.signIn);
    }
  }, [firebaseUser, backendUser, isAuthorized, pathname]);

  //validate user routes
  useEffect(() => {
    if (isPublicRoute || isUndefinedRoute) return;

    if (isClient && isUserAuthorized && !isClientRoute) redirectUserHandler();

    if (isAttorney && isUserAuthorized && !isAttorneyRoute) redirectUserHandler();

    if (isAdmin && isUserAuthorized && !isAdminRoute) redirectUserHandler();
  }, [firebaseUser, backendUser, isAuthorized, pathname, isAdmin, isClient, isAttorney]);

  // not allow to open auth route if use is logged in
  useEffect(() => {
    if (isPublicRoute && isUserAuthorized && !isForbiddenRoute && !isClientRoute) {
      navigate(ROUTES.home, { replace: true });
    }
  }, [pathname, isForbiddenRoute]);

  useEffect(() => {
    if (!firebaseUser) return;

    if (!backendUser && !isPublicRoute) {
      push(ROUTES.signIn);
    }
  }, [backendUser, firebaseUser, isPublicRoute]);

  if (backendUser || isPublicRoute) {
    return <>{children}</>;
  }

  return null;
};
