import { FC, useEffect } from 'react';
import { Grid, Stack } from '@mui/material';
import { DashboardLayout, useApiSecurity } from 'features/common';
import { MainChatContent } from 'features/chat/components/MainChatContent/MainChatContent';
import MDBox from 'components/MDBox';
import { Loader } from 'features/common/components/Loader';
import MDTypography from 'components/MDTypography';
import { EmptyChat } from './components/EmptyChat/EmptyChat';
import { ListOfChatsCard } from './components/ListOfChats/ListOfChatsCard';
import { UsersList } from './components/UsersList';
import { useMyChatsState } from './hooks/useMyChatsState';

export const ITEMS_PER_PAGE = 30;

export const MyChatsTemplate: FC = () => {
  const {
    isChatListLoading,
    isChatListOpen,
    isMessagessLoading,
    isAttorney,
    selectedUser,
    isSearching,
    isUsersLoading,
    searchValue,
    selectedUserChat,
    listOfUserChatsData,
    usersData,
    getMoreListOfChats,
    onChangeSearchInputHandler,
    onBackButtonClickHandler,
    onSelectedUserChatItemClickHandler,
    onUserItemClickHandler,
    resetState,
  } = useMyChatsState();

  useEffect(() => {
    return () => resetState();
  }, []);

  const { aesDecrypt } = useApiSecurity();

  return (
    <DashboardLayout>
      <Grid container spacing={2} flex={1}>
        {isUsersLoading ? (
          <Grid item xs={12} display="flex" flex={1}>
            <Loader />
          </Grid>
        ) : !isSearching && !usersData?.users?.length && !isUsersLoading && !searchValue ? (
          <Stack
            sx={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MDTypography sx={{ textAlign: 'center' }}>No chats yet</MDTypography>
          </Stack>
        ) : (
          <>
            <Grid item xs={12} md={4}>
              <MDBox sx={{ position: 'relative', height: 1, width: 1 }}>
                <UsersList
                  selectedUserId={selectedUser?.firebaseUser}
                  users={usersData?.users}
                  searchInputValue={searchValue}
                  isSearching={isSearching}
                  onChangeSearchInputHandler={onChangeSearchInputHandler}
                  onUserChatItemClickHandler={onUserItemClickHandler}
                  aesDecrypt={aesDecrypt}
                  isAttorney={isAttorney}
                />

                <ListOfChatsCard
                  listOfUserChats={listOfUserChatsData?.listOfUserChats}
                  onSelectedUserChatItemClickHandler={onSelectedUserChatItemClickHandler}
                  selectedChatId={selectedUserChat?.id}
                  isAttorney={isAttorney}
                  isChatListOpen={isChatListOpen}
                  isChatListLoading={isChatListLoading}
                  selectedUserId={selectedUser?.firebaseUser}
                  selectedUserFirstName={selectedUser?.userProfile?.firstName}
                  onCloseMenuHandler={onBackButtonClickHandler}
                  totalItems={listOfUserChatsData?.totalItems}
                  getMoreListOfChats={getMoreListOfChats}
                  aesDecrypt={aesDecrypt}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column', width: 1 }}>
              <Grid container flexDirection="column" spacing={2} flex={1} flexWrap="nowrap">
                {!selectedUserChat ? (
                  <EmptyChat />
                ) : (
                  <MainChatContent isListOfChats isHideBackButton isMessagessLoading={isMessagessLoading} />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </DashboardLayout>
  );
};
