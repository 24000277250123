import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Card, CardContent, CardHeader, CircularProgress, Stack, Switch } from '@mui/material';
import {
  BaseFormField,
  Contacts,
  CountryState,
  ToastType,
  createGeoDataService,
  crmIntegrationApiService,
  notice,
} from 'features/common';
import MDTypography from 'components/MDTypography';
import { CREATE_CASE_FORM_STEPS, useCreateCase } from 'features/store';
import MDBox from 'components/MDBox';
import { DependantInformationScheme, dependantInformationFormSchema } from '../CreateLegalCase/form.config';
import { ButtonsFooter } from '../CreateLegalCase/ButtonsFooter';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';
import { getOptionById } from 'features/common/helpers/utilities';

type AutocompleteDependantOption =
  | Contacts
  | {
      '@id': 'custom';
      value: 'Custom';
    };

export const DependantInformation: FC = () => {
  const { getCRMCaseContacts, getCRMCaseFiles, getCRMCaseNotes } = crmIntegrationApiService();
  const {
    CRMCaseDocuments,
    CRMCasenotes,
    CRMCaseData,
    setStateData,
    saveFormDataHandler,
    activeStep,
    dependantInformationData,
  } = useCreateCase(state => ({
    dependantInformationData: state.dependantData,
    activeStep: state.activeStep,
    saveFormDataHandler: state.setData,
    CRMCaseData: state.CRMCaseData,
    CRMCaseDocuments: state.CRMCaseDocuments,
    CRMCasenotes: state.CRMCasenotes,
    setStateData: state.setStateData,
  }));

  const CRMCaseid = CRMCaseData?.id;
  const CRMCaseProvider = CRMCaseData?.provider;

  const [crmCaseContacts, setCrmCaseContacts] = useState<Contacts[]>([]);
  const [isContactsLoading, setIsContactsLoading] = useState(false);

  const autocompleteDependantOptions: AutocompleteDependantOption[] = [
    { '@id': 'custom', value: 'Custom' },
    ...crmCaseContacts,
  ];

  const [selectedContactValue, setSelectedContactValue] = useState(autocompleteDependantOptions?.[0]?.['@id']);
  const [selectedAutocompleteOptionIndex, setSelectedAutocompleteOptionIndex] = useState<null | number>(null);

  const onGetCRMCaseContactsHandler = async () => {
    try {
      setIsContactsLoading(true);
      const { data } = await getCRMCaseContacts(CRMCaseid, CRMCaseProvider);
      if (data['hydra:totalItems'] === 1) {
        const firstContactItem = data?.['hydra:member']?.[0];
        setSelectedContactValue(firstContactItem?.['@id']);
        setSelectedAutocompleteOptionIndex(0);
        setValue('dependantCaseState', firstContactItem?.state?.['@id'] || '', { shouldValidate: true });
        setValue('dependantFirstName', firstContactItem?.firstName || '', { shouldValidate: true });
        setValue('dependantLastName', firstContactItem?.lastName || '', { shouldValidate: true });
        setValue('dependantLastName', firstContactItem?.lastName || '', { shouldValidate: true });
        setDependantCaseStateTitle(firstContactItem?.state?.name);
      }

      setCrmCaseContacts(data['hydra:member']);
      setIsContactsLoading(false);
    } catch (error) {
      setIsContactsLoading(false);
      console.error(error);
      paymentRequiredErrorHandler(error);
    }
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty, isSubmitting },
    watch,
    getValues,
    setValue,
  } = useForm<DependantInformationScheme>({
    resolver: yupResolver(dependantInformationFormSchema),
    mode: 'onTouched',
    defaultValues: {
      ...dependantInformationData,
    },
  });

  const isCaseForDependantCurrentValue = watch('isCaseForDependant');

  useEffect(() => {
    !CRMCaseData?.caseForDependant && isCaseForDependantCurrentValue && onGetCRMCaseContactsHandler();
  }, [isCaseForDependantCurrentValue]);

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      if (!CRMCaseDocuments?.length) {
        const { data } = await getCRMCaseFiles(CRMCaseid, CRMCaseProvider);
        setStateData('CRMCaseDocuments', data['hydra:member']);
      }

      if (!CRMCasenotes?.length) {
        const { data } = await getCRMCaseNotes(CRMCaseid, CRMCaseProvider);
        setStateData('CRMCasenotes', data['hydra:member']);
      }
      saveFormDataHandler({
        step: activeStep,
        data: { ...formData, dependantCaseStateTitle },
        activeStep: CREATE_CASE_FORM_STEPS.CASE_REVIEW,
      });
    } catch (error) {
      console.error(error);
      paymentRequiredErrorHandler(error);
    }
  });

  const [stateList, setStateList] = useState<CountryState[]>([]);
  const [isStatesLoading, setIsStatesLoading] = useState(false);
  const [dependantCaseStateTitle, setDependantCaseStateTitle] = useState(
    dependantInformationData.dependantCaseStateTitle
  );

  const { getStates } = createGeoDataService();

  const getStateListHandler = async () => {
    try {
      setIsStatesLoading(true);
      const { data } = await getStates({});
      setStateList(data['hydra:member']);
      setIsStatesLoading(false);
    } catch (error) {
      setIsStatesLoading(false);
      notice(ToastType.ERROR, 'Failed to fetch states, please try again!');
      console.error(error);
    }
  };

  useEffect(() => {
    getStateListHandler();
  }, []);

  const onClickBackButtonHandler = () => {
    saveFormDataHandler({
      ...(isDirty && {
        step: activeStep,
        data: { ...getValues(), dependantCaseStateTitle },
      }),
      activeStep: CREATE_CASE_FORM_STEPS.CASE_INFO,
    });
  };

  const isSelectContacts = !CRMCaseData?.caseForDependant && isCaseForDependantCurrentValue;

  const getOptionLabel = (option: AutocompleteDependantOption) => {
    if ('firstName' in option) {
      return option?.name;
    } else {
      return option?.value;
    }
  };

  const onChangeContactValueHandler = (
    _: React.SyntheticEvent<Element, Event>,
    value: NonNullable<AutocompleteDependantOption>
  ) => {
    setSelectedContactValue(value?.['@id']);
    if ('firstName' in value) {
      setSelectedAutocompleteOptionIndex(crmCaseContacts.findIndex(el => el['@id'] === value?.['@id']));
      setValue('dependantCaseState', value?.state?.['@id'] || '');
      setValue('dependantFirstName', value?.firstName || '');
      setValue('dependantLastName', value?.lastName || '');
    } else {
      setValue('dependantCaseState', '');
      setValue('dependantFirstName', '');
      setValue('dependantLastName', '');
    }
  };

  const contactAutocompleteValue = getOptionById(selectedContactValue, autocompleteDependantOptions);

  return (
    <form onSubmit={onFormSubmitHandler}>
      <Card>
        <CardHeader sx={{ px: 3, pb: 0 }} title="Is This Case for a Dependent?" />
        <CardContent>
          <MDTypography variant="subtitle2">
            Indicate whether you're acting as a legal guardian, executor, or representative for the party involved in
            this case.
          </MDTypography>
          <Stack spacing={1}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
              my={isSelectContacts ? 1.5 : 0}
            >
              <MDTypography variant="button" color="text" sx={{ width: 170 }}>
                Representing a dependant
              </MDTypography>
              <Switch {...register('isCaseForDependant')} checked={watch('isCaseForDependant')} />
            </Stack>
            {watch('isCaseForDependant') && (
              <Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} gap={{ xs: 0, sm: 3 }} width={1}>
                  <Stack width={1} gap={1}>
                    {isSelectContacts && (
                      <Autocomplete
                        value={contactAutocompleteValue}
                        sx={{
                          width: 1,
                          '.MuiInputBase-root': {
                            py: '4.5px',
                            mb: '18px',
                          },
                        }}
                        onChange={onChangeContactValueHandler}
                        getOptionLabel={getOptionLabel}
                        options={autocompleteDependantOptions}
                        freeSolo={false}
                        loading={isContactsLoading}
                        isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                        disableClearable
                        renderInput={params => (
                          <BaseFormField
                            formFieldProps={{ pb: { xs: 1, sm: 0 } }}
                            formInputProps={{
                              ...params,
                              label: 'Dependant',
                              placeholder: 'Select a contact',
                              InputLabelProps: { shrink: true },
                              fullWidth: true,
                              InputProps: {
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {isContactsLoading ? (
                                      <MDBox pr={0} display="flex">
                                        <CircularProgress color="inherit" size={20} />
                                      </MDBox>
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              },
                            }}
                          />
                        )}
                      />
                    )}
                    <BaseFormField
                      formInputProps={{
                        ...register('dependantFirstName'),
                        placeholder: 'Enter dependent’s first name',
                        type: 'text',
                        label: `Dependent's first name`,
                        fullWidth: true,
                        InputLabelProps: { shrink: true },
                        disabled:
                          isSelectContacts &&
                          contactAutocompleteValue?.['@id'] !== 'custom' &&
                          !!crmCaseContacts[selectedAutocompleteOptionIndex]?.firstName,
                      }}
                      errorValue={errors['dependantFirstName']?.message}
                    />

                    {!isSelectContacts && (
                      <BaseFormField
                        formFieldProps={{
                          mb: { xs: 1, sm: 0 },
                        }}
                        formInputProps={{
                          ...register('dependantLastName'),
                          placeholder: 'Enter dependent’s last name',
                          type: 'text',
                          label: `Dependent's last name`,
                          fullWidth: true,
                          InputLabelProps: { shrink: true },
                          disabled:
                            isSelectContacts &&
                            contactAutocompleteValue?.['@id'] !== 'custom' &&
                            !!crmCaseContacts[selectedAutocompleteOptionIndex]?.lastName,
                        }}
                        errorValue={errors['dependantLastName']?.message}
                      />
                    )}
                  </Stack>

                  <Stack width={1} gap={1}>
                    {isSelectContacts && (
                      <BaseFormField
                        formFieldProps={{
                          mb: { xs: 1, sm: 0 },
                        }}
                        formInputProps={{
                          ...register('dependantLastName'),
                          placeholder: 'Enter dependent’s last name',
                          type: 'text',
                          label: `Dependent's last name`,
                          fullWidth: true,
                          InputLabelProps: { shrink: true },
                          disabled:
                            isSelectContacts &&
                            contactAutocompleteValue?.['@id'] !== 'custom' &&
                            !!crmCaseContacts[selectedAutocompleteOptionIndex]?.lastName,
                        }}
                        errorValue={errors['dependantLastName']?.message}
                      />
                    )}

                    <Controller
                      control={control}
                      name="dependantCaseState"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Autocomplete
                            value={getOptionById(value, stateList)}
                            sx={{
                              width: 1,
                              '.MuiInputBase-root': {
                                py: '4.5px',
                              },
                            }}
                            onChange={(_, value) => {
                              onChange(value ? value['@id'] : '');
                              setDependantCaseStateTitle(value?.name || '');
                            }}
                            disabled={
                              isSelectContacts &&
                              contactAutocompleteValue?.['@id'] !== 'custom' &&
                              !!crmCaseContacts[selectedAutocompleteOptionIndex]?.state?.['@id']
                            }
                            getOptionLabel={option => option.name}
                            options={stateList}
                            freeSolo={false}
                            loading={isStatesLoading}
                            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                            disableClearable={false}
                            renderInput={params => (
                              <BaseFormField
                                formFieldProps={{ pb: { xs: 1, sm: 0 } }}
                                formInputProps={{
                                  ...params,
                                  label: 'State of residence',
                                  placeholder: `Select dependent's state`,
                                  InputLabelProps: { shrink: true },
                                  fullWidth: true,
                                  InputProps: {
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {isStatesLoading ? (
                                          <MDBox pr={0} display="flex">
                                            <CircularProgress color="inherit" size={20} />
                                          </MDBox>
                                        ) : null}
                                        {errors['dependantCaseState']?.message ? null : params.InputProps.endAdornment}
                                      </>
                                    ),
                                  },
                                }}
                                errorValue={errors['dependantCaseState']?.message}
                              />
                            )}
                          />
                        );
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
                >
                  <MDTypography variant="button" color="text" sx={{ width: 170 }}>
                    Is the dependent a minor (under 18)?
                  </MDTypography>

                  <Switch {...register('isUnder18')} checked={watch('isUnder18')} />
                </Stack>
                <MDTypography variant="subtitle2" pb={1}>
                  Note: If the dependent is a minor, your presence is required for all communications with an attorney.
                </MDTypography>
              </Stack>
            )}
          </Stack>

          <ButtonsFooter
            isLoading={isSubmitting}
            isSubmitButtonDisabled={!isValid || isSubmitting}
            onClickBackButtonHandler={onClickBackButtonHandler}
          />
        </CardContent>
      </Card>
    </form>
  );
};
