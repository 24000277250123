import { FC } from 'react';
import { Badge, Tabs as MUITabs, Tab } from '@mui/material';

type TabsProps = {
  tabsData: string[];
  bagesValues?: number[];
  orientation?: 'horizontal' | 'vertical';
  tabValue?: number;
  handleSetTabValue: (event: React.SyntheticEvent<Element, Event>, newValue: number) => void;
};
export const Tabs: FC<TabsProps> = ({ handleSetTabValue, bagesValues, tabsData, orientation, tabValue }) => (
  <MUITabs
    orientation={orientation}
    value={tabValue}
    onChange={handleSetTabValue}
    sx={{ overflow: 'visible', '& .MuiTabs-scroller, .MuiButtonBase-root': { overflow: 'visible !important' } }}
  >
    {tabsData.map((el, index) => (
      <Tab
        key={el}
        label={
          <Badge
            badgeContent={bagesValues ? bagesValues[index] : 0}
            color="error"
            sx={{ '.MuiBadge-badge': { right: -6, top: -1 } }}
          >
            {el}
          </Badge>
        }
      />
    ))}
  </MUITabs>
);
