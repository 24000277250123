import { FC } from 'react';
import MDBox from 'components/MDBox';
import MDAvatar from 'components/MDAvatar';

type ReviewAvatarCellProps = {
  image: string;
  avatarCharacters: string;
};

export const ReviewAvatarCell: FC<ReviewAvatarCellProps> = ({ image, avatarCharacters }) => {
  return (
    <MDBox display="flex" alignItems="center" pr={2}>
      <MDAvatar
        src={image}
        alt="user avatar"
        sx={{
          backgroundColor: theme => theme.palette.info.main,
          '& img': {
            height: 1,
          },
        }}
      >
        {avatarCharacters}
      </MDAvatar>
    </MDBox>
  );
};
