import { FC, memo } from 'react';
import { Chip, Stack } from '@mui/material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { PracticeAreaCategory } from 'features/clio-settings/ClioSettingsApiService';
import MDTypography from 'components/MDTypography';

type LawTypeItemProps = {
  lawTypeId: string;
  lawTypeTitle: string;
  clioLawTypes: PracticeAreaCategory[];
  isSelected: boolean;
  onClickLawTypeItemHandler: (event: React.MouseEvent<HTMLLIElement>) => void;
};

export const LawTypeItem: FC<LawTypeItemProps> = memo(
  ({ clioLawTypes, lawTypeId, lawTypeTitle, isSelected, onClickLawTypeItemHandler }) => {
    return (
      <Stack
        component="li"
        id={lawTypeId}
        onClick={onClickLawTypeItemHandler}
        sx={{
          p: 1,
          borderRadius: '6px',
          ':hover': {
            backgroundColor: ({ palette }) => palette.grey[200],
            cursor: 'pointer',
          },
          ...(isSelected && {
            backgroundColor: ({ palette }) => palette.grey[200],
          }),
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <MDTypography variant="h6" fontWeight="medium">
            {lawTypeTitle}
          </MDTypography>
          <ChevronRightRoundedIcon fontSize="medium" />
        </Stack>
        <Stack component="ul" direction="row" flexWrap="wrap" ml={-1}>
          {clioLawTypes?.map(clioLawType => (
            <Stack component="li" direction="row" key={clioLawType?.id} sx={{ pl: 1 }}>
              <Chip label={clioLawType?.value} sx={{ mb: 0.5, cursor: 'pointer' }} />
            </Stack>
          ))}
        </Stack>
      </Stack>
    );
  }
);
