import { CaseLawType } from 'features/auth';
import { backendApiClient } from '../../common';

export type GetCaseLawTypeSuggestionsResponse = {
  'hydra:member': ReadonlyArray<
    CaseLawTypeResponse & {
      createdBy: {
        '@context': string;
        '@id': string;
        '@type': string;
        email: string;
        phone: string;
      };
    }
  >;
  'hydra:totalItems': number;
};
export type CreateCaseLawTypeSuggestResponse = {
  '@context': string;
  '@id': string;
  '@type': string;
  title: string;
};
export type PatchCaseLawTypeSuggestionsPayload = { pending: boolean };

export type CreateCaseLawTypePayload = {
  title: string;
};
export type PatchCaseLawTypePayload = Partial<CreateCaseLawTypePayload>;
export type Pagination = {
  page?: number;
  itemsPerPage?: number;
};
export type CreateCaseLawTypeResponse = {
  '@context': string;
  '@id': string;
  '@type': string;
  title: string;
  createdAt: string;
  updatedAt: string;
};

export type CaseLawTypeResponse = CaseLawType & {
  updatedAt?: string | null;
};

export type GetCaseLawTypeResponse = {
  'hydra:member': CaseLawTypeResponse[];
  'hydra:totalItems': number;
};

export const createCaseLawTypesService = () => {
  const getCaseLawTypes = async (params: Pagination) => {
    return await backendApiClient({}).get<GetCaseLawTypeResponse>('/case_law_types', { params });
  };

  const createCaseLawType = async (createContentItemPayload: CreateCaseLawTypePayload) => {
    return await backendApiClient({}).post<CreateCaseLawTypeResponse>('/case_law_types', createContentItemPayload);
  };
  const createCaseLawTypeSuggest = async (createContentItemPayload: CreateCaseLawTypePayload) => {
    return await backendApiClient({}).post<CreateCaseLawTypeResponse, CreateCaseLawTypePayload>(
      '/case_law_types/suggest',
      createContentItemPayload
    );
  };
  const getCaseLawTypeSuggestions = async (params: Pagination) => {
    return await backendApiClient({}).get<GetCaseLawTypeSuggestionsResponse>(`/case_law_types/suggestions`, { params });
  };

  const patchCaseLawTypeSuggestions = async (id: string, patchPagePayload: PatchCaseLawTypeSuggestionsPayload) => {
    return await backendApiClient({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch<CreateCaseLawTypeResponse>(`/case_law_types/suggestions/${id}`, patchPagePayload);
  };

  const getCaseLawTypeById = async (id: string) => {
    return await backendApiClient({}).get<CreateCaseLawTypeResponse>(`/case_law_types/${id}`);
  };

  const patchCaseLawTypeById = async (id: string, patchPagePayload: PatchCaseLawTypePayload) => {
    return await backendApiClient({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch<CreateCaseLawTypeResponse>(`/case_law_types/${id}`, patchPagePayload);
  };

  const deleteCaseLawTypeById = async (id: string) => {
    return await backendApiClient({}).delete(`/case_law_types/${id}`);
  };

  return {
    getCaseLawTypes,
    createCaseLawType,
    getCaseLawTypeById,
    patchCaseLawTypeById,
    deleteCaseLawTypeById,
    getCaseLawTypeSuggestions,
    patchCaseLawTypeSuggestions,
    createCaseLawTypeSuggest,
  };
};
