import { FC, useEffect, useState } from 'react';
import { Autocomplete, Card, CardContent, CardHeader, CircularProgress, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BaseFormField,
  CountiesPaginationParams,
  CountryState,
  County,
  ToastType,
  createAttorneyService,
  createGeoDataService,
  notice,
  useUser,
} from 'features/common';
import { useCaseLawTypes } from 'features/common/hooks/useCaseLawTypes';
import { CREATE_CASE_FORM_STEPS, useCreateCase } from 'features/store';
import MDBox from 'components/MDBox';
import { CaseInformationSchema, caseInformationFormSchema } from './form.config';
import { ButtonsFooter } from './ButtonsFooter';
import { CaseLawTypeResponse } from 'features/case-law-types';

type CaseInformationProps = {
  isAttorneyView?: boolean;
};

export const CaseInformation: FC<CaseInformationProps> = ({ isAttorneyView }) => {
  const {
    saveFormDataHandler,
    activeStep,
    caseInformationData: {
      caseCounty,
      caseLawType,
      caseState,
      description,
      caseNumber,
      caseCountyTitle: countyTitle,
      caseLawTypeTitle: lawTypeTitle,
      caseStateTitle: stateTitle,
    },
  } = useCreateCase(state => ({
    caseInformationData: state.caseData,
    activeStep: state.activeStep,
    saveFormDataHandler: state.setData,
  }));

  const { getAttorneyProfileById } = createAttorneyService();

  const { backendUser } = useUser();

  const [currentAttorneyCaseLawTypes, setCurrentAttorneyCaseLawTypes] = useState<CaseLawTypeResponse[]>([]);
  const [isCurrentAttorneyCaseLawTypesLoading, setIsCurrentAttorneyCaseLawTypesLoading] = useState(
    isAttorneyView ? true : false
  );

  const onGetAttorneyCurrentCaseLawTypes = async () => {
    if (!backendUser?.attorneyProfile) return;
    const { caseLawTypes } = await getAttorneyProfileById(
      // TODO conference hack
      backendUser?.attorneyProfile?.['@id']?.replace('/attorney_profiles/', '')
    );
    setIsCurrentAttorneyCaseLawTypesLoading(false);
    setCurrentAttorneyCaseLawTypes(caseLawTypes);
  };

  useEffect(() => {
    isAttorneyView && onGetAttorneyCurrentCaseLawTypes();
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isValid, isDirty },
  } = useForm<CaseInformationSchema>({
    resolver: yupResolver(caseInformationFormSchema),
    mode: 'onTouched',
    defaultValues: {
      caseCounty,
      caseLawType,
      caseState,
      description,
    },
  });

  const onFormSubmitHandler = handleSubmit(formData =>
    saveFormDataHandler({
      step: activeStep,
      data: { ...formData, caseCountyTitle, caseStateTitle, caseLawTypeTitle, caseNumber: caseNumberValue },
      activeStep: CREATE_CASE_FORM_STEPS.DEPENDANT_INFO,
    })
  );

  const { caseLawTypesList, isCaseLawTypesLoading } = useCaseLawTypes(!isAttorneyView);

  const [caseStateList, setCaseStateList] = useState<CountryState[]>([]);
  const [caseCountyList, setCaseCountyList] = useState<Omit<County, 'name'>[]>([]);

  const [isCountyListLoading, setIsCountyListLoading] = useState(false);
  const [isStateListLoading, setIsStateListLoading] = useState(false);

  const [caseCountyTitle, setCaseCountyTitle] = useState(countyTitle);
  const [caseStateTitle, setCaseStateTitle] = useState(stateTitle);
  const [caseLawTypeTitle, setCaseLawTypeTitle] = useState(lawTypeTitle);
  const [caseNumberValue, setCaseNumberValue] = useState(caseNumber);

  const { getCounties, getStates } = createGeoDataService();

  const getCaseStateListHandler = async () => {
    try {
      setIsStateListLoading(true);
      const { data } = await getStates({});
      setCaseStateList(data['hydra:member']);
      setIsStateListLoading(false);
    } catch (error) {
      setIsStateListLoading(false);
      notice(ToastType.ERROR, 'Failed to fetch states, please try again!');
      console.error(error);
    }
  };

  const getCountyListHandler = async (params: CountiesPaginationParams) => {
    try {
      setIsCountyListLoading(true);
      const { data } = await getCounties({ itemsPerPage: 200, ...params });
      setCaseCountyList(data['hydra:member']);
      setIsCountyListLoading(false);
    } catch (error) {
      setIsCountyListLoading(false);
      notice(ToastType.ERROR, 'Failed to fetch counties, please try again!');
      console.error(error);
    }
  };

  useEffect(() => {
    getCaseStateListHandler();
  }, []);

  useEffect(() => {
    const isFetchCounty = caseState && !caseCountyList.length && caseStateList.length;

    if (isFetchCounty) {
      const stateValue = getStateValue(caseState);
      getCountyListHandler({ 'state.isoCode': stateValue?.isoCode });
    }
  }, [caseState, caseStateList]);

  const currentCaseState = watch('caseState');

  const onClickBackButtonHandler = () => {
    saveFormDataHandler({
      ...(isDirty && {
        step: activeStep,
        data: { ...getValues(), caseCountyTitle, caseLawTypeTitle, caseStateTitle, caseNumber: caseNumberValue },
      }),
      activeStep: CREATE_CASE_FORM_STEPS.CLIENT_INFO,
    });
  };

  const getStateValue = (stateId: string) => {
    return stateId
      ? caseStateList.find(option => {
          return stateId === option['@id'];
        }) ?? null
      : null;
  };

  const onChangeCaseNumberHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setCaseNumberValue(event.currentTarget.value);

  return (
    <form onSubmit={onFormSubmitHandler}>
      <Card>
        <CardHeader title="Case Information" />
        <CardContent>
          <Stack direction={{ xs: 'column', sm: 'row' }} gap={{ xs: 0, sm: 3 }} width={1}>
            <Stack width={1}>
              {isAttorneyView && (
                <BaseFormField
                  formInputProps={{
                    label: 'Case number (optional)',
                    placeholder: 'Enter a case number',
                    InputLabelProps: { shrink: true },
                    fullWidth: true,
                    value: caseNumberValue,
                    onChange: onChangeCaseNumberHandler,
                  }}
                />
              )}
              <Controller
                control={control}
                name="caseState"
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return (
                    <Autocomplete
                      value={getStateValue(value)}
                      onChange={(_, value) => {
                        onChange(value ? value['@id'] : '');
                        error && clearErrors('caseState');
                        setValue('caseCounty', '');
                        setCaseStateTitle(value?.name || '');
                        value && getCountyListHandler({ 'state.isoCode': value?.isoCode });
                      }}
                      getOptionLabel={option => option?.name}
                      options={caseStateList}
                      freeSolo={false}
                      isOptionEqualToValue={(option, value) => option?.['@id'] === value?.['@id']}
                      disableClearable={false}
                      renderInput={params => (
                        <BaseFormField
                          formInputProps={{
                            ...params,
                            label: 'Case state',
                            placeholder: 'Select a case state',
                            InputLabelProps: { shrink: true },
                            fullWidth: true,
                            InputProps: {
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {isStateListLoading ? (
                                    <MDBox pr={0} display="flex">
                                      <CircularProgress color="inherit" size={20} />
                                    </MDBox>
                                  ) : null}
                                  {errors['caseState']?.message ? null : params.InputProps.endAdornment}
                                </>
                              ),
                            },
                          }}
                          errorValue={errors['caseState']?.message}
                        />
                      )}
                    />
                  );
                }}
              />

              <Controller
                control={control}
                name="caseCounty"
                render={({ field: { onChange, value } }) => {
                  return (
                    <Autocomplete
                      disabled={!currentCaseState}
                      value={
                        value
                          ? caseCountyList.find(option => {
                              return value === option['@id'];
                            }) ?? null
                          : null
                      }
                      options={caseCountyList}
                      getOptionLabel={option => option.shortName}
                      onChange={(_, value) => {
                        onChange(value ? value['@id'] : '');
                        setCaseCountyTitle(value?.shortName || '');
                      }}
                      freeSolo={false}
                      isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                      loading={isCountyListLoading}
                      disableClearable={false}
                      renderInput={params => (
                        <BaseFormField
                          formInputProps={{
                            ...params,
                            label: 'Case county',
                            placeholder: !currentCaseState ? 'Select a case state first' : 'Select a case county',
                            InputLabelProps: { shrink: true },
                            fullWidth: true,
                            InputProps: {
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {isCountyListLoading ? (
                                    <MDBox pr={0} display="flex">
                                      <CircularProgress color="inherit" size={20} />
                                    </MDBox>
                                  ) : null}
                                  {errors['caseCounty']?.message ? null : params.InputProps.endAdornment}
                                </>
                              ),
                            },
                          }}
                          errorValue={errors['caseCounty']?.message}
                        />
                      )}
                    />
                  );
                }}
              />
            </Stack>
            <Stack width={1}>
              <Controller
                control={control}
                name="caseLawType"
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return (
                    <Autocomplete
                      value={
                        value
                          ? (isAttorneyView ? currentAttorneyCaseLawTypes : caseLawTypesList).find(option => {
                              return value === option['@id'];
                            }) ?? null
                          : null
                      }
                      getOptionLabel={option => option.title}
                      onChange={(_, value) => {
                        onChange(value ? value['@id'] : '');
                        error && clearErrors('caseLawType');
                        setCaseLawTypeTitle(value?.title || '');
                      }}
                      options={isAttorneyView ? currentAttorneyCaseLawTypes : caseLawTypesList}
                      freeSolo={false}
                      isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                      disableClearable={false}
                      renderInput={params => (
                        <BaseFormField
                          formInputProps={{
                            ...params,
                            label: 'Law type',
                            placeholder: 'Select a case law type',
                            InputLabelProps: { shrink: true },
                            fullWidth: true,
                            InputProps: {
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {isCurrentAttorneyCaseLawTypesLoading || isCaseLawTypesLoading ? (
                                    <MDBox pr={0} display="flex">
                                      <CircularProgress color="inherit" size={20} />
                                    </MDBox>
                                  ) : null}
                                  {errors['caseLawType']?.message ? null : params.InputProps.endAdornment}
                                </>
                              ),
                            },
                          }}
                          errorValue={errors['caseLawType']?.message}
                        />
                      )}
                    />
                  );
                }}
              />

              <BaseFormField
                formInputProps={{
                  ...register('description'),
                  label: 'Description',
                  placeholder: 'Enter a case description',
                  fullWidth: true,
                  InputLabelProps: { shrink: true },
                  multiline: true,
                  rows: 4,
                }}
                errorValue={errors['description']?.message}
              />
            </Stack>
          </Stack>
          <ButtonsFooter isSubmitButtonDisabled={!isValid} onClickBackButtonHandler={onClickBackButtonHandler} />
        </CardContent>
      </Card>
    </form>
  );
};
