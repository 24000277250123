import { useState } from 'react';

export const usePopover = () => {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => setOpenPopover(event.currentTarget);

  const handleClosePopover = () => setOpenPopover(null);

  return {
    openPopover,
    handleClosePopover,
    handleOpenPopover,
  };
};
