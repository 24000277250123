import * as yup from 'yup';
import { ROUTES } from 'features/common/variables/routes';
import { UserPermissions } from 'features/company-list';

const routes = [
  { icon: 'business outlined', label: 'company information', href: ROUTES.firmProfile },
  { icon: 'group_outlined', label: 'company users', href: ROUTES.myCompanyUsers },
  { icon: 'query_builder', label: 'company working hours', href: ROUTES.myCompanyWorkingHours },
  { icon: 'gavel', label: 'company law types', href: ROUTES.myCompanyCaseLawTypes },
  { icon: 'manage_accounts', label: 'permissions', href: ROUTES.myCompanyPermissions },
  { icon: 'subscriptions', label: 'subscriptions', href: ROUTES.myCompanySubscriptions },
];
export const sideNavMenuRoutes = (isCompanyOwner: boolean) => (isCompanyOwner ? routes : routes.slice(0, 4));

export type CompanyPermissionsSchema = Partial<UserPermissions>;

export const companyPermissionsValidationFormSchema: yup.ObjectSchema<CompanyPermissionsSchema> = yup
  .object({
    adminCanAddAttorney: yup.boolean().notRequired(),
    adminCanEditCompanyInfo: yup.boolean().notRequired(),
    adminCanDeleteAttorney: yup.boolean().notRequired(),
    adminCanPromoteAdmin: yup.boolean().notRequired(),
  })
  .required();

export type FormFieldName = keyof CompanyPermissionsSchema;

export const COMPANY_USER_PERMISSIONS_FIELDS: {
  switchTitle: string;
  watchSwitcherValue: FormFieldName;
}[] = [
  {
    switchTitle: 'Admin can add attorney',
    watchSwitcherValue: 'adminCanAddAttorney',
  },
  {
    switchTitle: 'Admin can delete attorney',
    watchSwitcherValue: 'adminCanDeleteAttorney',
  },
  {
    switchTitle: 'Admin can edit company info',
    watchSwitcherValue: 'adminCanEditCompanyInfo',
  },
  {
    switchTitle: 'Admin can promote admin',
    watchSwitcherValue: 'adminCanPromoteAdmin',
  },
];
