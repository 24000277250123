export const CreateEventModalContentStyles = {
  AutocompleteSX: {
    mt: '16px !important',
    '.MuiInputBase-root': {
      py: '4.5px',
    },
  },
  TimePickerSX: {
    mb: 1,
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-17px',
      left: '5px',
      m: 0,
      fontSize: 10,
    },
  },
};
