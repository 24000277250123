import { FC } from 'react';
import { Card, Divider, Grid, Stack } from '@mui/material';
import { DashboardLayout } from 'features/common';
import { ATTORNEY_SUBSCRIPTIONS_TYPES } from 'features/subscriptions/config';
import { SubscriptionItem } from 'features/subscriptions/components/SubscriptionItem';

export const AttorneyProSubscriptionTemplate: FC = () => {
  return (
    <DashboardLayout>
      <Grid container sx={{ px: 0, my: 0, flex: 1 }}>
        <Grid item xs={12}>
          <Card sx={{ py: 8, px: 3, height: 1 }}>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              spacing={{ xs: 2.5, lg: 2 }}
              divider={<Divider sx={{ my: '12px !important', display: { xs: 'block', lg: 'none' } }} />}
            >
              {ATTORNEY_SUBSCRIPTIONS_TYPES.map(el => (
                <SubscriptionItem key={el} paymentPeriod={el} />
              ))}
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};
