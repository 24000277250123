import { useEffect } from 'react';
import { STEPS, useCreateCase } from 'features/store';

export const useCreateMyLegalCaseState = () => {
  const { CRMCaseId, activeStep, resetStateHandler } = useCreateCase(state => ({
    activeStep: state.activeStep,
    resetStateHandler: state.resetState,
    CRMCaseId: state.CRMCaseData?.id,
  }));

  useEffect(() => {
    return () => {
      resetStateHandler();
    };
  }, []);

  const steps = CRMCaseId ? STEPS?.slice(0, 3) : STEPS;
  const currentStepIndex = steps.findIndex(step => step === activeStep);

  return { currentStepIndex, activeStep };
};
