import { Pagination } from 'features/case-law-types';
import { ListOfItems, PATCH_HEADERS, backendApiClient } from 'features/common';
import { BASE_LEGAL_CASES_API_URL } from 'features/my-legal-cases/MyLegalCasesApiService';

const BASE_LEGAL_CASE_DELAYED_NOTIFICATION_API_URL: Readonly<string> = '/legal_case_notifications';

export type DelayedNotificationType = 'every_day' | 'every_week' | 'every_two_week' | 'every_month';

export type CreateDelayedNotificationPayload = {
  legalCase: string;
  message: string;
  type: DelayedNotificationType;
  startedAt: string;
};

export type PatchDelayedNotificationPayload = Pick<CreateDelayedNotificationPayload, 'message' | 'type' | 'startedAt'>;

export type DelayedNotification = {
  '@context': string;
  '@id': string;
  '@type': string;
  id: string;
  legalCase: string;
  message: string;
  type: DelayedNotificationType;
  createdAt: string;
  nextSendAt: string;
  updatedAt: string;
  startedAt: string;
};

export const delayedNotificationsApiService = () => {
  const createDelayedNotification = async (payload: CreateDelayedNotificationPayload) => {
    return await backendApiClient({}).post<DelayedNotification>(
      `${BASE_LEGAL_CASE_DELAYED_NOTIFICATION_API_URL}`,
      payload
    );
  };

  const deleteDelayedNotification = async (id: string) => {
    return await backendApiClient({}).delete(`${BASE_LEGAL_CASE_DELAYED_NOTIFICATION_API_URL}/${id}`);
  };

  const patchDelayedNotification = async (id: string, patchPayload: PatchDelayedNotificationPayload) => {
    return await backendApiClient({
      headers: PATCH_HEADERS,
    }).patch<DelayedNotification>(`${BASE_LEGAL_CASE_DELAYED_NOTIFICATION_API_URL}/${id}`, patchPayload);
  };

  const getDelayedNotifications = async (caseId: string, params?: Pagination) => {
    return await backendApiClient({}).get<ListOfItems<DelayedNotification[]>>(
      `${BASE_LEGAL_CASES_API_URL}/${caseId}/notification`,
      { params }
    );
  };

  return {
    createDelayedNotification,
    deleteDelayedNotification,
    patchDelayedNotification,
    getDelayedNotifications,
  };
};
