import { FC } from 'react';
import { TemplateWrapper } from './TemplateWrapper';
import { AttorneyStatesLawTypes } from '../components';

export const AttorneyStatesLawTypesTemplate: FC = () => {
  return (
    <TemplateWrapper>
      <AttorneyStatesLawTypes />
    </TemplateWrapper>
  );
};
