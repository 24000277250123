import { FC, useEffect, useRef, useState } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import { useIsOnScreen } from 'features/common/hooks/useIsOnScreen';
import { AllConsultationsOffersMenuContentProps } from 'features/case-details/types';
import { FetchMoreLoader } from 'features/common/components/Loader';
import { ConsultationOfferCard } from './ConsultationOfferCard';
import { useUserProfile } from 'features/common/hooks/useUserProfile';
import { UserOverviewContent } from './UserOverviewContent';
import { MenuPopover } from 'features/common/components/MenuPopover';
import { SideMenu } from 'features/common';
import { CompanyInfoSideMenuContent } from 'features/my-company/components/CompanyInfoSideMenuContent';

const ITEMS_PER_PAGE = 30;

export const AllConsultationsOffersMenuContent: FC<AllConsultationsOffersMenuContentProps> = ({
  isLoading,
  consultationsOffers,
  totalItems,
  approveLegalCaseOfferHandler,
  declineLegalCaseOfferHandler,
  fetchMoreOffersHandler,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pages: Readonly<number> = Math.ceil(totalItems / ITEMS_PER_PAGE);

  const [isFetchMoreLoading, setIsFetchMoreLoading] = useState(false);

  const lastElementRef = useRef<HTMLDivElement | null>(null);
  const isOnScreen = useIsOnScreen(isLoading ? null : lastElementRef);

  const onFetchMoreCaseOffersHandler = async () => {
    setIsFetchMoreLoading(true);

    const nextPage = currentPage + 1;
    await fetchMoreOffersHandler({ itemsPerPage: ITEMS_PER_PAGE, page: nextPage });
    setCurrentPage(nextPage);

    setIsFetchMoreLoading(false);
  };

  useEffect(() => {
    if (isOnScreen && currentPage < pages) {
      onFetchMoreCaseOffersHandler();
    }
  }, [isOnScreen]);

  const {
    isUserOverviewMenuOpen,
    isUserProfileOverviewLoading,
    onCloseUserOverviewHandler,
    onUserAvatarClickHandler,
    userRatings,
    userProfileOverview,
    isOpenCompanyInfoMenu,
    onClickViewCompanyProfileButtonHandler,
    onCloseCompanyInfoMenuHandler,
  } = useUserProfile();

  return (
    <Stack
      spacing={2}
      sx={{
        mt: 3,
        pb: 1.5,
        height: 1,
      }}
    >
      <Stack position="relative" height={1} spacing={2}>
        {isLoading && (
          <Stack flex={1} justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        )}
        {!isLoading &&
          consultationsOffers?.map(offer => (
            <ConsultationOfferCard
              key={offer.id}
              lastElementRef={lastElementRef}
              offerId={offer.id}
              firstName={offer?.owner?.userProfile?.firstName}
              lastName={offer?.owner?.userProfile?.lastName}
              createdAt={offer?.createdAt}
              logoURL={offer?.owner?.logo?.publicUrl}
              approveLegalCaseOfferHandler={approveLegalCaseOfferHandler}
              declineLegalCaseOfferHandler={declineLegalCaseOfferHandler}
              isAllOffers
              consultationOwnerFirebaseId={offer?.owner?.firebaseUser}
              onUserAvatarClickHandler={onUserAvatarClickHandler}
            />
          ))}

        {isFetchMoreLoading && <FetchMoreLoader />}
      </Stack>
      <MenuPopover open={isUserOverviewMenuOpen} onClose={onCloseUserOverviewHandler} arrow="top-left">
        <UserOverviewContent
          attorneyDescription={userProfileOverview?.attorneyProfile?.description}
          lawTypes={userProfileOverview?.attorneyProfile?.caseLawTypes}
          country={userProfileOverview?.userProfile?.country?.name}
          state={userProfileOverview?.userProfile?.state?.name}
          isLoading={isUserProfileOverviewLoading}
          userRatings={userRatings}
          workingHours={userProfileOverview?.attorneyProfile?.workingHours}
          companyInfo={{
            companyFullName: userProfileOverview?.company?.fullName,
            companyLogo: userProfileOverview?.company?.logo?.publicUrl,
          }}
          onClickViewCompanyProfileButtonHandler={onClickViewCompanyProfileButtonHandler}
        />
      </MenuPopover>

      <SideMenu
        customWidth={450}
        isOpen={isOpenCompanyInfoMenu}
        onClose={onCloseCompanyInfoMenuHandler}
        title="Company profile"
      >
        <CompanyInfoSideMenuContent companyId={userProfileOverview?.company?.['@id']} />
      </SideMenu>
    </Stack>
  );
};
