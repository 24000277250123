import { FC, useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Grid, Stack } from '@mui/material';
import { SideMenu, useBoolean } from 'features/common';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { Loader } from 'features/common/components/Loader';
import { GetContentItemCatagoryBySlugResponse } from 'features/content-items';
import { RegisterNewCompanyForm } from '../components/RegisterCompanyForm';
import { CompanyInvitations } from './components/CompanyInvitations';

export const RegisterNewCompanyTemplate: FC<{
  getCreateCompanyDescription: () => Promise<void>;
  registerCompanyDescription: GetContentItemCatagoryBySlugResponse;
}> = ({ getCreateCompanyDescription, registerCompanyDescription }) => {
  const [isRegisterSidebarOpen, openRegisterSideBar, closeRegisterSideBar] = useBoolean(false);

  const [isLoading, setIsLoading] = useState(true);

  const onGetDescriptionHandler = async () => {
    await getCreateCompanyDescription();
    setIsLoading(false);
  };
  useEffect(() => {
    onGetDescriptionHandler();
  }, []);

  return (
    <Grid item xs={12}>
      <MDBox mt={4}>
        <Grid container spacing={2} columns={{ xs: 12, md: 12, lg: 12 }}>
          <CompanyInvitations />

          <Grid item xs={12}>
            <Card sx={{ minHeight: 200 }}>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <CardHeader title="Register a new company" sx={{ textAlign: 'center' }} />
                  <CardContent>
                    <MDBox
                      component="p"
                      sx={{
                        '*': {
                          all: 'revert',
                        },
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          registerCompanyDescription?.htmlContent || 'Use register button below to create new company',
                      }}
                    />
                    <Stack alignItems="center" mt={3}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ width: { xs: 1, sm: 'auto' } }}
                        onClick={openRegisterSideBar}
                      >
                        Register
                      </MDButton>
                    </Stack>
                  </CardContent>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <SideMenu isOpen={isRegisterSidebarOpen} onClose={closeRegisterSideBar} title="Register a new company">
        <RegisterNewCompanyForm onCloseSideBarHandler={closeRegisterSideBar} />
      </SideMenu>
    </Grid>
  );
};
