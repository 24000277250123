import { ButtonColor } from 'components/MDButton';
import { CardBadgeColor, CardColor } from './templates/AttorneyProSubscriptionTemplate/PricingCard';
import { PAYMENT_PERIOD } from './services/subscriptionsApiService';

export const ATTORNEY_SUBSCRIPTIONS_TYPES: PAYMENT_PERIOD[] = [
  PAYMENT_PERIOD.MONTHLY,
  PAYMENT_PERIOD.YEARLY,
  PAYMENT_PERIOD.TWO_YEARS,
];

type SubscriptionItem = {
  badge: {
    color: CardBadgeColor;
    label: string;
  };
  price: {
    value: string;
    type: string;
    priceAnnotation?: string;
  };
  specifications: {
    label: string;
    includes?: boolean;
  }[];
  action: { color: ButtonColor; label: string };
  cardColor?: CardColor;
};

export const SUBSCRIPTION_CARD_DATA_MAP: Record<PAYMENT_PERIOD, SubscriptionItem> = {
  monthly: {
    badge: { color: 'info', label: 'Attorney Subscription Monthly' },
    price: { value: '399.99', type: 'mo' },
    specifications: [{ label: 'Unlimited access to the application', includes: true }],
    action: { color: 'info', label: 'Buy' },
    cardColor: 'white',
  },
  yearly: {
    badge: { color: 'info', label: 'Attorney Subscription Annual' },
    price: { value: '349.00', type: 'mo', priceAnnotation: '$4 188.00 per 1-year' },
    specifications: [{ label: 'Unlimited access to the application', includes: true }],
    action: { color: 'info', label: 'Buy' },
    cardColor: 'white',
  },
  twoYears: {
    badge: { color: 'info', label: 'Attorney Subscription 2-Year' },
    price: { value: '299.00', type: 'mo', priceAnnotation: '$7 176.00 per 2-years' },
    specifications: [{ label: 'Unlimited access to the application', includes: true }],
    action: { color: 'info', label: 'Buy' },
  },
};
