import { FC, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  AutocompleteChangeReason,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
} from '@mui/material';
import { useCaseLawTypes } from 'features/common/hooks/useCaseLawTypes';
import MDBox from 'components/MDBox';
import { BaseFormField, useUser } from 'features/common';
import { CaseLawTypeResponse } from 'features/case-law-types';
import MDButton from 'components/MDButton';
import { useMyCompany } from 'features/store';

export const CompanyCaseLawTypesTemplate: FC = () => {
  const { myCompany, updateCompanyCaseLawTypesHandler } = useMyCompany();

  const { caseLawTypesList, isCaseLawTypesLoading } = useCaseLawTypes();

  const [currentCaseLawTypes, setCurrentCaseLawTypes] = useState<CaseLawTypeResponse[]>(myCompany?.caseLawTypes);

  const [isOptions, setIsOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isDefaultArraySameAsCurrent = useMemo(() => {
    if (currentCaseLawTypes.length !== myCompany.caseLawTypes.length || !myCompany?.caseLawTypes) return false;

    const sortedCurrent = [...currentCaseLawTypes].sort((a, b) => (a.title > b.title ? 1 : -1));
    const sortedDefault = [...myCompany.caseLawTypes].sort((a, b) => (a.title > b.title ? 1 : -1));

    for (let i = 0; i < sortedCurrent.length; i++) {
      if (sortedCurrent[i].title !== sortedDefault[i].title) {
        return false;
      }
    }

    return true;
  }, [currentCaseLawTypes, myCompany?.caseLawTypes]);

  const onSubmitHandler = async () => {
    const formattedLawTypes = currentCaseLawTypes.map(lawType => lawType['@id']);

    if (!isDefaultArraySameAsCurrent) {
      setIsLoading(true);
      await updateCompanyCaseLawTypesHandler({
        caseLawTypes: formattedLawTypes,
      });
      setIsLoading(false);
    }
  };

  const onAutocompleteChangeHandler = async (
    _: React.SyntheticEvent<Element, Event>,
    values: (string | CaseLawTypeResponse)[],
    reason: AutocompleteChangeReason
  ) => {
    isOptions && setIsOptions(true);
    const caseLawTypeResponses = values.filter(item => typeof item !== 'string') as CaseLawTypeResponse[];
    setCurrentCaseLawTypes(caseLawTypeResponses);
  };

  const onInputChangeHandler = (_: React.SyntheticEvent<Element, Event>, value: string) => {
    const filteredOptions = caseLawTypesList.filter(el => el.title.toLowerCase().includes(value.trim().toLowerCase()));
    setIsOptions(!!filteredOptions.length);
  };

  useEffect(() => {
    setIsOptions(!!caseLawTypesList.length);
  }, [caseLawTypesList]);

  const { backendUser } = useUser();

  const isDisabled =
    backendUser?.companyUser?.[0]?.role === 'ROLE_COMPANY_ATTORNEY' ||
    (backendUser?.companyUser?.[0]?.role === 'ROLE_COMPANY_ADMIN' &&
      !myCompany?.userPermissions?.adminCanEditCompanyInfo);

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader title="Company case law types" />
        <CardContent
          sx={{
            '.MuiAutocomplete-option[aria-selected="true"]': {
              mb: '6px !important',
            },
            '&.MuiAutocomplete-popperDisablePortal, .MuiAutocomplete-popper': {
              display: !isOptions ? 'none !important' : 'block !important',
            },
          }}
        >
          <Autocomplete
            disabled={isDisabled}
            disablePortal
            value={currentCaseLawTypes}
            getOptionLabel={option => (typeof option === 'string' ? '' : option?.title)}
            options={caseLawTypesList}
            isOptionEqualToValue={(option, value) => option?.['@id'] === value?.['@id']}
            onChange={onAutocompleteChangeHandler}
            onInputChange={onInputChangeHandler}
            multiple
            freeSolo
            renderInput={params => (
              <BaseFormField
                formInputProps={{
                  ...params,
                  label: 'Case law type',
                  fullWidth: true,
                  InputProps: {
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isCaseLawTypesLoading ? (
                          <MDBox pr={0} display="flex">
                            <CircularProgress color="inherit" size={20} />
                          </MDBox>
                        ) : null}
                      </>
                    ),
                  },
                }}
              />
            )}
          />

          <MDBox mt={1} mb={1} sx={{ width: 1 }}>
            <MDButton
              variant="gradient"
              color="info"
              type="submit"
              sx={{ textTransform: 'none', width: { xs: 1, sm: 'auto' } }}
              disabled={isLoading || isDefaultArraySameAsCurrent}
              onClick={onSubmitHandler}
              isLoading={isLoading}
            >
              Save
            </MDButton>
          </MDBox>
        </CardContent>
      </Card>
    </Grid>
  );
};
