import { FC, memo } from 'react';
import { Card, Stack } from '@mui/material';
import MDAvatar from 'components/MDAvatar';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useUserProfile } from 'features/common/hooks/useUserProfile';
import { MenuPopover } from 'features/common/components/MenuPopover';
import { UserOverviewContent } from 'features/case-details/components/CaseInfo/UserOverviewContent';
import { ClientLegalCase } from '../MyClientsApiService';
import { ClientCaseCard } from './ClientCaseCard';
import { SideMenu } from 'features/common';
import { CompanyInfoSideMenuContent } from 'features/my-company/components/CompanyInfoSideMenuContent';

type ClientCardProps = {
  clientFirstName: string;
  clientLastName: string;
  legalCases: ClientLegalCase[];
  clientLogoURL?: string;
  clientState: string;
  clientCountry: string;
  legalCaseCount: number;
  rating: number;
  lastElementRef: React.MutableRefObject<HTMLDivElement> | null;
  clientFirebaseId: string;
};

export const ClientCard: FC<ClientCardProps> = memo(
  ({
    clientFirstName,
    clientLogoURL,
    clientLastName,
    legalCases,
    legalCaseCount,
    rating,
    lastElementRef,
    clientCountry,
    clientState,
    clientFirebaseId,
  }) => {
    const {
      isUserOverviewMenuOpen,
      isUserProfileOverviewLoading,
      onCloseUserOverviewHandler,
      onUserAvatarClickHandler,
      userProfileOverview,
      isOpenCompanyInfoMenu,
      onClickViewCompanyProfileButtonHandler,
      onCloseCompanyInfoMenuHandler,
    } = useUserProfile();

    return (
      <Card sx={{ height: 1 }} ref={lastElementRef}>
        <MDBox
          sx={{
            width: 1,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 1,
          }}
        >
          <Stack direction="row">
            <MDBox display="flex" alignItems="center" flex={1}>
              <MDAvatar
                src={clientLogoURL || ''}
                alt="user avatar"
                size="lg"
                variant="circular"
                id={clientFirebaseId}
                onClick={!clientLastName && !clientFirstName ? undefined : onUserAvatarClickHandler}
                sx={{
                  mt: -3.5,
                  '& img': {
                    height: 1,
                  },
                  ':hover': {
                    cursor: 'pointer',
                  },
                  backgroundColor: theme => theme.palette.info.light,
                }}
              />
              <Stack ml={2} mt={-1.8} direction="column" alignItems="flex-start">
                <Stack direction="row">
                  <MDTypography variant="h6" textTransform="capitalize" fontWeight="medium" flex={1}>
                    {`${clientFirstName} ${clientLastName}`}
                  </MDTypography>
                </Stack>
                <MDTypography variant="button" sx={{ display: 'flex', flex: 1 }}>
                  {`${clientState}, ${clientCountry}`}
                </MDTypography>
              </Stack>
            </MDBox>
            <Stack
              spacing={1}
              direction="row"
              alignItems="baseline"
              sx={{ flexWrap: 'wrap', justifyContent: 'flex-end' }}
            >
              <Stack direction="row" gap={0.5}>
                <MDTypography variant="button" fontWeight="medium" sx={{ alignSelf: 'center', lineHeight: 1 }}>
                  {legalCaseCount || 0}
                </MDTypography>
                <MDTypography
                  variant="subtitle2"
                  sx={{ display: 'flex', alignItems: 'center', fontSize: 14, lineHeight: 1 }}
                >
                  {legalCaseCount > 1 ? 'Cases' : 'Case'}
                </MDTypography>
              </Stack>
              <Stack direction="row" gap={0.5}>
                <MDTypography variant="button" fontWeight="medium" sx={{ alignSelf: 'center', lineHeight: 1 }}>
                  {rating || 0}
                </MDTypography>
                <MDTypography
                  variant="subtitle2"
                  sx={{ display: 'flex', alignItems: 'center', fontSize: 14, lineHeight: 1 }}
                >
                  Rating
                </MDTypography>
              </Stack>
            </Stack>
          </Stack>

          <Stack
            sx={{
              maxHeight: 'auto',
              overflowY: 'auto',
              ...(legalCases.length > 2 && {
                maxHeight: 360,
                pr: 1,
              }),
            }}
          >
            {legalCases?.map(legalCase => {
              return (
                <ClientCaseCard
                  key={legalCase?.id}
                  id={legalCase?.id}
                  caseNumber={legalCase?.caseNumber}
                  createdAt={legalCase?.createdAt}
                  description={legalCase?.description}
                  countyShortName={legalCase?.county?.shortName}
                  stateName={legalCase?.state?.name}
                  countryId={legalCase?.country?.id}
                  isDocusignRequest={legalCase?.docuSignRequest}
                  caseLawTypeTitle={legalCase?.caseLawType?.title}
                  clientFirebaseId={clientFirebaseId}
                  updatedAt={legalCase?.updatedAt}
                />
              );
            })}
          </Stack>
        </MDBox>

        <MenuPopover open={isUserOverviewMenuOpen} onClose={onCloseUserOverviewHandler} arrow="top-left">
          <UserOverviewContent
            attorneyDescription=""
            lawTypes={null}
            country={userProfileOverview?.userProfile?.country?.name}
            state={userProfileOverview?.userProfile?.state?.name}
            isLoading={isUserProfileOverviewLoading}
            userRatings={null}
            companyInfo={{
              companyFullName: userProfileOverview?.company?.fullName,
              companyLogo: userProfileOverview?.company?.logo?.publicUrl,
            }}
            onClickViewCompanyProfileButtonHandler={onClickViewCompanyProfileButtonHandler}
          />
        </MenuPopover>

        <SideMenu
          customWidth={450}
          isOpen={isOpenCompanyInfoMenu}
          onClose={onCloseCompanyInfoMenuHandler}
          title="Company profile"
        >
          <CompanyInfoSideMenuContent companyId={userProfileOverview?.company?.['@id']} />
        </SideMenu>
      </Card>
    );
  }
);
