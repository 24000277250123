import { styled } from '@mui/material';
import MDBox from 'components/MDBox';

export const MessageWrapper = styled(MDBox, { shouldForwardProp: prop => prop !== 'isMine' })(({ theme, isMine }) => ({
  padding: 8,
  borderRadius: 8,
  backgroundColor: isMine ? theme.palette.info.main : theme.palette.grey[100],
  '& span': {
    color: isMine ? 'white !important' : theme.palette.grey[700],
  },
  alignSelf: isMine ? 'flex-end' : 'flex-start',
  marginBottom: 4,
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
  maxWidth: '50%',
  flexDirection: 'column',
}));

export const SignDocumentWrapper = styled(MDBox, {
  shouldForwardProp: prop => prop !== 'isMine' && prop !== 'isSigned' && prop !== 'isCancelDocSign',
})(({ isMine, isSigned, theme, isCancelDocSign }) => ({
  ':hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.grey[100],
  },
  borderRadius: '0 0 8px 8px',
  padding: '8px',
  border: `1px solid ${isMine ? theme.palette.info.main : theme.palette.grey[200]}`,
  ...(isCancelDocSign && {}),
  borderTop: 'none',
  ...(isSigned && {
    textAlign: 'center',
    ':hover': {
      cursor: 'default',
    },
    backgroundColor: theme.palette.grey[200],
  }),
}));
