import { backendApiClient } from '../api';
import { BaseData } from '../types';

const BASE_ADOBE_SIGN_API_URL = '/adobe_sign';

type AdobesignAuthURL = BaseData<'AdobeSignAuthDto'> & {
  authUrl: string;
};

type ConnectAdobeSignPayload = {
  code: string;
  state: string;
  redirectUrl: string;
};

export const adobesignApiService = () => {
  const generateAdobeSignAuthURL = async (redirectUrl: string) =>
    backendApiClient({}).post<AdobesignAuthURL>(`${BASE_ADOBE_SIGN_API_URL}/generate_auth_url`, {
      redirectUrl,
    });

  const connectAdobeSign = async (payload: ConnectAdobeSignPayload) => {
    return await backendApiClient({}).post(`${BASE_ADOBE_SIGN_API_URL}/access_code`, payload);
  };

  const disconnectAdobeSign = async () => await backendApiClient({}).post(`${BASE_ADOBE_SIGN_API_URL}/disconnect`, {});

  return { generateAdobeSignAuthURL, connectAdobeSign, disconnectAdobeSign };
};
