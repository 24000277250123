import { Stack } from '@mui/material';
import MDAvatar from 'components/MDAvatar';
import MDTypography from 'components/MDTypography';
import { FC } from 'react';

type CRMIntegrationCaseInfoProps = {
  logoSrc: string;
  title?: string;
  originalCaseNumber: string | null;
};

export const CRMIntegrationCaseInfo: FC<CRMIntegrationCaseInfoProps> = ({
  logoSrc,
  originalCaseNumber,
  title = 'Connected with Clio matter',
}) => {
  return (
    <Stack direction="row" spacing={1} alignItems="flex-start">
      <MDAvatar src={logoSrc} variant="rounded" alt="Clio logo" sx={{ width: 20, height: 20 }} />
      <Stack>
        <MDTypography variant="button" fontWeight="medium" sx={{ lineHeight: 1 }}>
          {title}
        </MDTypography>
        {originalCaseNumber && (
          <MDTypography variant="button" fontWeight="medium">
            {originalCaseNumber}
          </MDTypography>
        )}
      </Stack>
    </Stack>
  );
};
