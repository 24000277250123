import { FC } from 'react';
import { Stack } from '@mui/material';
import { DependantInfoItem } from './DependantInfoItem';

type DependantInfoProps = {
  dependantFirstName: string;
  dependantLastName: string;
  dependantStateOfResidence: string;
  dependantUnder18: boolean;
};
export const DependantInfo: FC<DependantInfoProps> = ({
  dependantFirstName,
  dependantLastName,
  dependantStateOfResidence,
  dependantUnder18,
}) => {
  return (
    <Stack>
      <DependantInfoItem title="First name" titleValue={dependantFirstName} />
      <DependantInfoItem title="Last name" titleValue={dependantLastName} />
      <DependantInfoItem title="State" titleValue={dependantStateOfResidence} />
      <DependantInfoItem title="Under 18" titleValue={dependantUnder18 ? 'Yes' : 'No'} />
    </Stack>
  );
};
