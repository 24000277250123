import { FC, memo } from 'react';
import {
  CommentsDisabledRounded,
  CommentRounded,
  KeyboardVoiceRounded,
  MicOffRounded,
  VideocamOffRounded,
  VideocamRounded,
  CallEndRounded,
  DescriptionRounded,
  ErrorOutlineOutlined,
} from '@mui/icons-material';
import { Dialog, Stack } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { SideMenu, useBoolean, useUser } from 'features/common';
import { FillingTemplateSideMenu } from 'features/common/components/FillingTemplateSideMenuContent/FillingTemplateSideMenu';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { SelectFileSignTemplateModal } from 'features/common/components/SelectFileSignTemplateModalWindow';
import { ComponentsStyles as Styled } from './Components.styles';
import { RequestToSignDocumentsOptionsModalWindow } from './RequestToSignDocumentsModalWindow';
import { useControls } from '../hooks/useControls';
import { DocumentsToSignContent } from './DocumentsToSignContent';
import { ControlsProps } from '../types';
import { useRepresentation } from '../hooks/useRepresentation';
import { AvailiableDocumentsToSendSignRequest } from './AvailiableDocumentsToSendSignRequest';
import { RepresentationControls } from './RepresentationControls';
import { NotConnectedFileSignProvidersWindow } from './NotConnectedFileSignProvidersWindow';
import { CreateCaseModalContent } from 'features/my-clients/components';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'features/common/variables/routes';
import MDTypography from 'components/MDTypography';

export const Controls: FC<ControlsProps> = memo(
  ({
    handleMuteAudio,
    handleMuteVideo,
    isMicDisabled,
    isVideoDisabled,
    onChatIconClickHandler,
    onLeaveChannel,
    eventType,
    isChatOpen,
    isReconnecting,
    eventId,
    isCaseOnConsultation,
    isAttorney,
    legalCaseId,
    isRemoteParticipantJoined,
    setRepresentationId,
    children,
    remoteParticipantFirebaseId,
    headerLink,
    updateEventdata,
    remoteParticipantData,
  }) => {
    const {
      isRejecting,
      isApprovingRepresentation,
      isSendingOffer,
      representation,
      legalCaseUID,
      awaitingFilsToSign,
      isSignDocumentsModalOpen,
      openSignDocumentsModal,
      closeSignDocumentsModal,
      closeDialogWindow,
      isDialogOpen,
      dialogWindowAcceptHandler,
      onClickApproveRepresentationHandler,
      onClickDeclineRepresentationHandler,
      onClickSendRepresentationHandler,
    } = useRepresentation({
      eventId,
      isCaseOnConsultation,
      legalCaseId,
      isAttorney,
      setRepresentationId,
      updateEventdata,
      headerLink,
    });

    // TODO Conference hack --open
    const { isCanSendRepresentationRequest } = useUser();
    const navigate = useNavigate();

    const isCannotSendRepresentationRequest =
      isRemoteParticipantJoined &&
      isAttorney &&
      isCaseOnConsultation &&
      representation?.status !== 'approve' &&
      !isCanSendRepresentationRequest;

    const [isBuySubscriptionModalOpen, openBuySubscriptionModal, closeBuySubscriptionModal] = useBoolean(false);

    const onClickBuySubscriptionHandler = async () => {
      await onLeaveChannel();
      closeBuySubscriptionModal();
      navigate(ROUTES.subscription);
    };

    // --close
    const isSendRepresentationRequestBox =
      isRemoteParticipantJoined &&
      isAttorney &&
      isCaseOnConsultation &&
      representation?.status !== 'approve' &&
      isCanSendRepresentationRequest;

    const isAcceptDeclineRepresentationBox =
      !isAttorney && isCaseOnConsultation && representation?.representation && representation?.status !== 'approve';

    const isUploadFileButtonDisabled = isCaseOnConsultation && representation?.status !== 'approve';

    const isViewFilesToSignBox = isAttorney
      ? !!awaitingFilsToSign?.awaitingSignByAttorney
      : !!awaitingFilsToSign?.awaitingSignByClient;

    const isOnlyCallTimeBox =
      !isSendRepresentationRequestBox && !isAcceptDeclineRepresentationBox && isRemoteParticipantJoined;

    const {
      isRequestToSignDocumentsModalOpen,
      isDocumentLoading,
      onInputFileUploadHandler,
      openRequestToSignDocumentsModalOpen,
      closeRequestToSignDocumentsModalOpen,
      closeSelectDocumentsFromCaseMenu,
      isSelectDocumentsFromCaseMenuOpen,
      onOpenSelectDocumentsFromTheCaseMenuHandler,
      closeSelectTemplateDocumentModal,
      isSelectTemplateDocumentModalOpen,
      onOpenSelectTemplateDocumentModalHandler,
      selectedTemplate,
      onClickSelectTemplateItemHandler,
      isFillTemplateMenuOpen,
      closeFillTemplateMenu,
      isNotConnectedFileSignServicesOpen,
      openNotConnectedFileSignServicesModal,
      closeNotConnectedFileSignServicesModal,
      fileSignProvider,
      onChangeRadioButtonHandler,
      isFileSignServicesActive,
      isSomeFileSignServiceActive,
    } = useControls({
      legalCaseUID: legalCaseId?.replace('/legal_cases/', ''),
      remoteParticipantFirebaseId,
    });

    return (
      <MDBox
        sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: ({ zIndex }) => zIndex.fab,
          bottom: '15px',
        }}
      >
        <RepresentationControls
          isOnlyCallTimeBox={isOnlyCallTimeBox}
          isRemoteParticipantJoined={isRemoteParticipantJoined}
          remoteParticipantData={remoteParticipantData}
          isAcceptDeclineRepresentationBox={isAcceptDeclineRepresentationBox}
          isViewFilesToSignBox={isViewFilesToSignBox}
          isSendRepresentationRequestBox={isSendRepresentationRequestBox}
          isSendingOffer={isSendingOffer}
          isApprovingRepresentation={isApprovingRepresentation}
          isRejecting={isRejecting}
          onClickApproveRepresentationHandler={onClickApproveRepresentationHandler}
          onClickDeclineRepresentationHandler={onClickDeclineRepresentationHandler}
          onClickSendRepresentationHandler={onClickSendRepresentationHandler}
          openSignDocumentsModal={openSignDocumentsModal}
          representationStatus={representation?.status}
          isCannotSendRepresentationRequest={isCannotSendRepresentationRequest}
          onClickBuySubscriptionHandler={openBuySubscriptionModal}
        >
          {children}
        </RepresentationControls>

        <MDBox
          sx={{
            p: 1.5,
            opacity: 0.9,
            backgroundColor: ({ palette }) => palette?.grey[600],
            borderRadius: '0 0 8px 8px',
          }}
        >
          <Stack spacing={{ xs: 1, sm: 1.5 }} direction="row" justifyContent="space-between" alignItems="center">
            <MDButton
              disabled={isReconnecting}
              iconOnly
              onClick={handleMuteAudio}
              size="large"
              color="white"
              sx={Styled.ActionButtonsSX}
            >
              {isMicDisabled ? <MicOffRounded /> : <KeyboardVoiceRounded color="inherit" />}
            </MDButton>

            {eventType === 'video_call' && (
              <MDButton
                disabled={isReconnecting}
                iconOnly
                size="large"
                onClick={handleMuteVideo}
                color="white"
                sx={Styled.ActionButtonsSX}
              >
                {isVideoDisabled ? <VideocamOffRounded /> : <VideocamRounded />}
              </MDButton>
            )}

            <MDButton
              disabled={isReconnecting}
              iconOnly
              onClick={onChatIconClickHandler}
              size="large"
              color="white"
              sx={Styled.ActionButtonsSX}
            >
              {isChatOpen ? <CommentRounded /> : <CommentsDisabledRounded />}
            </MDButton>
            {isAttorney && (
              <MDButton
                disabled={isReconnecting || isUploadFileButtonDisabled}
                iconOnly
                onClick={
                  isSomeFileSignServiceActive
                    ? openRequestToSignDocumentsModalOpen
                    : openNotConnectedFileSignServicesModal
                }
                size="large"
                color="white"
                sx={Styled.ActionButtonsSX}
              >
                <DescriptionRounded />
              </MDButton>
            )}

            <MDButton
              iconOnly
              size="large"
              disabled={isReconnecting}
              onClick={onLeaveChannel}
              sx={{
                ...Styled.FooterButtonSX,
                backgroundColor: ({ palette }) => `${palette.error.main} !important`,
                ':hover': {
                  backgroundColor: ({ palette }) => `${palette.error.light} !important`,
                },
              }}
            >
              <CallEndRounded />
            </MDButton>
          </Stack>
        </MDBox>

        <SideMenu
          isOpen={isSignDocumentsModalOpen}
          onClose={closeSignDocumentsModal}
          title="Sign the documents"
          customWidth={450}
        >
          <DocumentsToSignContent closeSignDocumentsModal={closeSignDocumentsModal} legalCaseUID={legalCaseUID} />
        </SideMenu>

        <SideMenu
          isOpen={isSelectDocumentsFromCaseMenuOpen}
          onClose={closeSelectDocumentsFromCaseMenu}
          title="Select document from the case to sign"
          customWidth={450}
        >
          <AvailiableDocumentsToSendSignRequest
            legalCaseUID={legalCaseUID}
            userSignerFirebaseId={remoteParticipantFirebaseId}
            closeSignDocumentsModal={closeSelectDocumentsFromCaseMenu}
            fileSignProvider={fileSignProvider?.provider}
          />
        </SideMenu>

        <NotConnectedFileSignProvidersWindow
          isOpen={isNotConnectedFileSignServicesOpen}
          onCloseDialogHandler={closeNotConnectedFileSignServicesModal}
        />

        <RequestToSignDocumentsOptionsModalWindow
          isOpen={isRequestToSignDocumentsModalOpen}
          onCloseModalHandler={closeRequestToSignDocumentsModalOpen}
          onInputFileUploadHandler={onInputFileUploadHandler}
          isDocumentLoading={isDocumentLoading}
          onClickSeletDocumentFromCaseHandler={onOpenSelectDocumentsFromTheCaseMenuHandler}
          onClickCreateDocumentFromTemplateHandler={onOpenSelectTemplateDocumentModalHandler}
          isCreateDocumentFromTemplateDisabled={!isSomeFileSignServiceActive}
          onChangeRadioButtonHandler={onChangeRadioButtonHandler}
          selectedSignProvider={fileSignProvider?.provider}
          isFileSignServicesActive={isFileSignServicesActive}
        />

        <SelectFileSignTemplateModal
          selectedFileSignProvider={fileSignProvider}
          isOpen={isSelectTemplateDocumentModalOpen}
          onCloseWindowHandler={closeSelectTemplateDocumentModal}
          onClickSelectTemplateItemHandler={onClickSelectTemplateItemHandler}
        />

        <FillingTemplateSideMenu
          isOpen={isFillTemplateMenuOpen}
          closeMenuHandler={closeFillTemplateMenu}
          title={selectedTemplate?.name || ''}
          templateId={selectedTemplate?.id || ''}
          remoteParticipantFirebaseId={remoteParticipantFirebaseId}
          legalCaseId={legalCaseId}
          selectedFileSignProvider={fileSignProvider?.provider}
        />

        <DialogWindow
          onCloseDialogHandler={closeDialogWindow}
          onApproveDialogHandler={dialogWindowAcceptHandler}
          isDialogOpen={isDialogOpen}
          dialogTitle="Are you sure?"
          paperPropsSX={{ minWidth: 420 }}
        />

        {/* TODO conference hack */}
        <Dialog open={isBuySubscriptionModalOpen} onClose={closeBuySubscriptionModal}>
          <CreateCaseModalContent
            title={
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <MDTypography variant="h5">Careful</MDTypography>

                <ErrorOutlineOutlined fontSize="large" color="error" />
              </Stack>
            }
            contentTitle="If you proceed with the subscription, the call will be interrupted. Please end the consultation."
          >
            <MDButton variant="gradient" color="error" onClick={onClickBuySubscriptionHandler}>
              Subscribe
            </MDButton>
            <MDButton variant="gradient" color="dark" onClick={closeBuySubscriptionModal}>
              Later
            </MDButton>
          </CreateCaseModalContent>
        </Dialog>
      </MDBox>
    );
  }
);
