import { FC } from 'react';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { CircularProgress, Stack, Theme } from '@mui/material';
import MDTypography from 'components/MDTypography';

type ModalItemProps = {
  onClickHandler?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  title: string;
};

export const ModalItem: FC<ModalItemProps> = ({ isDisabled, isLoading, onClickHandler, title }) => {
  return (
    <Stack
      sx={{
        p: 1,
        borderRadius: '6px',
        ...(!isDisabled && {
          ':hover': {
            backgroundColor: ({ palette }) => palette.grey[200],
            cursor: 'pointer',
          },
        }),
      }}
      onClick={isDisabled ? null : onClickHandler}
      direction="row"
      justifyContent="space-between"
      width={1}
      alignItems="center"
    >
      <MDTypography
        sx={{ fontSize: 18, fontWeight: 500, ...(isDisabled && { color: ({ palette }) => palette.grey[500] }) }}
      >
        {title}
      </MDTypography>

      {isLoading ? (
        <CircularProgress size={20} />
      ) : (
        <ChevronRightRoundedIcon fontSize="medium" color={isDisabled ? 'disabled' : 'action'} />
      )}
    </Stack>
  );
};
