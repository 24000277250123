import { Pagination } from 'features/case-law-types';
import {
  BackendUserResponse,
  GetAttorneyProfileResponse,
  ListOfItems,
  UserRoles,
  backendApiClient,
} from 'features/common';
import { CompanyInfo } from 'features/company-list';
import { User } from 'features/my-legal-cases/MyLegalCasesApiService';

const BASE_ADMIN_INVITATION_API_URL = '/admin_invitations';

export type GetUsersParams = Pagination &
  Partial<{
    search: string;
    'attorneyProfile.active': boolean;
    'attorneyProfile.active[]': boolean[];
    'attorneyProfile.caseLawTypes.title': string;
    'attorneyProfile.caseLawTypes.title[]': string[];
    'clientLegalCases.caseLawType': string;
    'clientLegalCases.caseLawType[]': string[];
    'clientLegalCases.state.isoCode': string;
    'clientLegalCases.state.isoCode[]': string[];
    'clientLegalCases.country': string;
    'clientLegalCases.country[]': string[];
    'clientLegalCases.status.type': string;
    'clientLegalCases.status.type[]': string[];
    'clientLegalCases.county': string;
    'clientLegalCases.county[]': string[];
    'exists[attorneyProfile]': boolean;
  }>;

export type GetUserByIdResponse = Omit<BackendUserResponse, 'attorneyProfile'> & {
  attorneyProfile: GetAttorneyProfileResponse | null;
  company: CompanyInfo | null;
};

export type GetUsersResponse = User & {
  roles: UserRoles;
  email: string;
  phone: string;
  createdAt: string;
};

export type CreateAdminInvitationPayload = {
  email: string;
  firstName: string;
  lastName: string;
  expiredAt?: string;
};

export type AdminInvitation = CreateAdminInvitationPayload & {
  '@id': string;
  '@type': string;
  accessCode: string;
};

export const usersManagementApiService = () => {
  const getUsers = async (params?: GetUsersParams) =>
    await backendApiClient({}).get<ListOfItems<GetUsersResponse[]>>('/users', {
      params,
    });

  const getUserById = async (firebaseUserId: string) =>
    await backendApiClient({}).get<GetUserByIdResponse>(`/users/${firebaseUserId}`);

  const createAdminInvitation = async (payload: CreateAdminInvitationPayload) =>
    await backendApiClient({}).post<AdminInvitation>(BASE_ADMIN_INVITATION_API_URL, payload);

  const acceptAdminInvitation = async (accessCode: string) =>
    await backendApiClient({}).post<AdminInvitation>(`${BASE_ADMIN_INVITATION_API_URL}/accept`, {
      accessCode,
    });

  const getAdmins = async (params: GetUsersParams) =>
    await backendApiClient({}).get<ListOfItems<GetUsersResponse[]>>('/users/admins', {
      params,
    });

  return { getAdmins, getUsers, acceptAdminInvitation, getUserById, createAdminInvitation };
};
