import { FC, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { Loader } from 'features/common/components/Loader';
import { caseDetailsApiService } from 'features/case-details/CaseDetailsApiService';
import { ToastType, notice } from 'features/common';
import { CaseFile } from 'features/my-legal-cases/MyLegalCasesApiService';
import { DocumentsItemToSign } from 'features/case-details/components/Documents/DocumentsItemToSign';
import { MenuContentProps } from '../types';

export const DocumentsToSignContent: FC<
  MenuContentProps & {
    closeSignDocumentsModal: () => void;
  }
> = ({ legalCaseUID, closeSignDocumentsModal }) => {
  const { getLegalCaseFilesRequiredToSign } = caseDetailsApiService();

  const [isDocumentLoading, setIsDocumentLoading] = useState(true);

  const [files, setFiles] = useState<CaseFile[]>([]);

  const onGetFilesToSignHandler = async () => {
    try {
      const { data } = await getLegalCaseFilesRequiredToSign(legalCaseUID);
      setFiles(data?.files);
      setIsDocumentLoading(false);
    } catch (error) {
      console.error(error);
      setIsDocumentLoading(false);
      notice(ToastType.ERROR, 'Failed to fetch files, try again!');
    }
  };

  useEffect(() => {
    onGetFilesToSignHandler();
  }, []);

  return (
    <Stack height={1}>
      <Stack
        spacing={1}
        sx={{
          mt: 3,
          pb: 1.5,
          height: 1,
        }}
      >
        {isDocumentLoading ? (
          <Loader />
        ) : !isDocumentLoading && files?.length ? (
          files?.map(file => (
            <DocumentsItemToSign
              key={file.id}
              title={file?.title}
              id={file.id}
              fileSize={Number(file?.fileSize)}
              ownerFirstName={file?.owner?.userProfile?.firstName}
              ownerLastName={file?.owner?.userProfile?.lastName}
              fileType={file?.type}
              caseId={legalCaseUID}
              closeSignDocumentsModal={closeSignDocumentsModal}
            />
          ))
        ) : (
          <Stack flex={1} justifyContent="center" alignItems="center" minHeight={150}>
            <MDTypography sx={{ textAlign: 'center' }}>No documents were found...</MDTypography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
