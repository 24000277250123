import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSettings } from 'features/store';
import { GeneralMailSchema, generalMailValidationSchema } from '../form.config';
import { ConfigItemId, GetEmailTemplatesResponse } from '../SettingsApiService';
import { getEmailTemplatesFields } from '../helpers';
import { ConfigMailTemplatesInitialState, FieldChangedMapState } from '../types';
import { ToastType, notice } from 'features/common';
import { formatMinutesToSeconds, formatSecondsToMinutes } from 'features/common/helpers/utilities';
import { useConfigSettingsContextState } from '../context/ConfigSettingsContextProvider';

export const useMailSettings = () => {
  const { configMap } = useConfigSettingsContextState();
  const { fetchEmailTemplatesHandler, fetchSettingsHandler, updateSettingsHandler, emailTemplates, settings } =
    useSettings(state => ({
      updateSettingsHandler: state.updateSettingsHandler,
      fetchEmailTemplatesHandler: state.fetchEmailTemplatesHandler,
      fetchSettingsHandler: state.fetchSettingsHandler,
      emailTemplates: state.emailTemplates,
      settings: state.settings,
    }));

  const mailTemplatesConfig = useMemo(() => settings?.filter(el => el.tags.includes('template')), [settings]);
  const [configMailTemplatesState, setConfigMailTemplatesState] = useState<ConfigMailTemplatesInitialState | null>(
    null
  );

  const [isTemplatesLoading, setIsTemplatesLoading] = useState(true);

  const [isSettingsLoading, setIsSettingsLoading] = useState(true);
  const [isTemplatesUpdating, setIsTemplatesUpdating] = useState(false);
  const [isFieldChangedMap, setIsFieldChangedMap] = useState<FieldChangedMapState>({});

  const onFetchEmailTemplatesHandler = async () => {
    await fetchEmailTemplatesHandler();
    setIsTemplatesLoading(false);
  };

  const onFetchSettingsHandler = async () => {
    await fetchSettingsHandler({ itemsPerPage: 50 });
    setIsSettingsLoading(false);
  };

  useEffect(() => {
    if (!configMailTemplatesState && emailTemplates.length) {
      const initialValues: ConfigMailTemplatesInitialState = {};

      mailTemplatesConfig?.forEach(config => {
        initialValues[config.id] = emailTemplates?.find(template => template?.name === config?.configValue);
      });

      setConfigMailTemplatesState(initialValues);
    }
  }, [emailTemplates]);

  const handleAutocompleteChange = (configValue: string, newValue: GetEmailTemplatesResponse) => {
    setConfigMailTemplatesState(prevState => ({
      ...prevState,
      [configValue]: newValue,
    }));

    const isValueChanged = newValue?.name !== configMap[configValue as ConfigItemId]?.configValue;

    if (isValueChanged) {
      setIsFieldChangedMap(prevIsFieldChangedMap => ({
        ...prevIsFieldChangedMap,
        [configValue]: true,
      }));
    } else {
      setIsFieldChangedMap(prevIsFieldChangedMap => ({
        ...prevIsFieldChangedMap,
        [configValue]: false,
      }));
    }
  };

  const changedFieldsKeys = Object.keys(isFieldChangedMap);

  const onUpdateTemplateHandler = async () => {
    setIsTemplatesUpdating(true);
    const savePromises = changedFieldsKeys.map(async fieldId => {
      const value = configMailTemplatesState[fieldId as ConfigItemId].name;
      await updateSettingsHandler(fieldId, { configValue: value }, false);
    });

    await Promise.all(savePromises);
    setIsTemplatesUpdating(false);
    setIsFieldChangedMap({});

    notice(ToastType.SUCCESS, 'Successfully updated!');
  };

  const {
    register: generalMailRegister,
    formState: generalMailFormState,
    handleSubmit: generalMailHandleSubmit,
  } = useForm<GeneralMailSchema>({
    resolver: yupResolver(generalMailValidationSchema),
    values: {
      mail_sender_email: configMap['mail_sender_email']?.configValue || '',
      mail_sender_name: configMap['mail_sender_name']?.configValue || '',
      mail_sender_email_chat_reminder_seconds:
        formatSecondsToMinutes(configMap['mail_sender_email_chat_reminder_seconds']?.configValue) || '',
      contact_us_email: configMap['contact_us_email']?.configValue || '',
    },
    mode: 'onTouched',
  });

  const isSaveGeneralMailButtonDisabled =
    !generalMailFormState.isValid || !generalMailFormState.isDirty || generalMailFormState.isSubmitting;

  const onSubmitGeneralMailFormHandler = generalMailHandleSubmit(async formValues => {
    const valuesToUpdate = Object.entries(formValues).filter(([key, value]) => {
      return value !== generalMailFormState.defaultValues[key as keyof GeneralMailSchema];
    });

    await Promise.all(
      valuesToUpdate.map(([key, val]) =>
        updateSettingsHandler(configMap[key as keyof GeneralMailSchema]?.id, {
          configValue: key === 'mail_sender_email_chat_reminder_seconds' ? formatMinutesToSeconds(val) : val,
        })
      )
    );
  });

  const emailTemplatesFields = getEmailTemplatesFields({
    configMailTemplatesState,
  });

  const isSaveTemplatesButtonsDisabled =
    !changedFieldsKeys.length || !Object.values(isFieldChangedMap).filter(el => el).length;

  return {
    isSaveGeneralMailButtonDisabled,
    generalMailFormState,
    emailTemplates,
    onSubmitGeneralMailFormHandler,
    generalMailRegister,
    handleAutocompleteChange,
    onUpdateTemplateHandler,
    settings,
    mailTemplatesConfig,
    emailTemplatesFields,
    configMailTemplatesState,
    isTemplatesUpdating,
    isSaveTemplatesButtonsDisabled,
    onFetchEmailTemplatesHandler,
    isTemplatesLoading,
    isSettingsLoading,
    onFetchSettingsHandler,
  };
};
