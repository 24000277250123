import { FC, memo } from 'react';
import { Dialog, DialogContent, DialogTitle, Divider, Stack } from '@mui/material';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { SIGN_PROVIDERS, SignProvidersRadioGroup } from 'features/common/components/FileSignProviderDialogWindow';
import { SignProvidersRadioGroupProps } from 'features/common/components/FileSignProviderDialogWindow/types';
import { ModalItem } from './ModalItem';

type RequestToSignDocumentsModalWindowProps = Pick<
  SignProvidersRadioGroupProps,
  'onChangeRadioButtonHandler' | 'selectedSignProvider'
> & {
  isOpen: boolean;
  isDocumentLoading?: boolean;
  onCloseModalHandler: () => void;
  onInputFileUploadHandler: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  onClickSeletDocumentFromCaseHandler?: () => void;
  onClickCreateDocumentFromTemplateHandler: () => void;
  isCreateDocumentFromTemplateDisabled?: boolean;
  modalTitle?: string;
  isFileSignServicesActive: boolean;
};

export const RequestToSignDocumentsOptionsModalWindow: FC<RequestToSignDocumentsModalWindowProps> = memo(
  ({
    onClickSeletDocumentFromCaseHandler,
    onInputFileUploadHandler,
    isOpen,
    isDocumentLoading,
    isCreateDocumentFromTemplateDisabled,
    onCloseModalHandler,
    onClickCreateDocumentFromTemplateHandler,
    modalTitle = 'Request documents to sign',
    onChangeRadioButtonHandler,
    selectedSignProvider,
    isFileSignServicesActive,
  }) => {
    return (
      <Dialog
        open={isOpen}
        onClose={onCloseModalHandler}
        PaperProps={{
          sx: {
            maxWidth: 400,
            width: 1,
          },
        }}
      >
        <DialogTitle textAlign="center">{modalTitle}</DialogTitle>
        <DialogContent>
          <Stack divider={<Divider sx={{ my: 1 }} />}>
            {onClickSeletDocumentFromCaseHandler && (
              <ModalItem onClickHandler={onClickSeletDocumentFromCaseHandler} title="Select document from the case" />
            )}

            <ModalItem
              isDisabled={isCreateDocumentFromTemplateDisabled}
              onClickHandler={onClickCreateDocumentFromTemplateHandler}
              title="Create document from template"
            />

            <MDBox component="label">
              <ModalItem title="Upload document" isLoading={isDocumentLoading} />
              <input
                disabled={isDocumentLoading}
                onChange={onInputFileUploadHandler}
                type="file"
                hidden
                accept="image/*,audio/*,video/*,application/*,text/*"
              />
            </MDBox>
          </Stack>
          <Divider sx={{ my: 1, mb: 1.5 }} />
          {isFileSignServicesActive && (
            <Stack spacing={1.5} mb={1.5}>
              <MDTypography textAlign="center" fontWeight={500}>
                Choose a service to interact with?
              </MDTypography>

              <SignProvidersRadioGroup
                onChangeRadioButtonHandler={onChangeRadioButtonHandler}
                selectedSignProvider={selectedSignProvider}
                fileSignProviders={SIGN_PROVIDERS}
              />
            </Stack>
          )}
          <MDButton sx={{ mt: 1 }} fullWidth variant="outlined" color="dark" onClick={onCloseModalHandler}>
            Cancel
          </MDButton>
        </DialogContent>
      </Dialog>
    );
  }
);
