import { FC, useEffect, useState } from 'react';
import { DashboardLayout, ToastType, notice, useRouter, useUser } from 'features/common';
import { Grid } from '@mui/material';
import { chatApiService } from 'features/common/services/chatApiService';
import { useMyChats } from 'features/store';
import { Loader } from 'features/common/components/Loader';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';
import { MainChatContent } from './components/MainChatContent/MainChatContent';

export const ChatTemplate: FC = () => {
  const { isAttorney } = useUser();
  const setSelectedUserChat = useMyChats(state => state.setSelectedUserChat);

  const { state, navigate } = useRouter();

  const { getListOfChats } = chatApiService();
  const [isLoading, setIsLoading] = useState(true);

  const onGetChatDataHandler = async () => {
    try {
      const { data } = await getListOfChats({
        'legalCase.caseNumber': state?.caseNumber,
        [isAttorney ? 'client' : 'attorney']: state?.userFirebaseId,
      });

      const chatData = data['hydra:member']?.[0];
      if (!chatData) {
        notice(ToastType.ERROR, 'This chat does not exist');
        navigate(state?.prevRoute, { replace: true });
      }
      setSelectedUserChat(chatData);
      setIsLoading(false);
    } catch (error) {
      paymentRequiredErrorHandler(error);
      console.error(error);
    }
  };

  useEffect(() => {
    onGetChatDataHandler();
  }, []);

  return (
    <DashboardLayout>
      <Grid container flex={1} height={1} spacing={2}>
        {isLoading ? (
          <Grid item xs={12} display="flex">
            <Loader />
          </Grid>
        ) : (
          <MainChatContent />
        )}
      </Grid>
    </DashboardLayout>
  );
};
