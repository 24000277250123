import { useEffect, useState } from 'react';
import { Card, Grid } from '@mui/material';
import { EditorState } from 'draft-js';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormField, DashboardLayout, notice, RichTextEditor, ToastType, useRouter } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';
import { convertEditorStateValueToHTML, getEditorStateFromContent } from 'features/common/helpers/textEditor';
import { Loader } from 'features/common/components/Loader';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useEditStaticPageData } from '../hooks/useEditStaticPageData';
import { baseBackendErrorHandler } from '../../common/errorHanders';
import { EditStaticPageFormSchema, editStaticPageFormSchema } from './formSchema';
import { ButtonsFooter } from '../components';
import { FailedLoadContent } from 'features/common/components/FailedLoadContent';

export const EditStaticPageTemplate = (): JSX.Element => {
  const router = useRouter();

  const { staticPageData, isLoading, isFailed, patchContentPageHandler } = useEditStaticPageData(
    router.params?.staticPageId
  );

  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, errors, isDirty },
    register,
    setError,
    setValue,
    reset,
  } = useForm<EditStaticPageFormSchema>({
    resolver: yupResolver(editStaticPageFormSchema),
    mode: 'onChange',
    values: {
      urlPath: staticPageData?.urlPath,
      contentTitle: staticPageData?.contentTitle,
      contentBody: staticPageData?.contentBody,
    },
  });

  const onSubmitFormHandler = async (data: EditStaticPageFormSchema) => {
    try {
      if (!editorState) return;

      await patchContentPageHandler({
        contentTitle: data.contentTitle,
        urlPath: data.urlPath,
        contentBody: convertEditorStateValueToHTML(editorState),
      });

      await router.push(ROUTES.staticPages);

      notice(ToastType.SUCCESS, 'Successfully updated static page!');
    } catch (error) {
      console.error(error);
      baseBackendErrorHandler(error, { formError: { formData: data, setError } });
    }
  };

  const initializeStaticPageData = () => {
    const initializeEditorState = () => {
      const editorState = getEditorStateFromContent(staticPageData?.contentBody);
      setEditorState(editorState);
    };

    staticPageData && initializeEditorState();
  };

  const onClickDiscardButtonHandler = () => {
    initializeStaticPageData();
    reset();
  };

  const onEditorStateChangeHandler = (editorState: EditorState) => {
    setEditorState(editorState);
    setValue('contentBody', convertEditorStateValueToHTML(editorState), { shouldDirty: true });
  };

  useEffect(() => {
    initializeStaticPageData();
  }, [staticPageData]);

  const isSaveButtonDisabled = !isValid || !isDirty || isSubmitting;

  return (
    <DashboardLayout isNavigationDisabled>
      {isLoading && !isSubmitting ? (
        <Loader />
      ) : staticPageData ? (
        <form onSubmit={handleSubmit(onSubmitFormHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card sx={{ p: 2 }}>
                <MDBox
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                  }}
                >
                  <MDTypography variant="h4" fontWeight="bold" sx={{ mb: '16px' }}>
                    Edit Content Page
                  </MDTypography>
                  <MDBox
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: { xs: 2, sm: 4 },
                      flexDirection: { xs: 'column', sm: 'row' },
                    }}
                  >
                    <BaseFormField
                      formFieldProps={{ sx: { flex: 1 } }}
                      formInputProps={{
                        ...register('contentTitle'),
                        type: 'text',
                        label: 'Title',
                        fullWidth: true,
                        color: 'white',
                        disabled: isLoading,
                        InputLabelProps: { shrink: true },
                      }}
                      errorValue={errors['contentTitle']?.message}
                    />
                    <BaseFormField
                      formFieldProps={{ sx: { flex: 1 } }}
                      formInputProps={{
                        ...register('urlPath'),
                        type: 'text',
                        label: 'Page Url Path',
                        fullWidth: true,
                        color: 'white',
                        disabled: isLoading,
                        InputLabelProps: { shrink: true },
                      }}
                      errorValue={errors.urlPath?.message}
                    />
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card sx={{ p: 2 }}>
                <RichTextEditor editorState={editorState} onEditorStateChange={onEditorStateChangeHandler} />
              </Card>
            </Grid>

            <ButtonsFooter
              isDiscardButtonDisabled={!isDirty || isSubmitting}
              onClickDiscardButtonHandler={onClickDiscardButtonHandler}
              isSaveButtonDisabled={isSaveButtonDisabled}
              isLoading={isSubmitting}
            />
          </Grid>
        </form>
      ) : (
        isFailed && <FailedLoadContent />
      )}
    </DashboardLayout>
  );
};
