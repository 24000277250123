import { ToastType, notice } from 'features/common';
import { immer } from 'zustand/middleware/immer';
import {
  FeedNotification,
  GetNotificationsParams,
  notificationCenterApiService,
} from 'features/notification-center/NotificationCenterApiService';
import { AgoraAudioVideoIncomingCallData } from 'features/common/services/agoraEventApiService';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders/paymentRequiredErrorHandler';
import { createStore } from '../createStore';

type PushNotificationData = {
  firebaseToken: string;
  pushNotificationId: string;
  inviteCallData: AgoraAudioVideoIncomingCallData | null;
};

export type NotificationCenterState = {
  notifications: FeedNotification[];
  totalItems: number | null;
  isLoading: boolean;
  isCallModalOpen: boolean;
  isFetchMoreLoading: boolean;
  pushNotificationData: PushNotificationData;
};

export type NotificationCenterActions = {
  getNotificationsHandler: (params?: GetNotificationsParams) => Promise<void>;
  fetchMoreNotificationsHandler: (params: GetNotificationsParams) => Promise<void>;
  setPushNotificationId: (id: string) => void;
  setFirebaseToken: (token: string) => void;
  setCallInvitationMessage: (message: AgoraAudioVideoIncomingCallData | null) => void;
  markNotificationAsReadHandler: (id: string) => Promise<void>;
  markAllNotificationsAsReadHandler: () => Promise<void>;
  setIsCallModalOpen: (isOpen: boolean) => void;
};

const { getNotifications, patchNotification, readAllNotification } = notificationCenterApiService();

const initialState: NotificationCenterState = {
  isLoading: false,
  isFetchMoreLoading: false,
  isCallModalOpen: false,
  notifications: [],
  totalItems: null,
  pushNotificationData: {
    firebaseToken: '',
    pushNotificationId: '',
    inviteCallData: null,
  },
};

export const useNotificationsCenter = createStore<NotificationCenterState & NotificationCenterActions>(
  immer(set => ({
    ...initialState,
    setCallInvitationMessage: message => {
      set(state => {
        state.pushNotificationData.inviteCallData = message;
      });
    },

    markNotificationAsReadHandler: async id => {
      try {
        const { data } = await patchNotification(id, new Date().toISOString());

        set(state => {
          state.notifications = state.notifications.map(el => (el?.id === id ? data : el));
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch notifications, please try again!');
        console.error(error);
      }
    },
    getNotificationsHandler: async params => {
      try {
        set({ isLoading: true });
        const { data } = await getNotifications(params);
        set({ notifications: data['hydra:member'], totalItems: data['hydra:totalItems'], isLoading: false });
      } catch (error) {
        set({ isLoading: false });
        paymentRequiredErrorHandler(error, 'Failed to fetch notifications, please try again!');
        console.error(error);
      }
    },
    markAllNotificationsAsReadHandler: async () => {
      try {
        set({ isLoading: true });
        await readAllNotification();
        const { data: notificationsData } = await getNotifications({ page: 1, itemsPerPage: 30 });

        set({
          notifications: notificationsData['hydra:member'],
          totalItems: notificationsData['hydra:totalItems'],
          isLoading: false,
        });
      } catch (error) {
        set({ isLoading: false });
        notice(ToastType.ERROR, 'Failed to mark all notifications as read, please try again!');
        console.error(error);
      }
    },
    fetchMoreNotificationsHandler: async params => {
      try {
        set({ isFetchMoreLoading: true });
        const { data } = await getNotifications(params);
        set(state => ({ notifications: [...state.notifications, ...data['hydra:member']], isFetchMoreLoading: false }));
      } catch (error) {
        set({ isFetchMoreLoading: false });
        paymentRequiredErrorHandler(error, 'Failed to fetch notifications, please try again!');
        console.error(error);
      }
    },
    setFirebaseToken: firebaseToken =>
      set(state => {
        state.pushNotificationData.firebaseToken = firebaseToken;
      }),
    setPushNotificationId: id =>
      set(state => {
        state.pushNotificationData.pushNotificationId = id;
      }),
    setIsCallModalOpen: isOpen => {
      return set(state => {
        state.isCallModalOpen = isOpen;
      });
    },
  })),
  'notifications',
  initialState
);
