import { isAfter, isBefore } from 'date-fns';

export const getIsEventTime = (eventStartTime: string | Date, eventEndTime: string | Date) => {
  const startDate = new Date(eventStartTime);
  const endDate = new Date(eventEndTime);
  const currentDate = new Date();

  const isAfterEventEndTime = isAfter(currentDate, endDate);
  const isEventTime = isAfter(currentDate, startDate) && isBefore(currentDate, endDate);
  return { isAfterEventEndTime, isEventTime };
};
