import { FC, useEffect, useState } from 'react';
import { Autocomplete, Card, CardContent, CardHeader, CircularProgress, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BaseFormField,
  CountiesPaginationParams,
  CountryState,
  County,
  ToastType,
  createGeoDataService,
  notice,
} from 'features/common';
import { CREATE_CASE_FORM_STEPS, useCreateCase } from 'features/store';
import MDBox from 'components/MDBox';
import { getOptionById } from 'features/common/helpers/utilities';
import { clioUserSettingsApiService } from 'features/clio-settings/ClioSettingsApiService';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';
import { CaseInformationSchema, caseInformationFormSchema } from '../CreateLegalCase/form.config';
import { ButtonsFooter } from '../CreateLegalCase/ButtonsFooter';

export const CaseInformation: FC = () => {
  const { createClioCaseLawType } = clioUserSettingsApiService();
  const {
    saveFormDataHandler,
    setStateData,
    activeStep,
    caseInformationData: {
      caseCounty,
      caseLawType,
      caseState,
      description,
      caseNumber,
      caseCountyTitle: countyTitle,
      caseLawTypeTitle: lawTypeTitle,
      caseStateTitle: stateTitle,
    },
    CRMCaseData,
    isCaseForDependant,
  } = useCreateCase(state => ({
    caseInformationData: state.caseData,
    activeStep: state.activeStep,
    saveFormDataHandler: state.setData,
    CRMCaseData: state.CRMCaseData,
    setStateData: state.setStateData,
    isCaseForDependant: state.dependantData?.isCaseForDependant,
  }));

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isValid, isDirty },
  } = useForm<CaseInformationSchema>({
    resolver: yupResolver(caseInformationFormSchema),
    mode: 'onTouched',
    defaultValues: {
      caseNumber: caseNumber || CRMCaseData?.caseNumber,
      caseCounty: caseCounty || CRMCaseData?.county?.['@id'],
      caseLawType: caseLawType || CRMCaseData?.caseLawType?.['@id'],
      caseState: caseState || CRMCaseData?.state?.['@id'],
      description: description || CRMCaseData?.description,
    },
  });

  const onFormSubmitHandler = handleSubmit(formData => {
    !isCaseForDependant &&
      CRMCaseData?.caseForDependant &&
      setStateData('dependantData', {
        isCaseForDependant: CRMCaseData?.caseForDependant,
        dependantCaseState: CRMCaseData?.dependantStateOfResidence,
        dependantFirstName: CRMCaseData?.dependantFirstName,
        dependantLastName: CRMCaseData?.dependantLastName,
        isUnder18: CRMCaseData?.dependantUnder18,
        dependantCaseStateTitle: '',
      });

    saveFormDataHandler({
      step: activeStep,
      data: { ...formData, caseCountyTitle, caseStateTitle, caseLawTypeTitle },
      activeStep: CREATE_CASE_FORM_STEPS.DEPENDANT_INFO,
    });
  });

  const [isCreatingClioCaseLawType, setIsCreatingClioCaseLawType] = useState(false);
  const [caseStateList, setCaseStateList] = useState<CountryState[]>([]);
  const [caseCountyList, setCaseCountyList] = useState<Omit<County, 'name'>[]>([]);

  const [isCountyListLoading, setIsCountyListLoading] = useState(false);
  const [isStateListLoading, setIsStateListLoading] = useState(false);

  const [caseCountyTitle, setCaseCountyTitle] = useState(countyTitle ? countyTitle : CRMCaseData?.county?.name || '');
  const [caseStateTitle, setCaseStateTitle] = useState(stateTitle ? stateTitle : CRMCaseData?.state?.name || '');
  const [caseLawTypeTitle, setCaseLawTypeTitle] = useState(
    lawTypeTitle ? lawTypeTitle : CRMCaseData?.caseLawType?.title || ''
  );

  const { getCounties, getStates } = createGeoDataService();

  const getCaseStateListHandler = async () => {
    try {
      setIsStateListLoading(true);
      const { data } = await getStates({});
      setCaseStateList(data['hydra:member']);
      setIsStateListLoading(false);
    } catch (error) {
      setIsStateListLoading(false);
      notice(ToastType.ERROR, 'Failed to fetch states, please try again!');
      console.error(error);
    }
  };

  const getCountyListHandler = async (params: CountiesPaginationParams) => {
    try {
      setIsCountyListLoading(true);
      const { data } = await getCounties({ itemsPerPage: 200, ...params });
      setCaseCountyList(data['hydra:member']);
      setIsCountyListLoading(false);
    } catch (error) {
      setIsCountyListLoading(false);
      notice(ToastType.ERROR, 'Failed to fetch counties, please try again!');
      console.error(error);
    }
  };

  useEffect(() => {
    getCaseStateListHandler();

    if (CRMCaseData?.state && !caseState && !caseCounty && !CRMCaseData?.county)
      getCountyListHandler({ 'state.isoCode': CRMCaseData?.state?.isoCode });
    if (!caseCounty && CRMCaseData?.county) getCountyListHandler({ name: CRMCaseData?.county?.name });
  }, []);

  useEffect(() => {
    const isFetchCounty =
      caseCounty !== CRMCaseData?.county?.['@id'] && caseState && !caseCountyList.length && caseStateList.length;

    if (isFetchCounty) {
      const stateValue = getOptionById(caseState, caseStateList);
      getCountyListHandler({ 'state.isoCode': stateValue?.isoCode });
    }

    if (!!CRMCaseData?.county && caseCounty === CRMCaseData?.county?.['@id'] && !caseCountyList.length) {
      getCountyListHandler({ name: CRMCaseData?.county?.name });
    }
  }, [caseState, caseStateList]);

  const currentCaseState = watch('caseState');

  const onClickBackButtonHandler = () => {
    saveFormDataHandler({
      ...(isDirty && {
        step: activeStep,
        data: { ...getValues(), caseCountyTitle, caseLawTypeTitle, caseStateTitle },
      }),
      activeStep: CREATE_CASE_FORM_STEPS.CLIENT_INFO,
    });
  };

  useEffect(() => {
    if (!CRMCaseData?.county && !countyTitle)
      setError('caseCounty', {
        message: 'In order to continue creating the case, the information you entered must match the proposed option.',
      });

    if (!CRMCaseData?.state && !stateTitle)
      setError('caseState', {
        message: 'In order to continue creating the case, the information you entered must match the proposed option.',
      });

    if (!CRMCaseData?.caseLawType && !!CRMCaseData?.originalLawType && !caseLawTypeTitle) {
      setError('caseLawType', {
        message: `Received Case law type value - "${CRMCaseData?.originalLawType?.value}" does not match the proposed options`,
      });
    }
  }, [CRMCaseData]);

  return (
    <form onSubmit={onFormSubmitHandler}>
      <Card>
        <CardHeader title="Case Information" />
        <CardContent>
          <Stack direction={{ xs: 'column', sm: 'row' }} gap={{ xs: 0, sm: 3 }} width={1}>
            <Stack width={1}>
              <BaseFormField
                formInputProps={{
                  ...register('caseNumber'),
                  label: 'Case number (optional)',
                  placeholder: 'Enter a case number',
                  InputLabelProps: { shrink: true },
                  fullWidth: true,
                }}
              />

              <Controller
                control={control}
                name="caseState"
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return (
                    <Autocomplete
                      value={getOptionById(value, caseStateList)}
                      onChange={(_, value) => {
                        onChange(value ? value['@id'] : '');
                        error && clearErrors('caseState');
                        setValue('caseCounty', '');
                        setCaseStateTitle(value?.name || '');
                        value && getCountyListHandler({ 'state.isoCode': value?.isoCode });
                      }}
                      getOptionLabel={option => option?.name}
                      options={caseStateList}
                      freeSolo={false}
                      isOptionEqualToValue={(option, value) => option?.['@id'] === value?.['@id']}
                      disableClearable
                      renderInput={params => (
                        <BaseFormField
                          formInputProps={{
                            ...params,
                            label: 'Case state',
                            placeholder: 'Select a case state',
                            InputLabelProps: { shrink: true },
                            fullWidth: true,
                            InputProps: {
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {isStateListLoading ? (
                                    <MDBox pr={0} display="flex">
                                      <CircularProgress color="inherit" size={20} />
                                    </MDBox>
                                  ) : null}
                                  {errors['caseState']?.message ? null : params.InputProps.endAdornment}
                                </>
                              ),
                            },
                          }}
                          errorValue={errors['caseState']?.message}
                        />
                      )}
                    />
                  );
                }}
              />

              <Controller
                control={control}
                name="caseCounty"
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return (
                    <Autocomplete
                      disabled={!CRMCaseData?.mappedCounty.length && !currentCaseState}
                      value={getOptionById(value, caseCountyList)}
                      options={caseCountyList}
                      getOptionLabel={option => option.shortName}
                      onChange={(_, value) => {
                        onChange(value ? value['@id'] : '');
                        setCaseCountyTitle(value?.shortName || '');
                        error && clearErrors('caseCounty');
                      }}
                      freeSolo={false}
                      isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                      loading={isCountyListLoading}
                      disableClearable={false}
                      renderInput={params => (
                        <BaseFormField
                          formInputProps={{
                            ...params,
                            label: 'Case county',
                            placeholder: !currentCaseState ? 'Select a case state first' : 'Select a case county',
                            InputLabelProps: { shrink: true },
                            fullWidth: true,
                            InputProps: {
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {isCountyListLoading ? (
                                    <MDBox pr={0} display="flex">
                                      <CircularProgress color="inherit" size={20} />
                                    </MDBox>
                                  ) : null}
                                  {errors['caseCounty']?.message ? null : params.InputProps.endAdornment}
                                </>
                              ),
                            },
                          }}
                          errorValue={errors['caseCounty']?.message}
                        />
                      )}
                    />
                  );
                }}
              />
            </Stack>
            <Stack width={1}>
              <Controller
                control={control}
                name="caseLawType"
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return (
                    <Autocomplete
                      value={
                        value
                          ? CRMCaseData.matchedCaseLawTypes?.find(option => {
                              return value === option['@id'];
                            }) ?? null
                          : null
                      }
                      getOptionLabel={option => option.title}
                      onChange={async (_, value) => {
                        try {
                          setIsCreatingClioCaseLawType(true);
                          onChange(value ? value['@id'] : '');
                          await createClioCaseLawType({
                            caseLawType: value?.['@id'],
                            practiceAreaId: CRMCaseData?.originalLawType?.id || '',
                          });
                          setIsCreatingClioCaseLawType(false);
                          error && clearErrors('caseLawType');
                          setCaseLawTypeTitle(value?.title || '');
                        } catch (error) {
                          console.error(error);
                          paymentRequiredErrorHandler(error);
                        }
                      }}
                      options={CRMCaseData.matchedCaseLawTypes || []}
                      freeSolo={false}
                      isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                      disableClearable={false}
                      renderInput={params => (
                        <BaseFormField
                          formInputProps={{
                            ...params,
                            label: 'Law type',
                            placeholder: 'Select a case law type',
                            InputLabelProps: { shrink: true },
                            fullWidth: true,
                            InputProps: {
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {isCreatingClioCaseLawType ? (
                                    <MDBox pr={0} display="flex">
                                      <CircularProgress color="inherit" size={20} />
                                    </MDBox>
                                  ) : null}
                                  {errors['caseLawType']?.message ? null : params.InputProps.endAdornment}
                                </>
                              ),
                            },
                          }}
                          errorValue={errors['caseLawType']?.message}
                        />
                      )}
                    />
                  );
                }}
              />

              <BaseFormField
                formInputProps={{
                  ...register('description'),
                  label: 'Description',
                  placeholder: 'Enter a case description',
                  fullWidth: true,
                  InputLabelProps: { shrink: true },
                  multiline: true,
                  rows: 4,
                }}
                errorValue={errors['description']?.message}
              />
            </Stack>
          </Stack>
          <ButtonsFooter isSubmitButtonDisabled={!isValid} onClickBackButtonHandler={onClickBackButtonHandler} />
        </CardContent>
      </Card>
    </form>
  );
};
