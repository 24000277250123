import { useEffect, useState } from 'react';
import { ToastType, notice, useAsync, useUser } from '../../common';
import { GetUserSettings, PatchUserSettingsPayload, userSettingsService } from '../services/userSettingsService';

export const useNotificationsSettings = (userId: string) => {
  const isAttorney = useUser()?.isAttorney;
  const { getUserSettings, patchUserSettings } = userSettingsService();
  const [currentSettings, setCurrentSettings] = useState<PatchUserSettingsPayload>({
    allowChat: false,
    allowCallReminder: false,
    allowCaseStatus: false,
    allowCaseUpdates: false,
    allowMissedCall: false,
    allowEmailNotification: false,
    allowNewLead: false,
  });
  const [loadSettingsState, loadSettingsHandler] = useAsync(async () => {
    try {
      const settingsData = await getUserSettings(userId);

      setCurrentSettings(settingsData);
      return settingsData;
    } catch (error) {
      notice(ToastType.ERROR, 'Something went wrong during settings load!');
      console.error({ error });
    }
  });

  const [patchSettingsState, patchSettingsHandler] = useAsync(
    async (patchSettingsPayload: PatchUserSettingsPayload) => {
      try {
        if (SettingsData) {
          const { data } = await patchUserSettings(userId, patchSettingsPayload);
          notice(ToastType.SUCCESS, 'Notification setting successfully updated!');
          setCurrentSettings(data);
        }
      } catch (error) {
        loadSettingsHandler();
        notice(ToastType.ERROR, 'Something went wrong during settings update!');
        console.error({ error });
      }
    }
  );

  const SettingsData: GetUserSettings | null = (loadSettingsState.isSuccessful() && loadSettingsState.data) || null;

  useEffect(() => {
    loadSettingsHandler();
  }, [userId]);

  const isLoading = loadSettingsState.isPending();

  return { isAttorney, currentSettings, loadSettingsState, loadSettingsHandler, isLoading, patchSettingsHandler };
};
