import { FC, useEffect, useRef, useState } from 'react';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { Card, CardContent, CircularProgress, Stack } from '@mui/material';
import MDBox from 'components/MDBox';
import { Loader } from 'features/common/components/Loader';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { useIsOnScreen } from 'features/common/hooks/useIsOnScreen';
import { ITEMS_PER_PAGE } from 'features/my-chats/MyChatsTemplate';
import { getMessageText } from 'features/chat/components/Message';
import { ListOfChatsCardProps } from './types';
import { ListOfChatsItem } from './ListOfChatsItem';

export const ListOfChatsCard: FC<ListOfChatsCardProps> = ({
  isAttorney,
  isChatListOpen,
  listOfUserChats,
  onCloseMenuHandler,
  onSelectedUserChatItemClickHandler,
  getMoreListOfChats,
  selectedChatId,
  selectedUserFirstName,
  isChatListLoading,
  totalItems,
  selectedUserId,
  aesDecrypt,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pages: Readonly<number> = Math.ceil(totalItems / ITEMS_PER_PAGE);

  const [isFetchMoreLoading, setIsFetchMoreLoading] = useState(false);

  const lastElementRef = useRef<HTMLDivElement | null>(null);
  const isOnScreen = useIsOnScreen(isChatListLoading ? null : lastElementRef);

  const onFetchMoreMyLegalCaseNotesHandler = async () => {
    setIsFetchMoreLoading(true);

    const nextPage = currentPage + 1;
    await getMoreListOfChats({
      [isAttorney ? 'client' : 'attorney']: selectedUserId,
      itemsPerPage: ITEMS_PER_PAGE,
      page: nextPage,
    });
    setCurrentPage(nextPage);

    setIsFetchMoreLoading(false);
  };

  useEffect(() => {
    if (isOnScreen && currentPage < pages) {
      onFetchMoreMyLegalCaseNotesHandler();
    }
  }, [isOnScreen]);

  useEffect(() => {
    !isChatListOpen && currentPage > 1 && setCurrentPage(1);
  }, [isChatListOpen]);

  return (
    <MDBox
      sx={{
        position: 'absolute',
        top: 0,
        left: -1000,
        zIndex: 100,
        width: 1,
        height: 1,
        ...(isChatListOpen && {
          left: 0,
        }),
        transition: 'left 0.3s ease-in-out',
      }}
    >
      <Card sx={{ width: 1, height: 1, display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ p: 2, height: 1 }}>
          <Stack height={1} spacing={1} position="relative">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <MDTypography>{`${selectedUserFirstName}'s cases`}</MDTypography>
              <MDButton
                size="medium"
                iconOnly
                onClick={onCloseMenuHandler}
                sx={{
                  '& > svg': {
                    fontSize: '25px !important',
                  },
                }}
              >
                <KeyboardBackspaceOutlinedIcon sx={{ fontSize: '25px !important' }} />
              </MDButton>
            </Stack>
            {isChatListLoading ? (
              <Loader />
            ) : (
              <Stack spacing={2} sx={{ overflowY: 'auto', height: 1, pr: '3px' }}>
                {listOfUserChats?.map((chat, index) => {
                  const messageText = getMessageText(!isAttorney, chat?.lastMessage, aesDecrypt);

                  return (
                    <ListOfChatsItem
                      key={chat?.['@id']}
                      lastElementRef={listOfUserChats?.length - 1 === index ? lastElementRef : null}
                      chatId={chat?.id}
                      isChatSelected={selectedChatId === chat?.id}
                      onItemClickHandler={onSelectedUserChatItemClickHandler}
                      caseNumber={chat?.legalCase?.caseNumber}
                      messageText={messageText}
                      userFirstName={chat?.[isAttorney ? 'client' : 'attorney']?.userProfile?.firstName}
                      userLastName={chat?.[isAttorney ? 'client' : 'attorney']?.userProfile?.lastName}
                      countUnwatchedMessages={chat?.countUnwatchedMessages}
                      messageCreatedAt={chat?.lastMessage?.createdAt}
                      userLogoURL={chat?.[isAttorney ? 'client' : 'attorney']?.logo?.publicUrl}
                    />
                  );
                })}
              </Stack>
            )}

            {isFetchMoreLoading && (
              <MDBox sx={{ position: 'absolute', bottom: 15, left: '50%', transform: 'translateX(-50%)' }}>
                <CircularProgress />
              </MDBox>
            )}
          </Stack>
        </CardContent>
      </Card>
    </MDBox>
  );
};
