import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, delay: number | null = 1000) => {
  const intervalRef = useRef(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback?.current();

    if (delay !== null) {
      intervalRef.current = setInterval(tick, delay);
      return () => clearInterval(intervalRef?.current);
    }
  }, [delay]);

  return intervalRef;
};
