import { FC } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { dateToCustomFormat } from 'features/common/helpers/date';
import { MenuPopover } from 'features/common/components/MenuPopover';
import { PopoverMenuItem } from 'features/common/components/PopoverMenuItem';
import { usePopover } from 'features/common/hooks/usePopover';
import { NotesCardProps } from 'features/case-details/types';
import { ThreeDotsMenu } from '../ThreeDotsMenu';

export const NotesCard: FC<NotesCardProps> = ({
  createdAt,
  onEditIconClickHandler,
  onDeleteIconClickHandler,
  description,
  id,
  title,
  visible,
  isNotMyNote,
  lastElementRef,
  isDisabledActionButton,
  isAttorneyView,
}) => {
  const createdDate = dateToCustomFormat(createdAt);

  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  const onEditMenuItemClickHandler = () => {
    handleClosePopover();
    onEditIconClickHandler(id);
  };

  const onDeleteMenuItemClickHandler = () => {
    handleClosePopover();
    onDeleteIconClickHandler(id);
  };

  return (
    <MDBox
      ref={lastElementRef}
      borderRadius="xl"
      p={1.5}
      bgColor="white"
      sx={{
        position: 'relative',
        fontSize: ({ typography: { size } }) => size.md,
        backgroundColor: theme => (isNotMyNote ? 'inherit' : theme.palette.background.note),
        ...(isNotMyNote && {
          boxShadow: theme => theme.boxShadows.md,
          border: theme => `1px solid ${theme.borders.borderColor}`,
        }),
      }}
    >
      {!isNotMyNote && <ThreeDotsMenu isDisabled={isDisabledActionButton} onClickHandler={handleOpenPopover} />}
      <Stack spacing={1.5}>
        <MDTypography
          variant="button"
          sx={{
            fontWeight: 800,
            mr: 2.5,
            whiteSpace: 'initial',
            wordBreak: 'break-all',
          }}
        >
          {title}
        </MDTypography>
        <MDTypography
          variant="button"
          sx={{
            fontWeight: 400,
            whiteSpace: 'initial',
            wordBreak: 'break-all',
          }}
        >
          {description}
        </MDTypography>
        {isNotMyNote ? (
          <MDTypography variant="button" fontSize="14px">
            {createdDate}
          </MDTypography>
        ) : (
          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            alignItems={{ sm: 'flex-start', md: 'center' }}
          >
            <FormControlLabel
              control={<Checkbox readOnly disabled checked={visible} />}
              sx={{
                display: 'flex',
                flex: 1,
                mt: '0 !important',
                mr: '3px !important',
                ml: '-3px',
                '.MuiButtonBase-root': {
                  p: '4px',
                  ...(!isNotMyNote && {
                    '& svg': {
                      borderColor: 'grey.500',
                    },
                  }),
                },
              }}
              label={
                <MDTypography variant="button">{`Visible to the ${
                  isAttorneyView ? 'client' : 'attorney'
                }`}</MDTypography>
              }
            />
            <MDTypography variant="button" fontSize="14px">
              {createdDate}
            </MDTypography>
          </Stack>
        )}
      </Stack>

      <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="top-right">
        <PopoverMenuItem
          onClickHandler={onEditMenuItemClickHandler}
          title="Edit"
          icon={<ModeEditIcon fontSize="medium" />}
        />
        <PopoverMenuItem
          onClickHandler={onDeleteMenuItemClickHandler}
          title="Delete"
          icon={<DeleteOutlineIcon fontSize="medium" />}
        />
      </MenuPopover>
    </MDBox>
  );
};
