import { FC } from 'react';
import { Stack } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import MDBox from 'components/MDBox';
import { documentIconColors } from 'features/my-legal-cases/components/CreateLegalCase/Documents';
import { getFormattedFileSize } from 'features/common/helpers/utilities';
import { SupportedFileTypes } from 'features/my-legal-cases/components/CreateLegalCase/form.config';
import MDTypography from 'components/MDTypography';
import rgba from 'assets/theme/functions/rgba';
import { DocumentItemContentProps } from './types';

export const DocumentItemContent: FC<DocumentItemContentProps> = ({
  fileSize,
  fileType,
  ownerFirstName,
  ownerLastName,
  title,
}) => {
  const formattedFileSize = getFormattedFileSize(fileSize);
  return (
    <Stack direction="row" alignItems="center">
      <MDBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 1,
          backgroundColor: theme => rgba(theme.palette[documentIconColors[fileType]].main, 0.2),
          borderRadius: '5px',
          width: { xs: 35, sm: 40 },
          height: { xs: 35, sm: 40 },
        }}
      >
        <DescriptionOutlinedIcon
          fontSize="medium"
          sx={{
            color: theme => theme.palette[documentIconColors[fileType as SupportedFileTypes]]?.main,
          }}
        />
      </MDBox>
      <Stack spacing={0.5} sx={{ flex: 1, ml: '16px !important' }}>
        <MDTypography variant="subtitle2" noWrap sx={{ whiteSpace: 'initial', wordBreak: 'break-all' }}>
          {title}
        </MDTypography>

        <MDTypography variant="button" color="text" sx={{ whiteSpace: 'initial', wordBreak: 'break-all' }}>
          {`${ownerFirstName} ${ownerLastName} - ${formattedFileSize}`}
        </MDTypography>
      </Stack>
    </Stack>
  );
};
