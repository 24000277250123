import { FC, useEffect, useRef, useState } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import { Loader } from 'features/common/components/Loader';
import { useIsOnScreen } from 'features/common/hooks/useIsOnScreen';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import { ListOfEventsItem } from './ListOfEventsItem';
import { ListOfEventsProps } from '../types';

export const ListOfEvents: FC<ListOfEventsProps> = ({
  onDeleteEventIconClickHandler,
  onEditIconClickHandler,
  onClickConnectToCallButtonHandler,
  eventsByDate,
  isEventsByDateLoading,
  totalItems,
  caseId,
  selectedDayRange,
  userFirebaseId,
  getMoreEventsByDateHandler,
}) => {
  const [isFetchMoreLoading, setIsFetchMoreLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pages: Readonly<number> = Math.ceil(totalItems / 30);

  const lastElementRef = useRef<HTMLDivElement | null>(null);
  const isOnScreen = useIsOnScreen(isEventsByDateLoading ? null : lastElementRef);

  const onFetchMoreMyLegalCaseNotesHandler = async () => {
    setIsFetchMoreLoading(true);

    const nextPage = currentPage + 1;
    await getMoreEventsByDateHandler({
      legalCase: caseId,
      itemsPerPage: 30,
      page: nextPage,
      'startedAt[strictly_after]': selectedDayRange.start,
      'startedAt[strictly_before]': selectedDayRange.end,
    });
    setCurrentPage(nextPage);

    setIsFetchMoreLoading(false);
  };

  useEffect(() => {
    if (isOnScreen && currentPage < pages) {
      onFetchMoreMyLegalCaseNotesHandler();
    }
  }, [isOnScreen]);

  return (
    <Stack spacing={2.5} flex={1} my={3} position="relative">
      {isEventsByDateLoading ? (
        <Loader />
      ) : eventsByDate.length ? (
        eventsByDate?.map((event, index) => {
          const eventOwnerFirebaseId = event?.participants?.[0]?.user?.firebaseUser;
          const isCurrentUserEventOwner = eventOwnerFirebaseId === userFirebaseId;

          const firstName = event?.participants?.[isCurrentUserEventOwner ? 1 : 0].user?.userProfile?.firstName;
          const lastName = event?.participants?.[isCurrentUserEventOwner ? 1 : 0].user?.userProfile?.lastName;
          const logoURL = event?.participants?.[isCurrentUserEventOwner ? 1 : 0].user?.logo?.publicUrl;
          return (
            <ListOfEventsItem
              key={event?.id}
              firstName={firstName}
              lastName={lastName}
              caseNumber={event?.legalCase?.caseNumber}
              eventStartTime={event?.startedAt}
              eventEndTime={event?.expiredAt}
              note={event?.note}
              eventId={event?.id}
              onDeleteEventIconClickHandler={onDeleteEventIconClickHandler}
              onEditIconClickHandler={onEditIconClickHandler}
              eventType={event?.type}
              isEventOwner={isCurrentUserEventOwner}
              lastElementRef={index === eventsByDate.length - 1 ? lastElementRef : null}
              onClickConnectToCallButtonHandler={onClickConnectToCallButtonHandler}
              logoURL={logoURL}
            />
          );
        })
      ) : (
        <Stack justifyContent="center" flex={1}>
          <MDTypography sx={{ mx: 'auto' }}>No events were found...</MDTypography>
        </Stack>
      )}
      {isFetchMoreLoading && (
        <MDBox sx={{ position: 'absolute', bottom: 15, left: '50%', transform: 'translateX(-50%)' }}>
          <CircularProgress />
        </MDBox>
      )}
    </Stack>
  );
};
