import { useCallback, useEffect, useState } from 'react';
import { CaseLawTypeResponse, createCaseLawTypesService } from 'features/case-law-types';
import { ToastType, notice } from '../index';

export const useCaseLawTypes = (isImmediatelyCall: boolean = true) => {
  const { getCaseLawTypes, createCaseLawTypeSuggest } = createCaseLawTypesService();
  const [isCaseLawTypesLoading, setIsCaseLawTypesLoading] = useState(isImmediatelyCall ? true : false);

  const [caseLawTypesList, setCaseLawTypesList] = useState<CaseLawTypeResponse[]>([]);

  const getCaseLawTypesHandler = useCallback(async () => {
    try {
      const { data } = await getCaseLawTypes({});

      setCaseLawTypesList(data['hydra:member']);
      setIsCaseLawTypesLoading(false);
    } catch (error) {
      setIsCaseLawTypesLoading(false);
      console.error(error);
    }
  }, []);

  const createCaseLawTypeSuggestHandler = async (title: string) => {
    try {
      setIsCaseLawTypesLoading(true);
      await createCaseLawTypeSuggest({ title });
      setIsCaseLawTypesLoading(false);
      notice(
        ToastType.SUCCESS,
        'Suggestion created for this case law type. It will be added to your profile if administrator approve it.'
      );
    } catch (error) {
      setIsCaseLawTypesLoading(false);
      notice(ToastType.ERROR, 'Something went wrong with creating suggest for this case law type');
    }
  };

  useEffect(() => {
    if (isImmediatelyCall) getCaseLawTypesHandler();
  }, []);

  return {
    isCaseLawTypesLoading,
    caseLawTypesList,
    getCaseLawTypesHandler,
    createCaseLawTypeSuggestHandler,
  };
};
