import { FC, memo } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import { BaseFormField } from '../BaseFormField';
import { TemplateFieldProps } from './types';

export const TemplateField: FC<TemplateFieldProps> = memo(
  ({ isAutofill, index, error, label, register, list, type, isRequired, name, control }) => {
    return (
      <>
        {type === 'text' || type === 'numerical' ? (
          <BaseFormField
            formInputProps={{
              ...register(`fields.${index}.value` as const, {
                required: isAutofill ? false : { value: isRequired, message: 'This field is required' },
                ...(isRequired && { min: 0 }),
              }),
              type: type === 'numerical' ? 'number' : 'text',
              label: label || '',
              placeholder: name || '',
              fullWidth: true,
              disabled: isAutofill,
            }}
            errorValue={error}
          />
        ) : type === 'list' ? (
          <Controller
            control={control}
            rules={{
              required: isAutofill ? false : { value: isRequired, message: 'This field is required' },
            }}
            name={`fields.${index}.value` as const}
            render={({ field }) => {
              return (
                <Autocomplete
                  value={
                    field.value
                      ? list.find(option => {
                          return field.value === option?.value;
                        }) ?? null
                      : null
                  }
                  disabled={isAutofill}
                  onChange={(_, value) => field.onChange(value?.value ? value.value : null)}
                  getOptionLabel={option => option?.value}
                  isOptionEqualToValue={(option, value) => option?.['@id'] === value?.['@id']}
                  sx={{
                    '.MuiInputBase-root': {
                      py: '4.5px',
                    },
                  }}
                  disableClearable={isRequired}
                  freeSolo={false}
                  renderInput={params => (
                    <BaseFormField
                      formInputProps={{
                        ...params,
                        label: label || '',
                        placeholder: name || '',
                        fullWidth: true,
                      }}
                      errorValue={error}
                    />
                  )}
                  options={list}
                />
              );
            }}
          />
        ) : type === 'boolean' ? (
          <MDBox sx={{ display: 'flex', alignItems: 'center', pb: 2, position: 'relative' }}>
            <FormControlLabel
              sx={{
                display: 'flex',
                mt: '0 !important',
                mr: '3px !important',
                ml: '-3px',
                '.MuiButtonBase-root': {
                  p: '4px',
                },
              }}
              control={
                <Controller
                  name={`fields.${index}.value` as const}
                  rules={{
                    required: { value: isRequired, message: 'This field is required' },
                  }}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Checkbox
                        {...field}
                        checked={!!field.value?.length}
                        onChange={e => field.onChange(e.target.checked ? 'Yes' : '')}
                      />
                    );
                  }}
                />
              }
              label={<MDTypography variant="button">{label}</MDTypography>}
            />
            {error && (
              <FormHelperText error sx={{ fontSize: '10px', position: 'absolute', bottom: '1px' }}>
                * {error}
              </FormHelperText>
            )}
          </MDBox>
        ) : null}
      </>
    );
  }
);
