import { useState, useEffect, useCallback } from 'react';
import { usePrompt } from './usePrompt';
import { ToastType, notice, useRouter } from 'features/common';
import { AxiosError } from 'axios';
import { BackendError } from 'features/common/errorHanders';

type UseAudioVideoRoomStateProps = {
  eventId: string;
  isReady: boolean;
  isScheduledCall: boolean;
  backendUserFirebaseId: string;
  joinCall: (firebaseId?: string) => Promise<void>;
  startCall: () => Promise<void>;
  onCloseAgoraConnectionHandler: () => Promise<void>;
  caseId: string;
  endParticipantFirebaseId: string;
};

export const useAudioVideoRoomState = ({
  backendUserFirebaseId,
  isScheduledCall,
  eventId,
  isReady,
  caseId,
  endParticipantFirebaseId,
  joinCall,
  startCall,
  onCloseAgoraConnectionHandler,
}: UseAudioVideoRoomStateProps) => {
  const { navigate, state } = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  const isInitiateCallPropsExist = eventId ? true : !!caseId && !!endParticipantFirebaseId;

  const onInitiateCreateCallHandler = async () => {
    try {
      setIsLoading(true);
      await startCall();
      setIsLoading(false);
    } catch (error) {
      const postAgoraEventError = error as AxiosError;
      console.error(error);

      setIsLoading(false);
      if (postAgoraEventError?.response?.status === 409) {
        await onCloseAgoraConnectionHandler();

        const backendError = postAgoraEventError?.response?.data as BackendError;
        notice(ToastType.ERROR, backendError?.['hydra:description']);
        navigate(state?.prevRoute, { replace: true });
        return;
      }
      notice(ToastType.ERROR, 'Something went wrong with call initialization.');
    }
  };

  const onInitiateJoinCallHandler = async () => {
    setIsLoading(true);
    await joinCall(isScheduledCall ? backendUserFirebaseId : null);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isReady && isInitiateCallPropsExist) {
      eventId ? onInitiateJoinCallHandler() : onInitiateCreateCallHandler();
    }
  }, [isReady]);

  useEffect(() => {
    if (!isInitiateCallPropsExist) {
      onCloseAgoraConnectionHandler();
      notice(ToastType.WARNING, 'This room no longer exist!');
      navigate(state?.prevRoute, { replace: true });
    }
  }, []);

  useEffect(() => {
    if (isInitiateCallPropsExist && isReady) {
      const listener = function (event: PopStateEvent) {
        window.history.pushState(null, document.title, window.location.href);
        window.alert('Attention!!! If you want to leave the page, please click on the leave conversation button!');
      };

      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', listener);

      return () => {
        window.removeEventListener('popstate', listener);
      };
    }
  }, [isReady]);

  const [isChatOpen, setIsChatOpen] = useState(false);

  const onClickChatIconButtonHandler = useCallback(() => setIsChatOpen(prevState => !prevState), []);

  usePrompt(
    'Attention!!! Are you sure you want to leave the page, your call will be finished?',
    isInitiateCallPropsExist && isReady
  );

  return { onClickChatIconButtonHandler, isChatOpen, isLoading };
};
