import { EditOutlined, Clear } from '@mui/icons-material';
import { Rating, Stack, Tooltip } from '@mui/material';
import MDButton from 'components/MDButton';
import { Column } from 'react-table';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import { getAvatarCharacters } from 'features/common/helpers/utilities';
import MDBox from 'components/MDBox';
import { COMPANY_ROLES } from 'features/company-list';

export type UserData = {
  src: string;
  firstName: string;
  lastName: string;
};

const COMPANY_ROLES_RECORD: Record<COMPANY_ROLES, string> = {
  ROLE_COMPANY_ATTORNEY: 'Company attorney',
  ROLE_COMPANY_ADMIN: 'Company admin',
};

type CompanyUsersTableColumn = Column<Partial<ColumnData>>;

type ColumnData = {
  rating: number;
  caseCount: number;
  role: COMPANY_ROLES;
  userData: UserData;
  actionBtns: {
    id: string;
    isAllowToDeleteAttorney: boolean;
    isAllowToPromoteAdmin: boolean;
    role: COMPANY_ROLES;
    onDeleteIconClickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onEditIconClickHandler: (role: COMPANY_ROLES, userId: string) => void;
  };
};

export const COMPANY_USERS_TABLE_COLUMNS: CompanyUsersTableColumn[] = [
  {
    Header: 'User',
    accessor: 'userData',
    align: 'left',
    Cell: ({ value }) => {
      const avatarCharacters = getAvatarCharacters(value?.firstName, value?.lastName);
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <MDAvatar src={value?.src || ''} alt="user avatar" bgColor="info">
            {avatarCharacters}
          </MDAvatar>
          <Stack spacing={1}>
            <MDTypography variant="button" fontWeight="medium">
              {`${value?.firstName} ${value?.lastName}`}
            </MDTypography>
          </Stack>
        </Stack>
      );
    },
  },
  {
    Header: 'Role',
    accessor: 'role',
    align: 'left',
    Cell: ({ value }) => {
      const role = COMPANY_ROLES_RECORD[value];
      return <MDTypography variant="button">{role}</MDTypography>;
    },
  },

  {
    Header: 'Rating',
    accessor: 'rating',
    align: 'left',
    Cell: ({ value }) => (
      <Rating value={value} precision={0.1} readOnly sx={{ color: theme => theme.palette.grey[700] }} />
    ),
  },
  {
    Header: 'Case Amount',
    accessor: 'caseCount',
    align: 'left',
    Cell: ({ value }) => {
      return <MDTypography variant="button">{value}</MDTypography>;
    },
  },

  {
    Header: 'Actions',
    accessor: 'actionBtns',
    Cell: ({ value }) => {
      const onEditButtonClickHandler = () => value.onEditIconClickHandler(value?.role, value?.id);
      return (
        <MDBox sx={{ display: 'flex', gap: '5px', width: '100%', justifyContent: 'flex-start' }}>
          <Tooltip title="Edit attorney">
            <MDBox>
              <MDButton
                disabled={!value?.isAllowToPromoteAdmin}
                color="secondary"
                size="small"
                variant="outlined"
                iconOnly
                id={value?.role}
                onClick={onEditButtonClickHandler}
              >
                <EditOutlined />
              </MDButton>
            </MDBox>
          </Tooltip>

          <Tooltip title="Delete attorney">
            <MDBox>
              <MDButton
                disabled={!value?.isAllowToDeleteAttorney}
                color="secondary"
                size="small"
                variant="outlined"
                iconOnly
                id={value?.id}
                onClick={value.onDeleteIconClickHandler}
              >
                <Clear />
              </MDButton>
            </MDBox>
          </Tooltip>
        </MDBox>
      );
    },
  },
];
