import { useEffect, useState } from 'react';
import { usePagination } from 'features/common/hooks/usePagination';
import { ToastType, notice } from '../../common';
import { CompanyResponse, GetCompanyParams, createCompanyService } from '../services';
import { abortRequestErrorHandler } from 'features/common/errorHanders';

export const useCompanyList = (isActive?: boolean) => {
  const { getCompanies, createCompany, patchCompanyById, deleteCompanyById, getCompanyById, patchCompanyByIdActive } =
    createCompanyService();

  const [companiesList, setCompaniesList] = useState<ReadonlyArray<CompanyResponse>>([]);
  const [companiesTotal, setCompaniesTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { currentPage, entriesPerPage, setCurrentPage, onChangeEntriesPerPageHandler, onDeleteItem } = usePagination({
    itemsLength: companiesList?.length,
    totalItems: companiesTotal,
  });

  const getItemsHandler = async (params?: GetCompanyParams) => {
    try {
      const { data } = await getCompanies({
        page: currentPage,
        itemsPerPage: entriesPerPage,
        active: isActive || false,
        ...params,
      });

      setCompaniesTotal(data['hydra:totalItems']);
      setCompaniesList(data['hydra:member']);
    } catch (error) {
      abortRequestErrorHandler(error);
    }
  };

  const loadCompaniesHandler = async (page: number = currentPage) => {
    setIsLoading(true);
    await getItemsHandler({ page });
    setIsLoading(false);
  };

  useEffect(() => {
    loadCompaniesHandler();
  }, [entriesPerPage]);

  const onChangePageHandler = async (page: number) => {
    setCurrentPage(page);
    await loadCompaniesHandler(page);
  };

  return {
    companiesTotal,
    companiesList,
    isLoading,
    entriesPerPage,
    currentPage,
    setIsLoading,
    onChangePageHandler,
    getItemsHandler,
    onDeleteItem,
    onChangeEntriesPerPageHandler,
    loadCompaniesHandler,
    createCompany,
    patchCompanyById,
    deleteCompanyById,
    getCompanyById,
    patchCompanyByIdActive,
  };
};
