import { FC } from 'react';
import { Divider, Radio, Stack } from '@mui/material';
import MDTypography from 'components/MDTypography';
import ClioLogo from 'assets/images/icons/logo-clio.svg';
import MDAvatar from 'components/MDAvatar';

type CRMCaseItemProps = {
  provider?: string;
  caseNumber: string;
  displayName: string;
  id: string;
  isChecked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export const CRMCaseItem: FC<CRMCaseItemProps> = ({
  onChange,
  isChecked,
  caseNumber,
  displayName,
  id,
  provider = 'clio',
}) => {
  return (
    <Stack>
      <label>
        <Stack
          direction="row"
          alignItems="center"
          p={1}
          sx={{
            borderRadius: '10px',
            ':hover': {
              cursor: 'pointer',
              backgroundColor: ({ palette }) => palette.grey[200],
            },
          }}
        >
          <MDAvatar src={ClioLogo} variant="rounded" alt="Clio logo" sx={{ width: 30, height: 30, mr: 1 }} />
          <Stack flex={1}>
            <MDTypography variant="button" sx={{ fontWeight: 500 }}>
              {caseNumber}
            </MDTypography>

            <MDTypography variant="button">{displayName}</MDTypography>
          </Stack>
          <Radio checked={isChecked} onChange={onChange} value={id} />
        </Stack>
      </label>
      <Divider orientation="horizontal" sx={{ my: 1 }} />
    </Stack>
  );
};
