import { FC } from 'react';
import { Stack } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

export type RequestButtonsContentProps = {
  isApproving: boolean;
  isRejecting: boolean;
  onApproveButtonClickHandler: () => Promise<void>;
  onDeclineButtonClickHandler: () => Promise<void>;
  title: string;
};

export const RequestButtonsContent: FC<RequestButtonsContentProps> = ({
  onApproveButtonClickHandler,
  onDeclineButtonClickHandler,
  isApproving,
  isRejecting,
  title,
}) => {
  return (
    <Stack spacing={1} px={1}>
      <MDTypography variant="subtitle2" fontWeight="medium" sx={{ px: 1, textAlign: 'center' }}>
        {title}
      </MDTypography>

      <MDBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          width: 1,
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        <MDButton
          variant="outlined"
          color="success"
          type="button"
          fullWidth={false}
          disabled={isApproving}
          isLoading={isApproving}
          sx={{ textTransform: 'none', fontSize: 14 }}
          onClick={onApproveButtonClickHandler}
        >
          Accept
        </MDButton>
        <MDButton
          variant="outlined"
          color="info"
          type="button"
          isLoading={isRejecting}
          disabled={isRejecting}
          fullWidth={false}
          sx={{ textTransform: 'none', fontSize: 14 }}
          onClick={onDeclineButtonClickHandler}
        >
          Decline
        </MDButton>
      </MDBox>
    </Stack>
  );
};
