import { Pagination } from 'features/case-law-types';
import { BaseData, ListOfItems, backendApiClient } from 'features/common';

const BASE_CONFIG_API_URL: Readonly<string> = '/configs';

type BaseAgoraTags = 'ttl' | 'seconds' | 'agora';
type AgoraVideoCallTags = BaseAgoraTags | 'video';
type AgoraVoiceCallTags = BaseAgoraTags | 'voice';
type ClientInvitationTags = 'ttl' | 'hours';
type MailTags = 'mail' | 'template';

export type ConfigItemId =
  | 'mail_sender_email'
  | 'mail_sender_name'
  | 'mail_sender_email_chat_reminder_seconds'
  | 'client_invitation_expired_hours'
  | 'agora_voice_call_time_seconds'
  | 'agora_video_call_time_seconds'
  | 'contact_us_email'
  | 'security_pin_lock_expired_seconds'
  | MailTemplateItemId;

export type MailTemplateItemId =
  | 'mail_tpl_contact_us'
  | 'mail_tpl_welcome_attorney'
  | 'mail_tpl_welcome_client'
  | 'mail_admin_invitation'
  | 'mail_tpl_chat'
  | 'mail_tpl_call_reminder'
  | 'mail_tpl_case_status'
  | 'mail_tpl_case_updates'
  | 'mail_tpl_missed_call'
  | 'mail_back_to_queue_request'
  | 'mail_back_to_queue_confirm'
  | 'mail_back_to_queue_decline'
  | 'mail_tpl_event_create'
  | 'mail_agora_event_status'
  | 'mail_agora_event_updates'
  | 'mail_agora_event_delete'
  | 'mail_legal_case_offer_create'
  | 'mail_legal_case_offer_approve'
  | 'mail_legal_case_offer_decline'
  | 'mail_legal_case_offer_pending'
  | 'mail_tpl_case_create_attorney_notification'
  | 'mail_tpl_case_create'
  | 'mail_representation_decline'
  | 'mail_representation_create'
  | 'mail_representation_approve'
  | 'mail_consultation_delete_by_client'
  | 'mail_consultation_delete_by_attorney'
  | 'mail_consultation_decline'
  | 'mail_consultation_create'
  | 'mail_consultation_approve'
  | 'mail_client_invitation'
  | 'mail_back_to_queue';

export type ConfigTags = MailTags | AgoraVideoCallTags | AgoraVoiceCallTags | ClientInvitationTags;

export type GetConfigsResponse = BaseData<'Config'> & {
  id: ConfigItemId;
  anonymousAccess: boolean;
  configValue: string;
  tags: ConfigTags[];
  userAccess: boolean;
};

export type GetEmailTemplatesResponse = BaseData<'MailgunTemplateDto'> & {
  description: string;
  name: string;
};

export type GetConfigsParams = Pagination &
  Partial<{
    anonymousAccess: boolean;
    'anonymousAccess[]': boolean[];
    userAccess: boolean;
    'userAccess[]': boolean[];
    tags: string;
  }>;

export type PatchConfigPayload = Partial<Omit<GetConfigsResponse, '@id' | '@type' | 'id'>>;

export const settingsApiService = () => {
  const getConfigs = async (params?: GetConfigsParams) => {
    const data = await backendApiClient({}).get<ListOfItems<GetConfigsResponse[]>>(BASE_CONFIG_API_URL, { params });
    return data;
  };

  const getConfigById = async (id: string) =>
    await backendApiClient({}).get<GetConfigsResponse>(`${BASE_CONFIG_API_URL}/${id}`);

  const patchConfig = async (id: string, patchPayload: PatchConfigPayload) => {
    const data = await backendApiClient({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch<GetConfigsResponse>(`${BASE_CONFIG_API_URL}/${id}`, patchPayload);
    return data;
  };

  const getEmailTemplates = async (params: Pagination) =>
    await backendApiClient({}).get<ListOfItems<GetEmailTemplatesResponse[]>>(`${BASE_CONFIG_API_URL}/mail/templates`, {
      params,
    });

  return {
    getEmailTemplates,
    getConfigs,
    patchConfig,
    getConfigById,
  };
};
