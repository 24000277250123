import { FC } from 'react';
import Favicon from 'react-favicon';
import { useAppStatisticContextState } from 'features/contexts/AppStatisticsContextProvider';

export const BaseFavicon: FC = () => {
  const { userStatistic } = useAppStatisticContextState();

  return (
    <Favicon
      alertCount={userStatistic?.applicationBadge || null}
      alertFillColor="red"
      alertTextColor="white"
      url="/favicon.ico"
      iconSize={16}
    />
  );
};
