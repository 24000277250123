import { FC } from 'react';
import { Stack } from '@mui/material';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';

type ConnectClioProps = {
  onClickConnectClioHandler: () => Promise<void>;
};

export const ConnectClio: FC<ConnectClioProps> = ({ onClickConnectClioHandler }) => {
  return (
    <Stack
      spacing={2}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 1,
      }}
    >
      <MDTypography sx={{ textAlign: 'center' }}>You have not connected Clio to your account yet. </MDTypography>

      <MDBox justifyContent="center" display="flex">
        <MDButton variant="gradient" color="info" onClick={onClickConnectClioHandler}>
          Connect
        </MDButton>
      </MDBox>
    </Stack>
  );
};
