import { FC, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { baseFirebaseAuthErrorHandler, createFirebaseService } from 'features/auth';
import { ToastType, notice, useRouter } from 'features/common';
import { useDeviceDetection } from 'features/common/hooks/useDeviceDetection';
import { ROUTES } from 'features/common/variables/routes';
import { MOBILE_APP_DEEP_LINK } from 'features/reset-password';
import { FirebaseError } from 'firebase/app';
import { setLayout, useMaterialUIController } from 'context';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

export const VerifyEmailTemplate: FC = () => {
  const { confirmEmail } = createFirebaseService();

  const [controller, dispatch] = useMaterialUIController();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setLayout(dispatch, 'auth');
  }, [dispatch]);

  const device = useDeviceDetection();

  const router = useRouter();
  const [searchParams, _] = useSearchParams();

  const vefifyEmailHandler = async () => {
    try {
      const oobCode = searchParams.get('oobCode');

      if (oobCode) {
        setIsLoading(true);
        await confirmEmail(oobCode);

        setIsLoading(false);
        handleDeviceNavigation();
        notice(ToastType.SUCCESS, 'Email successfully verified!');
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (error instanceof FirebaseError) {
        baseFirebaseAuthErrorHandler(error);
        handleDeviceNavigation();
        return;
      } else {
        notice(ToastType.ERROR, 'Something went wrong, please try again!');
      }
    }
  };

  const handleDeviceNavigation = () =>
    setTimeout(async () => {
      await new Promise(res => {
        res(router.navigate(ROUTES.signIn));
      }).then(() => {
        if (device === 'Mobile') window.location.href = MOBILE_APP_DEEP_LINK;
      });
    }, 20);

  return (
    <Stack sx={{ width: 1, height: '100vh' }}>
      <Stack justifyContent="center" alignItems="center" height={1} spacing={1} px={1}>
        <MDTypography variant="h3" textAlign="center">
          Verify your email address
        </MDTypography>
        <MDTypography variant="h4" textAlign="center" color="secondary">
          Thanks for signing up. Please click the button below to verify your email address.
        </MDTypography>
        <MDButton isLoading={isLoading} onClick={vefifyEmailHandler} variant="gradient" color="info">
          Verify
        </MDButton>
      </Stack>
    </Stack>
  );
};
