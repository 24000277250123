import { FC, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { useCopyToClipboard } from 'features/common/hooks';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';
import { GetUserByIdResponse, createBackendUserService } from 'features/common/services';
import { ToastType, notice } from 'features/common/modules';
import { AxiosError } from 'axios';
import {
  FileSignTemplateField,
  SendToSignEnvelopeTemplatePayload,
  TemplateToSignFieldValue,
  fileSignApiService,
} from 'features/common/services/fileSignApiService';
import { Loader } from '../Loader';
import { TemplateField } from './TemplateField';
import { UserProfileBox } from './UserProfileBox';
import { FillingTemplateSideMenuContentProps, FormValues } from './types';

export const FillingTemplateSideMenuContent: FC<FillingTemplateSideMenuContentProps> = ({
  templateId,
  remoteParticipantFirebaseId,
  closeMenuHandler,
  legalCaseId,
  fetchLegalCaseDocuments,
  selectedFileSignProvider,
}) => {
  const { getSignProviderTemplateFields, sendEnvelopeFileToSign } = fileSignApiService();

  const { copy } = useCopyToClipboard();
  const { getUserById } = createBackendUserService();
  const [templateFields, setTemplateFields] = useState<FileSignTemplateField[]>([]);
  const [userProfile, setUserProfile] = useState<GetUserByIdResponse>(null);
  const [isLoading, setIsLoading] = useState(true);

  const onGetTemplateFieldsHandler = async () => {
    try {
      const { data: userData } = await getUserById(remoteParticipantFirebaseId || '');
      const { data } = await getSignProviderTemplateFields(selectedFileSignProvider, templateId);

      setUserProfile(userData);
      setTemplateFields(data['hydra:member']);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      paymentRequiredErrorHandler(error);
    }
  };

  useEffect(() => {
    onGetTemplateFieldsHandler();
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      fields: [],
    },
    values: { fields: templateFields },
  });

  const { fields } = useFieldArray({
    control,
    name: 'fields',
  });

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      const fields: TemplateToSignFieldValue[] = formData.fields.map(field => ({
        documentNumber: field?.documentNumber,
        id: field?.id,
        value: field?.value,
      }));

      const payload: SendToSignEnvelopeTemplatePayload = {
        fields,
        templateId,
        participant: `/users/${remoteParticipantFirebaseId}`,
        object: legalCaseId,
      };

      await sendEnvelopeFileToSign(selectedFileSignProvider, payload);

      if (fetchLegalCaseDocuments) {
        await fetchLegalCaseDocuments();
      }

      closeMenuHandler();
      notice(ToastType.SUCCESS, 'Template has been successfully sent!');
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        if (error.response.status === 422) {
          notice(ToastType.ERROR, error?.response.data?.['hydra:description']);
          return;
        }
        paymentRequiredErrorHandler(error);
      }
    }
  });

  const isButtonDisabled = isSubmitting || !isValid;

  return (
    <Stack
      component="form"
      onSubmit={onFormSubmitHandler}
      spacing={1}
      sx={{
        mt: 3,
        pb: 1.5,
        height: 1,
        position: 'relative',
      }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Stack height={1} spacing={2.5} flex={1}>
            {userProfile && (
              <UserProfileBox
                firstName={userProfile?.userProfile?.firstName}
                lastName={userProfile?.userProfile?.lastName}
                email={userProfile?.email}
                phoneNumber={userProfile?.phone}
                logoURL={userProfile?.logo?.publicUrl}
                onCopyHandler={copy}
              />
            )}

            {!isLoading && !templateFields?.length ? (
              <Stack flex={1} justifyContent="center" alignItems="center" minHeight={200}>
                <MDTypography>No variables were found...</MDTypography>
              </Stack>
            ) : (
              <Stack spacing={1.5}>
                <MDTypography variant="button" fontSize="medium" fontWeight="medium">
                  Template variables
                </MDTypography>
                <Stack
                  sx={{
                    maxHeight: `calc(100vh - 340px)`,
                    overflowY: 'auto',
                    pt: 1,
                    pr: 0.3,
                  }}
                >
                  {fields.map((field, index) => (
                    <TemplateField
                      register={register}
                      error={errors?.fields?.[index]?.value?.message || ''}
                      index={index}
                      key={field?.id}
                      isRequired={field?.required}
                      list={field?.list}
                      name={field?.name}
                      type={field?.type}
                      label={field?.label}
                      isAutofill={field?.autofill}
                      control={control}
                    />
                  ))}
                </Stack>
              </Stack>
            )}
          </Stack>
          {!isLoading && (
            <Stack spacing={2} pb={2} sx={{ position: 'absolute', bottom: 0, width: 1 }}>
              <MDButton
                isLoading={isSubmitting}
                disabled={isButtonDisabled}
                type="submit"
                variant="gradient"
                color="info"
              >
                Send to sign
              </MDButton>
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};
