import { User } from 'features/case-details/CaseDetailsApiService';
import { BaseData, backendApiClient } from 'features/common';

const BASE_REQUEST_API_URL: Readonly<string> = '/legal_case_consultations';

export type Consultation = BaseData<'Consultation'> & {
  id: string;
  legalCase: string;
  owner: User;
  createdAt: string;
};

export const legalCaseConsultationApiService = () => {
  const createConsultation = async (caseId: string) => {
    return await backendApiClient({}).post<Consultation>(BASE_REQUEST_API_URL, {
      legalCase: caseId,
    });
  };

  const deleteConsultation = async (id: string) => await backendApiClient({}).delete(`${BASE_REQUEST_API_URL}/${id}`);

  const approveConsultation = async (id: string) =>
    await backendApiClient({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch<Consultation>(`${BASE_REQUEST_API_URL}/${id}/approve`, {});

  const declineConsultation = async (caseId: string) => {
    return await backendApiClient({}).post<Consultation>(`/legal_cases/${caseId}/consultation_decline`, {});
  };

  return {
    declineConsultation,
    createConsultation,
    deleteConsultation,
    approveConsultation,
  };
};
