import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CircularProgress, Stack } from '@mui/material';
import { createBackendAuthService } from 'features/auth';
import { ToastType, notice, useRouter, useUser } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';

export const ClioCallBackController = () => {
  const { connectBackendUserHandler } = useUser();
  const [searchParams, _] = useSearchParams();
  const router = useRouter();
  const { connectClioToProfile } = createBackendAuthService();

  const connectClio = async () => {
    const code = searchParams.get('code');

    if (!code) {
      router.back();
      return;
    }

    try {
      await connectClioToProfile(code);
      notice(ToastType.SUCCESS, 'Successfully connected Clio to your account');
    } catch (error) {
      console.error({ error });
      notice(ToastType.ERROR, 'Something went wrong with Clio connection');
    }
    router.push(ROUTES.integrations).then(() => {
      connectBackendUserHandler();
    });
  };

  useEffect(() => {
    connectClio();
  }, []);

  return (
    <Stack sx={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </Stack>
  );
};
