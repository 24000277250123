import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { CheckCircleOutlineOutlined } from '@mui/icons-material';
import { ToastType, notice, useRouter, useUser } from 'features/common';
import { Loader } from 'features/common/components/Loader';
import { ROUTES } from 'features/common/variables/routes';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { useAuth } from 'features/store';
import MDTypography from 'components/MDTypography';
import { setLayout, useMaterialUIController } from 'context';
import { subscriptionsApiService } from '../../services/subscriptionsApiService';

export const SuccessPaymentTemplate: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isPromocodeUsed, setIsPromocodeUsed] = useState(false);

  const [searchParams] = useSearchParams();

  const { navigate } = useRouter();

  const sessionId = searchParams.get('session_id');

  const setStateData = useAuth(state => state.setStateData);

  const { connectBackendUserHandler } = useUser();

  const [_, dispatch] = useMaterialUIController();

  useEffect(() => {
    setLayout(dispatch, 'auth');
  }, [dispatch]);

  const { fetchStripe } = subscriptionsApiService();

  const handleSuccess = async () => {
    try {
      if (sessionId) {
        const { data } = await fetchStripe({
          fetchToken: sessionId,
        });
        await connectBackendUserHandler();
        data?.usedPromoCode && setIsPromocodeUsed(true);
        setStateData('isNavigationBlocked', false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong!');
      navigate(ROUTES.myLegalCases);
    }
  };

  useEffect(() => {
    handleSuccess();
  }, []);

  const onClickButtonHandler = () => navigate(ROUTES.myLegalCases);

  return (
    <MDBox
      component="section"
      sx={{
        width: 1,
        height: '100vh',
        p: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Card sx={{ minWidth: 350 }}>
          <CardHeader
            sx={{ textAlign: 'center' }}
            title={
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
                <MDTypography variant="h5" fontWeight={600} textAlign="center">
                  Payment successful!
                </MDTypography>
                <CheckCircleOutlineOutlined
                  sx={{
                    fontSize: '2.5rem !important',
                  }}
                  color="success"
                />
              </Stack>
            }
          />
          <CardContent>
            <Stack alignItems="center" justifyContent="center" spacing={3} textAlign="center">
              {isPromocodeUsed && (
                <Stack spacing={0.5}>
                  <MDTypography variant="h5">Thank you for taking advantage of our promotional offer.</MDTypography>
                  <MDTypography variant="body1">
                    Please be aware that after the promotion period ends, you will be automatically billed the full
                    amount of your subscription price on a monthly basis, unless you cancel your subscription before the
                    end of the promotional period. If you have any questions or concerns regarding your subscription,
                    feel free to reach out to our customer support team at info@legalbeagle.net. We appreciate your
                    continued support.
                  </MDTypography>
                </Stack>
              )}
              <MDButton variant="gradient" color="info" onClick={onClickButtonHandler}>
                Confirm
              </MDButton>
            </Stack>
          </CardContent>
        </Card>
      )}
    </MDBox>
  );
};
