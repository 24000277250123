import { GetEmailTemplatesResponse, MailTemplateItemId } from './SettingsApiService';
import { ConfigMailTemplatesInitialState } from './types';

const BASE_DESCRIPTION_STRING = 'Select template, which will be sent to';

export type GetEmailTemplatesFieldsProps = {
  configMailTemplatesState: ConfigMailTemplatesInitialState;
};

export const getEmailTemplatesFields = ({
  configMailTemplatesState,
}: GetEmailTemplatesFieldsProps): Array<{
  description: string;
  label: string;
  value: GetEmailTemplatesResponse;
  id: MailTemplateItemId;
}> => {
  return [
    {
      description: `${BASE_DESCRIPTION_STRING} the new user with Attorney role`,
      label: 'Welcome attorney email template',
      value: configMailTemplatesState?.['mail_tpl_welcome_attorney'],
      id: 'mail_tpl_welcome_attorney',
    },
    {
      description: `${BASE_DESCRIPTION_STRING} the new user with Client role`,
      label: 'Welcome client email template',
      value: configMailTemplatesState?.['mail_tpl_welcome_client'],
      id: 'mail_tpl_welcome_client',
    },
    {
      description: `${BASE_DESCRIPTION_STRING} the invited new user`,
      label: 'Client invitation email template',
      value: configMailTemplatesState?.['mail_client_invitation'],
      id: 'mail_client_invitation',
    },

    {
      description: `${BASE_DESCRIPTION_STRING} the new user with Admin role`,
      label: 'Welcome admin email template',
      value: configMailTemplatesState?.['mail_admin_invitation'],
      id: 'mail_admin_invitation',
    },

    {
      description: `${BASE_DESCRIPTION_STRING} the user about new chat message`,
      label: 'New chat message email template',
      value: configMailTemplatesState?.['mail_tpl_chat'],
      id: 'mail_tpl_chat',
    },

    {
      description: `${BASE_DESCRIPTION_STRING} the user about scheduled call`,
      label: 'Call reminder email template',
      value: configMailTemplatesState?.['mail_tpl_call_reminder'],
      id: 'mail_tpl_call_reminder',
    },
    {
      description: `${BASE_DESCRIPTION_STRING} the user about case status update`,
      label: 'Case status update email template',
      value: configMailTemplatesState?.['mail_tpl_case_status'],
      id: 'mail_tpl_case_status',
    },

    {
      description: `${BASE_DESCRIPTION_STRING} the user about case details update`,
      label: 'Case details update email template',
      value: configMailTemplatesState?.['mail_tpl_case_updates'],
      id: 'mail_tpl_case_updates',
    },

    {
      description: `${BASE_DESCRIPTION_STRING} the user about missed call`,
      label: 'Missed call email template',
      value: configMailTemplatesState?.['mail_tpl_missed_call'],
      id: 'mail_tpl_missed_call',
    },
    {
      description: `${BASE_DESCRIPTION_STRING} the user about back case to queue`,
      label: 'Returning case to queue template',
      value: configMailTemplatesState?.['mail_back_to_queue'],
      id: 'mail_back_to_queue',
    },
    {
      description: `${BASE_DESCRIPTION_STRING} the user about back to queue request`,
      label: 'Back to queue request email template',
      value: configMailTemplatesState?.['mail_back_to_queue_request'],
      id: 'mail_back_to_queue_request',
    },
    {
      description: `${BASE_DESCRIPTION_STRING} the user when their back to queue request is confirmed`,
      label: 'Back to queue confirm email template',
      value: configMailTemplatesState?.['mail_back_to_queue_confirm'],
      id: 'mail_back_to_queue_confirm',
    },
    {
      description: `${BASE_DESCRIPTION_STRING} the user when their back to queue request is declined`,
      label: 'Back to queue decline email template',
      value: configMailTemplatesState?.['mail_back_to_queue_decline'],
      id: 'mail_back_to_queue_decline',
    },

    {
      description: `${BASE_DESCRIPTION_STRING} the user about new agora event`,
      label: 'New agora event email template',
      value: configMailTemplatesState?.['mail_tpl_event_create'],
      id: 'mail_tpl_event_create',
    },
    {
      description: `${BASE_DESCRIPTION_STRING} the user about agora event status update`,
      label: 'Agora event status update email template',
      value: configMailTemplatesState?.['mail_agora_event_status'],
      id: 'mail_agora_event_status',
    },
    {
      description: `${BASE_DESCRIPTION_STRING} the user about agora event details update`,
      label: 'Agora event details update email template',
      value: configMailTemplatesState?.['mail_agora_event_updates'],
      id: 'mail_agora_event_updates',
    },

    {
      description: `${BASE_DESCRIPTION_STRING} the user about agora event delation`,
      label: 'Delete agora event email template',
      value: configMailTemplatesState?.['mail_agora_event_delete'],
      id: 'mail_agora_event_delete',
    },
    {
      description: `${BASE_DESCRIPTION_STRING} the user when new case law type matched with user law types`,
      label: 'New legal case law type matched user`s law type template',
      value: configMailTemplatesState?.['mail_tpl_case_create_attorney_notification'],
      id: 'mail_tpl_case_create_attorney_notification',
    },
    {
      description: `${BASE_DESCRIPTION_STRING} the user when new case is created`,
      label: 'Create new case template',
      value: configMailTemplatesState?.['mail_tpl_case_create'],
      id: 'mail_tpl_case_create',
    },
    {
      description: `${BASE_DESCRIPTION_STRING} the user when their case representation is created`,
      label: 'New case representation request email template',
      value: configMailTemplatesState?.['mail_representation_create'],
      id: 'mail_representation_create',
    },
    {
      description: `${BASE_DESCRIPTION_STRING} the user when their case representation is approved`,
      label: 'Approve case representation email template',
      value: configMailTemplatesState?.['mail_representation_approve'],
      id: 'mail_representation_approve',
    },
    {
      description: `${BASE_DESCRIPTION_STRING} the user when their case representation is declined`,
      label: 'Decline case representation email template',
      value: configMailTemplatesState?.['mail_representation_decline'],
      id: 'mail_representation_decline',
    },
    {
      description: `${BASE_DESCRIPTION_STRING} the user when their case consultation request is created`,
      label: 'New case consultation request email template',
      value: configMailTemplatesState?.['mail_consultation_create'],
      id: 'mail_consultation_create',
    },
    {
      description: `${BASE_DESCRIPTION_STRING} the user when their case consultation is approved`,
      label: 'Approve case consultation email template',
      value: configMailTemplatesState?.['mail_consultation_approve'],
      id: 'mail_consultation_approve',
    },
    {
      description: `${BASE_DESCRIPTION_STRING} the user when their case consultation is declined`,
      label: 'Decline case consultation email template',
      value: configMailTemplatesState?.['mail_consultation_decline'],
      id: 'mail_consultation_decline',
    },
    {
      description: `${BASE_DESCRIPTION_STRING} the user when their case consultation is declined by client`,
      label: 'Decline case consultation by client email template',
      value: configMailTemplatesState?.['mail_consultation_delete_by_client'],
      id: 'mail_consultation_delete_by_client',
    },

    {
      description: `${BASE_DESCRIPTION_STRING} the user when sending contact us request`,
      label: 'Contact us email template',
      value: configMailTemplatesState?.['mail_tpl_contact_us'],
      id: 'mail_tpl_contact_us',
    },
  ];
};
