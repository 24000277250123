import { DescriptionOutlined } from '@mui/icons-material';
import rgba from 'assets/theme/functions/rgba';
import MDBox from 'components/MDBox';
import { documentIconColors } from 'features/my-legal-cases/components/CreateLegalCase/Documents';
import { SupportedFileTypes } from 'features/my-legal-cases/components/CreateLegalCase/form.config';
import { FC } from 'react';

type DocumentIconProps = {
  fileType: SupportedFileTypes;
};

export const DocumentIcon: FC<DocumentIconProps> = ({ fileType }) => {
  return (
    <MDBox
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 1,
        backgroundColor: theme => rgba(theme.palette[documentIconColors[fileType]].main, 0.2),
        borderRadius: '5px',
        width: { xs: 35, sm: 40 },
        height: { xs: 35, sm: 40 },
      }}
    >
      <DescriptionOutlined
        fontSize="medium"
        sx={{
          color: theme => theme.palette[documentIconColors[fileType as SupportedFileTypes]]?.main,
        }}
      />
    </MDBox>
  );
};
