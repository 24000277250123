import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Stack } from '@mui/material';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import { BaseFormField, ToastType, notice } from 'features/common';
import { myCompanyApiService } from '../services';
import {
  AddAttorneyToCompanySchema,
  ROLES_OPTIONS,
  RoleOption,
  addAttorneyToCompanyValidationSchema,
} from './form.config';

type AddUserModalContentProps = {
  onCloseMenuHandler: () => void;
};

export const AddUserModalContent: FC<AddUserModalContentProps> = ({ onCloseMenuHandler }) => {
  const { createInvitationToTheCompanyByEmail } = myCompanyApiService();

  const {
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    control,
    register,
    setError,
    setFocus,
  } = useForm<AddAttorneyToCompanySchema>({
    resolver: yupResolver(addAttorneyToCompanyValidationSchema),
    mode: 'onTouched',
    defaultValues: {
      role: 'ROLE_COMPANY_ATTORNEY',
    },
  });

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      await createInvitationToTheCompanyByEmail(formData.email, formData.role);
      notice(ToastType.SUCCESS, 'Successfully sent invitation!');
      onCloseMenuHandler();
    } catch (error: any) {
      if (error?.response?.status === 422) {
        setError('email', { message: `${error?.response?.data['hydra:description']}` });
        setFocus('email');
      } else {
        notice(ToastType.ERROR, 'Something went wrong, please try again!');
      }
      console.error(error);
    }
  });

  const isSubmitButtonDisabled = isSubmitting || !isValid;
  return (
    <MDBox
      component="form"
      onSubmit={onFormSubmitHandler}
      sx={{
        mt: 3,
        pb: 1.5,
        height: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack flex={1} spacing={2}>
        <BaseFormField
          formInputProps={{
            ...register('email'),
            type: 'text',
            label: 'Email',
            fullWidth: true,
          }}
          errorValue={errors['email']?.message}
        />

        <Controller
          name="role"
          control={control}
          render={({ field: { onChange, value } }) => {
            const onChangeHandler = (_: React.SyntheticEvent<Element, Event>, newValue: RoleOption) =>
              onChange(newValue?.value);

            return (
              <Autocomplete
                options={ROLES_OPTIONS}
                getOptionLabel={option => option.title}
                value={
                  value
                    ? ROLES_OPTIONS.find(option => {
                        return value === option.value;
                      }) ?? null
                    : null
                }
                isOptionEqualToValue={(option, value) => option.title === value.title}
                freeSolo={false}
                onChange={onChangeHandler}
                disableClearable
                sx={{
                  mt: '16px !important',
                  '.MuiInputBase-root': {
                    py: '4.5px',
                  },
                }}
                renderInput={params => (
                  <BaseFormField
                    formFieldProps={{
                      pb: 0,
                    }}
                    formInputProps={{
                      ...params,
                      label: 'Role',
                      InputLabelProps: { shrink: true },
                      fullWidth: true,
                    }}
                  />
                )}
              />
            );
          }}
        />
      </Stack>
      <MDButton
        disabled={isSubmitButtonDisabled}
        isLoading={isSubmitting}
        variant="gradient"
        color="info"
        type="submit"
      >
        Send invitation
      </MDButton>
    </MDBox>
  );
};
