import { Theme } from '@mui/material';

export const ComponentsStyles = {
  FooterButtonSX: {
    borderRadius: '50%',
    backgroundColor: ({ palette }: Theme) => `${palette.grey[800]} !important`,
    ':hover': {
      backgroundColor: ({ palette }: Theme) => `${palette.grey[700]} !important`,
    },
    '& svg': {
      width: 20,
      height: 20,
      color: 'white !important',
    },
  },
  ActionWrapperSX: {
    p: '12px',
    borderRadius: '8px 8px 0 0',
    backgroundColor: ({ palette }: Theme) => palette?.grey[800],
    opacity: 0.9,
  },
  ActionButtonsSX: {
    borderRadius: '50%',
    '& svg': {
      width: 20,
      height: 20,
    },
  },
};

export const getQualityIndicatorColor = (quality: number): (({ palette }: Theme) => string) => {
  if (quality === 1) {
    return ({ palette }) => palette.success.main;
  }
  if (quality === 2) {
    return ({ palette }) => palette.warning.main;
  }
  if (quality > 2 && quality < 4) {
    return ({ palette }) => palette.warning.dark;
  }
  if (quality >= 4) {
    return ({ palette }) => palette.error.main;
  }
};
