import { Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import { DashboardLayout } from 'features/common';
import { BaseSideNaveMenu } from 'features/common/components/BaseSideNaveMenu';
import { ROUTES } from 'features/common/variables/routes';
import { useAppStatisticContextState } from 'features/contexts/AppStatisticsContextProvider';
import { FC } from 'react';

type TemplateWrapperProps = {
  children: React.ReactNode;
};

export const TemplateWrapper: FC<TemplateWrapperProps> = ({ children }) => {
  const { adminStatistic } = useAppStatisticContextState();

  const sidenavItems = [
    { icon: 'work', label: 'case law types', href: ROUTES.caseLawTypes },
    {
      icon: 'work_history',
      label: 'suggestions',
      badgeContent: adminStatistic?.caseLawTypeWaitingForSuggestion || 0,
      href: ROUTES.caseLawTypesSuggestions,
    },
  ];

  return (
    <DashboardLayout>
      <MDBox mt={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <BaseSideNaveMenu sidenavItems={sidenavItems} />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>{children}</MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};
