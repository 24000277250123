import { FC } from 'react';
import { Card, Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

type ButtonsFooterProps = {
  isDiscardButtonDisabled: boolean;
  onClickDiscardButtonHandler: () => void;
  isSaveButtonDisabled: boolean;
  isLoading: boolean;
  saveButtonTitle?: string;
};

export const ButtonsFooter: FC<ButtonsFooterProps> = ({
  isDiscardButtonDisabled,
  isLoading,
  isSaveButtonDisabled,
  saveButtonTitle = 'Save changes',
  onClickDiscardButtonHandler,
}) => {
  return (
    <Grid item xs={12}>
      <Card sx={{ p: 2 }}>
        <MDBox
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: { xs: 1, sm: 2 },
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <MDButton
            variant="outlined"
            color="dark"
            type="button"
            fullWidth={false}
            disabled={isDiscardButtonDisabled}
            sx={{ textTransform: 'none' }}
            onClick={onClickDiscardButtonHandler}
          >
            Discard changes
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            type="submit"
            disabled={isSaveButtonDisabled}
            isLoading={isLoading}
            fullWidth={false}
            sx={{ textTransform: 'none' }}
          >
            {saveButtonTitle}
          </MDButton>
        </MDBox>
      </Card>
    </Grid>
  );
};
