import {
  reviewApiService,
  GetUserReviewsResponse,
  GetUserReviewParams,
  CreateReviewPayload,
} from 'features/review/services';
import { ToastType, notice } from 'features/common';
import { createStore } from '../createStore';
import { abortRequestErrorHandler } from 'features/common/errorHanders';

export type UserReviewsState = {
  userReviews: GetUserReviewsResponse[];
  totalItems: number | null;
  reviewedUserFirebaseId: string;
  isReviewModalOpen: boolean;
};

export type UserReviewsActions = {
  fetchUserReviewsHandler: (params: GetUserReviewParams) => Promise<void>;
  approveUserReviewHandler: (reviewId: string) => Promise<void>;
  deleteUserReviewHandler: (reviewId: string) => Promise<void>;
  createUserReviewHandler: (payload: CreateReviewPayload) => Promise<void>;
  setReviewedUserFirebaseId: (id: string) => void;
  setIsReviewModalOpen: (val: boolean) => void;
};

const { getUserReviews, deleteUserReview, patchUserReviewStatus, createUserReview } = reviewApiService();

const initialState: UserReviewsState = {
  userReviews: [],
  totalItems: null,
  reviewedUserFirebaseId: '',
  isReviewModalOpen: false,
};

export const useUserReviews = createStore<UserReviewsState & UserReviewsActions>(
  (set, get) => ({
    ...initialState,
    setIsReviewModalOpen: isReviewModalOpen => {
      set(state => ({
        ...state,
        isReviewModalOpen,
      }));
    },
    setReviewedUserFirebaseId: reviewedUserFirebaseId => {
      set(state => ({
        ...state,
        reviewedUserFirebaseId,
      }));
    },
    createUserReviewHandler: async payload => {
      try {
        await createUserReview(payload);
        get().setReviewedUserFirebaseId('');
        notice(ToastType.SUCCESS, 'Successfully sent the review!');
      } catch (error) {
        console.error(error);
        notice(ToastType.ERROR, 'Failed to send review, please try again!');
      }
    },
    approveUserReviewHandler: async reviewId => {
      try {
        const { data } = await patchUserReviewStatus(reviewId);
        set(state => ({
          totalItems: state.totalItems ? state.totalItems - 1 : state.totalItems,
          userReviews: state.userReviews.filter(review => review.id !== data.id),
        }));
        notice(ToastType.SUCCESS, 'Successfully approved the review!');
      } catch (error) {
        console.error(error);
        notice(ToastType.ERROR, 'Failed to approve review, please try again!');
      }
    },
    fetchUserReviewsHandler: async params => {
      try {
        const { data } = await getUserReviews(params);
        set({ userReviews: data['hydra:member'], totalItems: data['hydra:totalItems'] });
      } catch (error) {
        abortRequestErrorHandler(error, 'Failed to fetch reviews, please try again!');
      }
    },
    deleteUserReviewHandler: async reviewId => {
      try {
        await deleteUserReview(reviewId);
        set(state => ({
          totalItems: state.totalItems ? state.totalItems - 1 : state.totalItems,
          userReviews: state.userReviews.filter(review => review.id !== reviewId),
        }));
        notice(ToastType.SUCCESS, 'Successfully deleted the review!');
      } catch (error) {
        console.error(error);
        notice(ToastType.ERROR, 'Failed to delete review, please try again!');
      }
    },
  }),
  'userReviews',
  initialState
);
