import { useEffect, useState } from 'react';
import { ToastType, notice, useBoolean, useRouter } from 'features/common';
import { usePopover } from 'features/common/hooks/usePopover';
import { Pagination } from 'features/case-law-types';
import { ChangeLegalCaseStatusPayload } from '../CaseDetailsApiService';
import { AxiosError } from 'axios';
import { ROUTES } from 'features/common/variables/routes';

type UseCaseInfoStateTestProps = {
  caseId: string;
  isAttorney: boolean;
  changeLegalCaseStatusHandler: (payload: ChangeLegalCaseStatusPayload) => Promise<void>;
  fetchLegalCaseHandler: (caseId: string, isAttorney?: boolean) => Promise<void>;
  getConsultationsListHandler: (caseId: string, params?: Pagination) => Promise<void>;
};

export const useCaseInfoState = ({
  caseId,
  isAttorney,
  getConsultationsListHandler,
  fetchLegalCaseHandler,
  changeLegalCaseStatusHandler,
}: UseCaseInfoStateTestProps) => {
  const router = useRouter();
  const [isCaseLoading, setIsCaseLoading] = useState(true);

  useEffect(() => {
    !isCaseLoading && caseId && setIsCaseLoading(true);
  }, [caseId]);

  const onFetchCaseDetailsHandler = async () => {
    try {
      await fetchLegalCaseHandler(caseId, isAttorney);
      setIsCaseLoading(false);
    } catch (errorResponse: any) {
      const error = errorResponse as AxiosError;

      if (error.response.status === 403) {
        return router
          .push(ROUTES.myLegalCases)
          .then(() => notice(ToastType.ERROR, errorResponse?.response?.data?.['hydra:description']));
      }
      if (error.response.status === 404) {
        return router.push(ROUTES.myLegalCases).then(() => notice(ToastType.ERROR, 'This case does not exist!'));
      }
    }
  };

  const [isCaseOffersLoading, setIsCaseOffersLoading] = useState(false);
  const [isStatusUpdating, setIsStatusUpdating] = useState(false);

  const [isEditFormMenuOpen, openEditFormMenu, closeEditFormMenu] = useBoolean(false);
  const [isViewAllMenuOpen, openViewAllMenu, closeViewAllMenu] = useBoolean(false);

  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  const onViewAllButtonHandler = async () => {
    setIsCaseOffersLoading(true);
    openViewAllMenu();
    await getConsultationsListHandler(caseId, { page: 1, itemsPerPage: 30 });
    setIsCaseOffersLoading(false);
  };

  const onClickCaseStatusMenuItemHandler = async (event: React.MouseEvent<HTMLLIElement>) => {
    handleClosePopover();
    setIsStatusUpdating(true);
    await changeLegalCaseStatusHandler({ status: event.currentTarget.id });
    setIsStatusUpdating(false);
  };

  const [isConsultationsListLoading, setIsConsultationsListLoading] = useState(false);

  const onGetConsultationsListHandler = async () => {
    setIsConsultationsListLoading(true);
    await getConsultationsListHandler(caseId, { page: 1, itemsPerPage: 30 });
    setIsConsultationsListLoading(false);
  };

  return {
    isCaseLoading,
    isCaseOffersLoading,
    isStatusUpdating,
    isEditFormMenuOpen,
    isViewAllMenuOpen,
    openPopover,
    isConsultationsListLoading,
    handleClosePopover,
    onGetConsultationsListHandler,
    onClickCaseStatusMenuItemHandler,
    openEditFormMenu,
    closeEditFormMenu,
    handleOpenPopover,
    closeViewAllMenu,
    onViewAllButtonHandler,
    onFetchCaseDetailsHandler,
  };
};
