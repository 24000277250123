import { FC, PropsWithChildren, useEffect } from 'react';

// Material Dashboard 2 PRO React context
import { useRouter } from 'features/common';
import MDBox from 'components/MDBox';
import { setLayout, useMaterialUIController } from 'context';

type BasePageLayoutProps = PropsWithChildren<{
  background?: 'white' | 'light' | 'default';
}>;

export const BasePageLayout: FC<BasePageLayoutProps> = ({ background, children }) => {
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useRouter();

  useEffect(() => {
    setLayout(dispatch, 'page');
  }, [pathname, dispatch]);

  return (
    <>
      <MDBox
        // TODO: fix custom scroll and enable this prop
        // width="100vw"
        height="100%"
        minHeight="100vh"
        bgColor={background}
        sx={{ overflowX: 'hidden' }}
      >
        {children}
      </MDBox>
    </>
  );
};

// Setting default values for the props for BasePageLayout
BasePageLayout.defaultProps = {
  background: 'default',
};
