import { FC } from 'react';
import { Add } from '@mui/icons-material';
import MDBox from 'components/MDBox';

type AddDocumentActionButtonProps = {
  isDisabled?: boolean;
  onInputFileUploadHandler?: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
};

export const AddDocumentActionButton: FC<AddDocumentActionButtonProps> = ({ onInputFileUploadHandler, isDisabled }) => {
  return (
    <MDBox
      component="label"
      sx={{
        p: 1,
        display: 'flex',
        ...(!isDisabled &&
          onInputFileUploadHandler && {
            borderRadius: 5,
            cursor: 'pointer',
            ':hover': {
              backgroundColor: theme => theme.palette.grey[200],
            },
          }),
      }}
    >
      <Add fontSize="medium" color={!onInputFileUploadHandler || isDisabled ? 'disabled' : 'inherit'} />
      <input
        disabled={!onInputFileUploadHandler || isDisabled}
        onChange={onInputFileUploadHandler}
        type="file"
        hidden
        accept="image/*,audio/*,video/*,application/*,text/*"
      />
    </MDBox>
  );
};
