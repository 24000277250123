import { Pagination } from 'features/case-law-types';
import { BaseData, ListOfItems, backendApiClient } from 'features/common';

const BASE_URL = '/critical_update_versions';

export enum OS {
  IOS = 'ios',
  ANDROID = 'android',
  WEB = 'web',
  IPAD = 'ipad',
}

export type CriticalUpdateVersion = BaseData<''> & {
  id: string;
  os: OS;
  version: string;
  createdAt: string;
};

export type CreateCriticalUpdateVersionPayload = {
  os: OS;
  version: string;
};

export const urgentVersionsApiService = () => {
  const getCriticalUpdateVersions = async (params: Pagination) =>
    await backendApiClient({}).get<ListOfItems<CriticalUpdateVersion[]>>(BASE_URL, {
      params,
    });

  const getLastCriticalUpdateVersions = async () =>
    await backendApiClient({}).get<ListOfItems<CriticalUpdateVersion[]>>(`${BASE_URL}/last`);

  const createCriticalUpdateVersion = async (payload: CreateCriticalUpdateVersionPayload) =>
    await backendApiClient({}).post<CriticalUpdateVersion>(BASE_URL, payload);

  const deleteCriticalUpdateVersion = async (id: string) => await backendApiClient({}).delete(`${BASE_URL}/${id}`);

  return {
    getCriticalUpdateVersions,
    createCriticalUpdateVersion,
    getLastCriticalUpdateVersions,
    deleteCriticalUpdateVersion,
  };
};
