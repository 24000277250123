import { FC } from 'react';
import { Card } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

type ButtonsFooterProps = {
  isLoading: boolean;
  onGoBackHandler: () => void;
  onCreateCaseHandler: () => Promise<void>;
};
export const ButtonsFooter: FC<ButtonsFooterProps> = ({ isLoading, onCreateCaseHandler, onGoBackHandler }) => {
  return (
    <Card
      sx={{
        p: 2,
        mt: 2,
        boxShadow: theme => theme.boxShadows.colored.light,
        border: theme => `1px solid ${theme.borders.borderColor} `,
      }}
    >
      <MDBox
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2,
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        <MDButton
          variant="gradient"
          color="light"
          type="button"
          fullWidth={false}
          disabled={isLoading}
          sx={{ textTransform: 'none', fontSize: 14 }}
          onClick={onGoBackHandler}
        >
          Go back
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          type="button"
          isLoading={isLoading}
          disabled={isLoading}
          fullWidth={false}
          sx={{ textTransform: 'none', fontSize: 14 }}
          onClick={onCreateCaseHandler}
        >
          Create case
        </MDButton>
      </MDBox>
    </Card>
  );
};
