import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { GetWorkingHoursResponse, ToastType, createAttorneyService, notice, useUser } from 'features/common';
import { AttorneyProfileWorkingHoursSchema } from 'features/profile';
import { WorkingHoursFormField } from 'features/profile/components/AttorneyWorkingHoursForm/WorkingHoursFormField';
import {
  FormFieldName,
  WORKING_HOURS_FORM_FIELDS,
} from 'features/profile/components/AttorneyWorkingHoursForm/form.config';
import { useMyCompany } from 'features/store';

export const CompanyWorkingHoursTemplate: FC = () => {
  const { myCompany, fetchUserCompanyHandler } = useMyCompany();
  const [workingHours, setWorkingHours] = useState<GetWorkingHoursResponse | null>(myCompany.workingHours);

  const { patchWorkingHoursById } = createAttorneyService();

  const { backendUser } = useUser();

  useEffect(() => {
    if (myCompany && !workingHours) {
      setWorkingHours(myCompany.workingHours);
    }
  }, [myCompany]);

  const { register, control, formState, handleSubmit, setError, clearErrors, setValue, watch, trigger } =
    useForm<AttorneyProfileWorkingHoursSchema>({
      mode: 'onTouched',
      values: {
        isMonday: workingHours?.mondayEnabled,

        fromTimeMonday: workingHours?.mondayFrom,
        toTimeMonday: workingHours?.mondayTo,

        isTuesday: workingHours?.tuesdayEnabled,
        fromTimeTuesday: workingHours?.tuesdayFrom,
        toTimeTuesday: workingHours?.tuesdayTo,

        isWednesday: workingHours?.wednesdayEnabled,
        fromTimeWednesday: workingHours?.wednesdayFrom,
        toTimeWednesday: workingHours?.wednesdayTo,

        isThursday: workingHours?.thursdayEnabled,
        fromTimeThursday: workingHours?.thursdayFrom,
        toTimeThursday: workingHours?.thursdayTo,

        isFriday: workingHours?.fridayEnabled,
        fromTimeFriday: workingHours?.fridayFrom,
        toTimeFriday: workingHours?.fridayTo,

        isSaturday: workingHours?.saturdayEnabled,
        fromTimeSaturday: workingHours?.saturdayFrom,
        toTimeSaturday: workingHours?.saturdayTo,

        isSunday: workingHours?.sundayEnabled,
        toTimeSunday: workingHours?.sundayTo,
        fromTimeSunday: workingHours?.sundayFrom,
      },
    });

  const onSetSwitcherValue = (
    checked: boolean,
    switcherFieldName: FormFieldName,
    fromFieldName: FormFieldName,
    toFieldName: FormFieldName
  ) => {
    setValue(switcherFieldName, checked);
    if (!checked) {
      clearErrors(toFieldName);
      clearErrors(fromFieldName);
      setValue(toFieldName, '', { shouldDirty: true, shouldValidate: false });
      setValue(fromFieldName, '', { shouldDirty: true, shouldValidate: false });
      trigger([fromFieldName, toFieldName]);
    }
  };

  const updateWorkingHoursHandler = async (formData: AttorneyProfileWorkingHoursSchema) => {
    try {
      const patchPayload: Partial<GetWorkingHoursResponse> = {
        fridayEnabled: formData.isFriday,
        fridayFrom: formData.fromTimeFriday || '',
        fridayTo: formData.toTimeFriday || '',
        mondayEnabled: formData.isMonday,
        mondayFrom: formData.fromTimeMonday || '',
        mondayTo: formData.toTimeMonday || '',
        saturdayEnabled: formData.isSaturday,
        saturdayFrom: formData.fromTimeSaturday || '',
        saturdayTo: formData.toTimeSaturday || '',
        sundayEnabled: formData.isSunday,
        sundayFrom: formData.fromTimeSunday || '',
        sundayTo: formData.toTimeSunday || '',
        thursdayEnabled: formData.isThursday,
        thursdayFrom: formData.fromTimeThursday || '',
        thursdayTo: formData.toTimeThursday || '',
        tuesdayEnabled: formData.isTuesday,
        tuesdayFrom: formData.fromTimeTuesday || '',
        tuesdayTo: formData.toTimeTuesday || '',
        wednesdayEnabled: formData.isWednesday,
        wednesdayFrom: formData.fromTimeWednesday || '',
        wednesdayTo: formData.toTimeWednesday || '',
      };
      await patchWorkingHoursById(workingHours?.['@id']?.replace('/working_hours/', ''), patchPayload);
      await fetchUserCompanyHandler();
      notice(ToastType.SUCCESS, 'Successfuly updated working hours!');
    } catch (error) {
      notice(ToastType.ERROR, 'Failed to updated working hours, try again!');
      console.error(error);
    }
  };

  const onFormSudmitHandler = handleSubmit(updateWorkingHoursHandler);

  const isButtonDisabled =
    !!Object.entries(formState.errors).length || formState.isSubmitting || !formState.isValid || !formState.isDirty;

  const isDisabledWorkingHoursSwitcher =
    backendUser?.companyUser?.[0]?.role === 'ROLE_COMPANY_ATTORNEY' ||
    (backendUser?.companyUser?.[0]?.role === 'ROLE_COMPANY_ADMIN' &&
      !myCompany?.userPermissions?.adminCanEditCompanyInfo);

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader title="Company working hours" />
        <CardContent>
          <MDBox display="flex" flexDirection="column" component="form" width={1} onSubmit={onFormSudmitHandler}>
            {WORKING_HOURS_FORM_FIELDS.map(
              ({ switchTitle, watchFromTimeInputValue, watchSwitcherValue, watchToTimeInputValue }) => (
                <WorkingHoursFormField
                  key={switchTitle}
                  register={register}
                  formState={formState}
                  setError={setError}
                  clearErrors={clearErrors}
                  control={control}
                  watch={watch}
                  watchFromTimeInputValue={watchFromTimeInputValue}
                  watchSwitcherValue={watchSwitcherValue}
                  watchToTimeInputValue={watchToTimeInputValue}
                  switchTitle={switchTitle}
                  onSetSwitcherValue={onSetSwitcherValue}
                  isDisabled={isDisabledWorkingHoursSwitcher}
                />
              )
            )}
            <MDBox mt={1} mb={1} sx={{ width: 1 }}>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                isLoading={formState.isSubmitting}
                sx={{ textTransform: 'none', width: { xs: 1, sm: 'auto' } }}
                disabled={isButtonDisabled}
              >
                Save
              </MDButton>
            </MDBox>
          </MDBox>
        </CardContent>
      </Card>
    </Grid>
  );
};
