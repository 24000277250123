import { useCallback, useState } from 'react';
import { ToastType, notice, useDocument, useFileSign } from 'features/common';
import { checkIsFileSizeGreaterThanValue } from 'features/common/helpers/utilities';
import { createFileService } from 'features/common/services/filesService';
import { fileSignApiService } from 'features/common/services/fileSignApiService';
import { UseControlsProps } from '../types';

export const useControls = ({ legalCaseUID, remoteParticipantFirebaseId }: UseControlsProps) => {
  //upload files
  const [errorMesasage, setErrorMessage] = useState('');
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);

  const { sendEnvelopeFileToSign } = fileSignApiService();
  const { createFile } = createFileService();
  const {
    fileSignProvider,
    onChangeRadioButtonHandler,
    fileSignProvidersToMap,
    isFileSignServicesActive,
    isSomeFileSignServiceActive,
    isSelectTemplateDocumentModalOpen,
    closeSelectTemplateDocumentModal,
    isFillTemplateMenuOpen,
    openFillTemplateMenu,
    closeFillTemplateMenu,
    selectedTemplate,
    onClickSelectTemplateItemHandler,
    isSelectDocumentsFromCaseMenuOpen,
    openRequestToSignDocumentsModalOpen,
    openSelectDocumentsFromCaseMenu,
    closeSelectDocumentsFromCaseMenu,
    isRequestToSignDocumentsModalOpen,
    closeRequestToSignDocumentsModalOpen,
    onOpenSelectTemplateDocumentModalHandler,
    isNotConnectedFileSignServicesOpen,
    openNotConnectedFileSignServicesModal,
    closeNotConnectedFileSignServicesModal,
  } = useFileSign({});

  const { createFormDataHandler } = useDocument();

  const onInputFileUploadHandler = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files[0];

      if (checkIsFileSizeGreaterThanValue(file.size)) {
        setErrorMessage('The document size should be equal or less than 25 MB');
        return;
      }

      try {
        const documentFormData = createFormDataHandler(file);
        setIsDocumentLoading(true);

        const { data } = await createFile(documentFormData);

        await sendEnvelopeFileToSign(fileSignProvider?.provider, {
          file: data?.['@id'],
          object: `/legal_cases/${legalCaseUID}`,
          participant: `/users/${remoteParticipantFirebaseId}`,
        });

        notice(ToastType.SUCCESS, 'Successfully uploaded the document!');

        setIsDocumentLoading(false);
        closeRequestToSignDocumentsModalOpen();
        errorMesasage && setErrorMessage('');
      } catch (error: any) {
        setIsDocumentLoading(false);

        if (error?.response?.status === 422) {
          notice(ToastType.ERROR, error?.response?.data?.['hydra:description']);
        } else {
          notice(ToastType.ERROR, 'Failed to upload document, try again!');
        }
        console.error(error);
      }
    },
    [fileSignProvider]
  );

  const onOpenSelectDocumentsFromTheCaseMenuHandler = useCallback(() => {
    openSelectDocumentsFromCaseMenu();
    closeRequestToSignDocumentsModalOpen();
  }, []);

  return {
    isSelectDocumentsFromCaseMenuOpen,
    closeSelectDocumentsFromCaseMenu,
    isDocumentLoading,
    onInputFileUploadHandler,
    isRequestToSignDocumentsModalOpen,
    closeRequestToSignDocumentsModalOpen,
    onOpenSelectDocumentsFromTheCaseMenuHandler,
    openRequestToSignDocumentsModalOpen,
    isSelectTemplateDocumentModalOpen,
    closeSelectTemplateDocumentModal,
    onOpenSelectTemplateDocumentModalHandler,
    isFillTemplateMenuOpen,
    openFillTemplateMenu,
    closeFillTemplateMenu,
    selectedTemplate,
    onClickSelectTemplateItemHandler,
    isNotConnectedFileSignServicesOpen,
    openNotConnectedFileSignServicesModal,
    closeNotConnectedFileSignServicesModal,
    onChangeRadioButtonHandler,
    fileSignProvider,
    fileSignProvidersToMap,
    isFileSignServicesActive,
    isSomeFileSignServiceActive,
  };
};
