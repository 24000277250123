import { FC } from 'react';
import { Alert, AlertTitle } from '@mui/material';

import { DashboardLayout } from 'features/common';

export const AttorneysLawFirms: FC = () => (
  <DashboardLayout>
    <Alert severity="warning">
      <AlertTitle>Attorneys and Law firms</AlertTitle>
      This area is still in development
    </Alert>
  </DashboardLayout>
);
