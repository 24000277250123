import React, { FC, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import { BaseFormFieldProps } from '../BaseFormField/types';

export type FormFieldProps = React.ComponentProps<typeof MDBox>;
export type FormInputProps = React.ComponentProps<typeof MDInput>;

type BasePasswordFormFieldProps = BaseFormFieldProps;

export const BasePasswordFormField: FC<BasePasswordFormFieldProps> = ({
  formFieldProps,
  formInputProps,
  errorValue,
}) => {
  const [isShowPassword, setIshowPassword] = useState(false);

  const handleClickShowPassword = () => setIshowPassword(isShowPassword => !isShowPassword);

  return (
    <MDBox position="relative" pb="18px" {...formFieldProps}>
      <MDInput
        {...formInputProps}
        type={isShowPassword ? 'text' : 'password'}
        isDisableErrorStyles
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                {isShowPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={Boolean(errorValue)}
      />
      {errorValue && (
        <MDTypography position="absolute" fontSize={10} mt={0.1} color="error">
          * {errorValue}
        </MDTypography>
      )}
    </MDBox>
  );
};
