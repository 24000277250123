import { useEffect } from 'react';
import {
  CreateStaticPagePayload,
  createStaticPagesService,
  GetStaticPageByIdResponse,
} from 'features/static-pages/services';
import { notice, ToastType, useAsync } from '../../common';

export const useEditStaticPageData = (staticPageId: string) => {
  const { getContentPageById, patchContentPageById } = createStaticPagesService();

  const [loadStaticPageState, loadStaticPagesHandler] = useAsync(async (staticPageId: string) => {
    try {
      const { data } = await getContentPageById(staticPageId);
      return data;
    } catch (error) {
      notice(ToastType.ERROR, 'Something went wrong during static page loading!');
      console.error(error);
    }
  });

  const [patchContentPageState, patchContentPageHandler] = useAsync(
    async (patchPagePayload: CreateStaticPagePayload) => {
      if (staticPageData) {
        return await patchContentPageById(staticPageData['@id'], patchPagePayload);
      }
    }
  );

  useEffect(() => {
    if (staticPageId) {
      loadStaticPagesHandler(staticPageId);
    }
  }, [staticPageId]);

  const isLoading = loadStaticPageState.isPending() || patchContentPageState.isPending();
  const staticPageData: GetStaticPageByIdResponse | null =
    (loadStaticPageState.isSuccessful() && loadStaticPageState.data) || null;

  const isFailed = loadStaticPageState.isFailed() || (loadStaticPageState.isSuccessful() && !loadStaticPageState.data);

  return {
    isLoading,
    staticPageData,
    isFailed,
    patchContentPageHandler,
  };
};
