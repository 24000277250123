import { useEffect, useState } from 'react';
import { Autocomplete, Card, Grid } from '@mui/material';
import { EditorState } from 'draft-js';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import { BaseFormField, DashboardLayout, notice, RichTextEditor, ToastType, useRouter } from 'features/common';
import { CATEGORY_IRI } from 'features/common/mocks/IRIvalues';
import { ROUTES } from 'features/common/variables/routes';
import { convertEditorStateValueToHTML } from 'features/common/helpers/textEditor';
import { ButtonsFooter } from 'features/static-pages/components';
import { baseBackendErrorHandler } from '../../common/errorHanders';
import { createContentItemService } from '../services';
import { createContentItemFormSchema, CreateContentItemFormSchema } from './formSchema';
import { useContentCategories } from '../hooks';

export const CreateContentItemTemplate = (): JSX.Element => {
  const router = useRouter();
  const { contentCategoriesList, isLoading } = useContentCategories({ itemsPerPage: 100 });

  const { createContentItem } = createContentItemService();

  const [editorState, setEditorState] = useState(null);
  const [categories, setCategories] = useState<string[]>([]);

  const {
    handleSubmit,
    formState: { isValid, isSubmitting, errors, isDirty },
    register,
    setError,
    setValue,
    reset,
    watch,
    control,
  } = useForm<CreateContentItemFormSchema>({
    resolver: yupResolver(createContentItemFormSchema),
    mode: 'onTouched',
    defaultValues: { category: '', htmlContent: '', name: '' },
  });

  const initCategory = () => {
    const categoryIdFromUrl = CATEGORY_IRI + router.pathname.replace('/content-items/create/', '');
    if (!contentCategoriesList.length) return;
    const categoryName =
      contentCategoriesList.find(categoryItem => categoryItem['@id'] === categoryIdFromUrl)?.name ||
      contentCategoriesList[0]?.name;

    setValue('category', categoryName);
  };

  const onSubmitFormHandler = async (data: CreateContentItemFormSchema) => {
    try {
      if (!editorState) return;
      const categoryId = contentCategoriesList.find(categoryItem => categoryItem.name === data.category)['@id'];

      if (!categoryId) {
        notice(ToastType.ERROR, `Category doesn't exist`);
        return;
      }

      await createContentItem({
        name: data.name,
        category: categoryId,
        htmlContent: convertEditorStateValueToHTML(editorState),
      });

      await router.push(ROUTES.contentItems);
      notice(ToastType.SUCCESS, `Successfully created content item with name - ${data.name}`);
    } catch (error) {
      console.error(error);
      baseBackendErrorHandler(error, { formError: { formData: data, setError } });
    }
  };

  useEffect(() => {
    if (!categories.length) {
      setCategories(contentCategoriesList.map(category => category.name));
    }
  }, [contentCategoriesList]);

  useEffect(() => {
    if (contentCategoriesList.length) initCategory();
  }, [contentCategoriesList]);

  const isSaveButtonDisabled = !isValid || !isDirty || isSubmitting;

  const onClickDiscardButtonHandler = () => {
    setEditorState(EditorState.createEmpty());
    initCategory();
    reset();
  };

  return (
    <DashboardLayout isNavigationDisabled={true}>
      <form onSubmit={handleSubmit(onSubmitFormHandler)}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <MDTypography variant="h4" fontWeight="medium">
              Create new content item below
            </MDTypography>
            <MDBox mt={1} mb={2}>
              <MDTypography variant="body2" color="text">
                You’re able to create your own content for user to see.
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ padding: 3 }}>
              <MDTypography variant="body2" color="text" sx={{ paddingBottom: 2 }}>
                Assign name that will be url path to your content item.
              </MDTypography>
              <BaseFormField
                formInputProps={{
                  ...register('name'),
                  type: 'text',
                  label: 'Name',
                  fullWidth: true,
                  color: 'white',
                  InputLabelProps: { shrink: true },
                }}
                errorValue={errors['name']?.message}
              />
              <MDBox mb={3}>
                <MDBox mb={1.625} display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize"
                  >
                    Category
                  </MDTypography>
                </MDBox>
                <Autocomplete
                  value={watch('category') || null}
                  options={categories}
                  disableClearable
                  onChange={(_, value) => {
                    setValue('category', value);
                  }}
                  renderInput={params => <MDInput {...params} {...register('category')} variant="standard" />}
                />
                {errors['category']?.message && (
                  <MDTypography fontSize={10} mt={0.1} color="error">
                    *{errors['category']?.message}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="body2" color="text" sx={{ paddingBottom: 2 }}>
                  Content
                </MDTypography>
              </MDBox>
              <Controller
                control={control}
                name="htmlContent"
                render={({ field }) => {
                  const onChange = (editorState: EditorState) => {
                    setEditorState(editorState);
                    const isNoPlainText = !editorState.getCurrentContent().getPlainText().length;
                    field.onChange(isNoPlainText ? '' : convertEditorStateValueToHTML(editorState));
                  };
                  return <RichTextEditor editorState={editorState} onEditorStateChange={onChange} />;
                }}
              />
              {errors['htmlContent']?.message && (
                <MDTypography fontSize={10} mt={0.1} color="error">
                  *{errors['htmlContent']?.message}
                </MDTypography>
              )}
            </Card>
          </Grid>

          <ButtonsFooter
            isDiscardButtonDisabled={!isDirty || isSubmitting}
            onClickDiscardButtonHandler={onClickDiscardButtonHandler}
            isSaveButtonDisabled={isSaveButtonDisabled}
            isLoading={isSubmitting}
            saveButtonTitle="Create"
          />
        </Grid>
      </form>
    </DashboardLayout>
  );
};
