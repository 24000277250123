import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { LinearProgress, Stack } from '@mui/material';
import firebase from 'firebase/auth';
import { firebaseAuth } from '../index';
import { useAsync } from '../../common';

const Context = createContext<FirebaseUserContextValue>({
  firebaseUser: null,
  accessToken: null,
});

type AccessToken = string | undefined;

export type FirebaseUser = firebase.User;

type FirebaseUserContextValue = {
  firebaseUser: FirebaseUser | null;
  accessToken: string | null;
};

export const FirebaseUserContextProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>(null);
  const [accessToken, setAccessToken] = useState<AccessToken>(null);
  const [isLoading, setIsLoading] = useState(true);

  const [_, connectTokenHandler] = useAsync(async (firebaseUser: FirebaseUser) => {
    if (firebaseUser) {
      const token = await firebaseUser.getIdToken();

      setAccessToken(token);
    } else {
      setAccessToken(null);
    }
  });

  const connect = useCallback(async (firebaseUser: FirebaseUser) => {
    setFirebaseUser(firebaseUser);
    setIsLoading(false);

    await connectTokenHandler(firebaseUser);
  }, []);

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(connect);

    return unsubscribe;
  }, []);

  const contextValue = useMemo(
    () => ({
      firebaseUser,
      accessToken,
    }),
    [firebaseUser, accessToken]
  );

  return (
    <Context.Provider value={contextValue}>
      {isLoading ? (
        <Stack
          sx={{
            justifyContent: 'center',
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: 1,
            alignItems: 'center',
          }}
        >
          <LinearProgress sx={{ width: '80%', overflow: 'hidden' }} />
        </Stack>
      ) : (
        children
      )}
    </Context.Provider>
  );
};

export const useFirebaseUser = () => useContext(Context);
