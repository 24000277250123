import { FC } from 'react';
import MDTypography from 'components/MDTypography';
import { dateToCustomFormat } from 'features/common/helpers/date';
import { MessageProps } from './types';

export const MessageTimestamp: FC<Pick<MessageProps, 'createdAt' | 'isMine'>> = ({ createdAt, isMine }) => {
  const createdDate = dateToCustomFormat(createdAt, 'hh:mm a');
  return (
    <MDTypography
      variant="caption"
      sx={{ alignSelf: isMine ? 'flex-end' : 'flex-start', wordBreak: 'break-word', fontWeight: 300 }}
    >
      {createdDate || ''}
    </MDTypography>
  );
};
