import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';
import { DashboardLayout } from 'features/common';
import { BaseSideNaveMenu } from 'features/common/components/BaseSideNaveMenu';
import { ROUTES } from 'features/common/variables/routes';
import { ConfigSettingsContextProvider } from '../context/ConfigSettingsContextProvider';

const sideNavMenuRoutes = [
  { icon: 'settings applications', label: 'General', href: ROUTES.settingsGeneral },
  { icon: 'mail outline', label: 'Mail', href: ROUTES.settingsMail },
  { icon: 'integration_instructions', label: 'Clio integration', href: ROUTES.settingsClioIntegration },
  { icon: 'app_settings_alt', label: 'Urgent versions', href: ROUTES.settingsUrgentVersions },
];

export const SettingsTemplate: FC = () => {
  return (
    <DashboardLayout>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4}>
          <BaseSideNaveMenu sidenavItems={sideNavMenuRoutes} />
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <ConfigSettingsContextProvider>
            <Outlet />
          </ConfigSettingsContextProvider>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};
