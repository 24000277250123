import { Column } from 'react-table';
import { Clear } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { CriticalUpdateVersion, OS } from 'features/settings/UrgentVersionsApiService';
import { formatDate } from 'features/common/helpers/date';

type ActionButtons = {
  onDeleteIconClickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  id: string;
};

const OS_MAP: Partial<Record<OS, string>> = {
  ios: 'iOS',
  android: 'Android',
};

type UrgentVersionsColumnData = Pick<CriticalUpdateVersion, 'os' | 'createdAt' | 'version'> & {
  actionBtns: ActionButtons;
};

export const TABLE_COLUMNS: Column<UrgentVersionsColumnData>[] = [
  {
    Header: 'Operation system',
    accessor: 'os',
    align: 'left',
    Cell: cellData => <MDTypography variant="button">{OS_MAP[cellData.value]}</MDTypography>,
  },
  {
    Header: 'Version',
    accessor: 'version',
    align: 'left',
    Cell: cellData => <MDTypography variant="button">{cellData.value}</MDTypography>,
  },
  {
    Header: 'Creation Date',
    accessor: 'createdAt',
    align: 'left',
    Cell: cellData => <MDTypography variant="button">{formatDate(cellData.value)}</MDTypography>,
  },
  {
    Header: 'Action',
    accessor: 'actionBtns',
    Cell: ({ value }) => {
      return (
        <Tooltip title="Delete">
          <MDButton
            id={value.id}
            size="small"
            variant="outlined"
            color="secondary"
            iconOnly
            onClick={value?.onDeleteIconClickHandler}
          >
            <Clear />
          </MDButton>
        </Tooltip>
      );
    },
  },
];
