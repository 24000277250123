import { ObjectSchema } from 'yup';
import * as yup from 'yup';

export const CONTENT_CATEGORY_SLUG_REGEX = /^[a-z0-9_]+$/g;

export type CreateContentCategoryFormSchema = EditContentCategoryFormSchema;
export type EditContentCategoryFormSchema = { name: string; slug: string };

export const createContentCategoryFormSchema: ObjectSchema<CreateContentCategoryFormSchema> = yup
  .object({
    name: yup.string().required().trim().label('Category name'),
    slug: yup.string().required().trim().label('Slug').matches(CONTENT_CATEGORY_SLUG_REGEX, {
      message: 'Slug should include only lower case letters, numbers and underscore sign',
    }),
  })
  .required();

export type CreateContentItemFormSchema = { name: string; category: string; htmlContent: string };
export type EditContentItemFormSchema = CreateContentItemFormSchema;

export const createContentItemFormSchema: ObjectSchema<CreateContentItemFormSchema> = yup
  .object({
    name: yup.string().required().trim().label('Item name'),
    category: yup.string().required('This field is required').trim().label('Category'),
    htmlContent: yup.string().required('This field is required').label('Content'),
  })
  .required();
