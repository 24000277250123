import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { Rating, Tooltip } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { GetUserReviewsResponse } from '../services';
import { formatDate } from 'features/common/helpers/date';
import { ROLES, UserRoles } from 'features/common';
import { FeedbackCell } from './FeedbackCell';
import { Column } from 'react-table';

export type UserData = {
  src: string;
  firstName: string;
  lastName: string;
  roles?: UserRoles;
  companyName?: string;
};

type ReviewTableColumn = Column<Partial<ColumnData>>;

type ColumnData = Pick<GetUserReviewsResponse, 'rating' | 'createdAt'> & {
  reviewedUser: UserData & {
    body: string;
  };
  author: UserData;
  actionBtns: {
    id: string;
    onDeleteIconClickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onApproveIconClickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  };
};

export const REVIEW_TABLE_COLUMNS: ReviewTableColumn[] = [
  {
    Header: 'Feedback',
    accessor: 'reviewedUser',
    align: 'left',
    Cell: ({ value }) => {
      const reviewedUserRole = ROLES[value?.roles[0]];
      return (
        <FeedbackCell
          src={value.src}
          firstName={value.firstName}
          lastName={value.lastName}
          companyName={value.companyName}
          role={reviewedUserRole}
          body={value.body}
        />
      );
    },
  },
  {
    Header: 'Rating',
    accessor: 'rating',
    align: 'left',
    Cell: ({ value }) => (
      <Rating value={value} precision={0.1} readOnly sx={{ color: theme => theme.palette.grey[700] }} />
    ),
  },
  {
    Header: 'Author',
    accessor: 'author',
    align: 'left',
    Cell: ({ value }) => {
      const authorRole = ROLES[value?.roles[0]];
      return <FeedbackCell src={value.src} role={authorRole} firstName={value.firstName} lastName={value.lastName} />;
    },
  },
  {
    Header: 'Creation date',
    accessor: 'createdAt',
    Cell: ({ value }) => <MDBox>{value ? formatDate(value) : '-'}</MDBox>,
  },
  {
    Header: 'Actions',
    accessor: 'actionBtns',
    Cell: ({ value }) => (
      <MDBox sx={{ display: 'flex', gap: '5px', width: '100%', justifyContent: 'flex-start' }}>
        <Tooltip title="Approve review">
          <MDButton
            color="secondary"
            size="small"
            variant="outlined"
            iconOnly
            id={value.id}
            onClick={value.onApproveIconClickHandler}
          >
            <DoneIcon />
          </MDButton>
        </Tooltip>
        <Tooltip title="Delete review">
          <MDButton
            color="secondary"
            size="small"
            variant="outlined"
            iconOnly
            id={value.id}
            onClick={value.onDeleteIconClickHandler}
          >
            <ClearIcon />
          </MDButton>
        </Tooltip>
      </MDBox>
    ),
  },
];
