import { FC } from 'react';
import { Stack } from '@mui/material';
import MDButton from 'components/MDButton';

type ButtonsBoxProps = {
  onClickDiscardButtonHandler: () => void;
  isDisabledSubmitButton: boolean;
  isLoading: boolean;
};

export const ButtonsBox: FC<ButtonsBoxProps> = ({ isDisabledSubmitButton, isLoading, onClickDiscardButtonHandler }) => {
  return (
    <Stack spacing={1.5}>
      <MDButton disabled={isDisabledSubmitButton} isLoading={isLoading} color="info" variant="gradient" type="submit">
        Save
      </MDButton>
      <MDButton variant="gradient" type="button" color="dark" onClick={onClickDiscardButtonHandler}>
        Discard
      </MDButton>
    </Stack>
  );
};
