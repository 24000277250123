import { backendApiClient } from '../../common';

export type CreateContentCategoryPayload = {
  name: string;
  slug: string;
};

type PatchContentCategoryPayload = CreateContentCategoryPayload;

type CreateContentCategoryResponse = {
  '@id': string;
  '@type': string;
  '@context': string;
  name: string;
};

export type ContentCategoryResponse = {
  '@id': string;
  '@type': string;
  name: string;
  slug: string;
};

export type GetContentCategoryResponse = {
  'hydra:member': ContentCategoryResponse[];
  'hydra:totalItems': number;
};
export type GetContentCategoriesRequest = {
  page?: number;
  itemsPerPage?: number;
};

export const createContentCategoryService = () => {
  const getContentCategories = async (params: GetContentCategoriesRequest) => {
    return await backendApiClient({}).get<GetContentCategoryResponse>('/content_item_categories', { params });
  };

  const getContentCategoryById = async (id: string) => {
    return await backendApiClient({}).get<GetContentCategoryResponse>(`/content_item_categories/${id}`);
  };

  const createContentCategory = async (createContentItemPayload: CreateContentCategoryPayload) => {
    return await backendApiClient({}).post<CreateContentCategoryResponse, CreateContentCategoryPayload>(
      '/content_item_categories',
      createContentItemPayload
    );
  };

  const patchContentCategoryById = async (id: string, patchPagePayload: PatchContentCategoryPayload) => {
    return await backendApiClient({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch<GetContentCategoryResponse>(`/content_item_categories/${id}`, patchPagePayload);
  };

  const deleteContentCategoryById = async (id: string) => {
    return await backendApiClient({}).delete(`/content_item_categories/${id}`);
  };

  return {
    createContentCategory,
    patchContentCategoryById,
    getContentCategories,
    getContentCategoryById,
    deleteContentCategoryById,
  };
};
