import { ApiSecurityFunction } from 'features/common';
import { CreateLegalCaseMessageResponse } from 'features/common/services/chatApiService';

const APPROVE_REPRESENTATION_TEXT = 'Request has been accepted.';
const DECLINE_REPRESENTATION_TEXT = 'Request has been declined.';

export const getMessageText = (
  isClient: boolean,
  message: CreateLegalCaseMessageResponse,
  aesDecrypt: ApiSecurityFunction
) => {
  if (message?.type === 'file') return message?.file?.title;

  if (message?.type === 'text') return aesDecrypt(message?.message) || '';

  if (message?.type === 'prompt_representation_decline') return DECLINE_REPRESENTATION_TEXT;

  if (message?.type === 'prompt') {
    if (message?.representation?.status === 'approve') return APPROVE_REPRESENTATION_TEXT;

    if (isClient) {
      if (message?.representation?.status === 'pending')
        return 'The attorney made a request\nto change the status of the case.';
    } else {
      if (message?.representation?.status === 'pending') return 'Request has been sent.';
    }
  }
};
