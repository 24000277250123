import { useState } from 'react';
import { CircularProgress, Stack, Switch, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ClearIcon from '@mui/icons-material/Clear';
import CustomPaginationTable from 'features/content-items/components/CustomPaginationTable';
import { TablePaginationFooter } from 'features/common/components/TablePaginationFooter';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { TableHeader } from 'features/common/components/TableHeader';
import { formatDate } from 'features/common/helpers/date';
import { DashboardLayout, notice, ToastType, useBoolean, useRouter } from '../../common';
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import MDButton from '../../../components/MDButton';
import { useStaticPageListData } from '../hooks/useStaticPageListData';
import { StaticPageItemResponse } from '../services';

export const StaticPageListTemplate = (): JSX.Element => {
  const router = useRouter();

  const {
    isLoading,
    currentPage,
    entriesPerPage,
    staticPageTotalItems,
    staticPageList,
    onChangeEntriesPerPageHandler,
    loadStaticPagesHandler,
    onChangePageHandler,
    patchContentPageById,
    deleteContentPageById,
    onDeleteItem,
    setIsLoading,
    getItemsHandler,
  } = useStaticPageListData();

  const [selectedContentPage, setSelectedContentPage] = useState<StaticPageItemResponse>(null);
  const [isSubmitPageDeleteModalOpen, openSubmitDeleteModal, closeSubmitDeleteModal] = useBoolean(false);

  const onDeleteItemHandler = async () => {
    try {
      const nextPage = onDeleteItem() || currentPage;
      setIsLoading(true);

      closeSubmitDeleteModal();
      await deleteContentPageById(selectedContentPage['@id']);
      await getItemsHandler({ page: nextPage });
      setIsLoading(false);
      notice(ToastType.SUCCESS, 'Static page succesfully deleted');
    } catch (error) {
      setIsLoading(false);
      notice(ToastType.ERROR, 'Something went wrong!');
    }
  };

  const onClickAddButtonHandler = () => router.push('/static/create');

  return (
    <DashboardLayout>
      <Card>
        <TableHeader title="Content Pages" onButtonClickHandler={onClickAddButtonHandler} />

        {isLoading ? (
          <Stack justifyContent="center" alignItems="center" minHeight={300}>
            <CircularProgress sx={{ mx: 'auto', mb: '16px' }} />
          </Stack>
        ) : staticPageList.length > 0 ? (
          <MDBox width={1}>
            <CustomPaginationTable
              isSorted={false}
              showTotalEntries={true}
              onChangeEntriesPerPage={onChangeEntriesPerPageHandler}
              entriesPerPage={{ defaultValue: entriesPerPage }}
              table={{
                columns: [
                  { Header: 'Public visibility', accessor: 'publicVisibility', width: '100px' },
                  { Header: 'Page path', accessor: 'pagePath' },
                  { Header: 'Title', accessor: 'title' },
                  { Header: 'Creation date', accessor: 'createdAt' },
                  { Header: 'Last Update', accessor: 'lastUpdate' },
                  { Header: '', accessor: 'actionBtns' },
                ],
                rows: staticPageList.map(staticPage => ({
                  pagePath: staticPage.urlPath,
                  title: staticPage.contentTitle,
                  publicVisibility: (
                    <Switch
                      onChange={() =>
                        patchContentPageById(staticPage['@id'], {
                          publicVisibility: !staticPage.publicVisibility,
                        }).then(async () => {
                          await loadStaticPagesHandler();
                          notice(ToastType.SUCCESS, 'Successfully switched public visibility!');
                        })
                      }
                      checked={staticPage.publicVisibility}
                    />
                  ),
                  createdAt: formatDate(staticPage.createdAt),
                  lastUpdate: staticPage.updatedAt ? formatDate(staticPage.updatedAt) : '-',
                  actionBtns: (
                    <MDBox sx={{ display: 'flex', gap: '4px', alignItems: 'ceter', justifyContent: 'flex-end' }}>
                      <Tooltip title="Edit selected content page">
                        <MDButton
                          variant="outlined"
                          color="secondary"
                          size="small"
                          iconOnly
                          onClick={() => router.push(`/static/${staticPage['@id'].replace('/content_pages/', '')}`)}
                        >
                          <ModeEditIcon />
                        </MDButton>
                      </Tooltip>
                      <Tooltip title="Delete selected content page">
                        <MDButton
                          onClick={() => {
                            setSelectedContentPage(staticPage);
                            openSubmitDeleteModal();
                          }}
                          variant="outlined"
                          color="secondary"
                          size="small"
                          iconOnly
                        >
                          <ClearIcon />
                        </MDButton>
                      </Tooltip>
                    </MDBox>
                  ),
                })),
              }}
            />
            <TablePaginationFooter
              page={currentPage}
              currentItemsPerPage={entriesPerPage}
              totalItems={staticPageTotalItems}
              onChangePage={onChangePageHandler}
            />
          </MDBox>
        ) : (
          <MDBox sx={{ minHeight: 300, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            <MDTypography sx={{ mx: 'auto' }}>No static pages were found...</MDTypography>
          </MDBox>
        )}
      </Card>
      <DialogWindow
        onCloseDialogHandler={closeSubmitDeleteModal}
        onApproveDialogHandler={onDeleteItemHandler}
        isDialogOpen={isSubmitPageDeleteModalOpen}
        dialogTitle={`Are you sure you want to delete ${selectedContentPage?.contentTitle || 'this content'} page?
        `}
      />
    </DashboardLayout>
  );
};
