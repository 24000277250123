// @fullcalendar components
import FullCalendar, { CalendarOptions } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import scrollGridPlugin from '@fullcalendar/scrollgrid';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Custom styles for Calendar
import CalendarRoot from 'examples/Calendar/CalendarRoot';

import { memo } from 'react';

// Declaring props types for the Calender
interface Props extends CalendarOptions {
  header?: {
    title?: string;
    date?: string;
  };
  calendarRef?: React.MutableRefObject<FullCalendar> | null;
}

function Calendar({ header, events, calendarRef, ...rest }: Props): JSX.Element {
  return (
    <Card sx={{ height: 1 }}>
      <MDBox pt={header.title || header.date ? 2 : 0} px={2} lineHeight={1}>
        {header.title ? (
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {header.title}
          </MDTypography>
        ) : null}
        {header.date ? (
          <MDTypography component="p" variant="button" color="text" fontWeight="regular">
            {header.date}
          </MDTypography>
        ) : null}
      </MDBox>
      <CalendarRoot p={2}>
        <FullCalendar
          {...rest}
          ref={calendarRef}
          dayMinWidth={150}
          plugins={[dayGridPlugin, timeGridPlugin, scrollGridPlugin, interactionPlugin]}
          events={events}
          height="100%"
        />
      </CalendarRoot>
    </Card>
  );
}

export default memo(Calendar);
