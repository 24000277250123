import { FC, useState } from 'react';
import { Divider, Stack } from '@mui/material';
import { IllustrationLayout, notice, ToastType, useRouter, createBackendUserService, SideMenu } from 'features/common';
import { AUTH_FLOW_STEPS, useAuth } from 'features/store';
import { ROUTES } from 'features/common/variables/routes';
import { useAttorneyStatesLawTypes } from 'features/common/hooks/useAttorneyStatesLawTypes';
import { Loader } from 'features/common/components/Loader';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { StateLawTypeItem } from './StateLawTypeItem';
import { StatesMenuContent } from './StatesMenuContent';
import { AuthFlowBackgrounds } from 'features/auth/variables/illustartion';
import { Illustrationtitle } from 'features/common/components/IllustatrionLogo';

export const SelectLawTypesStateStep: FC = () => {
  const router = useRouter();
  const { resetAllStepsData, setStateData, toStep } = useAuth(state => ({
    setStateData: state.setStateData,
    toStep: state.toStep,
    resetAllStepsData: state.resetAllStepsData,
  }));

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { getBackendUser } = createBackendUserService();

  const onBackButtonClickHandler = () => toStep(AUTH_FLOW_STEPS.SELECT_LAW_TYPE);

  const onClickNextButtonHandler = async () => {
    try {
      setIsSubmitting(true);

      const backendUser = await getBackendUser();

      if (backendUser) {
        setStateData('backendUser', backendUser);
        setStateData('isAuthorized', true);
        resetAllStepsData();
        router.push(ROUTES.myLegalCases).then(() => {
          notice(ToastType.SUCCESS, 'Welcome!');
        });
      }

      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  };

  const {
    isSelectStateMenuOpen,
    onCloseSelectLawTypeAndResetStateHandler,
    onClickLawTypeItemHandler,
    selectedAttorneyLawType,
    isLoading,
    attoreyProfileCaseLawTypes,
    setAttoreyProfileCaseLawTypes,
    countryStateList,
    isStateListLoading,
  } = useAttorneyStatesLawTypes();

  const isSubmitButtonDisabled = !!attoreyProfileCaseLawTypes.find(el => !el.states.length);

  return (
    <IllustrationLayout
      title="Assign States to Your Law Types"
      description="Indicate the states where you're licensed to practice for each area of law you've selected. This step ensures you receive case notifications tailored to your regional expertise."
      onClickBack={onBackButtonClickHandler}
      illustration={AuthFlowBackgrounds.AUTH_ONE}
      illustrationLogo={<Illustrationtitle />}
    >
      {isLoading || isStateListLoading ? (
        <MDBox minHeight={200} justifyContent="center" alignItems="center" display="flex">
          <Loader />
        </MDBox>
      ) : attoreyProfileCaseLawTypes.length ? (
        <Stack>
          <Stack component="ul" divider={<Divider sx={{ my: '7px' }} />}>
            {attoreyProfileCaseLawTypes?.map(attorneyLawType => (
              <StateLawTypeItem
                key={attorneyLawType?.['@id']}
                lawTypeId={attorneyLawType?.['@id']}
                lawTypeTitle={attorneyLawType?.caseLawType?.title}
                states={attorneyLawType?.states}
                isSelected={selectedAttorneyLawType?.['@id'] === attorneyLawType?.['@id']}
                onClickLawTypeItemHandler={onClickLawTypeItemHandler}
              />
            ))}
          </Stack>
          <MDBox mt={4} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              type="button"
              fullWidth
              sx={{ textTransform: 'none' }}
              disabled={isSubmitButtonDisabled}
              isLoading={isSubmitting}
              onClick={onClickNextButtonHandler}
            >
              Finish
            </MDButton>
          </MDBox>
        </Stack>
      ) : (
        <Stack justifyContent="center" flex={1}>
          <MDTypography sx={{ mx: 'auto' }}>No law types were found...</MDTypography>
        </Stack>
      )}

      <SideMenu
        isOpen={isSelectStateMenuOpen}
        onClose={onCloseSelectLawTypeAndResetStateHandler}
        title={`${selectedAttorneyLawType?.caseLawType?.title} law type states`}
        customWidth={450}
      >
        <StatesMenuContent
          selectedAttroneyLawTypeId={selectedAttorneyLawType?.['@id']}
          states={countryStateList}
          matchedStates={selectedAttorneyLawType?.states}
          onCloseMenuHandler={onCloseSelectLawTypeAndResetStateHandler}
          setAttoreyProfileCaseLawTypes={setAttoreyProfileCaseLawTypes}
        />
      </SideMenu>
    </IllustrationLayout>
  );
};
