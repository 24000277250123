import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardContent, CardHeader, Stack, Switch, Theme } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import { createFileService } from 'features/common/services/filesService';
import { ToastType, notice, useBoolean, useUser } from 'features/common';
import { CREATE_CASE_FORM_STEPS, useCreateCase } from 'features/store';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';
import { DocumentsScheme, SupportedFileTypes, documentsFormSchema } from '../form.config';
import { ButtonsFooter } from '../ButtonsFooter';
import { DocumentItem } from './DocumentItem';

export const documentIconColors: Record<SupportedFileTypes, 'primary' | 'info' | 'success' | 'error'> = {
  audio: 'primary',
  image: 'success',
  video: 'error',
  document: 'info',
};

export const Documents: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [documentId, setDocumentId] = useState('');
  const [isDialogOpen, openDialogHandler, closeDialogHandler] = useBoolean(false);

  const { createFile, deleteFileById } = createFileService();
  const { backendUser, isAttorney } = useUser();

  const { saveFormDataHandler, activeStep, documentsData } = useCreateCase(state => ({
    documentsData: state.documentsData,
    activeStep: state.activeStep,
    saveFormDataHandler: state.setData,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    watch,
    setValue,
    clearErrors,
    setError,
    getValues,
  } = useForm<DocumentsScheme>({
    resolver: yupResolver(documentsFormSchema),
    mode: 'onTouched',
    defaultValues: {
      ...documentsData,
    },
  });

  const onFormSubmitHandler = handleSubmit(formData => {
    return saveFormDataHandler({
      step: activeStep,
      data: formData,
      activeStep: CREATE_CASE_FORM_STEPS.NOTES,
    });
  });

  const files = watch('documents');
  const isDocuments = watch('isDocuments');

  const checkIsFileAlreadyAddedHandler = (fileName: string) => {
    if (files?.find(el => el.title === fileName)) {
      setError('documents', {
        message: 'This document is already added',
      });
      return;
    }
  };

  const onInputFileUploadHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    const fileType = file.type.split('/')[0];

    checkIsFileAlreadyAddedHandler(file.name);

    if (Number(file.size) / 1000 ** 2 > 25) {
      setError('documents', {
        message: 'The document size should be equal or less than 25 MB',
      });
      return;
    }
    try {
      setIsLoading(true);
      const documentFormData = new FormData();

      documentFormData.append('file', file);
      documentFormData.append('type', file.type.includes('application') ? 'document' : fileType);
      documentFormData.append('bucketType', 'security');
      documentFormData.append('title', file.name);

      const { data } = await createFile(documentFormData);

      setValue('documents', [...watch('documents'), data]);
      errors['documents']?.message && clearErrors('documents');
      setIsLoading(false);
      notice(ToastType.SUCCESS, 'Successfully uploaded!');
      return;
    } catch (error: any) {
      setIsLoading(false);

      if (error?.response.status === 422) {
        notice(ToastType.ERROR, error?.response?.data?.['hydra:description']);
      } else {
        paymentRequiredErrorHandler(error, 'Failed to upload document, please try again!');
      }
      console.error(error);
    }
  };

  const onClickDeleteFileHandler = async () => {
    try {
      closeDialogHandler();
      setIsLoading(true);
      await deleteFileById(documentId);
      setValue(
        'documents',
        files.filter(file => file.id !== documentId)
      );
      setIsLoading(false);
      errors['documents']?.message && clearErrors('documents');
      notice(ToastType.SUCCESS, 'Successfully deleted!');
    } catch (error) {
      setIsLoading(false);
      notice(ToastType.ERROR, 'Failed to delete document, try again!');
      console.error(error);
    }
  };

  const onClickDeleteIconHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDocumentId(event.currentTarget.id);
    openDialogHandler();
  };

  const onClickBackButtonHandler = () => {
    saveFormDataHandler({
      ...(isDirty && {
        step: activeStep,
        data: getValues(),
      }),
      activeStep: CREATE_CASE_FORM_STEPS.DEPENDANT_INFO,
    });
  };

  const onClickDeleteCRMFileHandler = async () => {
    closeDialogHandler();

    setValue(
      'documents',
      files.filter(file => String(file.id) !== documentId),
      { shouldValidate: true }
    );
    errors['documents']?.message && clearErrors('documents');
  };

  return (
    <form onSubmit={onFormSubmitHandler}>
      <Card>
        <CardHeader sx={{ px: 3, pb: 0 }} title="Upload Case Documents" />
        <CardContent>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            alignItems="center"
            sx={{ justifyContent: 'space-between' }}
            mb={3}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <MDTypography variant="button" color="text">
                Provide any relevant documents to support your case. This could include contracts, correspondence, or
                any official paperwork.
              </MDTypography>

              <Switch {...register('isDocuments')} checked={watch('isDocuments')} />
            </Stack>

            <MDBox
              component="div"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: { xs: 1, sm: 'auto' },
                alignSelf: 'flex-start',
              }}
            >
              <MDButton
                variant="gradient"
                component="label"
                color="info"
                sx={{ textTransform: 'none', '&.Mui-disabled': { color: 'white !important', opacity: 0.65 } }}
                isLoading={isLoading}
                disabled={!isDocuments || isLoading}
                fullWidth
              >
                Upload
                <input
                  {...register('documents')}
                  onChange={onInputFileUploadHandler}
                  type="file"
                  hidden
                  accept="image/*,audio/*,video/*,application/*,text/*"
                />
              </MDButton>
            </MDBox>
          </Stack>
          <Stack spacing={1} mb={2} sx={{ overflowX: 'auto' }}>
            {isDocuments && (
              <Stack spacing={1}>
                {files?.map(file => {
                  const fileType = file.type.split('/')[0] as SupportedFileTypes;
                  return (
                    <DocumentItem
                      key={file.id}
                      fileType={fileType}
                      fileOwnerFirstName={backendUser?.userProfile?.firstName}
                      fileOwnerLastName={backendUser?.userProfile?.lastName}
                      id={file?.id}
                      size={file?.fileSize}
                      title={file?.title}
                      onClickDeleteIconHandler={onClickDeleteIconHandler}
                    />
                  );
                })}
                {errors['documents']?.message && (
                  <MDTypography
                    variant="body2"
                    sx={{ color: (theme: Theme) => theme.palette.error.main, fontSize: '12px' }}
                  >
                    {errors['documents']?.message}
                  </MDTypography>
                )}
              </Stack>
            )}
          </Stack>
          <ButtonsFooter
            isSubmitButtonDisabled={isLoading || !isValid}
            onClickBackButtonHandler={onClickBackButtonHandler}
          />
        </CardContent>
      </Card>
      <DialogWindow
        onCloseDialogHandler={closeDialogHandler}
        onApproveDialogHandler={onClickDeleteFileHandler}
        isDialogOpen={isDialogOpen}
        dialogTitle="Are you sure want to delete the document?"
      />
    </form>
  );
};
