import React, { FC } from 'react';
import { Stack, Theme, darken } from '@mui/material';
import MDBox from 'components/MDBox';
import { CloseRounded, DoneRounded } from '@mui/icons-material';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { ComponentsStyles as Styled } from './Components.styles';
import MDAvatar from 'components/MDAvatar';
import { RepresentationControlsProps } from '../types';
import { getAvatarCharacters } from 'features/common/helpers/utilities';

export const RepresentationControls: FC<RepresentationControlsProps> = ({
  remoteParticipantData,
  isAcceptDeclineRepresentationBox,
  isRemoteParticipantJoined,
  isSendRepresentationRequestBox,
  isViewFilesToSignBox,
  isApprovingRepresentation,
  representationStatus,
  isSendingOffer,
  children,
  isRejecting,
  onClickApproveRepresentationHandler,
  onClickDeclineRepresentationHandler,
  onClickSendRepresentationHandler,
  openSignDocumentsModal,

  //TODO Conference hack
  isCannotSendRepresentationRequest,
  onClickBuySubscriptionHandler,
}) => {
  const remoteParticipantFullName = `${remoteParticipantData?.firstName} ${remoteParticipantData?.lastName}`;
  return (
    <MDBox
      sx={{
        ...Styled.ActionWrapperSX,
        ...(isAcceptDeclineRepresentationBox && { backgroundColor: ({ palette }) => palette?.info.main }),
      }}
    >
      <Stack>
        {isSendRepresentationRequestBox && (
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" spacing={2}>
            <Stack>
              <MDTypography variant="button" color="white">
                {representationStatus === 'pending' ? `You’ve sent representation request` : 'Representation request'}
              </MDTypography>

              <MDTypography variant="button" sx={{ color: ({ palette }: Theme) => palette?.grey[300] }}>
                Client can confirm or deny
              </MDTypography>
            </Stack>
            {representationStatus !== 'pending' && (
              <MDButton
                onClick={onClickSendRepresentationHandler}
                disabled={isSendingOffer}
                isLoading={isSendingOffer}
                variant="gradient"
                color="dark"
              >
                Send
              </MDButton>
            )}
          </Stack>
        )}

        {/* TODO Conference hack */}
        {isCannotSendRepresentationRequest && (
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" spacing={2}>
            <Stack>
              <MDTypography variant="button" color="white">
                Free request was used
              </MDTypography>
            </Stack>

            <MDButton
              onClick={onClickBuySubscriptionHandler}
              disabled={isSendingOffer}
              isLoading={isSendingOffer}
              variant="gradient"
              color="info"
            >
              Subscribe
            </MDButton>
          </Stack>
        )}

        {isViewFilesToSignBox && (
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" spacing={2}>
            <Stack>
              <MDTypography variant="button" color="white">
                Document sign request
              </MDTypography>

              <MDTypography variant="button" sx={{ color: ({ palette }: Theme) => palette?.grey[300] }}>
                Sign the documents
              </MDTypography>
            </Stack>
            <MDButton
              onClick={openSignDocumentsModal}
              sx={{ backgroundColor: ({ palette }) => palette?.grey[900], color: 'white !important' }}
              variant="gradient"
              color="dark"
            >
              View
            </MDButton>
          </Stack>
        )}

        {isAcceptDeclineRepresentationBox && (
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" spacing={2}>
            <Stack>
              <MDTypography variant="button" color="white">
                Attorney made a request
              </MDTypography>

              <MDTypography variant="button" sx={{ color: ({ palette }: Theme) => palette?.grey[300] }}>
                You can confirm or deny
              </MDTypography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
              <MDButton
                disabled={isApprovingRepresentation}
                isLoading={isApprovingRepresentation}
                onClick={onClickApproveRepresentationHandler}
                iconOnly
                size="large"
                sx={Styled.FooterButtonSX}
                variant="gradient"
                color="dark"
              >
                <DoneRounded />
              </MDButton>
              <MDButton
                disabled={isRejecting}
                isLoading={isRejecting}
                onClick={onClickDeclineRepresentationHandler}
                iconOnly
                size="large"
                sx={Styled.FooterButtonSX}
                variant="gradient"
                color="dark"
              >
                <CloseRounded />
              </MDButton>
            </Stack>
          </Stack>
        )}
        <Stack direction="row" alignItems="center" spacing={1}>
          <MDAvatar
            size="md"
            sx={{ bgcolor: theme => darken(theme.palette.light.main, 0.3) }}
            src={remoteParticipantData?.logoUrl}
          >
            {getAvatarCharacters(remoteParticipantData?.firstName, remoteParticipantData?.lastName)}
          </MDAvatar>
          <Stack>
            <MDTypography variant="button" color="white">
              {!isRemoteParticipantJoined ? `Calling ${remoteParticipantFullName}` : remoteParticipantFullName}
            </MDTypography>
            {isRemoteParticipantJoined && <MDBox display="flex">{children}</MDBox>}
          </Stack>
        </Stack>
      </Stack>
    </MDBox>
  );
};
