import { Pagination } from 'features/case-law-types';
import {
  BaseData,
  CaseLawType,
  Country,
  CountryState,
  County,
  Integrations,
  ListOfItems,
  Logo,
  UserProfileResponse,
  backendApiClient,
} from 'features/common';
import { removeEmptyFields } from 'features/common/helpers/utilities';
import { CaseCompany, GetMyLegalCasesParams } from 'features/my-legal-cases/MyLegalCasesApiService';

const BASE_API_URL: Readonly<string> = '/legal_cases/leads_v2';

export type GetLegalCasesFeedParams = Pagination &
  GetMyLegalCasesParams &
  Partial<{
    search: string;
  }>;

export type Owner = BaseData<'User'> & {
  userProfile: UserProfileResponse;
  company: CaseCompany;
  integration: Integrations;
  logo: Logo | null;
  rating: string;
  legalCaseCount: number;
  firebaseUser: string;
};

export type GetLegalCasesFeedResponse = BaseData<'LegalCase'> & {
  id: string;
  caseNumber: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  caseLawType: CaseLawType;
  country: Country;
  state: CountryState;
  county: County;
  client: Owner;
};

export const legalCasesFeedApiService = () => {
  const getLegalCasesFeeds = async (params?: GetLegalCasesFeedParams) => {
    removeEmptyFields(params);
    return await backendApiClient({}).get<ListOfItems<GetLegalCasesFeedResponse[]>>(BASE_API_URL, {
      params,
    });
  };

  return {
    getLegalCasesFeeds,
  };
};

export const ITEMS_PER_PAGE = 30;
