import { Pagination } from 'features/case-law-types';
import { BaseData, CountryState, ListOfItems, PATCH_HEADERS, backendApiClient } from '../index';

const BASE_URL = '/attorney_profiles';
const BASE_WORKING_HOURS_URL = '/working_hours/';
const BASE_ATTORNEY_PROFILE_CASE_LAW_TYPE_URL = '/attorney_profile_case_law_types';

export type CreateAttorneyProfileResponse = {
  '@context': string;
  '@id': string;
  '@type': 'AttorneyProfile';
  active: boolean;
  caseLawTypes: string[];
};

export type UpdateAttorneyProfileParams = {
  barNumber?: string;
  businessAddress?: string;
  attorneyProfileId: string;
};
export type GetAttorneyProfileResponse = {
  '@context': string;
  '@id': string;
  '@type': string;
  id?: string;
  barNumber: string;
  businessAddress: {
    '@context': string;
    '@id': string;
    '@type': string;
    title: string;
    type: string;
    nameOfAddressee: string;
    zipCode: string;
    town: string;
    stateCode: string;
    countryCode: string;
    createdAt: string;
  };
  active: boolean;
  caseLawTypes: CaseLawType[];
  description: string;
  workingHours: GetWorkingHoursResponse;
};
export type CaseLawType = {
  '@context': string;
  '@id': string;
  '@type': string;
  title: string;
};
export type PatchAttorneyProfilePayload = Partial<{
  barNumber: string;
  description: string;
  businessAddress: string;
  caseLawTypes: CaseLawType[];
}>;

export type UpdateAttorneyProfileResponse = CreateAttorneyProfileResponse & {};
export type UpdateAttorneyProfileLawCaseResponse = CreateAttorneyProfileResponse & {};

export type GetWorkingHoursResponse = {
  '@context'?: string;
  '@id': string;
  '@type': 'WorkingHours';
  fridayEnabled: boolean;
  fridayFrom: string;
  fridayTo: string;
  mondayEnabled: boolean;
  mondayFrom: string;
  mondayTo: string;
  saturdayEnabled: boolean;
  saturdayFrom: string;
  saturdayTo: string;
  sundayEnabled: boolean;
  sundayFrom: string;
  sundayTo: string;
  thursdayEnabled: boolean;
  thursdayFrom: string;
  thursdayTo: string;
  tuesdayEnabled: boolean;
  tuesdayFrom: string;
  tuesdayTo: string;
  wednesdayEnabled: boolean;
  wednesdayFrom: string;
  wednesdayTo: string;
};

export type AttorneyProfileCaseLawType = BaseData<''> & {
  caseLawType: Omit<CaseLawType, '@context'>;
  states: CountryState[];
};

export type CreateAttorneyProfileCaseLawTypePayload = {
  caseLawType: 'string';
  states: string[];
};

export type UpdateAttorneyProfileCaseLawTypePayload = {
  states: string[];
};

export const createAttorneyService = () => {
  const createAttorneyProfile = async (code: string | null) => {
    try {
      return await backendApiClient({}).post<CreateAttorneyProfileResponse>(BASE_URL, {
        barNumber: code,
      });
    } catch (error) {
      throw error;
    }
  };

  const updateAttorneyProfile = async ({
    attorneyProfileId,
    businessAddress,
    barNumber,
  }: UpdateAttorneyProfileParams | undefined) => {
    try {
      return await backendApiClient({
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }).patch<UpdateAttorneyProfileResponse>(attorneyProfileId, {
        ...(barNumber ? { barNumber } : {}),
        ...(businessAddress ? { businessAddress } : {}),
      });
    } catch (error) {
      throw error;
    }
  };

  const updateAttorneyProfileLawTypes = async (attorneyProfileId: string, caseLawTypes: ReadonlyArray<string>) => {
    try {
      return await backendApiClient({ headers: PATCH_HEADERS }).patch<UpdateAttorneyProfileLawCaseResponse>(
        `${attorneyProfileId}/case_law_types`,
        {
          caseLawTypes,
        }
      );
    } catch (error) {
      throw error;
    }
  };
  const getAttorneyProfileById = async (attorneyId: string) => {
    try {
      const { data: profile } = await backendApiClient({}).get<GetAttorneyProfileResponse>(BASE_URL + '/' + attorneyId);

      return profile;
    } catch (error) {
      throw error;
    }
  };

  const patchAttorneyProfileById = async (attorneyId: string, patchAttorneyPayload: PatchAttorneyProfilePayload) => {
    try {
      return await backendApiClient({
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }).patch<PatchAttorneyProfilePayload>(BASE_URL + '/' + attorneyId, patchAttorneyPayload);
    } catch (error) {
      throw error;
    }
  };

  const putAttorneyCaseLawTypeById = async (attorneyId: string, putAttorneyPayload: { caseLawTypes: string[] }) => {
    try {
      return await backendApiClient({
        headers: PATCH_HEADERS,
      }).patch<PatchAttorneyProfilePayload>(BASE_URL + `/${attorneyId}/case_law_types`, putAttorneyPayload);
    } catch (error) {
      throw error;
    }
  };

  const deleteAttorneyCaseLawTypeById = async (attorneyId: string, caseLawTypeId: string) => {
    try {
      return await backendApiClient({}).delete<PatchAttorneyProfilePayload>(
        BASE_URL + `/${attorneyId}/case_law_types/${caseLawTypeId}`
      );
    } catch (error) {
      throw error;
    }
  };

  const getWorkingHoursById = async (workingHoursId: string) => {
    try {
      const { data } = await backendApiClient({}).get<GetWorkingHoursResponse>(
        `${BASE_WORKING_HOURS_URL}${workingHoursId}`
      );

      return data;
    } catch (error) {
      throw error;
    }
  };

  const patchWorkingHoursById = async (workingHoursId: string, patchPayload: Partial<GetWorkingHoursResponse>) => {
    try {
      const { data } = await backendApiClient({
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }).patch<GetWorkingHoursResponse>(`${BASE_WORKING_HOURS_URL}${workingHoursId}`, patchPayload);

      return data;
    } catch (error) {
      throw error;
    }
  };

  const getAttorneyProfileCaseLawTypes = async (params: Pagination) =>
    await backendApiClient({}).get<ListOfItems<AttorneyProfileCaseLawType[]>>(BASE_ATTORNEY_PROFILE_CASE_LAW_TYPE_URL, {
      params,
    });

  const getAttorneyProfileCaseLawTypesByAdmin = async (attorneyProfileId: string, params: Pagination) =>
    await backendApiClient({}).get<ListOfItems<AttorneyProfileCaseLawType[]>>(
      `${BASE_ATTORNEY_PROFILE_CASE_LAW_TYPE_URL}/${attorneyProfileId}/list`,
      {
        params,
      }
    );

  const createAttorneyProfileCaseLawTypes = async (payload: CreateAttorneyProfileCaseLawTypePayload) =>
    await backendApiClient({}).post<AttorneyProfileCaseLawType>(BASE_ATTORNEY_PROFILE_CASE_LAW_TYPE_URL, {
      payload,
    });

  const getAttorneyProfileCaseLawTypesById = async (caseLawTypeId: string) =>
    await backendApiClient({}).get<AttorneyProfileCaseLawType>(
      `${BASE_ATTORNEY_PROFILE_CASE_LAW_TYPE_URL}/${caseLawTypeId}`
    );

  const deleteAttorneyProfileCaseLawTypesById = async (caseLawTypeId: string) =>
    await backendApiClient({}).delete(`${BASE_ATTORNEY_PROFILE_CASE_LAW_TYPE_URL}/${caseLawTypeId}`);

  const updateAttorneyProfileCaseLawTypesById = async (
    caseLawTypeId: string,
    payload: UpdateAttorneyProfileCaseLawTypePayload
  ) =>
    await backendApiClient({}).patch<AttorneyProfileCaseLawType>(
      `${BASE_ATTORNEY_PROFILE_CASE_LAW_TYPE_URL}/${caseLawTypeId}`,
      payload,
      { headers: PATCH_HEADERS }
    );

  return {
    getWorkingHoursById,
    patchWorkingHoursById,
    createAttorneyProfile,
    updateAttorneyProfileLawTypes,
    updateAttorneyProfile,
    getAttorneyProfileById,
    patchAttorneyProfileById,
    putAttorneyCaseLawTypeById,
    deleteAttorneyCaseLawTypeById,
    updateAttorneyProfileCaseLawTypesById,
    deleteAttorneyProfileCaseLawTypesById,
    getAttorneyProfileCaseLawTypesById,
    createAttorneyProfileCaseLawTypes,
    getAttorneyProfileCaseLawTypes,
    getAttorneyProfileCaseLawTypesByAdmin,
  };
};
