import { FC, memo, useEffect } from 'react';
import { Grid } from '@mui/material';
import Calendar from 'examples/Calendar';
import { SideMenu } from 'features/common';
import { Loader } from 'features/common/components/Loader';
import { DialogWindow } from 'features/common/components/DialogWindow';
import MDBox from 'components/MDBox';
import { ListOfEvents } from './components/ListOfEvents/ListOfEvents';
import { useEventsState } from './hooks/useEventsState';
import { CalendarConfig, eventDataTransformHandler, getCustomButtons, selectAllowHandler } from './calendar.config';
import { CreateEventModalContent } from './components/CreateEventModalContent';
import { EventContent } from './components/EventContent';

export const CaseEvents: FC<{ isDisabled?: boolean }> = memo(({ isDisabled }) => {
  const {
    calendarRef,
    calendarState,
    caseId,
    closeAllEventsModal,
    closeConfirmDeleteEventModal,
    eventsByDateData,
    formattedDate,
    getMoreEventsByDateHandler,
    handleCustomButtonClick,
    isAddEventModalOpen,
    isAllEventsModalOpen,
    isConfirmDeleteEventModalOpen,
    isEventDataLoading,
    isEventsByDateLoading,
    isLoading,
    onCellClickHandler,
    onClickMoreEventsButtonHandler,
    onCloseModalContentHandler,
    onEventClickHandler,
    onEventDropHandler,
    onFetchEventsHandler,
    onDeleteEventButtonClickHandler,
    onDeleteEventIconClickHandler,
    patchEventHandler,
    createEventHandler,
    onEditIconClickHandler,
    selectedDayRange,
    userFirebaseId,
    userFullName,
    currentEvent,
    events,
    isAttorney,
    myLegalCase,
    backendUser,
    isCaseEvents,
    currentDate,
    onClickConnectToCallButtonHandler,
    onCreateEventButtonClickHandler,
  } = useEventsState();

  useEffect(() => {
    !isDisabled && onFetchEventsHandler();
  }, [caseId]);

  const isAllowActions = isDisabled ? false : true;

  return (
    <>
      <Grid item xs={12} display={isCaseEvents ? 'flex' : 'none'}>
        <MDBox position="relative">
          <Calendar
            calendarRef={calendarRef}
            header={{ title: 'Case events' }}
            selectable={isAllowActions}
            initialView="dayGridMonth"
            initialDate={new Date()}
            dayMaxEvents={1}
            editable={isAllowActions}
            selectAllow={selectAllowHandler}
            eventDataTransform={eventDataTransformHandler}
            eventConstraint={CalendarConfig.eventConstraint}
            eventDrop={onEventDropHandler}
            headerToolbar={isDisabled ? null : CalendarConfig.headerToolbar}
            customButtons={getCustomButtons(handleCustomButtonClick, onCreateEventButtonClickHandler)}
            moreLinkClick={onClickMoreEventsButtonHandler}
            eventDurationEditable={false}
            eventClick={onEventClickHandler}
            events={events}
            eventContent={event => (
              <EventContent
                event={event}
                onDeleteEventIconClickHandler={onDeleteEventIconClickHandler}
                onEditIconClickHandler={onEditIconClickHandler}
                userFirebaseId={backendUser?.firebaseUser}
                onClickConnectToCallButtonHandler={onClickConnectToCallButtonHandler}
              />
            )}
            eventTimeFormat={{ hour: 'numeric', minute: '2-digit', meridiem: 'short' }}
            select={onCellClickHandler}
          />
          {isLoading && (
            <MDBox
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                display: 'flex',
                width: 1,
                height: 1,
                borderRadius: '12px',
                overflow: 'hidden',
                backgroundColor: 'rgba(0, 0, 0, 0)',
                zIndex: ({ zIndex }) => zIndex.fab,
              }}
            >
              <Loader />
            </MDBox>
          )}
        </MDBox>

        <SideMenu
          isOpen={isAddEventModalOpen}
          onClose={onCloseModalContentHandler}
          title={`${currentEvent || isEventDataLoading ? 'Edit' : 'Create'} event`}
          customWidth={450}
        >
          <CreateEventModalContent
            legalCaseId={caseId ? myLegalCase?.['@id'] : null}
            userFirebaseId={userFirebaseId}
            caseNumber={myLegalCase?.caseNumber}
            userFullName={userFullName}
            selectedDate={currentDate || calendarState?.start}
            isAttorney={isAttorney}
            isEventDataLoading={isEventDataLoading}
            createEventHandler={createEventHandler}
            patchEventHandler={patchEventHandler}
            closeAddEventModal={onCloseModalContentHandler}
            currentEventData={
              currentEvent
                ? {
                    noteEnabled: currentEvent?.noteEnabled,
                    note: currentEvent?.note,
                    notificationTime: currentEvent?.notificationTime,
                    startedAt: currentEvent?.startedAt,
                    notificationEnabled: currentEvent?.notificationEnabled,
                    type: currentEvent?.type,
                    id: currentEvent?.id,
                    participants: currentEvent?.participants,
                    legalCase:
                      typeof currentEvent?.legalCase === 'string'
                        ? currentEvent?.legalCase
                        : currentEvent?.legalCase?.['@id'],
                  }
                : null
            }
          />
        </SideMenu>

        <SideMenu isOpen={isAllEventsModalOpen} onClose={closeAllEventsModal} title={formattedDate} customWidth={450}>
          <ListOfEvents
            eventsByDate={eventsByDateData?.events}
            isEventsByDateLoading={isEventsByDateLoading}
            onDeleteEventIconClickHandler={onDeleteEventIconClickHandler}
            onEditIconClickHandler={onEditIconClickHandler}
            totalItems={eventsByDateData.totalItems}
            getMoreEventsByDateHandler={getMoreEventsByDateHandler}
            caseId={caseId ? myLegalCase?.['@id'] : null}
            selectedDayRange={selectedDayRange}
            userFirebaseId={backendUser?.firebaseUser}
            onClickConnectToCallButtonHandler={onClickConnectToCallButtonHandler}
          />
        </SideMenu>

        <DialogWindow
          onCloseDialogHandler={closeConfirmDeleteEventModal}
          onApproveDialogHandler={onDeleteEventButtonClickHandler}
          isDialogOpen={isConfirmDeleteEventModalOpen}
          dialogTitle="Are you sure want to delete the event?"
        />
      </Grid>
    </>
  );
});
