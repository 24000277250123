import { ObjectSchema } from 'yup';
import * as yup from 'yup';

export type EditStaticPageFormSchema = Partial<{
  contentTitle: string;
  contentBody: string;
  urlPath: string;
}>;

const URL_PATH_REGEX = /^[a-z0-9_-]+$/;

export const editStaticPageFormSchema: ObjectSchema<EditStaticPageFormSchema> = yup
  .object({
    contentTitle: yup.string().required('This field is required').trim().label('Content Title'),
    urlPath: yup
      .string()
      .required('This field is required')
      .matches(URL_PATH_REGEX, 'This field must contains only lowercase characters without spaces')
      .label('Url'),
    contentBody: yup.string().required('This field is required').label('Content body'),
  })
  .required();
