import { FC, useEffect, useState } from 'react';
import { Autocomplete, Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';
import {
  BaseFormField,
  ClioExportMapping,
  ToastType,
  crmIntegrationApiService,
  notice,
  useBoolean,
} from 'features/common';
import { PracticeAreaCategory } from 'features/clio-settings/ClioSettingsApiService';
import { ExportCaseToClioMenuContentProps } from './types';
import { ExportCaseToClioValidationScheme, exportCaseToClioValidatationSchema } from './form.config';
import { DialogWindow } from 'features/common/components/DialogWindow';

export const ExportCaseToClioMenuContent: FC<ExportCaseToClioMenuContentProps> = ({
  updateLegalCaseHandler,
  onCloseMenuHandler,
  caseId,
}) => {
  const { getClioExportMapping, exportCaseToClio } = crmIntegrationApiService();

  const [clioMappingValues, setClioMappingValues] = useState<ClioExportMapping | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const onGetClioExportMapping = async () => {
    try {
      const { data } = await getClioExportMapping(caseId);
      setClioMappingValues(data);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      console.error(error);
      if (error?.response?.status === 422) {
        notice(ToastType.ERROR, error?.response?.data?.['hydra:description']);
      } else {
        paymentRequiredErrorHandler(error);
      }
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting, isDirty },
  } = useForm<ExportCaseToClioValidationScheme>({
    mode: 'onChange',
    resolver: yupResolver(exportCaseToClioValidatationSchema),
    values: {
      practiceArea: clioMappingValues?.practiceArea,
    },
  });

  useEffect(() => {
    onGetClioExportMapping();
  }, []);

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      closeExportDialog();
      const { data } = await exportCaseToClio({
        legalCase: `/legal_cases/${caseId}`,
        practiceArea: formData.practiceArea?.id,
      });
      updateLegalCaseHandler(data);
      onCloseMenuHandler();
      notice(ToastType.SUCCESS, 'Legal case has been successfully exported!');
    } catch (error) {
      console.error(error);
      paymentRequiredErrorHandler(error);
    }
  });

  const isSubmitButtonDisabled = !isValid || isSubmitting;

  const [isExportDialogOpen, openExportDialog, closeExportDialog] = useBoolean(false);

  return (
    <MDBox
      component="form"
      onSubmit={onFormSubmitHandler}
      my={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <Stack spacing={2.5} flex={1}>
        <Controller
          control={control}
          name="practiceArea"
          render={({ field: { value, onChange } }) => {
            const onChangeHandler = async (_: React.SyntheticEvent<Element, Event>, option: PracticeAreaCategory) =>
              onChange(option);
            return (
              <Autocomplete
                value={value || null}
                getOptionLabel={option => option?.value}
                options={clioMappingValues?.practiceAreas || []}
                loading={isLoading}
                isOptionEqualToValue={(option, value) => option?.['@id'] === value?.['@id']}
                onChange={onChangeHandler}
                sx={{ width: 1, flex: 1 }}
                freeSolo={false}
                disableClearable
                renderInput={params => (
                  <BaseFormField
                    formInputProps={{
                      ...params,
                      label: 'Practice Area',
                      fullWidth: true,
                    }}
                    errorValue={errors?.practiceArea?.message}
                  />
                )}
              />
            );
          }}
        />
      </Stack>

      <MDButton
        variant="gradient"
        color="info"
        type="button"
        fullWidth
        disabled={isSubmitButtonDisabled}
        isLoading={isSubmitting}
        onClick={openExportDialog}
      >
        Export
      </MDButton>

      <DialogWindow
        onCloseDialogHandler={closeExportDialog}
        onApproveDialogHandler={onFormSubmitHandler}
        isDialogOpen={isExportDialogOpen}
        dialogTitle="Are you sure you want to export this case?"
      />
    </MDBox>
  );
};
