import { useAsync } from '../../common';
import { useEffect } from 'react';
import { CreateQuestionResponse, PatchQuestionPayload, createFAQService } from '../services';

export const useEditFAQ = (selectedQuestionId: string) => {
  const { getQuestionById, patchQuestionById } = createFAQService();
  const [loadQuestionState, loadQuestionHandler] = useAsync(async () => {
    if (!selectedQuestionId) {
      return;
    }
    const { data } = await getQuestionById(selectedQuestionId);
    return data;
  });
  const [patchQuestionState, patchQuestionHandler] = useAsync(async (patchQuestionPayload: PatchQuestionPayload) => {
    if (questionData) {
      return await patchQuestionById(selectedQuestionId, patchQuestionPayload);
    }
  });

  useEffect(() => {
    if (selectedQuestionId) {
      loadQuestionHandler();
    }
  }, [selectedQuestionId]);

  const isLoading = loadQuestionState.isPending();
  const questionData: CreateQuestionResponse | null =
    (loadQuestionState.isSuccessful() && loadQuestionState.data) || null;

  return {
    loadQuestionState,
    patchQuestionState,
    patchQuestionHandler,
    questionData,
    isLoading,
    patchQuestionById,
  };
};
