import { SyntheticEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, AutocompleteInputChangeReason, CircularProgress } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { CreateBaseUserProfileSchema } from 'features/auth';
import {
  BaseFormField,
  Country,
  CountryState,
  ToastType,
  createBackendUserService,
  notice,
  useAsync,
  useGeoData,
  useUser,
} from 'features/common';
import { baseBackendErrorHandler } from 'features/common/errorHanders';
import { BaseAutocompleteField } from 'features/common/components/BaseAutocompleteField';
import { PERSONAL_TITLES } from 'features/common/mocks/values';
import { useDebounce } from 'features/common/hooks/useDebounce';
import { editUserProfileFormSchema } from '../templates/formSchema';
import { userProfileService } from '../services/userProfileService';
import { PhoneField } from 'features/common/components/PhoneField';
import { isPhoneValid } from 'features/common/helpers/utilities';

export const ProfileForm: React.FC = () => {
  const { backendUser, isAdmin, connectBackendUserHandler } = useUser();
  const [selectedPersonTitle, setSelectedPersonTitle] = useState('');

  const [selectedTown, setSelectedTown] = useState('');
  const [reason, setReason] = useState<AutocompleteInputChangeReason | null>(null);

  const [selectedState, setSelectedState] = useState<CountryState | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);

  const { patchProfile } = userProfileService();
  const { updateBackendUser } = createBackendUserService();

  const { control, register, formState, handleSubmit, setValue, setError, clearErrors, getValues } =
    useForm<CreateBaseUserProfileSchema>({
      resolver: yupResolver(editUserProfileFormSchema),
      mode: 'onTouched',
      defaultValues: {
        email: backendUser?.email || '',
        personTitle: backendUser?.userProfile?.personTitle || '',
        phone: backendUser?.phone || '',
        firstName: backendUser.userProfile?.firstName,
        lastName: backendUser.userProfile?.lastName,
      },
    });

  const {
    onCountrySelect,
    onCountryStateSelect,
    onTownCityFieldInput,
    onGetCountryListHandlerState,
    isTownCityFieldInputLoading,
    countryList,
    countryStateList,
    cityList,
  } = useGeoData();

  const [editBaseUserProfileState, editBaseUserProfileSubmitHandler] = useAsync(
    async (formData: CreateBaseUserProfileSchema) => {
      try {
        const isValid = isPhoneValid(formData.phone);

        if (!isValid && !backendUser.phone) {
          setError('phone', { message: 'Enter a valid phone number' });
          return;
        }

        await patchProfile({
          userProfile: {
            personTitle: formData?.personTitle || undefined,
            firstName: formData.firstName,
            lastName: formData.lastName,
            country: selectedCountry?.['@id'],
            state: selectedState?.['@id'],
            town: formData.town,
          },
        });

        if (isAdmin && !backendUser?.phone) {
          await updateBackendUser(backendUser?.firebaseUser, { phone: formData?.phone });
        }
        connectBackendUserHandler();
        notice(ToastType.SUCCESS, `Successfully updated profile`);
      } catch (error) {
        baseBackendErrorHandler(error, { formError: { formData, setError } });
      }
    }
  );
  const isFormInvalid = Object.entries(formState.errors).length > 0;
  const isFormDirty =
    selectedState &&
    countryStateList.length !== 0 &&
    (Object.entries(formState.dirtyFields).length !== 0 ||
      selectedTown !== backendUser.userProfile?.town ||
      selectedCountry?.['@id'] !== backendUser.userProfile?.country?.['@id'] ||
      selectedState?.['@id'] !== backendUser.userProfile?.state?.['@id'] ||
      selectedPersonTitle !== backendUser.userProfile?.personTitle);

  const connectCountry = async () => {
    const currentCountry = countryList.find(country => backendUser.userProfile?.country?.['@id'] === country?.['@id']);

    if (currentCountry?.id && !selectedCountry) {
      await onCountrySelect(currentCountry?.id);
      setValue('country', currentCountry?.id);
      setSelectedCountry(currentCountry);
      return;
    }
    if (!currentCountry?.id && !selectedState) {
      await onCountrySelect('US');
      return;
    }
  };

  const connectCountryState = async () => {
    const currentState = countryStateList.find(state => backendUser.userProfile?.state?.['@id'] === state?.['@id']);
    if (!selectedState && currentState?.isoCode) {
      await onCountryStateSelect(currentState?.id);
      setValue('state', currentState?.isoCode);
      setSelectedState(currentState);
    }
  };

  const connectPersonTitle = () => {
    const currentPersonTitle = backendUser.userProfile?.personTitle;
    setSelectedPersonTitle(currentPersonTitle);
    setValue('personTitle', currentPersonTitle || '');
  };

  const connectTown = () => {
    if (!selectedTown) {
      const currentTown = backendUser.userProfile?.town;
      const currentUserTown = cityList?.find(city => city.name === currentTown);

      setValue('town', currentUserTown?.name || currentTown);
      setSelectedTown(currentUserTown?.name || currentTown);
    }
  };

  useEffect(() => {
    !selectedPersonTitle && connectPersonTitle();

    countryList?.length && !selectedCountry && connectCountry();

    countryStateList?.length && !selectedState && connectCountryState();

    cityList?.length && !selectedTown && !reason && connectTown();
  }, [countryList, countryStateList, cityList]);

  const onChangeTownHandler = async (event: SyntheticEvent<Element, Event>, value: string) => {
    if (value) {
      setValue('town', value);
      setSelectedTown(value);
      formState.errors.town?.message && clearErrors('town');
    }
  };

  const onChangeTownInputValueHandler = async (
    event: SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setValue('town', value || '');
    setSelectedTown(value);
    setReason(reason);
    formState.errors.town?.message && clearErrors('town');

    if (reason === 'clear' && cityList?.length < 10) {
      setReason(reason);
      await onCountryStateSelect(selectedState['@id']);
    }

    if (reason === 'input' && !value && !cityList.length) {
      setReason(reason);
      await onCountryStateSelect(selectedState['@id']);
    }
  };
  const debouncedValue = useDebounce(selectedTown, 250);

  useEffect(() => {
    const fetchCityList = async () => {
      await onTownCityFieldInput({ cityName: debouncedValue });
      setReason(null);
    };
    if (selectedTown?.length >= 3 && reason === 'input') {
      fetchCityList();
    }
  }, [debouncedValue]);

  return (
    <form onSubmit={handleSubmit(editBaseUserProfileSubmitHandler)}>
      <MDBox display="flex" flexDirection="row" gap="8px" width="full">
        <MDBox display="flex" flexDirection="column" width="50%" gap="8px">
          <BaseAutocompleteField
            options={countryList}
            getOptionLabel={option => option.name}
            value={selectedCountry}
            isDisabled={!countryList?.length}
            onChangeHandler={async value => {
              if (value && typeof value !== 'string') {
                setValue('country', value['@id']);
                setValue('state', '');
                setSelectedState(null);
                setSelectedCountry(value);
                clearErrors('country');
                await onCountrySelect(value['@id']);
              }
            }}
            errorMessage={formState.errors['country']?.message}
            isLoading={onGetCountryListHandlerState.isPending()}
            formInputProps={{
              label: 'Country',
            }}
          />
          <Autocomplete
            value={selectedPersonTitle || null}
            getOptionLabel={option => option}
            isOptionEqualToValue={(option, value) => option === value}
            options={PERSONAL_TITLES}
            onChange={(event, value) => {
              setValue('personTitle', value || '');
              setSelectedPersonTitle(value || '');
            }}
            renderInput={params => (
              <BaseFormField
                formInputProps={{
                  ...params,
                  label: 'Person title',
                  fullWidth: true,
                }}
                errorValue={formState.errors['personTitle']?.message}
              />
            )}
          />
          <BaseFormField
            formInputProps={{
              ...register('firstName'),
              type: 'text',
              label: 'First name',
              fullWidth: true,
            }}
            errorValue={formState.errors['firstName']?.message}
          />

          <BaseFormField
            formInputProps={{
              ...register('email'),
              type: 'text',
              label: 'Email',
              fullWidth: true,
              disabled: Boolean(backendUser?.email),
            }}
          />
        </MDBox>
        <MDBox
          display="flex"
          flexDirection="column"
          width="50%"
          gap="8px"
          sx={{
            '&.MuiAutocomplete-popperDisablePortal, .MuiAutocomplete-popper': {
              display: !cityList.length ? 'none !important' : 'block !important',
            },
          }}
        >
          <BaseAutocompleteField
            options={countryStateList}
            getOptionLabel={option => option.name}
            value={selectedState}
            isDisabled={!countryStateList?.length}
            onChangeHandler={async value => {
              if (value) {
                setValue('state', value['@id']);
                setValue('town', '');
                setSelectedState(value);
                setSelectedTown('');
                clearErrors('state');
                await onCountryStateSelect(value['@id']);
              }
            }}
            errorMessage={formState.errors['state']?.message}
            isLoading={onGetCountryListHandlerState.isPending()}
            formInputProps={{
              label: 'State',
            }}
          />
          <Autocomplete
            value={selectedTown}
            isOptionEqualToValue={(option, value) => (option ? option === value : null)}
            disablePortal
            getOptionLabel={option => option}
            options={cityList.map(option => option.name)}
            {...register('town')}
            onChange={onChangeTownHandler}
            onInputChange={onChangeTownInputValueHandler}
            freeSolo
            disableClearable={false}
            loading={isTownCityFieldInputLoading}
            renderInput={params => (
              <BaseFormField
                formInputProps={{
                  ...params,
                  label: 'Town/City',
                  fullWidth: true,
                  InputLabelProps: { shrink: true },
                  InputProps: {
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isTownCityFieldInputLoading ? (
                          <MDBox pr={0} display="flex">
                            <CircularProgress color="inherit" size={20} />
                          </MDBox>
                        ) : null}
                        {formState.errors['town']?.message ? null : params.InputProps.endAdornment}
                      </>
                    ),
                  },
                }}
                errorValue={formState.errors['town']?.message}
              />
            )}
          />
          <BaseFormField
            formInputProps={{
              ...register('lastName'),
              type: 'text',
              label: 'Last name',
              fullWidth: true,
            }}
            errorValue={formState.errors['lastName']?.message}
          />

          <Controller
            control={control}
            name="phone"
            render={({ field }) => {
              const onChange = (value: string) => field.onChange(value);
              return (
                <PhoneField
                  disabled={Boolean(backendUser?.phone)}
                  {...field}
                  onChange={onChange}
                  label="Phone number"
                  errorValue={formState.errors['phone']?.message}
                />
              );
            }}
          />
        </MDBox>
      </MDBox>
      <MDBox mt={1} mb={1}>
        <MDButton
          variant="gradient"
          color="info"
          type="submit"
          sx={{ textTransform: 'none' }}
          isLoading={editBaseUserProfileState.isPending()}
          disabled={!isFormDirty || isFormInvalid || editBaseUserProfileState.isPending()}
        >
          Save
        </MDButton>
      </MDBox>
    </form>
  );
};
