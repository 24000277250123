import { useEffect, useRef, useState } from 'react';

export const useCountDown = (delay = 1000) => {
  const [secondsToLeft, setSecondsToLeft] = useState<number | null>(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (secondsToLeft <= 0 || secondsToLeft === null) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      return;
    }

    timeoutRef.current = setTimeout(() => {
      setSecondsToLeft(secondsToLeft - 1);
    }, delay);

    return () => clearTimeout(timeoutRef?.current);
  }, [secondsToLeft]);

  const startTimer = (seconds: number) => setSecondsToLeft(seconds);

  const stopTimer = () => {
    setSecondsToLeft(null);
    if (timeoutRef?.current) {
      clearTimeout(timeoutRef?.current);
      timeoutRef.current = null;
    }
  };

  return {
    startTimer,
    stopTimer,
    secondsToLeft,
  };
};
