import { backendApiClient } from '../../common';

export type CaseLawType = {
  '@id': string;
  '@type': string;
  title: string;
  createdAt?: string;
};

export type CaseLawTypeCollectionResponse = {
  '@context': string;
  '@id': string;
  '@type': string;
  'hydra:member': CaseLawType[];
};

export const createLawTypesService = () => {
  const getLawTypes = async () => {
    return await backendApiClient({}).get<CaseLawTypeCollectionResponse>('/case_law_types');
  };

  return { getLawTypes };
};
