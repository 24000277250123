import { PhoneNumberUtil } from 'google-libphonenumber';

export const getAvatarCharacters = (firstName?: string, lastName?: string) =>
  firstName && lastName ? `${firstName[0]}${lastName[0]}` : 'U';

export const getFormattedFileSize = (fileSize: number) => {
  const fileSizeInKB = (fileSize / 1000).toFixed(2);
  const fileSizeInMB = (Number(fileSizeInKB) / 1000).toFixed(2);

  let formattedSize;
  if (Number(fileSizeInMB) >= 1) {
    formattedSize = fileSizeInMB + ' MB';
  } else {
    formattedSize = fileSizeInKB + ' KB';
  }
  return formattedSize;
};

export const checkIsFileSizeGreaterThanValue = (fileSize: string | number, valueToCheck: number = 25) =>
  Number(fileSize) / 1000 ** 2 > valueToCheck;

export const removeEmptyFields = <EntryObject extends Record<string, unknown>>(object: EntryObject) => {
  Object.keys(object).forEach((field: keyof EntryObject) => {
    if (!object[field]) {
      delete object[field];
    }
  });
};

export const getOptionById = <T extends { '@id': string; id?: string }>(
  id: string,
  options: T[],
  optionToCompare: '@id' | 'id' = '@id'
): T | null => {
  return id
    ? options.find(option => {
        return id === option[optionToCompare];
      }) ?? null
    : null;
};

export function formatIntervalTime(time: number) {
  const minutes = String(Math.floor(time / 60)).padStart(2, '0');
  const seconds = String(time % 60).padStart(2, '0');
  return `${minutes}:${seconds}`;
}

export function formatSecondsToMinutes(seconds: number | string) {
  const minutes = String(BigInt(Math.floor((Number(seconds) || 0) / 60)));
  return minutes;
}

export function formatMinutesToSeconds(minutes: number | string) {
  const seconds = String(BigInt(Math.floor((Number(minutes) || 0) * 60)));
  return seconds;
}

export function sortArray<T>(arrayToSort: T[]) {
  return arrayToSort.sort((a, b) => (a > b ? 1 : -1));
}

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};
