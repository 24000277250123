import { FC } from 'react';
import { Grid } from '@mui/material';
import { CaseLawTypeMapping } from '../Components';

export const ClionIntegrationSettingsTemplate: FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CaseLawTypeMapping />
      </Grid>
    </Grid>
  );
};
