import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card } from '@mui/material';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js';
import {
  BaseFormField,
  DashboardLayout,
  notice,
  RichTextEditor,
  ToastType,
  useBoolean,
  useRouter,
} from 'features/common';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { convertEditorStateValueToHTML } from 'features/common/helpers/textEditor';
import { ROUTES } from 'features/common/variables/routes';
import { createStaticPagesService } from './../services';
import { EditStaticPageFormSchema, editStaticPageFormSchema } from './formSchema';
import { baseBackendErrorHandler } from '../../common/errorHanders';
import { ButtonsFooter } from '../components';

export const CreateStaticPageTemplate = (): JSX.Element => {
  const router = useRouter();
  const { createStaticPage } = createStaticPagesService();

  const [editorState, setEditorState] = useState(null);
  const [isDiscardChangesModalVisible, enableDiscardChangesModal, disableDiscardChangesModal] = useBoolean(false);

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty, errors },
    register,
    setError,
    setValue,
    reset,
  } = useForm<EditStaticPageFormSchema>({
    resolver: yupResolver(editStaticPageFormSchema),
    mode: 'onTouched',
  });

  const onSubmitFormHandler = async (data: EditStaticPageFormSchema) => {
    try {
      if (!editorState) return;

      await createStaticPage({
        contentTitle: data.contentTitle,
        urlPath: data.urlPath,
        contentBody: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      });

      await router.push(ROUTES.staticPages);

      notice(ToastType.SUCCESS, `Successfully created static page by /static/${data.urlPath} path`);
    } catch (error) {
      console.error(error);
      baseBackendErrorHandler(error, { formError: { formData: data, setError } });
    }
  };

  const onEditorStateChangeHandler = (editorState: EditorState) => {
    setValue('contentBody', convertEditorStateValueToHTML(editorState), { shouldValidate: true });
    setEditorState(editorState);
  };

  const isSaveButtonDisabled = !isValid || isSubmitting;

  const onClickDiscardButtonHandler = () => {
    setEditorState(EditorState.createEmpty());
    reset();
    disableDiscardChangesModal();
  };

  return (
    <DashboardLayout>
      <form onSubmit={handleSubmit(onSubmitFormHandler)}>
        <MDBox sx={{ display: 'flex', flexDirection: 'column', gap: '16px', mx: 'auto' }}>
          <Card sx={{ p: '16px' }}>
            <MDBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <MDTypography variant="h4" fontWeight="bold" sx={{ mb: '8px' }}>
                Create New Content Page
              </MDTypography>
              <MDBox
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: { xs: 0, sm: 4 },
                  flexDirection: { xs: 'column', sm: 'row' },
                }}
              >
                <BaseFormField
                  formFieldProps={{ sx: { flex: 1 } }}
                  formInputProps={{
                    ...register('contentTitle'),
                    type: 'text',
                    label: 'Title',
                    fullWidth: true,
                    color: 'white',
                  }}
                  errorValue={errors['contentTitle']?.message}
                />
                <BaseFormField
                  formFieldProps={{ sx: { flex: 1 } }}
                  formInputProps={{
                    ...register('urlPath'),
                    type: 'text',
                    label: 'Url Path',
                    fullWidth: true,
                    color: 'white',
                  }}
                  errorValue={errors['urlPath']?.message}
                />
              </MDBox>
            </MDBox>
          </Card>
          <Card sx={{ p: '16px' }}>
            <RichTextEditor editorState={editorState} onEditorStateChange={onEditorStateChangeHandler} />
            {errors['contentBody']?.message && (
              <MDTypography fontSize={10} mt={0.1} color="error">
                *{errors['contentBody']?.message}
              </MDTypography>
            )}
          </Card>
          <ButtonsFooter
            isDiscardButtonDisabled={!isDirty || isSubmitting}
            onClickDiscardButtonHandler={enableDiscardChangesModal}
            isSaveButtonDisabled={isSaveButtonDisabled}
            isLoading={isSubmitting}
            saveButtonTitle="Create new page"
          />
        </MDBox>
      </form>
      <DialogWindow
        onCloseDialogHandler={disableDiscardChangesModal}
        onApproveDialogHandler={onClickDiscardButtonHandler}
        isDialogOpen={isDiscardChangesModalVisible}
        dialogTitle="Are you sure you want to discard your changes? All unsaved data will be deleted!"
      />
    </DashboardLayout>
  );
};
