import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthFlowBackgrounds } from 'features/auth/variables/illustartion';
import { IllustrationLayout, ToastType, notice, useRouter } from 'features/common';
import { FirebaseResetPasswordFormSchema, firebaseResetPasswordFormValidationSchema } from './form.config';
import { ROUTES } from 'features/common/variables/routes';
import { BasePasswordFormField } from 'features/common/components/BasePasswordFormField';
import { useResponsive } from 'features/common/hooks/useResponsive';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { baseFirebaseAuthErrorHandler, createFirebaseService } from 'features/auth';
import { FirebaseError } from 'firebase/app';
import { useDeviceDetection } from 'features/common/hooks/useDeviceDetection';

export const MOBILE_APP_DEEP_LINK = 'app.legalbeagle://loginPage';

export const ResetPasswordTemplate: FC = () => {
  const { confirmResetPassword } = createFirebaseService();

  const device = useDeviceDetection();

  const router = useRouter();
  const [searchParams, _] = useSearchParams();

  const {
    register,
    formState: { isSubmitting, isValid, errors },
    handleSubmit,
  } = useForm<FirebaseResetPasswordFormSchema>({
    resolver: yupResolver(firebaseResetPasswordFormValidationSchema),
    mode: 'onTouched',
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  });

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      const oobCode = searchParams.get('oobCode');

      if (oobCode) {
        await confirmResetPassword(oobCode, formData?.password);
        notice(ToastType.SUCCESS, 'Password successfully changed!');

        device === 'Mobile' ? (window.location.href = MOBILE_APP_DEEP_LINK) : router.navigate(ROUTES.signIn);
      }
    } catch (error) {
      console.error(error);
      if (error instanceof FirebaseError) {
        baseFirebaseAuthErrorHandler(error);
        router.navigate(ROUTES.signIn);
        return;
      } else {
        notice(ToastType.ERROR, 'Something went wrong, please try again!');
      }
    }
  });

  const isSmallScreen = useResponsive('between', 'xs', 'sm');
  const isLgScreens = useResponsive('up', 'md');

  return (
    <IllustrationLayout
      title="Password reset"
      description="Create new password"
      illustration={AuthFlowBackgrounds.AUTH_ONE}
    >
      <MDBox component="form" display="flex" flexDirection="column" gap="8px" onSubmit={onFormSubmitHandler}>
        <BasePasswordFormField
          formFieldProps={{
            pb:
              isSmallScreen && errors?.['password']?.type === 'matches'
                ? '45px'
                : isLgScreens && errors?.['password']?.type === 'matches'
                ? '30px'
                : '18px',
          }}
          formInputProps={{
            ...register('password'),
            label: 'New password',
            fullWidth: true,
          }}
          errorValue={errors?.['password']?.message}
        />
        <BasePasswordFormField
          formFieldProps={{ mb: 1 }}
          formInputProps={{
            ...register('passwordConfirmation'),
            label: 'Confirm Password',
            fullWidth: true,
          }}
          errorValue={errors?.['passwordConfirmation']?.message}
        />

        <MDButton
          type="submit"
          fullWidth
          variant="gradient"
          color="info"
          isLoading={isSubmitting}
          disabled={isSubmitting || !isValid}
        >
          Save new password
        </MDButton>
      </MDBox>
    </IllustrationLayout>
  );
};
