import { ToastType, notice } from 'features/common';
import {
  GetConfigsParams,
  GetConfigsResponse,
  GetEmailTemplatesResponse,
  PatchConfigPayload,
  settingsApiService,
} from 'features/settings/SettingsApiService';
import { CriticalUpdateVersion } from 'features/settings/UrgentVersionsApiService';
import { createStore } from '../createStore';
import { abortRequestErrorHandler } from 'features/common/errorHanders';

type UrgentVersionsData = {
  urgentVersions: CriticalUpdateVersion[];
  totalItems: number;
};

export type SettingsState = {
  settings: GetConfigsResponse[];
  totalItems: number | null;
  emailTemplates: GetEmailTemplatesResponse[];
  emailTemplatesTotalItems: number | null;
  urgentVersionsData: UrgentVersionsData;
  lastUrgentVersions: CriticalUpdateVersion[];
};

export type SettingsActions = {
  fetchSettingsHandler: (params?: GetConfigsParams) => Promise<void>;
  fetchEmailTemplatesHandler: () => Promise<void>;
  updateSettingsHandler: (id: string, payload: PatchConfigPayload, isToastMessage?: boolean) => Promise<void>;
  setLastUrgentVersions: (data: CriticalUpdateVersion[]) => void;
  setUrgentVersions: (data: CriticalUpdateVersion[], totalItems: number) => void;
};

const { getConfigs, patchConfig, getEmailTemplates } = settingsApiService();

const initialState: SettingsState = {
  settings: [],
  totalItems: null,
  emailTemplates: [],
  emailTemplatesTotalItems: null,
  lastUrgentVersions: [],
  urgentVersionsData: { totalItems: null, urgentVersions: [] },
};

export const useSettings = createStore<SettingsState & SettingsActions>(
  set => ({
    ...initialState,
    setLastUrgentVersions: lastUrgentVersions => {
      set({ lastUrgentVersions });
    },
    setUrgentVersions: (urgentVersions, totalItems) => {
      set({ urgentVersionsData: { urgentVersions, totalItems } });
    },

    fetchEmailTemplatesHandler: async () => {
      try {
        const { data } = await getEmailTemplates({ page: 1, itemsPerPage: 100 });

        set({
          emailTemplates: data['hydra:member'],
          emailTemplatesTotalItems: data['hydra:totalItems'],
        });
      } catch (error) {
        notice(ToastType.ERROR, 'Failed to fetch email templates, please try again!');
        console.error(error);
      }
    },
    fetchSettingsHandler: async params => {
      try {
        const { data } = await getConfigs(params);
        set({ settings: data['hydra:member'], totalItems: data['hydra:totalItems'] });
      } catch (error) {
        abortRequestErrorHandler(error, 'Failed to fetch settings, please try again!');
      }
    },
    updateSettingsHandler: async (id, payload, isToastMessage = true) => {
      try {
        const { data } = await patchConfig(id, payload);

        set(state => ({
          settings: state.settings.map(config => (config.id === data.id ? data : config)),
        }));

        isToastMessage && notice(ToastType.SUCCESS, 'Successfully updated!');
      } catch (error) {
        notice(ToastType.ERROR, 'Failed to update settings, please try again!');
        console.error(error);
      }
    },
  }),
  'adminSettings',
  initialState
);
