import { BaseData, backendApiClient } from 'features/common';

export type FetchStripeFormData = {
  fetchToken: string;
};

export type SubscriptionStatus = 'paid' | 'lead' | 'canceled' | 'enterprise' | 'trial';

export type UserSubscriptionStatus = BaseData<'UserSubscriptionCheckingDto'> & {
  hasSubscription: boolean;
  status: SubscriptionStatus;
};

export type FetchStripeResponse = BaseData<'StripeReceiptDto'> & {
  usedPromoCode: boolean;
};

export type SubscriptionURL = BaseData<'StripeSessionDto'> & {
  paymentUrl: string;
};

export enum PAYMENT_PERIOD {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
  TWO_YEARS = 'twoYears',
}

export type GenerateSubscriptionURLPayload = {
  enterprise: boolean;
  successUrl: string;
  cancelUrl: string;
  period?: PAYMENT_PERIOD;
};

export type UnsubscribeResponse = {
  deactivated: boolean;
  hasSubscriptionInAnotherStores: boolean;
};

export const subscriptionsApiService = () => {
  const fetchStripe = (data: FetchStripeFormData) => {
    return backendApiClient({}).post<FetchStripeResponse>('/users/stripe_fetch', data);
  };

  const checkSubscriptionStatus = () => {
    return backendApiClient({}).get<UserSubscriptionStatus>('/users/subscription_checking');
  };

  const generateSubscriptionURL = async (payload: GenerateSubscriptionURLPayload) => {
    return await backendApiClient({}).post<SubscriptionURL>('/stripe/generate_payment_url', payload);
  };

  const unsubscribe = async () => {
    return await backendApiClient({}).post<UnsubscribeResponse>('/stripe/unsubscribe', {});
  };

  return {
    fetchStripe,
    checkSubscriptionStatus,
    generateSubscriptionURL,
    unsubscribe,
  };
};
