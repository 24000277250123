import { FC } from 'react';
import { Stack } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { getAvatarCharacters } from 'features/common/helpers/utilities';
import MDAvatar from 'components/MDAvatar';
import MDTypography from 'components/MDTypography';
import { MenuPopover } from 'features/common/components/MenuPopover';
import { UserOverviewContent } from 'features/case-details/components/CaseInfo/UserOverviewContent';
import { useUserProfile } from 'features/common/hooks/useUserProfile';
import { SideMenu } from 'features/common';
import { CompanyInfoSideMenuContent } from 'features/my-company/components/CompanyInfoSideMenuContent';
import { CardHeaderProps } from './types';

export const CardHeader: FC<CardHeaderProps> = ({
  attorneyFirebaseid,
  firstName,
  attorneyLogo,
  lastName,
  companyShortName,
}) => {
  const avatarCharacters = getAvatarCharacters(firstName, lastName);
  const isUserFullname = firstName && lastName;

  const {
    isUserOverviewMenuOpen,
    isUserProfileOverviewLoading,
    onCloseUserOverviewHandler,
    onUserAvatarClickHandler,
    userRatings,
    userProfileOverview,
    isOpenCompanyInfoMenu,
    onClickViewCompanyProfileButtonHandler,
    onCloseCompanyInfoMenuHandler,
  } = useUserProfile();

  const onClickAvatarHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event?.stopPropagation();
    onUserAvatarClickHandler(event);
  };

  const onClosePopoverHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event?.stopPropagation();
    onCloseUserOverviewHandler();
  };

  return (
    <Stack direction="row" spacing={1.5} alignItems="center" position="relative">
      <MDAvatar
        src={attorneyLogo || ''}
        alt="user avatar"
        sx={{
          backgroundColor: theme => theme.palette.info.main,
          '& img': {
            height: 1,
            borderRadius: '50%',
          },
        }}
        id={attorneyFirebaseid}
        onClick={!isUserFullname ? undefined : onClickAvatarHandler}
      >
        {!isUserFullname ? <PersonOutlineIcon fontSize="medium" /> : avatarCharacters}
      </MDAvatar>
      <Stack>
        <MDTypography variant="button" fontWeight="medium">
          {!isUserFullname ? 'Unrepresented' : `${firstName} ${lastName}`}
        </MDTypography>
        {isUserFullname && (
          <MDTypography variant="button" fontWeight="medium">
            {companyShortName}
          </MDTypography>
        )}
      </Stack>

      <MenuPopover open={isUserOverviewMenuOpen} onClose={onClosePopoverHandler} arrow="top-left">
        <UserOverviewContent
          attorneyDescription={userProfileOverview?.attorneyProfile?.description}
          lawTypes={userProfileOverview?.attorneyProfile?.caseLawTypes}
          country={userProfileOverview?.userProfile?.country?.name}
          state={userProfileOverview?.userProfile?.state?.name}
          isLoading={isUserProfileOverviewLoading}
          userRatings={userRatings}
          workingHours={userProfileOverview?.attorneyProfile?.workingHours}
          companyInfo={{
            companyFullName: userProfileOverview?.company?.fullName,
            companyLogo: userProfileOverview?.company?.logo?.publicUrl,
          }}
          onClickViewCompanyProfileButtonHandler={onClickViewCompanyProfileButtonHandler}
        />
      </MenuPopover>

      <SideMenu
        customWidth={450}
        isOpen={isOpenCompanyInfoMenu}
        onClose={onCloseCompanyInfoMenuHandler}
        title="Company profile"
      >
        <CompanyInfoSideMenuContent companyId={userProfileOverview?.company?.['@id']} />
      </SideMenu>
    </Stack>
  );
};
