import * as yup from 'yup';
import { CreateCriticalUpdateVersionPayload, OS } from 'features/settings/UrgentVersionsApiService';

export type CreateUrgentVersionValidationScheme = Pick<CreateCriticalUpdateVersionPayload, 'version'> & {
  os: OS | null;
};

const VERSION_REGEX = /^([0-9]+(\.[0-9]+){2})$/gi;

export const createUrgentVersionValidatationSchema: yup.ObjectSchema<CreateUrgentVersionValidationScheme> = yup
  .object({
    os: yup.string<OS>().required().label('Operation System'),
    version: yup
      .string()
      .required()
      .label('Version')
      .max(16)
      .matches(VERSION_REGEX, { message: 'Version value should be like x.x.x' }),
  })
  .required();

export const OS_OPTIONS: { '@id': OS; title: string }[] = [
  { title: 'iOS', '@id': OS.IOS },
  { title: 'Android', '@id': OS.ANDROID },
];
