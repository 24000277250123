import { FC } from 'react';
import { Badge, Card, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material//Search';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { BaseFormField } from 'features/common';
import MDButton from 'components/MDButton';

type FiltersBarProps = {
  onOpenFiltersMenuHandler: () => void;
  onChangeSearchInputHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filtersCount: number;
};

export const FiltersBar: FC<FiltersBarProps> = ({
  onOpenFiltersMenuHandler,
  onChangeSearchInputHandler,
  filtersCount,
}) => {
  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between">
        <BaseFormField
          formFieldProps={{
            pb: 0,
          }}
          formInputProps={{
            label: 'Search',
            placeholder: 'Find the cases',
            width: '100%',
            onChange: onChangeSearchInputHandler,
            InputProps: {
              startAdornment: <SearchIcon fontSize="medium" />,
            },
          }}
        />

        <MDButton
          disableRipple
          variant="contained"
          onClick={onOpenFiltersMenuHandler}
          endIcon={
            <Badge badgeContent={filtersCount} color="error" sx={{ '.MuiBadge-badge': { right: '-5px' } }}>
              <FilterListOutlinedIcon fontSize="small" />
            </Badge>
          }
        >
          Filters
        </MDButton>
      </Stack>
    </Card>
  );
};
