import { FC, useState } from 'react';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { Dialog, Stack } from '@mui/material';
import { shallow } from 'zustand/shallow';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { BaseAvatar } from 'features/common/components/BaseAvatar';
import MDTypography from 'components/MDTypography';
import { AgoraEventType, agoraEventsApiService } from 'features/common/services/agoraEventApiService';
import { ToastType, controller, notice, resetAbortContoller, useRouter } from 'features/common';
import { useAudioVideoChat, useLockScreen } from 'features/store';
import { ROUTES } from 'features/common/variables/routes';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';

type CallInvitationPopupProps = {
  isOpen: boolean;
  onClosehandler: () => void;
  userCallerFirstName: string;
  userCallerLastName: string;
  userCallerLogoURL: string;
  eventId: string;
  eventType: AgoraEventType;
  userCallerFirebaseId: string;
  onToggleSoundHandler: () => void;
};

export const CallInvitationPopup: FC<CallInvitationPopupProps> = ({
  isOpen,
  onClosehandler,
  userCallerFirstName,
  userCallerLastName,
  userCallerLogoURL,
  eventId,
  onToggleSoundHandler,
  eventType,
  userCallerFirebaseId,
}) => {
  const { navigate, pathname } = useRouter();
  const { pushNotificationCallCancel, pushNotificationCallCancelAnonymous } = agoraEventsApiService();
  const { setEventId, setStartCallProps } = useAudioVideoChat(state => ({
    setEventId: state.setEventId,
    setStartCallProps: state.setStartCallProps,
  }));

  const { isRedirectToLockScreen, deviceId, setCallData } = useLockScreen(
    state => ({
      setCallData: state.setCallData,
      isRedirectToLockScreen: state.isRedirectToLockScreen,
      deviceId: state.deviceId,
    }),
    shallow
  );

  const isLockScreen = isRedirectToLockScreen && deviceId && pathname === ROUTES.lockScreen;

  const [isDeclining, setIsDeclining] = useState(false);

  const onClickAcceptHandler = async () => {
    setEventId(eventId);
    setStartCallProps({ endParticipantFirebaseId: userCallerFirebaseId, eventType, caseId: '' });
    onToggleSoundHandler();
    onClosehandler();

    if (isLockScreen) {
      setCallData({ endParticipantFirebaseId: userCallerFirebaseId, eventId, eventType });
      notice(ToastType.INFO, 'To continue, please enter your PIN code');
      return;
    }
    navigate(eventType === 'voice_call' ? ROUTES.audioRoom : ROUTES.videoRoom, {
      state: {
        prevRoute: pathname,
      },
    });
  };

  const onClickDeclineButtonHandler = async () => {
    try {
      if (controller.signal.aborted) resetAbortContoller();
      setIsDeclining(true);
      if (isLockScreen) await pushNotificationCallCancelAnonymous(eventId, deviceId);
      else await pushNotificationCallCancel(eventId, {});
      onClosehandler();
      setIsDeclining(false);
    } catch (error) {
      paymentRequiredErrorHandler(error);
      setIsDeclining(false);
      console.error(error);
    }
  };

  return (
    <Dialog open={isOpen}>
      <MDBox sx={{ p: 2, minWidth: 250, maxWidth: 350, width: 1 }}>
        <Stack spacing={2}>
          <Stack spacing={1} justifyContent="center" alignItems="center">
            <BaseAvatar
              size="lg"
              userFirstName={userCallerFirstName}
              userLastName={userCallerLastName}
              src={userCallerLogoURL}
            />
            <MDTypography>{`${userCallerFirstName} ${userCallerLastName}`}</MDTypography>
            <Stack direction="row" alignItems="center" spacing={1}>
              {eventType === 'video_call' ? (
                <VideocamOutlinedIcon fontSize="medium" />
              ) : (
                <MicNoneOutlinedIcon fontSize="medium" />
              )}
              <MDTypography variant="button" fontSize={16}>
                Calling you...
              </MDTypography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
            <MDButton variant="gradient" color="info" onClick={onClickAcceptHandler}>
              Accept
            </MDButton>

            <MDButton
              disabled={isDeclining}
              isLoading={isDeclining}
              variant="gradient"
              color="dark"
              onClick={onClickDeclineButtonHandler}
            >
              Decline
            </MDButton>
          </Stack>
        </Stack>
      </MDBox>
    </Dialog>
  );
};
