import { useState } from 'react';
import { ClioEnum, clioEnumApiService } from '../services';
import { ToastType, notice } from '../modules';
import { BASE_ERROR_MESSAGE } from '../errorHanders';

type BaseEnumData<T> = {
  data: T[];
  totalItems: number | null;
  isLoading: boolean;
};

export const useClioEnums = () => {
  const {
    getClioMappingLegalCaseAddressPositionEnum,
    getClioMappingPracticeAreaCategoriesEnum,
    getClioMappingLegalCaseNumberPositionEnum,
    getClioMappingClientAddressPositionEnum,
    getClioMappingClientEmailPositionEnum,
    getClioMappingClientPhonePositionEnum,
  } = clioEnumApiService();

  const [clioMappingLegalCaseAddressPositionEnum, setClioMappingLegalCaseAddressPositionEnum] = useState<
    BaseEnumData<ClioEnum>
  >({ data: [], isLoading: true, totalItems: 0 });

  const [clioMappingPracticeAreaCategoriesEnum, setClioMappingPracticeAreaCategoriesEnum] = useState<
    BaseEnumData<ClioEnum>
  >({
    data: [],
    isLoading: true,
    totalItems: 0,
  });

  const [clioMappingLegalCaseNumberPositionEnum, setClioMappingLegalCaseNumberPositionEnum] = useState<
    BaseEnumData<ClioEnum>
  >({
    data: [],
    isLoading: true,
    totalItems: 0,
  });

  const [clioMappingClientAddressPositionEnum, setClioMappingClientAddressPositionEnum] = useState<
    BaseEnumData<ClioEnum>
  >({
    data: [],
    isLoading: true,
    totalItems: 0,
  });

  const [clioMappingClientEmailPositionEnum, setClioMappingClientEmailPositionEnum] = useState<BaseEnumData<ClioEnum>>({
    data: [],
    isLoading: true,
    totalItems: 0,
  });

  const [clioMappingClientPhonePositionEnum, setClioMappingClientPhonePositionEnum] = useState<BaseEnumData<ClioEnum>>({
    data: [],
    isLoading: true,
    totalItems: 0,
  });

  const onGetClioMappingClientPhonePositionEnum = async () => {
    try {
      const { data } = await getClioMappingClientPhonePositionEnum();
      setClioMappingClientPhonePositionEnum(prevState => ({
        ...prevState,
        data: data['hydra:member'],
        totalItems: data['hydra:totalItems'],
        isLoading: false,
      }));
    } catch (error) {
      setClioMappingClientPhonePositionEnum(prevState => ({
        ...prevState,
        isLoading: false,
      }));
      console.error(error);
      notice(ToastType.ERROR, BASE_ERROR_MESSAGE);
    }
  };

  const onGetClioMappingClientEmailPositionEnum = async () => {
    try {
      const { data } = await getClioMappingClientEmailPositionEnum();
      setClioMappingClientEmailPositionEnum(prevState => ({
        ...prevState,
        data: data['hydra:member'],
        totalItems: data['hydra:totalItems'],
        isLoading: false,
      }));
    } catch (error) {
      setClioMappingClientEmailPositionEnum(prevState => ({
        ...prevState,
        isLoading: false,
      }));
      console.error(error);
      notice(ToastType.ERROR, BASE_ERROR_MESSAGE);
    }
  };

  const onGetClioMappingClientAddressPositionEnum = async () => {
    try {
      const { data } = await getClioMappingClientAddressPositionEnum();
      setClioMappingClientAddressPositionEnum(prevState => ({
        ...prevState,
        data: data['hydra:member'],
        totalItems: data['hydra:totalItems'],
        isLoading: false,
      }));
    } catch (error) {
      setClioMappingClientAddressPositionEnum(prevState => ({
        ...prevState,
        isLoading: false,
      }));
      console.error(error);
      notice(ToastType.ERROR, BASE_ERROR_MESSAGE);
    }
  };

  const onGetClioMappingLegalCaseNumberPositionEnum = async () => {
    try {
      const { data } = await getClioMappingLegalCaseNumberPositionEnum();
      setClioMappingLegalCaseNumberPositionEnum(prevState => ({
        ...prevState,
        data: data['hydra:member'],
        totalItems: data['hydra:totalItems'],
        isLoading: false,
      }));
    } catch (error) {
      setClioMappingLegalCaseNumberPositionEnum(prevState => ({
        ...prevState,
        isLoading: false,
      }));
      console.error(error);
      notice(ToastType.ERROR, BASE_ERROR_MESSAGE);
    }
  };

  const onGetClioMappingLegalCaseAddressPositionEnum = async () => {
    try {
      const { data } = await getClioMappingLegalCaseAddressPositionEnum();
      setClioMappingLegalCaseAddressPositionEnum(prevState => ({
        ...prevState,
        data: data['hydra:member'],
        totalItems: data['hydra:totalItems'],
        isLoading: false,
      }));
    } catch (error) {
      setClioMappingLegalCaseAddressPositionEnum(prevState => ({
        ...prevState,
        isLoading: false,
      }));
      console.error(error);
      notice(ToastType.ERROR, BASE_ERROR_MESSAGE);
    }
  };

  const onGetClioMappingPracticeAreaCategoriesEnums = async () => {
    try {
      const { data } = await getClioMappingPracticeAreaCategoriesEnum();
      setClioMappingPracticeAreaCategoriesEnum(prevState => ({
        ...prevState,
        data: data['hydra:member'],
        totalItems: data['hydra:totalItems'],
        isLoading: false,
      }));
    } catch (error) {
      setClioMappingPracticeAreaCategoriesEnum(prevState => ({
        ...prevState,
        isLoading: false,
      }));
      console.error(error);
      notice(ToastType.ERROR, BASE_ERROR_MESSAGE);
    }
  };

  return {
    clioMappingLegalCaseAddressPositionEnum,
    clioMappingPracticeAreaCategoriesEnum,
    clioMappingLegalCaseNumberPositionEnum,
    clioMappingClientAddressPositionEnum,
    clioMappingClientEmailPositionEnum,
    clioMappingClientPhonePositionEnum,
    onGetClioMappingClientPhonePositionEnum,
    onGetClioMappingClientEmailPositionEnum,
    onGetClioMappingClientAddressPositionEnum,
    onGetClioMappingLegalCaseNumberPositionEnum,
    onGetClioMappingLegalCaseAddressPositionEnum,
    onGetClioMappingPracticeAreaCategoriesEnums,
  };
};
