import * as yup from 'yup';
import { PASSWORD_VALIDATION_HELPER_TEXT, passwordRegExp } from 'features/auth';

export type FirebaseResetPasswordFormSchema = Partial<{
  password: string;
  passwordConfirmation: string;
}>;

export const firebaseResetPasswordFormValidationSchema: yup.ObjectSchema<FirebaseResetPasswordFormSchema> = yup
  .object({
    password: yup
      .string()
      .required('Password field is required')
      .matches(passwordRegExp, PASSWORD_VALIDATION_HELPER_TEXT),
    passwordConfirmation: yup
      .string()
      .required('Password confirmation field is required')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })
  .required();
