import { FC, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { CircularProgress, Stack } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { getAvatarCharacters } from 'features/common/helpers/utilities';
import { BaseStoreAsyncActionFunction } from 'features/store';
import MDAvatar from 'components/MDAvatar';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import { getNotificationIconInfo } from '../notification.config';
import { TransitionType } from '../NotificationCenterApiService';

type NotificationModalItemProps = {
  firstName?: string;
  lastName?: string;
  logoURL?: string | null;
  message: string;
  transitionType: TransitionType;
  id: string;
  createdAt: string;
  caseNumber: string;
  legalCaseId: string;
  opponentFirebaseId: string;
  onCloseMenuHandler: () => void;
  viewedAt: string | null;
  markNotificationAsReadHandler: BaseStoreAsyncActionFunction;
  isAllowTransition: boolean;
};

export const NotificationModalItem: FC<NotificationModalItemProps> = ({
  message,
  firstName,
  lastName,
  id,
  caseNumber,
  createdAt,
  logoURL,
  legalCaseId,
  opponentFirebaseId,
  viewedAt,
  isAllowTransition,
  transitionType,
  markNotificationAsReadHandler,
  onCloseMenuHandler,
}) => {
  const avatarCharacters = getAvatarCharacters(firstName, lastName);
  const isUserFullname = firstName && lastName;

  const distanceToNow = formatDistanceToNow(new Date(createdAt), { addSuffix: true });

  const iconData = getNotificationIconInfo({
    caseNumber,
    opponentFirebaseId,
    notificationId: id,
    legalCaseId,
    transitionType,
    markNotificationAsReadHandler,
    viewedAt,
    isAllowTransition,
  });

  const IconComponent = iconData?.icon;

  const [isLoading, setIsLoading] = useState(false);

  const onNotificationClickHandler = async () => {
    setIsLoading(true);
    await iconData?.onClickHandler();
    onCloseMenuHandler();
    setIsLoading(false);
  };

  return (
    <Stack
      id={id}
      direction="row"
      width={1}
      spacing={1.5}
      alignItems="flex-start"
      position="relative"
      sx={{
        p: 1.5,
        ...(iconData?.onClickHandler && {
          cursor: 'pointer',
        }),
      }}
      onClick={onNotificationClickHandler}
    >
      <MDAvatar
        src={logoURL || ''}
        alt="user avatar"
        sx={{
          backgroundColor: theme => theme.palette.info.main,
          '& img': {
            height: 1,
            borderRadius: '50%',
          },
        }}
      >
        {!isUserFullname ? <PersonOutlineIcon fontSize="medium" /> : avatarCharacters}
      </MDAvatar>
      <Stack width={1}>
        <MDTypography variant="button" fontWeight="medium" mr={1.2}>
          {`${firstName} ${lastName}`}
        </MDTypography>

        <MDTypography variant="button" sx={{ fontWeight: 400, whiteSpace: 'initial' }}>
          {message}
        </MDTypography>
        <Stack direction="row" spacing={1} alignItems="center" mt={1}>
          <Stack direction="row" spacing={1} alignItems="center" flex={1}>
            {iconData?.icon && (
              <MDBox
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  backgroundColor: iconData?.backgroundColor,
                  width: 25,
                  height: 25,
                }}
              >
                <IconComponent sx={{ color: 'white !important' }} />
              </MDBox>
            )}
            {caseNumber && (
              <MDBox
                sx={{
                  display: 'flex',
                  p: '2px 4px',
                  alignItems: 'center',
                  borderRadius: '30px',
                  backgroundColor: ({ palette }) => palette.grey[100],
                }}
              >
                <MDTypography variant="button">{caseNumber}</MDTypography>
              </MDBox>
            )}
          </Stack>
          <MDTypography variant="button">{distanceToNow}</MDTypography>
        </Stack>
      </Stack>
      {!viewedAt && (
        <MDBox
          component="p"
          sx={{
            position: 'absolute',
            top: '15px',
            right: '10px',
            content: '""',
          }}
        >
          {isLoading ? (
            <CircularProgress size={15} />
          ) : (
            <MDBox
              component="span"
              sx={{
                display: 'flex',
                width: 10,
                height: 10,
                borderRadius: '50%',
                backgroundColor: ({ palette }) => palette.info.light,
              }}
            />
          )}
        </MDBox>
      )}
    </Stack>
  );
};
