import { SupportedFileTypes } from 'features/my-legal-cases/components/CreateLegalCase/form.config';
import { BaseData, backendApiClient } from '../index';
import { FileSignProvider } from './fileSignApiService';

export type BucketType = 'public' | 'security';
export type CreateFileRequest = {
  type?: string;
  title?: string;
  file: any;
  bucketType: BucketType;
};
export type CreateFileResponse = GetFileByIdResponse;

export type FilePermissionProvider = {
  provider: FileSignProvider;
  label: string;
};

export type FilePermission = BaseData<'FilePermissionDto'> & {
  canBeDelete: boolean;
  canBeDownload: boolean;
  canBeRequestToSign: boolean;
  canBeSign: boolean;
  needToSign: boolean;
  signed: boolean;
  template: boolean;
  requestedToSign: boolean;
  providers: FilePermissionProvider[];
  activeProvider: boolean;
};

export type GetFileByIdResponse = BaseData<'File'> & {
  '@context': string;
  id: string;
  type: SupportedFileTypes;
  title: string;
  fileSize: number;
  fileExtension: string;
  updatedAt: string;
  template: boolean;
  publicUrl?: string;
  filePermission: FilePermission;
};

export type PatchFilePayload = Partial<{
  barNumber: string;
  businessAddress: string;
  caseLawType: [];
}>;

export type SignFileResponse = BaseData<'FileRequestSignDto'> & {
  urlForSigner: string;
};

const BASE_URL = '/files';

export const createFileService = () => {
  const createFile = async (data: FormData) => {
    try {
      return await backendApiClient({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).post<CreateFileResponse>(BASE_URL, data);
    } catch (error) {
      throw error;
    }
  };

  const getFileById = async (fileId: string) => {
    try {
      const { data: file } = await backendApiClient({}).get<GetFileByIdResponse>(BASE_URL + '/' + fileId);

      return file;
    } catch (error) {
      throw error;
    }
  };

  const deleteFileById = async (fileId: string) => {
    try {
      const { data: file } = await backendApiClient({}).delete<GetFileByIdResponse>(BASE_URL + '/' + fileId);

      return file;
    } catch (error) {
      throw error;
    }
  };

  const downloadFileById = async (fileId: string) => {
    try {
      const { data } = await backendApiClient({ responseType: 'blob' }).get(`${BASE_URL}/${fileId}/download`);

      return data;
    } catch (error) {
      throw error;
    }
  };

  const signFile = async (fileId: string) =>
    await backendApiClient({}).post<SignFileResponse>(`${BASE_URL}/${fileId}/sign`, {});
  const makeFileSigned = async (fileId: string) => await backendApiClient({}).post(`${BASE_URL}/${fileId}/signed`, {});

  return {
    downloadFileById,
    createFile,
    getFileById,
    deleteFileById,
    makeFileSigned,
    signFile,
  };
};
