import MDBox from 'components/MDBox';
import { FC } from 'react';

type UnwatchedMessagesCounterProps = {
  unwatchedMessages: number;
};

export const UnwatchedMessagesCounter: FC<UnwatchedMessagesCounterProps> = ({ unwatchedMessages }) => {
  return (
    <MDBox
      component="span"
      sx={{
        borderRadius: '10px',
        backgroundColor: theme => theme.palette.info.main,
        display: 'flex',
        p: '1px 0',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white !important',
        fontSize: 12,
        minWidth: 20,
        height: 20,
        ml: '2px',
      }}
    >
      {unwatchedMessages > 99 ? '99+' : unwatchedMessages}
    </MDBox>
  );
};
