import { immer } from 'zustand/middleware/immer';
import { ToastType, notice } from 'features/common';
import {
  AgoraEvent,
  CreateAgoraEventResponse,
  CreateAgoraEventPayload,
  GetAgoraEventsParams,
  agoraEventsApiService,
  PatchAgoraEventPayload,
} from 'features/common/services/agoraEventApiService';
import { createStore } from '../createStore';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders/paymentRequiredErrorHandler';

export type MyEventsState = {
  events: AgoraEvent[];
  currentEvent: CreateAgoraEventResponse | null;
  totalItems: number | null;
  eventsByDateData: {
    events: AgoraEvent[];
    totalItems: number | null;
  };
};

export type MyEventsActions = {
  getEventsHandler: (params?: GetAgoraEventsParams) => Promise<void>;
  getEventByIdHandler: (id: string) => Promise<void>;
  createEventHandler: (payload: CreateAgoraEventPayload) => Promise<void>;
  setCurrentEvent: (event: CreateAgoraEventResponse | null) => void;
  sendNotification: (eventId: string) => Promise<void>;

  deleteEventHandler: (eventId: string) => Promise<void>;
  editEventHandler: (eventId: string, payload: PatchAgoraEventPayload) => Promise<void>;

  getEventsByDateHandler: (params?: GetAgoraEventsParams) => Promise<void>;
  getMoreEventsByDateHandler: (params?: GetAgoraEventsParams) => Promise<void>;
};

const { getAgoraEvent, createAgoraEvent, getAgoraEvents, createNotification, deleteAgoraEvent, patchAgoraEvent } =
  agoraEventsApiService();

const initialState: MyEventsState = {
  events: [],
  totalItems: null,
  currentEvent: null,
  eventsByDateData: {
    events: [],
    totalItems: null,
  },
};

export const useMyEvents = createStore<MyEventsState & MyEventsActions>(
  immer(set => ({
    ...initialState,
    sendNotification: async (eventId: string) => {
      try {
        await createNotification(eventId);
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to send notification, please try again!');
        console.error(error);
      }
    },
    deleteEventHandler: async eventId => {
      try {
        await deleteAgoraEvent(eventId);
        set(state => {
          state.eventsByDateData.events = state.eventsByDateData.events.filter(el => el.id !== eventId);
          state.eventsByDateData.totalItems = state.eventsByDateData.totalItems
            ? (state.eventsByDateData.totalItems -= 1)
            : state.eventsByDateData.totalItems;

          state.events = state.events.filter(el => el.id !== eventId);
          state.totalItems = state.totalItems ? (state.totalItems -= 1) : state.totalItems;
        });

        notice(ToastType.SUCCESS, 'Successfully deleted!');
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to delete event, please try again!');
        console.error(error);
      }
    },
    editEventHandler: async (eventId, payload) => {
      try {
        const data = await patchAgoraEvent(eventId, payload);

        set(state => {
          state.eventsByDateData.events = state.eventsByDateData.events.map(el =>
            el.id === eventId ? { ...el, ...data } : el
          );

          state.events = state.events.map(el => (el.id === eventId ? { ...el, ...data } : el));

          state.currentEvent = null;
        });
        notice(ToastType.SUCCESS, 'Successfully edited!');
      } catch (error) {
        notice(ToastType.ERROR, 'Failed to edit event, please try again!');
        console.error(error);
      }
    },
    getEventsByDateHandler: async params => {
      try {
        const data = await getAgoraEvents(params);
        set(state => {
          state.eventsByDateData.events = data['hydra:member'];
          state.eventsByDateData.totalItems = data['hydra:totalItems'];
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch events, please try again!');
        console.error(error);
      }
    },
    getMoreEventsByDateHandler: async params => {
      try {
        const data = await getAgoraEvents(params);
        set(state => {
          state.eventsByDateData.events.push(...data['hydra:member']);
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch events, please try again!');
        console.error(error);
      }
    },
    setCurrentEvent: event => {
      set(state => {
        state.currentEvent = event;
      });
    },
    createEventHandler: async payload => {
      try {
        const { data } = await createAgoraEvent(payload);
        set(state => {
          state.events.push(data);
        });

        notice(ToastType.SUCCESS, 'Successfully created!');
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to create event, please try again!');
        console.error(error);
      }
    },

    getEventByIdHandler: async id => {
      try {
        const { data } = await getAgoraEvent(id);
        set(state => {
          state.currentEvent = data;
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch event, please try again!');
        console.error(error);
      }
    },
    getEventsHandler: async params => {
      try {
        const data = await getAgoraEvents(params);
        set(state => {
          state.events = data['hydra:member'];
          state.totalItems = data['hydra:totalItems'];
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch events, please try again!');
        console.error(error);
      }
    },
  })),
  'myEvents',
  initialState
);
