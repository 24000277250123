import { FC, useState } from 'react';
import { Stack } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { MessageWrapper } from './Message.styles';
import { MessageTimestamp } from './MessageTimestamp';

type PromptMessageProps = {
  isMine: boolean;
  messageText: string;
  isShowButtons: boolean;
  declineRepresentationRequest: () => Promise<void>;
  approveRepresentationRequest: () => Promise<void>;
  createdAt: string;
};

export const PromptMessage: FC<PromptMessageProps> = ({
  isMine,
  approveRepresentationRequest,
  declineRepresentationRequest,
  messageText,
  isShowButtons,
  createdAt,
}) => {
  const [isRejecting, setIsRejecting] = useState(false);

  const [isApproving, setIsApproving] = useState(false);

  const onClickDeclineRepresentationHandler = async () => {
    setIsRejecting(true);
    await declineRepresentationRequest();
    setIsRejecting(false);
  };

  const onClickApproveRepresentationHandler = async () => {
    setIsApproving(true);
    await approveRepresentationRequest();
    setIsApproving(false);
  };

  return (
    <>
      {isShowButtons ? (
        <Stack
          sx={{ alignSelf: isMine ? 'flex-end' : 'flex-start', width: 'auto', maxWidth: '50%', mb: '4px' }}
          spacing={1}
        >
          <MDBox
            sx={{
              padding: '4px',
              borderRadius: '8px',
              backgroundColor: theme => (isMine ? theme.palette.info.main : theme.palette.grey[100]),
              '& span': {
                color: theme => (isMine ? 'white !important' : theme.palette.grey[700]),
              },
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              flexDirection: 'column',
              '& span + span': {
                ml: 1,
              },
            }}
          >
            <MDTypography variant="button">The attorney made representation offer. Whould you accept?</MDTypography>

            <MessageTimestamp createdAt={createdAt} isMine={isMine} />
          </MDBox>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <MDButton
              onClick={onClickApproveRepresentationHandler}
              isLoading={isApproving}
              disabled={isApproving}
              variant="gradient"
              color="info"
              fullWidth
            >
              Accept
            </MDButton>

            <MDButton
              onClick={onClickDeclineRepresentationHandler}
              isLoading={isRejecting}
              disabled={isRejecting}
              variant="gradient"
              color="dark"
              fullWidth
            >
              Decline
            </MDButton>
          </Stack>
        </Stack>
      ) : (
        <MessageWrapper isMine={isMine}>
          <MDTypography variant="button">{messageText}</MDTypography>

          <MessageTimestamp createdAt={createdAt} isMine={isMine} />
        </MessageWrapper>
      )}
    </>
  );
};
