import { ObjectSchema } from 'yup';
import * as yup from 'yup';

export type CreateFAQFormSchema = EditFAQFormSchema;
export type EditFAQFormSchema = { question: string; answer: string };

export const createFAQFormSchema: ObjectSchema<CreateFAQFormSchema> = yup
  .object({
    question: yup.string().required().trim().label('Question'),
    answer: yup.string().required().trim().label('Answer'),
  })
  .required();
