import { FC, useEffect, useState } from 'react';
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Stack,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormField, Country, CountryState, useGeoData, useUser } from 'features/common';
import { BaseAutocompleteField } from 'features/common/components/BaseAutocompleteField';
import { useDebounce } from 'features/common/hooks/useDebounce';
import MDTypography from 'components/MDTypography';
import { UploadAvatar } from 'features/common/components/UploadAvatar';
import { useDocument } from 'features/common/hooks/useDocument';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { RegisterCompanySchema, registerCompanyValidationSchema } from './components/RegisterCompanyForm';
import { useMyCompany } from 'features/store';
import { PhoneField } from 'features/common/components/PhoneField';

export const CompanyInfoTemplate: FC = () => {
  const { myCompany, uploadCompanyLogo, updateUserCompanyHandler } = useMyCompany();
  const backendUser = useUser()?.backendUser;

  const country = myCompany?.headquarterAddress?.country;
  const state = myCompany?.headquarterAddress?.state;
  const {
    setError,
    register,
    formState: { errors, isSubmitting, isValid, isDirty },
    handleSubmit,
    clearErrors,
    control,
  } = useForm<RegisterCompanySchema>({
    resolver: yupResolver(registerCompanyValidationSchema),
    mode: 'onTouched',
    defaultValues: {
      companyEmail: myCompany?.email,
      fullName: myCompany?.fullName,
      description: myCompany?.description,
      companyPhone: myCompany?.phone,
      shortName: myCompany?.shortName,
      site: myCompany?.site,
      zipCode: myCompany?.headquarterAddress?.zipCode,
      addressLine1: myCompany?.headquarterAddress?.addressLine1,
      addressLine2: myCompany?.headquarterAddress?.addressLine2,
      country: country?.['@id'],
      state: state?.['@id'],
      town: myCompany?.headquarterAddress?.town,
    },
  });

  const {
    onCountryStateSelect,
    onTownCityFieldInput,
    getStateListHandler,
    onGetCountryListHandlerState,
    isTownCityFieldInputLoading,
    countryList,
    countryStateList,
    cityList,
    isCityListLoading,
    isStateListLoading,
  } = useGeoData();

  const [selectedCountry, setSelectedCountry] = useState<Country | null>(country || null);
  const [selectedState, setSelectedState] = useState<CountryState | null>(state || null);
  const [selectedTown, setSelectedTown] = useState(myCompany?.headquarterAddress?.town || '');
  const [reason, setReason] = useState<AutocompleteInputChangeReason | null>(null);
  const [isAvatarUploading, setIsAvatarUploading] = useState(false);

  useEffect(() => {
    country && getStateListHandler(country?.['@id']);
    if (country && state) {
      onCountryStateSelect(state?.['@id']);
    }
  }, []);

  const debouncedValue = useDebounce(selectedTown, 250);

  useEffect(() => {
    const fetchCityList = async () => {
      await onTownCityFieldInput({ cityName: debouncedValue });

      setReason(null);
    };
    if (selectedTown?.length >= 3 && reason === 'input') {
      fetchCityList();
    }
  }, [debouncedValue]);

  const createFormDataHandler = useDocument().createFormDataHandler;

  const onInputFileUploadHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    const formData = createFormDataHandler(file, 'public');

    setIsAvatarUploading(true);
    await uploadCompanyLogo(formData);
    setIsAvatarUploading(false);
  };

  const onUpdateCompanyHandler = async (formData: RegisterCompanySchema) =>
    await updateUserCompanyHandler(formData, setError);

  const onFormSubmitHandler = handleSubmit(onUpdateCompanyHandler);

  const isSaveButtonDisabled = !isDirty || !isValid || isSubmitting;

  const isCurrentUserCompanyOwner = myCompany?.owner?.firebaseUser === backendUser?.firebaseUser;
  const currentUserRoleCompanyRole = !isCurrentUserCompanyOwner ? backendUser?.companyUser?.[0]?.role : null;

  const isAllowToEditCompanyInfo =
    isCurrentUserCompanyOwner ||
    (currentUserRoleCompanyRole === 'ROLE_COMPANY_ADMIN' && myCompany?.userPermissions?.adminCanEditCompanyInfo);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent sx={{ p: 3 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <UploadAvatar
                isDisabled={!isAllowToEditCompanyInfo}
                isLoading={isAvatarUploading}
                onInputFileUploadHandler={onInputFileUploadHandler}
                logoURL={myCompany?.logo?.publicUrl}
              />
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  {myCompany?.fullName}
                </MDTypography>
                <MDTypography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center', fontSize: 14 }}>
                  Rating
                  <MDTypography variant="button" fontWeight="medium" sx={{ ml: 0.5 }}>
                    {myCompany?.rating || 0}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={onFormSubmitHandler}>
          <Card>
            <CardHeader sx={{ px: 3 }} title="Company information" />
            <CardContent sx={{ px: 3, pb: 1, pt: 0 }}>
              <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 0, sm: 1, md: 2 }}>
                <Stack
                  spacing={1}
                  width={1}
                  sx={{
                    '&.MuiAutocomplete-popperDisablePortal, .MuiAutocomplete-popper': {
                      display: !cityList.length ? 'none !important' : 'block !important',
                    },
                  }}
                >
                  <BaseFormField
                    formInputProps={{
                      ...register('fullName'),
                      type: 'text',
                      label: 'Company full name',
                      fullWidth: true,
                      disabled: !isAllowToEditCompanyInfo,
                    }}
                    errorValue={errors['fullName']?.message}
                  />

                  <BaseFormField
                    formInputProps={{
                      ...register('companyEmail'),
                      type: 'text',
                      label: 'Company public email',
                      fullWidth: true,
                      disabled: !isAllowToEditCompanyInfo,
                    }}
                    errorValue={errors['companyEmail']?.message}
                  />

                  <BaseFormField
                    formInputProps={{
                      ...register('description'),
                      type: 'text',
                      label: 'Company description',
                      fullWidth: true,
                      disabled: !isAllowToEditCompanyInfo,
                    }}
                    errorValue={errors['description']?.message}
                  />

                  <Controller
                    control={control}
                    name="country"
                    render={({ field: { onChange } }) => {
                      const onChangeHandler = async (value: Country) => {
                        onChange(value ? value['@id'] : '');
                        setSelectedCountry(value);
                        value && (await getStateListHandler(value['@id']));
                      };
                      return (
                        <BaseAutocompleteField
                          options={countryList}
                          getOptionLabel={option => option.name}
                          value={selectedCountry}
                          isDisabled={!isAllowToEditCompanyInfo}
                          onChangeHandler={onChangeHandler}
                          errorMessage={errors['country']?.message}
                          isLoading={onGetCountryListHandlerState.isPending()}
                          autocompleSxProps={{
                            width: 1,
                            '.MuiInputBase-root': {
                              py: '4.5px',
                            },
                          }}
                          formInputProps={{
                            label: 'Country',
                          }}
                          placeholder="Select a country"
                        />
                      );
                    }}
                  />

                  <Controller
                    control={control}
                    name="town"
                    render={({ field: { onChange } }) => {
                      const onChangeTownHandler = async (_: React.SyntheticEvent<Element, Event>, value: string) => {
                        if (value) {
                          onChange(value);
                          setSelectedTown(value);
                          errors.town?.message && clearErrors('town');
                        }
                      };

                      const onChangeTownInputValueHandler = async (
                        _: React.SyntheticEvent<Element, Event>,
                        value: string,
                        reason: AutocompleteInputChangeReason
                      ) => {
                        onChange(value || '');
                        setSelectedTown(value);
                        setReason(reason);
                        errors.town?.message && clearErrors('town');

                        if (reason === 'clear' && cityList?.length < 10) {
                          setReason(reason);
                          await onCountryStateSelect(selectedState['@id']);
                        }

                        if (reason === 'input' && !value && !cityList.length && selectedState) {
                          setReason(reason);
                          await onCountryStateSelect(selectedState['@id']);
                        }
                      };
                      return (
                        <Autocomplete
                          value={selectedTown}
                          isOptionEqualToValue={(option, value) => (option ? option === value : null)}
                          disablePortal
                          getOptionLabel={option => option}
                          options={cityList.map(option => option.name)}
                          onChange={onChangeTownHandler}
                          onInputChange={onChangeTownInputValueHandler}
                          freeSolo
                          disabled={!isAllowToEditCompanyInfo}
                          disableClearable={false}
                          sx={{
                            '.MuiInputBase-root': {
                              py: '4.5px',
                            },
                          }}
                          loading={isTownCityFieldInputLoading || isCityListLoading}
                          renderInput={params => (
                            <BaseFormField
                              formInputProps={{
                                ...params,
                                label: 'Town/City',
                                placeholder: 'Select a Town/City or enter your own',
                                fullWidth: true,
                                InputLabelProps: { shrink: true },
                                InputProps: {
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {isTownCityFieldInputLoading || isCityListLoading ? (
                                        <MDBox pr={0} display="flex">
                                          <CircularProgress color="inherit" size={20} />
                                        </MDBox>
                                      ) : null}
                                      {errors['town']?.message ? null : params.InputProps.endAdornment}
                                    </>
                                  ),
                                },
                              }}
                              errorValue={errors['town']?.message}
                            />
                          )}
                        />
                      );
                    }}
                  />

                  <BaseFormField
                    formInputProps={{
                      ...register('addressLine1'),
                      type: 'text',
                      label: 'Address line 1',
                      fullWidth: true,
                      disabled: !isAllowToEditCompanyInfo,
                    }}
                    errorValue={errors['addressLine1']?.message}
                  />
                </Stack>

                <Stack spacing={1} width={1}>
                  <BaseFormField
                    formInputProps={{
                      ...register('shortName'),
                      type: 'text',
                      label: 'Company short name',
                      fullWidth: true,
                      disabled: !isAllowToEditCompanyInfo,
                    }}
                    errorValue={errors['shortName']?.message}
                  />
                  <BaseFormField
                    formInputProps={{
                      ...register('site'),
                      type: 'text',
                      label: 'Company site',
                      fullWidth: true,
                      disabled: !isAllowToEditCompanyInfo,
                    }}
                    errorValue={errors['site']?.message}
                  />

                  <Controller
                    control={control}
                    name="companyPhone"
                    render={({ field }) => {
                      const onChange = (value: string) => field.onChange(value);
                      return (
                        <PhoneField
                          label="Company public phone number"
                          disabled={!isAllowToEditCompanyInfo}
                          {...field}
                          onChange={onChange}
                          errorValue={errors['companyPhone']?.message}
                        />
                      );
                    }}
                  />

                  <Controller
                    control={control}
                    name="state"
                    render={({ field: { onChange } }) => {
                      const onChangeHandler = async (value: CountryState) => {
                        if (value) {
                          onChange(value['@id']);
                          setSelectedState(value);
                          setSelectedTown('');
                          await onCountryStateSelect(value['@id']);
                        }
                      };
                      return (
                        <BaseAutocompleteField
                          options={countryStateList}
                          getOptionLabel={option => option.name}
                          value={selectedState}
                          onChangeHandler={onChangeHandler}
                          errorMessage={errors['state']?.message}
                          isLoading={isStateListLoading}
                          isDisabled={!isAllowToEditCompanyInfo}
                          autocompleSxProps={{
                            width: 1,
                            '.MuiInputBase-root': {
                              py: '4.5px',
                            },
                          }}
                          formInputProps={{
                            label: 'State',
                          }}
                          placeholder={!selectedCountry ? 'Select a country first' : 'Select a state'}
                        />
                      );
                    }}
                  />
                  <BaseFormField
                    formInputProps={{
                      ...register('zipCode'),
                      type: 'text',
                      label: 'Zip code',
                      fullWidth: true,
                      disabled: !isAllowToEditCompanyInfo,
                    }}
                    errorValue={errors['zipCode']?.message}
                  />
                  <BaseFormField
                    formInputProps={{
                      ...register('addressLine2'),
                      type: 'text',
                      label: 'Address line 2',
                      fullWidth: true,
                      disabled: !isAllowToEditCompanyInfo,
                    }}
                    errorValue={errors['addressLine2']?.message}
                  />
                </Stack>
              </Stack>

              <MDBox mt={1} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  type="submit"
                  sx={{ textTransform: 'none', width: { xs: 1, sm: 'auto' } }}
                  isLoading={isSubmitting}
                  disabled={isSaveButtonDisabled}
                >
                  Save
                </MDButton>
              </MDBox>
            </CardContent>
          </Card>
        </form>
      </Grid>
    </Grid>
  );
};
