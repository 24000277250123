import { BackendUserResponse, PATCH_HEADERS, backendApiClient } from 'features/common';

export type GetUserSettings = {
  '@context': string;
  '@id': string;
  '@type': string;
  allowChat: boolean;
  allowCallReminder: boolean;
  allowCaseStatus: boolean;
  allowCaseUpdates: boolean;
  allowMissedCall: boolean;
  allowEmailNotification: boolean;
  allowNewLead: boolean;
};

export type PatchUserSettingsPayload = Partial<Omit<GetUserSettings, '@context' | '@id' | '@type'>>;

export const userSettingsService = () => {
  const getUserSettings = async (userId: string) => {
    try {
      const { data: userSettings } = await backendApiClient({}).get<GetUserSettings>(`/user_settings/${userId}`);

      return userSettings;
    } catch (error) {
      throw error;
    }
  };

  const patchUserSettings = async (userId: string, patchSettingsPayload: PatchUserSettingsPayload) => {
    try {
      return await backendApiClient({
        headers: PATCH_HEADERS,
      }).patch<PatchUserSettingsPayload>('/user_settings/' + userId, patchSettingsPayload);
    } catch (error) {
      throw error;
    }
  };

  return {
    getUserSettings,
    patchUserSettings,
  };
};
