import { FC, useEffect, useState } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { TablePaginationFooter } from 'features/common/components/TablePaginationFooter';
import CustomPaginationTable from 'features/content-items/components/CustomPaginationTable';
import { useUserReviews } from 'features/store';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { useBoolean } from 'features/common';
import { usePagination } from 'features/common/hooks/usePagination';
import { REVIEW_TABLE_COLUMNS } from './reviewTable.config';

export const ReviewTable: FC = () => {
  const { totalItems, fetchUserReviewsHandler, deleteUserReviewHandler, approveUserReviewHandler, userReviews } =
    useUserReviews(store => ({
      totalItems: store.totalItems,
      fetchUserReviewsHandler: store.fetchUserReviewsHandler,
      deleteUserReviewHandler: store.deleteUserReviewHandler,
      userReviews: store.userReviews,
      approveUserReviewHandler: store.approveUserReviewHandler,
    }));

  const [isDialogOpen, openDialogHandler, closeDialogHandler] = useBoolean(false);
  const [isApproveDialogOpen, openApproveDialogHandler, closeApproveDialogHandler] = useBoolean(false);

  const [reviewItemId, setReviewItemId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { currentPage, isLastItem, setCurrentPage, entriesPerPage, onChangeEntriesPerPageHandler, onDeleteItem } =
    usePagination({
      totalItems,
      itemsLength: userReviews?.length,
    });

  const onFetchItemsHandler = async () => {
    if (isLastItem) {
      const nextPage = onDeleteItem();
      await fetchUserReviewsHandler({
        page: nextPage,
        itemsPerPage: entriesPerPage,
        active: false,
      });
    }
  };

  const onDeleteUserReviewHandler = async () => {
    closeDialogHandler();
    setIsLoading(true);

    await deleteUserReviewHandler(reviewItemId);
    await onFetchItemsHandler();

    setIsLoading(false);
  };

  const onApproveUserReviewHandler = async () => {
    closeApproveDialogHandler();
    setIsLoading(true);

    await approveUserReviewHandler(reviewItemId);
    await onFetchItemsHandler();

    setIsLoading(false);
  };

  const onDeleteIconClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setReviewItemId(event.currentTarget.id);
    openDialogHandler();
  };

  const onApproveIconClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setReviewItemId(event.currentTarget.id);
    openApproveDialogHandler();
  };

  const onChangePageHandler = async (page: number) => {
    setCurrentPage(page);
    setIsLoading(true);
    await fetchUserReviewsHandler({
      itemsPerPage: entriesPerPage,
      page,
      active: false,
    });
    setIsLoading(false);
  };

  const onFetchUserReviewsHandler = async () => {
    setIsLoading(true);
    await fetchUserReviewsHandler({
      itemsPerPage: entriesPerPage,
      page: currentPage,
      active: false,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    onFetchUserReviewsHandler();
  }, [entriesPerPage]);

  return (
    <MDBox minHeight="300px" justifyItems="center" alignItems="center" display="flex">
      {isLoading && (
        <Stack width={1} alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      {userReviews.length && !isLoading ? (
        <MDBox width="100%">
          <CustomPaginationTable
            entriesPerPage={{ defaultValue: entriesPerPage }}
            onChangeEntriesPerPage={onChangeEntriesPerPageHandler}
            isSorted={false}
            showTotalEntries
            table={{
              columns: REVIEW_TABLE_COLUMNS,
              rows: userReviews.map(review => ({
                id: review.id,
                createdAt: review.createdAt,
                reviewedUser: {
                  src: review?.reviewedUser?.logo?.publicUrl,
                  roles: review?.reviewedUser?.roles,
                  companyName: review?.reviewedUser?.company?.shortName,
                  firstName: review?.reviewedUser.userProfile.firstName,
                  lastName: review?.reviewedUser.userProfile.lastName,
                  body: review.body,
                },
                author: {
                  src: review?.createdBy?.logo?.publicUrl,
                  roles: review?.createdBy?.roles,
                  companyName: review?.createdBy?.company?.shortName,
                  firstName: review?.createdBy.userProfile.firstName,
                  lastName: review?.createdBy.userProfile.lastName,
                },
                rating: review.rating,
                actionBtns: {
                  id: review.id,
                  onDeleteIconClickHandler,
                  onApproveIconClickHandler,
                },
              })),
            }}
          />
          <TablePaginationFooter
            page={currentPage}
            currentItemsPerPage={entriesPerPage}
            totalItems={totalItems}
            onChangePage={onChangePageHandler}
          />
        </MDBox>
      ) : (
        !isLoading && <MDTypography sx={{ mx: 'auto' }}>No reviews were found...</MDTypography>
      )}
      <DialogWindow
        onCloseDialogHandler={closeDialogHandler}
        onApproveDialogHandler={onDeleteUserReviewHandler}
        isDialogOpen={isDialogOpen}
        dialogTitle="Are you sure want to delete the review?"
      />
      <DialogWindow
        onCloseDialogHandler={closeApproveDialogHandler}
        onApproveDialogHandler={onApproveUserReviewHandler}
        isDialogOpen={isApproveDialogOpen}
        dialogTitle="Are you sure want to approve the review?"
      />
    </MDBox>
  );
};
