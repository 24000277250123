import React, { useState } from 'react';
import { AuthMethodType } from 'features/auth/types';
import { IllustrationLayout } from 'features/common';
import { AuthenticationMethodTabs } from '../AuthenticationMethodTabs';
import { FirebaseSignInWithEmail } from './FirebaseSignInWithEmail';
import { FirebaseAuthWithPhone } from '../FirebaseAuthWithPhone';
import { AuthFlowBackgrounds } from 'features/auth/variables/illustartion';
import { Illustrationtitle } from 'features/common/components/IllustatrionLogo';

const PHONE_NUMBER_SIGN_IN_TITLE = 'Enter your phone number to Sign In';

export const SignInStep = () => {
  const [authMethodType, setAuthMethodType] = useState(AuthMethodType.EMAIL);

  const handleSetTabValue = (_: React.SyntheticEvent<Element, Event>, newValue: AuthMethodType) =>
    setAuthMethodType(newValue);

  return (
    <IllustrationLayout
      title="Sign In"
      description={authMethodType === AuthMethodType.EMAIL ? '' : PHONE_NUMBER_SIGN_IN_TITLE}
      illustration={AuthFlowBackgrounds.AUTH_ONE}
      illustrationLogo={<Illustrationtitle />}
    >
      <AuthenticationMethodTabs authMethodType={authMethodType} handleSetTabValue={handleSetTabValue} />
      {authMethodType === AuthMethodType.EMAIL && <FirebaseSignInWithEmail />}
      {authMethodType === AuthMethodType.PHONE && <FirebaseAuthWithPhone isSignIn />}
    </IllustrationLayout>
  );
};
