import { FC } from 'react';
import { TimePicker } from '@mui/x-date-pickers';
import { InputAdornment } from '@mui/material';
import { Clear, AccessTime } from '@mui/icons-material';
import { useBoolean } from 'features/common/hooks';
import { BaseTimePickerProps } from './types';

export const BaseTimePicker: FC<BaseTimePickerProps> = ({
  label,
  value,
  isError,
  onChange,
  onClickClearButtonHandler,
  onErrorHandler,
  slotProps,
  sx,
  ...rest
}) => {
  const [isOpen, onClickOpenIconHandler, onCloseHandler] = useBoolean(false);

  return (
    <TimePicker
      {...rest}
      label={label}
      open={isOpen}
      onOpen={onClickOpenIconHandler}
      onClose={onCloseHandler}
      slots={{
        inputAdornment: props => (
          <>
            {onClickClearButtonHandler ? (
              <InputAdornment
                {...props}
                disableTypography={!value}
                sx={{ cursor: value ? 'pointer' : 'default' }}
                onClick={onClickClearButtonHandler}
              >
                <Clear fontSize="medium" color={!value ? 'disabled' : 'action'} />
              </InputAdornment>
            ) : null}

            <InputAdornment {...props} sx={{ cursor: 'pointer' }} onClick={onClickOpenIconHandler}>
              <AccessTime fontSize="medium" color="action" />
            </InputAdornment>
          </>
        ),
      }}
      slotProps={{
        mobilePaper: {
          sx: {
            '.MuiTimeClock-root': {
              width: 290,
            },
            '.MuiDialogActions-root': {
              justifyContent: 'center',
            },
            '.MuiClockPointer-thumb': {
              backgroundColor: theme => theme.palette.info.main,
              borderColor: theme => theme.palette.info.main,
            },
            '.MuiPickersArrowSwitcher-root': {
              top: 0,
            },
            mx: 1,
            minWidth: '290px !important',
            '.MuiClockPointer-root, .MuiClock-pin': {
              backgroundColor: theme => `${theme.palette.info.main}`,
            },
          },
        },
        desktopPaper: {
          sx: {
            '.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClock-root': {
              width: 'auto !important',
              overflowY: 'scroll !important',
            },
            '.MuiDialogActions-root': {
              padding: '5px',
            },
          },
        },
        digitalClockSectionItem: {
          sx: {
            minWidth: 'auto !important',
            ':hover': {
              backgroundColor: theme => theme.palette.grey[200],
            },
            '&.Mui-selected': {
              backgroundColor: theme => `${theme.palette.info.main} !important`,
              ':focus': {
                color: 'white !important',
              },
              ':hover': {
                backgroundColor: theme => `${theme.palette.info.light} !important`,
              },
            },
          },
        },
        ...slotProps,
      }}
      disableOpenPicker={false}
      sx={{
        ...(isError
          ? {
              '& .MuiOutlinedInput-notchedOutline, &:after': {
                borderColor: theme => `${theme.palette.error.main} !important`,
              },
              '& .Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline, &:after': {
                  borderColor: theme => `${theme.palette.error.main} !important`,
                  ':hover': {
                    borderColor: theme => `${theme.palette.error.main} !important`,
                  },
                },
              },

              '& .MuiInputLabel-root.Mui-focused': {
                color: theme => `${theme.palette.error.main} !important`,
              },
            }
          : {
              '& .Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline, &:after': {
                  borderColor: theme => `${theme.palette.info.main} !important`,
                },
              },

              '& .MuiInputLabel-root.Mui-focused': {
                color: theme => `${theme.palette.info.main} !important`,
              },
            }),
        ...sx,
      }}
      value={value}
      onChange={onChange}
      onError={onErrorHandler}
    />
  );
};
