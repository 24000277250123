import { FC } from 'react';
import { Stack } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { dateToCustomFormat } from 'features/common/helpers/date';
import { CardFooterProps } from './types';
import { useUser } from 'features/common';

export const CardFooter: FC<CardFooterProps> = ({
  caseForDependant,
  dependantFirstName,
  dependantLastName,
  createdAt,
  stateName,
  countyName,
  countryId,
  isShowDependant = true,
}) => {
  const createdDate = dateToCustomFormat(createdAt);
  const { backendUser } = useUser();

  const depandantName = caseForDependant
    ? `${dependantFirstName} ${dependantLastName}`
    : `${backendUser?.userProfile.firstName} ${backendUser?.userProfile.lastName}`;
  return (
    <Stack spacing={1}>
      {isShowDependant && <MDTypography variant="button">{`For ${depandantName}`}</MDTypography>}
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
      >
        <MDTypography variant="button" sx={{ display: 'flex', flex: 1 }}>
          {`${countyName}, ${stateName}, ${countryId}`}
        </MDTypography>

        <MDTypography variant="button">{createdDate}</MDTypography>
      </Stack>
    </Stack>
  );
};
