import { FC } from 'react';
import { Grid } from '@mui/material';
import { DashboardLayout } from 'features/common';
import { CREATE_CASE_FORM_STEPS } from 'features/store';
import MDBox from 'components/MDBox';
import { CreateCaseStepper } from './components/CreateLegalCase/CreateCaseStepper';
import { ReviewMyLegalCaseTemplate } from './ReviewMyLegalCaseTemplate';
import { CaseInformation, ClientInformation, DependantInformation } from './components/ImportCRMLegalCase';
import { useCreateMyLegalCaseState } from './hooks/useCreateMyLegalCaseState';

const IMPORT_CASE_STEPS = [
  CREATE_CASE_FORM_STEPS.CLIENT_INFO,
  CREATE_CASE_FORM_STEPS.CASE_INFO,
  CREATE_CASE_FORM_STEPS.DEPENDANT_INFO,
];

function getStepContent(stepIndex: CREATE_CASE_FORM_STEPS): JSX.Element {
  switch (stepIndex) {
    case CREATE_CASE_FORM_STEPS.CLIENT_INFO:
      return <ClientInformation />;
    case CREATE_CASE_FORM_STEPS.CASE_INFO:
      return <CaseInformation />;
    case CREATE_CASE_FORM_STEPS.DEPENDANT_INFO:
      return <DependantInformation />;
    default:
      return null;
  }
}

export const ImportMyLegalCaseTemplate: FC = () => {
  const { activeStep, currentStepIndex } = useCreateMyLegalCaseState();

  return (
    <>
      {activeStep === CREATE_CASE_FORM_STEPS.CASE_REVIEW ? (
        <ReviewMyLegalCaseTemplate isAttorneyView />
      ) : (
        <DashboardLayout>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CreateCaseStepper activeStep={currentStepIndex} steps={IMPORT_CASE_STEPS} />
              <MDBox p={3}>
                <MDBox>{getStepContent(activeStep)}</MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </DashboardLayout>
      )}
    </>
  );
};
