import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { FC } from 'react';

type RequirementsListProps = {
  requirements: string[];
};

export const RequirementsList: FC<RequirementsListProps> = ({ requirements }) => {
  return (
    <>
      {requirements.map((item, ind) => (
        <MDBox key={item + ind} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
            {item}
          </MDTypography>
        </MDBox>
      ))}
    </>
  );
};
