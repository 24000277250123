import { backendApiClient } from '../index';

type PatchSortingPayload = {
  object: string;
  direction: string;
  step: number;
};

export const createSortingService = () => {
  const patchSorting = async ({ object, direction, step }: PatchSortingPayload) => {
    try {
      const sorted = await backendApiClient({}).patch(
        '/sorting',
        {
          object,
          direction,
          step,
        },
        {
          headers: {
            'Content-Type': 'application/ld+json',
          },
        }
      );

      return sorted;
    } catch (error) {
      throw error;
    }
  };

  return {
    patchSorting,
  };
};
