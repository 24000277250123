import { FC, memo, useEffect } from 'react';
import { Grid, Stack } from '@mui/material';
import { MercureLinkMap, SideMenu } from 'features/common';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { useMercure } from 'features/common/hooks/useMercure';
import { DocumentsContent } from '../DocumentsContent';
import { ChatInput } from '../ChatInput';
import { ChatContentCard } from '../ChatContent';
import { ChatHeader } from '../ChatHeader';
import { useChatState } from '../../hooks/useChatState';

type MainChatContentProps = {
  isHideBackButton?: boolean;
  isMessagessLoading?: boolean;
  isListOfChats?: boolean;
};

export const MainChatContent: FC<MainChatContentProps> = memo(
  ({ isListOfChats, isHideBackButton, isMessagessLoading }) => {
    const {
      isChatMessagesLoading,
      firstMessageRef,
      isFetchMoreLoading,
      messagesWrapperRef,
      selectedChatData,
      chatId,
      errorMesasage,
      isDocumentsLoading,
      isDocumentsModalOpen,
      inputValue,
      downloadButtonRef,
      isDeleteDialogOpen,
      isFetchingNewMessages,
      onReceiveEventNewMessageHandler,
      closeDeleteDialogHandler,
      onClickDeleteMessageFileIconHandler,
      onClickDownloadDocumentHandler,
      closeDocumentsModal,
      onClickDeleteFileHandler,
      onInputFileUploadHandler,
      openDocumentsModal,
      onChangeInputHandler,
      onEnterPressHandler,
      onSendMessageButtonHandler,
      firstName,
      isCaseOnCounsultation,
      lastName,
      logoURL,
      isSendRepresentationRequestLoading,
      currentUserFirebaseId,
      isAttorney,
      selectedUserChat,
      onClickSendRepresentationButtonHandler,
      resetState,
      representation,
      onApproveRepresentationRequestHandler,
      onDeclineRepresentationRequestHandler,
      aesDecrypt,
      remoteChatParticipantFirebaseId,
      getChatFilesHandler,
      chatFilesData,
      getMoreChatFiles,
    } = useChatState(isListOfChats);

    useEffect(() => {
      return () => {
        if (!isListOfChats) resetState();
      };
    }, []);

    useMercure({
      eventId: chatId,
      onReceiveEventHandler: onReceiveEventNewMessageHandler,
      mercureSubscribeURL: MercureLinkMap[process.env.REACT_APP_BASE_APP_URL || 'http://localhost:3000'],
      topicSubURL: 'legal_case_chats',
    });

    return (
      <Grid item xs={12}>
        <Stack spacing={2} height={1} flex={1}>
          <ChatHeader
            isHideBackButton={isHideBackButton}
            isAttorney={isAttorney}
            caseId={selectedUserChat?.legalCase?.id}
            endParticipantFirebaseId={selectedUserChat?.[isAttorney ? 'client' : 'attorney']?.firebaseUser}
            isRepresentationExist={!!representation?.representation}
            isCaseOnCounsultation={isCaseOnCounsultation}
            firstName={firstName}
            lastName={lastName}
            logoURL={logoURL}
            isSendRepresentationRequestLoading={isSendRepresentationRequestLoading}
            onClickSendRepresentationButtonHandler={onClickSendRepresentationButtonHandler}
          />

          <ChatContentCard
            messages={selectedChatData.messages}
            currentUserFirebaseId={currentUserFirebaseId}
            messagesWrapperRef={messagesWrapperRef}
            firstMessageRef={firstMessageRef}
            isFetchMoreLoading={isFetchMoreLoading}
            isLoading={isChatMessagesLoading || isMessagessLoading}
            declineRepresentationRequest={onDeclineRepresentationRequestHandler}
            approveRepresentationRequest={onApproveRepresentationRequestHandler}
            isAttorney={isAttorney}
            aesDecrypt={aesDecrypt}
          />

          <ChatInput
            inputValue={inputValue}
            onChangeInputHandler={onChangeInputHandler}
            onSendMessageButtonHandler={onSendMessageButtonHandler}
            onDocumentsButtonClickHandler={openDocumentsModal}
            onEnterPressHandler={onEnterPressHandler}
            isFetchingNewMessages={isFetchingNewMessages}
          />
        </Stack>

        <SideMenu isOpen={isDocumentsModalOpen} onClose={closeDocumentsModal} title="Chat documents" customWidth={450}>
          <DocumentsContent
            errorMesasage={errorMesasage}
            onClickDeleteMessageFileIconHandler={onClickDeleteMessageFileIconHandler}
            downloadButtonRef={downloadButtonRef}
            onClickDownloadDocumentHandler={onClickDownloadDocumentHandler}
            onInputFileUploadHandler={onInputFileUploadHandler}
            isDocumentsLoading={isDocumentsLoading}
            remoteChatParticipantFirebaseId={remoteChatParticipantFirebaseId}
            getChatFilesHandler={getChatFilesHandler}
            getMoreChatFiles={getMoreChatFiles}
            chatFilesData={chatFilesData}
          />
        </SideMenu>
        <DialogWindow
          onCloseDialogHandler={closeDeleteDialogHandler}
          onApproveDialogHandler={onClickDeleteFileHandler}
          isDialogOpen={isDeleteDialogOpen}
          dialogTitle="Are you sure want to delete the document?"
        />
      </Grid>
    );
  }
);
