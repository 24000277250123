import { ToastType, notice } from 'features/common';
import {
  GetMyLegalCasesParams,
  GetMyLegalCasesResponse,
  StatusType,
  myLegalCasesApiService,
} from 'features/my-legal-cases/MyLegalCasesApiService';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders/paymentRequiredErrorHandler';
import { legalCaseConsultationApiService } from 'features/common/services/legalCaseConsultationApiService';
import { createStore } from '../createStore';

export type MyLegalCasesState = {
  isLoading: boolean;
  isFetchMoreLoading: boolean;
  myLegalCases: GetMyLegalCasesResponse[];
  totalItems: number | null;
  fetchParams: GetMyLegalCasesParams;
};

export type MyLegalCasesActions = {
  fetchMyLegalCasesHandler: (params?: GetMyLegalCasesParams) => Promise<void>;
  fetchMoreLegalCasesHandler: (params?: GetMyLegalCasesParams) => Promise<void>;
  deleteMyLegalCaseHandler: (caseId: string, statusType?: StatusType) => Promise<void>;
  declineAcceptedConsultationHandler: (caseId: string) => Promise<void>;
};

const { getMyLegalCases, deleteMyLegalCase } = myLegalCasesApiService();
const { declineConsultation } = legalCaseConsultationApiService();

const initialState: MyLegalCasesState = {
  isLoading: false,
  isFetchMoreLoading: false,
  myLegalCases: [],
  totalItems: null,
  fetchParams: {},
};
export const useMyLegalCases = createStore<MyLegalCasesState & MyLegalCasesActions>(
  set => ({
    ...initialState,
    fetchMyLegalCasesHandler: async params => {
      try {
        set({
          isLoading: true,
        });
        const { data } = await getMyLegalCases(params);
        set({
          myLegalCases: data['hydra:member'],
          totalItems: data['hydra:totalItems'],
          isLoading: false,
          fetchParams: params,
        });
      } catch (error) {
        set({
          isLoading: false,
        });
        paymentRequiredErrorHandler(error, 'Failed to fetch legal cases, please try again!');
        console.error(error);
      }
    },
    fetchMoreLegalCasesHandler: async params => {
      try {
        set({
          isFetchMoreLoading: true,
        });
        const { data } = await getMyLegalCases(params);
        set(state => ({
          myLegalCases: [...state.myLegalCases, ...data['hydra:member']],
          isFetchMoreLoading: false,
          fetchParams: params,
        }));
      } catch (error) {
        set({
          isFetchMoreLoading: false,
        });
        paymentRequiredErrorHandler(error, 'Failed to fetch legal cases, please try again!');
        console.error(error);
      }
    },
    deleteMyLegalCaseHandler: async (caseId, statusType) => {
      try {
        set({
          isLoading: true,
        });

        await deleteMyLegalCase(caseId, statusType);
        set(state => ({
          myLegalCases: state.myLegalCases.filter(myCase => myCase.id !== caseId),
          totalItems: state.totalItems ? state.totalItems - 1 : state.totalItems,
          isLoading: false,
        }));

        notice(ToastType.SUCCESS, 'Legal case has been successfully deleted!');
      } catch (error) {
        set({
          isLoading: false,
        });
        paymentRequiredErrorHandler(error, 'Failed to delete legal case, please try again!');
        console.error(error);
      }
    },

    declineAcceptedConsultationHandler: async caseId => {
      try {
        set({
          isLoading: true,
        });
        await declineConsultation(caseId);
        const { data } = await getMyLegalCases({ page: 1, itemsPerPage: 30, 'status.type[]': ['in_progress', 'todo'] });

        set({
          myLegalCases: data['hydra:member'],
          totalItems: data['hydra:totalItems'],
          isLoading: false,
        });

        notice(ToastType.SUCCESS, 'Successfully back to queue the legal case!');
      } catch (error) {
        set({
          isLoading: false,
        });

        paymentRequiredErrorHandler(error, 'Failed to back to queue the legal case, please try again!');
        console.error(error);
      }
    },
  }),
  'myLegalCases',
  initialState
);
