import { Theme } from '@mui/material';

export const ChatMenuStyles = {
  MessageWrapperSX: {
    flex: 1,
    p: 2,
    overflow: 'hidden',
    borderBottom: ({ palette }: Theme) => `1px solid ${palette.grey[100]}`,
  },
  ChatMenuWrapperSX: {
    position: 'absolute',
    top: 0,
    left: -1000,
    zIndex: 100,
    width: 1,
    height: 1,
    transition: 'left 0.2s ease-in-out',
  },
};
