import { FC } from 'react';
import { Chip, Grid, Stack } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { GetUserByIdResponse } from 'features/users-management/UsersManagementApiService';
import { AttorneyProfileCaseLawType } from 'features/common';

type UserInfoContentProps = Partial<Pick<GetUserByIdResponse, 'email' | 'rating' | 'phone'>> & {
  fullName: string;
  companyName?: string;
  country: string;
  state: string;
  town: string;
  caseAmount: number;
  barNumber?: string;
  caseLawTypes?: AttorneyProfileCaseLawType[];
  personTitle?: string | null;
};

export const UserInfoContent: FC<UserInfoContentProps> = ({ caseLawTypes, ...other }) => {
  const renderItems = Object.entries(other).filter(
    ([label, value]) => (label === 'caseAmount' && value !== null) || value
  );

  return (
    <Stack
      spacing={1}
      sx={{
        mt: 3,
        pb: 1.5,
        height: 1,
        wordWrap: 'break-word',
      }}
    >
      {renderItems.map(([label, value]) => {
        const formattedLabel = label?.split(/(?=[A-Z])/).join(' ');

        return (
          <MDBox key={label} display="flex" py={1} pr={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" sx={{ flex: 1.5 }}>
              {formattedLabel}: &nbsp;
            </MDTypography>
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              sx={{
                flex: 3,
                whiteSpace: 'initial',
                maxWidth: 300,
                width: 1,
              }}
            >
              {value}
            </MDTypography>
          </MDBox>
        );
      })}
      {caseLawTypes?.length > 0 && (
        <MDBox display="flex" py={1} pr={2}>
          <MDTypography
            variant="button"
            fontWeight="bold"
            textTransform="capitalize"
            sx={{ flex: 1.5, alignSelf: 'flex-start' }}
          >
            Case Law Types: &nbsp;
          </MDTypography>

          <MDBox
            component="div"
            fontWeight="regular"
            color="text"
            sx={{
              flex: 3,
              whiteSpace: 'initial',
              maxWidth: 300,
              width: 1,
            }}
          >
            <Grid container spacing={1}>
              {caseLawTypes.map(el => (
                <Grid item key={el['@id']}>
                  <Stack component="li">
                    <MDTypography variant="button" fontWeight="medium">
                      {el?.caseLawType?.title}
                    </MDTypography>

                    {el?.states?.length ? (
                      <Stack component="ul" direction="row" flexWrap="wrap" ml={-1}>
                        {el?.states?.map(state => (
                          <Stack component="li" direction="row" key={state?.['@id']} sx={{ pl: 1 }}>
                            <Chip label={state?.name} sx={{ mb: 0.5 }} />
                          </Stack>
                        ))}
                      </Stack>
                    ) : (
                      <MDTypography variant="button" color="error">
                        No states connected
                      </MDTypography>
                    )}
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </MDBox>
        </MDBox>
      )}
    </Stack>
  );
};
