import { FC } from 'react';
import { Card, CardContent, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material//Search';
import { GetUsersChats } from 'features/common/services/chatApiService';
import { ApiSecurityFunction, BaseFormField } from 'features/common';
import { Loader } from 'features/common/components/Loader';
import { getMessageText } from 'features/chat/components/Message';
import MDTypography from 'components/MDTypography';
import { UsersChatsItem } from './UsersChatsItem';

type UsersChatsListProps = {
  users: GetUsersChats[];
  searchInputValue: string;
  onChangeSearchInputHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUserChatItemClickHandler: (event: React.MouseEvent<HTMLDivElement>) => Promise<void>;
  selectedUserId: string;
  isSearching: boolean;
  aesDecrypt: ApiSecurityFunction;
  isAttorney: boolean;
};

export const UsersList: FC<UsersChatsListProps> = ({
  searchInputValue,
  users,
  selectedUserId,
  isSearching,
  isAttorney,
  onChangeSearchInputHandler,
  onUserChatItemClickHandler,
  aesDecrypt,
}) => {
  return (
    <Card sx={{ height: 1, width: 1 }}>
      <CardContent sx={{ p: 2, height: 1, display: 'flex', width: 1 }}>
        <Stack spacing={2} height={1} width={1}>
          <BaseFormField
            formFieldProps={{
              pb: 0,
            }}
            formInputProps={{
              label: 'Search',
              placeholder: 'Find the users',
              fullWidth: true,
              value: searchInputValue,
              onChange: onChangeSearchInputHandler,
              InputProps: {
                startAdornment: <SearchIcon fontSize="medium" />,
              },
            }}
          />
          <Stack spacing={1.5} flex={1} sx={{ overflow: 'auto', width: 1 }}>
            {isSearching ? (
              <Loader />
            ) : users.length ? (
              users?.map(user => {
                const messageText = getMessageText(!isAttorney, user?.chatLastMessage, aesDecrypt);

                return (
                  <UsersChatsItem
                    key={user?.firebaseUser}
                    isSelectedUser={selectedUserId === user?.firebaseUser}
                    chatsCount={user?.chatsCount}
                    chatsMessagesCount={user?.chatsMessagesCount}
                    messageText={messageText}
                    userFirstName={user?.userProfile?.firstName}
                    userLastName={user?.userProfile?.lastName}
                    userLogoURL={user?.logo?.publicUrl}
                    createdAt={user?.chatLastMessage?.createdAt}
                    userId={user?.firebaseUser}
                    onUserChatItemClickHandler={onUserChatItemClickHandler}
                    unwatchedChatsMessagesCount={user?.unwatchedChatsMessagesCount}
                  />
                );
              })
            ) : (
              <Stack sx={{ height: 1, justifyContent: 'center', alignItems: 'center' }}>
                <MDTypography>No users were found</MDTypography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
