import { useRef, useState } from 'react';
import { useBoolean } from './useBoolean';
import { BucketType, createFileService } from '../services/filesService';
import { ToastType, notice } from '../modules';

export const useDocument = () => {
  const { downloadFileById } = createFileService();

  const downloadButtonRef = useRef<HTMLAnchorElement | null>(null);
  const [isDeleteDialogOpen, openDeleteDialogHandler, closeDeleteDialogHandler] = useBoolean(false);

  const [documentId, setDocumentId] = useState('');

  const onClickDeleteIconHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDocumentId(event.currentTarget.id);
    openDeleteDialogHandler();
  };

  const onClickDeleteIconByIdHandler = (documentId: string) => {
    setDocumentId(documentId);
    openDeleteDialogHandler();
  };

  const onClickDownloadDocumentHandler = async (documentId: string, documentTitle: string) => {
    try {
      const file = await downloadFileById(documentId);
      const url = URL.createObjectURL(new Blob([file]));

      if (downloadButtonRef?.current) {
        const link = downloadButtonRef.current;
        link.href = url;
        link.setAttribute('download', `${documentTitle}`);
        link.click();
      }
      URL.revokeObjectURL(url);
    } catch (error) {
      notice(ToastType.ERROR, 'Something went wrong!');
      console.error(error);
    }
  };

  const createFormDataHandler = (file: File, bucketType: BucketType = 'security') => {
    const fileType = file.type.split('/')[0];

    const documentFormData = new FormData();

    documentFormData.append('file', file);
    documentFormData.append(
      'type',
      fileType.includes('application') || fileType.includes('text') ? 'document' : fileType
    );
    documentFormData.append('bucketType', bucketType);
    documentFormData.append('title', file.name);

    return documentFormData;
  };

  return {
    isDeleteDialogOpen,
    documentId,
    downloadButtonRef,
    onClickDeleteIconByIdHandler,
    onClickDeleteIconHandler,
    closeDeleteDialogHandler,
    createFormDataHandler,
    onClickDownloadDocumentHandler,
  };
};
