import { FC, useEffect, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import { useIsOnScreen } from 'features/common/hooks/useIsOnScreen';
import { FetchMoreLoader, Loader } from 'features/common/components/Loader';
import { CompanyInvitationItem } from './CompanyInvitationItem';
import { AllInvitationsOffersMenuContentProps } from './types';

const ITEMS_PER_PAGE = 30;

export const AllInvitationsOffersMenuContent: FC<AllInvitationsOffersMenuContentProps> = ({
  isLoading,
  invitationOffers,
  totalItems,
  approveOfferHandler,
  declineOfferHandler,
  fetchMoreOffersHandler,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pages: Readonly<number> = Math.ceil(totalItems / ITEMS_PER_PAGE);

  const [isFetchMoreLoading, setIsFetchMoreLoading] = useState(false);

  const lastElementRef = useRef<HTMLDivElement | null>(null);
  const isOnScreen = useIsOnScreen(isLoading ? null : lastElementRef);

  const onFetchMoreCaseOffersHandler = async () => {
    setIsFetchMoreLoading(true);

    const nextPage = currentPage + 1;
    await fetchMoreOffersHandler({ itemsPerPage: ITEMS_PER_PAGE, page: nextPage });
    setCurrentPage(nextPage);

    setIsFetchMoreLoading(false);
  };

  useEffect(() => {
    if (isOnScreen && currentPage < pages) {
      onFetchMoreCaseOffersHandler();
    }
  }, [isOnScreen]);

  return (
    <Stack
      spacing={2}
      sx={{
        mt: 3,
        pb: 1.5,
        height: 1,
      }}
    >
      <Stack position="relative" height={1} spacing={2}>
        {isLoading && <Loader />}
        {!isLoading &&
          invitationOffers?.map(offer => (
            <CompanyInvitationItem
              key={offer?.['@id']}
              lastElementRef={lastElementRef}
              offerId={offer?.['@id']}
              invitorFirstName={offer?.inviter?.userProfile?.firstName}
              invitorLastName={offer?.inviter?.userProfile?.lastName}
              invitorLogoURL={offer?.inviter?.logo?.publicUrl}
              companyFullName={offer?.company?.fullName}
              approveOfferHandler={approveOfferHandler}
              declineOfferHandler={declineOfferHandler}
            />
          ))}

        {isFetchMoreLoading && <FetchMoreLoader />}
      </Stack>
    </Stack>
  );
};
