import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { BaseFormField, GetAttorneyProfileResponse, ToastType, createAttorneyService, notice } from 'features/common';
import { baseBackendErrorHandler } from 'features/common/errorHanders';
import { AttorneyProfileSchema, editAttorneyProfileFormSchema } from '../templates/formSchema';

type AttorneyProfileFormProps = {
  attorneyProfile: GetAttorneyProfileResponse;
  connectAttorneyProfile: () => Promise<void>;
};

export const AttorneyProfileForm: React.FC<AttorneyProfileFormProps> = ({
  connectAttorneyProfile,
  attorneyProfile,
}) => {
  const { patchAttorneyProfileById } = createAttorneyService();

  const { register, formState, handleSubmit, setError, setValue, watch } = useForm<AttorneyProfileSchema>({
    resolver: yupResolver(editAttorneyProfileFormSchema),
    mode: 'onTouched',
    defaultValues: {
      barNumber: attorneyProfile?.description || '',
      description: attorneyProfile?.description || '',
    },
    values: {
      description: attorneyProfile?.description || '',
    },
  });

  const currentBarNumber = watch('barNumber');
  const currentDescription = watch('description');
  const isFormInvalid = Object.entries(formState.errors).length > 0;

  const isFormChanged =
    attorneyProfile?.barNumber !== currentBarNumber || attorneyProfile?.description !== currentDescription;

  const onSubmitHandler = async (formData: AttorneyProfileSchema) => {
    try {
      if (attorneyProfile?.barNumber !== currentBarNumber || attorneyProfile.description !== currentDescription) {
        await patchAttorneyProfileById(attorneyProfile['@id'].replace('/attorney_profiles/', ''), {
          barNumber: formData.barNumber,
          description: formData.description,
        });

        await connectAttorneyProfile();
        notice(ToastType.SUCCESS, `Successfully updated Attorney profile`);
      }
    } catch (error) {
      baseBackendErrorHandler(error, { formError: { formData, setError } });
    }
  };

  useEffect(() => {
    if (attorneyProfile?.barNumber) setValue('barNumber', attorneyProfile?.barNumber);
  }, [attorneyProfile]);

  return !attorneyProfile ? (
    <MDBox width="100%" display="flex" justifyContent="center">
      <CircularProgress />
    </MDBox>
  ) : (
    <>
      <MDBox
        display="flex"
        gap="8px"
        width="full"
        flexDirection="column"
        component="form"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <BaseFormField
          formInputProps={{
            ...register('barNumber'),
            placeholder: 'H2Y3T8',
            type: 'text',
            label: 'Bar number',
            fullWidth: true,
            InputLabelProps: { shrink: true },
          }}
          errorValue={formState.errors['barNumber']?.message}
        />

        <BaseFormField
          formInputProps={{
            ...register('description'),
            placeholder: 'Enter your description',
            type: 'text',
            label: 'Description',
            fullWidth: true,
            InputLabelProps: { shrink: true },
            multiline: true,
          }}
          errorValue={formState.errors['description']?.message}
        />

        <MDBox mt={1} mb={1}>
          <MDButton
            variant="gradient"
            color="info"
            type="submit"
            sx={{ textTransform: 'none' }}
            isLoading={formState.isSubmitting}
            disabled={isFormInvalid || !isFormChanged}
          >
            Save
          </MDButton>
        </MDBox>
      </MDBox>
    </>
  );
};
