import { immer } from 'zustand/middleware/immer';
import { ToastType, notice } from 'features/common';
import { createFileService } from 'features/common/services/filesService';
import { Pagination } from 'features/case-law-types';
import {
  CreateLegalCaseMessagePayload,
  CreateLegalCaseMessageResponse,
  GetListOfChatsParams,
  GetListOfChatsResponse,
  GetUsersChats,
  GetUsersChatsParams,
  chatApiService,
} from 'features/common/services/chatApiService';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders/paymentRequiredErrorHandler';
import { CaseFile } from 'features/my-legal-cases/MyLegalCasesApiService';
import { createStore } from '../createStore';

type UsersData = {
  users: GetUsersChats[];
  totalItems: number | null;
};

type ChatsData = Pick<UsersData, 'totalItems'> & {
  listOfUserChats: GetListOfChatsResponse[];
};

type SelectedChatData = Pick<UsersData, 'totalItems'> & {
  messages: CreateLegalCaseMessageResponse[];
};

export type ChatFilesData = {
  files: CaseFile[];
  totalItems: null | number;
};

export type MyChatsState = {
  headerLink: string;
  selectedChatData: SelectedChatData;
  usersData: UsersData;
  selectedUser: GetUsersChats | null;
  selectedUserChat: GetListOfChatsResponse | null;
  listOfUserChatsData: ChatsData;
  chatFilesData: ChatFilesData;
};

export type MyChatsActions = {
  getUsersChats: (params?: GetUsersChatsParams) => Promise<void>;
  setSelectedUser: (firebaseId: string) => void;
  resetState: () => void;
};

export type ListOfUserChatsActions = {
  setSelectedUserChat: (selectedUserChat: GetListOfChatsResponse | null) => void;
  getListOfChats: (params?: GetListOfChatsParams) => Promise<void>;
  getMoreListOfChats: (params?: GetListOfChatsParams) => Promise<void>;
};

type FetchParams = { chatId?: string; params?: Pagination };

type UpdateLastMessageData = {
  isAttorney: boolean;
};

export type SelectedChatActions = {
  getMessagesHandler: (params: FetchParams) => Promise<void>;
  getMoreMessagesHandler: (params: FetchParams) => Promise<void>;
  createMessageHandler: (
    payload: CreateLegalCaseMessagePayload,
    updateLastMessagePayload: UpdateLastMessageData | null
  ) => Promise<void>;
  uploadDocumentHandler: (chatId: string, formData: FormData) => Promise<void>;
  deleteMessageHandler: (messageId: string, fileId: string) => Promise<void>;
  makeViewedLegalCaseChatMessageHandler: (currentuserFirebaseId: string) => Promise<void>;
  getChatFilesHandler: () => Promise<void>;
  getMoreChatFiles: (params: Pagination) => Promise<void>;
};

const {
  getLegalCaseChatMessages,
  createLegalCaseChatMessage,
  deleteLegalCaseChatMessage,
  getListOfChats,
  getUsersChats,
  makeViewedLegalCaseChatMessage,
  getChatFiles,
} = chatApiService();

const { createFile } = createFileService();

const initialState: MyChatsState = {
  headerLink: '',

  listOfUserChatsData: { listOfUserChats: [], totalItems: null },
  selectedUserChat: null,

  usersData: { users: [], totalItems: null },
  selectedChatData: { messages: [], totalItems: null },
  selectedUser: null,
  chatFilesData: { files: [], totalItems: null },
};

export const useMyChats = createStore<MyChatsState & MyChatsActions & SelectedChatActions & ListOfUserChatsActions>(
  immer((set, get) => ({
    ...initialState,
    resetState: () => {
      set(initialState);
    },
    getChatFilesHandler: async () => {
      try {
        const chatId = get().selectedUserChat?.id;
        const { data } = await getChatFiles(chatId, { page: 1, itemsPerPage: 30 });
        set(state => {
          state.chatFilesData = { files: data['hydra:member'], totalItems: data['hydra:totalItems'] };
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch chat files, please try again!');
        console.error(error);
      }
    },
    getMoreChatFiles: async params => {
      try {
        const chatId = get().selectedUserChat?.id;
        const { data } = await getChatFiles(chatId, params);
        set(state => {
          state.chatFilesData.files.push(...data['hydra:member']);
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch chat files, please try again!');
        console.error(error);
      }
    },
    makeViewedLegalCaseChatMessageHandler: async currentuserFirebaseId => {
      try {
        const { selectedChatData, selectedUserChat, selectedUser } = get();

        const lastMessage = selectedChatData?.messages?.[0];
        if (currentuserFirebaseId === lastMessage?.owner?.firebaseUser || !lastMessage) return;
        await makeViewedLegalCaseChatMessage(lastMessage?.id);

        set(state => {
          if (selectedUserChat?.countUnwatchedMessages) {
            state.listOfUserChatsData.listOfUserChats = state.listOfUserChatsData.listOfUserChats.map(el =>
              el.id === selectedUserChat?.id
                ? {
                    ...selectedUserChat,
                    countUnwatchedMessages: 0,
                  }
                : el
            );
          }
          //update unwatched messages in list of users
          if (selectedUser && selectedUser.unwatchedChatsMessagesCount && selectedUserChat.countUnwatchedMessages) {
            state.usersData.users = state.usersData.users.map(el =>
              el.firebaseUser === selectedUser.firebaseUser
                ? {
                    ...selectedUser,
                    unwatchedChatsMessagesCount:
                      selectedUser?.unwatchedChatsMessagesCount - selectedUserChat?.countUnwatchedMessages,
                  }
                : el
            );
          }
        });
      } catch (error) {
        paymentRequiredErrorHandler(error);
        console.error(error);
      }
    },

    //select user logic
    setSelectedUser: firebaseUserId => {
      const users = get().usersData.users;
      const selectedUser = users?.find(user => user?.firebaseUser === firebaseUserId);
      set(state => {
        state.selectedUser = selectedUser;
      });
    },

    getUsersChats: async params => {
      try {
        const { data } = await getUsersChats(params);

        set(state => {
          state.usersData.users = data['hydra:member'];
          state.usersData.totalItems = data['hydra:totalItems'];
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch list of chats, please try again!');
        console.error(error);
      }
    },

    //list of user`s chats
    getListOfChats: async params => {
      try {
        const { data } = await getListOfChats(params);
        set(state => {
          state.listOfUserChatsData.listOfUserChats = data['hydra:member'];
          state.listOfUserChatsData.totalItems = data['hydra:totalItems'];
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch list of chats, please try again!');
        console.error(error);
      }
    },

    getMoreListOfChats: async params => {
      try {
        const { data } = await getListOfChats(params);
        set(state => {
          state.listOfUserChatsData.listOfUserChats.push(...data['hydra:member']);
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch list of chats, please try again!');
        console.error(error);
      }
    },

    setSelectedUserChat: selectedChat => {
      set(state => {
        state.selectedUserChat = selectedChat;
      });
    },

    deleteMessageHandler: async (messageId, fileId) => {
      try {
        await deleteLegalCaseChatMessage(messageId);

        notice(ToastType.SUCCESS, 'Successfully deleted file!');
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to delete file, please try again!');
        console.error(error);
      }
    },
    createMessageHandler: async (payload, updateLastMessagePayload) => {
      try {
        const { data } = await createLegalCaseChatMessage(payload);

        if (updateLastMessagePayload) {
          const { selectedUser, selectedUserChat } = get();

          set(state => {
            state.listOfUserChatsData.listOfUserChats = state.listOfUserChatsData.listOfUserChats.map(el =>
              el.id === selectedUserChat?.id
                ? {
                    ...selectedUserChat,
                    countUnwatchedMessages: 0,
                    lastMessage: {
                      ...data,
                      owner: selectedUserChat[updateLastMessagePayload.isAttorney ? 'attorney' : 'client'],
                    },
                  }
                : el
            );
            state.usersData.users = state.usersData.users.map(el =>
              el.firebaseUser === selectedUser?.firebaseUser
                ? { ...el, chatLastMessage: data, chatsMessagesCount: el.chatsMessagesCount + 1 }
                : el
            );
          });
        }
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to send message, please try again!');
        console.error(error);
      }
    },

    getMessagesHandler: async ({ chatId, params }) => {
      try {
        const { data } = await getLegalCaseChatMessages(chatId, params);

        set(state => {
          state.selectedChatData.messages = data['hydra:member'];
          state.selectedChatData.totalItems = data['hydra:totalItems'];
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch chat messages, please try again!');
        console.error(error);
      }
    },

    getMoreMessagesHandler: async ({ chatId, params }) => {
      try {
        const { data } = await getLegalCaseChatMessages(chatId, params);

        set(state => {
          state.selectedChatData.messages.push(...data['hydra:member']);
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch all messages, please try again!');
        console.error(error);
      }
    },

    uploadDocumentHandler: async (chatId, formData) => {
      try {
        const { data: createFileData } = await createFile(formData);
        await createLegalCaseChatMessage({
          chat: `/legal_case_chats/${chatId}`,
          type: 'file',
          file: createFileData['@id'],
        });
        const { data } = await getChatFiles(chatId, { page: 1, itemsPerPage: 30 });
        set(state => {
          state.chatFilesData = { files: data['hydra:member'], totalItems: data['hydra:totalItems'] };
        });
        notice(ToastType.SUCCESS, 'Successfully uploaded the document!');
      } catch (error) {
        notice(ToastType.ERROR, 'Failed to upload the document!');
      }
    },
  })),
  'myChats',
  initialState
);
