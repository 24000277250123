import { FC } from 'react';
import { CircularProgress, SxProps } from '@mui/material';
import { Edit, Add } from '@mui/icons-material';
import MDAvatar, { MDAvatarProps } from 'components/MDAvatar';
import MDBox from 'components/MDBox';

type UploadAvatarProps = SxProps &
  MDAvatarProps & {
    logoURL?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
    onInputFileUploadHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };

export const UploadAvatar: FC<UploadAvatarProps> = ({
  onInputFileUploadHandler,
  isLoading,
  logoURL,
  isDisabled,
  size = 'xl',
  variant = 'circular',
  sx,
}) => {
  return (
    <MDBox sx={{ position: 'relative' }}>
      <MDAvatar
        src={logoURL || ''}
        size={size}
        variant={variant}
        sx={{
          background: '#d3d3d3',
          objectFit: 'contain',
          width: '74px',
          height: '74px',
          position: 'relative',
          transition: 'background-color 0.3s',
          cursor: 'pointer',
          ...sx,
        }}
      ></MDAvatar>

      <MDBox
        component="label"
        sx={{
          opacity: isLoading ? 0.72 : 0,
          width: 74,
          height: 74,
          borderRadius: '50%',
          backgroundColor: '#f3f3f3',
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          transition: 'all 0.5s',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&:hover': {
            ...(!isDisabled && {
              opacity: 0.72,
              cursor: 'pointer',
            }),
          },
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {logoURL ? <Edit /> : <Add fontSize="medium" />}
            <input
              disabled={isLoading || isDisabled}
              onChange={onInputFileUploadHandler}
              type="file"
              hidden
              accept="image/*"
            />
          </>
        )}
      </MDBox>
    </MDBox>
  );
};
