import { FC } from 'react';
import { Popover, PopoverOrigin } from '@mui/material';
import { getPosition } from './getPosition';
import { MenuPopoverProps } from './types';
import { StyledArrow } from './MenuPopover.styles';

export const MenuPopover: FC<MenuPopoverProps> = ({
  open,
  children,
  arrow = 'top-right',
  disabledArrow,
  sx,
  ...other
}) => {
  const { style, anchorOrigin, transformOrigin } = getPosition(arrow);

  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      anchorOrigin={anchorOrigin as PopoverOrigin}
      transformOrigin={transformOrigin as PopoverOrigin}
      PaperProps={{
        sx: {
          overflow: 'inherit',
          background: 'white',
          border: theme => `1px solid ${theme.borders.borderColor}`,
          ...style,
          ...sx,
        },
      }}
      {...other}
    >
      {!disabledArrow && <StyledArrow arrow={arrow} />}
      {children}
    </Popover>
  );
};
