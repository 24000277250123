import { Card } from '@mui/material';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { PinCodeForm } from './PinCodeForm';

export const PIN_CODE_REQUIREMENTS = ['4 digits long'];

export const PinCode: React.FC = () => {
  return (
    <Card id="change-password">
      <MDBox p={3}>
        <MDTypography variant="h5">PIN code</MDTypography>
      </MDBox>
      <PinCodeForm />
    </Card>
  );
};
