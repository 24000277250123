import { Pagination } from 'features/case-law-types';
import { ListOfItems, Logo, UserRoles, backendApiClient } from 'features/common';
import { CompanyInfo, UserProfile } from 'features/company-list';

const BASE_USER_REVIEW_API_URL: Readonly<string> = '/user_reviews';

export type ReviewUser = {
  '@id': string;
  '@type': 'User';
  firebaseUser: string;
  userProfile: Omit<UserProfile, 'personTitle'>;
  logo: null | Omit<Logo, '@context'>;
  company: null | CompanyInfo;
  roles: UserRoles;
};

export type GetUserReviewsResponse = CompanyInfo & {
  '@id': string;
  '@type': 'UserReview';
  id: string;
  body: string;
  active: boolean;
  createdAt: string;
  updatedAt: string | null;
  rating: number | null;
  reviewedUser: ReviewUser;
  createdBy: ReviewUser;
};

export type GetUserReviewParams = Pagination & {
  active?: boolean;
};

export type CreateReviewPayload = Partial<{
  body: string;
  rating: number;
  reviewedUser: string;
}>;

export const reviewApiService = () => {
  const getUserReviews = async (params: GetUserReviewParams = { itemsPerPage: 10, active: false }) =>
    await backendApiClient({}).get<ListOfItems<GetUserReviewsResponse[]>>(BASE_USER_REVIEW_API_URL, {
      params,
    });

  const deleteUserReview = async (id: string) => await backendApiClient({}).delete(`${BASE_USER_REVIEW_API_URL}/${id}`);

  const patchUserReviewStatus = async (
    id: string,
    patchPayload: {
      active: boolean;
    } = { active: true }
  ) => {
    const data = await backendApiClient({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch<GetUserReviewsResponse>(`${BASE_USER_REVIEW_API_URL}/${id}/status`, patchPayload);
    return data;
  };

  const createUserReview = async (payload: CreateReviewPayload) =>
    await backendApiClient({}).post(BASE_USER_REVIEW_API_URL, payload);

  return {
    createUserReview,
    getUserReviews,
    deleteUserReview,
    patchUserReviewStatus,
  };
};
