import { FC } from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';
import MDBox from 'components/MDBox';

type CreateCaseStepperProps = {
  activeStep: number;
  steps: string[];
};

export const CreateCaseStepper: FC<CreateCaseStepperProps> = ({ activeStep, steps }) => {
  return (
    <MDBox mx={2} mt={0}>
      <Stepper activeStep={activeStep} alternativeLabel sx={{ overflowX: 'auto' }}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </MDBox>
  );
};
