import { useState } from 'react';
import { match } from 'ts-pattern';
import { Card, Grid, Icon, InputAdornment, Tooltip } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { baseBackendErrorHandler } from 'features/common/errorHanders';
import MDPagination from 'components/MDPagination';
import { SideMenu } from 'features/common/components/SideMenu';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { TableHeader } from 'features/common/components/TableHeader';
import { BaseFormField, DashboardLayout, notice, ToastType, useBoolean } from '../../common';
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import { CategoryNavigation, ContentItemTable } from '../components';
import { useContentCategories } from '../hooks/useContentCategories';
import {
  createContentCategoryFormSchema,
  CreateContentCategoryFormSchema,
  EditContentCategoryFormSchema,
} from './formSchema';
import MDButton from 'components/MDButton';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export const ContentItemsTemplate = (): JSX.Element => {
  const navigate = useNavigate();

  const [selectedCategoryData, setSelectedCategoryData] = useState<{
    categoryId: string;
    categorySlug: string;
    categoryName: string;
  }>({
    categoryId: '',
    categorySlug: 'all',
    categoryName: 'All',
  });

  const {
    loadContentCategoriesState,
    contentCategoriesList,
    createContentCategory,
    loadContentCategoriesHandler,
    patchContentCategoryById,
    deleteContentCategoryById,
    setContentCategoriesPage,
    contentCategoriesPage,
    contentCategoriesTotalPages,
  } = useContentCategories({});
  const [isContentCategoryDeleteModalOpen, openContentCategoryDeleteModal, closeContentCategoryDeleteModal] =
    useBoolean(false);
  const [isContentCategoryEditModalOpen, openContentCategoryEditModal, closeContentCategoryEditModal] =
    useBoolean(false);
  const [isContentCategoryAddModalOpen, openContentCategoryAddModal, closeContentCategoryAddModal] = useBoolean(false);

  const onCategorySelect = (id: string, slug: string, categoryName: string) => {
    setSelectedCategoryData(prevState => ({
      ...prevState,
      categoryId: id !== 'All' ? id : undefined,
      categorySlug: slug,
      categoryName,
    }));
    clearErrors();
  };

  const { handleSubmit, reset, register, formState, setError, setValue, clearErrors } =
    useForm<CreateContentCategoryFormSchema>({
      resolver: yupResolver(createContentCategoryFormSchema),
      mode: 'onTouched',
    });

  const onCreateCategoryFormHandler = async (data: CreateContentCategoryFormSchema) => {
    try {
      await createContentCategory({
        name: data.name,
        slug: data.slug,
      });

      await loadContentCategoriesHandler();
      closeContentCategoryAddModal();
      reset();
      clearErrors();
      notice(ToastType.SUCCESS, `Successfully created content category!`);
    } catch (error) {
      console.error(error);
      baseBackendErrorHandler(error, { formError: { formData: data, setError } });
    }
  };

  const onEditCategoryFormHandler = async (data: EditContentCategoryFormSchema) => {
    try {
      await patchContentCategoryById(selectedCategoryData.categoryId, {
        name: data.name,
        slug: data.slug,
      });
      await loadContentCategoriesHandler();
      closeContentCategoryEditModal();
      reset();
      clearErrors();

      notice(ToastType.SUCCESS, `Successfully edited content category!`);
    } catch (error) {
      console.error(error);
      baseBackendErrorHandler(error, { formError: { formData: data, setError } });
    }
  };

  const onChangePage = async (newPage: number) => {
    setContentCategoriesPage(newPage);
  };

  const onDeleteCategory = async () => {
    try {
      closeContentCategoryDeleteModal();
      await deleteContentCategoryById(selectedCategoryData.categoryId);
      setSelectedCategoryData({ categoryId: '', categorySlug: 'all', categoryName: 'All' });
      await loadContentCategoriesHandler();
      notice(ToastType.SUCCESS, `Successfully deleted category`);
    } catch (error) {
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong!');
    }
  };

  const onAddItem = async (categoryId: string) => {
    navigate('/content-items/create/' + categoryId);
  };

  const onEditCategoryClick = (categoryId: string) => {
    const currentCategory = contentCategoriesList.find(category => category['@id'] === categoryId);
    if (currentCategory) {
      setValue('name', currentCategory.name);
      setValue('slug', currentCategory?.slug);
      openContentCategoryEditModal();
    }
  };

  return (
    <DashboardLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={4}>
          <Card>
            <MDBox
              sx={{
                borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
                display: 'flex',
                flexDirection: 'column',
                gap: '3px',
                minWidth: '200px',
                width: '100%',
              }}
              p={2}
              lineHeight={1}
            >
              <MDTypography variant="h5" fontWeight="medium" px={2} pt={1.5}>
                Categories
              </MDTypography>
              <MDTypography variant="button" color="text" px={2} mb={1}>
                Manage categories here.
              </MDTypography>
              <CategoryNavigation
                selectedCategoryData={selectedCategoryData}
                onCategoryAdd={openContentCategoryAddModal}
                onCategoryClick={onCategorySelect}
                onAddCategoryItem={onAddItem}
                onEditCategory={onEditCategoryClick}
                onDeleteCategory={openContentCategoryDeleteModal}
                additionalItems={match(loadContentCategoriesState.status)
                  .with('successful', () => contentCategoriesList)
                  .otherwise(() => undefined)}
              />
              <MDBox
                display="flex"
                width="100%"
                sx={{
                  borderTop: 1,
                  borderTopColor: 'lightgray',
                  marginTop: 1,
                }}
                py={2}
                justifyContent={'center'}
              >
                <MDBox width="33%" sx={{ display: 'flex', justifyContent: 'center' }}>
                  {contentCategoriesPage > 1 && (
                    <MDPagination item onClick={() => onChangePage(contentCategoriesPage - 1)}>
                      <Icon sx={{ fontWeight: 'bold', fontSize: '20px' }}>chevron_left</Icon>
                    </MDPagination>
                  )}
                </MDBox>
                <MDBox width="33%" sx={{ display: 'flex', justifyContent: 'center' }}>
                  {contentCategoriesTotalPages > 1 && (
                    <MDTypography>
                      {contentCategoriesPage} of {contentCategoriesTotalPages}
                    </MDTypography>
                  )}
                </MDBox>
                <MDBox width="33%" sx={{ display: 'flex', justifyContent: 'center' }}>
                  {contentCategoriesPage < contentCategoriesTotalPages && (
                    <MDPagination
                      sx={{ placeSelf: 'end', marginLeft: 'auto' }}
                      item
                      onClick={() => onChangePage(contentCategoriesPage + 1)}
                    >
                      <Icon sx={{ fontWeight: 'bold', fontSize: '20px' }}>chevron_right</Icon>
                    </MDPagination>
                  )}
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={8}>
          <MDBox mb={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card
                  sx={{
                    minWidth: 300,
                    minHeight: 300,
                    display: 'flex',
                    width: 1,
                  }}
                >
                  <TableHeader title="Content Items" subtitle="Here you can work with content items." />
                  <ContentItemTable selectedCategoryData={selectedCategoryData} />
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>

      <DialogWindow
        onCloseDialogHandler={closeContentCategoryDeleteModal}
        onApproveDialogHandler={onDeleteCategory}
        isDialogOpen={isContentCategoryDeleteModalOpen}
        dialogTitle="Are you sure want to delete Category?"
      />

      <SideMenu
        isOpen={isContentCategoryEditModalOpen}
        onClose={() => {
          clearErrors('name');
          closeContentCategoryEditModal();
        }}
        customWidth={450}
        title="Edit Category"
      >
        <MDBox
          display="flex"
          flexDirection="column"
          py={4}
          component="form"
          onSubmit={handleSubmit(onEditCategoryFormHandler)}
        >
          <BaseFormField
            errorValue={formState.errors['name']?.message}
            formInputProps={{
              ...register('name'),
              type: 'text',
              label: 'Name',
              fullWidth: true,
              color: 'white',
              InputLabelProps: { shrink: true },
            }}
          />

          <BaseFormField
            errorValue={formState.errors['slug']?.message}
            formInputProps={{
              ...register('slug'),
              type: 'text',
              label: 'Slug',
              fullWidth: true,
              color: 'white',
              InputLabelProps: { shrink: true },
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start" sx={{ cursor: 'pointer' }}>
                    <Tooltip
                      title="Attention! Any changes to the slug field may lead to incorrect display of content on the landing page"
                      sx={{
                        color: theme => theme.palette.warning.main,
                      }}
                    >
                      <ErrorOutlineOutlinedIcon fontSize="medium" />
                    </Tooltip>
                  </InputAdornment>
                ),
              },
            }}
          />
          <MDBox mt={1} display="flex" justifyContent="space-between">
            <MDButton variant="outlined" color="dark" type="button" onClick={closeContentCategoryEditModal}>
              Discard
            </MDButton>
            <MDButton
              isLoading={formState.isSubmitting}
              disabled={formState.isSubmitting}
              variant="gradient"
              color="info"
              type="submit"
            >
              Save
            </MDButton>
          </MDBox>
        </MDBox>
      </SideMenu>

      <SideMenu
        isOpen={isContentCategoryAddModalOpen}
        onClose={() => {
          clearErrors();
          closeContentCategoryAddModal();
        }}
        title="Add new category"
        customWidth={450}
      >
        <MDBox
          display="flex"
          flexDirection="column"
          py={4}
          component="form"
          onSubmit={handleSubmit(onCreateCategoryFormHandler)}
        >
          <BaseFormField
            errorValue={formState.errors['name']?.message}
            formInputProps={{
              ...register('name'),
              type: 'text',
              label: 'Name',
              fullWidth: true,
              color: 'white',
              InputLabelProps: { shrink: true },
            }}
          />

          <BaseFormField
            errorValue={formState.errors['slug']?.message}
            formInputProps={{
              ...register('slug'),
              type: 'text',
              label: 'Slug',
              fullWidth: true,
              color: 'white',
              InputLabelProps: { shrink: true },
            }}
          />
          <MDBox mt={1} display="flex" justifyContent="space-between">
            <MDButton variant="outlined" color="dark" type="button" onClick={closeContentCategoryAddModal}>
              Discard
            </MDButton>
            <MDButton
              isLoading={formState.isSubmitting}
              disabled={formState.isSubmitting}
              variant="gradient"
              color="info"
              type="submit"
            >
              Save
            </MDButton>
          </MDBox>
        </MDBox>
      </SideMenu>
    </DashboardLayout>
  );
};
