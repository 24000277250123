import { Pagination } from 'features/case-law-types';
import { ListOfItems, backendApiClient } from '../../common';

export type CreateStaticPagePayload = {
  urlPath: string;
  contentTitle: string;
  contentBody: string;
  publicVisibility?: boolean;
};

type PatchStaticPagePayload = Partial<CreateStaticPagePayload>;

type CreateStaticPageResponse = {
  '@context': string;
  '@id': string;
  '@type': string;
  urlPath: string;
  contentTitle: string;
  contentBody: string;
  publicVisibility?: boolean;
  createdAt: string;
  updatedAt: string;
};

export type GetStaticPageByIdResponse = CreateStaticPageResponse;

export type GetStaticPageByUrlResponse = {
  '@context': string;
  '@id': string;
  '@type': string;
  contentTitle: string;
  contentBody: string;
};

export type StaticPageItemResponse = {
  '@id': string;
  '@type': string;
  urlPath: string;
  contentTitle: string;
  publicVisibility?: boolean;
  createdAt: string;
  updatedAt?: string;
};

export type GetStaticPagesResponse = {
  'hydra:member': ReadonlyArray<StaticPageItemResponse>;
};

export type GetStaticPagesParams = Pagination & {
  publicVisibility?: boolean;
  'publicVisibility[]'?: boolean[];
};

export const createStaticPagesService = () => {
  const getStaticPages = async (params: GetStaticPagesParams) =>
    await backendApiClient({}).get<ListOfItems<StaticPageItemResponse[]>>('/content_pages', { params });

  const getContentPageById = async (id: string) => {
    try {
      return await backendApiClient({}).get<GetStaticPageByIdResponse>(
        `/content_pages/${id.replace('/content_pages/', '')}`
      );
    } catch (error) {
      throw error;
    }
  };

  const getStaticPageByUrlPath = async (urlPath: string) => {
    try {
      return await backendApiClient({}).get<GetStaticPageByUrlResponse>(`/content_pages/static/${urlPath}`);
    } catch (error) {
      throw error;
    }
  };

  const createStaticPage = async (createPagePayload: CreateStaticPagePayload) => {
    try {
      return await backendApiClient({}).post<CreateStaticPageResponse, CreateStaticPagePayload>(
        '/content_pages',
        createPagePayload
      );
    } catch (error) {
      throw error;
    }
  };

  const patchContentPageById = async (id: string, patchPagePayload: PatchStaticPagePayload) => {
    try {
      return await backendApiClient({
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }).patch<GetStaticPageByUrlResponse>(`${id}`, patchPagePayload);
    } catch (error) {
      throw error;
    }
  };

  const deleteContentPageById = async (id: string) => {
    try {
      return await backendApiClient({}).delete(`${id}`);
    } catch (error) {
      throw error;
    }
  };

  return {
    getStaticPages,
    getContentPageById,
    createStaticPage,
    getStaticPageByUrlPath,
    patchContentPageById,
    deleteContentPageById,
  };
};
