import * as yup from 'yup';
import { MAX_LENGTH_VALIDATION_MESSAGE, Note } from 'features/my-legal-cases/components/CreateLegalCase/form.config';
import { AgoraEventType } from 'features/common/services/agoraEventApiService';

type EventNote = Pick<Note, 'noteBody'>;

export type CreateEventValidationScheme = {
  isReminder?: boolean;
  reminderValue?: string;
  time: Date;
  date: Date;
  eventType: AgoraEventType;
  isAddNote?: boolean;
  note?: Partial<EventNote>;
  legalCaseId: string;
  firebaseUserId: string;
};

export const createEventValidatationSchema: yup.ObjectSchema<CreateEventValidationScheme> = yup
  .object({
    legalCaseId: yup.string().required('This field is required'),
    firebaseUserId: yup.string().required('This field is required'),
    isReminder: yup.boolean().notRequired(),
    reminderValue: yup
      .string()
      .notRequired()
      .when('isReminder', {
        is: true,
        then: (schema: yup.StringSchema<string, yup.AnyObject, any, ''>) => schema.required('This field is required'),
        otherwise: (schema: yup.StringSchema<string, yup.AnyObject, any, ''>) => schema.notRequired(),
      }),
    time: yup.date().nonNullable().required('Please choose a time'),
    date: yup.date().nonNullable().required('Please choose a date'),
    eventType: yup.string<AgoraEventType>().required('Event type is required field'),
    isAddNote: yup.boolean().notRequired(),
    note: yup
      .object()
      .shape({
        noteBody: yup.string().notRequired(),
      })
      .notRequired()
      .when('isAddNote', {
        is: true,
        then: (schema: yup.ObjectSchema<EventNote, yup.AnyObject, any, ''>) =>
          schema
            .shape({
              noteBody: yup
                .string()
                .trim()
                .required('Please enter the note description')
                .max(1000, MAX_LENGTH_VALIDATION_MESSAGE),
            })
            .required('Please add a note'),
        otherwise: (schema: yup.ObjectSchema<EventNote, yup.AnyObject, any, ''>) => schema.notRequired(),
      }),
  })
  .required();
