import { SyntheticEvent } from 'react';
import { UserCredential } from '@firebase/auth';
import { AsyncState, Errored } from 'features/common';

export enum AuthMethodType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export enum UserWorkType {
  ATTORNEY = 'ATTORNEY',
  CLIENT = 'CLIENT',
}

export type AuthenticationMethodTabsProps = {
  authMethodType: AuthMethodType;
  handleSetTabValue: (event: SyntheticEvent<Element, Event>, authMethodType: AuthMethodType) => void;
};

export type AuthType = 'signIn' | 'signUp';

export type AuthTemplateProps = {
  authType: AuthType;
};

export type AuthHandlerByAuthType = (
  ...args:
    | ['email', Promise<void | UserCredential> | UserCredential]
    | ['oauth', Promise<void | UserCredential> | UserCredential]
    | ['phone', Promise<void | UserCredential> | UserCredential]
) => Promise<void>;

export type CommonFirebaseAuthTypeComponentProps = {
  authHandler: AuthHandlerByAuthType;
  signInState: AsyncState<void, Errored>;
  authMethodType: AuthMethodType;
  handleSetTabValue: (event: any, val: AuthMethodType) => void;
  acceptAdminInvitationHandler?: () => Promise<void>;
};

export type FirebaseUserAuthFormStepProps = {
  onFirebaseUserAuth: (userCredential: UserCredential) => Promise<void>;
};

export type FirebaseProvider = {
  displayName: null | string;
  email: null | string;
  phoneNumber: null | string;
  photoURL: null | string;
  providerId: string;
  uid: string;
};
