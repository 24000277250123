import { FC, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { DashboardLayout, useUser } from 'features/common';
import { useMyCompany } from 'features/store';
import { BaseSideNaveMenu } from 'features/common/components/BaseSideNaveMenu';
import { Loader } from 'features/common/components/Loader';
import { RegisterNewCompanyTemplate } from './RegisterNewCompanyTemplate/RegisterNewCompanyTemplate';
import { sideNavMenuRoutes } from './company.config';

export const MyCompanyTemplate: FC = () => {
  const { backendUserFirebaseId, connectBackendUserHandler } = useUser();
  const [isLoading, setIsLoading] = useState(true);

  const { myCompany, fetchUserCompanyHandler, getCreateCompanyDescription, registerCompanyDescription } = useMyCompany(
    state => ({
      myCompany: state.myCompany,
      fetchUserCompanyHandler: state.fetchUserCompanyHandler,
      getCreateCompanyDescription: state.getCreateCompanyDescription,
      registerCompanyDescription: state.registerCompanyDescription,
    }),
    shallow
  );

  const onFetchUserCompanyHandler = async () => {
    !isLoading && setIsLoading(true);
    await fetchUserCompanyHandler();
    setIsLoading(false);
  };

  useEffect(() => {
    onFetchUserCompanyHandler();
    connectBackendUserHandler();
  }, []);

  const isCompanyOwner = myCompany?.owner?.firebaseUser === backendUserFirebaseId;
  const sidenavItems = sideNavMenuRoutes(isCompanyOwner);

  return (
    <DashboardLayout>
      <Grid container spacing={3} flex={isLoading ? 1 : 0}>
        {isLoading ? (
          <Grid item xs={12} flex={1} display="flex">
            <Loader />
          </Grid>
        ) : myCompany ? (
          <>
            <Grid item xs={12} sm={12} md={4}>
              <BaseSideNaveMenu sidenavItems={isCompanyOwner ? sidenavItems : sidenavItems.slice(0, 5)} />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Outlet />
            </Grid>
          </>
        ) : (
          <RegisterNewCompanyTemplate
            registerCompanyDescription={registerCompanyDescription}
            getCreateCompanyDescription={getCreateCompanyDescription}
          />
        )}
      </Grid>
    </DashboardLayout>
  );
};
