import { FC, useEffect } from 'react';
import { DashboardLayout, SideMenu } from 'features/common';
import Calendar from 'examples/Calendar';
import {
  CalendarConfig,
  eventDataTransformHandler,
  getCustomButtons,
  selectAllowHandler,
} from 'features/case-details/components/CaseEvents/calendar.config';
import MDBox from 'components/MDBox';
import { Loader } from 'features/common/components/Loader';
import { CreateEventModalContent } from 'features/case-details/components/CaseEvents/components/CreateEventModalContent';
import { useEventsState } from 'features/case-details/components/CaseEvents/hooks/useEventsState';
import { ListOfEvents } from 'features/case-details/components/CaseEvents/components/ListOfEvents/ListOfEvents';
import { EventContent } from 'features/case-details/components/CaseEvents/components/EventContent';
import { DialogWindow } from 'features/common/components/DialogWindow';

export const MyEventsTemplate: FC = () => {
  const {
    calendarRef,
    calendarState,
    caseId,
    closeAllEventsModal,
    closeConfirmDeleteEventModal,
    eventsByDateData,
    formattedDate,
    getMoreEventsByDateHandler,
    handleCustomButtonClick,
    isAddEventModalOpen,
    isAllEventsModalOpen,
    isConfirmDeleteEventModalOpen,
    isEventDataLoading,
    isEventsByDateLoading,
    isLoading,
    onCellClickHandler,
    onClickMoreEventsButtonHandler,
    onCloseModalContentHandler,
    onEventClickHandler,
    onEventDropHandler,
    onFetchEventsHandler,
    onDeleteEventButtonClickHandler,
    onDeleteEventIconClickHandler,
    patchEventHandler,
    createEventHandler,
    onEditIconClickHandler,
    selectedDayRange,
    userFirebaseId,
    userFullName,
    currentEvent,
    events,
    isAttorney,
    myLegalCase,
    backendUser,
    currentDate,
    onClickConnectToCallButtonHandler,
    onCreateEventButtonClickHandler,
  } = useEventsState();

  useEffect(() => {
    onFetchEventsHandler();
  }, []);

  return (
    <>
      <DashboardLayout>
        <MDBox position="relative">
          <Calendar
            calendarRef={calendarRef}
            header={{ title: 'My events' }}
            selectable
            initialView="dayGridMonth"
            initialDate={new Date()}
            dayMaxEvents={1}
            editable
            selectAllow={selectAllowHandler}
            eventDataTransform={eventDataTransformHandler}
            eventConstraint={CalendarConfig.eventConstraint}
            eventDrop={onEventDropHandler}
            headerToolbar={CalendarConfig.headerToolbar}
            customButtons={getCustomButtons(handleCustomButtonClick, onCreateEventButtonClickHandler)}
            moreLinkClick={onClickMoreEventsButtonHandler}
            eventDurationEditable={false}
            eventClick={onEventClickHandler}
            events={events}
            eventTimeFormat={{ hour: 'numeric', minute: '2-digit', meridiem: 'short' }}
            select={onCellClickHandler}
            eventContent={event => (
              <EventContent
                event={event}
                onDeleteEventIconClickHandler={onDeleteEventIconClickHandler}
                onEditIconClickHandler={onEditIconClickHandler}
                onClickConnectToCallButtonHandler={onClickConnectToCallButtonHandler}
                userFirebaseId={backendUser?.firebaseUser}
              />
            )}
          />
          {isLoading && (
            <MDBox
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                display: 'flex',
                width: 1,
                height: 1,
                borderRadius: '12px',
                overflow: 'hidden',
                backgroundColor: 'rgba(0, 0, 0, 0)',
                zIndex: ({ zIndex }) => zIndex.fab,
                '& > div': {
                  mt: '70px',
                },
              }}
            >
              <Loader />
            </MDBox>
          )}
        </MDBox>
      </DashboardLayout>

      <SideMenu
        isOpen={isAddEventModalOpen}
        onClose={onCloseModalContentHandler}
        title={`${currentEvent || isEventDataLoading ? 'Edit' : 'Create'} event`}
        customWidth={450}
      >
        <CreateEventModalContent
          legalCaseId={caseId ? myLegalCase?.['@id'] : null}
          userFirebaseId={caseId ? userFirebaseId : backendUser?.firebaseUser}
          caseNumber={myLegalCase?.caseNumber}
          userFullName={userFullName}
          selectedDate={currentDate || calendarState?.start}
          isAttorney={isAttorney}
          isEventDataLoading={isEventDataLoading}
          createEventHandler={createEventHandler}
          patchEventHandler={patchEventHandler}
          closeAddEventModal={onCloseModalContentHandler}
          currentEventData={
            currentEvent
              ? {
                  noteEnabled: currentEvent?.noteEnabled,
                  note: currentEvent?.note,
                  notificationTime: currentEvent?.notificationTime,
                  startedAt: currentEvent?.startedAt,
                  notificationEnabled: currentEvent?.notificationEnabled,
                  type: currentEvent?.type,
                  id: currentEvent?.id,
                  participants: currentEvent?.participants,
                  legalCase:
                    typeof currentEvent?.legalCase === 'string'
                      ? currentEvent?.legalCase
                      : currentEvent?.legalCase?.['@id'],
                }
              : null
          }
        />
      </SideMenu>

      <SideMenu isOpen={isAllEventsModalOpen} onClose={closeAllEventsModal} title={formattedDate} customWidth={450}>
        <ListOfEvents
          onClickConnectToCallButtonHandler={onClickConnectToCallButtonHandler}
          eventsByDate={eventsByDateData?.events}
          isEventsByDateLoading={isEventsByDateLoading}
          onDeleteEventIconClickHandler={onDeleteEventIconClickHandler}
          onEditIconClickHandler={onEditIconClickHandler}
          totalItems={eventsByDateData.totalItems}
          getMoreEventsByDateHandler={getMoreEventsByDateHandler}
          caseId={caseId ? myLegalCase?.['@id'] : null}
          selectedDayRange={selectedDayRange}
          userFirebaseId={backendUser?.firebaseUser}
        />
      </SideMenu>

      <DialogWindow
        onCloseDialogHandler={closeConfirmDeleteEventModal}
        onApproveDialogHandler={onDeleteEventButtonClickHandler}
        isDialogOpen={isConfirmDeleteEventModalOpen}
        dialogTitle="Are you sure want to delete the event?"
      />
    </>
  );
};
