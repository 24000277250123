import { PracticeAreaCategory } from 'features/clio-settings/ClioSettingsApiService';
import * as yup from 'yup';

export type ExportCaseToClioValidationScheme = {
  practiceArea: PracticeAreaCategory;
};

const PracticeAreaCategorySchema = yup
  .object()
  .shape({
    '@id': yup.string().required(),
    '@type': yup.string().required(),
    id: yup.string().required(),
    value: yup.string().required(),
  })
  .required();

export const exportCaseToClioValidatationSchema = yup
  .object({
    practiceArea: PracticeAreaCategorySchema,
  })
  .required();
