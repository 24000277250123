import { Icon } from '@mui/material';
import MDBox from 'components/MDBox';
import MDPagination from 'components/MDPagination';
import MDTypography from 'components/MDTypography';

export type TablePaginationFooterProps = {
  onChangePage: (newPage: number) => void;
  totalItems: number;
  currentItemsPerPage: number;
  page: number;
};
export const TablePaginationFooter = ({
  onChangePage,
  totalItems,
  currentItemsPerPage,
  page,
}: TablePaginationFooterProps) => {
  const totalPagesOptions = Array(Math.ceil(totalItems / currentItemsPerPage))
    .fill(0)
    .map((pageOption, index) => index);
  // Render the paginations
  const canNextPage = page < totalPagesOptions.length;
  const canPreviousPage = page > 1;
  const renderPagination = totalPagesOptions.map((option: any) => (
    <MDPagination
      item
      key={option}
      onClick={() => {
        onChangePage(Number(option + 1));
      }}
      active={page - 1 === option}
    >
      {option + 1}
    </MDPagination>
  ));

  return (
    <MDBox
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      justifyContent="space-between"
      alignItems={{ xs: 'flex-start', sm: 'center' }}
      p={3}
    >
      <MDBox mb={{ xs: 3, sm: 0 }}>
        <MDTypography variant="button" color="secondary" fontWeight="regular">
          Showing {page} page from {totalPagesOptions.length}
        </MDTypography>
      </MDBox>
      {totalPagesOptions.length > 1 && (
        <MDPagination variant="gradient" color="secondary">
          {canPreviousPage && (
            <MDPagination item onClick={() => onChangePage(page - 1)}>
              <Icon sx={{ fontWeight: 'bold' }}>chevron_left</Icon>
            </MDPagination>
          )}
          {renderPagination.length > 6 ? null : renderPagination}
          {canNextPage && (
            <MDPagination item onClick={() => onChangePage(page + 1)}>
              <Icon sx={{ fontWeight: 'bold' }}>chevron_right</Icon>
            </MDPagination>
          )}
        </MDPagination>
      )}
    </MDBox>
  );
};
