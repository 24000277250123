import { FC } from 'react';
import { Autocomplete, CircularProgress, Stack } from '@mui/material';
import { BaseFormField } from 'features/common';
import MDButton from 'components/MDButton';
import { useRegisterCompanyForm } from 'features/my-company/hooks/useRegisterCompanyForm';
import MDBox from 'components/MDBox';
import { Controller } from 'react-hook-form';
import { PhoneField } from 'features/common/components/PhoneField';

type RegisterCompanyFormProps = {
  onCloseSideBarHandler: () => void;
};

export const RegisterNewCompanyForm: FC<RegisterCompanyFormProps> = ({ onCloseSideBarHandler }) => {
  const {
    onChangeCountryHandler,
    onChangeCountryStateHandler,
    onChangeTownHandler,
    onSubmit,
    register,
    onChangeTownInputValueHandler,
    control,
    cityList,
    countryList,
    countryStateList,
    errors,
    isSubmitButtonDisabled,
    isTownCityFieldInputLoading,
    selectedCountry,
    selectedState,
    selectedTown,
    isLoading,
  } = useRegisterCompanyForm(onCloseSideBarHandler);

  return (
    <form onSubmit={onSubmit}>
      <Stack
        spacing={1}
        my={2}
        sx={{
          '&.MuiAutocomplete-popperDisablePortal, .MuiAutocomplete-popper': {
            display: !cityList.length ? 'none !important' : 'block !important',
          },
        }}
      >
        <BaseFormField
          formInputProps={{
            ...register('fullName'),
            type: 'text',
            label: 'Company full name',
            fullWidth: true,
          }}
          errorValue={errors['fullName']?.message}
        />
        <BaseFormField
          formInputProps={{
            ...register('shortName'),
            type: 'text',
            label: 'Company short name',
            fullWidth: true,
          }}
          errorValue={errors['shortName']?.message}
        />

        <BaseFormField
          formInputProps={{
            ...register('companyEmail'),
            type: 'text',
            label: 'Company public email',
            fullWidth: true,
          }}
          errorValue={errors['companyEmail']?.message}
        />

        <BaseFormField
          formInputProps={{
            ...register('site'),
            type: 'text',
            label: 'Company site',
            fullWidth: true,
          }}
          errorValue={errors['site']?.message}
        />

        <BaseFormField
          formInputProps={{
            ...register('description'),
            type: 'text',
            label: 'Company description',
            fullWidth: true,
          }}
          errorValue={errors['description']?.message}
        />

        <Controller
          control={control}
          name="companyPhone"
          render={({ field }) => {
            const onChange = (value: string) => field.onChange(value);
            return (
              <PhoneField
                label="Company public phone number"
                {...field}
                onChange={onChange}
                errorValue={errors['companyPhone']?.message}
              />
            );
          }}
        />

        <Autocomplete
          options={countryList}
          getOptionLabel={option => option.name}
          value={selectedCountry}
          isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
          {...register('country')}
          freeSolo={false}
          onChange={onChangeCountryHandler}
          disableClearable={false}
          disabled={!countryList.length}
          renderInput={params => (
            <BaseFormField
              formInputProps={{
                ...params,
                label: 'Country',
                placeholder: 'Select a country',
                InputLabelProps: { shrink: true },
                fullWidth: true,
              }}
              errorValue={errors['country']?.message}
            />
          )}
        />
        <Autocomplete
          value={selectedState}
          options={countryStateList}
          getOptionLabel={option => option.name}
          isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
          disableClearable={false}
          {...register('state')}
          disabled={!countryStateList.length}
          onChange={onChangeCountryStateHandler}
          freeSolo={false}
          renderInput={params => (
            <BaseFormField
              formInputProps={{
                ...params,
                label: 'State',
                placeholder: !selectedCountry ? 'Select a country first' : 'Select a state',
                fullWidth: true,
                InputLabelProps: { shrink: true },
              }}
              errorValue={errors['state']?.message}
            />
          )}
        />
        <Autocomplete
          value={selectedTown}
          isOptionEqualToValue={(option, value) => option === value}
          disablePortal
          getOptionLabel={option => option}
          options={cityList.map(option => option.name)}
          {...register('town')}
          onChange={onChangeTownHandler}
          onInputChange={onChangeTownInputValueHandler}
          freeSolo
          disableClearable={false}
          loading={isTownCityFieldInputLoading}
          autoSelect
          renderInput={params => (
            <BaseFormField
              formInputProps={{
                ...params,
                label: 'Town/City',
                placeholder: 'Select a Town/City or enter your own',
                fullWidth: true,
                InputLabelProps: { shrink: true },
                InputProps: {
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isTownCityFieldInputLoading ? (
                        <MDBox pr={0} display="flex">
                          <CircularProgress color="inherit" size={20} />
                        </MDBox>
                      ) : null}
                      {errors['town']?.message ? null : params.InputProps.endAdornment}
                    </>
                  ),
                },
              }}
              errorValue={errors['town']?.message}
            />
          )}
        />
        <BaseFormField
          formInputProps={{
            ...register('zipCode'),
            type: 'text',
            label: 'Zip code',
            fullWidth: true,
          }}
          errorValue={errors['zipCode']?.message}
        />
        <BaseFormField
          formInputProps={{
            ...register('addressLine1'),
            type: 'text',
            label: 'Address line 1',
            fullWidth: true,
          }}
          errorValue={errors['addressLine1']?.message}
        />
        <BaseFormField
          formInputProps={{
            ...register('addressLine2'),
            type: 'text',
            label: 'Address line 2',
            fullWidth: true,
          }}
          errorValue={errors['addressLine2']?.message}
        />
        <MDButton disabled={isSubmitButtonDisabled} isLoading={isLoading} variant="gradient" color="info" type="submit">
          Register
        </MDButton>
      </Stack>
    </form>
  );
};
