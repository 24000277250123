import { FC, useState } from 'react';
import { AxiosError } from 'axios';
import { Stack } from '@mui/material';
import { BaseFormField, IllustrationLayout, notice, ToastType, createAttorneyService } from 'features/common';
import MDButton from 'components/MDButton';
import { BackendError, baseBackendErrorHandler } from 'features/common/errorHanders';
import { AUTH_FLOW_STEPS, useAuth } from 'features/store';
import { createBackendAuthService } from 'features/auth/services';
import { AuthFlowBackgrounds } from 'features/auth/variables/illustartion';
import { IllustrationLogo } from 'features/common/components/IllustatrionLogo';

export const CreateAttorneyProfileStep: FC = () => {
  const {
    toStep,
    backendUser,
    attorneyProfileAuthData: { attorneyProfileId, barNumber },
    setData,
  } = useAuth(state => ({
    toStep: state.toStep,
    setStateData: state.setStateData,
    setData: state.setData,
    backendUser: state.backendUser,
    attorneyProfileAuthData: state.attorneyProfileAuthData,
  }));

  const { addAttorneyProfileToUserProfile } = createBackendAuthService();
  const { createAttorneyProfile, updateAttorneyProfile } = createAttorneyService();

  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState(barNumber);
  const [codeError, setCodeError] = useState('');

  const onChangeCodeValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
    codeError && setCodeError('');
  };

  const createAttorneyProfileHandler = async () => {
    try {
      if (attorneyProfileId && !code) {
        toStep(AUTH_FLOW_STEPS.SELECT_LAW_TYPE);
        return;
      }
      if (attorneyProfileId && code) {
        const { data } = await updateAttorneyProfile({ attorneyProfileId, barNumber: code });

        setData({
          currentStep: AUTH_FLOW_STEPS.CREATE_ATTORNEY_PROFILE,
          data: { attorneyProfileId: data?.['@id'], barNumber: code },
          activeStep: AUTH_FLOW_STEPS.SELECT_LAW_TYPE,
        });
        return;
      }
      setIsLoading(true);
      const { data } = await createAttorneyProfile(code ? code : null);

      if (data) {
        await addAttorneyProfileToUserProfile(backendUser?.firebaseUser, data?.['@id']);

        setData({
          currentStep: AUTH_FLOW_STEPS.CREATE_ATTORNEY_PROFILE,
          data: { attorneyProfileId: data?.['@id'], barNumber: code },
          activeStep: AUTH_FLOW_STEPS.SELECT_LAW_TYPE,
        });
      }
      setIsLoading(false);
      toStep(AUTH_FLOW_STEPS.SELECT_LAW_TYPE);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      baseBackendErrorHandler(error, {
        customErrorHandler(error) {
          if (error instanceof AxiosError) {
            const errorResponse = error.response.data as BackendError;

            if (errorResponse && 'violations' in errorResponse) {
              return errorResponse.violations.forEach(({ message, propertyPath }) => {
                if (propertyPath === 'barNumber') {
                  setCodeError(message);
                } else {
                  notice(ToastType.ERROR, message);
                }
              });
            }
          }
        },
      });
    }
  };

  const onClickGoNextHandler = async () => await createAttorneyProfileHandler();

  const onSkipBtnClickHandler = async () => {
    if (attorneyProfileId) {
      toStep(AUTH_FLOW_STEPS.SELECT_LAW_TYPE);
      return;
    }
    await createAttorneyProfileHandler();
  };

  return (
    <IllustrationLayout
      title="Bar Number or State Registration (Optional)"
      description="Enhance your profile's credibility by providing your Bar number or state registration. This information is not required but can increase client trust in your expertise."
      illustration={AuthFlowBackgrounds.AUTH_TWO}
      illustrationLogo={<IllustrationLogo top={10} left={20} />}
    >
      <BaseFormField
        formInputProps={{
          type: 'text',
          fullWidth: true,
          placeholder: 'Code',
          value: code,
          onChange: onChangeCodeValueHandler,
        }}
        errorValue={codeError || null}
      />
      <Stack spacing={2}>
        <MDButton
          variant="gradient"
          color="info"
          type="submit"
          fullWidth
          sx={{ textTransform: 'none' }}
          onClick={onClickGoNextHandler}
          disabled={!code || isLoading}
          isLoading={isLoading}
        >
          Next
        </MDButton>
        <MDButton
          type="button"
          variant="outlined"
          color="info"
          fullWidth
          sx={{ textTransform: 'none', bgcolor: 'transparent' }}
          disabled={isLoading || !!codeError}
          onClick={onSkipBtnClickHandler}
        >
          Skip
        </MDButton>
      </Stack>
    </IllustrationLayout>
  );
};
