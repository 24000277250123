import React, { FC, useEffect, useRef, useState } from 'react';
import { CircularProgress, Stack, Theme } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { AddDocumentActionButton } from 'features/case-details/components/Documents';
import { DocumentItem } from 'features/case-details/components/Documents/DocumentItem';
import { DocumentItemProps } from 'features/case-details/types';
import { Loader } from 'features/common/components/Loader';
import { Pagination } from 'features/case-law-types';
import { ChatFilesData } from 'features/store';
import { useIsOnScreen } from 'features/common';
import MDBox from 'components/MDBox';

type DocumentsContentProps = Pick<
  DocumentItemProps,
  'downloadButtonRef' | 'onClickDownloadDocumentHandler' | 'onClickDeleteMessageFileIconHandler'
> & {
  onInputFileUploadHandler: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  isDocumentsLoading: boolean;
  errorMesasage: string;
  remoteChatParticipantFirebaseId: string;
  getChatFilesHandler: () => Promise<void>;
  getMoreChatFiles: (params: Pagination) => Promise<void>;
  chatFilesData: ChatFilesData;
};

export const DocumentsContent: FC<DocumentsContentProps> = ({
  downloadButtonRef,
  onClickDeleteMessageFileIconHandler,
  onClickDownloadDocumentHandler,
  onInputFileUploadHandler,
  isDocumentsLoading,
  errorMesasage,
  remoteChatParticipantFirebaseId,
  getChatFilesHandler,
  getMoreChatFiles,
  chatFilesData: { files, totalItems },
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const onGetChatFilesHandler = async () => {
    await getChatFilesHandler();
    setIsLoading(false);
  };

  useEffect(() => {
    onGetChatFilesHandler();
  }, []);

  const [isFetchMoreLoading, setIsFetchMoreLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pages = Math.ceil(totalItems / 30);

  const lastElementRef = useRef<HTMLDivElement | null>(null);
  const isOnScreen = useIsOnScreen(isLoading || isDocumentsLoading ? null : lastElementRef);

  const onFetchMoreFilesHandler = async () => {
    setIsFetchMoreLoading(true);

    const nextPage = currentPage + 1;
    await getMoreChatFiles({
      itemsPerPage: 30,
      page: nextPage,
    });
    setCurrentPage(nextPage);

    setIsFetchMoreLoading(false);
  };

  useEffect(() => {
    if (isOnScreen && currentPage < pages) {
      onFetchMoreFilesHandler();
    }
  }, [isOnScreen]);

  return (
    <Stack height={1} position="relative">
      <Stack mt={1} direction="row" justifyContent="space-between" alignItems="center">
        <MDTypography variant="body2">Add document</MDTypography>
        <AddDocumentActionButton
          isDisabled={isDocumentsLoading || isLoading}
          onInputFileUploadHandler={onInputFileUploadHandler}
        />
      </Stack>

      <Stack
        spacing={1}
        sx={{
          mt: 3,
          pb: 1.5,
          height: 1,
        }}
      >
        {isDocumentsLoading || isLoading ? (
          <Loader />
        ) : !isDocumentsLoading && files?.length ? (
          files?.map(document => (
            <DocumentItem
              key={document['@id']}
              title={document?.title}
              lastElementRef={lastElementRef}
              id={document?.id}
              messageId={document?.id}
              onClickDeleteMessageFileIconHandler={onClickDeleteMessageFileIconHandler}
              downloadButtonRef={downloadButtonRef}
              onClickDownloadDocumentHandler={onClickDownloadDocumentHandler}
              fileSize={Number(document?.fileSize)}
              ownerFirstName={document?.owner?.userProfile?.firstName}
              ownerLastName={document?.owner?.userProfile?.lastName}
              fileType={document?.type}
              requestedToSignUserFirebaseId={document?.sign?.userSigner?.firebaseUser || ''}
              userSignerFirebaseId={remoteChatParticipantFirebaseId}
              filePermission={document?.filePermission}
              isChatDocument
            />
          ))
        ) : (
          <Stack flex={1} justifyContent="center" alignItems="center" minHeight={150}>
            <MDTypography sx={{ textAlign: 'center' }}>No documents were found...</MDTypography>
          </Stack>
        )}
        {errorMesasage && (
          <MDTypography variant="body2" sx={{ color: ({ palette }: Theme) => palette.error.main, fontSize: '12px' }}>
            {errorMesasage}
          </MDTypography>
        )}
      </Stack>
      {isFetchMoreLoading && (
        <MDBox sx={{ position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-50%)' }}>
          <CircularProgress />
        </MDBox>
      )}
    </Stack>
  );
};
