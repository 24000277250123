import { StoreApi, UseBoundStore, create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export type Store<T extends Record<string, unknown>> = UseBoundStore<StoreApi<T>>;

type Resseter = {
  name: string;
  resetFunction: () => void;
};

export const resetters: Resseter[] = [];

export const resetAllStores = () => {
  for (const resetter of resetters) resetter.resetFunction();
};

export const createStore = <T extends Record<string, unknown>>(
  fn: (set: StoreApi<T>['setState'], get: StoreApi<T>['getState'], api: StoreApi<T>) => T,
  persistName: string,
  initialState: Partial<T>,
  devToolsName?: string
): Store<T> => {
  const store = create<T>()(
    devtools(persist(fn, { name: persistName }), {
      name: devToolsName || persistName,
      enabled: process.env.NODE_ENV === 'development',
      actionsWhitelist: [''],
    })
  );

  const state = store.getState();

  resetters.push({
    name: persistName,
    resetFunction: () => {
      store.setState({ ...state, ...initialState }, true);
    },
  });

  return store;
};
