import { FC } from 'react';
import { DialogActions, DialogContentText, DialogTitle, Stack } from '@mui/material';

type CreateCaseModalContentProps = {
  title: string | React.ReactNode;
  contentTitle: string;
  children: React.ReactNode;
};

export const CreateCaseModalContent: FC<CreateCaseModalContentProps> = ({ contentTitle, title, children }) => {
  return (
    <Stack sx={{ p: 1.5 }}>
      <DialogTitle sx={{ textAlign: 'center' }}>{title}</DialogTitle>
      <DialogContentText textAlign="center">{contentTitle}</DialogContentText>
      <DialogActions>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1.5}
          sx={{ width: 1, pt: 1, justifyContent: 'center', flexDirection: { xs: 'column', sm: 'row' } }}
        >
          {children}
        </Stack>
      </DialogActions>
    </Stack>
  );
};
