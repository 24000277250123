import { FC } from 'react';
import { Stack, Tooltip, alpha } from '@mui/material';
import {
  PhoneOutlined,
  VideocamOutlined,
  ChatOutlined,
  PersonOutline,
  LowPriorityOutlined,
  FileUploadOutlined,
} from '@mui/icons-material';
import MDAvatar from 'components/MDAvatar';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { getAvatarCharacters } from 'features/common/helpers/utilities';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { CaseInfoHeaderProps } from './types';
import { RequestButtonsContent } from './BackToQueueContent';
import { useCaseInfoHeaderState } from 'features/case-details/hooks/useCaseInfoHeaderState';
import { SideMenu } from 'features/common';
import { ExportCaseToClioMenuContent } from './ExportCaseToClioMenuContent';
import { ChooseCRMToExportModalWindow } from './ChooseCRMToExportModalWindow';
import MDButton from 'components/MDButton';

export const CaseInfoHeader: FC<CaseInfoHeaderProps> = ({
  userData: { caseCount, firstName, lastName, rating, userFirebaseId, logoURL },
  companyShortName,
  isDisableActions,
  caseId,
  isClosedCase,
  isFeedView,
  onUserAvatarClickHandler,
  updateLegalCaseHandler,
  representationId,
  consultationData,
  approveRepresentationsHandler,
  declineRepresentationsHandler,
  isCaseWithAttorney,
  caseNumber,
  isAllowToExport,
  isInvitation,
}) => {
  const iconColor = isDisableActions ? 'disabled' : 'inherit';

  const {
    isBackToQueueButton,
    isBackToQueueButtonDisabled,
    isRejecting,
    isApproving,
    isUserFullname,
    isApprovingRepresentation,
    isSendingOffer,
    isSendConsultationOfferButtonDisabled,
    isSendConsultationOfferDialogOpen,
    backToQueueButtonTooltipTitle,
    sendConsultationButtonTooltipTitle,
    isBackToQueueDialogOpen,
    isClient,
    closeBackToQueueDialogHandler,
    openBackToQueueDialogHandler,
    openSendConsultationOfferDialogHandler,
    closeSendConsultationOfferDialogHandler,
    onAudioVideoButtonClickHandler,
    onClickChatButtonHandler,
    onAcceptRepresentationOfferHandler,
    onDeclineOfferConsultationButtonClickHandler,
    onDeclineRepresentationOfferHandler,
    onSendOfferConsultationButtonClickHandler,
    isExportCaseToClioMenuOpen,
    closeExportCaseToClioMenuHandler,
    isChooseCRMWindowOpen,
    openChooseCRMWindowHandler,
    closeChooseCRMWindowHandler,
    onClickOnCRMOptionHandler,
    onClickChooseCRMButtonHandler,
  } = useCaseInfoHeaderState({
    userFirstName: firstName,
    userLastName: lastName,
    userFirebaseId,
    approveRepresentationsHandler,
    caseId,
    caseNumber,
    consultationData,
    declineRepresentationsHandler,
    representationId,
    isClosedCase,
    updateLegalCaseHandler,
  });

  return (
    <Stack spacing={1.5}>
      {consultationData?.isCaseOnConsultation && (
        <MDTypography variant="button" sx={{ px: 1, fontSize: 16 }}>
          You're in counseling with:
        </MDTypography>
      )}
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        px={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" spacing={2} alignItems={!isUserFullname ? 'center' : 'flex-start'}>
          <MDAvatar
            src={logoURL || ''}
            size="lg"
            alt="user avatar"
            onClick={onUserAvatarClickHandler}
            id={userFirebaseId}
            bgColor="info"
            sx={{
              '& img': {
                height: 1,
              },
              fontSize: 16,
              ...(onUserAvatarClickHandler && {
                cursor: 'pointer',
              }),
            }}
          >
            {!isUserFullname ? <PersonOutline fontSize="medium" /> : getAvatarCharacters(firstName, lastName)}
          </MDAvatar>

          <Stack spacing={0.5}>
            <MDTypography variant="subtitle1" fontWeight="medium" fontSize={18}>
              {!isUserFullname ? 'Unrepresented' : `${firstName} ${lastName}`}
            </MDTypography>
            {companyShortName && isUserFullname && <MDTypography variant="subtitle2">{companyShortName}</MDTypography>}

            {isUserFullname && !isInvitation && (
              <Stack spacing={1} direction="row">
                <MDTypography variant="subtitle2">
                  <MDTypography variant="button" fontWeight="medium" fontSize={16} sx={{ mr: 0.5 }}>
                    {caseCount || 0}
                  </MDTypography>
                  {caseCount > 1 ? 'Cases' : 'Case'}
                </MDTypography>
                <MDTypography variant="subtitle2">
                  <MDTypography variant="button" fontWeight="medium" fontSize={16} sx={{ mr: 0.5 }}>
                    {rating || 0}
                  </MDTypography>
                  Rating
                </MDTypography>
              </Stack>
            )}
          </Stack>
        </Stack>
        {isUserFullname && (
          <Stack
            sx={{
              '& button > svg': {
                fontSize: '24px !important',
              },
              '& button': {
                background: theme => alpha(theme.palette.info.main, 0.95),
              },
            }}
            direction="row"
            spacing={1}
            alignItems="flex-start"
            justifyContent="center"
          >
            {isFeedView && !consultationData?.isCaseOnConsultation && (
              <Tooltip title={sendConsultationButtonTooltipTitle}>
                <MDBox>
                  <MDButton
                    disabled={isSendConsultationOfferButtonDisabled || isDisableActions || isSendingOffer}
                    sx={{ textTransform: 'capitalize' }}
                    color="info"
                    onClick={openSendConsultationOfferDialogHandler}
                    isLoading={isSendingOffer}
                  >
                    Offer consultation
                  </MDButton>
                </MDBox>
              </Tooltip>
            )}

            {isAllowToExport && (
              <Tooltip title="Export case to CRM">
                {/* TODO conference hack */}
                <MDButton iconOnly color="info" onClick={onClickChooseCRMButtonHandler} disabled={isDisableActions}>
                  <FileUploadOutlined fontSize="medium" />
                </MDButton>
              </Tooltip>
            )}

            {isBackToQueueButton && (
              <Tooltip title={backToQueueButtonTooltipTitle}>
                <MDBox>
                  <MDButton
                    iconOnly
                    color="info"
                    disabled={isBackToQueueButtonDisabled || isDisableActions || isApproving}
                    onClick={openBackToQueueDialogHandler}
                    isLoading={isApproving}
                  >
                    <LowPriorityOutlined fontSize="medium" />
                  </MDButton>
                </MDBox>
              </Tooltip>
            )}

            {isCaseWithAttorney && !isInvitation && (
              <>
                <MDButton iconOnly color="info" onClick={onClickChatButtonHandler} disabled={isDisableActions}>
                  <ChatOutlined fontSize="medium" color={iconColor} />
                </MDButton>
                <MDButton
                  id="voice_call"
                  iconOnly
                  color="info"
                  onClick={onAudioVideoButtonClickHandler}
                  disabled={isDisableActions}
                >
                  <PhoneOutlined fontSize="medium" color={iconColor} />
                </MDButton>
                <MDButton
                  disabled={isDisableActions}
                  iconOnly
                  color="info"
                  id="video_call"
                  onClick={onAudioVideoButtonClickHandler}
                >
                  <VideocamOutlined fontSize="medium" color={iconColor} />
                </MDButton>
              </>
            )}
          </Stack>
        )}
      </Stack>

      {consultationData?.isCaseOnConsultation && isClient && representationId && (
        <RequestButtonsContent
          title="
            Attorney sent you a request for a representation of your case:"
          isApproving={isApprovingRepresentation}
          isRejecting={isRejecting}
          onApproveButtonClickHandler={onAcceptRepresentationOfferHandler}
          onDeclineButtonClickHandler={onDeclineRepresentationOfferHandler}
        />
      )}
      <DialogWindow
        onCloseDialogHandler={closeSendConsultationOfferDialogHandler}
        onApproveDialogHandler={onSendOfferConsultationButtonClickHandler}
        isDialogOpen={isSendConsultationOfferDialogOpen}
        dialogTitle="Are you sure want to offer consultation?"
      />
      <DialogWindow
        onCloseDialogHandler={closeBackToQueueDialogHandler}
        onApproveDialogHandler={onDeclineOfferConsultationButtonClickHandler}
        isDialogOpen={isBackToQueueDialogOpen}
        dialogTitle="Are you sure want to back to queue the legal case?"
      />

      <ChooseCRMToExportModalWindow
        onCloseDialogHandler={closeChooseCRMWindowHandler}
        isDialogOpen={isChooseCRMWindowOpen}
        onOptionClickHandler={onClickOnCRMOptionHandler}
      />

      <SideMenu
        isOpen={isExportCaseToClioMenuOpen}
        onClose={closeExportCaseToClioMenuHandler}
        title="Export to clio"
        customWidth={450}
      >
        <ExportCaseToClioMenuContent
          updateLegalCaseHandler={updateLegalCaseHandler}
          onCloseMenuHandler={closeExportCaseToClioMenuHandler}
          caseId={caseId}
        />
      </SideMenu>
    </Stack>
  );
};
