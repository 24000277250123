import { PropsWithChildren } from 'react';
import { Close } from '@mui/icons-material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import ConfiguratorRoot from './ConfiguratorRoot';

type SideMenuProps = PropsWithChildren & {
  isOpen: boolean;
  title: string;
  titleDescription?: string;
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
  customWidth?: number;
};
export const SideMenu = ({ onClose, isOpen, title, titleDescription, children, customWidth = 500 }: SideMenuProps) => {
  return (
    <ConfiguratorRoot
      anchor="right"
      open={isOpen}
      onClose={onClose}
      ownerState={{ openConfigurator: isOpen }}
      customWidth={customWidth}
    >
      <MDBox display="flex" flexDirection="column" pt={4} pb={0.5} px={2} flex={1}>
        <MDButton onClick={onClose} iconOnly sx={{ position: 'absolute', right: 30, top: 25 }}>
          <Close fontSize="large" sx={{ height: 30, width: 30 }} />
        </MDButton>
        <MDBox>
          <MDTypography variant="h5" sx={{ whiteSpace: 'initial', mr: 5 }}>
            {title}
          </MDTypography>
          {titleDescription && (
            <MDTypography
              variant="body2"
              color="text"
              sx={{
                wordBreak: 'break-all',
                whiteSpace: 'initial',
              }}
            >
              {titleDescription}
            </MDTypography>
          )}
        </MDBox>
        <>{children}</>
      </MDBox>
    </ConfiguratorRoot>
  );
};
