import { CreateBaseUserProfileSchema, PASSWORD_VALIDATION_HELPER_TEXT, passwordRegExp } from 'features/auth';
import { ObjectSchema } from 'yup';
import * as yup from 'yup';

export type UserProfileSchema = CreateBaseUserProfileSchema;

export type AttorneyProfileSchema = {
  barNumber?: string;
  description?: string;
};
export type ChangePasswordSchema = {
  newPassword: string;
  passwordConfirmation: string;
  currentPassword: string;
};

export type CreatePinCodeSchema = {
  pinCode: string;
  pinCodeConfirmation: string;
};

const PIN_CODE_VALIDATION_MESSAGE = 'PIN code must be 4 digits long';

export const editUserProfileFormSchema: ObjectSchema<UserProfileSchema> = yup
  .object({
    personTitle: yup.string().label('Person title'),
    firstName: yup.string().required().label('First Name'),
    lastName: yup.string().required().label('Last Name'),
    email: yup.string().label('Email'),
    country: yup.string().required().label('Country'),
    state: yup.string().required().label('State'),
    town: yup.string().required().label('Town/City').trim(),
    phone: yup.string().required().label('Phone Number'),
  })
  .required();

export const editAttorneyProfileFormSchema: ObjectSchema<AttorneyProfileSchema> = yup
  .object({
    barNumber: yup.string().label('Bar number'),
    description: yup.string().optional().label('Description'),
  })
  .required();

export const changePasswordFormSchema: ObjectSchema<ChangePasswordSchema> = yup
  .object({
    currentPassword: yup.string().required('Current password field is required'),
    newPassword: yup
      .string()
      .required('New password field is required')
      .matches(passwordRegExp, PASSWORD_VALIDATION_HELPER_TEXT),
    passwordConfirmation: yup
      .string()
      .required('Password confirmation field is required')
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  })
  .required();

export const createPinCodeValidationFormSchema: ObjectSchema<CreatePinCodeSchema> = yup
  .object({
    pinCode: yup
      .string()
      .label('PIN code')
      .required(PIN_CODE_VALIDATION_MESSAGE)
      .max(4, PIN_CODE_VALIDATION_MESSAGE)
      .min(4, PIN_CODE_VALIDATION_MESSAGE)
      .trim(),
    pinCodeConfirmation: yup
      .string()
      .required(PIN_CODE_VALIDATION_MESSAGE)
      .trim()
      .oneOf([yup.ref('pinCode'), null], `PINs don't match`),
  })
  .required();

export type AttorneyProfileWorkingHoursSchema = {
  isMonday: boolean;
  fromTimeMonday?: string;
  toTimeMonday?: string;

  isTuesday: boolean;
  fromTimeTuesday?: string;
  toTimeTuesday?: string;

  isWednesday: boolean;
  fromTimeWednesday?: string;
  toTimeWednesday?: string;

  isThursday: boolean;
  fromTimeThursday?: string;
  toTimeThursday?: string;

  isFriday: boolean;
  fromTimeFriday?: string;
  toTimeFriday?: string;

  isSaturday: boolean;
  fromTimeSaturday?: string;
  toTimeSaturday?: string;

  isSunday: boolean;
  fromTimeSunday?: string;
  toTimeSunday?: string;
};
