import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'features/common/variables/routes';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

type RedirectButtonProps = {
  routeTo: ROUTES.signIn | ROUTES.signUp;
  buttonText?: string;
  questionTitle?: string;
};

export const RedirectButton: FC<RedirectButtonProps> = ({
  questionTitle = 'Already registered?',
  routeTo,
  buttonText = 'Sign in',
}) => {
  return (
    <MDBox mt={3} mb={1} textAlign="center">
      <MDTypography variant="button" color="text">
        {questionTitle}&nbsp;
        <MDTypography component={Link} to={routeTo} variant="button" color="info" fontWeight="medium" textGradient>
          {buttonText}
        </MDTypography>
      </MDTypography>
    </MDBox>
  );
};
