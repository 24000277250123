import { FC } from 'react';
import MDBox from 'components/MDBox';
import { ReactComponent as ReactLogo } from 'assets/images/title.svg';
import { BoxProps } from '@mui/material';

const brandBlue = '/images/logo-lb-small-blue.svg';

type IllustrationLogoProps = Omit<BoxProps, 'color' | 'borderRadius'> & {};

export const IllustrationLogo: FC<IllustrationLogoProps> = ({ ...rest }) => {
  return (
    <MDBox
      position="absolute"
      {...rest}
      sx={{ color: ({ palette }) => palette.info.main, display: 'flex', alignItems: 'center' }}
    >
      <img
        src={brandBlue}
        alt="Legal Beagle logo"
        width="70px"
        height="90px"
        style={{ display: 'block', marginRight: '8px' }}
      />

      <ReactLogo style={{ width: '140px', height: '40px' }} />
    </MDBox>
  );
};
