import { Stack } from '@mui/material';
import MDTypography from 'components/MDTypography';
import React, { FC } from 'react';

type FieldWrapperProps = {
  children: React.ReactNode;
  title: string;
  description: string;
};
export const FieldWrapper: FC<FieldWrapperProps> = ({ children, description, title }) => {
  return (
    <Stack
      direction={{ sm: 'column', md: 'row' }}
      spacing={{ xs: 1.5, md: 1 }}
      justifyContent="space-between"
      alignItems={{ md: 'center', sm: 'flex-start' }}
    >
      <Stack flex={1}>
        <MDTypography variant="subtitle1" sx={{ fontWeight: '400' }}>
          {title}
        </MDTypography>
        <MDTypography variant="button">{description}</MDTypography>
      </Stack>
      {children}
    </Stack>
  );
};
