import { backendApiClient, CountryStateResponse, ListOfItems } from '../index';
import { CountriesResponse, County } from './types';

const BASE_STATES_URL: Readonly<string> = '/states';
const BASE_COUNTIES_URL: Readonly<string> = '/counties';
export type PaginationParamsWithOrder = {
  page?: number;
  name?: string;
  itemsPerPage?: number;
  orderingType?: 'name' | 'id';
  orderingDirection?: 'dsc' | 'asc';
};

export type CountiesPaginationParams = PaginationParamsWithOrder & {
  'state.isoCode'?: string;
};

export const createGeoDataService = () => {
  const apiClient = backendApiClient({});

  const getDefaultPaginationParams = (paginationParams?: PaginationParamsWithOrder) => ({
    ...(paginationParams.page ? { page: paginationParams.page } : {}),
    ...(paginationParams.name ? { name: paginationParams.name } : {}),
    ...{ itemsPerPage: paginationParams.itemsPerPage || 100 },
    ...(paginationParams.orderingDirection
      ? { [`order[${paginationParams.orderingType}]`]: paginationParams.orderingDirection }
      : {}),
  });

  const getCountries = async (paginationParams: PaginationParamsWithOrder) => {
    return await apiClient.get<CountriesResponse>('/countries', {
      params: {
        'order[name]': 'asc',
        ...getDefaultPaginationParams(paginationParams),
      },
    });
  };

  const getStatesByCountryId = async (countryId: string, paginationParams: PaginationParamsWithOrder) => {
    return await apiClient.get<CountryStateResponse>(BASE_STATES_URL, {
      params: {
        'order[name]': 'asc',
        ...getDefaultPaginationParams(paginationParams),
        'country.id%5B%5D': countryId,
      },
    });
  };

  const getStates = async (paginationParams: PaginationParamsWithOrder) => {
    return await apiClient.get<CountryStateResponse>(BASE_STATES_URL, {
      params: {
        'order[name]': 'asc',
        ...getDefaultPaginationParams(paginationParams),
      },
    });
  };

  const getCounties = async (paginationParams: CountiesPaginationParams) => {
    return await apiClient.get<ListOfItems<County[]>>(BASE_COUNTIES_URL, {
      params: {
        'order[name]': 'asc',
        ...getDefaultPaginationParams(paginationParams),
        'state.isoCode': paginationParams['state.isoCode'],
      },
    });
  };

  const getCountyById = async (id: string) => await apiClient.get<County>(`${BASE_COUNTIES_URL}/${id}`);

  const getCitiesByStateId = async (stateId: string, paginationParams: PaginationParamsWithOrder) => {
    return await apiClient.get('/cities', {
      params: {
        'order[name]': 'asc',
        'state.id': stateId,
        ...getDefaultPaginationParams(paginationParams),
      },
    });
  };

  return { getCountries, getCountyById, getStatesByCountryId, getCitiesByStateId, getStates, getCounties };
};
