import { FC } from 'react';
import { Stack } from '@mui/material';
import { ContentCopyRounded, EmailOutlined, LocalPhoneOutlined } from '@mui/icons-material';
import MDTypography from 'components/MDTypography';
import { BaseAvatar } from '../BaseAvatar';
import { UserProfileBoxProps } from './types';

export const UserProfileBox: FC<UserProfileBoxProps> = ({
  email,
  firstName,
  lastName,
  logoURL,
  onCopyHandler,
  phoneNumber,
}) => {
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <BaseAvatar size="lg" userFirstName={firstName} userLastName={lastName} src={logoURL} />
        <Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <MDTypography variant="button" fontSize="medium" fontWeight="medium">
              {firstName}
            </MDTypography>
            <ContentCopyRounded onClick={() => onCopyHandler(firstName)} fontSize="medium" sx={{ cursor: 'pointer' }} />
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1}>
            <MDTypography variant="button" fontSize="medium" fontWeight="medium">
              {lastName}
            </MDTypography>
            <ContentCopyRounded onClick={() => onCopyHandler(lastName)} fontSize="medium" sx={{ cursor: 'pointer' }} />
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={1}>
            <LocalPhoneOutlined />
            <MDTypography variant="button">Phone number</MDTypography>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1}>
            <MDTypography
              variant="button"
              sx={{
                fontWeight: 500,
              }}
            >
              {phoneNumber}
            </MDTypography>
            <ContentCopyRounded onClick={() => onCopyHandler(phoneNumber)} sx={{ cursor: 'pointer' }} />
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={1}>
            <EmailOutlined />
            <MDTypography variant="button">Email</MDTypography>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1}>
            <MDTypography
              variant="button"
              sx={{
                fontWeight: 500,
              }}
            >
              {email}
            </MDTypography>
            <ContentCopyRounded onClick={() => onCopyHandler(email)} sx={{ cursor: 'pointer' }} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
