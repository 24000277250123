import { User } from 'features/case-details/CaseDetailsApiService';
import { Pagination } from 'features/case-law-types';
import { BaseData, ListOfItems, PATCH_HEADERS, backendApiClient } from 'features/common';

export const BASE_USER_FEED_API_URL: Readonly<string> = '/user_feeds';

export type CreateMyLegalCasePayload = {
  description: string;
  caseLawType: string;
  country: string;
  state: string;
  county: string;
  caseNumber?: string;
  client?: string;
  invitation?: string;
  caseForDependant?: boolean;
  dependantStateOfResidence?: string;
  dependantFirstName?: string;
  dependantLastName?: string;
  dependantUnder18?: boolean;
  backToQueue?: boolean;
};

export type GetNotificationsParams = Pagination &
  Partial<{
    legalCase: string;
    'legalCase[]': string[];
  }>;

export type NotificationFeedType =
  | 'chat'
  | 'call_reminder'
  | 'case_status'
  | 'case_updates'
  | 'missed_call'
  | 'back_to_queue_request'
  | 'back_to_queue_confirm'
  | 'back_to_queue_decline'
  | 'agora_event_create'
  | 'agora_event_push_notification'
  | 'agora_event_status'
  | 'agora_event_updates'
  | 'agora_event_delete'
  | 'consultation_create'
  | 'consultation_approve'
  | 'consultation_decline'
  | 'consultation_delete_by_attorney'
  | 'consultation_delete_by_client'
  | 'file_sign_request'
  | 'file_signing'
  | 'representation_create'
  | 'representation_approve'
  | 'representation_decline'
  | 'back_to_queue'
  | 'clio_sync_error';

export type TransitionType = 'chat_transition' | 'case_transition' | 'call_transition' | 'without_transition';

export type FeedLegalCase = BaseData<'LegalCase'> & {
  id: string;
  caseNumber: string;
};

export type FeedNotification = BaseData<'UserFeed'> & {
  legalCase: FeedLegalCase | null;
  id: string;
  owner: User;
  type: NotificationFeedType;
  message: string;
  createdAt: string;
  viewedAt: null | string;
  opponent: User | null;
  allowTransition: boolean;
  transitionType: TransitionType;
  legalCaseChat?: string;
};

export const notificationCenterApiService = () => {
  const getNotifications = async (params?: GetNotificationsParams) =>
    await backendApiClient({}).get<ListOfItems<FeedNotification[]>>(BASE_USER_FEED_API_URL, {
      params,
    });

  const deleteNotification = async (id: string) => await backendApiClient({}).delete(`${BASE_USER_FEED_API_URL}/${id}`);

  const patchNotification = async (id: string, viewedAt: string) => {
    return await backendApiClient({
      headers: PATCH_HEADERS,
    }).patch<FeedNotification>(`${BASE_USER_FEED_API_URL}/${id}/viewed`, {
      viewedAt,
    });
  };

  const readAllNotification = async () => {
    return await backendApiClient({}).post(`${BASE_USER_FEED_API_URL}/view_all`, {});
  };

  return {
    patchNotification,
    deleteNotification,
    getNotifications,
    readAllNotification,
  };
};

export const ITEMS_PER_PAGE = 10;
