import { Visibility } from '@mui/icons-material';
import { CircularProgress, Tooltip } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { TablePaginationFooter } from 'features/common/components/TablePaginationFooter';
import DataTable from 'features/content-items/components/CustomPaginationTable';
import { formatDate } from 'features/common/helpers/date';
import { CompaniesTableProps } from './CompanyListTable';

export const CompanyWaitingListTable: React.FC<CompaniesTableProps> = ({
  onMoreCompanyInfo,
  companiesList,
  entriesPerPage,
  isLoading,
  onChangePage,
  totalItems,
  currentPage,
  onChangeEntriesPerPage,
}) => {
  return (
    <MDBox minWidth="300px" minHeight="300px" justifyItems="center" alignItems="center" display="flex">
      {isLoading ? (
        <CircularProgress sx={{ mx: 'auto', mb: '16px' }} />
      ) : companiesList.length > 0 ? (
        <MDBox width="100%">
          <DataTable
            entriesPerPage={{ defaultValue: entriesPerPage }}
            onChangeEntriesPerPage={onChangeEntriesPerPage}
            isSorted={false}
            table={{
              columns: [
                { Header: 'Full name', accessor: 'fullName', align: 'left' },
                { Header: 'Owner', accessor: 'owner', align: 'left' },
                { Header: 'Email', accessor: 'email', align: 'left' },
                { Header: 'Phone', accessor: 'phone', align: 'left' },
                { Header: 'Creation date', accessor: 'createdAt', align: 'left' },
                { Header: 'Last Update', accessor: 'updatedAt', align: 'left' },
                { Header: '', accessor: 'actionBtns' },
              ],
              rows: companiesList.map(company => ({
                id: company['@id'],
                fullName: (
                  <MDTypography noWrap={true} fontSize={14} maxWidth="350px" minWidth="100px">
                    {company.fullName}
                  </MDTypography>
                ),
                owner: (
                  <MDTypography noWrap={true} fontSize={14} maxWidth="350px" minWidth="100px">
                    {`${company.owner.userProfile.firstName} ${company.owner.userProfile.lastName}`}
                  </MDTypography>
                ),
                email: (
                  <MDTypography noWrap={true} fontSize={14} maxWidth="350px" minWidth="100px">
                    {company.email}
                  </MDTypography>
                ),
                phone: (
                  <MDTypography noWrap={true} fontSize={14} maxWidth="350px" minWidth="100px">
                    {company.phone}
                  </MDTypography>
                ),
                createdAt: company.createdAt ? formatDate(company.createdAt) : '-',
                updatedAt: company.updatedAt ? formatDate(company.updatedAt) : '-',
                actionBtns: (
                  <MDBox sx={{ display: 'flex', gap: '5px', width: '100%', justifyContent: 'flex-start' }}>
                    <Tooltip title="View company info">
                      <MDButton
                        variant="outlined"
                        color="secondary"
                        iconOnly
                        onClick={e => {
                          onMoreCompanyInfo(company['@id'].replace('/companies/', ''));
                        }}
                      >
                        <Visibility />
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                ),
              })),
            }}
          />
          <TablePaginationFooter
            page={currentPage}
            currentItemsPerPage={entriesPerPage}
            totalItems={totalItems}
            onChangePage={onChangePage}
          />
        </MDBox>
      ) : (
        <MDTypography sx={{ mx: 'auto' }}>No unapproved companies were found...</MDTypography>
      )}
    </MDBox>
  );
};
