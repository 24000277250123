import { useCallback } from 'react';
import { getSignDataFromCookies, setSignDataToCookies } from '../cookies';

export const useCookiesFileSignData = () => {
  const signData = getSignDataFromCookies();

  const setSignDataToCookiesHandler = useCallback(
    ({ fileId, path }: { fileId: string; path: string }) => {
      const signDataItemIndex = signData?.findIndex(el => el.fileId === fileId);
      if (signDataItemIndex !== -1) {
        signData[signDataItemIndex] = { fileId, path };
      } else {
        signData.push({ fileId, path });
      }

      setSignDataToCookies(signData, { expires: 24 });
    },
    [signData]
  );

  const removeSignDataItem = useCallback(
    ({ fileId }: { fileId: string }): null | string => {
      const signDataItem = signData?.find(el => el.fileId === fileId);

      if (signDataItem) {
        const filteredSignData = signData.filter(el => el.fileId !== signDataItem.fileId);

        setSignDataToCookies(filteredSignData, { expires: 24 });

        return signDataItem.path;
      } else {
        return null;
      }
    },
    [signData]
  );

  return {
    setSignDataToCookiesHandler,
    removeSignDataItem,
  };
};
