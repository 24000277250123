import { FC, useEffect, useState } from 'react';
import { Dialog, DialogTitle, Stack, TextField } from '@mui/material';
import { shallow } from 'zustand/shallow';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { useAudioVideoChat, useUserReviews } from 'features/store';
import { useRouter, useUser } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';
import { RATING_VALUES } from './modal.config';

const NOT_ALLOWED_ROUTES = [ROUTES.signIn, ROUTES.signUp, ROUTES.lockScreen, ROUTES.videoRoom, ROUTES.audioRoom];

export const ReviewModal: FC = () => {
  const {
    isReviewModalOpen,
    setIsReviewModalOpen,
    setReviewedUserFirebaseId,
    reviewedUserFirebaseId,
    createUserReviewHandler,
  } = useUserReviews(
    state => ({
      setReviewedUserFirebaseId: state.setReviewedUserFirebaseId,
      reviewedUserFirebaseId: state.reviewedUserFirebaseId,
      createUserReviewHandler: state.createUserReviewHandler,
      setIsReviewModalOpen: state.setIsReviewModalOpen,
      isReviewModalOpen: state.isReviewModalOpen,
    }),
    shallow
  );

  const { representationId, caseId } = useAudioVideoChat(state => ({
    representationId: state.representationId,
    caseId: state.caseId,
  }));
  const { isAttorney, isClient } = useUser();
  const { pathname } = useRouter();

  const dialogTitle = isAttorney ? 'Rate a client' : 'Rate a lawyer';

  const [rating, setRating] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState('');

  const onClickRatingButtonHandler = (val: number) => () => setRating(val);

  const onClickRateButtonHandler = async () => {
    setIsLoading(true);
    await createUserReviewHandler({
      reviewedUser: `/users/${reviewedUserFirebaseId}`,
      rating,
      ...(description && {
        body: description,
      }),
    });
    resetState();
    setIsReviewModalOpen(false);
    setIsLoading(false);
  };

  const resetState = () => {
    setRating(null);
    description && setDescription('');
  };

  const onClickSkipButtonHandler = () => {
    setReviewedUserFirebaseId('');
    resetState();
    setIsReviewModalOpen(false);
  };

  const onChangeDescriptionFieldHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setDescription(event.target.value);

  useEffect(() => {
    if (NOT_ALLOWED_ROUTES.includes(pathname as ROUTES)) return;

    if (isAttorney && !!reviewedUserFirebaseId) setIsReviewModalOpen(true);

    if (isClient && !!reviewedUserFirebaseId && !representationId && !caseId) setIsReviewModalOpen(true);
  }, [reviewedUserFirebaseId, pathname]);

  useEffect(() => {
    return () => {
      if (isReviewModalOpen) setIsReviewModalOpen(false);
    };
  }, [pathname, isReviewModalOpen]);

  return (
    <Dialog open={isReviewModalOpen} onClose={onClickSkipButtonHandler}>
      <DialogTitle sx={{ textAlign: 'center' }}>{dialogTitle}</DialogTitle>
      <Stack sx={{ width: 1, height: 1, p: 2 }} spacing={3}>
        <Stack spacing={1}>
          <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="space-around" spacing={1}>
            {RATING_VALUES.map(val => (
              <MDButton
                sx={{ fontSize: 14, mt: '4px !important' }}
                key={val}
                variant="gradient"
                size="small"
                color={rating === val ? 'success' : 'info'}
                onClick={onClickRatingButtonHandler(val)}
              >
                {val}
              </MDButton>
            ))}
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <MDTypography variant="button">Not satisfied</MDTypography>
            <MDTypography variant="button">Very satisfied</MDTypography>
          </Stack>
        </Stack>

        <TextField
          onChange={onChangeDescriptionFieldHandler}
          value={description}
          label="Description (Optional)"
          multiline
          rows={4}
        />
        <Stack direction="row" spacing={2} sx={{ width: 1, justifyContent: 'center', alignItems: 'center' }}>
          <MDButton onClick={onClickSkipButtonHandler} variant="outlined" color="dark">
            Skip
          </MDButton>
          <MDButton
            disabled={!rating || isLoading}
            isLoading={isLoading}
            onClick={onClickRateButtonHandler}
            variant="outlined"
            color="info"
          >
            Rate
          </MDButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};
