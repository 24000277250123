import { BaseData, ListOfItems, backendApiClient } from '../../common';

export type CreateContentItemPayload = {
  name: string;
  htmlContent: string;
  category: string;
};

type PatchContentItemPayload = CreateContentItemPayload;

export type CreateContentItemResponse = {
  '@id': string;
  '@type': string;
  '@context': string;
  name: string;
  htmlContent: string;
  createdAt: string;
  updatedAt: string;
  category: string;
};

export type GetContentItemStaticResponse = {
  '@context': string;
  '@id': string;
  '@type': string;
  htmlContent: string;
  category: string;
};

export type GetContentItemByIdResponse = CreateContentItemResponse;
export type GetContentItemRequest = {
  page?: number;
  itemsPerPage?: number;
  category?: string;
};
export type ContentItemResponse = {
  '@id': string;
  '@type': string;
  name: string;
  category: string;
  createdAt: string;
  updatedAt: string;
};

export type GetContentItemsResponse = {
  'hydra:member': ContentItemResponse[];
  'hydra:totalItems': number;
};

export type GetContentItemCatagoryBySlugResponse = BaseData<'ContentItem'> & {
  name: string;
  htmlContent: string;
};

export type ContentItemCategorySlug = 'firm_profile';

export const createContentItemService = () => {
  const SERVICE_URL = '/content_items';

  const getContentItemCategoriesBySlug = async (slug: ContentItemCategorySlug = 'firm_profile') =>
    await backendApiClient({}).get<ListOfItems<GetContentItemCatagoryBySlugResponse[]>>(
      `/content_item_categories/${slug}${SERVICE_URL}`,
      {
        params: { page: 1, itemsPerPage: 5 },
      }
    );

  const getContentItems = async (params: GetContentItemRequest) => {
    return await backendApiClient({}).get<GetContentItemsResponse>(SERVICE_URL, { params });
  };

  const getContentItemById = async (id: string) => {
    return await backendApiClient({}).get<GetContentItemByIdResponse>(`${SERVICE_URL}/${id.replace(SERVICE_URL, '')}`);
  };

  const getContentItemStaticById = async (id: string) => {
    return await backendApiClient({}).get<GetContentItemStaticResponse>(
      `${SERVICE_URL}/static${id.replace(SERVICE_URL, '')}`
    );
  };

  const createContentItem = async (createContentItemPayload: CreateContentItemPayload) => {
    return await backendApiClient({}).post<CreateContentItemResponse, CreateContentItemPayload>(
      SERVICE_URL,
      createContentItemPayload
    );
  };

  const patchContentItemById = async (id: string, patchPagePayload: PatchContentItemPayload) => {
    return await backendApiClient({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch<GetContentItemsResponse>(`${SERVICE_URL}/${id.replace(SERVICE_URL, '')}`, patchPagePayload);
  };

  const deleteContentItemById = async (id: string) => {
    return await backendApiClient({}).delete(`${SERVICE_URL}${id.replace(SERVICE_URL, '')}`);
  };

  return {
    createContentItem,
    getContentItemStaticById,
    getContentItems,
    getContentItemById,
    patchContentItemById,
    deleteContentItemById,
    getContentItemCategoriesBySlug,
  };
};
