import { FC } from 'react';
import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { HighlightOffOutlined } from '@mui/icons-material';
import { ROUTES } from 'features/common/variables/routes';
import { useRouter } from 'features/common';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

export const CancelPaymentTemplate: FC = () => {
  const { navigate } = useRouter();
  const onClickButtonHandler = () => navigate(ROUTES.myLegalCases);

  return (
    <MDBox
      component="section"
      sx={{
        width: 1,
        height: '100vh',
        p: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card sx={{ minWidth: 300 }}>
        <CardHeader
          sx={{ textAlign: 'center' }}
          title={
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
              <MDTypography variant="h5" fontWeight={600} textAlign="center">
                Your payment was canceled!
              </MDTypography>
              <HighlightOffOutlined
                sx={{
                  fontSize: '2.5rem !important',
                }}
                color="error"
              />
            </Stack>
          }
        />
        <CardContent>
          <Stack alignItems="center" justifyContent="center" spacing={3}>
            <MDButton variant="gradient" color="info" onClick={onClickButtonHandler}>
              Confirm
            </MDButton>
          </Stack>
        </CardContent>
      </Card>
    </MDBox>
  );
};
