import { createRef, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { useIsOnScreen } from 'features/common/hooks/useIsOnScreen';
import { useMyLegalCases } from 'features/store';
import { useRouter } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';
import { ITEMS_PER_PAGE } from '../MyLegalCasesApiService';

export const useMyLegalCasesState = () => {
  const [tabValue, setTabValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const route = useRouter();

  const handleSetTabValue = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setTabValue(newValue);
    setCurrentPage(1);
  };

  const onAddIconClikcHandler = () =>
    route.navigate(ROUTES.createLegalCase, {
      state: {
        test: true,
      },
    });

  const {
    fetchMyLegalCasesHandler,
    myLegalCases,
    isLoading,
    totalItems,
    isFetchMoreLoading,
    fetchMoreLegalCasesHandler,
  } = useMyLegalCases(
    state => ({
      myLegalCases: state.myLegalCases,
      fetchMyLegalCasesHandler: state.fetchMyLegalCasesHandler,
      isLoading: state.isLoading,
      totalItems: state.totalItems,
      isFetchMoreLoading: state.isFetchMoreLoading,
      fetchMoreLegalCasesHandler: state.fetchMoreLegalCasesHandler,
    }),
    shallow
  );

  const lastElementRef = createRef<HTMLDivElement | null>();
  const isOnScreen = useIsOnScreen(isLoading ? null : lastElementRef);

  const pages: Readonly<number> = Math.ceil(totalItems / ITEMS_PER_PAGE);

  return {
    isOnScreen,
    fetchMoreLegalCasesHandler,
    fetchMyLegalCasesHandler,
    isLoading,
    isFetchMoreLoading,
    tabValue,
    handleSetTabValue,
    myLegalCases,
    currentPage,
    pages,
    setCurrentPage,
    lastElementRef,
    onAddIconClikcHandler,
  };
};
