import { FC } from 'react';
import { Add } from '@mui/icons-material';
import { Fab, Stack } from '@mui/material';
import MDTypography from 'components/MDTypography';

type TableHeaderProps = {
  title: string;
  subtitle?: string;
  onButtonClickHandler?: () => void;
};

export const TableHeader: FC<TableHeaderProps> = ({ onButtonClickHandler, subtitle, title }) => {
  return (
    <Stack direction="row" justifyContent="space-between" p={3} lineHeight={1} pb={0}>
      <Stack flex={1}>
        <MDTypography variant="h5" fontWeight="medium">
          {title}
        </MDTypography>
        {subtitle && (
          <MDTypography variant="button" color="text">
            {subtitle}
          </MDTypography>
        )}
      </Stack>

      {onButtonClickHandler && (
        <Fab color="info" aria-label="add" size="medium" onClick={onButtonClickHandler}>
          <Add sx={{ width: 25, height: 25 }} />
        </Fab>
      )}
    </Stack>
  );
};
