import { notice, ToastType, useAsync } from '../../common';
import { useEffect } from 'react';
import { CreateContentItemPayload, createContentItemService, GetContentItemByIdResponse } from '../services';

export const useEditContentItem = (selectedContentItemId: string) => {
  const { getContentItemById, patchContentItemById } = createContentItemService();
  const [loadContentItemState, loadContentItemHandler] = useAsync(async () => {
    if (!selectedContentItemId) {
      return;
    }

    try {
      const { data } = await getContentItemById(selectedContentItemId);

      return data;
    } catch (error) {
      notice(ToastType.ERROR, 'Something went wrong during content item loading!');
      console.error(error);
    }
  });

  const [patchContentItemState, patchContentItemHandler] = useAsync(
    async (patchContentItemPayload: CreateContentItemPayload) => {
      if (contentItemData) {
        return await patchContentItemById(selectedContentItemId, patchContentItemPayload);
      }
    }
  );

  useEffect(() => {
    if (selectedContentItemId) {
      loadContentItemHandler();
    }
  }, [selectedContentItemId]);

  const isLoading = loadContentItemState.isPending() || patchContentItemState.isPending();
  const contentItemData: GetContentItemByIdResponse | null =
    (loadContentItemState.isSuccessful() && loadContentItemState.data) || null;

  const isFailed =
    loadContentItemState.isFailed() || (loadContentItemState.isSuccessful() && !loadContentItemState.data);

  return {
    isFailed,
    isLoading,
    contentItemData,
    selectedContentItemId,
    patchContentItemHandler,
  };
};
