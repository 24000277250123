export const FSW_SCOPE = '/firebase-push-notification-scope';

export const isSWExist = 'serviceWorker' in navigator && typeof window.navigator.serviceWorker !== 'undefined';

export const isSupportedNotificationApi = () =>
  'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

export const getSWRegistration = async () => await window.navigator.serviceWorker.getRegistration(FSW_SCOPE);

export const unregisterSW = async () => {
  if (isSWExist) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      for (const registration of registrations) {
        if (registration.scope.includes(FSW_SCOPE)) registration.unregister();
      }
    });
  }
};
