import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Stack } from '@mui/material';
import { BaseFormField, ToastType, notice } from 'features/common';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import { useEditFAQ } from 'features/faq/hooks/useEditFAQ';
import { CreateFAQFormSchema, createFAQFormSchema } from 'features/faq/templates/formSchema';
import { baseBackendErrorHandler } from 'features/common/errorHanders';
import { StyledForm } from './EditFAQItemForm.styles';

type EditFAQItemFormProps = {
  faqItemId: string;
  closeSideBarHandler: () => void;
  loadQuestionsHandler?: () => Promise<void>;
};

export const EditFAQItemForm: FC<EditFAQItemFormProps> = ({ faqItemId, loadQuestionsHandler, closeSideBarHandler }) => {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors, isValid },
    setError,
    watch,
    setValue,
  } = useForm<CreateFAQFormSchema>({
    resolver: yupResolver(createFAQFormSchema),
  });
  const { questionData, patchQuestionHandler, isLoading, patchQuestionState } = useEditFAQ(faqItemId);

  const onSubmitFormHandler = async (data: CreateFAQFormSchema) => {
    try {
      await patchQuestionHandler({
        question: data.question,
        answer: data.answer,
      });
      closeSideBarHandler();
      await loadQuestionsHandler();
      notice(ToastType.SUCCESS, 'Successfully edited!');
    } catch (error) {
      console.error(error);
      baseBackendErrorHandler(error, { formError: { formData: data, setError } });
    }
  };

  const initializeContentItemData = () => {
    if (questionData) {
      setValue('answer', questionData.answer);
      setValue('question', questionData.question);
    }
  };

  useEffect(() => {
    initializeContentItemData();
  }, [questionData]);

  const answerValue = watch('answer');
  const questionValue = watch('question');
  const isButtonDisabled =
    !isValid || (answerValue === questionData?.answer && questionValue === questionData?.question) || isSubmitting;

  return (
    <MDBox py={4} display="flex" flexDirection="column" height={1} justifyContent="center" alignItems="center">
      {isLoading ? (
        <CircularProgress />
      ) : (
        <StyledForm onSubmit={handleSubmit(onSubmitFormHandler)}>
          <Stack
            spacing={1}
            sx={{
              width: 1,
              flex: 1,
            }}
          >
            <BaseFormField
              formInputProps={{
                ...register('question'),
                type: 'text',
                label: 'Question',
                fullWidth: true,
                color: 'white',
                InputLabelProps: { shrink: true },
              }}
              errorValue={errors['question']?.message}
            />
            <BaseFormField
              formInputProps={{
                ...register('answer'),
                type: 'text',
                multiline: true,
                label: 'Answer',
                fullWidth: true,
                color: 'white',
                InputLabelProps: { shrink: true },
              }}
              errorValue={errors['answer']?.message}
            />
          </Stack>
          <MDButton
            sx={{ width: 1 }}
            disabled={isButtonDisabled}
            isLoading={patchQuestionState.isPending()}
            variant="gradient"
            color="info"
            type="submit"
          >
            Save
          </MDButton>
        </StyledForm>
      )}
    </MDBox>
  );
};
