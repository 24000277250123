export const baseEditorStyles: Readonly<React.CSSProperties> = {
  background: 'white',
  borderRadius: '6px',
  padding: '0 10px',
  minHeight: '270px',
  maxHeight: '500px',
  overflowY: 'auto',
};

export const toolbarConfig: Readonly<{
  options: string[];
}> = {
  options: ['inline', 'blockType', 'fontSize', 'link', 'textAlign', 'list', 'history'],
};
