import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Autocomplete, AutocompleteInputChangeReason, CircularProgress } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BaseFormField,
  Country,
  CountryState,
  createBackendUserService,
  IllustrationLayout,
  useAsync,
  useGeoData,
  useRouter,
} from 'features/common';
import { useDebounce } from 'features/common/hooks/useDebounce';
import { PERSONAL_TITLES } from 'features/common/mocks/values';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { BaseAutocompleteField } from 'features/common/components/BaseAutocompleteField';
import { getOptionById, isPhoneValid } from 'features/common/helpers/utilities';
import { baseBackendErrorHandler } from 'features/common/errorHanders';
import { AUTH_FLOW_STEPS, useAuth } from 'features/store';
import { ROUTES } from 'features/common/variables/routes';
import { CreateBaseUserProfileSchema, createBaseUserProfileSchema } from 'features/auth/formSchema';
import { UserWorkType } from 'features/auth/types';
import { firebaseAuth } from 'features/auth';
import { User } from 'firebase/auth';
import { AuthFlowBackgrounds } from 'features/auth/variables/illustartion';
import { IllustrationLogo } from 'features/common/components/IllustatrionLogo';
import { PhoneField } from 'features/common/components/PhoneField';

export const CreateUserProfileStep: FC = () => {
  const { resetAllStepsData, toStep, workType, invitedProfile, setStateData } = useAuth(state => ({
    setStateData: state.setStateData,
    invitedProfile: state.invitationUser,
    backendUser: state.backendUser,
    workType: state.workType,
    toStep: state.toStep,
    resetAllStepsData: state.resetAllStepsData,
  }));

  const router = useRouter();

  const [selectedTownName, setSelectedTownName] = useState('');

  const [reason, setReason] = useState<AutocompleteInputChangeReason | null>(null);

  const [firebaseUser, setFirebaseUser] = useState<User | null>(null);

  const [selectedPersonTitle, setSelectedPersonTitle] = useState('');

  const [selectedTown, setSelectedTown] = useState('');

  const { createBackedUser } = createBackendUserService();

  const firebaseUserDisplayNameArray = firebaseUser?.displayName?.split(' ');

  const {
    register,
    formState: { errors, isSubmitting },
    watch,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    control,
  } = useForm<CreateBaseUserProfileSchema>({
    resolver: yupResolver(createBaseUserProfileSchema),
    mode: 'onTouched',
    defaultValues: {
      personTitle: 'Mr.',
      phone: '',
      country: invitedProfile?.country || '',
      state: invitedProfile?.state || '',
      firstName: invitedProfile?.firstName || '',
      lastName: invitedProfile?.lastName || '',
      town: '',
    },
    values: {
      email: firebaseUser?.email || invitedProfile?.email || '',
      phone: firebaseUser?.phoneNumber || '',
      firstName: firebaseUserDisplayNameArray?.[0] || invitedProfile?.firstName || '',
      lastName: firebaseUserDisplayNameArray?.[1] || invitedProfile?.lastName || '',
    },
  });

  useEffect(() => {
    if (invitedProfile?.state && invitedProfile?.country) {
      onGetCountryListHandler();
      getStateListHandler(invitedProfile?.country);
      onCountryStateSelect(invitedProfile?.state);
    }
  }, []);

  const {
    onCountryStateSelect,
    onTownCityFieldInput,
    onGetCountryListHandler,
    getStateListHandler,
    isStateListLoading,
    isTownCityFieldInputLoading,
    onGetCountryListHandlerState,
    countryList,
    countryStateList,
    cityList,
    selectedStateId,
  } = useGeoData();

  const countryCurrentValue = watch('country');
  const stateCurrentValue = watch('state');

  const countryValue = getOptionById(countryCurrentValue, countryList);
  const stateValue = getOptionById(stateCurrentValue, countryStateList);

  const [createBaseUserProfileState, createBaseUserProfileSubmitHandler] = useAsync(
    async (formData: CreateBaseUserProfileSchema) => {
      try {
        const isValid = isPhoneValid(formData.phone);

        if (!isValid) {
          setError('phone', { message: 'Enter a valid phone number' });
          return;
        }
        const { data } = await createBackedUser({
          userProfile: {
            personTitle: formData.personTitle || undefined,
            firstName: formData.firstName,
            lastName: formData.lastName,
            country: formData.country,
            state: formData.state,
            town: formData.town,
          },
          phone: formData.phone,
          email: formData.email,
          userWorkType: workType,
        });

        setStateData('backendUser', data);
        if (workType === UserWorkType.CLIENT) {
          setStateData('isAuthorized', true);
          resetAllStepsData();
          router.navigate(ROUTES.myLegalCases);
        } else {
          toStep(AUTH_FLOW_STEPS.CREATE_ATTORNEY_PROFILE);
        }
      } catch (error) {
        baseBackendErrorHandler(error, { formError: { formData, setError } });
      }
    }
  );

  const isFormInvalid = !!Object.entries(errors).length;

  const onChangeTownInputValueHandler = async (
    _: SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setValue('town', value || '');
    setSelectedTownName(value || '');
    setReason(reason);
    errors.town?.message && clearErrors('town');

    if (reason === 'clear' && cityList?.length < 10) {
      setReason(reason);
      await onCountryStateSelect(selectedStateId);
    }

    if (reason === 'input' && !value && !cityList.length) {
      setReason(reason);
      await onCountryStateSelect(selectedStateId);
    }
  };

  const onChangeTownHandler = async (event: SyntheticEvent<Element, Event>, value: string) => {
    if (value) {
      setValue('town', value);
      setSelectedTown(value);
      errors.town?.message && clearErrors('town');
    }
  };

  const debouncedValue = useDebounce(selectedTownName, 250);

  useEffect(() => {
    const fetchCityList = async () => {
      await onTownCityFieldInput({ cityName: debouncedValue });
      setReason(null);
    };
    if (selectedTownName?.length >= 3 && reason === 'input') {
      fetchCityList();
    }
  }, [debouncedValue]);

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(firebaseUser => {
      if (firebaseUser) {
        setFirebaseUser(firebaseUser);
      }
    });
    return unsubscribe;
  }, []);

  const onClickBackButton = () =>
    toStep(workType === UserWorkType.CLIENT ? AUTH_FLOW_STEPS.INVITATION_CODE : AUTH_FLOW_STEPS.WORK_TYPE);

  return (
    <IllustrationLayout
      title="Tell us about yourself"
      description="Please fill in the following fields to complete your profile. This information will enable us to personalize your Legal Beagle journey and match you with the right legal connections."
      onClickBack={onClickBackButton}
      illustration={AuthFlowBackgrounds.AUTH_TWO}
      illustrationLogo={<IllustrationLogo top={10} left={20} />}
    >
      <form onSubmit={handleSubmit(createBaseUserProfileSubmitHandler)}>
        <MDBox
          display="flex"
          flexDirection="column"
          gap="8px"
          sx={{
            '&.MuiAutocomplete-popperDisablePortal, .MuiAutocomplete-popper': {
              display: !cityList.length ? 'none !important' : 'block !important',
            },
          }}
        >
          <Autocomplete
            value={selectedPersonTitle || null}
            getOptionLabel={option => option}
            isOptionEqualToValue={(option, value) => option === value}
            options={PERSONAL_TITLES}
            onChange={(_, value) => {
              setValue('personTitle', value || '');
              setSelectedPersonTitle(value || '');
            }}
            renderInput={params => (
              <BaseFormField
                formInputProps={{
                  ...params,
                  label: 'Title',
                  fullWidth: true,
                }}
                errorValue={errors['personTitle']?.message}
              />
            )}
          />
          <BaseFormField
            formInputProps={{
              ...register('firstName'),
              type: 'text',
              label: 'First name',
              fullWidth: true,
              InputLabelProps: { shrink: !!watch('firstName') },
            }}
            errorValue={errors['firstName']?.message}
          />
          <BaseFormField
            formInputProps={{
              ...register('lastName'),
              type: 'text',
              label: 'Last name',
              fullWidth: true,
              InputLabelProps: { shrink: !!watch('lastName') },
            }}
            errorValue={errors['lastName']?.message}
          />
          <BaseFormField
            formInputProps={{
              ...register('email'),
              type: 'text',
              label: 'Email',
              fullWidth: true,
              disabled: !!firebaseUser?.email,
              InputLabelProps: { shrink: !!firebaseUser?.email || !!watch('email') },
              autoComplete: 'new-email',
            }}
            errorValue={errors['email']?.message}
          />

          <Controller
            control={control}
            name="country"
            render={({ field: { onChange } }) => {
              const onChangeHandler = async (value: Country) => {
                onChange(value ? value['@id'] : '');
                value && (await getStateListHandler(value['@id']));
              };
              return (
                <BaseAutocompleteField
                  options={countryList}
                  getOptionLabel={option => option.name}
                  value={countryValue}
                  onChangeHandler={onChangeHandler}
                  errorMessage={errors['country']?.message}
                  isLoading={onGetCountryListHandlerState.isPending()}
                  autocompleSxProps={{
                    width: 1,
                    '.MuiInputBase-root': {
                      py: '4.5px',
                    },
                  }}
                  formInputProps={{
                    label: 'Country',
                  }}
                  placeholder="Select a country"
                />
              );
            }}
          />

          <Controller
            control={control}
            name="state"
            render={({ field: { onChange } }) => {
              const onChangeHandler = async (value: CountryState) => {
                onChange(value ? value['@id'] : '');
                setValue('town', '');
                setSelectedTownName('');
                setSelectedTown('');
                await onCountryStateSelect(value['@id']);
              };
              return (
                <BaseAutocompleteField
                  options={countryStateList}
                  getOptionLabel={option => option.name}
                  value={stateValue || null}
                  onChangeHandler={onChangeHandler}
                  errorMessage={errors['country']?.message}
                  isLoading={isStateListLoading}
                  autocompleSxProps={{
                    width: 1,
                    '.MuiInputBase-root': {
                      py: '4.5px',
                    },
                  }}
                  formInputProps={{
                    label: 'State',
                  }}
                  placeholder={!countryCurrentValue ? 'Select a country first' : 'Select a state'}
                />
              );
            }}
          />
          <Autocomplete
            value={selectedTown}
            isOptionEqualToValue={(option, value) => (option ? option === value : null)}
            disablePortal
            getOptionLabel={option => option}
            options={cityList.map(option => option.name)}
            {...register('town')}
            onChange={onChangeTownHandler}
            onInputChange={onChangeTownInputValueHandler}
            freeSolo
            disableClearable={false}
            loading={isTownCityFieldInputLoading}
            renderInput={params => (
              <BaseFormField
                formInputProps={{
                  ...params,
                  label: 'Town/City',
                  fullWidth: true,
                  InputLabelProps: { shrink: true },
                  InputProps: {
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isTownCityFieldInputLoading ? (
                          <MDBox pr={0} display="flex">
                            <CircularProgress color="inherit" size={20} />
                          </MDBox>
                        ) : null}
                        {errors['town']?.message ? null : params.InputProps.endAdornment}
                      </>
                    ),
                  },
                }}
                errorValue={errors['town']?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="phone"
            render={({ field }) => {
              const onChange = (value: string) => field.onChange(value);
              return (
                <PhoneField
                  disabled={!!firebaseUser?.phoneNumber}
                  {...field}
                  value={field.value}
                  onChange={onChange}
                  label="Phone number"
                  errorValue={errors['phone']?.message}
                />
              );
            }}
          />

          <MDBox mt={4} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              type="submit"
              fullWidth
              sx={{ textTransform: 'none' }}
              isLoading={isSubmitting}
              disabled={isFormInvalid || createBaseUserProfileState.isPending()}
            >
              Next
            </MDButton>
          </MDBox>
        </MDBox>
      </form>
    </IllustrationLayout>
  );
};
