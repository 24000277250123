import { FC } from 'react';
import { Card, Grid, Stack } from '@mui/material';

export const EmptyChat: FC = () => {
  return (
    <Grid item xs={12} sx={{ flex: 1 }}>
      <Card sx={{ flex: 1, height: 1 }}>
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          }}
        >
          Select a chat
        </Stack>
      </Card>
    </Grid>
  );
};
