export enum CONTENT_CATEGORY_SLUGS {
  HOME = 'home_page',
  FOOTER = 'footer',
  ATTORNEYS = 'attorneys',
  ENTERPRISE = 'enterprise',
  JOIN_US = 'join_us',
  ALL = 'all',
}

export const FOOTER_REQUIRED_CONTENT_ITEMS_NAMES = {
  [CONTENT_CATEGORY_SLUGS.FOOTER]: [
    'app_links',
    'connect_links',
    'copyright',
    'quick_links',
    'quick_links_title',
    'app_links_title',
    'connect_links_title',
    'contact_us_form',
  ],
};

export const JOIN_US_REQUIRED_CONTENT_ITEMS_NAMES = {
  [CONTENT_CATEGORY_SLUGS.JOIN_US]: ['join_us_title'],
};

export const HOME_PAGE_REQUIRED_CONTENT_ITEMS_NAMES = {
  [CONTENT_CATEGORY_SLUGS.HOME]: [
    'create_case_description',
    'explore_feature',
    'explore_features_title',
    'fully_remote',
    'fully_remote_title',
    'help_description',
    'how_it_works',
    'how_it_works_title',
    'signup_description',
    'subtitle',
    'title',
  ],
};

export const ATTORNEY_PAGE_REQUIRED_CONTENT_ITEMS_NAMES = {
  [CONTENT_CATEGORY_SLUGS.ATTORNEYS]: [
    'attorney_connect_clients_description',
    'attorney_esigning_description',
    'attorney_fully_remote_description',
    'attorney_subtitle',
    'attorney_title',
    'attorney_fully_remote_description',
    'attorney_esigning_title',
    'attorney_connect_clients_title',
  ],
};

export const ENTERPRISE_PAGE_REQUIRED_CONTENT_ITEMS_NAMES = {
  [CONTENT_CATEGORY_SLUGS.ENTERPRISE]: [
    'enterprise_about_section_customizable',
    'enterprise_about_section_powerful',
    'enterprise_about_section_secure',
    'enterprise_about_section_subtitle',
    'enterprise_about_section_support',
    'enterprise_law_firm_section_branded',
    'enterprise_feature_section_cross_platform_description',
    'enterprise_feature_section_feature_request_description',
    'enterprise_feature_section_feature_subtitle',
    'enterprise_feature_section_feature_title',
    'enterprise_law_firm_section_integr_and_features',
    'enterprise_feature_section_legal_integrations_description',
    'enterprise_feature_section_maintance_description',
    'enterprise_feature_section_pricing_description',
    'enterprise_law_firm_section_subtitle',
    'enterprise_feature_section_support_description',
    'enterprise_law_firm_section_title',
    'enterprise_subtitle',
    'enterprise_title',
    'enterprise_about_section_title',
  ],
};

export const ALL_REQUIRED_CONTENT_ITEMS_NAMES = {
  [CONTENT_CATEGORY_SLUGS.ALL]: [
    FOOTER_REQUIRED_CONTENT_ITEMS_NAMES,
    JOIN_US_REQUIRED_CONTENT_ITEMS_NAMES,
    HOME_PAGE_REQUIRED_CONTENT_ITEMS_NAMES,
    ATTORNEY_PAGE_REQUIRED_CONTENT_ITEMS_NAMES,
    ENTERPRISE_PAGE_REQUIRED_CONTENT_ITEMS_NAMES,
  ],
};

export const REQUIRED_CONTENT_ITEMS_NAMES: Partial<Record<CONTENT_CATEGORY_SLUGS, { [key: string]: string[] }>> = {
  attorneys: ATTORNEY_PAGE_REQUIRED_CONTENT_ITEMS_NAMES,
  enterprise: ENTERPRISE_PAGE_REQUIRED_CONTENT_ITEMS_NAMES,
  footer: FOOTER_REQUIRED_CONTENT_ITEMS_NAMES,
  home_page: HOME_PAGE_REQUIRED_CONTENT_ITEMS_NAMES,
  join_us: JOIN_US_REQUIRED_CONTENT_ITEMS_NAMES,
};

export const getRequiredContentItems = (items: { [key: string]: string[] }, itemNamesSet: Set<string>) =>
  Object.entries(items)?.reduce((acc, [slug, items]) => {
    const filteredItems = items?.filter(itemName => !itemNamesSet.has(itemName));
    if (filteredItems.length) {
      acc.push({ items: filteredItems });
    }
    return acc;
  }, [] as { slug?: string; items: string[] }[]);

export const getAllRequiredContentItems = (items: typeof ALL_REQUIRED_CONTENT_ITEMS_NAMES, itemNamesSet: Set<string>) =>
  Object.entries(items).reduce((acc, [_, items]) => {
    items.forEach(itemsObject => {
      const [slug, items] = Object.entries(itemsObject)[0];
      const filteredItems = items?.filter(itemName => !itemNamesSet.has(itemName));

      if (filteredItems.length) {
        acc.push({ slug, items: filteredItems });
      }
    });

    return acc;
  }, [] as { slug: string; items: string[] }[]);
