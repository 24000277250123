import { Pagination } from 'features/case-law-types';
import { GetMyLegalCasesResponse } from 'features/my-legal-cases/MyLegalCasesApiService';
import {
  BaseData,
  CaseLawType,
  ListOfItems,
  Logo,
  PATCH_HEADERS,
  UserProfileResponse,
  backendApiClient,
} from '../index';

const BASE_AGORA_EVENT_API_URL: Readonly<string> = '/agora_events';

export type AgoraAudioVideoIncomingCallData = {
  agoraEvent: string;
  callCancel: string;
  agoraEventType: AgoraEventType;
  callerFirebaseUuid: string;
  callerFirstName: string;
  callerLastName: string;
  callerLogo: string;
  isVisitorAttorney: string;
  startedAt: string;
  type: 'call_transition';
  visitorFirebaseUuid: string;
  visitorFirstName: string;
  visitorLastName: string;
  visitorLogo: string;
};

export type GetAgoraEventsParams = Pagination &
  Partial<{
    'startedAt[before]': string;
    'startedAt[strictly_before]': string;
    'startedAt[after]': string;
    'startedAt[strictly_after]': string;
    legalCase: string;
    'legalCase[]': string[];
  }>;

export type AgoraEventType = 'video_call' | 'voice_call';
export type CreateAgoraEventPayload = {
  user: string;
  type: AgoraEventType;
  startedAt?: string;
  legalCase: string;
  noteEnabled?: boolean;
  notificationEnabled?: boolean;
  notificationTime?: string | Date;
  note?: string;
};

export type AgoraEventLegalCase =
  | (Pick<GetMyLegalCasesResponse, '@id' | '@type' | 'caseNumber' | 'country' | 'county'> & {
      caseLawType: CaseLawType;
      onConsultation: boolean;
    })
  | null;
export type AgoraEvent = BaseData<'AgoraEvent'> &
  PatchAgoraEventPayload & {
    id: string;
    type: AgoraEventType;
    expiredAt: string;
    status: EventStatus;
    legalCase?: AgoraEventLegalCase;
    participants?: Pick<EventParticipant, 'role' | 'user' | '@type' | '@id'>[] | null;
  };

type EventParticipantRole = 'role_agora_attendee' | 'role_agora_publisher';

export type EventParticipant = BaseData<'AgoraEventParticipant'> & {
  participantUid: number;
  role: EventParticipantRole;
  token: string;
  user: BaseData<'User'> & {
    firebaseUser: string;
    logo: null | Logo;
    userProfile: Pick<UserProfileResponse, '@id' | '@type' | 'firstName' | 'lastName'>;
  };
};

export type CreateAgoraEventResponse = BaseData<'AgoraEvent'> &
  PatchAgoraEventPayload & {
    '@context': '/contexts/AgoraEvent';
    channelName: string;
    expiredAt: string;
    id: string;
    status: EventStatus;
    type: AgoraEventType;
    participants: EventParticipant[];
    legalCase: AgoraEventLegalCase;
  };

export type PatchAgoraEventPayload = Partial<{
  startedAt: string | Date;
  notificationEnabled: boolean;
  notificationTime: string;
  noteEnabled: boolean;
  note: string;
  time: string | Date;
  type: AgoraEventType;
}>;

export type EventStatus = 'created' | 'cancel' | 'in_progress' | 'finished' | 'expired' | 'missed';
export type CancelCallNotificationPayload = {
  status: EventStatus;
};

export type PatchAgoraEventStatusPayload = Partial<{
  status: EventStatus;
}>;

export type PushNotificationCallCancelPayload = {
  manualCallCancel?: boolean;
};

export const agoraEventsApiService = () => {
  const getAgoraEvents = async (params?: GetAgoraEventsParams) => {
    const { data } = await backendApiClient({}).get<ListOfItems<AgoraEvent[]>>(BASE_AGORA_EVENT_API_URL, { params });
    return data;
  };

  const getAgoraEvent = async (id: string) =>
    await backendApiClient({}).get<CreateAgoraEventResponse>(`${BASE_AGORA_EVENT_API_URL}/${id}`);

  const createAgoraEvent = async (payload: CreateAgoraEventPayload) =>
    await backendApiClient({}).post<CreateAgoraEventResponse>(BASE_AGORA_EVENT_API_URL, payload);

  const patchAgoraEvent = async (id: string, payload: PatchAgoraEventPayload) => {
    const { data } = await backendApiClient({
      headers: PATCH_HEADERS,
    }).patch<CreateAgoraEventResponse>(`${BASE_AGORA_EVENT_API_URL}/${id}`, payload);
    return data;
  };

  const sendAgoraEventDuration = async (id: string, duration: number) =>
    await backendApiClient({
      headers: PATCH_HEADERS,
    }).patch(`${BASE_AGORA_EVENT_API_URL}/${id}/duration`, { duration });

  const createNotification = async (eventId: string, params?: EventStatus) => {
    const { data } = await backendApiClient({}).post(`${BASE_AGORA_EVENT_API_URL}/${eventId}/push_notification`, {
      params,
    });
    return data;
  };

  const deleteAgoraEvent = async (eventId: string) => {
    const { data } = await backendApiClient({}).delete(`${BASE_AGORA_EVENT_API_URL}/${eventId}`);
    return data;
  };

  const patchAgoraEventStatus = async (id: string, payload: PatchAgoraEventStatusPayload) => {
    const { data } = await backendApiClient({
      headers: { 'Content-Type': 'application/merge-patch+json' },
    }).patch(`${BASE_AGORA_EVENT_API_URL}/${id}/status`, payload);
    return data;
  };

  const pushNotificationCallCancel = async (id: string, payload: PushNotificationCallCancelPayload) =>
    await backendApiClient({}).post(`${BASE_AGORA_EVENT_API_URL}/${id}/push_notification_call_cancel`, payload);

  const pushNotificationCallCancelAnonymous = async (id: string, deviceId: string) =>
    await backendApiClient({}).post(`${BASE_AGORA_EVENT_API_URL}/${id}/push_notification_call_cancel_anonymous`, {
      deviceId,
    });

  return {
    pushNotificationCallCancel,
    sendAgoraEventDuration,
    patchAgoraEventStatus,
    deleteAgoraEvent,
    createNotification,
    patchAgoraEvent,
    getAgoraEvent,
    getAgoraEvents,
    createAgoraEvent,
    pushNotificationCallCancelAnonymous,
  };
};
