import { FC } from 'react';
import { Stack, Tooltip } from '@mui/material';
import { ModeEdit, Clear, AccessTimeOutlined, MicNoneOutlined, VideocamOutlined } from '@mui/icons-material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { dateToCustomFormat } from 'features/common/helpers/date';
import { BaseAvatar } from 'features/common/components/BaseAvatar';
import { getIsEventTime } from '../../helpers';
import { ListOfEventsItemProps } from '../types';

export const ListOfEventsItem: FC<ListOfEventsItemProps> = ({
  firstName,
  lastName,
  logoURL,
  caseNumber,
  eventId,
  eventEndTime,
  eventStartTime,
  note,
  lastElementRef,
  eventType,
  isEventOwner,
  onDeleteEventIconClickHandler,
  onEditIconClickHandler,
  onClickConnectToCallButtonHandler,
}) => {
  const { isAfterEventEndTime, isEventTime } = getIsEventTime(eventStartTime, eventEndTime);
  const isEditDeleteButtonDisabled = isAfterEventEndTime || isEventTime;

  const onClickConnectToCallHandler = () => onClickConnectToCallButtonHandler(eventId, eventType);

  return (
    <MDBox
      ref={lastElementRef}
      borderRadius="xl"
      p={1.5}
      bgColor="white"
      sx={{
        position: 'relative',
        fontSize: ({ typography: { size } }) => size.md,
        boxShadow: theme => theme.boxShadows.md,
        border: theme => `1px solid ${theme.borders.borderColor}`,
      }}
    >
      <Stack spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <BaseAvatar src={logoURL} userFirstName={firstName} userLastName={lastName} size="md" />

          <Stack flex={1}>
            <MDTypography variant="subtitle1" fontWeight="medium" fontSize={18}>
              {`${firstName} ${lastName}`}
            </MDTypography>
            <Stack direction="row" spacing={1} alignItems="center">
              {eventType === 'video_call' ? (
                <VideocamOutlined sx={{ width: 20, height: 20 }} />
              ) : (
                <MicNoneOutlined sx={{ width: 20, height: 20 }} />
              )}
              <AccessTimeOutlined sx={{ width: 20, height: 20 }} />
              <MDTypography variant="button" fontSize={16} sx={{ lineHeight: 1 }}>
                {dateToCustomFormat(eventStartTime, 'hh:mm a')}
              </MDTypography>
            </Stack>
          </Stack>

          {isEventOwner && (
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Tooltip title="Edit event">
                <MDBox>
                  <MDButton
                    variant="outlined"
                    color="secondary"
                    size="small"
                    id={eventId}
                    disabled={isEditDeleteButtonDisabled}
                    iconOnly
                    onClick={onEditIconClickHandler}
                  >
                    <ModeEdit />
                  </MDButton>
                </MDBox>
              </Tooltip>
              <Tooltip title="Delete event">
                <MDBox>
                  <MDButton
                    color="secondary"
                    variant="outlined"
                    size="small"
                    iconOnly
                    disabled={isEditDeleteButtonDisabled}
                    id={eventId}
                    onClick={onDeleteEventIconClickHandler}
                  >
                    <Clear />
                  </MDButton>
                </MDBox>
              </Tooltip>
            </Stack>
          )}
        </Stack>
        <MDTypography variant="button" fontWeight="medium" sx={{ whiteSpace: 'initial', wordBreak: 'break-all' }}>
          {caseNumber}
        </MDTypography>

        <MDTypography variant="button" fontWeight="medium" sx={{ whiteSpace: 'initial', wordBreak: 'break-all' }}>
          {note}
        </MDTypography>
        {isEventTime && (
          <MDButton
            onClick={onClickConnectToCallHandler}
            variant="outlined"
            color="info"
            id={eventId}
            sx={{ textTransform: 'none', mt: '14px !important' }}
          >
            Connect to call
          </MDButton>
        )}
      </Stack>
    </MDBox>
  );
};
