import { AttorneyProfileWorkingHoursSchema } from 'features/profile/templates';

export type FormFieldName = keyof AttorneyProfileWorkingHoursSchema;

export const WORKING_HOURS_FORM_FIELDS: {
  switchTitle: string;
  watchSwitcherValue: FormFieldName;
  watchFromTimeInputValue: FormFieldName;
  watchToTimeInputValue: FormFieldName;
}[] = [
  {
    switchTitle: 'Monday',
    watchSwitcherValue: 'isMonday',
    watchFromTimeInputValue: 'fromTimeMonday',
    watchToTimeInputValue: 'toTimeMonday',
  },
  {
    switchTitle: 'Tuesday',
    watchSwitcherValue: 'isTuesday',
    watchFromTimeInputValue: 'fromTimeTuesday',
    watchToTimeInputValue: 'toTimeTuesday',
  },
  {
    switchTitle: 'Wednesday',
    watchSwitcherValue: 'isWednesday',
    watchFromTimeInputValue: 'fromTimeWednesday',
    watchToTimeInputValue: 'toTimeWednesday',
  },
  {
    switchTitle: 'Thursday',
    watchSwitcherValue: 'isThursday',
    watchFromTimeInputValue: 'fromTimeThursday',
    watchToTimeInputValue: 'toTimeThursday',
  },
  {
    switchTitle: 'Friday',
    watchSwitcherValue: 'isFriday',
    watchFromTimeInputValue: 'fromTimeFriday',
    watchToTimeInputValue: 'toTimeFriday',
  },
  {
    switchTitle: 'Saturday',
    watchSwitcherValue: 'isSaturday',
    watchFromTimeInputValue: 'fromTimeSaturday',
    watchToTimeInputValue: 'toTimeSaturday',
  },
  {
    switchTitle: 'Sunday',
    watchSwitcherValue: 'isSunday',
    watchFromTimeInputValue: 'fromTimeSunday',
    watchToTimeInputValue: 'toTimeSunday',
  },
];
