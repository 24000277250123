import { FC, useEffect } from 'react';
import { useUrgentVersions } from '../hooks/useUrgentVersions';
import { Card, Grid, Stack } from '@mui/material';
import MDBox from 'components/MDBox';
import { SideMenu } from 'features/common';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { UrgentVersionsTable } from '../Components/UrgentVersions/UrgentVersionsTable';
import { UrgentVersionCard } from '../Components/UrgentVersionCard';
import { OS } from '../UrgentVersionsApiService';
import { CreateVersionMenuContent } from '../Components/UrgentVersions/CreateVersionMenuContent';

import { ReactComponent as AndroidLogo } from 'assets/images/icons/android_icon.svg';
import { ReactComponent as IOSLogo } from 'assets/images/icons/ios_logo_icon.svg';
import { Loader } from 'features/common/components/Loader';

const CARD_LOGO_MAP: Partial<Record<OS, React.FunctionComponent>> = {
  ios: IOSLogo,
  android: AndroidLogo,
};

export const UrgentVersionsTemplate: FC = () => {
  const {
    isLoadingLastUrgentVersions,
    lastUrgentVersions,
    onGetCriticalUpdateVersionsHandler,
    onGetLastCriticalUpdateVersionsHandler,
    isLoadingUrgentVersions,
    currentPage,
    entriesPerPage,
    onChangeEntriesPerPageHandler,
    onChangePageHandler,
    totalItems,
    urgentVersions,
    closeCreateMenu,
    isCreateMenuOpen,
    openCreateMenu,
    setDataToStoreHandler,
    onClickDeleteIconHandler,
    onApproveDeleteUrgentVersionHandler,
    closeDeleteDialog,
    isDeleteDialogOpen,
    isDeleting,
  } = useUrgentVersions();

  useEffect(() => {
    onGetCriticalUpdateVersionsHandler();
  }, [entriesPerPage]);

  useEffect(() => {
    onGetLastCriticalUpdateVersionsHandler();
  }, []);

  return (
    <Stack spacing={2} width={1}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} width={1}>
        {isLoadingLastUrgentVersions ? (
          <Card sx={{ minHeight: 150, width: 1 }}>
            <Loader />
          </Card>
        ) : (
          lastUrgentVersions.map(el => (
            <Grid item xs={12} sm={6} key={el['@id']}>
              <UrgentVersionCard
                os={el.os}
                color="info"
                version={el.version || ''}
                icon={
                  <MDBox
                    component={CARD_LOGO_MAP[el.os]}
                    sx={{
                      width: 48,
                      height: 48,
                      fill: theme => theme.palette.common.white,
                    }}
                  />
                }
              />
            </Grid>
          ))
        )}
      </Stack>

      <UrgentVersionsTable
        data={urgentVersions}
        entriesPerPage={entriesPerPage}
        currentPage={currentPage}
        totalItems={totalItems}
        onChangePage={onChangePageHandler}
        isLoading={isLoadingUrgentVersions}
        onChangeEntriesPerPage={onChangeEntriesPerPageHandler}
        onDeleteIconClickHandler={onClickDeleteIconHandler}
        onAddButtonClickHandler={openCreateMenu}
      />

      <SideMenu customWidth={450} isOpen={isCreateMenuOpen} onClose={closeCreateMenu} title="Create urgent version">
        <CreateVersionMenuContent
          setDataToStoreHandler={setDataToStoreHandler}
          itemsPerPage={entriesPerPage}
          currentPage={currentPage}
          onCloseMenuHandler={closeCreateMenu}
        />
      </SideMenu>

      <DialogWindow
        onCloseDialogHandler={closeDeleteDialog}
        onApproveDialogHandler={onApproveDeleteUrgentVersionHandler}
        isDialogOpen={isDeleteDialogOpen}
        isLoading={isDeleting}
        dialogTitle="Are you sure you want to delete urgent version?"
      />
    </Stack>
  );
};
