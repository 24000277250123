import { useCallback, useMemo } from 'react';
import { BaseData, MercureLinkMap, useRouter, useUser } from 'features/common';
import { useCaseDetails } from 'features/store';
import { useCaseInfoState } from './useCaseInfoState';
import { useMercure } from 'features/common/hooks/useMercure';
import { debounce } from '@mui/material';

type EventMessage = BaseData<'FileSign' | 'LegalCaseNote' | 'File'> & {
  file?: string;
  id?: string;
  owner?:
    | null
    | (BaseData<'User'> & {
        firebaseUser: string;
      });
};

export const useCaseDetailsState = () => {
  const {
    getLegalCaseStatusesHandler,
    changeLegalCaseStatusHandler,
    getConsultationsListHandler,
    approveConsultationHandler,
    declineConsultationHandler,
    getMoreConsultationsHandler,
    approveRepresentationsHandler,
    declineRepresentationsHandler,
    declineAcceptedConsultationHandler,
    fetchLegalCaseHandler,
    updateLegalCase,
    uploadDocumentHandler,
    deleteDocumentHandler,
    fetchLegalCaseNotesHandler,
    fetchMoreMyLegalCaseNotesHandler,
    fetchMoreLegalCaseNotesHandler,
    updateMyLegalCaseNoteHandler,
    deleteMyLegalCaseNoteHandler,
    fetchMyLegalCaseNotesHandler,
    createMyLegaCaseNoteHandler,
    getMySelectedNote,
    caseStatuses,
    case: legalCase,
    consultations,
    representations,
    notes,
    myNotes,
    selectedNote,
    fetchLegalCaseDelayedNotificationsHandler,
    createLegaCaseDelayedNotificationHandler,
    delayedNotifications,
    getSelectedDelayedNotification,
    deleteLegalCaseDelayedNotificationHandler,
    updateLegalCaseDelayedNotificationHandler,
    fetchLegalCaseDocuments,
    getMyNoteById,
  } = useCaseDetails();
  const { isAttorney, backendUserFirebaseId } = useUser();
  const { params } = useRouter();

  const caseId = params?.caseId;

  const myLegalCase = legalCase?.myLegalCase;

  const {
    isCaseLoading,
    isCaseOffersLoading,
    isConsultationsListLoading,
    isEditFormMenuOpen,
    isStatusUpdating,
    isViewAllMenuOpen,
    openPopover,
    handleClosePopover,
    closeEditFormMenu,
    closeViewAllMenu,
    handleOpenPopover,
    onClickCaseStatusMenuItemHandler,
    onFetchCaseDetailsHandler,
    onViewAllButtonHandler,
    openEditFormMenu,
  } = useCaseInfoState({
    caseId,
    fetchLegalCaseHandler,
    isAttorney,
    getConsultationsListHandler,
    changeLegalCaseStatusHandler,
  });

  const representationId = representations?.representationList?.[0]?.id;
  const isViewAllConsultationsButton = consultations?.totalItems > 4;
  const isClosedCase = myLegalCase?.status?.type === 'done';
  const isFeedView = !myLegalCase?.attorney && isAttorney;
  const isAttorneyView = isAttorney && !!myLegalCase?.attorney;
  const isAllowToOpenCaseStatusesMenu = isAttorney && myLegalCase?.attorney && !myLegalCase?.onConsultation;

  const isDelayedNotificationCard = isAttorney && !myLegalCase?.onConsultation && !!myLegalCase.attorney;

  const sortedCaseStatuses = useMemo(
    () => [...caseStatuses]?.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase())),
    [caseStatuses]
  );

  const debouncedFetchMyLegalCaseNotes = useCallback(debounce(getMyNoteById, 1000), []);
  const debouncedFetchLegalCaseNotes = useCallback(debounce(getMyNoteById, 1000), []);
  const debouncedFetchLegalCaseDocuments = useCallback(debounce(fetchLegalCaseDocuments, 1000), []);

  const onReceiveEventMessageHandler = async (event: MessageEvent) => {
    const message: EventMessage = JSON.parse(event.data);
    if (message?.file) {
      await fetchLegalCaseDocuments();
    }

    if (message?.['@type'] === 'File') {
      debouncedFetchLegalCaseDocuments();
    }

    if (message?.['@type'] === 'LegalCaseNote') {
      backendUserFirebaseId === message?.owner?.firebaseUser
        ? await debouncedFetchMyLegalCaseNotes(message?.id, true)
        : await debouncedFetchLegalCaseNotes(message?.id);
    }
  };

  useMercure({
    mercureSubscribeURL: MercureLinkMap[process.env.REACT_APP_BASE_APP_URL || 'http://localhost:3000'],
    onReceiveEventHandler: onReceiveEventMessageHandler,
    eventId: caseId,
    topicSubURL: 'legal_cases',
  });

  return {
    consultations,
    notes,
    myNotes,
    selectedNote,
    createMyLegaCaseNoteHandler,
    getMySelectedNote,
    fetchMyLegalCaseNotesHandler,
    fetchLegalCaseNotesHandler,
    fetchMoreMyLegalCaseNotesHandler,
    fetchMoreLegalCaseNotesHandler,
    updateMyLegalCaseNoteHandler,
    deleteMyLegalCaseNoteHandler,
    declineAcceptedConsultationHandler,
    updateLegalCase,
    getLegalCaseStatusesHandler,
    approveRepresentationsHandler,
    declineRepresentationsHandler,
    getConsultationsListHandler,
    uploadDocumentHandler,
    deleteDocumentHandler,
    approveConsultationHandler,
    declineConsultationHandler,
    getMoreConsultationsHandler,
    representationId,
    caseId,
    isAttorney,
    isAttorneyView,
    isClosedCase,
    isFeedView,
    isAllowToOpenCaseStatusesMenu,
    isViewAllConsultationsButton,
    sortedCaseStatuses,
    myLegalCase,
    closeEditFormMenu,
    closeViewAllMenu,
    handleClosePopover,
    handleOpenPopover,
    isCaseLoading,
    isCaseOffersLoading,
    isConsultationsListLoading,
    isEditFormMenuOpen,
    isStatusUpdating,
    isViewAllMenuOpen,
    onClickCaseStatusMenuItemHandler,
    onFetchCaseDetailsHandler,
    onViewAllButtonHandler,
    openEditFormMenu,
    openPopover,
    isDelayedNotificationCard,
    fetchLegalCaseDelayedNotificationsHandler,
    createLegaCaseDelayedNotificationHandler,
    delayedNotifications,
    getSelectedDelayedNotification,
    deleteLegalCaseDelayedNotificationHandler,
    updateLegalCaseDelayedNotificationHandler,
    fetchLegalCaseDocuments,
  };
};
