import { FC } from 'react';
import { Stack } from '@mui/material';
import { CreateLegalCaseMessageResponse } from 'features/common/services/chatApiService';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import { Message } from '../Message';
import { DateLabel } from './DateLabel';
import { FileMessage } from '../Message/FileMessage';
import { ChatContentProps } from './types';
import { PromptMessage } from '../Message/PromptMessage';
import { getMessageText } from '../Message/helpers';

export const ChatContent: FC<ChatContentProps> = ({
  firstMessageRef,
  messagesWrapperRef,
  messages,
  isFetchMoreLoading,
  currentUserFirebaseId,
  approveRepresentationRequest,
  declineRepresentationRequest,
  isAttorney,
  aesDecrypt,
}) => {
  const groupedMessagesByDate: {
    date: string;
    messages: CreateLegalCaseMessageResponse[];
  }[] = messages?.reduce((acc, message) => {
    const createdAt = new Date(message.createdAt);
    const currentDate = createdAt.toISOString().split('T')[0];

    const existingDateEntry = acc.find(entry => entry.date === currentDate);

    if (existingDateEntry) {
      existingDateEntry.messages.push(message);
    } else {
      acc.push({ date: currentDate, messages: [message] });
    }

    return acc;
  }, []);

  return (
    <Stack sx={{ width: 1, height: 1, flex: 1, position: 'relative' }}>
      <Stack
        sx={{
          height: 1,
          overflowY: 'auto',
          flex: 1,
          flexDirection: 'column-reverse',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
        }}
        ref={messagesWrapperRef}
      >
        {groupedMessagesByDate?.map(({ date, messages }, index) => {
          return (
            <MDBox key={date} sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <DateLabel date={date} />
              {isFetchMoreLoading && groupedMessagesByDate.length - 1 === index && (
                <MDBox
                  sx={{
                    display: 'flex',
                    width: 1,
                    justifyContent: 'center',
                    position: 'sticky',
                    top: '5px',
                    zIndex: theme => theme.zIndex.drawer,
                    marginBottom: '4px',
                  }}
                >
                  <MDTypography variant="body2">Loading history...</MDTypography>
                </MDBox>
              )}

              <Stack
                flexDirection="column-reverse"
                sx={{
                  height: 1,
                  flex: 1,
                  pr: '3px',
                  mt: '-5px',
                }}
              >
                {messages?.map((message, index) => {
                  const isMine = currentUserFirebaseId === message?.owner?.firebaseUser;

                  const messageRef = index === messages.length - 1 ? firstMessageRef : null;
                  const messageText = getMessageText(!isAttorney, message, aesDecrypt);

                  return message?.type === 'file' ? (
                    <FileMessage
                      key={message?.id}
                      isSigned={message?.file?.sign?.signed}
                      fileId={message?.file?.id}
                      title={message?.file?.title}
                      fileSize={message?.file?.fileSize}
                      ownerFirstName={message?.file?.owner?.userProfile?.firstName}
                      ownerLastName={message?.file?.owner?.userProfile?.lastName}
                      fileType={message?.file?.type}
                      isMine={isMine}
                      firstMessageRef={messageRef}
                      isAttorney={isAttorney}
                      fileOwnerFirebaseId={message?.file?.owner?.firebaseUser}
                      userSignerFirebaseId={message?.file?.sign?.userSigner?.firebaseUser}
                      currentUserFirebaseId={currentUserFirebaseId}
                      createdAt={message?.createdAt}
                    />
                  ) : message?.type === 'prompt' ? (
                    <PromptMessage
                      key={message?.id}
                      isMine={isMine}
                      messageText={messageText}
                      isShowButtons={message?.representation?.status === 'pending' && !isAttorney}
                      declineRepresentationRequest={declineRepresentationRequest}
                      approveRepresentationRequest={approveRepresentationRequest}
                      createdAt={message?.createdAt}
                    />
                  ) : (
                    <Message
                      key={message?.id}
                      createdAt={message?.createdAt}
                      firstMessageRef={messageRef}
                      messageText={messageText}
                      isMine={isMine}
                    />
                  );
                })}
              </Stack>
            </MDBox>
          );
        })}
      </Stack>
    </Stack>
  );
};
