import { FC } from 'react';
import { Stack } from '@mui/material';
import { Loader } from 'features/common/components/Loader';
import { RemoteParticipant } from './RemoteParticipant';
import { LocalParticipant } from './LocalParticipant';
import { VideosProps } from '../types';

export const Videos: FC<VideosProps> = ({
  isRemoteUserAudioMuted,
  isRemoteUserVideoMuted,
  remoteParticipant,
  tracks,
  localParticipant,
  eventRemoteParticipant,
  eventType,
  isReconnecting,
  isCalling,
}) => {
  return (
    <Stack
      spacing={2}
      width={1}
      height={1}
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        transition: 'width 0.3s ease-in-out',
      }}
    >
      {isReconnecting ? (
        <Loader />
      ) : (
        <>
          <RemoteParticipant
            isRemoteUserAudioMuted={isRemoteUserAudioMuted}
            isRemoteUserVideoMuted={isRemoteUserVideoMuted}
            eventRemoteParticipant={eventRemoteParticipant}
            remoteParticipant={remoteParticipant}
            eventType={eventType}
            isCalling={isCalling}
          />

          {eventType === 'video_call' && !isCalling && (
            <LocalParticipant
              localParticipant={localParticipant}
              isRemoteParticipantJoined={!!remoteParticipant}
              localParticipantCameraVideoTrack={tracks?.[1]}
            />
          )}
        </>
      )}
    </Stack>
  );
};
