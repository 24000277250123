import React, { FC } from 'react';
import { Stack } from '@mui/material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import DocusignLogo from 'assets/images/icons/logo-docusign.png';

type TemplateItemProps = {
  onClickHandler: (event: React.MouseEvent<HTMLLIElement>) => void;
  id: string;
  title: string;
};

export const TemplateItem: FC<TemplateItemProps> = ({ id, onClickHandler, title }) => {
  return (
    <Stack
      component="li"
      id={id}
      sx={{
        p: 1,
        borderRadius: '6px',
        ':hover': {
          backgroundColor: ({ palette }) => palette.grey[200],
          cursor: 'pointer',
        },
      }}
      onClick={onClickHandler}
      direction="row"
      justifyContent="space-between"
      width={1}
      alignItems="center"
    >
      <Stack direction="row" alignItems="center">
        <MDAvatar src={DocusignLogo} variant="rounded" alt="Docusign logo" sx={{ width: 30, height: 30, mr: 1 }} />
        <MDTypography variant="button" sx={{ fontWeight: 500 }}>
          {title}
        </MDTypography>
      </Stack>

      <ChevronRightRoundedIcon fontSize="medium" color="action" />
    </Stack>
  );
};
