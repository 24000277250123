import { CaseLawTypeResponse, Pagination } from 'features/case-law-types';
import {
  BaseData,
  ENCRYPTED_HEADER,
  ListOfItems,
  Logo,
  PATCH_HEADERS,
  UserProfileResponse,
  backendApiClient,
} from 'features/common';
import { Consultation } from 'features/common/services/legalCaseConsultationApiService';
import { Representation } from 'features/common/services/legalCaseRepresentationApiService';
import {
  BASE_LEGAL_CASES_API_URL,
  BASE_LEGAL_CASE_NOTES_API_URL,
  CaseFile,
  CreateLegalCaseNoteResponse,
  CreateMyLegalCasePayload,
  GetMyLegalCasesResponse,
  LegalCaseStatus,
  UpdateNotePayload,
} from 'features/my-legal-cases/MyLegalCasesApiService';

const BASE_LEGAL_CASE_CHATS_API_URL: Readonly<string> = '/legal_case_chats';
const BASE_LEGAL_CASE_OFFERS_API_URL: Readonly<string> = '/legal_cases_offers';

export type GetMyLegalCaseDetailsResponse = GetMyLegalCasesResponse & {
  caseLawType: CaseLawTypeResponse;
  files: CaseFile[];
  onConsultation: boolean;
};

export type GetCaseDetailsNotes = Omit<CreateLegalCaseNoteResponse, '@context' | 'id'>;
export type PatchLegelCasePayload = Omit<CreateMyLegalCasePayload, 'client' | 'invitation'>;

export type GetMyLegalCaseChats = Pagination &
  Partial<{
    client: string;
    'client[]': string[];
    attorney: string;
    'attorney[]': string[];
    legalCase: string;
    'legalCase[]': string[];
  }>;

export type GetCaseHistoryParams = Omit<GetMyLegalCaseChats, 'attorney' | 'attorney[]' | 'client' | 'client[]'>;

export type User = BaseData<'User'> & {
  firebaseUser: string;
  logo: null | Logo;
  userProfile: Pick<UserProfileResponse, '@id' | '@type' | 'firstName' | 'lastName'>;
};

export type GetMyLegalCaseChatsResponse = BaseData<'LegalCaseChat'> & {
  firebaseUser: string;
  id: string;
  updatedAt: string;
  countUnwatchedMessages: number;
  lastMessage:
    | (BaseData<'LegalCaseChatMessage'> & {
        createdAt: string;
        id: string;
        message: string;
        updatedAt: string | null;
        viewedAt: string | null;
        owner: User;
      })
    | null;
  attorney: User;
  client: User;
  legalCase: Pick<GetMyLegalCasesResponse, 'updatedAt' | 'id' | '@id' | '@type' | 'caseNumber'> & {
    files: CaseFile[];
  };
};

type CaseStatus = BaseData<'LegalCaseStatus'> & {
  title: string;
};

export type GetMyLegalCaseHistoryResponse = BaseData<'UserFeed'> & {
  createdAt: string;
  id: string;
  legalCase: Pick<GetMyLegalCasesResponse, 'caseNumber' | '@id' | '@type'> & {
    files: CaseFile[];
  } & CaseStatus;
  previousCaseStatus?: CaseStatus;
  message: string;
  type: 'case_updates' | 'case_status';
  viewedAt: null | string;
};

export type GetMyLegalCaseStatusesParams = Partial<
  Pagination & {
    type: string;
    'type[]': string[];
  }
>;

export type ChangeLegalCaseStatusPayload = {
  status: string;
};

export const caseDetailsApiService = () => {
  const getMyLegalCase = async (id: string) => {
    return await backendApiClient({}).get<GetMyLegalCaseDetailsResponse>(`${BASE_LEGAL_CASES_API_URL}/${id}`);
  };

  const patchLegalCase = async (id: string, patchPayload: PatchLegelCasePayload) => {
    return await backendApiClient({
      headers: PATCH_HEADERS,
    }).patch<GetMyLegalCaseDetailsResponse>(`${BASE_LEGAL_CASES_API_URL}/${id}`, patchPayload);
  };

  const getLegalCaseNoteById = async (id: string) => {
    return await backendApiClient({
      headers: ENCRYPTED_HEADER,
    }).get<ListOfItems<GetCaseDetailsNotes>>(`${BASE_LEGAL_CASE_NOTES_API_URL}/${id}`);
  };

  const getMyLegalCaseNotes = async (id: string, params?: Pagination) => {
    return await backendApiClient({
      headers: ENCRYPTED_HEADER,
    }).get<ListOfItems<GetCaseDetailsNotes[]>>(`${BASE_LEGAL_CASES_API_URL}/${id}/notes/mine`, { params });
  };

  const getLegalCaseNotes = async (id: string, params?: Pagination) =>
    await backendApiClient({
      headers: ENCRYPTED_HEADER,
    }).get<ListOfItems<GetCaseDetailsNotes[]>>(`${BASE_LEGAL_CASES_API_URL}/${id}/notes`, {
      params,
    });

  const deleteLegalCaseNote = async (id: string) =>
    backendApiClient({}).delete(`${BASE_LEGAL_CASE_NOTES_API_URL}/${id}`);

  const updateLegalCaseNote = async (id: string, payload: Partial<UpdateNotePayload>) =>
    backendApiClient({
      headers: {
        ...PATCH_HEADERS,
        ...ENCRYPTED_HEADER,
      },
    }).patch<GetCaseDetailsNotes>(`${BASE_LEGAL_CASE_NOTES_API_URL}/${id}`, payload);

  const getMyLegalCaseChats = async (params: GetMyLegalCaseChats) =>
    await backendApiClient({}).get<ListOfItems<GetMyLegalCaseChatsResponse[]>>(`${BASE_LEGAL_CASE_CHATS_API_URL}`, {
      params,
    });

  const getMyLegalCaseHistory = async (params: GetCaseHistoryParams) =>
    await backendApiClient({}).get<ListOfItems<GetMyLegalCaseHistoryResponse[]>>('/user_feeds', {
      params,
    });

  const createLegalCaseOffer = async (id: string) =>
    backendApiClient({}).post(BASE_LEGAL_CASE_OFFERS_API_URL, {
      legalCase: id,
    });

  const getMyLegalCaseStatuses = async (params: GetMyLegalCaseStatusesParams) =>
    await backendApiClient({}).get<ListOfItems<LegalCaseStatus[]>>('/legal_case_statuses', {
      params,
    });

  const changeLegalCaseStatus = async (id: string, payload: ChangeLegalCaseStatusPayload) =>
    await backendApiClient({ headers: PATCH_HEADERS }).patch<GetMyLegalCaseDetailsResponse>(
      `${BASE_LEGAL_CASES_API_URL}/${id}/status`,
      payload
    );

  const getLegalCaseConsultations = async (caseId: string, params?: Pagination) =>
    await backendApiClient({}).get<ListOfItems<Consultation[]>>(`${BASE_LEGAL_CASES_API_URL}/${caseId}/consultations`, {
      params,
    });

  const getLegalCaseRepresentations = async (caseId: string, params: Pagination) =>
    await backendApiClient({}).get<ListOfItems<Representation[]>>(
      `${BASE_LEGAL_CASES_API_URL}/${caseId}/representations`,
      {
        params,
      }
    );

  const getLegalCaseFiles = async (caseId: string) =>
    backendApiClient({}).get<{ files: CaseFile[] }>(`${BASE_LEGAL_CASES_API_URL}/${caseId}/files`, {});

  const getLegalCaseFilesRequestedToSign = async (caseId: string) =>
    backendApiClient({}).get<{ files: CaseFile[] }>(`${BASE_LEGAL_CASES_API_URL}/${caseId}/files/sign/requested`, {});

  const getLegalCaseFilesRequiredToSign = async (caseId: string) =>
    backendApiClient({}).get<{ files: CaseFile[] }>(`${BASE_LEGAL_CASES_API_URL}/${caseId}/files/sign/required`, {});

  const getRequestAvaliableToSignLegalCaseFiles = async (caseId: string) =>
    backendApiClient({}).get<{ files: CaseFile[] }>(
      `${BASE_LEGAL_CASES_API_URL}/${caseId}/files/sign/request_available`,
      {}
    );

  return {
    getLegalCaseFilesRequiredToSign,
    getRequestAvaliableToSignLegalCaseFiles,
    getLegalCaseFilesRequestedToSign,
    getLegalCaseFiles,
    getMyLegalCaseHistory,
    getLegalCaseNotes,
    getMyLegalCase,
    patchLegalCase,
    deleteLegalCaseNote,
    updateLegalCaseNote,
    getMyLegalCaseNotes,
    getMyLegalCaseChats,
    createLegalCaseOffer,
    getMyLegalCaseStatuses,
    changeLegalCaseStatus,
    getLegalCaseConsultations,
    getLegalCaseRepresentations,
    getLegalCaseNoteById,
  };
};
