export enum ROUTES {
  home = '/',
  videoRoom = '/video-room',
  audioRoom = '/audio-room',
  myLegalCases = '/cases',
  caseDetails = '/cases/:caseId',
  createLegalCase = '/cases/create',
  caseReview = '/my-legal-cases/review',
  firmProfile = '/firm-profile',
  myCompanyWorkingHours = '/firm-profile/working-hours',
  myCompanySubscriptions = '/firm-profile/subscriptions',
  myCompanyCaseLawTypes = '/firm-profile/case-law-types',
  myCompanyPermissions = '/firm-profile/permissions',
  myCompanyUsers = '/firm-profile/users',
  eventCalendar = '/event-calendar',
  conversations = '/conversations',
  legalCasesFeed = '/case-feed',
  attorneysLawFirms = '/attorneys-and-law-firms',
  signIn = '/authentication/sign-in',
  signUp = '/authentication/sign-up',
  adminSignUp = '/authentication/admin-sign-up/:accessCode',
  recoveryPassword = '/authentication/recovery-password',
  profile = '/profile',
  attorneyLawTypes = '/profile/practice-areas',
  staticPages = '/static',
  staticCreatePage = '/static/create',
  staticPageDetails = '/static/:staticPageId',
  contentItems = '/content-items',
  contentItemCreateForCategory = '/content-items/create/:contentCategoryName',
  contentItemDetails = '/content-items/:contentItemId',
  faq = '/faq',
  subscription = '/subscription',
  successPayment = '/success-payment',
  cancelPayment = '/cancel-payment',
  security = '/profile/security',
  notifications = '/profile/notifications',
  caseLawTypes = '/case-law-types',
  caseLawTypesSuggestions = '/case-law-types/suggestions',
  companyList = '/company-list',
  companyListSuggestions = '/company-list-suggestions',
  integrations = '/profile/integrations',
  reviews = '/reviews',
  docusignRedirect = 'docusign/redirect',
  adobeSignRedirect = 'adobe-sign/redirect',
  clioCallback = 'clio/callback',
  clioSettings = '/clio-settings',
  clioCaseLawTypesMapping = '/clio-settings/case-law-types-mapping',
  legalCaseChat = '/cases/chat',
  attorneyManagement = '/attorney-list',
  clientManagement = '/client-list',
  adminManagement = '/admin-list',
  docusignSignProccessCallback = '/legal_case_chats/:id/files/:fileId/signed',
  settingsGeneral = '/settings',
  settingsClioIntegration = '/settings/clio-integration',
  settingsUrgentVersions = '/settings/urgent-versions',
  settingsMail = '/settings/mail',
  importCRMCase = '/cases/import-case',
  signProcessCompleteRedirect = '/sign-process-complete',
  fileSignEnd = 'file_sign_end/:id',
  firebaseSecurity = '/firebase-security',
  resetPassword = '/reset-password',
  verifyUserEmail = '/verify-email',
  openApp = '/open-app',
  lockScreen = '/lock-screen',
  incominCallPush = '/incoming-call-push',
  notFound = '*',
}
