import { FC } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SxProps, Theme } from '@mui/material';
import MDBox from 'components/MDBox';

type ThreeDotsMenuProps = {
  isDisabled: boolean;
  onClickHandler: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  sxProps?: SxProps<Theme>;
};

export const ThreeDotsMenu: FC<ThreeDotsMenuProps> = ({ sxProps, isDisabled, onClickHandler }) => {
  return (
    <MDBox
      sx={{
        position: 'absolute',
        right: 6,
        top: 5,
        padding: '3px',
        ...(!isDisabled && {
          cursor: 'pointer',
          borderRadius: '50%',
          display: 'flex',
          '&:hover': {
            backgroundColor: theme => theme.palette.grey[100],
          },
        }),
        ...sxProps,
      }}
      onClick={isDisabled ? undefined : onClickHandler}
    >
      <MoreVertIcon fontSize="medium" color={isDisabled ? 'action' : 'inherit'} />
    </MDBox>
  );
};
