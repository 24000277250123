import { CaseLawTypeResponse, Pagination } from 'features/case-law-types';
import { PracticeAreaCategory } from 'features/clio-settings/ClioSettingsApiService';
import { BaseData, ListOfItems, backendApiClient } from 'features/common';

const BASE_CONFIG_API_URL: Readonly<string> = '/clio/mapping/admin';

export type GetEmailTemplatesResponse = BaseData<'MailgunTemplateDto'> & {
  description: string;
  name: string;
};

export type CreateClioCaseLawTypePayload = {
  caseLawType: string;
  practiceAreaCategory: string;
};

export type UpdateClioCaseLawTypePayload = Pick<CreateClioCaseLawTypePayload, 'caseLawType'> & {
  practiceAreaCategories: string[];
};

export type ClioCaseLawType = BaseData<'KeyValueDto'> & {
  caseLawType: Pick<CaseLawTypeResponse, '@id' | '@type' | 'title'>;
  practiceAreaCategory: PracticeAreaCategory;
};

export type UpdateHorizontalMappingPayload = {
  legalCaseAddressSource: string;
};

export type HorizontalMapping = BaseData<'ClioMappingAdminHorizontal'> & {
  legalCaseAddressSource: string;
};

export const clioSettingsApiService = () => {
  const getClioCaseLawTypes = async (params?: Pagination) =>
    await backendApiClient({}).get<ListOfItems<ClioCaseLawType[]>>(`${BASE_CONFIG_API_URL}/case_law_types`, { params });

  const updateClioCaseLawType = async (payload: UpdateClioCaseLawTypePayload) =>
    await backendApiClient({}).patch(`${BASE_CONFIG_API_URL}/case_law_types`, payload);

  const getClioHorizontalMapping = async () =>
    await backendApiClient({}).get<HorizontalMapping>(`${BASE_CONFIG_API_URL}/horizontal`);

  const updateHorizontalMapping = async (payload: UpdateHorizontalMappingPayload) =>
    await backendApiClient({}).patch(`${BASE_CONFIG_API_URL}/horizontal`, payload);

  return {
    getClioCaseLawTypes,
    updateClioCaseLawType,
    getClioHorizontalMapping,
    updateHorizontalMapping,
  };
};
