import { FC } from 'react';
import { SideMenu } from '../SideMenu';
import { FillingTemplateSideMenuContent } from './FillingTemplateSideMenuContent';
import { FillingTemplateSideMenuProps } from './types';

export const FillingTemplateSideMenu: FC<FillingTemplateSideMenuProps> = ({
  closeMenuHandler,
  fetchLegalCaseDocuments,
  legalCaseId,
  remoteParticipantFirebaseId,
  templateId,
  title,
  isOpen,
  selectedFileSignProvider,
}) => {
  return (
    <SideMenu isOpen={isOpen} onClose={closeMenuHandler} title={title || ''} customWidth={450}>
      <FillingTemplateSideMenuContent
        closeMenuHandler={closeMenuHandler}
        templateId={templateId || ''}
        remoteParticipantFirebaseId={remoteParticipantFirebaseId}
        legalCaseId={legalCaseId}
        fetchLegalCaseDocuments={fetchLegalCaseDocuments}
        selectedFileSignProvider={selectedFileSignProvider}
      />
    </SideMenu>
  );
};
