import { Pagination } from 'features/case-law-types';
import { BaseData, ListOfItems, UserProfileResponse, backendApiClient } from 'features/common';
import { GetMyClientsParams } from 'features/my-clients/MyClientsApiService';

export type GetParticipants = GetMyClientsParams &
  Partial<{
    email: string;
    'userProfile.firstName': string;
    'userProfile.lastName': string;
  }>;

export type EventParticipant = BaseData<'User'> & {
  userProfile: Pick<UserProfileResponse, '@id' | '@type' | 'lastName' | 'firstName'>;
};

export type GetParticipantCases = Pagination &
  Partial<{
    participant: string;
    'status.type': string;
    'status.type[]': string[];
    caseLawType: string;
    'caseLawType[]': string[];
    country: string;
    'country[]': string[];
    'state.isoCode': string;
    'state.isoCode[]': string[];
    county: string;
    'county[]': string[];
  }>;

export type ParticipantCase = BaseData<'Case'> & {
  caseNumber: 'string';
};

export const myEventsApiService = () => {
  const getParticipants = async (params: GetParticipants) =>
    await backendApiClient({}).get<ListOfItems<EventParticipant[]>>(`/users/participants`, { params });

  const getParticipantCases = async (params: GetParticipantCases) =>
    await backendApiClient({}).get<ListOfItems<ParticipantCase[]>>(`/legal_cases/my`, { params });

  return {
    getParticipantCases,
    getParticipants,
  };
};
