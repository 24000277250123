import * as yup from 'yup';
import { RegularityItem } from './types';

export type CreateDelayedNotificationValidationScheme = {
  startingDate: Date;
  time: Date;
  message: string;
};

export const createDelayedNotificationValidatationSchema: yup.ObjectSchema<CreateDelayedNotificationValidationScheme> =
  yup
    .object({
      time: yup.date().nonNullable().required('Please choose a time'),
      startingDate: yup.date().nonNullable().required('Please choose a starting date'),
      message: yup.string().required('Please enter a message'),
    })
    .required();

export const REGULARITIES: RegularityItem[] = [
  { title: 'Every day', value: 'every_day' },
  { title: 'Every week', value: 'every_week' },
  { title: 'Every two weeks', value: 'every_two_week' },
  { title: 'Every month', value: 'every_month' },
];
