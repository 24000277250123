import { FC, useEffect, useState } from 'react';
import { Autocomplete, CircularProgress, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AttorneyProfileCaseLawType,
  BaseFormField,
  ToastType,
  createAttorneyService,
  notice,
  useCaseLawTypes,
  useUser,
} from 'features/common';
import MDButton from 'components/MDButton';
import { baseBackendErrorHandler } from 'features/common/errorHanders';
import { CaseLawTypeResponse, createCaseLawTypesService } from 'features/case-law-types';
import MDTypography from 'components/MDTypography';
import { SuggestLawTypeValidationSchema, createLawTypeSuggestionFormSchema, defaultValues } from './form.config';
import MDBox from 'components/MDBox';

type SuggestLawTypeContentMenuProps = {
  onCloseMenuHandler: () => void;
  setAttoreyProfileCaseLawTypes: (value: React.SetStateAction<AttorneyProfileCaseLawType[]>) => void;
};

export const SuggestLawTypeContentMenu: FC<SuggestLawTypeContentMenuProps> = ({
  setAttoreyProfileCaseLawTypes,
  onCloseMenuHandler,
}) => {
  const { createCaseLawTypeSuggest } = createCaseLawTypesService();
  const { getAttorneyProfileById, putAttorneyCaseLawTypeById, getAttorneyProfileCaseLawTypes } =
    createAttorneyService();

  const { backendUser } = useUser();

  const [currentCaseLawTypes, setCurrentCaseLawTypes] = useState<CaseLawTypeResponse[]>([]);
  const [isUpdatingLawTypes, setIsUpdatingLawTypes] = useState(false);

  // TODO conference hack
  const attorneyProfileId = backendUser?.attorneyProfile?.['@id']?.replace('/attorney_profiles/', '');

  const onGetAttorneyCurrentCaseLawTypes = async () => {
    if (!backendUser?.attorneyProfile) return;
    const { caseLawTypes } = await getAttorneyProfileById(attorneyProfileId);
    setCurrentCaseLawTypes(caseLawTypes);
  };

  const {
    register,
    formState: { isValid, errors, isSubmitting },
    setError,
    handleSubmit,
  } = useForm<SuggestLawTypeValidationSchema>({
    resolver: yupResolver(createLawTypeSuggestionFormSchema),
    mode: 'onTouched',
    defaultValues,
  });

  const onSubmitFormHandler = handleSubmit(async formData => {
    try {
      await createCaseLawTypeSuggest({ title: formData.title });
      onCloseMenuHandler();

      notice(
        ToastType.SUCCESS,
        'Suggestion created for this practice area. It will be added to your profile if administrator approve it.'
      );
    } catch (error) {
      console.error(error);

      baseBackendErrorHandler(error, { formError: { formData, setError } });
    }
  });

  const { caseLawTypesList, isCaseLawTypesLoading } = useCaseLawTypes();

  const onChangeAutocompleteHandler = (_: React.SyntheticEvent<Element, Event>, value: CaseLawTypeResponse[]) =>
    setCurrentCaseLawTypes(value);

  const onSaveButtonClickHandler = async () => {
    try {
      const selectedLawTypesIds = currentCaseLawTypes.map(lawType => lawType?.['@id']);
      setIsUpdatingLawTypes(true);
      await putAttorneyCaseLawTypeById(attorneyProfileId, {
        caseLawTypes: selectedLawTypesIds,
      });
      const { data } = await getAttorneyProfileCaseLawTypes({ page: 1, itemsPerPage: 500 });
      setAttoreyProfileCaseLawTypes(data?.['hydra:member']);

      onCloseMenuHandler();
      setIsUpdatingLawTypes(false);
      notice(ToastType.SUCCESS, `Successfully updated practice area`);
    } catch (error) {
      setIsUpdatingLawTypes(false);
      console.error(error);
      baseBackendErrorHandler(error);
    }
  };

  useEffect(() => {
    onGetAttorneyCurrentCaseLawTypes();
  }, []);

  return (
    <Stack component="form" onSubmit={onSubmitFormHandler} height={1} spacing={1.5} my={2} position="relative">
      <Stack spacing={2}>
        <Stack>
          <Autocomplete
            value={currentCaseLawTypes}
            getOptionLabel={option => option?.title}
            options={caseLawTypesList}
            isOptionEqualToValue={(option, value) => option?.['@id'] === value?.['@id']}
            onChange={onChangeAutocompleteHandler}
            multiple
            loading={isCaseLawTypesLoading}
            freeSolo={false}
            renderInput={params => (
              <BaseFormField
                formInputProps={{
                  ...params,
                  label: 'Practice area',
                  fullWidth: true,
                  InputProps: {
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isCaseLawTypesLoading ? (
                          <MDBox pr={0} display="flex">
                            <CircularProgress color="inherit" size={20} />
                          </MDBox>
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  },
                }}
              />
            )}
          />
          <MDButton
            type="button"
            variant="gradient"
            color="info"
            isLoading={isUpdatingLawTypes}
            disabled={isUpdatingLawTypes || !currentCaseLawTypes?.length}
            onClick={onSaveButtonClickHandler}
          >
            Save
          </MDButton>
        </Stack>

        <Stack>
          <MDTypography mb={2} variant="h5" whiteSpace="initial">
            Suggest new practice area
          </MDTypography>
          <BaseFormField
            formInputProps={{
              ...register('title'),
              type: 'text',
              label: 'Your variant',
              fullWidth: true,
            }}
            errorValue={errors['title']?.message}
          />
          <MDTypography variant="button" whiteSpace="initial" mb={1}>
            You will have to wait for the administrator to approve your practice area.
          </MDTypography>

          <MDButton
            type="submit"
            variant="gradient"
            color="info"
            isLoading={isSubmitting}
            disabled={!isValid || isSubmitting}
          >
            Send
          </MDButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
