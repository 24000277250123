import { useEffect, useState } from 'react';
import { usePagination } from 'features/common/hooks/usePagination';
import { GetStaticPagesParams, StaticPageItemResponse, createStaticPagesService } from '../services';
import { ToastType, notice } from '../../common';
import { abortRequestErrorHandler } from 'features/common/errorHanders';

export const useStaticPageListData = () => {
  const { getStaticPages, patchContentPageById, deleteContentPageById } = createStaticPagesService();

  const [staticPageList, setStaticPageList] = useState<ReadonlyArray<StaticPageItemResponse>>([]);
  const [staticPageTotalItems, setStaticPageTotalItems] = useState<null | number>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { currentPage, entriesPerPage, setCurrentPage, onChangeEntriesPerPageHandler, onDeleteItem } = usePagination({
    itemsLength: staticPageList?.length,
    totalItems: staticPageTotalItems || 0,
  });

  const getItemsHandler = async (params?: GetStaticPagesParams) => {
    try {
      const { data } = await getStaticPages({
        page: currentPage,
        itemsPerPage: entriesPerPage,
        ...params,
      });
      setStaticPageList(data['hydra:member']);
      setStaticPageTotalItems(data['hydra:totalItems']);
    } catch (error) {
      abortRequestErrorHandler(error);
    }
  };

  const loadStaticPagesHandler = async (page?: number) => {
    setIsLoading(true);
    await getItemsHandler({ page });
    setIsLoading(false);
  };

  useEffect(() => {
    loadStaticPagesHandler();
  }, [entriesPerPage]);

  const onChangePageHandler = async (page: number) => {
    setCurrentPage(page);
    await loadStaticPagesHandler(page);
  };

  return {
    isLoading,
    staticPageList,
    currentPage,
    staticPageTotalItems,
    entriesPerPage,
    setIsLoading,
    getItemsHandler,
    onDeleteItem,
    onChangeEntriesPerPageHandler,
    loadStaticPagesHandler,
    onChangePageHandler,
    patchContentPageById,
    deleteContentPageById,
  };
};
