import { useCallback, useState } from 'react';
import {
  City,
  CountiesPaginationParams,
  Country,
  CountryState,
  County,
  ToastType,
  createGeoDataService,
  notice,
  useAsync,
} from '../index';

export const useGeoData = (fetchCountryList: boolean = true) => {
  const [isCountyListLoading, setIsCountyListLoading] = useState(false);
  const [isStateListLoading, setIsStateListLoading] = useState(false);
  const [isCityListLoading, setIsCityListLoading] = useState(false);

  const { getCountries, getStatesByCountryId, getCitiesByStateId, getStates, getCounties } = createGeoDataService();

  const [countyList, setCountyList] = useState<County[]>([]);
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [countryStateList, setCountryStateList] = useState<CountryState[]>([]);
  const [cityList, setCityList] = useState<City[]>([]);

  const [selectedStateId, setSelectedStateId] = useState<string | null>(null);

  const getStateListHandler = async (countryId?: string) => {
    try {
      setIsStateListLoading(true);
      const { data } = countryId ? await getStatesByCountryId(countryId, {}) : await getStates({});
      setCountryStateList(data['hydra:member']);
      setIsStateListLoading(false);
    } catch (error) {
      setIsStateListLoading(false);
      notice(ToastType.ERROR, 'Failed to fetch states, please try again!');
      console.error(error);
    }
  };

  const getCountiesHandler = async (params: CountiesPaginationParams) => {
    try {
      setIsCountyListLoading(true);
      const { data } = await getCounties(params);

      setCountyList(data['hydra:member']);
      setIsCountyListLoading(false);
    } catch (error) {
      setIsCountyListLoading(false);
      console.error(error);
      notice(ToastType.ERROR, 'Failed to fetch counties, please try again!');
    }
  };

  const onCountrySelect = async (selectedCountryId: string) => {
    if (selectedCountryId) {
      const {
        data: { 'hydra:member': countryStateList },
      } = await getStatesByCountryId(selectedCountryId, {});

      return setCountryStateList(countryStateList);
    }

    setCountryStateList([]);
  };

  const onCountryStateSelect = async (selectedCountryStateId: string) => {
    if (selectedCountryStateId) {
      setSelectedStateId(selectedCountryStateId);

      return await onTownCityFieldInput({ stateId: selectedCountryStateId });
    }
    setCityList([]);
  };

  const townCityFieldInputHandler = useCallback(
    async ({ stateId, cityName }: { stateId?: string; cityName?: string }) => {
      const {
        data: { 'hydra:member': citiesList },
      } = await getCitiesByStateId(stateId || selectedStateId, { itemsPerPage: 50, name: cityName });

      return setCityList(citiesList);
    },
    [selectedStateId]
  );

  const [onTownCityFieldInputState, onTownCityFieldInput] = useAsync(townCityFieldInputHandler);

  const resetTownList = () => setCityList([]);

  const isTownCityFieldInputLoading = onTownCityFieldInputState.isPending();

  const [onGetCountryListHandlerState, onGetCountryListHandler] = useAsync(
    async () => {
      const {
        data: { 'hydra:member': countryList },
      } = await getCountries({});

      setCountryList(countryList);
    },
    { immediateCall: fetchCountryList, immediateInvocationArgs: [] }
  );

  return {
    countryList,
    countryStateList,
    cityList,
    isTownCityFieldInputLoading,
    selectedStateId,
    countyList,
    isStateListLoading,
    isCountyListLoading,
    isCityListLoading,
    getStateListHandler,
    getCountiesHandler,
    getCitiesByStateId,
    onCountrySelect,
    onCountryStateSelect,
    onTownCityFieldInput,
    resetTownList,

    onGetCountryListHandler,
    onGetCountryListHandlerState,
  };
};
