import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardContent, CardHeader, Grid, Stack, Switch } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { useMyCompany } from 'features/store';
import MDTypography from 'components/MDTypography';
import {
  COMPANY_USER_PERMISSIONS_FIELDS,
  CompanyPermissionsSchema,
  companyPermissionsValidationFormSchema,
} from './company.config';
import { ToastType, notice, useRouter, useUser } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';

export const CompanyPermissionsTemplate: FC = () => {
  const { myCompany, updateUserPermissions } = useMyCompany();
  const backendUser = useUser()?.backendUser;
  const permissions = myCompany?.userPermissions;
  const {
    register,
    formState: { isDirty, isSubmitting, isValid },
    handleSubmit,
    watch,
  } = useForm<CompanyPermissionsSchema>({
    resolver: yupResolver(companyPermissionsValidationFormSchema),
    mode: 'onTouched',
    defaultValues: {
      adminCanAddAttorney: permissions?.adminCanAddAttorney,
      adminCanDeleteAttorney: permissions?.adminCanDeleteAttorney,
      adminCanEditCompanyInfo: permissions?.adminCanEditCompanyInfo,
      adminCanPromoteAdmin: permissions?.adminCanPromoteAdmin,
    },
    values: {
      adminCanAddAttorney: permissions?.adminCanAddAttorney,
      adminCanDeleteAttorney: permissions?.adminCanDeleteAttorney,
      adminCanEditCompanyInfo: permissions?.adminCanEditCompanyInfo,
      adminCanPromoteAdmin: permissions?.adminCanPromoteAdmin,
    },
  });

  const onFormSudmitHandler = handleSubmit(async formData => {
    await updateUserPermissions({
      adminCanAddAttorney: formData.adminCanAddAttorney,
      adminCanDeleteAttorney: formData.adminCanDeleteAttorney,
      adminCanEditCompanyInfo: formData.adminCanEditCompanyInfo,
      adminCanPromoteAdmin: formData.adminCanPromoteAdmin,
    });
  });

  const isCompanyOwner = myCompany?.owner?.firebaseUser === backendUser?.firebaseUser;

  const isButtonDisabled = !isValid || !isDirty || isSubmitting || !isCompanyOwner;

  const { push } = useRouter();

  useEffect(() => {
    if (!isCompanyOwner) {
      push(ROUTES.firmProfile).then(() => notice(ToastType.WARNING, 'You do not have access to this page'));
      return;
    }
  }, []);

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader title="Company user permissions" />
        <CardContent>
          <MDBox display="flex" flexDirection="column" component="form" width={1} onSubmit={onFormSudmitHandler}>
            {COMPANY_USER_PERMISSIONS_FIELDS.map(field => (
              <Stack
                key={field.watchSwitcherValue}
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2 }}
                mb={0.5}
                alignItems="center"
                sx={{
                  width: { xs: 1, sm: 'auto' },
                  justifyContent: { xs: 'space-between', sm: 'flex-start' },
                  alignItems: { xs: 'flex-start', sm: 'center' },
                }}
              >
                <MDTypography variant="button" color="text" sx={{ maxWidth: 200, width: 1 }}>
                  {field.switchTitle}
                </MDTypography>

                <Switch
                  {...register(field.watchSwitcherValue)}
                  checked={!!watch(field.watchSwitcherValue)}
                  sx={{
                    ...(!isCompanyOwner && {
                      pointerEvents: 'none',
                      cursor: 'auto',
                    }),
                  }}
                />
              </Stack>
            ))}
            <MDBox mt={2} mb={1} sx={{ width: 1 }}>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                isLoading={isSubmitting}
                sx={{ textTransform: 'none', width: { xs: 1, sm: 'auto' } }}
                disabled={isButtonDisabled}
              >
                Save
              </MDButton>
            </MDBox>
          </MDBox>
        </CardContent>
      </Card>
    </Grid>
  );
};
