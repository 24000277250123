import { FC } from 'react';
import { Apple, Google } from '@mui/icons-material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { OAUTH_PROVIDER_TYPES } from 'features/auth/services';

type AuthProvidersButtonsProps = {
  isSignIn?: boolean;
  isNextButtonDisabled: boolean;
  isProviderButtonDisabled: boolean;
  onAuthProviderButtonClickHandler: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
};

export const AuthProvidersButtons: FC<AuthProvidersButtonsProps> = ({
  isProviderButtonDisabled,
  isNextButtonDisabled,
  onAuthProviderButtonClickHandler,
}) => {
  return (
    <MDBox mt={4} mb={1} flexDirection="column" display="flex" gap="20px">
      <MDButton
        variant="gradient"
        color="info"
        type="submit"
        fullWidth
        sx={{ textTransform: 'none' }}
        disabled={isNextButtonDisabled}
      >
        Next
      </MDButton>
      <MDButton
        variant="outlined"
        color="info"
        type="button"
        fullWidth
        id={OAUTH_PROVIDER_TYPES.APPLE}
        sx={{ textTransform: 'none', color: '#333' }}
        startIcon={<Apple />}
        onClick={onAuthProviderButtonClickHandler}
        disabled={isProviderButtonDisabled}
      >
        Continue with Apple
      </MDButton>
      <MDButton
        variant="outlined"
        color="info"
        type="button"
        fullWidth
        sx={{ textTransform: 'none', color: '#333' }}
        startIcon={<Google />}
        id={OAUTH_PROVIDER_TYPES.GOOGLE}
        onClick={onAuthProviderButtonClickHandler}
        disabled={isProviderButtonDisabled}
      >
        Continue with Google
      </MDButton>
    </MDBox>
  );
};
