import { FC, memo } from 'react';
import { Dialog, DialogContent, DialogTitle, Stack } from '@mui/material';
import MDButton from 'components/MDButton';
import { FilePermissionProvider } from 'features/common/services/filesService';
import { FilseSignProviderDialogWindowProps } from './types';
import { SignProvidersRadioGroup } from './SignProvidersRadioGroup';

export const SIGN_PROVIDERS: FilePermissionProvider[] = [
  { label: 'DocuSign', provider: 'docu_sign' },
  { label: 'Adobe sign', provider: 'adobe_sign' },
];

export const FileSignProviderDialogWindow: FC<FilseSignProviderDialogWindowProps> = memo(
  ({
    fileSignProviders = SIGN_PROVIDERS,
    isOpen,
    selectedSignProvider,
    onCloseDialogWindow,
    onChangeRadioButtonHandler,
    onChooseHandler,
  }) => {
    const onClickChooseButtonHandler = () => {
      onCloseDialogWindow();
      onChooseHandler();
    };

    return (
      <Dialog open={isOpen} onClose={onCloseDialogWindow}>
        <Stack spacing={2}>
          <DialogTitle sx={{ pb: 1 }}>Which service to sign through?</DialogTitle>
          <DialogContent>
            <Stack spacing={2.5}>
              <SignProvidersRadioGroup
                onChangeRadioButtonHandler={onChangeRadioButtonHandler}
                selectedSignProvider={selectedSignProvider}
                fileSignProviders={fileSignProviders}
              />
              <MDButton onClick={onClickChooseButtonHandler} variant="gradient" color="info">
                Choose
              </MDButton>
            </Stack>
          </DialogContent>
        </Stack>
      </Dialog>
    );
  }
);
