import { useCallback, useState } from 'react';
import { useBoolean } from './useBoolean';
import { useUser } from './useUser';
import { ToastType, notice } from '../modules';
import { SIGN_SERVICE_NOT_INTEGRATED_MESSAGE } from 'features/case-details/components/Documents/DocumentItem';
import { FileSignTemplate } from '../services/fileSignApiService';

export const useUploadDocumentWindows = () => {
  const { isDocuSignActive, isAdobeSignActive } = useUser();
  const [
    isNotConnectedFileSignServicesOpen,
    openNotConnectedFileSignServicesModal,
    closeNotConnectedFileSignServicesModal,
  ] = useBoolean(false);

  const [isRequestToSignDocumentsModalOpen, openRequestToSignDocumentsModalOpen, closeRequestToSignDocumentsModalOpen] =
    useBoolean(false);

  const [isSelectDocumentsFromCaseMenuOpen, openSelectDocumentsFromCaseMenu, closeSelectDocumentsFromCaseMenu] =
    useBoolean(false);

  const [isFillDocuSignTemplateMenuOpen, openFillDocuSignTemplateMenu, closeFillDocuSignTemplateMenu] =
    useBoolean(false);

  const [isSelectTemplateDocumentModalOpen, openSelectTemplateDocumentModal, closeSelectTemplateDocumentModal] =
    useBoolean(false);

  const [selectedTemplate, setSelectedTemplate] = useState<null | FileSignTemplate>(null);

  const onClickSelectTemplateItemHandler = useCallback((template: FileSignTemplate) => {
    setSelectedTemplate(template);
    closeSelectTemplateDocumentModal();
    openFillDocuSignTemplateMenu();
  }, []);

  const onOpenSelectTemplateDocumentModalHandler = useCallback(() => {
    if (!isDocuSignActive && !isAdobeSignActive) {
      notice(ToastType.WARNING, SIGN_SERVICE_NOT_INTEGRATED_MESSAGE);
      return;
    }

    openSelectTemplateDocumentModal();

    closeRequestToSignDocumentsModalOpen();
  }, [isDocuSignActive]);

  return {
    isSelectTemplateDocumentModalOpen,
    closeSelectTemplateDocumentModal,
    openSelectTemplateDocumentModal,
    isFillDocuSignTemplateMenuOpen,
    openFillDocuSignTemplateMenu,
    closeFillDocuSignTemplateMenu,
    selectedTemplate,
    onClickSelectTemplateItemHandler,
    isSelectDocumentsFromCaseMenuOpen,
    openSelectDocumentsFromCaseMenu,
    closeSelectDocumentsFromCaseMenu,
    isRequestToSignDocumentsModalOpen,
    openRequestToSignDocumentsModalOpen,
    closeRequestToSignDocumentsModalOpen,
    onOpenSelectTemplateDocumentModalHandler,
    isNotConnectedFileSignServicesOpen,
    openNotConnectedFileSignServicesModal,
    closeNotConnectedFileSignServicesModal,
  };
};
