import * as yup from 'yup';
import { emailRegExp } from 'features/auth';

export type AdminInvitationSchema = { email: string; firstName: string; lastName: string; expiredAt: Date };

export const createAdminInvitationValidationFormSchema: yup.ObjectSchema<AdminInvitationSchema> = yup
  .object({
    firstName: yup.string().required().max(50),
    lastName: yup.string().required().max(50),
    expiredAt: yup.date().nonNullable(),
    email: yup.string().matches(emailRegExp, 'Please enter a valid email').trim(),
  })
  .required();
