import { DateSpanApi, EventInput } from '@fullcalendar/react';
import { isBefore, startOfDay } from 'date-fns';
import { AgoraEvent } from 'features/common/services/agoraEventApiService';
import { isDatePast } from 'features/common/helpers/date';
import { ButtonName } from './types';

export const CalendarConfig = {
  headerToolbar: {
    right: 'customCreateEvent customtoday customprev customnext',
  },
  eventConstraint: {
    start: new Date(),
  },
};

export const selectAllowHandler = (span: DateSpanApi): boolean => {
  const startDate = startOfDay(span.start);
  const today = startOfDay(new Date());
  return !isBefore(startDate, today);
};

export const eventDataTransformHandler = (event: EventInput): EventInput => {
  const agoraEvent = event as AgoraEvent;

  const isPastEvent = isDatePast(agoraEvent?.startedAt);

  return {
    ...event,
    title: `Case number: ${agoraEvent?.legalCase?.caseNumber}`,
    start: agoraEvent?.startedAt,
    end: agoraEvent?.expiredAt,
    className: isPastEvent ? 'event-past' : 'event-info',
  };
};

export const getCustomButtons = (
  onButtonClick: (val: ButtonName) => void,
  onCreateEventButtonClickHandler: () => void
) => ({
  customCreateEvent: {
    text: 'Create event',
    click: onCreateEventButtonClickHandler,
  },
  customprev: {
    text: '<',
    hint: 'prev',
    click: () => onButtonClick('customprev'),
  },
  customnext: {
    text: '>',
    click: () => onButtonClick('customnext'),
  },
  customtoday: {
    text: 'Today',
    click: () => onButtonClick('customtoday'),
  },
});
