import { FC } from 'react';
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton, { ButtonColor } from 'components/MDButton';
import { Chip, SxProps, Theme, Tooltip } from '@mui/material';
import { useResponsive } from 'features/common/hooks/useResponsive';

export type CardColor = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'light' | 'dark' | 'white';
export type CardBadgeColor = Exclude<CardColor, 'white'>;

interface PricingCardProps {
  color?: CardColor;
  badge: {
    color: CardBadgeColor;
    label: string;
  };
  price: {
    currency: string;
    value: string;
    type: string;
    priceAnnotation?: string;
  };
  specifications: {
    label: string;
    includes?: boolean;
  }[];
  action: {
    onClickHandler: () => Promise<void>;
    label: string;
    color: ButtonColor;
    isDisabled?: boolean;
    badgeTitle?: string;
    isLoading: boolean;
  };
  shadow?: boolean;
  isActiveSubscription?: boolean;
  sx?: SxProps<Theme>;
}

export const PricingCard: FC<PricingCardProps> = ({
  color,
  badge,
  price,
  specifications,
  action,
  shadow,
  isActiveSubscription,
  sx,
}) => {
  const renderSpecifications = specifications.map(({ label, includes }) => (
    <MDBox key={label} display="flex" alignItems="center" p={1}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.5rem"
        height="1.5rem"
        mr={2}
        mt={-0.125}
      >
        <MDTypography variant="body1" color={color === 'white' ? 'text' : 'white'} sx={{ lineHeight: 0 }}>
          <Icon>{includes ? 'done' : 'remove'}</Icon>
        </MDTypography>
      </MDBox>
      <MDTypography variant="body2" color={color === 'white' ? 'text' : 'white'} fontWeight="regular">
        {label}
      </MDTypography>
    </MDBox>
  ));

  const isSmallScreen = useResponsive('down', 'sm');

  return (
    <Card sx={{ justifyContent: 'center', boxShadow: ({ boxShadows: { lg } }) => (shadow ? lg : 'none'), ...sx }}>
      <MDBox bgColor={color} variant={color === 'white' ? 'contained' : 'gradient'} borderRadius="xl">
        <MDBox
          bgColor={badge.color as string}
          width={1}
          px={{ xs: 1, sm: 2, md: 3 }}
          pt={0}
          pb={0.5}
          mx="auto"
          mt={-1.375}
          borderRadius="section"
          lineHeight={1}
          maxWidth="max-content"
        >
          <MDTypography
            variant="caption"
            textTransform="uppercase"
            fontWeight="medium"
            color={badge.color === 'light' ? 'dark' : 'white'}
            whiteSpace="initial"
            textAlign="center"
            width={1}
            sx={{ fontSize: { xs: '10px', sm: '12px' } }}
          >
            {badge.label}
          </MDTypography>
        </MDBox>
        <MDBox pt={3} pb={2} px={{ xs: 1, sm: 2 }} textAlign="center">
          <MDBox my={1} position="relative">
            <MDTypography variant={isSmallScreen ? 'h2' : 'h1'} color={color === 'white' ? 'dark' : 'white'}>
              <MDTypography display="inline" component="small" variant="h5" color="inherit" verticalAlign="top">
                {price.currency}
              </MDTypography>
              {price.value}
              <MDTypography display="inline" component="small" variant="h5" color="inherit">
                /{price.type}
              </MDTypography>
            </MDTypography>
            {price.priceAnnotation && (
              <MDBox sx={{ position: 'absolute', top: '100%', left: 0, width: 1, mt: -1 }}>
                <MDTypography variant="button" color={color === 'white' ? 'dark' : 'white'} sx={{ fontWeight: 500 }}>
                  {price.priceAnnotation}
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
        <MDBox pb={3} px={{ xs: 1, sm: 3 }}>
          {renderSpecifications}
          <MDBox mt={3} sx={{ justifyContent: 'center', display: 'flex' }}>
            {!isActiveSubscription ? (
              <Tooltip title={action?.badgeTitle || ''}>
                <MDBox>
                  <MDButton
                    disabled={action?.isDisabled || action?.isLoading}
                    variant="gradient"
                    color={action.color}
                    onClick={action?.onClickHandler}
                    isLoading={action?.isLoading}
                  >
                    {action.label}&nbsp;
                    <Icon sx={{ fontWeight: 'bold', alignSelf: 'baseline' }}>arrow_forward</Icon>
                  </MDButton>
                </MDBox>
              </Tooltip>
            ) : (
              <Chip
                variant="filled"
                color="success"
                sx={{
                  '.MuiChip-label': {
                    alignItems: 'center',
                    color: 'white !important',
                    display: 'flex',
                  },
                }}
                label={
                  <>
                    <Icon sx={{ fontWeight: 'bold', alignSelf: 'center', mt: '1px' }}>check_circle</Icon>&nbsp;
                    Subscription is active
                  </>
                }
              />
            )}
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
};
