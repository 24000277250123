import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { Button, InputAdornment, MenuItem, Stack, TextFieldProps, Typography } from '@mui/material';
import { KeyboardArrowDownRounded, SearchOutlined } from '@mui/icons-material';
import {
  CountryData,
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
  ParsedCountry,
  getCountry,
} from 'react-international-phone';
import 'react-international-phone/style.css';
import MDTypography from 'components/MDTypography';
import { usePopover } from 'features/common/hooks/usePopover';
import { BaseFormField } from '../BaseFormField';
import { MenuPopover } from '../MenuPopover';

type PhoneFieldProps = Omit<TextFieldProps, 'value' | 'onChange'> & {
  value: string;
  onChange: (val: string) => void;
  errorValue?: string;
};

export const PhoneField: FC<PhoneFieldProps> = memo(({ onChange, value, errorValue, ...rest }) => {
  const onChangePhoneInputHandler = (data: { phone: string; inputValue: string; country: ParsedCountry }) => {
    if (fieldValue.startsWith('+')) {
      const country = getCountry({ field: 'dialCode', value: fieldValue.replaceAll('+', '') });
      if (!country) return;
    }
    onChange(data.phone);
  };

  useEffect(() => {
    if (value && !fieldValue) setFieldValue(value);
  }, [value]);

  const { inputRef, handlePhoneValueChange, country, setCountry } = usePhoneInput({
    defaultCountry: 'us',
    value,
    countries: defaultCountries,
    preferredCountries: ['us'],
    onChange: onChangePhoneInputHandler,
    disableDialCodeAndPrefix: true,
    disableCountryGuess: false,
    defaultMask: '....................',
  });

  const [fieldValue, setFieldValue] = useState(value || '');

  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  const onSelectCountryHandler = (event: React.MouseEvent<HTMLLIElement>) => {
    const iso2 = event.currentTarget.id;
    setCountry(iso2, { focusOnInput: true });

    if (fieldValue) setFieldValue('');

    if (searchValue) setSearchValue('');

    handleClosePopover();
  };

  const [searchValue, setSearchValue] = useState('');
  const [filteredCountries, setFilteredCountries] = useState<CountryData[]>(defaultCountries);

  const onChageSearchCountryHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(e.target.value);

    const filtered = defaultCountries.filter(el => el?.[0]?.toLowerCase().includes(e.target.value.toLowerCase()));
    setFilteredCountries(filtered);
  };

  const formatMaskLength = useMemo(
    () =>
      typeof country.format === 'string' &&
      country.format &&
      country?.format?.split('')?.filter(char => char === '.')?.length,

    [country]
  );

  const onChangeFieldValueHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputFieldValue = e.target.value;

    if (inputFieldValue.startsWith('+')) {
      const country = getCountry({ field: 'dialCode', value: e.target.value.replaceAll('+', '') });
      if (!country) {
        setFieldValue(inputFieldValue);
        onChange(inputFieldValue);

        return;
      }
      const trimmedValue = formatMaskLength ? inputFieldValue.substring(0, formatMaskLength) : inputFieldValue;
      setFieldValue(trimmedValue);
      handlePhoneValueChange(e);

      return;
    } else {
      const trimmedValue = formatMaskLength ? inputFieldValue.substring(0, formatMaskLength) : inputFieldValue;
      setFieldValue(trimmedValue);
      handlePhoneValueChange(e);
    }
  };

  const fieldValueToShow = fieldValue?.startsWith(`+${country.dialCode}`)
    ? fieldValue.substring(country?.dialCode?.length + 1)
    : fieldValue;

  return (
    <>
      <BaseFormField
        formInputProps={{
          ...rest,
          sx: {
            '.MuiInputBase-root': {
              pl: 0,
            },
          },
          placeholder: 'Enter a phone number',
          ref: inputRef,
          fullWidth: true,
          type: 'tel',
          value: fieldValueToShow,
          onChange: onChangeFieldValueHandler,

          InputProps: {
            startAdornment: (
              <InputAdornment position="start" sx={{ margin: 0 }}>
                <Stack
                  direction="row"
                  sx={{
                    borderRight: theme => `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <Stack height={1}>
                    <Button
                      sx={{
                        borderRadius: 0,
                        borderTopLeftRadius: '6px',
                        borderBottomLeftRadius: '6px',
                        px: '4px',
                        pl: '6px',
                        py: '9.5px',
                      }}
                      size="medium"
                      onClick={handleOpenPopover}
                      variant="text"
                    >
                      <FlagImage iso2={country.iso2} style={{ display: 'flex' }} />
                      <MDTypography variant="button" sx={{ ml: 1, fontWeight: 500 }}>
                        +{country.dialCode}
                      </MDTypography>
                      <KeyboardArrowDownRounded
                        sx={{
                          width: '20px !important',
                          height: '20px !important',
                          fill: 'inherit',
                          transition: 'transform 0.3s ease-in-out',
                          ...(openPopover && {
                            transform: 'rotate(-180deg)',
                          }),
                        }}
                      />
                    </Button>

                    <MenuPopover
                      arrow="top-left"
                      open={openPopover}
                      onClose={handleClosePopover}
                      sx={{ maxHeight: 270, maxWidth: 350, overflow: 'hidden', p: 2 }}
                    >
                      <BaseFormField
                        formInputProps={{
                          sx: {
                            '.MuiInputBase-input': {
                              fontSize: '14px',
                            },
                          },
                          fullWidth: true,
                          size: 'small',
                          value: searchValue,
                          placeholder: 'Search',
                          onChange: onChageSearchCountryHandler,
                          InputProps: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchOutlined fontSize="medium" />
                              </InputAdornment>
                            ),
                          },
                        }}
                      />
                      <Stack sx={{ maxHeight: 175, overflowY: 'auto' }}>
                        {searchValue && !filteredCountries.length ? (
                          <MDTypography textAlign="center" variant="body2">
                            No entries to show
                          </MDTypography>
                        ) : (
                          (searchValue && filteredCountries.length ? filteredCountries : defaultCountries).map(c => {
                            const country = parseCountry(c);
                            return (
                              <MenuItem key={country.iso2} onClick={onSelectCountryHandler} id={country.iso2}>
                                <Stack direction="row" alignItems="center" spacing={1} whiteSpace="initial">
                                  <FlagImage iso2={country.iso2} />
                                  <Typography variant="subtitle2" fontWeight={500}>
                                    {country.name}
                                  </Typography>

                                  <Typography color="gray" variant="body2">
                                    +{country.dialCode}
                                  </Typography>
                                </Stack>
                              </MenuItem>
                            );
                          })
                        )}
                      </Stack>
                    </MenuPopover>
                  </Stack>
                </Stack>
              </InputAdornment>
            ),
          },
        }}
        errorValue={errorValue}
      />
    </>
  );
});
