import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import { BaseFormField, ToastType, notice } from 'features/common';
import { BaseAutocompleteField } from 'features/common/components/BaseAutocompleteField';
import { CriticalUpdateVersion, OS, urgentVersionsApiService } from 'features/settings/UrgentVersionsApiService';
import { getOptionById } from 'features/common/helpers/utilities';
import { baseBackendErrorHandler } from 'features/common/errorHanders';
import { createUrgentVersionValidatationSchema, OS_OPTIONS, CreateUrgentVersionValidationScheme } from './form.config';

type CreateVersionMenuContentProps = {
  itemsPerPage: number;
  onCloseMenuHandler: () => void;
  setDataToStoreHandler: (data: {
    lastUrgentVersions: CriticalUpdateVersion[];
    urgentVersions: CriticalUpdateVersion[];
    totalItems: number;
  }) => void;
  currentPage: number;
};

export const CreateVersionMenuContent: FC<CreateVersionMenuContentProps> = ({
  itemsPerPage,
  onCloseMenuHandler,
  setDataToStoreHandler,
  currentPage,
}) => {
  const { createCriticalUpdateVersion, getCriticalUpdateVersions, getLastCriticalUpdateVersions } =
    urgentVersionsApiService();

  const {
    register,
    handleSubmit,
    control,
    setError,
    watch,
    formState: { errors, isValid, isSubmitting },
  } = useForm<CreateUrgentVersionValidationScheme>({
    resolver: yupResolver(createUrgentVersionValidatationSchema),
    mode: 'onTouched',
    defaultValues: {
      os: null,
      version: '',
    },
  });

  const osValue = getOptionById(watch('os'), OS_OPTIONS);

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      await createCriticalUpdateVersion({ os: formData.os, version: formData.version });
      const result = await Promise.all([
        getCriticalUpdateVersions({ page: currentPage, itemsPerPage }),
        getLastCriticalUpdateVersions(),
      ]);

      const versionsData = result?.[0]?.data;
      const lastVersionsData = result?.[1]?.data;
      onCloseMenuHandler();
      setDataToStoreHandler({
        lastUrgentVersions: lastVersionsData['hydra:member'],
        totalItems: versionsData?.['hydra:totalItems'],
        urgentVersions: versionsData?.['hydra:member'],
      });

      notice(ToastType.SUCCESS, 'Successfully created!');
    } catch (error) {
      baseBackendErrorHandler(error, { formError: { formData, setError } });
    }
  });

  return (
    <MDBox
      component="form"
      onSubmit={onFormSubmitHandler}
      my={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <Stack flex={1}>
        <Controller
          control={control}
          name="os"
          render={({ field: { onChange } }) => {
            const onChangeHandler = async (value: { '@id': OS; title: string }) => {
              onChange(value ? value['@id'] : '');
            };
            return (
              <BaseAutocompleteField
                options={OS_OPTIONS}
                getOptionLabel={option => option.title}
                value={osValue}
                onChangeHandler={onChangeHandler}
                errorMessage={errors['os']?.message}
                autocompleSxProps={{
                  width: 1,
                  '.MuiInputBase-root': {
                    py: '4.5px',
                  },
                }}
                formInputProps={{
                  label: 'Operation system',
                }}
                placeholder="Select an OS"
              />
            );
          }}
        />

        <BaseFormField
          formFieldProps={{
            pb: 0,
          }}
          formInputProps={{
            ...register('version'),
            type: 'text',
            label: 'Version',
            fullWidth: true,
          }}
          errorValue={errors['version']?.message}
        />
      </Stack>
      <MDButton
        type="submit"
        variant="gradient"
        color="info"
        isLoading={isSubmitting}
        disabled={!isValid || isSubmitting}
      >
        Create
      </MDButton>
    </MDBox>
  );
};
