import { FC } from 'react';
import { Card, CardContent, CardHeader, Checkbox, FormControlLabel, Stack, Switch } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import MDTypography from 'components/MDTypography';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormField } from 'features/common';
import { CREATE_CASE_FORM_STEPS, useCreateCase } from 'features/store';
import { NotesScheme, notesFormSchema } from './form.config';
import { ButtonsFooter } from './ButtonsFooter';

export const Notes: FC<{ isAttorneyView?: boolean }> = ({ isAttorneyView }) => {
  const { notesData, saveFormDataHandler, activeStep } = useCreateCase(state => ({
    notesData: state.notesData,
    caseInformationData: state.caseData,
    dependantInformationData: state.dependantData,
    documentsData: state.documentsData,
    activeStep: state.activeStep,
    saveFormDataHandler: state.setData,
    resetStateHandler: state.resetState,
  }));

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting, isDirty },
    watch,
    getValues,
  } = useForm<NotesScheme>({
    resolver: yupResolver(notesFormSchema),
    mode: 'onTouched',
    defaultValues: {
      ...notesData,
    },
  });

  const onFormSubmitHandler = handleSubmit(async formData =>
    saveFormDataHandler({ step: activeStep, data: formData, activeStep: CREATE_CASE_FORM_STEPS.CASE_REVIEW })
  );

  const { fields } = useFieldArray({
    name: 'notes',
    control,
  });

  const isAddNotes = watch('isAddNotes');

  const isDisabledSubmitButton = !isValid || isSubmitting;

  const onClickBackButtonHandler = () => {
    saveFormDataHandler({
      ...(isDirty && {
        step: CREATE_CASE_FORM_STEPS.NOTES,
        data: getValues(),
      }),
      activeStep: CREATE_CASE_FORM_STEPS.DOCUMENTS,
    });
  };

  return (
    <form onSubmit={onFormSubmitHandler}>
      <Card>
        <CardHeader sx={{ px: 3, pb: 0 }} title="Add Additional Notes" />
        <CardContent>
          <MDTypography variant="subtitle2">
            Feel free to add any extra notes that may assist with your case. By default, these notes are private.
          </MDTypography>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
            mb={3}
          >
            <MDTypography variant="button" color="text">
              Add a note?
            </MDTypography>

            <Switch {...register('isAddNotes')} checked={isAddNotes} />
          </Stack>
          <Stack spacing={1}>
            {watch('isAddNotes') && (
              <Stack spacing={0.5}>
                {fields?.map((field, index) => (
                  <Stack key={field.id} spacing={1}>
                    <BaseFormField
                      formInputProps={{
                        ...register(`notes.${index}.noteTitle` as const),
                        type: 'text',
                        label: 'Title',
                        placeholder: 'Enter note title',
                        fullWidth: true,
                        InputLabelProps: { shrink: true },
                      }}
                      errorValue={errors?.notes?.[index]?.noteTitle?.message}
                    />
                    <BaseFormField
                      formInputProps={{
                        ...register(`notes.${index}.noteBody` as const),
                        placeholder: 'Enter note description',
                        type: 'text',
                        label: 'Note',
                        fullWidth: true,
                        multiline: true,
                        rows: 4,
                        InputLabelProps: { shrink: true },
                      }}
                      errorValue={errors?.notes?.[index]?.noteBody?.message}
                    />
                    <Stack direction="row" justifyContent="space-between">
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...register(`notes.${index}.isVisible` as const)}
                            checked={watch('notes')[0].isVisible}
                          />
                        }
                        sx={{
                          display: 'flex',
                          mt: '0 !important',
                        }}
                        label={
                          <MDTypography variant="button" color="text">
                            {`Visible to the ${isAttorneyView ? 'client' : 'attorney'}`}
                          </MDTypography>
                        }
                      />
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            )}
          </Stack>

          <ButtonsFooter
            isSubmitButtonDisabled={isDisabledSubmitButton}
            onClickBackButtonHandler={onClickBackButtonHandler}
            isLoading={isSubmitting}
            saveButtonText="Case Review"
          />
        </CardContent>
      </Card>
    </form>
  );
};
