import { FC } from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import MDTypography from 'components/MDTypography';

type ViewAllOffersButtonProps = {
  onClickViewAllButtonHandler: () => void;
};

export const ViewAllOffersButton: FC<ViewAllOffersButtonProps> = ({ onClickViewAllButtonHandler }) => {
  return (
    <Grid item xs={12} sm={12} md={6} lg={2.3}>
      <Card
        sx={{
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
          height: 1,
          backgroundColor: 'grey.100',
          boxShadow: theme => theme.boxShadows.xxl,
          cursor: 'pointer',
          ':hover': {
            boxShadow: theme => theme.boxShadows.inset,
          },
        }}
        onClick={onClickViewAllButtonHandler}
      >
        <CardContent sx={{ p: 2, pb: '16px !important' }}>
          <MDTypography variant="subtitle1">View All</MDTypography>
        </CardContent>
      </Card>
    </Grid>
  );
};
