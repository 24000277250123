import { CircularProgress, Tooltip } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ClearIcon from '@mui/icons-material/Clear';
import DataTable from 'features/content-items/components/CustomPaginationTable';
import { TablePaginationFooter, TablePaginationFooterProps } from 'features/common/components/TablePaginationFooter';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { CaseLawTypeResponse } from '../services';
import { formatDate } from 'features/common/helpers/date';

export type CaseLawTypesTableProps = Pick<TablePaginationFooterProps, 'totalItems' | 'onChangePage'> & {
  onEditClick: (id: string, title: string) => void;
  onDeleteClick: (id: string) => void;
  caseLawTypesList: readonly CaseLawTypeResponse[];
  isLoading: boolean;
  entriesPerPage: number;
  onChangeEntriesPerPage: (value: number) => void;
  currentPage: number;
};

export const CaseLawTypesTable: React.FC<CaseLawTypesTableProps> = ({
  entriesPerPage,
  isLoading,
  currentPage,
  caseLawTypesList,
  totalItems,
  onChangePage,
  onChangeEntriesPerPage,
  onDeleteClick,
  onEditClick,
}) => {
  return (
    <MDBox minWidth="300px" minHeight="300px" justifyItems="center" alignItems="center" display="flex">
      {isLoading ? (
        <CircularProgress sx={{ mx: 'auto', mb: '16px' }} />
      ) : caseLawTypesList.length > 0 ? (
        <MDBox width="100%">
          <DataTable
            entriesPerPage={{ defaultValue: entriesPerPage }}
            onChangeEntriesPerPage={onChangeEntriesPerPage}
            isSorted={false}
            showTotalEntries={true}
            table={{
              columns: [
                { Header: 'Title', accessor: 'title', align: 'left' },
                { Header: 'Creation date', accessor: 'createdAt', align: 'left' },
                { Header: 'Last Update', accessor: 'updatedAt', align: 'left' },
                { Header: '', accessor: 'actionBtns' },
              ],
              rows: caseLawTypesList.map(caseLawTypeItem => ({
                id: caseLawTypeItem['@id'],
                title: (
                  <MDTypography noWrap={true} fontSize={14} maxWidth="350px" minWidth="100px">
                    {caseLawTypeItem.title}
                  </MDTypography>
                ),
                createdAt: caseLawTypeItem.createdAt ? formatDate(caseLawTypeItem.createdAt) : '-',
                updatedAt: caseLawTypeItem.updatedAt ? formatDate(caseLawTypeItem.updatedAt) : '-',
                actionBtns: (
                  <MDBox sx={{ display: 'flex', gap: '5px', width: '100%', justifyContent: 'flex-start' }}>
                    <Tooltip title="Edit selected case law type">
                      <MDButton
                        variant="outlined"
                        color="secondary"
                        size="small"
                        iconOnly
                        onClick={e => {
                          onEditClick(caseLawTypeItem['@id'].replace('/case_law_types/', ''), caseLawTypeItem.title);
                        }}
                      >
                        <ModeEditIcon />
                      </MDButton>
                    </Tooltip>
                    <Tooltip title="Delete selected case law type">
                      <MDButton
                        color="secondary"
                        variant="outlined"
                        size="small"
                        iconOnly
                        onClick={() => {
                          onDeleteClick(caseLawTypeItem['@id'].replace('/case_law_types/', ''));
                        }}
                      >
                        <ClearIcon />
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                ),
              })),
            }}
          />
          <TablePaginationFooter
            page={currentPage}
            currentItemsPerPage={entriesPerPage}
            totalItems={totalItems}
            onChangePage={onChangePage}
          />
        </MDBox>
      ) : (
        <MDTypography sx={{ mx: 'auto' }}>No Case Law Types were found...</MDTypography>
      )}
    </MDBox>
  );
};
