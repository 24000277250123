import { FC } from 'react';
import { useSubscription } from 'features/common/hooks/useSubscription';
import { SUBSCRIPTION_CARD_DATA_MAP } from '../config';
import { PricingCard } from '../templates/AttorneyProSubscriptionTemplate/PricingCard';
import { PAYMENT_PERIOD } from '../services/subscriptionsApiService';

type SubscriptionItemProps = {
  paymentPeriod: PAYMENT_PERIOD;
};

export const SubscriptionItem: FC<SubscriptionItemProps> = ({ paymentPeriod }) => {
  const { onGenerateSubscriptionUrlHandler, isLoading } = useSubscription();

  const redirectToCheckout = async () => {
    await onGenerateSubscriptionUrlHandler({ period: paymentPeriod });
  };

  const { badge, action, price, specifications, cardColor } = SUBSCRIPTION_CARD_DATA_MAP[paymentPeriod];

  return (
    <PricingCard
      sx={{ width: 1 }}
      color={cardColor || 'dark'}
      badge={badge}
      price={{ currency: '$', ...price }}
      specifications={specifications}
      action={{
        isLoading,
        onClickHandler: redirectToCheckout,
        ...action,
      }}
    />
  );
};
