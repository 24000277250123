import { FC } from 'react';
import { useUser } from 'features/common';
import { Navigate } from 'react-router-dom';
import { ROUTES } from 'features/common/variables/routes';

export const DashboardTemplate: FC = () => {
  const { isAdmin } = useUser();
  const redirectTo = isAdmin ? ROUTES.contentItems : ROUTES.myLegalCases;

  return <Navigate to={redirectTo} />;
};
