import { FC } from 'react';
import { Card, CardContent, CardHeader, CircularProgress, Grid, Stack } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MDTypography from 'components/MDTypography';
import MDBadge from 'components/MDBadge';
import { MenuPopover } from 'features/common/components/MenuPopover';
import { GetMyLegalCaseDetailsResponse } from 'features/case-details/CaseDetailsApiService';
import { Consultation } from 'features/common/services/legalCaseConsultationApiService';
import { Pagination } from 'features/case-law-types';
import { useUserProfile } from 'features/common/hooks/useUserProfile';
import { BaseStoreAsyncActionFunction } from 'features/store';
import { dateToCustomFormat } from 'features/common/helpers/date';
import { CaseInfoActionButton } from './CaseInfoActionButton';
import { UserOverviewContent } from './UserOverviewContent/UserOverviewContent';
import { ConsultationOfferCard } from './ConsultationOfferCard';
import { ViewAllOffersButton } from './ViewAllOffersButton';
import { DependantInfoItem } from '../DependantInfoItem';
import { DependantInfo } from '../DependantInfo';
import { CaseInfoHeader } from './CaseInfoHeader/CaseInfoHeader';
import ClioLogo from 'assets/images/icons/logo-clio.svg';
import { CRMIntegrationCaseInfo } from './CRMIntegrationCaseInfo';
import { SideMenu } from 'features/common';
import { CompanyInfoSideMenuContent } from 'features/my-company/components/CompanyInfoSideMenuContent';

type CaseInfoProps = {
  legalCase: GetMyLegalCaseDetailsResponse;
  isAttorney: boolean;
  consultations: Consultation[];
  caseId: string;
  representationId: string;
  isFeedView: boolean;
  isClosedCase: boolean;
  isAttorneyView: boolean;
  isViewAllConsultationsButton: boolean;
  isStatusUpdating: boolean;
  isAllowToOpenCaseStatusesMenu: boolean;
  handleOpenPopover: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  openPopover: HTMLElement;
  openEditFormMenu: () => void;
  onViewAllButtonHandler: () => Promise<void>;
  getConsultationsListHandler: (caseId: string, params?: Pagination) => Promise<void>;
  declineAcceptedConsultationHandler: () => Promise<void>;
  approveRepresentationsHandler: BaseStoreAsyncActionFunction;
  declineRepresentationsHandler: BaseStoreAsyncActionFunction;
  updateLegalCaseHandler: (payload: Partial<GetMyLegalCaseDetailsResponse>) => void;
  approveConsultationHandler: BaseStoreAsyncActionFunction;
  declineConsultationHandler: BaseStoreAsyncActionFunction;
};

export const CaseInfo: FC<CaseInfoProps> = ({
  getConsultationsListHandler,
  declineAcceptedConsultationHandler,
  approveRepresentationsHandler,
  declineRepresentationsHandler,
  approveConsultationHandler,
  declineConsultationHandler,
  updateLegalCaseHandler,
  onViewAllButtonHandler,
  openEditFormMenu,
  handleOpenPopover,
  openPopover,
  isAllowToOpenCaseStatusesMenu,
  isViewAllConsultationsButton,
  isStatusUpdating,
  representationId,
  caseId,
  consultations,
  legalCase,
  isAttorney,
  isClosedCase,
  isFeedView,
  isAttorneyView,
}) => {
  const isInvitationDataDisplayable = legalCase?.invitation && !legalCase?.client && isAttorney;
  const {
    isUserOverviewMenuOpen,
    isUserProfileOverviewLoading,
    onCloseUserOverviewHandler,
    onUserAvatarClickHandler,
    userRatings,
    userProfileOverview,
    onOpenUserOverviewHandler,
    isOpenCompanyInfoMenu,
    onClickViewCompanyProfileButtonHandler,
    onCloseCompanyInfoMenuHandler,
  } = useUserProfile();

  const caseCreatedDate = dateToCustomFormat(legalCase?.createdAt);
  return (
    <>
      {consultations.length > 0 && !isAttorneyView && !isFeedView && !legalCase?.attorney && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {consultations?.slice(0, isViewAllConsultationsButton ? 3 : 4).map(consultation => (
              <Grid
                key={consultation?.id}
                item
                xs={12}
                sm={12}
                md={consultations?.length === 1 ? 4 : 6}
                lg={consultations?.length === 1 ? 4 : isViewAllConsultationsButton ? 3.2 : 3}
              >
                <ConsultationOfferCard
                  createdAt={consultation?.createdAt}
                  offerId={consultation?.id}
                  firstName={consultation?.owner?.userProfile?.firstName}
                  lastName={consultation?.owner?.userProfile?.lastName}
                  logoURL={consultation?.owner?.logo?.publicUrl}
                  approveLegalCaseOfferHandler={approveConsultationHandler}
                  declineLegalCaseOfferHandler={declineConsultationHandler}
                  consultationOwnerFirebaseId={consultation?.owner?.firebaseUser}
                  onUserAvatarClickHandler={onUserAvatarClickHandler}
                />
              </Grid>
            ))}

            {isViewAllConsultationsButton && (
              <ViewAllOffersButton onClickViewAllButtonHandler={onViewAllButtonHandler} />
            )}
          </Grid>
        </Grid>
      )}

      {(legalCase?.attorney || isAttorney) && (
        <Grid item xs={12}>
          <Card>
            <CardContent sx={{ pt: 2, px: 1, pb: 0 }}>
              <CaseInfoHeader
                caseNumber={legalCase?.caseNumber}
                isCaseWithAttorney={!!legalCase?.attorney}
                consultationData={{
                  isCaseOnConsultation: legalCase?.onConsultation,
                  consultationCreatedTime: consultations?.[0]?.createdAt || '',
                  getConsultationsHandler: getConsultationsListHandler,
                  declineAcceptedConsultationHandler: declineAcceptedConsultationHandler,
                }}
                approveRepresentationsHandler={approveRepresentationsHandler}
                declineRepresentationsHandler={declineRepresentationsHandler}
                representationId={representationId}
                userData={{
                  firstName: isInvitationDataDisplayable
                    ? legalCase.invitation.firstName
                    : legalCase?.[isAttorney ? 'client' : 'attorney']?.userProfile?.firstName,
                  lastName: isInvitationDataDisplayable
                    ? legalCase.invitation.lastName
                    : legalCase?.[isAttorney ? 'client' : 'attorney']?.userProfile?.lastName,
                  rating: legalCase?.[isAttorney ? 'client' : 'attorney']?.rating,
                  caseCount: legalCase?.[isAttorney ? 'client' : 'attorney']?.legalCaseCount,
                  logoURL: legalCase?.[isAttorney ? 'client' : 'attorney']?.logo?.publicUrl,
                  userFirebaseId: legalCase?.[isAttorney ? 'client' : 'attorney']?.firebaseUser,
                }}
                companyShortName={isAttorney ? '' : legalCase?.attorney?.company?.shortName}
                isClosedCase={isClosedCase}
                caseId={caseId}
                updateLegalCaseHandler={updateLegalCaseHandler}
                onUserAvatarClickHandler={legalCase?.invitation ? onOpenUserOverviewHandler : onUserAvatarClickHandler}
                isFeedView={isFeedView}
                isAllowToExport={legalCase?.crmIntegrationData?.canBeExport}
                isInvitation={!!legalCase?.invitation}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
      <Grid item xs={12} md={isFeedView ? 12 : 7} lg={isFeedView ? 12 : 8}>
        <Card>
          {isStatusUpdating ? (
            <Stack
              alignItems="center"
              flex={1}
              justifyContent="center"
              sx={{
                minHeight: 300,
              }}
            >
              <CircularProgress />
            </Stack>
          ) : (
            <Card sx={{ boxShadow: 'none', width: 1 }}>
              <CardHeader
                title="Case Details"
                action={
                  isFeedView ? null : (
                    <CaseInfoActionButton
                      onClickButtonHandler={openEditFormMenu}
                      isDisabledButton={legalCase?.status?.type === 'done' || isAttorneyView || !!legalCase?.attorney}
                    />
                  )
                }
              />
              <CardContent sx={{ pb: '8px important', px: 2 }}>
                <Stack mb={1}>
                  <Stack direction="row" justifyContent="space-between">
                    <MDTypography fontWeight="medium">{legalCase?.caseNumber}</MDTypography>

                    <MDBadge
                      size="xs"
                      onClick={
                        isAttorneyView && !!legalCase?.attorney && !legalCase?.onConsultation
                          ? handleOpenPopover
                          : undefined
                      }
                      sx={{
                        '.MuiBadge-badge': {
                          background: legalCase?.status?.lightThemeBgColor,
                        },
                        ...(isAllowToOpenCaseStatusesMenu && {
                          ':hover': {
                            cursor: 'pointer',
                          },
                        }),
                      }}
                      badgeContent={
                        <>
                          {legalCase?.status?.title}
                          {isAllowToOpenCaseStatusesMenu && (
                            <KeyboardArrowDownIcon
                              fontSize="small"
                              sx={{
                                ml: 0.2,
                                transition: 'transform 0.3s ease-in-out',
                                ...(openPopover && {
                                  transform: 'rotate(-180deg)',
                                }),
                              }}
                            />
                          )}
                        </>
                      }
                      container
                    />
                  </Stack>
                  {legalCase?.crmIntegrationData?.isClioLinked && (
                    <CRMIntegrationCaseInfo
                      logoSrc={ClioLogo}
                      originalCaseNumber={legalCase?.crmIntegrationData?.clioOriginalNumber}
                    />
                  )}
                </Stack>
                <Stack
                  spacing={1}
                  sx={{ ...(!legalCase.caseForDependant && { maxHeight: 310, overflowY: 'auto' }), pr: 1 }}
                >
                  <Stack spacing={3}>
                    <MDTypography
                      fontWeight="light"
                      sx={{
                        ...(legalCase.caseForDependant && {
                          maxHeight: 200,
                          overflowY: 'auto',
                          pr: 1,
                        }),
                      }}
                    >
                      {legalCase?.description}
                    </MDTypography>

                    <Stack>
                      <DependantInfoItem title="Law type" titleValue={legalCase?.caseLawType?.title} />
                      <DependantInfoItem title="Case state" titleValue={legalCase?.state?.name} />
                      <DependantInfoItem title="Case county" titleValue={legalCase?.county?.name} />
                      <DependantInfoItem title="Created At" titleValue={caseCreatedDate} />
                    </Stack>

                    {legalCase?.caseForDependant && (
                      <Stack>
                        <MDTypography fontWeight="medium">Dependant information</MDTypography>
                        <DependantInfo
                          dependantFirstName={legalCase?.dependantFirstName}
                          dependantLastName={legalCase?.dependantLastName}
                          dependantStateOfResidence={legalCase?.dependantStateOfResidence?.name}
                          dependantUnder18={legalCase?.dependantUnder18}
                        />
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          )}
        </Card>
      </Grid>

      <MenuPopover open={isUserOverviewMenuOpen} onClose={onCloseUserOverviewHandler} arrow="top-left">
        <UserOverviewContent
          attorneyDescription={!isAttorney ? userProfileOverview?.attorneyProfile?.description : ''}
          lawTypes={!isAttorney ? userProfileOverview?.attorneyProfile?.caseLawTypes : null}
          country={userProfileOverview?.userProfile?.country?.name || legalCase.invitation?.country?.name}
          state={userProfileOverview?.userProfile?.state?.name || legalCase.invitation?.state?.name}
          isLoading={isUserProfileOverviewLoading}
          userRatings={!isAttorney ? userRatings : null}
          workingHours={!isAttorney ? userProfileOverview?.attorneyProfile?.workingHours : null}
          companyInfo={{
            companyFullName: userProfileOverview?.company?.fullName,
            companyLogo: userProfileOverview?.company?.logo?.publicUrl,
          }}
          onClickViewCompanyProfileButtonHandler={onClickViewCompanyProfileButtonHandler}
        />
      </MenuPopover>

      <SideMenu
        customWidth={450}
        isOpen={isOpenCompanyInfoMenu}
        onClose={onCloseCompanyInfoMenuHandler}
        title="Company profile"
      >
        <CompanyInfoSideMenuContent companyId={userProfileOverview?.company?.['@id']} />
      </SideMenu>
    </>
  );
};
