import { useEffect, useState } from 'react';
import { Card, Chip, Stack } from '@mui/material';
import MDButton from 'components/MDButton';
import { UploadAvatar } from 'features/common/components/UploadAvatar';
import { createFileService } from 'features/common/services/filesService';
import { useFirebaseUser } from 'features/auth/contexts/FirebaseUserContextProvider';
import { useSubscription } from 'features/common/hooks/useSubscription';
import { DialogWindow } from 'features/common/components/DialogWindow';
import {
  GetAttorneyProfileResponse,
  ToastType,
  createAttorneyService,
  createBackendUserService,
  notice,
  useBoolean,
  useUser,
} from '../../common';
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import { ProfileForm } from '../components';
import { AttorneyProfileForm } from '../components/AttorneyProfileForm';
import { AttorneyWorkingHoursForm } from '../components/AttorneyWorkingHoursForm/AttorneyWorkingHoursForm';
import { TemplateWrapper } from './TemplateWrapper';

export const UserProfileTemplate = (): JSX.Element => {
  const { createFile } = createFileService();
  const { putBackendUserLogo } = createBackendUserService();
  const { getAttorneyProfileById } = createAttorneyService();

  const [isAvatarLoading, setIsAvatarLoading] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState(null);

  const [attorneyData, setAttorneyData] = useState<GetAttorneyProfileResponse | null>(null);
  const { backendUser, isAttorney, isAdmin, connectBackendUserHandler } = useUser();
  const { firebaseUser } = useFirebaseUser();

  const attorneyId = backendUser?.attorneyProfile?.['@id'];

  const {
    isBuySubscriptionButton,
    isCancellSubscriptionButton,
    isUnsubscribing,
    subscriptionPlanLabel,
    onClickBuySubscription,
    onClickCancellSubscription,
    onGetUserSubscriptionStatus,
  } = useSubscription();

  const connectAttorneyProfile = async () => {
    try {
      const attorneyProfile = await getAttorneyProfileById(attorneyId.replace('/attorney_profiles/', ''));
      setAttorneyData(attorneyProfile);
    } catch (error) {
      console.error('error');
      notice(ToastType.ERROR, 'Failed to get attorney profile, please try again!');
    }
  };

  const connectAvatar = async (file: File) => {
    const fileFormData = new FormData();

    fileFormData.append('file', file);
    fileFormData.append('type', 'image');
    fileFormData.append('bucketType', 'public');
    fileFormData.append('title', '');

    try {
      setIsAvatarLoading(true);
      const createdLogoResponse = await createFile(fileFormData);
      await putBackendUserLogo(firebaseUser.uid, { logo: createdLogoResponse.data['@id'] });
      setIsAvatarLoading(false);

      setAvatarPreview(createdLogoResponse?.data.publicUrl);
      connectBackendUserHandler();
      notice(ToastType.SUCCESS, 'Avatar successfully updated!');
    } catch (error) {
      setIsAvatarLoading(false);
      setAvatarPreview(backendUser?.logo?.publicUrl);
      notice(ToastType.ERROR, 'Something went wrong with avatar update!');
    }
  };

  const handleAvatarUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files[0];
    const url = URL.createObjectURL(fileUploaded);
    setAvatarPreview(url);
    connectAvatar(fileUploaded);
  };

  useEffect(() => {
    if (attorneyId && !attorneyData) connectAttorneyProfile();
  }, [attorneyData]);

  useEffect(() => {
    if (backendUser?.logo) {
      setAvatarPreview(backendUser?.logo?.publicUrl);
    }
  }, [backendUser]);

  useEffect(() => {
    if (isAttorney) onGetUserSubscriptionStatus();
    connectBackendUserHandler();
  }, []);

  const [isOpen, setOpen, setClose] = useBoolean(false);

  return (
    <TemplateWrapper>
      <DialogWindow
        onCloseDialogHandler={setClose}
        onApproveDialogHandler={async () => {
          await onClickCancellSubscription();
          setClose();
        }}
        isDialogOpen={isOpen}
        dialogTitle="Are you sure you want to cancel subscription?"
        isLoading={isUnsubscribing}
      />
      <Stack spacing={3}>
        <Card>
          <Stack
            p={2}
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <UploadAvatar
                isDisabled={isAvatarLoading}
                isLoading={isAvatarLoading}
                onInputFileUploadHandler={handleAvatarUpload}
                logoURL={avatarPreview}
              />
              <Stack>
                <MDTypography variant="h5" fontWeight="medium">
                  {`${backendUser?.userProfile.firstName} ${backendUser?.userProfile.lastName}`}
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="medium">
                  {isAdmin ? 'Administrator' : isAttorney ? 'Attorney' : 'Client'}
                </MDTypography>
              </Stack>
            </Stack>

            {isBuySubscriptionButton && (
              <MDButton variant="contained" color="info" onClick={onClickBuySubscription}>
                Buy subscription
              </MDButton>
            )}

            {isCancellSubscriptionButton && (
              <Stack spacing={1} alignItems="center" sx={{ button: { maxWidth: 200 } }}>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                  <MDTypography variant="button" fontWeight="medium">
                    Subscription plan:
                  </MDTypography>
                  <Chip size="small" color="info" label={subscriptionPlanLabel} />
                </Stack>
                <MDButton size="small" variant="contained" color="error" onClick={setOpen}>
                  Cancel subscription
                </MDButton>
              </Stack>
            )}
          </Stack>
        </Card>
        <Card>
          <MDBox pl={3} pt={3}>
            <MDTypography variant="h5">Profile</MDTypography>
          </MDBox>
          <MDBox p={3}>
            <ProfileForm />
          </MDBox>
        </Card>

        {attorneyId && (
          <Stack spacing={3}>
            <Card>
              <MDBox pl={3} pt={3}>
                <MDTypography variant="h5">Attorney profile</MDTypography>
              </MDBox>
              <MDBox p={3}>
                <AttorneyProfileForm attorneyProfile={attorneyData} connectAttorneyProfile={connectAttorneyProfile} />
              </MDBox>
            </Card>

            <Card>
              <MDBox pl={3} pt={3}>
                <MDTypography variant="h5">Attorney Hours of Operation</MDTypography>
              </MDBox>
              <MDBox p={3}>
                <AttorneyWorkingHoursForm attorneyProfile={attorneyData} />
              </MDBox>
            </Card>
          </Stack>
        )}
      </Stack>
    </TemplateWrapper>
  );
};
