import { FC, useEffect } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import { SideMenu } from 'features/common';
import { TablePaginationFooter } from 'features/common/components/TablePaginationFooter';
import CustomPaginationTable from 'features/content-items/components/CustomPaginationTable';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { USERS_TABLE_COLUMNS } from './userTable.config';
import { UserInfoContent } from './UserInfoContent/UserInfoContent';
import { GetUsersParams } from '../UsersManagementApiService';
import { userManagementState } from '../hooks/userManagement.state';

export type UsersTableProps = {
  fetchParams?: GetUsersParams;
  isAdminPage?: boolean;
  isAttorneyPage?: boolean;
  emptyStateMessage?: 'No clients' | 'No attorneys' | 'No admins';
};

export const UsersTable: FC<UsersTableProps> = ({
  isAdminPage,
  isAttorneyPage,
  fetchParams,
  emptyStateMessage = 'No clients',
}) => {
  const {
    currentPage,
    currentUser,
    isLoading,
    isSearching,
    searchValue,
    isMoreInfoMenuOpen,
    users,
    entriesPerPage,
    onChangeEntriesPerPageHandler,
    onChangePageHandler,
    onCloseInfoMenuHandler,
    onMoreInfoIconClickHandler,
    onSearchUsersHandler,
    onGetUsersHandler,
    totalItems,
    attorneyProfileCaseLawType,
  } = userManagementState({ fetchParams, isAdminPage, isAttorneyPage });

  useEffect(() => {
    onGetUsersHandler();
  }, [entriesPerPage]);

  return (
    <MDBox minWidth="300px" minHeight="300px" justifyItems="center" alignItems="center" display="flex">
      {isLoading ? (
        <Stack width={1} alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (users.length || searchValue || isSearching) && !isLoading ? (
        <MDBox width="100%">
          <CustomPaginationTable
            entriesPerPage={{ defaultValue: entriesPerPage }}
            emptySearchStateMessage={emptyStateMessage}
            onChangeEntriesPerPage={onChangeEntriesPerPageHandler}
            onChangeSearchHandler={onSearchUsersHandler}
            isSearching={isSearching}
            isSorted={false}
            canSearch
            hiddenColumns={isAdminPage ? ['legalCaseCount'] : []}
            showTotalEntries
            table={{
              columns: USERS_TABLE_COLUMNS,
              rows: users?.map(user => ({
                legalCaseCount: user?.legalCaseCount,
                userData: {
                  logoURL: user?.logo?.publicUrl,
                  roles: user?.roles,
                  companyName: user?.company?.shortName,
                  firstName: user?.userProfile.firstName,
                  lastName: user?.userProfile?.lastName,
                  id: user?.userProfile?.['@id'],
                },
                createdAt: user?.createdAt,
                email: user?.email,
                phoneNumber: user?.phone,
                subscriptionStatus: user?.userProfile?.subscriptionStatus,
                actionBtns: {
                  onMoreInfoIconClickHandler,
                  id: user?.firebaseUser,
                },
              })),
            }}
          />
          {users.length ? (
            <TablePaginationFooter
              page={currentPage}
              currentItemsPerPage={entriesPerPage}
              totalItems={totalItems}
              onChangePage={onChangePageHandler}
            />
          ) : null}
        </MDBox>
      ) : !isSearching && !searchValue && !users.length ? (
        <MDTypography sx={{ mx: 'auto' }}>{emptyStateMessage + ' were found...'}</MDTypography>
      ) : null}

      <SideMenu isOpen={isMoreInfoMenuOpen} onClose={onCloseInfoMenuHandler} title="User info">
        <MDBox display="flex" flexDirection="column" height={1} justifyContent="center">
          {!currentUser ? (
            <CircularProgress sx={{ mx: 'auto', mb: '16px' }} />
          ) : (
            <UserInfoContent
              personTitle={currentUser?.userProfile?.personTitle}
              fullName={`${currentUser?.userProfile?.firstName} ${currentUser?.userProfile?.lastName}`}
              email={currentUser?.email}
              phone={currentUser?.phone}
              country={currentUser?.userProfile?.country?.name}
              state={currentUser?.userProfile?.state?.name}
              town={currentUser?.userProfile?.town}
              companyName={currentUser?.company?.shortName}
              caseAmount={isAdminPage ? null : currentUser?.legalCaseCount}
              rating={currentUser?.rating}
              barNumber={currentUser?.attorneyProfile?.barNumber}
              caseLawTypes={attorneyProfileCaseLawType}
            />
          )}
        </MDBox>
      </SideMenu>
    </MDBox>
  );
};
