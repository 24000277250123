import { FC } from 'react';
import { Stack, Theme } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MDBadge from 'components/MDBadge';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { HighlightBar } from 'features/common/components/HighlightBar';
import { useRouter } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';
import { dateToCustomFormat } from 'features/common/helpers/date';
import { ClientLegalCase } from '../MyClientsApiService';

type ClientCaseCardProps = Pick<ClientLegalCase, 'id' | 'caseNumber' | 'description' | 'createdAt' | 'updatedAt'> & {
  countyShortName: string;
  stateName: string;
  countryId: string;
  isDocusignRequest: boolean;
  caseLawTypeTitle: string;
  clientFirebaseId: string;
};

export const ClientCaseCard: FC<ClientCaseCardProps> = ({
  caseNumber,
  countryId,
  countyShortName,
  createdAt,
  description,
  id,
  stateName,
  caseLawTypeTitle,
  isDocusignRequest,
  clientFirebaseId,
  updatedAt,
}) => {
  const router = useRouter();

  const onClickOnCardHandler = (event: React.MouseEvent<HTMLDivElement>) =>
    router.push(`${ROUTES.myLegalCases}/${event.currentTarget.id}`);

  const onRedirectToChatHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    router.navigate(ROUTES.legalCaseChat, {
      state: {
        caseNumber,
        userFirebaseId: clientFirebaseId,
        prevRoute: router.pathname,
      },
    });
  };

  return (
    <MDBox
      key={id}
      component="div"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor="grey-100"
      borderRadius="lg"
      id={id}
      sx={{
        cursor: 'pointer',
        position: 'relative',
        p: 1.5,
        mt: 2,
        height: 1,
      }}
      onClick={onClickOnCardHandler}
    >
      {isDocusignRequest && <HighlightBar />}
      <Stack spacing={1.5} width={1} height={1}>
        <Stack direction="row" spacing={1.5} justifyContent="space-between" alignItems="center">
          <Stack>
            <MDTypography
              variant="button"
              sx={{
                fontWeight: 800,
              }}
            >
              {caseNumber}
            </MDTypography>
            {updatedAt && <MDTypography variant="button">{dateToCustomFormat(updatedAt)}</MDTypography>}
          </Stack>

          <MDBox
            component="div"
            sx={{
              display: 'flex',
              p: '2px',
              borderRadius: 1,
              ':hover': {
                boxShadow: theme => theme.boxShadows.navbarBoxShadow,
              },
            }}
            onClick={onRedirectToChatHandler}
          >
            <EmailOutlinedIcon fontSize="medium" />
          </MDBox>
        </Stack>
        <MDBadge
          size="xs"
          sx={{
            '.MuiBadge-badge': {
              background: (theme: Theme) => theme.palette.grey[700],
            },
          }}
          badgeContent={caseLawTypeTitle}
          container
        />
        <MDTypography
          variant="button"
          sx={{
            fontWeight: 400,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 4,
            MozLineClamp: 4,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            flex: 1,
          }}
        >
          {description}
        </MDTypography>
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          width={1}
          justifyContent="space-between"
        >
          <MDTypography variant="button" sx={{ display: 'flex', flex: 1, mr: 4 }}>
            {`${countyShortName}, ${stateName}, ${countryId}`}
          </MDTypography>

          <MDTypography variant="button">{dateToCustomFormat(createdAt)}</MDTypography>
        </Stack>
      </Stack>
    </MDBox>
  );
};
