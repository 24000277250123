import { FC } from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

type IllustrationtitleProps = {};

export const Illustrationtitle: FC<IllustrationtitleProps> = ({}) => {
  return (
    <MDBox
      position="absolute"
      top="18%"
      sx={{ left: '50%', transform: 'translateX(-50%)', width: 1, textAlign: 'center' }}
    >
      <MDTypography
        variant="h2"
        sx={{
          textTransform: 'uppercase',
          color: ({ palette }) => palette.info.main,
          fontSize: 44,
          letterSpacing: 16,
          ml: '5px',
        }}
      >
        legal beagle
      </MDTypography>
    </MDBox>
  );
};
