import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CircularProgress, Stack } from '@mui/material';
import { ToastType, adobesignApiService, notice, useRouter, useUser } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';

export const AdobeSignCallBackController = () => {
  const { connectBackendUserHandler } = useUser();
  const [searchParams, _] = useSearchParams();
  const router = useRouter();
  const { connectAdobeSign } = adobesignApiService();

  const connectAdobeSignHandler = async () => {
    const code = searchParams.get('code');
    const state = searchParams.get('state');

    if (!code && !state) {
      router.back();
      return;
    }

    try {
      await connectAdobeSign({
        code,
        state,
        redirectUrl: `${process.env.REACT_APP_BASE_APP_URL}/${ROUTES.adobeSignRedirect}`,
      });
      notice(ToastType.SUCCESS, 'Successfully connected AdobeSign to your account');
    } catch (error) {
      console.error({ error });
      notice(ToastType.ERROR, 'Something went wrong with AdobeSign connection');
    }

    router.push(ROUTES.integrations).then(() => {
      connectBackendUserHandler();
    });
  };

  useEffect(() => {
    connectAdobeSignHandler();
  }, []);

  return (
    <Stack sx={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress />
    </Stack>
  );
};
