import { useCallback, useState } from 'react';
import { usePopover } from './usePopover';
import { GetUserReviewsResponse } from 'features/review/services';
import { GetUserByIdResponse, createBackendUserService } from '../services';
import { ToastType, notice } from '../modules';
import { useBoolean } from './useBoolean';

export const useUserProfile = () => {
  const [userProfileOverview, setUserProfileOverview] = useState<null | GetUserByIdResponse>(null);
  const [userRatings, setUserRatings] = useState<null | GetUserReviewsResponse[]>(null);
  const [isUserProfileOverviewLoading, setIsUserProfileOverviewLoading] = useState(false);

  const [isOpenCompanyInfoMenu, onOpenCompanyInfoMenu, onCloseCompanyInfoMenu] = useBoolean(false);

  const onCloseCompanyInfoMenuHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onCloseCompanyInfoMenu();
  };

  const onClickViewCompanyProfileButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onOpenCompanyInfoMenu();
    onCloseUserOverviewHandler();
  };

  const {
    handleClosePopover: onCloseUserOverviewHandler,
    handleOpenPopover: onOpenUserOverviewHandler,
    openPopover: isUserOverviewMenuOpen,
  } = usePopover();

  const { getUserById, getUserRating } = createBackendUserService();

  const onUserAvatarClickHandler = useCallback(async (event: React.MouseEvent<HTMLDivElement>) => {
    try {
      setIsUserProfileOverviewLoading(true);
      onOpenUserOverviewHandler(event);
      const id = event.currentTarget.id;
      const { data } = await getUserById(id);
      const { data: userRatingData } = await getUserRating(id, { active: true, page: 1, itemsPerPage: 5 });
      setUserProfileOverview(data);
      setUserRatings(userRatingData['hydra:member']);
      setIsUserProfileOverviewLoading(false);
    } catch (error) {
      setIsUserProfileOverviewLoading(false);
      userProfileOverview && setUserProfileOverview(null);
      notice(ToastType.ERROR, 'Failed to get user info, try again!');
      console.error(error);
    }
  }, []);

  return {
    isUserProfileOverviewLoading,
    onUserAvatarClickHandler,
    userRatings,
    isUserOverviewMenuOpen,
    userProfileOverview,
    onCloseUserOverviewHandler,
    onOpenUserOverviewHandler,
    onClickViewCompanyProfileButtonHandler,
    isOpenCompanyInfoMenu,
    onCloseCompanyInfoMenuHandler,
  };
};
