import { FC } from 'react';
import { Card, CardContent } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { AgoraEventType } from 'features/common/services/agoraEventApiService';

export const PermissionsCard: FC<{ eventType: AgoraEventType }> = ({ eventType }) => {
  return (
    <Card sx={{ margin: '0 auto', display: 'flex', alignSelf: 'center', minHeight: 300, justifyContent: 'center' }}>
      <CardContent>
        <MDTypography mb={1} textAlign="center" variant="h3">{`${
          eventType === 'video_call' ? 'Camera and Microphone' : 'Microphone'
        } access required`}</MDTypography>
        <MDTypography
          textAlign="center"
          variant="h5"
          color="text"
        >{`In order to continue you must allow access to your ${
          eventType === 'video_call' ? 'camera and microphone' : 'microphone'
        }.`}</MDTypography>
      </CardContent>
    </Card>
  );
};
