import { useState } from 'react';
import { CircularProgress, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Check } from '@mui/icons-material';
import { TablePaginationFooter } from 'features/common/components/TablePaginationFooter';
import { SideMenu, ToastType, notice, useBoolean } from 'features/common';
import DataTable from 'features/content-items/components/CustomPaginationTable';
import { formatDate } from 'features/common/helpers/date';
import { DialogWindow } from 'features/common/components/DialogWindow';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { useSuggestions } from '../hooks';
import { CaseLawTypeResponse } from '../services';
import { ApproveLawTypeMenuContent } from './ApproveLawTypeMenuContent';

export const SuggestionsTable: React.FC = () => {
  const [selectedCaseId, setSelectedCaseId] = useState('');
  const [isCaseLawTypesDeleteModalOpen, openCaseLawTypesDeleteModal, closeCaseLawTypesDeleteModal] = useBoolean(false);

  const {
    suggestionsList,
    suggestionsTotal,
    entriesPerPage,
    isLoading,
    currentPage,
    patchCaseLawTypeSuggestions,
    onChangeEntriesPerPageHandler,
    onChangePageHandler,
    getItemsHandler,
    setIsLoading,
    deleteCaseLawTypeById,
    onDeleteItem,
  } = useSuggestions();

  const onApproveCaseLawSuggestion = async (id: string) => {
    try {
      const nextPage = onDeleteItem() || currentPage;
      await patchCaseLawTypeSuggestions(id, { pending: false });
      setIsLoading(true);
      await getItemsHandler({ page: nextPage });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong with suggestion approve!');
    }
  };

  const onDeclineSuggestion = async () => {
    try {
      const nextPage = onDeleteItem();
      setIsLoading(true);
      closeCaseLawTypesDeleteModal();
      await deleteCaseLawTypeById(selectedCaseId);
      await getItemsHandler({ page: nextPage });
      setIsLoading(false);
      notice(ToastType.SUCCESS, 'Case law suggestion successfully declined!');
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong with suggestion decline!');
    }
  };

  const onDecline = (id: string) => {
    setSelectedCaseId(id);
    openCaseLawTypesDeleteModal();
  };

  const [isApproveLawTypeMenuOpen, onOpenApproveLawTypeMenuHandler, onCloseApproveLawTypeMenuHandler] =
    useBoolean(false);

  const [selectedLawType, setSelectedLawType] = useState<null | CaseLawTypeResponse>(null);

  const onClickApproveButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    const selectedLawType = suggestionsList?.find(el => el['@id'] === event.currentTarget.id);
    setSelectedLawType(selectedLawType);
    onOpenApproveLawTypeMenuHandler();
  };

  return (
    <MDBox minWidth="300px" minHeight="300px" justifyItems="center" alignItems="center" display="flex">
      {isLoading ? (
        <CircularProgress sx={{ mx: 'auto', mb: '16px' }} />
      ) : suggestionsList.length > 0 ? (
        <MDBox width="100%">
          <DataTable
            entriesPerPage={{ defaultValue: entriesPerPage }}
            onChangeEntriesPerPage={onChangeEntriesPerPageHandler}
            isSorted={false}
            showTotalEntries={true}
            table={{
              columns: [
                { Header: 'Title', accessor: 'title', align: 'left' },
                { Header: 'Creation date', accessor: 'createdAt', align: 'left' },
                { Header: '', accessor: 'actionBtns' },
              ],
              rows: suggestionsList.map(caseLawTypeItem => ({
                id: caseLawTypeItem['@id'],
                title: (
                  <MDTypography noWrap={true} fontSize={14} maxWidth="350px" minWidth="100px">
                    {caseLawTypeItem.title}
                  </MDTypography>
                ),
                createdAt: caseLawTypeItem.createdAt ? formatDate(caseLawTypeItem.createdAt) : '-',
                updatedAt: caseLawTypeItem.updatedAt ? formatDate(caseLawTypeItem.updatedAt) : '-',
                actionBtns: (
                  <MDBox sx={{ display: 'flex', gap: '5px', width: '100%', justifyContent: 'flex-start' }}>
                    <Tooltip title="Approve case law suggestion">
                      <MDButton
                        id={caseLawTypeItem?.['@id']}
                        variant="outlined"
                        color="secondary"
                        iconOnly
                        onClick={onClickApproveButtonHandler}
                      >
                        <Check />
                      </MDButton>
                    </Tooltip>
                    <Tooltip title="Decline case law suggestion">
                      <MDButton
                        color="secondary"
                        variant="outlined"
                        iconOnly
                        onClick={() => {
                          onDecline(caseLawTypeItem['@id'].replace('/case_law_types/', ''));
                        }}
                      >
                        <ClearIcon />
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                ),
              })),
            }}
          />
          <TablePaginationFooter
            page={currentPage}
            currentItemsPerPage={entriesPerPage}
            totalItems={suggestionsTotal}
            onChangePage={onChangePageHandler}
          />
        </MDBox>
      ) : (
        <MDTypography sx={{ mx: 'auto' }}>No suggestions were found...</MDTypography>
      )}

      <DialogWindow
        onCloseDialogHandler={closeCaseLawTypesDeleteModal}
        onApproveDialogHandler={onDeclineSuggestion}
        isDialogOpen={isCaseLawTypesDeleteModalOpen}
        dialogTitle="Are you sure want to decline this Case Law suggestion?"
      />

      <SideMenu
        isOpen={isApproveLawTypeMenuOpen}
        onClose={onCloseApproveLawTypeMenuHandler}
        title="Approve law type suggestion"
        customWidth={450}
      >
        <ApproveLawTypeMenuContent
          selectedLawTypeId={selectedLawType?.['@id']}
          selectedLawTypeTitle={selectedLawType?.title}
          onCloseMenuHandler={onCloseApproveLawTypeMenuHandler}
          onApproveCaseLawSuggestion={onApproveCaseLawSuggestion}
        />
      </SideMenu>
    </MDBox>
  );
};
