import { FC, useEffect } from 'react';
import { Autocomplete, Card, CardContent, CardHeader, CircularProgress, Grid, Stack } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { BaseFormField } from 'features/common';
import { useMailSettings } from '../hooks/useMailSettings';
import { EndInputAdornment } from './EndInputAdornment';
import { FieldWrapper } from './FieldWrapper';
import { Loader } from 'features/common/components/Loader';

export const MailSettings: FC = ({}) => {
  const {
    configMailTemplatesState,
    emailTemplates,
    emailTemplatesFields,
    generalMailFormState,
    generalMailRegister,
    handleAutocompleteChange,
    isTemplatesUpdating,
    isSaveGeneralMailButtonDisabled,
    onSubmitGeneralMailFormHandler,
    onUpdateTemplateHandler,
    isSaveTemplatesButtonsDisabled,
    isTemplatesLoading,
    isSettingsLoading,
    onFetchEmailTemplatesHandler,
    onFetchSettingsHandler,
  } = useMailSettings();

  useEffect(() => {
    onFetchEmailTemplatesHandler();
    onFetchSettingsHandler();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Mail General" />
          <CardContent sx={{ minHeight: 200, display: 'flex' }}>
            {isSettingsLoading ? (
              <Loader />
            ) : (
              <form onSubmit={onSubmitGeneralMailFormHandler}>
                <Stack spacing={1.5}>
                  <FieldWrapper title="Sender name" description="Set displayed sender name in outgoing emails">
                    <BaseFormField
                      formFieldProps={{
                        pb: 0,
                        width: 1,
                        flex: 1,
                      }}
                      formInputProps={{
                        ...generalMailRegister('mail_sender_name'),
                        label: 'Sender name',
                        fullWidth: true,
                        InputLabelProps: { shrink: true },
                      }}
                      errorValue={generalMailFormState.errors['mail_sender_name']?.message}
                    />
                  </FieldWrapper>

                  <FieldWrapper title="Sender email" description="Set displayed sender email in outgoing emails">
                    <BaseFormField
                      formFieldProps={{
                        pb: 0,
                        width: 1,
                        flex: 1,
                      }}
                      formInputProps={{
                        ...generalMailRegister('mail_sender_email'),
                        label: 'Sender email',
                        fullWidth: true,
                        InputLabelProps: { shrink: true },
                      }}
                      errorValue={generalMailFormState.errors['mail_sender_email']?.message}
                    />
                  </FieldWrapper>

                  <FieldWrapper title="Chat notification delay" description="Chat notification reminder delay">
                    <BaseFormField
                      formFieldProps={{
                        pb: 0,
                        width: 1,
                        flex: 1,
                      }}
                      formInputProps={{
                        ...generalMailRegister('mail_sender_email_chat_reminder_seconds'),
                        label: 'Chat notification delay',
                        fullWidth: true,
                        InputLabelProps: { shrink: true },
                        InputProps: {
                          endAdornment: generalMailFormState.errors['mail_sender_email_chat_reminder_seconds']
                            ?.message ? null : (
                            <EndInputAdornment />
                          ),
                        },
                      }}
                      errorValue={generalMailFormState.errors['mail_sender_email_chat_reminder_seconds']?.message}
                    />
                  </FieldWrapper>

                  <FieldWrapper title="Contact us email" description="Set an email to receive contact us letters">
                    <BaseFormField
                      formFieldProps={{
                        pb: 0,
                        width: 1,
                        flex: 1,
                      }}
                      formInputProps={{
                        ...generalMailRegister('contact_us_email'),
                        label: 'Contact us email',
                        fullWidth: true,
                        InputLabelProps: { shrink: true },
                      }}
                      errorValue={generalMailFormState.errors['contact_us_email']?.message}
                    />
                  </FieldWrapper>
                </Stack>
                <MDBox my={2}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    type="submit"
                    sx={{ textTransform: 'none', width: { xs: 1, sm: 'auto' } }}
                    disabled={isSaveGeneralMailButtonDisabled}
                    isLoading={generalMailFormState.isSubmitting}
                  >
                    Save
                  </MDButton>
                </MDBox>
              </form>
            )}
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader title="Mail Templates" />
          <CardContent>
            <Stack spacing={1.5}>
              {emailTemplatesFields.map(config => {
                return (
                  <FieldWrapper key={config.id} title={config.label} description={config.description}>
                    <Autocomplete
                      freeSolo={false}
                      loading={isTemplatesLoading}
                      isOptionEqualToValue={(option, value) => option?.name === value?.name}
                      value={configMailTemplatesState?.[config.id] || null}
                      onChange={(_, newValue) => newValue && handleAutocompleteChange(config.id, newValue)}
                      options={emailTemplates}
                      disableClearable
                      getOptionLabel={template => template?.description}
                      sx={{
                        flex: 1,
                        width: 1,
                        '.MuiInputBase-root': {
                          py: '4.5px',
                        },
                      }}
                      renderInput={params => (
                        <BaseFormField
                          formFieldProps={{
                            pb: 0,
                          }}
                          formInputProps={{
                            ...params,
                            InputLabelProps: { shrink: true },
                            fullWidth: true,
                            label: config.label,
                            InputProps: {
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {isTemplatesLoading ? (
                                    <MDBox pr={0} display="flex">
                                      <CircularProgress color="inherit" size={20} />
                                    </MDBox>
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            },
                          }}
                        />
                      )}
                    />
                  </FieldWrapper>
                );
              })}
              <MDBox mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  type="button"
                  onClick={onUpdateTemplateHandler}
                  sx={{ textTransform: 'none', width: { xs: 1, sm: 'auto' } }}
                  disabled={isSaveTemplatesButtonsDisabled || isTemplatesUpdating}
                  isLoading={isTemplatesUpdating}
                >
                  Save
                </MDButton>
              </MDBox>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};
