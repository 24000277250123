import { FC } from 'react';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MDBox from 'components/MDBox';

type CaseInfoActionButtonProps = {
  onClickButtonHandler: () => void;
  isDisabledButton?: boolean;
};

export const CaseInfoActionButton: FC<CaseInfoActionButtonProps> = ({ isDisabledButton, onClickButtonHandler }) => {
  return (
    <MDBox
      sx={{
        p: 1,
        display: 'flex',
        ...(!isDisabledButton
          ? {
              borderRadius: 5,
              cursor: 'pointer',
              ':hover': {
                backgroundColor: theme => theme.palette.grey[200],
              },
            }
          : {
              svg: {
                color: theme => theme.palette.grey[400],
              },
            }),
      }}
      onClick={isDisabledButton ? undefined : onClickButtonHandler}
    >
      <ModeEditIcon />
    </MDBox>
  );
};
