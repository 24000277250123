import { useCallback, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateValidationError, TimeValidationError } from '@mui/x-date-pickers';
import { useForm } from 'react-hook-form';
import { set, startOfDay } from 'date-fns';
import { DelayedNotificationType } from 'features/case-details/DelayedNotificationsApiService';
import { CreateDelayedNotificationValidationScheme, createDelayedNotificationValidatationSchema } from '../form.config';
import { UseDelayedNotificationFormProps } from '../types';
import { BackendError, paymentRequiredErrorHandler } from 'features/common/errorHanders';
import { AxiosError } from 'axios';
import { ToastType, notice } from 'features/common';

export const useDelayedNotificationForm = ({
  createLegaCaseDelayedNotificationHandler,
  onCloseMenuHandler,
  isCreateMode,
  updateLegalCaseDelayedNotificationHandler,
  message,
  regularity,
  startedAt,
}: UseDelayedNotificationFormProps) => {
  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    setError,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<CreateDelayedNotificationValidationScheme>({
    resolver: yupResolver(createDelayedNotificationValidatationSchema),
    mode: 'onTouched',
    values: {
      message: message || '',
      startingDate: startedAt ? new Date(startedAt) : startOfDay(new Date()),
      time: startedAt ? new Date(startedAt) : null,
    },
  });

  const [regularityValue, setRegularityValue] = useState<DelayedNotificationType>(regularity || 'every_day');

  const onChangeRegularityValue = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) =>
      setRegularityValue(event.currentTarget.id as DelayedNotificationType),
    []
  );

  const onErrorHandler = (error: DateValidationError) => {
    error
      ? setError('startingDate', {
          message: 'Enter a valid date format',
        })
      : clearErrors('startingDate');
  };

  const onErrorTimePickerHandler = (error: TimeValidationError) => {
    error
      ? setError('time', {
          message: 'Enter a valid time format',
        })
      : clearErrors('time');
  };

  const onFormSubmitHandler = handleSubmit(async formData => {
    const selectedDate = formData.startingDate;
    const selectedTime = formData.time;

    const combinedDateTime = set(selectedDate, {
      hours: selectedTime.getHours(),
      minutes: selectedTime.getMinutes(),
    });

    const payload = {
      message: formData.message,
      startedAt: combinedDateTime.toISOString(),
      type: regularityValue,
    };
    try {
      if (isCreateMode) {
        await createLegaCaseDelayedNotificationHandler(payload);
      } else {
        await updateLegalCaseDelayedNotificationHandler(payload);
      }
      onCloseMenuHandler();
    } catch (error) {
      const axiosError = error as AxiosError<BackendError>;

      if (axiosError?.response?.status === 422) {
        notice(ToastType.ERROR, axiosError?.response?.data?.['hydra:description']);
      } else {
        paymentRequiredErrorHandler(
          error,
          isCreateMode
            ? 'Failed to create the delayed notification, please try again!'
            : 'Failed to update delayed notification, please try again!'
        );
      }
      console.error(error);
    }
  });

  const isFormDisabled = isSubmitting || !isValid || !isDirty;

  const isCreateButtonDisabled = isCreateMode
    ? regularity === regularityValue || isFormDisabled
    : regularity === regularityValue && isFormDisabled;

  return {
    errors,
    onErrorHandler,
    onErrorTimePickerHandler,
    onFormSubmitHandler,
    isCreateButtonDisabled,
    register,
    control,
    onChangeRegularityValue,
    regularityValue,
    isSubmitting,
  };
};
