import { Card, CardContent, Stack } from '@mui/material';
import MDBox from 'components/MDBox';
import { FC, memo } from 'react';
import { ChatMenu } from './ChatMenu/ChatMenu';
import { GetMyLegalCasesResponse } from 'features/my-legal-cases/MyLegalCasesApiService';
import MDTypography from 'components/MDTypography';
import { DependantInfoItem } from 'features/case-details/components/DependantInfoItem';
import { CreateAgoraEventResponse } from 'features/common/services/agoraEventApiService';

type SideNavInfoProps = {
  caseNumber: string;
  caseLawtypeTitle: string;
  countryName: string;
  countyName: string;
  isChatOpen: boolean;
  onClickChatIconButtonHandler: () => void;
  eventData: CreateAgoraEventResponse;
};

export const SideNavInfo: FC<SideNavInfoProps> = memo(
  ({ eventData, onClickChatIconButtonHandler, isChatOpen, caseLawtypeTitle, caseNumber, countryName, countyName }) => {
    return (
      <MDBox sx={{ position: 'relative', height: 1, width: 1, minHeight: 300 }}>
        <Card sx={{ height: 1 }}>
          <CardContent sx={{ display: 'flex', height: 1 }}>
            <Stack width={1}>
              <Stack spacing={1} flex={1} mb={1.5}>
                <MDTypography>Case Information</MDTypography>
                <DependantInfoItem title="Case number" titleValue={caseNumber} />
                <DependantInfoItem title="Case law type" titleValue={caseLawtypeTitle} />
                <DependantInfoItem title="Case country" titleValue={countryName} />
                <DependantInfoItem title="Case county" titleValue={countyName} />
              </Stack>
            </Stack>
          </CardContent>
        </Card>

        <ChatMenu
          onClickChatIconButtonHandler={onClickChatIconButtonHandler}
          isChatListOpen={isChatOpen}
          eventData={eventData}
        />
      </MDBox>
    );
  }
);
