import { FC, useEffect, useState } from 'react';
import { Add } from '@mui/icons-material';
import { useMyCompany } from 'features/store';
import { DialogWindow } from 'features/common/components/DialogWindow';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { TablePaginationFooter } from 'features/common/components/TablePaginationFooter';
import CustomPaginationTable from 'features/content-items/components/CustomPaginationTable';
import { usePagination } from 'features/common/hooks/usePagination';
import { Card, CircularProgress, Fab, Stack, Tooltip } from '@mui/material';
import { SideMenu, useBoolean, useRouter, useUser } from 'features/common';
import { COMPANY_ROLES } from 'features/company-list';
import { COMPANY_USERS_TABLE_COLUMNS } from './companyUsersTable.config';
import { EditUserModalContent } from './EditUserModalContent';
import { AddUserModalContent } from './AddUserModalContent';
import { ROUTES } from 'features/common/variables/routes';
import { useSubscriptionModalsContextState } from 'features/contexts/SubscriptionModalsContextProvider';
import { useSubscription } from 'features/common/hooks/useSubscription';

enum COMPANY_USER_PERMISSIONS {
  DELETE_ATTORNEY = 'delete_attorney',
  PROMOTE_ADMIN = 'promote_admin',
}

export const UsersCompanyTemplate: FC = () => {
  const {
    myCompany,
    companyUsersData: { totalItems, users: companyUsers },
    getCompanyUsersHandler,
    deleteCompanyUserHandler,
    updateCompanyUserHandler,
  } = useMyCompany();
  const router = useRouter();

  // TODO conference hack
  const { subscriptionStatus, onGetUserSubscriptionStatus, isCheckingSubscriptionStatus } = useSubscription();

  useEffect(() => {
    onGetUserSubscriptionStatus();
  }, []);

  const { backendUser, isCanSendRepresentationRequest } = useUser();

  const onClickAddUserButtonHandler = () => {
    if (
      isCanSendRepresentationRequest &&
      (subscriptionStatus.status === 'enterprise' || subscriptionStatus.status === 'lead') &&
      subscriptionStatus.isSubscriptionActive
    ) {
      return openAddUserModal();
    }
    openBuySubscriptionModal();
  };

  const { openBuySubscriptionModal } = useSubscriptionModalsContextState();

  const isCurrentUserCompanyOwner = myCompany?.owner?.firebaseUser === backendUser?.firebaseUser;
  const currentUserCompanyRole = !isCurrentUserCompanyOwner ? backendUser?.companyUser?.[0]?.role : null;

  const isAllowToAddUser =
    isCurrentUserCompanyOwner ||
    (myCompany?.userPermissions?.adminCanAddAttorney && currentUserCompanyRole === 'ROLE_COMPANY_ADMIN');

  const getPermissionsToDeleteAttorney = (reason: COMPANY_USER_PERMISSIONS, userFirebaseId: string) => {
    switch (reason) {
      case COMPANY_USER_PERMISSIONS.DELETE_ATTORNEY:
        return (
          isCurrentUserCompanyOwner ||
          (currentUserCompanyRole === 'ROLE_COMPANY_ADMIN' &&
            myCompany?.userPermissions?.adminCanDeleteAttorney &&
            userFirebaseId !== backendUser?.firebaseUser)
        );

      case COMPANY_USER_PERMISSIONS.PROMOTE_ADMIN:
        return (
          isCurrentUserCompanyOwner ||
          (currentUserCompanyRole === 'ROLE_COMPANY_ADMIN' &&
            myCompany?.userPermissions?.adminCanPromoteAdmin &&
            userFirebaseId !== backendUser?.firebaseUser)
        );

      default:
        return false;
    }
  };

  const [isLoading, setIsLoading] = useState(true);

  const { currentPage, setCurrentPage, entriesPerPage, onChangeEntriesPerPageHandler, onDeleteItem } = usePagination({
    totalItems,
    itemsLength: companyUsers?.length,
  });

  const onChangePageHandler = async (page: number) => {
    setCurrentPage(page);
    setIsLoading(true);
    await getCompanyUsersHandler({
      itemsPerPage: entriesPerPage,
      page,
    });
    setIsLoading(false);
  };

  const onFetchCompanyUsersHandler = async () => {
    setIsLoading(true);
    await getCompanyUsersHandler({ page: 1, itemsPerPage: 10 });
    setIsLoading(false);
  };

  useEffect(() => {
    onFetchCompanyUsersHandler();
  }, [entriesPerPage]);

  const [isAddUserModalOpen, openAddUserModal, closeAddUserModal] = useBoolean(false);

  const buttonTooltipTitle = myCompany?.active
    ? ''
    : 'You can`t invite attorney to the company until the application admin activates your company."';

  const [isDialogOpen, openDialogHandler, closeDialogHandler] = useBoolean(false);
  const [isEditMenuOpen, openEditMenuHandler, closeEditMenuHandler] = useBoolean(false);

  const [userId, setUserId] = useState('');
  const [userRole, setUserRole] = useState<COMPANY_ROLES | null>(null);
  const onDeleteIconClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserId(event.currentTarget.id?.replace('/company_users/', ''));
    openDialogHandler();
  };

  const onEditIconClickHandler = (role: COMPANY_ROLES, userId: string) => {
    setUserRole(role);
    setUserId(userId);
    openEditMenuHandler();
  };

  const onDeleteUserHandler = async () => {
    closeDialogHandler();
    setIsLoading(true);

    await deleteCompanyUserHandler(userId);
    if (companyUsers.length > 1) {
      await getCompanyUsersHandler({
        itemsPerPage: entriesPerPage,
        page: currentPage,
      });
    }

    setIsLoading(false);
    companyUsers.length === 1 && router.navigate(ROUTES.firmProfile, { replace: true });
  };

  const onUpdateCompanyUserHandler = async (userRole: COMPANY_ROLES) => {
    await updateCompanyUserHandler(userId, userRole);
    setUserRole(null);
    setUserId('');
  };

  return (
    <Card sx={{ minWidth: 300 }}>
      <MDBox p={3} lineHeight={1} pb={0}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <MDTypography variant="h5" fontWeight="medium">
              Company users
            </MDTypography>
            <MDTypography variant="button" color="text">
              Here you can manage company users.
            </MDTypography>
          </Stack>
          {isAllowToAddUser && (
            <Tooltip title={buttonTooltipTitle}>
              <MDBox>
                <Fab
                  disabled={!myCompany?.active || isCheckingSubscriptionStatus}
                  color="info"
                  aria-label="add"
                  size="medium"
                  sx={{ mt: { xs: 2, sm: 0 } }}
                  onClick={onClickAddUserButtonHandler}
                >
                  <Add sx={{ width: 25, height: 25 }} />
                </Fab>
              </MDBox>
            </Tooltip>
          )}
        </Stack>
      </MDBox>

      <MDBox minHeight="300px" justifyItems="center" alignItems="center" display="flex">
        {isLoading ? (
          <Stack width={1} alignItems="center">
            <CircularProgress />
          </Stack>
        ) : companyUsers?.length && !isLoading ? (
          <MDBox width="100%">
            <CustomPaginationTable
              entriesPerPage={{ defaultValue: entriesPerPage }}
              onChangeEntriesPerPage={onChangeEntriesPerPageHandler}
              isSorted={false}
              showTotalEntries
              table={{
                columns: COMPANY_USERS_TABLE_COLUMNS,
                rows: companyUsers?.map(company => ({
                  id: company?.user?.['@id'],
                  caseCount: company?.user?.legalCaseCount,
                  rating: company?.user?.rating,
                  userData: {
                    firstName: company?.user?.userProfile?.firstName,
                    lastName: company?.user?.userProfile?.lastName,
                    src: company?.user?.logo?.publicUrl,
                  },
                  role: company?.role,
                  actionBtns: {
                    id: company?.['@id'],
                    role: company?.role,
                    isCurrentUserCompanyOwner,
                    isAllowToDeleteAttorney: getPermissionsToDeleteAttorney(
                      COMPANY_USER_PERMISSIONS.DELETE_ATTORNEY,
                      company?.user?.firebaseUser
                    ),
                    isAllowToPromoteAdmin: getPermissionsToDeleteAttorney(
                      COMPANY_USER_PERMISSIONS.PROMOTE_ADMIN,
                      company?.user?.firebaseUser
                    ),
                    onDeleteIconClickHandler,
                    onEditIconClickHandler,
                  },
                })),
              }}
            />
            <TablePaginationFooter
              page={currentPage}
              currentItemsPerPage={entriesPerPage}
              totalItems={totalItems}
              onChangePage={onChangePageHandler}
            />
          </MDBox>
        ) : (
          !isLoading && <MDTypography sx={{ mx: 'auto' }}>No users were found...</MDTypography>
        )}
        <DialogWindow
          onCloseDialogHandler={closeDialogHandler}
          onApproveDialogHandler={onDeleteUserHandler}
          isDialogOpen={isDialogOpen}
          dialogTitle="Are you sure want to delete user?"
        />

        <SideMenu isOpen={isEditMenuOpen} onClose={closeEditMenuHandler} title="Edit role" customWidth={400}>
          <EditUserModalContent
            onCloseMenuHandler={closeEditMenuHandler}
            role={userRole}
            onUpdateCompanyUserHandler={onUpdateCompanyUserHandler}
          />
        </SideMenu>

        <SideMenu
          isOpen={isAddUserModalOpen}
          onClose={closeAddUserModal}
          title="Invite attorney to the company"
          customWidth={400}
        >
          <AddUserModalContent onCloseMenuHandler={closeAddUserModal} />
        </SideMenu>
      </MDBox>
    </Card>
  );
};
