/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react';

// react-router-dom components
import { NavLink, matchPath } from 'react-router-dom';

// @mui material components
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { Dialog, DialogActions, DialogContent, DialogTitle, Icon, debounce } from '@mui/material';

// Material Dashboard 2 PRO React TS components
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React TS examples components
import SidenavCollapse from 'examples/Sidenav/SidenavCollapse';
import SidenavList from 'examples/Sidenav/SidenavList';
import SidenavItem from 'examples/Sidenav/SidenavItem';

// Custom styles for the Sidenav
import SidenavRoot from 'examples/Sidenav/SidenavRoot';

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setMiniSidenav, setTransparentSidenav, setWhiteSidenav } from 'context';
import MDButton from '../../components/MDButton';
import { useRouter, useRoutes } from '../../features/common';
import { ROUTES } from 'features/common/variables/routes';
import sidenavLogoLabel from './styles/sidenav';
import MDBox from 'components/MDBox';

// Declaring props types for Sidenav
interface Props {
  color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'dark';
  brand?: string;
  brandName?: string;
  [key: string]: any;
}

function Sidenav({ color, brand, brandName, ...rest }: Props): JSX.Element {
  const [openCollapse, setOpenCollapse] = useState<boolean | string>(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState<boolean | string>(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;

  const { navigate, pathname } = useRouter();

  const collapseName = pathname.split('/').slice(1)[0];
  const items = pathname.split('/').slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];

  const { sidenavRoutes, isLogoutWindow, logout, closeLogOutWindow } = useRoutes();
  const [isLoading, setIsLoading] = useState(false);

  const onClickLogoutButtonHandler = async () => {
    setIsLoading(true);
    await logout();
    setIsLoading(false);
    closeLogOutWindow();
    navigate(ROUTES.signIn);
  };

  let textColor:
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'dark'
    | 'white'
    | 'inherit'
    | 'text'
    | 'light' = 'white';

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = 'dark';
  } else if (whiteSidenav && darkMode) {
    textColor = 'inherit';
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    const handleMiniSidenav = debounce(() => {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }, 100);

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener('resize', handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, pathname]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse: any) => {
    const template = collapse.map(({ name, route, key, href, action }: any) =>
      href ? (
        <Link key={key} href={href} target="_blank" rel="noreferrer" sx={{ textDecoration: 'none' }} onClick={action}>
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} style={{ textDecoration: 'none' }} onClick={action}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses: any) =>
    collapses.map(({ name, collapse, route, href, key, action }: any) => {
      let returnValue;
      if (collapse) {
        returnValue = (
          <SidenavItem
            key={`${key}${name}`}
            color={color}
            name={name}
            active={key === itemParentName ? 'isParent' : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }: any) => {
              openNestedCollapse === key && currentTarget.classList.contains('MuiListItem-root')
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key);

              action?.();
            }}
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: 'none' }} onClick={action}>
            <SidenavItem color={color} name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink to={route} key={key} style={{ textDecoration: 'none' }} onClick={action}>
            <SidenavItem color={color} name={name} active={key === collapseName} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = sidenavRoutes.map(
    ({ type, name, icon, title, collapse, noCollapse, key, href, route, action, isDisabled }: any) => {
      let returnValue;

      const isActive = !!matchPath({ path: route || key, end: pathname === ROUTES.home ? false : true }, pathname);

      if (type === 'collapse') {
        if (href) {
          returnValue = (
            <Link href={href} key={`${key}${name}`} target="_blank" rel="noreferrer" sx={{ textDecoration: 'none' }}>
              <SidenavCollapse name={name} icon={icon} active={isActive} noCollapse={noCollapse} />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = isDisabled ? (
            <SidenavCollapse
              key={`${key}${name}`}
              isDisabled={isDisabled}
              name={name}
              icon={icon}
              noCollapse={noCollapse}
              active={isActive}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          ) : (
            <NavLink to={route} key={`${key}${name}`}>
              <SidenavCollapse name={name} icon={icon} noCollapse={noCollapse} active={isActive}>
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={`${key}${name}`}
              name={name}
              icon={icon}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
              active={isActive}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === 'title') {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === 'divider') {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) || (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      } else if (type === 'button') {
        returnValue = <MDButton onClick={action}>{title}</MDButton>;
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}>
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: 'block', xl: 'none' }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: 'pointer' }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center" justifyContent={'center'}>
          {brand && <MDBox component="img" src={brand} alt="Brand" alignSelf={'center'} />}
          <MDBox width={'100%'} sx={(theme: any) => sidenavLogoLabel(theme, { miniSidenav })}>
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <List>{renderRoutes}</List>
      <Dialog open={isLogoutWindow} onClose={closeLogOutWindow}>
        <DialogContent>
          <DialogTitle>
            <MDTypography sx={{ textAlign: 'center' }}>Are you sure you want to log out?</MDTypography>
          </DialogTitle>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <MDButton variant="gradient" color="dark" onClick={closeLogOutWindow} sx={{ mr: 2 }}>
              No
            </MDButton>
            <MDButton
              isLoading={isLoading}
              disabled={isLoading}
              variant="gradient"
              color="error"
              onClick={onClickLogoutButtonHandler}
            >
              Yes
            </MDButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </SidenavRoot>
  );
}

// Declaring default props for Sidenav
Sidenav.defaultProps = {
  color: 'info',
  brand: '',
};

export default Sidenav;
