import { FC, memo } from 'react';
import { Card, CircularProgress, Stack } from '@mui/material';
import MDBox from 'components/MDBox';
import CustomPaginationTable from 'features/content-items/components/CustomPaginationTable';
import { CriticalUpdateVersion } from 'features/settings/UrgentVersionsApiService';
import { TablePaginationFooter } from 'features/common/components/TablePaginationFooter';
import { TableHeader } from 'features/common/components/TableHeader';
import MDTypography from 'components/MDTypography';
import { TABLE_COLUMNS } from './table.config';

type UrgentVersionsTableProps = {
  data: CriticalUpdateVersion[];
  entriesPerPage: number;
  currentPage: number;
  totalItems: number;
  onChangePage: (newPage: number) => void;
  isLoading: boolean;
  onChangeEntriesPerPage: (value: number) => void;
  onDeleteIconClickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onAddButtonClickHandler: () => void;
};

export const UrgentVersionsTable: FC<UrgentVersionsTableProps> = memo(
  ({
    data,
    entriesPerPage,
    currentPage,
    totalItems,
    onChangePage,
    isLoading,
    onChangeEntriesPerPage,
    onDeleteIconClickHandler,
    onAddButtonClickHandler,
  }) => {
    return (
      <Card sx={{ minWidth: '270px' }}>
        <TableHeader title="Urgent versions" onButtonClickHandler={onAddButtonClickHandler} />
        <MDBox minWidth="270px" minHeight="300px" justifyItems="center" alignItems="center" display="flex">
          {isLoading ? (
            <Stack width={1} alignItems="center">
              <CircularProgress />
            </Stack>
          ) : data.length && !isLoading ? (
            <MDBox width="100%">
              <CustomPaginationTable
                entriesPerPage={{ defaultValue: entriesPerPage }}
                onChangeEntriesPerPage={onChangeEntriesPerPage}
                isSorted={false}
                showTotalEntries
                table={{
                  columns: TABLE_COLUMNS,
                  rows: data?.map(el => ({
                    version: el?.version,
                    createdAt: el?.createdAt,
                    os: el?.os,
                    actionBtns: {
                      onDeleteIconClickHandler,
                      id: el?.id,
                    },
                  })),
                }}
              />
              {data.length ? (
                <TablePaginationFooter
                  page={currentPage}
                  currentItemsPerPage={entriesPerPage}
                  totalItems={totalItems}
                  onChangePage={onChangePage}
                />
              ) : null}
            </MDBox>
          ) : !isLoading && !data.length ? (
            <MDTypography sx={{ mx: 'auto' }}>No entries were found...</MDTypography>
          ) : null}
        </MDBox>
      </Card>
    );
  }
);
