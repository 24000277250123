import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AgoraAudioVideoIncomingCallData } from 'features/common/services/agoraEventApiService';
import { ROUTES } from 'features/common/variables/routes';
import { useRouter, useUser } from 'features/common';
import { useLockScreen, useNotificationsCenter } from 'features/store';

function paramsToObject(entries: IterableIterator<[string, string]>) {
  const result: { [key: string]: string } = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

export const IncominCallPush = (): null => {
  const [searchParams, _] = useSearchParams();

  const navigate = useRouter().push;

  const { setCallInvitationMessage, setIsCallModalOpen } = useNotificationsCenter(state => ({
    setCallInvitationMessage: state.setCallInvitationMessage,
    setIsCallModalOpen: state.setIsCallModalOpen,
  }));

  const { isPinCodeActive } = useUser();
  const isRedirectToLockScreen = useLockScreen(state => state.isRedirectToLockScreen);

  useEffect(() => {
    if (!searchParams.toString().length || !searchParams.has('agoraEvent')) return;
    const callData = paramsToObject(searchParams.entries());
    setCallInvitationMessage(callData as AgoraAudioVideoIncomingCallData);

    navigate(isPinCodeActive && isRedirectToLockScreen ? ROUTES.lockScreen : ROUTES.myLegalCases).then(() =>
      setIsCallModalOpen(true)
    );
  }, []);
  return null;
};
