import { SyntheticEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createBackendAuthService } from 'features/auth';
import { Country, CountryState, ToastType, notice, useAsync, useGeoData, useUser } from 'features/common';
import { createCompanyService } from 'features/company-list';
import {
  RegisterCompanySchema,
  registerCompanyFormDefaultValues,
  registerCompanyValidationSchema,
} from '../components/RegisterCompanyForm';
import { AutocompleteInputChangeReason } from '@mui/material';
import { useDebounce } from 'features/common/hooks/useDebounce';
import { baseBackendErrorHandler } from 'features/common/errorHanders';
import { useMyCompany } from 'features/store';
import { isPhoneValid } from 'features/common/helpers/utilities';

export const useRegisterCompanyForm = (onCloseSideBarHandler: () => void) => {
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [selectedState, setSelectedState] = useState<CountryState | null>(null);

  const [selectedTown, setSelectedTown] = useState('');
  const [reason, setReason] = useState<AutocompleteInputChangeReason | null>(null);

  const { createPostalAddress } = createBackendAuthService();
  const { createCompany } = createCompanyService();
  const { backendUser } = useUser();

  const fetchUserCompanyHandler = useMyCompany().fetchUserCompanyHandler;

  const {
    register,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    reset,
    control,
  } = useForm<RegisterCompanySchema>({
    resolver: yupResolver(registerCompanyValidationSchema),
    mode: 'onTouched',
    defaultValues: registerCompanyFormDefaultValues,
  });

  const {
    onCountrySelect,
    onCountryStateSelect,
    onTownCityFieldInput,
    isTownCityFieldInputLoading,
    countryList,
    countryStateList,
    cityList,
  } = useGeoData();

  const onChangeCountryHandler = async (event: SyntheticEvent<Element, Event>, value: Country) => {
    if (value) {
      setValue('country', value['@id']);
      setSelectedCountry(value);
      await onCountrySelect(value['@id']);
    }
  };

  const onChangeCountryStateHandler = async (event: SyntheticEvent<Element, Event>, value: CountryState) => {
    if (value) {
      selectedTown && setSelectedTown('');
      setValue('state', value['@id']);
      setSelectedState(value);
      await onCountryStateSelect(value['@id']);
    }
  };

  const onChangeTownHandler = async (event: SyntheticEvent<Element, Event>, value: string) => {
    if (value) {
      setValue('town', value);
      setSelectedTown(value);
      errors.town?.message && clearErrors('town');
    }
  };

  const onChangeTownInputValueHandler = async (
    event: SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setValue('town', value || '');
    setSelectedTown(value);
    setReason(reason);
    errors.town?.message && clearErrors('town');

    if (reason === 'clear' && cityList?.length < 10) {
      setReason(reason);
      await onCountryStateSelect(selectedState['@id']);
    }

    if (reason === 'input' && !value && !cityList.length && selectedState) {
      setReason(reason);
      await onCountryStateSelect(selectedState['@id']);
    }
  };

  const [registerCompanyState, onRegisterCompanyHandler] = useAsync(async (formValues: RegisterCompanySchema) => {
    try {
      const isValid = isPhoneValid(formValues.companyPhone);
      if (!isValid) {
        setError('companyPhone', { message: 'Enter a valid company phone number' });
        return;
      }

      const { data } = await createPostalAddress({
        zipCode: formValues.zipCode,
        addressLine1: formValues.addressLine1,
        addressLine2: formValues.addressLine2,
        country: formValues.country,
        state: formValues.state,
        town: formValues.town,
        type: 'business',
        nameOfAddressee: `${backendUser?.userProfile?.firstName} ${backendUser?.userProfile?.lastName}`,
        title: `Company ${formValues.fullName} headquarter`,
      });

      await createCompany({
        email: formValues.companyEmail,
        fullName: formValues.fullName,
        phone: formValues.companyPhone,
        shortName: formValues.shortName,
        headquarterAddress: data['@id'],
        description: formValues.description,
        site: formValues.site,
      });

      await fetchUserCompanyHandler();

      setSelectedCountry(null);
      setSelectedState(null);
      setSelectedTown('');
      reset();
      onCloseSideBarHandler();

      notice(ToastType.SUCCESS, `Successfully registered a new company`);
    } catch (error) {
      console.error(error);
      baseBackendErrorHandler(error, { formError: { formData: formValues, setError } });
    }
  });

  const onSubmit = handleSubmit(async data => await onRegisterCompanyHandler(data));

  const isSubmitButtonDisabled = !isValid || registerCompanyState.isPending() || isSubmitting;
  const isLoading = registerCompanyState.isPending();

  const debouncedValue = useDebounce(selectedTown, 250);

  useEffect(() => {
    const fetchCityList = async () => {
      await onTownCityFieldInput({ cityName: debouncedValue });
      setReason(null);
    };
    if (selectedTown?.length >= 3 && reason === 'input') {
      fetchCityList();
    }
  }, [debouncedValue]);

  return {
    onChangeCountryHandler,
    onChangeCountryStateHandler,
    onChangeTownHandler,
    onSubmit,
    register,
    onChangeTownInputValueHandler,
    reason,
    setReason,
    control,
    errors,
    countryList,
    countryStateList,
    cityList,
    isTownCityFieldInputLoading,
    isSubmitButtonDisabled,
    selectedCountry,
    selectedState,
    selectedTown,
    isLoading,
  };
};
