import { CaseLawTypeResponse, Pagination } from 'features/case-law-types';
import { BaseData, GetWorkingHoursResponse, ListOfItems, Logo, backendApiClient } from 'features/common';
import { COMPANY_ROLES, CompanyInfo, HeadquarterAddress, UserPermissions, UserProfile } from 'features/company-list';
import { GetUsersParams, GetUsersResponse } from 'features/users-management/UsersManagementApiService';

const BASE_MY_COMPANY_API_URL: Readonly<string> = '/users/my_company';
const BASE_COMPANY_ATTORNEY_INVITATION_API_URL: Readonly<string> = '/company_attorney_invitations';

export type GetUserCompanyResponse = CompanyInfo & {
  '@context': '/contexts/Company';
  '@id': string;
  '@type': 'Company';
  id: string;
  caseLawTypes: CaseLawTypeResponse[];
  active: boolean;
  createdAt: string;
  updatedAt: string | null;
  headquarterAddress: Omit<HeadquarterAddress, '@type'> & {
    '@type': 'PostalAddress';
  };
  owner: {
    '@id': '/users/my_company';
    '@type': 'User';
    logo: null | string;
    userProfile: UserProfile;
    firebaseUser: string;
  };
  userPermissions: UserPermissions & {
    '@id': string;
    '@type': 'CompanyUserPermission';
  };
  logo: null | Logo;
  workingHours: GetWorkingHoursResponse;
  rating: number | null;
};

export type CompanyInvitation = BaseData<'CompanyAttorneyInvitation'> & {
  company: BaseData<'Company'> & {
    fullName: string;
    logo: null | Logo;
    shortName: string;
  };
  inviter: BaseData<'User'> & {
    logo: null | Logo;
    userProfile: UserProfile;
  };
  role: COMPANY_ROLES;
};

export const myCompanyApiService = () => {
  const getUserCompany = async () => {
    const { data } = await backendApiClient({}).get<GetUserCompanyResponse>(BASE_MY_COMPANY_API_URL);
    return data;
  };

  const getAttoneyListWithoutCompany = async (params?: GetUsersParams) =>
    await backendApiClient({}).get<ListOfItems<GetUsersResponse[]>>('/users/attorney/without_company', {
      params,
    });

  const createInvitationToTheCompanyByEmail = async (email: string, role: COMPANY_ROLES) =>
    await backendApiClient({}).post(`${BASE_COMPANY_ATTORNEY_INVITATION_API_URL}/by_email`, {
      email,
      role,
    });

  const getMineInvitationToTheCompany = async (params?: Pagination) =>
    await backendApiClient({}).get<ListOfItems<CompanyInvitation[]>>(`${BASE_COMPANY_ATTORNEY_INVITATION_API_URL}/my`, {
      params,
    });

  const approveInvitation = async (invitationId: string) =>
    await backendApiClient({ headers: { 'Content-Type': 'application/merge-patch+json' } }).patch(
      `${BASE_COMPANY_ATTORNEY_INVITATION_API_URL}/${invitationId}/accept`,
      {}
    );

  const declineInvitation = async (invitationId: string) =>
    await backendApiClient({}).delete(`${BASE_COMPANY_ATTORNEY_INVITATION_API_URL}/${invitationId}`);

  return {
    declineInvitation,
    getMineInvitationToTheCompany,
    getUserCompany,
    getAttoneyListWithoutCompany,
    createInvitationToTheCompanyByEmail,
    approveInvitation,
  };
};
