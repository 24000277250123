import { Outlet } from 'react-router-dom';
import { FC, useState } from 'react';
import { Card, Grid, Stack } from '@mui/material';
import { DashboardLayout, ToastType, notice, useBoolean, useRouter, useUser } from 'features/common';
import { BaseSideNaveMenu } from 'features/common/components/BaseSideNaveMenu';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';
import { createBackendAuthService } from 'features/auth';
import { ROUTES } from 'features/common/variables/routes';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { sideNavMenuRoutes } from '../config';

export const ClioIntegrationTemplate: FC = () => {
  const { disconnectClioFromProfile } = createBackendAuthService();

  const { isClioActive, connectBackendUserHandler } = useUser();
  const { navigate } = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const [isDisconnectDialogOpen, openDisconnectDialogHandler, closeDisconnectDialogHandler] = useBoolean(false);

  const onClickDisconnectButtonHandler = async () => {
    try {
      closeDisconnectDialogHandler();
      setIsLoading(true);
      await disconnectClioFromProfile();
      await connectBackendUserHandler();
      setIsLoading(false);
      notice(ToastType.SUCCESS, 'Successfully disconnect!');
      navigate(ROUTES.integrations);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      paymentRequiredErrorHandler(error);
    }
  };

  const sidenavRoutes = sideNavMenuRoutes(isClioActive);

  return (
    <DashboardLayout>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4}>
          <Stack
            spacing={2}
            sx={{
              position: 'sticky',
              top: '1%',
            }}
          >
            <BaseSideNaveMenu sidenavItems={sidenavRoutes} sx={{ position: 'static' }} />

            <Card
              sx={{
                borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
                p: 2,
              }}
            >
              <MDButton
                disabled={!isClioActive || isLoading}
                fullWidth
                variant="gradient"
                color="error"
                isLoading={isLoading}
                onClick={openDisconnectDialogHandler}
              >
                Disconnect
              </MDButton>
            </Card>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <Outlet />
        </Grid>
      </Grid>

      <DialogWindow
        onCloseDialogHandler={closeDisconnectDialogHandler}
        onApproveDialogHandler={onClickDisconnectButtonHandler}
        isDialogOpen={isDisconnectDialogOpen}
        dialogTitle="Are you sure you want to disconnect Clio from your account?"
      />
    </DashboardLayout>
  );
};
