import { FC } from 'react';
import MDBox from 'components/MDBox';
import { Stack } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import MDTypography from 'components/MDTypography';
import MDBadge from 'components/MDBadge';
import { useRouter, useUser } from 'features/common';
import { MenuPopover } from 'features/common/components/MenuPopover';
import { PopoverMenuItem } from 'features/common/components/PopoverMenuItem';
import { usePopover } from 'features/common/hooks/usePopover';
import { useMyLegalCases } from 'features/store';
import { CardHeader } from './CardHeader';
import { CardFooter } from './CardFooter';
import { LegalCaseCardProps } from './types';
import { HighlightBar } from 'features/common/components/HighlightBar';
import { ROUTES } from 'features/common/variables/routes';

export const LegalCaseCard: FC<LegalCaseCardProps> = ({
  caseForDependant,
  caseNumber,
  createdAt,
  dependantFirstName,
  dependantLastName,
  firstName,
  lastName,
  description,
  countryId,
  stateName,
  statusBgColor,
  statusTitle,
  countyName,
  lastElementRef,
  caseId,
  companyShortName,
  attorneyLogo,
  statusType,
  attorneyFirebaseid,
  legalCaseActions,
}) => {
  const route = useRouter();

  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  const { deleteMyLegalCaseHandler, backToQueueHandler } = useMyLegalCases(state => ({
    deleteMyLegalCaseHandler: state.deleteMyLegalCaseHandler,
    backToQueueHandler: state.declineAcceptedConsultationHandler,
  }));

  const onCardClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    openPopover ? event.stopPropagation() : route.push(`/cases/${caseId}`);
  };

  const isOpenChatMenuItem = !!attorneyFirebaseid;

  const isThreeDotsMenu =
    (statusType !== 'done' && legalCaseActions?.canBeDeleted) ||
    (statusType !== 'done' && legalCaseActions?.backToQueue) ||
    (statusType !== 'done' && isOpenChatMenuItem);

  const onClickOnChatMenuItemHandler = () => {
    route.navigate(ROUTES.legalCaseChat, {
      state: {
        caseNumber,
        userFirebaseId: attorneyFirebaseid,
        prevRoute: route.pathname,
      },
    });
  };

  const onDeleteMenuItemClickHandler = async () => {
    await deleteMyLegalCaseHandler(caseId);
    handleClosePopover();
  };

  const onBackToQueueMenuItemClickHandler = () => {
    backToQueueHandler(caseId);
    handleClosePopover();
  };

  const isDocusignRequest = legalCaseActions?.docuSignRequest;

  return (
    <Stack position="relative" height={1}>
      {isDocusignRequest && <HighlightBar />}
      {isThreeDotsMenu && (
        <MDBox
          sx={{
            position: 'absolute',
            right: isDocusignRequest ? 10 : 6,
            top: 17,
            cursor: 'pointer',
            padding: '3px',
            borderRadius: '50%',
            display: 'flex',
            zIndex: theme => theme.zIndex.fab,
            '&:hover': {
              backgroundColor: theme => theme.palette.grey[300],
            },
          }}
          onClick={handleOpenPopover}
        >
          <MoreVertIcon fontSize="medium" />
        </MDBox>
      )}
      <MDBox
        ref={lastElementRef}
        borderRadius="xl"
        p={1.5}
        pr={1.6}
        bgColor="white"
        onClick={onCardClickHandler}
        sx={{
          height: 1,
          position: 'relative',
          fontSize: ({ typography: { size } }) => size.md,
          cursor: 'pointer',
        }}
      >
        <Stack spacing={1.5} height={1}>
          <CardHeader
            attorneyLogo={attorneyLogo}
            firstName={firstName}
            lastName={lastName}
            companyShortName={companyShortName}
            attorneyFirebaseid={attorneyFirebaseid}
          />

          <Stack direction="row" spacing={1.5}>
            <MDTypography
              variant="button"
              sx={{
                fontWeight: 800,
              }}
            >
              {caseNumber}
            </MDTypography>
            <MDBadge
              size="xs"
              sx={{
                '.MuiBadge-badge': {
                  background: statusBgColor,
                },
              }}
              badgeContent={statusTitle}
              container
            />
          </Stack>

          <MDTypography
            variant="button"
            sx={{
              fontWeight: 400,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 4,
              MozLineClamp: 4,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flex: 1,
            }}
          >
            {description}
          </MDTypography>

          <CardFooter
            caseForDependant={caseForDependant}
            dependantFirstName={dependantFirstName}
            dependantLastName={dependantLastName}
            createdAt={createdAt}
            stateName={stateName}
            countryId={countryId}
            countyName={countyName}
          />
        </Stack>

        <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="top-right">
          {legalCaseActions?.chat && (
            <PopoverMenuItem
              title="Open chat"
              icon={<EmailOutlinedIcon fontSize="medium" />}
              onClickHandler={onClickOnChatMenuItemHandler}
            />
          )}

          {legalCaseActions?.backToQueue && (
            <PopoverMenuItem
              title="Back to queue"
              icon={<BusinessCenterOutlinedIcon fontSize="medium" />}
              onClickHandler={onBackToQueueMenuItemClickHandler}
            />
          )}

          {legalCaseActions?.canBeDeleted && (
            <PopoverMenuItem
              onClickHandler={onDeleteMenuItemClickHandler}
              title="Delete"
              icon={<DeleteOutlineIcon fontSize="medium" />}
            />
          )}
        </MenuPopover>
      </MDBox>
    </Stack>
  );
};
