import { FC } from 'react';
import { Add } from '@mui/icons-material';
import { Card, Fab, Grid, Stack } from '@mui/material';
import { DashboardLayout, SideMenu, useBoolean } from '../../common';
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import { CreateFAQItemMenuContent, FAQTable } from '../components';
import { useFAQ } from '../hooks/useFAQ';
import { TableHeader } from 'features/common/components/TableHeader';

export const FAQListTemplate: FC = () => {
  const [isCreateMenuOpen, onOpenMenu, onCloseMenu] = useBoolean(false);

  const {
    questionsList,
    questionsTotal,
    currentPage,
    entriesPerPage,
    isLoading,
    getItemsHandler,
    setIsLoading,
    onDeleteItem,
    onChangeEntriesPerPageHandler,
    onChangePageHandler,
    loadQuestionsHandler,
  } = useFAQ();

  return (
    <DashboardLayout>
      <MDBox mt={4}>
        <Grid container spacing={2} columns={{ xs: 12, md: 8, lg: 4 }}>
          <Grid item xs={12} md={8} lg={4}>
            <MDBox mb={3} minWidth="300px" minHeight="300px" justifyItems="center" alignItems="center" display="flex">
              <Card sx={{ width: 1 }}>
                <TableHeader
                  title=" Frequently asked questions"
                  subtitle="Here you can work with current questions and their answers or create some by yourself."
                  onButtonClickHandler={onOpenMenu}
                />
                <FAQTable
                  questionsList={questionsList}
                  questionsTotal={questionsTotal}
                  currentPage={currentPage}
                  entriesPerPage={entriesPerPage}
                  isLoading={isLoading}
                  getItemsHandler={getItemsHandler}
                  setIsLoading={setIsLoading}
                  onDeleteItem={onDeleteItem}
                  onChangeEntriesPerPageHandler={onChangeEntriesPerPageHandler}
                  onChangePageHandler={onChangePageHandler}
                  loadQuestionsHandler={loadQuestionsHandler}
                />
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <SideMenu
        isOpen={isCreateMenuOpen}
        onClose={onCloseMenu}
        title="Create Frequently asked question"
        titleDescription="Here you can create question and add an answer to it by yourself."
        customWidth={450}
      >
        <CreateFAQItemMenuContent onCloseMenuHandler={onCloseMenu} loadQuestionsHandler={loadQuestionsHandler} />
      </SideMenu>
    </DashboardLayout>
  );
};
