import { emailRegExp } from 'features/auth';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';

export type SecuritySchema = {
  clientinvitationTTL: string;
  idle: string;
};

export type AudioCallSchema = {
  audio: number;
};

export type VideoCallSchema = {
  video: number;
};

export type AudioVideoCallSchema = {
  video: string;
  audio: string;
};

export type GeneralMailSchemaItem = {
  id: string;
  value: string;
};
export type GeneralMailSchema = {
  mail_sender_name: string;
  mail_sender_email: string;
  mail_sender_email_chat_reminder_seconds: string;
  contact_us_email: string;
};

export const generalMailValidationSchema: ObjectSchema<GeneralMailSchema> = yup.object().shape({
  mail_sender_name: yup.string().required('Sender name field is required'),
  mail_sender_email: yup
    .string()
    .required('Sender email field is required')
    .trim()
    .matches(emailRegExp, 'Please enter a valid email'),
  mail_sender_email_chat_reminder_seconds: yup.string().required('Chat notification delay field is required'),
  contact_us_email: yup
    .string()
    .required('Contact us field is required')
    .trim()
    .matches(emailRegExp, 'Please enter a valid email'),
});

export const securityValidationSchema: ObjectSchema<SecuritySchema> = yup.object().shape({
  clientinvitationTTL: yup.string().required('Сlient invitation TTL field is required'),
  idle: yup
    .string()
    .matches(/^[1-9][0-9]*$/, 'Idle timeout must have an integral positive value')
    .required('Idle timeout field is required')
    .max(4, 'Idle timeout must be 4 digits long')
    .label('Idle timeout'),
});

export const audioVideoCallValidationSchema: ObjectSchema<AudioVideoCallSchema> = yup.object().shape({
  video: yup.string().required('Video call duration field is required'),
  audio: yup.string().required('Audio call duration field is required'),
});
