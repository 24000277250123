import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export const convertEditorStateValueToHTML = (editorState: EditorState) =>
  draftToHtml(convertToRaw(editorState.getCurrentContent()));

export const getEditorStateFromContent = (contentBody: string = '') => {
  const contentBlock = htmlToDraft(contentBody);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
};
