import { immer } from 'zustand/middleware/immer';
import { AxiosError } from 'axios';
import { ToastType, notice } from 'features/common';
import { CreateLegalCaseNoteResponse, myLegalCasesApiService } from 'features/my-legal-cases/MyLegalCasesApiService';
import { GetMyLegalCaseDetailsResponse, caseDetailsApiService } from 'features/case-details/CaseDetailsApiService';
import { createFileService } from 'features/common/services/filesService';
import { legalCaseConsultationApiService } from 'features/common/services/legalCaseConsultationApiService';
import { legalCaseRepresentationApiService } from 'features/common/services/legalCaseRepresentationApiService';
import { delayedNotificationsApiService } from 'features/case-details/DelayedNotificationsApiService';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders/paymentRequiredErrorHandler';
import { createStore } from '../createStore';
import { MyLegalCaseActions, MyLegalCaseState } from './types';

const { createFile, deleteFileById } = createFileService();
const {
  getLegalCaseNoteById,
  getMyLegalCase,
  patchLegalCase,
  deleteLegalCaseNote,
  updateLegalCaseNote,
  getMyLegalCaseNotes,
  getMyLegalCaseChats,
  getMyLegalCaseHistory,
  getLegalCaseNotes,
  getMyLegalCaseStatuses,
  changeLegalCaseStatus,
  getLegalCaseConsultations,
  getLegalCaseRepresentations,
  getLegalCaseFiles,
} = caseDetailsApiService();

const { createDelayedNotification, deleteDelayedNotification, getDelayedNotifications, patchDelayedNotification } =
  delayedNotificationsApiService();
const { createLegalCaseNotes, putLegalCaseFile } = myLegalCasesApiService();
const { approveConsultation, deleteConsultation, declineConsultation } = legalCaseConsultationApiService();
const { approveRepresentation, deleteRepresentation } = legalCaseRepresentationApiService();

const initialState: MyLegalCaseState = {
  delayedNotifications: {
    notificationList: [],
    totalItems: null,
    selectedNotification: null,
  },
  case: {
    myLegalCase: {} as GetMyLegalCaseDetailsResponse,
  },
  myNotes: {
    caseNotes: [],
    totalItems: null,
  },
  conversations: {
    conversations: [],
    isConversationLoading: false,
  },
  selectedNote: {} as CreateLegalCaseNoteResponse,
  caseHistory: { isCaseHistoryLoading: false, caseHistoryData: [] },
  notes: {
    caseNotes: [],
    isLoading: false,
    totalItems: null,
  },
  caseStatuses: [],
  consultations: {
    consultationList: [],
    totalItems: null,
  },
  representations: {
    representationList: [],
    totalItems: null,
  },
};

export const useCaseDetails = createStore<MyLegalCaseState & MyLegalCaseActions>(
  immer((set, get) => ({
    ...initialState,
    //consultations
    declineAcceptedConsultationHandler: async () => {
      try {
        const state = get();
        const caseId = state.case.myLegalCase?.id;
        await declineConsultation(caseId);

        set(state => {
          state.case.myLegalCase = { ...state.case.myLegalCase, onConsultation: false, attorney: null };
        });
        if (state.representations.representationList.length) {
          set(state => {
            state.representations.representationList = [];
            state.representations.totalItems = null;
          });
        }
        await state.getConsultationsListHandler(caseId, { page: 1, itemsPerPage: 5 });

        notice(ToastType.SUCCESS, 'Successfully back to queue the legal case!');
      } catch (error) {
        paymentRequiredErrorHandler(error);
        console.error(error);
      }
    },
    getConsultationsListHandler: async (caseId, params) => {
      try {
        const { data } = await getLegalCaseConsultations(caseId, params);

        set(state => {
          state.consultations.consultationList = data['hydra:member'];
          state.consultations.totalItems = data['hydra:totalItems'];
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch counseling offers, please try again!');
        console.error(error);
      }
    },

    getMoreConsultationsHandler: async params => {
      try {
        const caseId = get().case.myLegalCase?.id;
        const { data } = await getLegalCaseConsultations(caseId, params);

        set(state => {
          state.consultations.consultationList.push(...data['hydra:member']);
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch counseling offers, please try again!');
        console.error(error);
      }
    },

    approveConsultationHandler: async consultationId => {
      try {
        await approveConsultation(consultationId);
        const caseId = get().case.myLegalCase?.id;

        await get().fetchLegalCaseHandler(caseId);
        set(state => {
          state.consultations.consultationList = [];
          state.consultations.totalItems = null;
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to approve counseling offers, please try again!');
        console.error(error);
      }
    },

    declineConsultationHandler: async consultationId => {
      try {
        await deleteConsultation(consultationId);

        set(state => {
          const totalItems = state.consultations.totalItems;
          state.consultations.consultationList = state.consultations.consultationList.filter(
            el => el.id !== consultationId
          );
          state.consultations.totalItems = totalItems ? state.consultations.totalItems - 1 : totalItems;
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to decline counseling offers, please try again!');
        console.error(error);
      }
    },

    getRepresentationListHandler: async (caseId, params) => {
      try {
        const { data } = await getLegalCaseRepresentations(caseId, params);

        set(state => {
          state.representations.representationList = data['hydra:member'];
          state.representations.totalItems = data['hydra:totalItems'];
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch representation offer, please try again!');

        console.error(error);
      }
    },

    getMoreRepresentationsHandler: async params => {
      try {
        const caseId = get().case.myLegalCase?.id;
        const { data } = await getLegalCaseRepresentations(caseId, params);

        set(state => {
          state.representations.representationList.push(...data['hydra:member']);
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch representation offer, please try again!');

        console.error(error);
      }
    },

    approveRepresentationsHandler: async id => {
      try {
        await approveRepresentation(id);
        const caseId = get().case.myLegalCase?.id;

        await get().fetchLegalCaseHandler(caseId);
        set(state => {
          state.representations.representationList = [];
          state.representations.totalItems = null;
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to approve representation offer, please try again!');

        console.error(error);
      }
    },

    declineRepresentationsHandler: async id => {
      try {
        await deleteRepresentation(id);
        const caseId = get().case.myLegalCase?.id;
        await get().fetchLegalCaseHandler(caseId);
        set(state => {
          const totalItems = state.representations.totalItems;
          state.representations.representationList = state.representations.representationList.filter(
            el => el.id !== id
          );
          state.representations.totalItems = totalItems ? state.representations.totalItems - 1 : totalItems;
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to decline representation offer, please try again!');
        console.error(error);
      }
    },

    fetchLegalCaseNotesHandler: async (caseId, params) => {
      try {
        set(state => {
          state.notes.isLoading = true;
        });

        const { data } = await getLegalCaseNotes(caseId, params);

        set(state => {
          state.notes.caseNotes = data['hydra:member'];
          state.notes.totalItems = data['hydra:totalItems'];
          state.notes.isLoading = false;
        });
      } catch (error) {
        set(state => {
          state.notes.isLoading = false;
        });
        paymentRequiredErrorHandler(error, 'Failed to fetch attorney notes, please try again!');
        console.error(error);
      }
    },
    fetchMoreLegalCaseNotesHandler: async params => {
      try {
        const caseId = get().case.myLegalCase?.id;
        const { data } = await getLegalCaseNotes(caseId, params);

        set(state => {
          state.notes.caseNotes.push(...data['hydra:member']);
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch all notes, please try again!');
        console.error(error);
      }
    },
    getMyLegalCaseHistoryHandler: async caseId => {
      try {
        set(state => {
          state.caseHistory.isCaseHistoryLoading = true;
        });
        const { data } = await getMyLegalCaseHistory({ legalCase: caseId });
        set(state => {
          state.caseHistory = { isCaseHistoryLoading: false, caseHistoryData: data['hydra:member'] };
        });
      } catch (error) {
        set(state => {
          state.caseHistory.isCaseHistoryLoading = false;
        });

        paymentRequiredErrorHandler(error, 'Failed to fetch case history, please try again!');
        console.error(error);
      }
    },
    createMyLegaCaseNoteHandler: async payload => {
      try {
        const caseId = get().case.myLegalCase?.['@id'];
        const { data } = await createLegalCaseNotes({ ...payload, legalCase: caseId });

        set(state => {
          state.myNotes.totalItems = state.myNotes.totalItems += 1;
          state.myNotes.caseNotes = [data, ...state.myNotes.caseNotes];
        });
        notice(ToastType.SUCCESS, 'Successfully created!');
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to create the note, please try again!');
        console.error(error);
      }
    },
    updateMyLegalCaseNoteHandler: async payload => {
      try {
        const noteId = get().selectedNote?.['@id'];
        const { data } = await updateLegalCaseNote(noteId.replace('/legal_case_notes/', ''), payload);

        set(state => {
          state.myNotes.caseNotes = state.myNotes.caseNotes.map(note =>
            note['@id'] === noteId ? { ...note, ...data } : note
          );
        });
        notice(ToastType.SUCCESS, 'Successfully update!');
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to update note, please try again!');
        console.error(error);
      }
    },
    getMyNoteById: async (noteId: string, isMine) => {
      try {
        const { data } = await getLegalCaseNoteById(noteId);

        set(state => {
          if (isMine) {
            state.myNotes.caseNotes = state.myNotes.caseNotes.map(note =>
              note['@id'] === noteId ? { ...note, ...data } : note
            );
          } else {
            state.notes.caseNotes = state.notes.caseNotes.map(note =>
              note['@id'] === noteId ? { ...note, ...data } : note
            );
          }
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to get note, please try again!');
        console.error(error);
      }
    },
    getMySelectedNote: noteId => {
      set(state => {
        state.selectedNote = state.myNotes.caseNotes.find(note => note['@id'] === noteId);
      });
    },
    deleteMyLegalCaseNoteHandler: async () => {
      try {
        const noteId = get().selectedNote?.['@id'];

        await deleteLegalCaseNote(noteId.replace('/legal_case_notes/', ''));

        set(state => {
          const totalItems = state.myNotes.totalItems;
          state.myNotes = {
            ...state.myNotes,
            caseNotes: state.myNotes.caseNotes.filter(note => note['@id'] !== noteId),
            totalItems: totalItems ? totalItems - 1 : totalItems,
          };
        });
        notice(ToastType.SUCCESS, 'Note has been successfully deleted!');
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to delete note, please try again!');
        console.error(error);
      }
    },

    fetchMoreMyLegalCaseNotesHandler: async params => {
      try {
        const caseId = get().case.myLegalCase?.id;
        const { data } = await getMyLegalCaseNotes(caseId, params);

        set(state => {
          state.myNotes.caseNotes.push(...data['hydra:member']);
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch all notes, please try again!');
        console.error(error);
      }
    },
    fetchMyLegalCaseNotesHandler: async (caseId, params) => {
      try {
        const { data } = await getMyLegalCaseNotes(caseId, params);

        set(state => {
          state.myNotes = {
            ...state.myNotes,
            totalItems: data['hydra:totalItems'],
            caseNotes: data['hydra:member'],
          };
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch notes, please try again!');
        console.error(error);
      }
    },
    updateLegalCase: async payload => {
      set(state => {
        state.case.myLegalCase = { ...state.case.myLegalCase, ...payload };
      });
    },
    updateLegalCaseDetails: async payload => {
      try {
        const caseId = get().case.myLegalCase.id;
        const { data } = await patchLegalCase(caseId, payload);

        set(state => {
          state.case.myLegalCase = data;
        });
        notice(ToastType.SUCCESS, 'Successfully updated case details!');
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to update case details, please try again!');
        console.error(error);
      }
    },
    fetchLegalCaseHandler: async (caseId, isAttorney) => {
      try {
        const { data } = await getMyLegalCase(caseId);

        if (!data?.attorney && !data?.onConsultation) {
          await get().getConsultationsListHandler(caseId, { itemsPerPage: 5, page: 1 });
        }

        if (data?.attorney && data?.onConsultation) {
          await get().getRepresentationListHandler(caseId, { itemsPerPage: 5, page: 1 });
        }

        if ((isAttorney && data?.onConsultation) || (isAttorney && !data?.attorney)) {
          set(state => {
            state.notes.caseNotes = [];
            state.notes.totalItems = null;
          });
        }

        set(state => {
          state.case.myLegalCase = data;
        });
      } catch (errorResponse) {
        const error = errorResponse as AxiosError;

        if (error?.response?.status === 404 || error?.response?.status === 403) {
          throw error;
        }
        paymentRequiredErrorHandler(error, 'Failed to fetch case details, please try again!');
        console.error(error);
      }
    },
    fetchLegalCaseDetailsHandler: async caseId => {
      try {
        const { data } = await getMyLegalCase(caseId);
        set(state => {
          state.case.myLegalCase = data;
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch case details, please try again!');
        console.error(error);
      }
    },

    //documents logic
    uploadDocumentHandler: async formData => {
      const {
        case: { myLegalCase },
      } = get();

      const { data: createFileData } = await createFile(formData);
      const { data } = await putLegalCaseFile(myLegalCase?.id, { file: createFileData['@id'] });

      set(state => {
        state.case.myLegalCase.files.unshift(data?.file);
      });
      notice(ToastType.SUCCESS, 'File has been successfully uploaded!');
    },
    deleteDocumentHandler: async (documentId: string) => {
      try {
        await deleteFileById(documentId);
        set(state => {
          state.case.myLegalCase.files = state.case.myLegalCase.files.filter(file => file.id !== documentId);
        });
        notice(ToastType.SUCCESS, 'File has been successfully deleted!');
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to delete the file, please try again!');
        console.error(error);
      }
    },
    fetchLegalCaseDocuments: async () => {
      try {
        const caseId = get().case.myLegalCase.id;
        const { data } = await getLegalCaseFiles(caseId);

        set(state => {
          state.case.myLegalCase.files = data?.files;
        });
      } catch (error) {
        console.error(error);
        paymentRequiredErrorHandler(error, 'Failed to fetch files, please try again!');
      }
    },

    //conversations
    getCaseConversationsHandler: async caseId => {
      try {
        set(state => {
          state.conversations.isConversationLoading = true;
        });
        const { data } = await getMyLegalCaseChats({ legalCase: caseId });

        set(state => {
          state.conversations.conversations = data['hydra:member'];
          state.conversations.isConversationLoading = false;
        });
      } catch (error) {
        set(state => {
          state.conversations.isConversationLoading = false;
        });

        paymentRequiredErrorHandler(error, 'Failed to fetch case conversation, please try again!');
        console.error(error);
      }
    },
    getLegalCaseStatusesHandler: async params => {
      try {
        const { data } = await getMyLegalCaseStatuses(params);
        set(state => {
          state.caseStatuses = data['hydra:member'];
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch case statuses, please try again!');
        console.error(error);
      }
    },
    changeLegalCaseStatusHandler: async payload => {
      try {
        const caseId = get().case.myLegalCase?.id;
        const { data } = await changeLegalCaseStatus(caseId, payload);
        set(state => {
          state.case.myLegalCase.status = data.status;
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to change case statuse, please try again!');
        console.error(error);
      }
    },

    //delayed notification
    fetchLegalCaseDelayedNotificationsHandler: async (caseId, params) => {
      try {
        const { data } = await getDelayedNotifications(caseId, params);

        set(state => {
          state.delayedNotifications.notificationList = data['hydra:member'];
          state.delayedNotifications.totalItems = data['hydra:totalItems'];
        });
      } catch (error) {
        notice(ToastType.ERROR, 'Failed to fetch delayed notification, please try again!');
        console.error(error);
      }
    },
    createLegaCaseDelayedNotificationHandler: async payload => {
      const caseId = get().case.myLegalCase?.['@id'];
      const { data } = await createDelayedNotification({ ...payload, legalCase: caseId });

      set(state => {
        state.delayedNotifications.totalItems = state.delayedNotifications.totalItems += 1;
        state.delayedNotifications.notificationList = [data, ...state.delayedNotifications.notificationList];
      });
      notice(ToastType.SUCCESS, 'Successfully created!');
    },

    getSelectedDelayedNotification: id => {
      set(state => {
        state.delayedNotifications.selectedNotification = state.delayedNotifications.notificationList.find(
          el => el?.id === id
        );
      });
    },
    deleteLegalCaseDelayedNotificationHandler: async () => {
      try {
        const selectedNotificationId = get().delayedNotifications.selectedNotification?.id;

        await deleteDelayedNotification(selectedNotificationId);

        set(state => {
          const totalItems = state.delayedNotifications.totalItems;
          state.delayedNotifications = {
            selectedNotification: null,
            notificationList: state.delayedNotifications.notificationList.filter(
              el => el.id !== selectedNotificationId
            ),
            totalItems: totalItems ? totalItems - 1 : totalItems,
          };
        });
        notice(ToastType.SUCCESS, 'Delayed notification has been successfully deleted!');
      } catch (error) {
        notice(ToastType.ERROR, 'Failed to delete delayed notification, please try again!');
        console.error(error);
      }
    },

    updateLegalCaseDelayedNotificationHandler: async payload => {
      const selectedNotificationId = get().delayedNotifications.selectedNotification?.id;

      const { data } = await patchDelayedNotification(selectedNotificationId, payload);

      set(state => {
        state.delayedNotifications.notificationList = state.delayedNotifications.notificationList.map(el =>
          el?.id === selectedNotificationId ? data : el
        );
        state.delayedNotifications.selectedNotification = null;
      });

      notice(ToastType.SUCCESS, 'Delayed notification has been successfully updated!');
    },
  })),
  'caseDetails',
  initialState
);
