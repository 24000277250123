import { useEffect } from 'react';
import { SignJWT } from 'jose';

const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const REACT_APP_MERCURE_JWT_SECRET = process.env.REACT_APP_MERCURE_JWT_SECRET;

type UseMercureProps = {
  onReceiveEventHandler: (event: MessageEvent) => Promise<void> | void;
  topicSubURL:
    | 'legal_case_chats'
    | 'agora_events'
    | 'user_statistics'
    | 'security/admin_statistics'
    | 'legal_cases'
    | 'legal_case_chats/listener'
    | 'attorney_profiles';
  mercureSubscribeURL: string;
  eventId?: string;
  isDissallowSubscription?: boolean;
  isWithOutEventId?: boolean;
};

const createJWT = async (topic: string) => {
  try {
    const payload = {
      mercure: {
        subscribe: [topic],
      },
    };

    const secret = new TextEncoder().encode(REACT_APP_MERCURE_JWT_SECRET);
    const jwt = await new SignJWT(payload).setProtectedHeader({ alg: 'HS256' }).sign(secret);

    return jwt;
  } catch (error) {
    console.error('Error creating JWT:', error);
  }
};

const parseHubUrl = (headerLink: string): string => {
  return headerLink?.match(/<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/)?.[1];
};

export const useMercure = ({
  onReceiveEventHandler,
  eventId,
  mercureSubscribeURL,
  topicSubURL,
  isDissallowSubscription,
  isWithOutEventId = false,
}: UseMercureProps) => {
  useEffect(() => {
    if (!mercureSubscribeURL || (!eventId && !isWithOutEventId) || isDissallowSubscription) return;

    const topic = `${REACT_APP_BASE_API_URL}/${topicSubURL}${isWithOutEventId ? '' : `/${eventId}`}`;
    const mercureURL = parseHubUrl(mercureSubscribeURL);
    const hub_url = new URL(mercureURL, window.origin);
    let source: EventSource | null = null;

    const connectToMercure = async () => {
      try {
        const token = await createJWT(topic);
        if (!token) return;

        hub_url.searchParams.append('topic', topic);
        hub_url.searchParams.append('authorization', token);

        source = new EventSource(hub_url);

        source.onmessage = onReceiveEventHandler;
      } catch (error) {
        console.error('Error connecting to Mercure:', error);
      }
    };

    connectToMercure();

    return () => {
      if (source) {
        source.close();
      }
    };
  }, [eventId, mercureSubscribeURL]);
};
