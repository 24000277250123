import { v4 as uuidv4 } from 'uuid';
import { syncTabs } from 'zustand-sync-tabs';
import { GetConfigsResponse, settingsApiService } from 'features/settings/SettingsApiService';
import { createBackendUserService } from 'features/common/services';
import { AgoraEventType } from 'features/common/services/agoraEventApiService';
import { createStore } from '../createStore';

type CallData = {
  eventId: string;
  endParticipantFirebaseId: string;
  eventType: AgoraEventType;
};

export type LockScreenState = {
  isDeactivateIdleTimer: boolean;
  isResetIdleTimer: boolean;
  isRedirectToLockScreen: boolean;
  idleConfigData: GetConfigsResponse;
  deviceId: string;
  lastIdleConfigExecutionTime: string | null;
  callData: CallData;
};

export type LockScreenActions = {
  setIsDeactivateIdleTimer: (value?: boolean) => void;
  setIsResetIdleTimer: (value: boolean) => void;
  setIsRedirectToLockScreen: (value: boolean) => void;
  onGetTimeToIdleHandler: () => Promise<void>;
  registerDeviceHandler: () => Promise<void>;
  resetStateHandler: () => void;
  setCallData: (callData: CallData) => void;
};

export const CONFIG_ID = 'security_pin_lock_expired_seconds';

const initialState: LockScreenState = {
  isDeactivateIdleTimer: false,
  isResetIdleTimer: false,
  isRedirectToLockScreen: false,
  idleConfigData: {} as GetConfigsResponse,
  deviceId: '',
  lastIdleConfigExecutionTime: null,
  callData: { endParticipantFirebaseId: '', eventId: '', eventType: 'video_call' },
};

const { getConfigById } = settingsApiService();
const { registerDevice } = createBackendUserService();

export const useLockScreen = createStore<LockScreenState & LockScreenActions>(
  syncTabs(
    set => ({
      ...initialState,
      setIsDeactivateIdleTimer: (isDeactivateIdleTimer = true) => {
        set({ isDeactivateIdleTimer });
      },
      setCallData: callData => {
        set({ callData });
      },
      resetStateHandler: () => {
        set(state => (state.isDeactivateIdleTimer ? { ...initialState, isDeactivateIdleTimer: true } : initialState));
      },
      setIsResetIdleTimer: isResetIdleTimer => {
        set({ isResetIdleTimer });
      },
      setIsRedirectToLockScreen: isRedirectToLockScreen => {
        set({ isRedirectToLockScreen });
      },
      onGetTimeToIdleHandler: async () => {
        const { data } = await getConfigById(CONFIG_ID);
        set({ idleConfigData: data, lastIdleConfigExecutionTime: Date.now().toString() });
      },
      registerDeviceHandler: async () => {
        const deviceId = uuidv4();
        await registerDevice({ deviceId, platformType: 'web' });
        set({ deviceId });
      },
    }),
    { name: 'lockScreen' }
  ),
  'lockScreen',
  initialState
);
