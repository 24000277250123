import { FC, memo, useEffect, useMemo } from 'react';
import { CircularProgress, Divider, MenuItem, Stack } from '@mui/material';
import { useNotificationsCenter } from 'features/store';
import { MenuPopover } from 'features/common/components/MenuPopover';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { NotificationModalItem } from './Components/NotificationModalItem';
import { ITEMS_PER_PAGE } from './NotificationCenterApiService';

type NotificationCenterModalProps = {
  isOpenMenu: HTMLElement;
  onCloseMenuHandler: () => void;
  onViewAllButtonClickHandler: () => void;
};

export const NotificationCenterModal: FC<NotificationCenterModalProps> = memo(
  ({ isOpenMenu, onCloseMenuHandler, onViewAllButtonClickHandler }) => {
    const {
      markAllNotificationsAsReadHandler,
      getNotificationsHandler,
      markNotificationAsReadHandler,
      isLoading,
      notifications,
      totalItems,
    } = useNotificationsCenter();

    const pages = Math.ceil(totalItems / ITEMS_PER_PAGE);

    useEffect(() => {
      getNotificationsHandler({ page: 1, itemsPerPage: ITEMS_PER_PAGE });
    }, []);

    const isReadAllButton = useMemo(() => notifications.some(el => !el.viewedAt), [notifications]);

    return (
      <MenuPopover open={isOpenMenu} onClose={onCloseMenuHandler} arrow="top-right">
        {!isLoading && <MDTypography sx={{ textAlign: 'center', fontWeight: '500' }}>Notifications</MDTypography>}
        <Stack
          sx={{ position: 'relative', maxHeight: 500, flex: 1, overflowY: 'auto' }}
          spacing={1}
          divider={<Divider variant="fullWidth" />}
        >
          {isLoading && (
            <Stack height={500} justifyContent="center" alignItems="center" width={400}>
              <CircularProgress />
            </Stack>
          )}

          {!isLoading && notifications.length
            ? notifications?.map(
                ({ id, createdAt, transitionType, allowTransition, viewedAt, legalCase, message, owner, opponent }) => (
                  <MenuItem key={id} sx={{ p: 0 }}>
                    <NotificationModalItem
                      id={id}
                      message={message}
                      transitionType={transitionType}
                      logoURL={(opponent ? opponent : owner)?.logo?.publicUrl}
                      caseNumber={legalCase?.caseNumber}
                      createdAt={createdAt || ''}
                      firstName={(opponent ? opponent : owner)?.userProfile?.firstName}
                      lastName={(opponent ? opponent : owner)?.userProfile?.lastName}
                      legalCaseId={legalCase?.['@id']}
                      opponentFirebaseId={opponent?.firebaseUser}
                      onCloseMenuHandler={onCloseMenuHandler}
                      viewedAt={viewedAt}
                      markNotificationAsReadHandler={markNotificationAsReadHandler}
                      isAllowTransition={allowTransition}
                    />
                  </MenuItem>
                )
              )
            : !isLoading &&
              !notifications.length && (
                <Stack height={500} width={400} justifyContent="center" alignItems="center">
                  No notifications were found
                </Stack>
              )}
          {!isLoading && (
            <MDBox
              sx={{
                display: isReadAllButton || pages > 1 ? 'block' : 'none',
                position: 'sticky',
                bottom: 0,
                left: 0,
                p: 1,
              }}
            >
              <Stack spacing={1}>
                {pages > 1 && (
                  <MDButton
                    variant="outlined"
                    color="success"
                    fullWidth
                    onClick={onViewAllButtonClickHandler}
                    sx={{
                      background: 'white',
                      backgroundColor: 'white',
                      ':hover': { background: 'white !important', opacity: 1 },
                    }}
                  >
                    View All
                  </MDButton>
                )}

                {isReadAllButton && (
                  <MDButton
                    variant="outlined"
                    color="info"
                    fullWidth
                    onClick={markAllNotificationsAsReadHandler}
                    sx={{
                      background: 'white',
                      backgroundColor: 'white',
                      ':hover': { background: 'white !important', opacity: 1 },
                    }}
                  >
                    Mark all notifications as read
                  </MDButton>
                )}
              </Stack>
            </MDBox>
          )}
        </Stack>
      </MenuPopover>
    );
  }
);
