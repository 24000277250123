import { FC } from 'react';
import { Stack } from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormField, IllustrationLayout } from 'features/common';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { createClientInvitationService } from 'features/auth/services/invitationsService';
import { AUTH_FLOW_STEPS, useAuth } from 'features/store';
import { baseBackendErrorHandler } from 'features/common/errorHanders';
import { AuthFlowBackgrounds } from 'features/auth/variables/illustartion';
import { IllustrationLogo } from 'features/common/components/IllustatrionLogo';

type CodeValidationSchema = {
  accessCode?: string;
};

export const codeFormValidationSchema: yup.ObjectSchema<CodeValidationSchema> = yup
  .object({
    accessCode: yup.string().trim().label('Code').min(1),
  })
  .required();

export const InvitationCodeStep: FC = () => {
  const { invitationCode, setStateData, toStep } = useAuth(state => ({
    invitationCode: state.invitationCode,
    setStateData: state.setStateData,
    toStep: state.toStep,
  }));

  const {
    handleSubmit,
    register,
    formState: { isValid, isSubmitting, errors },
    setError,
    watch,
    setValue,
  } = useForm<CodeValidationSchema>({
    defaultValues: { accessCode: '' },
    mode: 'onTouched',
    resolver: yupResolver(codeFormValidationSchema),
  });

  const { postClientInvitationAccept } = createClientInvitationService();

  const handleInvitationCodeConnect = handleSubmit(async formData => {
    try {
      if (formData.accessCode.length && !invitationCode) {
        const { data } = await postClientInvitationAccept(formData.accessCode);
        setStateData('invitationUser', data);
        toStep(AUTH_FLOW_STEPS.CREATE_USER_PROFILE);
      } else {
        toStep(AUTH_FLOW_STEPS.CREATE_USER_PROFILE);
      }
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 422) {
        error?.response?.data?.['hydra:description'] &&
          setError('accessCode', { message: error?.response?.data?.['hydra:description'] });
      } else {
        baseBackendErrorHandler(error, { formError: { formData, setError } });
      }
    }
  });

  const onClickBackButton = () => toStep(AUTH_FLOW_STEPS.WORK_TYPE);

  const onClickSkipButtonHandler = () => {
    toStep(AUTH_FLOW_STEPS.CREATE_USER_PROFILE);
    watch('accessCode').length && setValue('accessCode', '');
  };

  return (
    <IllustrationLayout
      title="Invitation Code (Optional)"
      description="If you've received an invitation code from an attorney, please enter it below."
      onClickBack={onClickBackButton}
      illustration={AuthFlowBackgrounds.AUTH_THREE}
      illustrationLogo={<IllustrationLogo bottom={10} right={10} />}
    >
      <MDBox display="flex" flexDirection="column" gap="8px" component="form" onSubmit={handleInvitationCodeConnect}>
        <BaseFormField
          formInputProps={{
            ...register('accessCode'),
            type: 'text',
            label: 'Code',
            fullWidth: true,
            disabled: invitationCode,
          }}
          errorValue={errors?.accessCode?.message}
        />
        <Stack spacing={2} mb={1}>
          <MDButton
            disabled={!watch('accessCode').length || isSubmitting || !isValid}
            variant="gradient"
            type="submit"
            color="info"
            fullWidth
            sx={{ textTransform: 'none' }}
          >
            Next
          </MDButton>
          <MDButton
            variant="outlined"
            type="button"
            color="info"
            onClick={onClickSkipButtonHandler}
            fullWidth
            sx={{ textTransform: 'none' }}
          >
            Skip
          </MDButton>
        </Stack>
      </MDBox>
    </IllustrationLayout>
  );
};
