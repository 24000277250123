import * as yup from 'yup';

export type FormValuesProps = {
  code1: string;
  code2: string;
  code3: string;
  code4: string;
};

export const defaultValues = {
  code1: '',
  code2: '',
  code3: '',
  code4: '',
};

const codeValidationRules = yup.string().required('Code is required');

export const codesValidatationSchema = yup
  .object({
    code1: codeValidationRules,
    code2: codeValidationRules,
    code3: codeValidationRules,
    code4: codeValidationRules,
  })
  .required();
