import { Integrations } from '../components/Integrations';
import { TemplateWrapper } from './TemplateWrapper';

export const IntegrationsTemplate = (): JSX.Element => {
  return (
    <TemplateWrapper>
      <Integrations />
    </TemplateWrapper>
  );
};
