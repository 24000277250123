import { ObjectSchema } from 'yup';
import * as yup from 'yup';
import { CreateBaseUserProfileSchema, emailRegExp } from 'features/auth';
import { GetFileByIdResponse } from 'features/common/services/filesService';

export type ClientSchema = Pick<CreateBaseUserProfileSchema, 'country' | 'email' | 'firstName' | 'lastName' | 'state'>;

export type CaseInformationSchema = {
  caseState: string;
  caseCounty: string;
  caseLawType: string;
  description: string;
  caseNumber?: string;
};

export type DependantInformationScheme = {
  isCaseForDependant: boolean;
  dependantFirstName?: string;
  dependantLastName?: string;
  dependantCaseState?: string;
  isUnder18?: boolean;
};

export type Note = {
  noteTitle: string;
  noteBody: string;
  isVisible?: boolean;
};

export type NotesScheme = {
  isAddNotes: boolean;
  notes?: Note[];
};

export type SupportedFileTypes = 'image' | 'audio' | 'video' | 'document';

export type DocumentItem = {
  isValid: boolean;
  file: File;
};

export type DocumentsScheme = {
  isDocuments: boolean;
  documents?: GetFileByIdResponse[];
};

const validationObject = {
  is: true,
  then: (schema: yup.StringSchema<string, yup.AnyObject, undefined, ''>) => schema.required('This field is required'),
  otherwise: (schema: yup.StringSchema<string, yup.AnyObject, undefined, ''>) => schema.notRequired(),
};

export const MAX_LENGTH_VALIDATION_MESSAGE = 'This value is too long. It should have 255 characters or less';

export type CreateLegalCaseSchema = ClientSchema &
  CaseInformationSchema &
  DependantInformationScheme &
  NotesScheme &
  DocumentsScheme;

export const clientFormSchema: ObjectSchema<ClientSchema> = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().matches(emailRegExp, 'Please enter a valid email'),
    country: yup.string().required(),
    state: yup.string().required(),
  })
  .required();

export const caseInformationFormSchema: ObjectSchema<CaseInformationSchema> = yup
  .object({
    caseNumber: yup.string().notRequired().optional(),
    caseState: yup.string().required(),
    caseCounty: yup.string().required(),
    caseLawType: yup.string().required(),
    description: yup.string().required('This field is required').trim().max(255, MAX_LENGTH_VALIDATION_MESSAGE),
  })
  .required();

export const dependantInformationFormSchema: ObjectSchema<DependantInformationScheme> = yup
  .object({
    isCaseForDependant: yup.boolean(),
    dependantFirstName: yup
      .string()
      .trim()
      .when('isCaseForDependant', validationObject)
      .max(255, MAX_LENGTH_VALIDATION_MESSAGE),
    dependantLastName: yup
      .string()
      .trim()
      .when('isCaseForDependant', validationObject)
      .max(255, MAX_LENGTH_VALIDATION_MESSAGE),
    dependantCaseState: yup.string().when('isCaseForDependant', validationObject),
    isUnder18: yup.boolean().when('isCaseForDependant', {
      is: true,
      then: (schema: yup.BooleanSchema<boolean, yup.AnyObject, undefined, ''>) => schema.notRequired(),
      otherwise: (schema: yup.BooleanSchema<boolean, yup.AnyObject, undefined, ''>) => schema.notRequired(),
    }),
  })
  .required();

export const documentsFormSchema: ObjectSchema<DocumentsScheme> = yup
  .object({
    isDocuments: yup.boolean(),
    documents: yup.array<GetFileByIdResponse>().when('isDocuments', {
      is: true,
      then: (schema: yup.ArraySchema<GetFileByIdResponse[], yup.AnyObject, '', ''>) =>
        schema.min(1, 'Upload at least one document').required('This field is required'),
      otherwise: (schema: yup.ArraySchema<GetFileByIdResponse[], yup.AnyObject, '', ''>) => schema.notRequired(),
    }),
  })
  .required();

export const NOTE_SHAPE = {
  noteTitle: yup.string().trim().required('Please enter the note title').max(255, MAX_LENGTH_VALIDATION_MESSAGE),
  noteBody: yup.string().trim().required('Please enter the note description').max(1000, MAX_LENGTH_VALIDATION_MESSAGE),
  isVisible: yup.boolean().notRequired(),
};

export const notesFormSchema: ObjectSchema<NotesScheme> = yup
  .object({
    isAddNotes: yup.boolean(),
    notes: yup.array().when('isAddNotes', {
      is: true,
      then: (schema: yup.ArraySchema<Note[], yup.AnyObject, undefined, ''>) =>
        schema
          .of(yup.object().shape(NOTE_SHAPE))
          .min(1, 'Please add at least one note')
          .required('Please add at least one note'),
      otherwise: (schema: yup.ArraySchema<any[], yup.AnyObject, undefined, ''>) => schema.notRequired(),
    }),
  })
  .required();
