import { useMemo, useState } from 'react';
// react-table components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  Column,
  HeaderGroup,
} from 'react-table';

// @mui material components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Autocomplete from '@mui/material/Autocomplete';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';

// Material Dashboard 2 PRO React TS examples components
import DataTableHeadCell, { Sorted } from 'examples/Tables/DataTable/DataTableHeadCell';
import DataTableBodyCell from 'examples/Tables/DataTable/DataTableBodyCell';
import { CircularProgress, Stack, TableCell } from '@mui/material';

// Declaring props types for DataTable

declare module 'react-table' {
  interface UseTableColumnOptions<D extends object = {}> {
    align?: 'left' | 'center' | 'right';
  }
}

export type ColumnItem = {
  Header: string;
  accessor: string;
  align?: string;
  width?: string;
};

export interface DataTableProps<T extends object> {
  entriesPerPage: {
    defaultValue: number;
    entries?: number[];
  };
  canSearch?: boolean;
  table: {
    columns: Column<T>[];
    rows: T[];
  };
  isSorted?: boolean;
  noEndBorder?: boolean;
  isSearching?: boolean;
  onChangeEntriesPerPage?: (value: number) => void;
  onChangeSearchHandler?: (value?: string) => Promise<void>;
  currentPage?: number;
  hiddenColumns?: string[];
  emptySearchStateMessage?: string;
}

function DataTable<T extends object>({
  entriesPerPage,
  canSearch,
  table,
  isSorted,
  noEndBorder,
  onChangeEntriesPerPage,
  onChangeSearchHandler,
  isSearching,
  currentPage,
  hiddenColumns = [],
  emptySearchStateMessage,
}: DataTableProps<T>): JSX.Element {
  let defaultValue: number;
  let entries: Array<number>;

  if (entriesPerPage) {
    defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
    entries = entriesPerPage.entries ? entriesPerPage.entries : [5, 10, 15, 20];
  }

  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { hiddenColumns, pageIndex: currentPage - 1, pageSize: defaultValue || 10 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    setPageSize,
    setGlobalFilter,
    state: { pageSize, globalFilter },
  } = tableInstance;

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value: number) => setPageSize(value);

  // Search input value state
  const [search, setSearch] = useState(globalFilter || '');

  // Search input state handle
  const onSearchChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
    onChangeSearchHandler(value);
  }, 150);

  // A function that sets the sorted value for the table
  const setSortedValue = (column: HeaderGroup<T>) => {
    let sortedValue: Sorted;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? 'desc' : 'asce';
    } else if (isSorted) {
      sortedValue = 'none';
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  return (
    <TableContainer sx={{ boxShadow: 'none' }}>
      {entriesPerPage || canSearch ? (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize}
                options={entries}
                getOptionLabel={option => option.toString()}
                isOptionEqualToValue={(option, value) => option == value}
                onChange={(_, newValue) => {
                  setEntriesPerPage(Number(newValue || 0));
                  onChangeEntriesPerPage(Number(newValue || 0));
                }}
                size="small"
                sx={{ width: '5rem' }}
                renderInput={params => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search || ''}
                size="small"
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSearch(event.target.value);
                  onSearchChange(event.target.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup, key) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, key) => (
                <DataTableHeadCell
                  key={key}
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : 'auto'}
                  align={column.align ? column.align : 'left'}
                  sorted={setSortedValue(column)}
                >
                  {column.render('Header')}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {isSearching ? (
            <TableRow>
              <TableCell colSpan={headerGroups?.[0].headers?.length}>
                <Stack justifyContent="center" alignItems="center" height={200}>
                  <CircularProgress />
                </Stack>
              </TableCell>
            </TableRow>
          ) : search && !table?.rows.length ? (
            <TableRow>
              <TableCell colSpan={headerGroups?.[0].headers?.length}>
                <Stack justifyContent="center" alignItems="center" height={200}>
                  <MDTypography>{`${
                    emptySearchStateMessage || 'No users'
                  } with search value "${search}" were found...`}</MDTypography>
                </Stack>
              </TableCell>
            </TableRow>
          ) : (
            rows.map((row, key) => {
              prepareRow(row);
              return (
                <TableRow key={key} {...row.getRowProps()}>
                  {row.cells.map((cell, key) => (
                    <DataTableBodyCell
                      key={key}
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : 'left'}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// Declaring default props for DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  isSorted: true,
  noEndBorder: false,
};

export default DataTable;
