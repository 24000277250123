import { FC, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardContent, CardHeader, InputAdornment, Stack } from '@mui/material';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import MDButton from 'components/MDButton';
import {
  BaseFormField,
  CRMCasesSearch,
  Country,
  CountryState,
  ToastType,
  crmIntegrationApiService,
  notice,
  useGeoData,
} from 'features/common';
import { CREATE_CASE_FORM_STEPS, useCreateCase } from 'features/store';
import { ClientSchema, clientFormSchema } from 'features/my-legal-cases/components/CreateLegalCase/form.config';
import { myClientsApiService } from 'features/my-clients/MyClientsApiService';
import { BaseAutocompleteField } from 'features/common/components/BaseAutocompleteField';
import { getOptionById } from 'features/common/helpers/utilities';
import { emailRegExp } from 'features/auth';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';
import { ImportFromCRMDialogWindow } from 'features/my-legal-cases/components/ImportCRMLegalCase/ImportFromCRMDialogWindow';
import { ButtonsBox } from 'features/my-clients/components/AttorneyCreateCase/ButtonsBox';

export const ClientInformation: FC = () => {
  const {
    saveFormDataHandler,
    setClientInvitationData,
    activeStep,
    setStateData,
    CRMCaseData,
    clientInformationData: {
      country,
      email,
      firstName,
      lastName,
      state,
      clientId,
      clientLogoURL,
      clientCaseCount,
      clientRating,
    },
  } = useCreateCase(state => ({
    clientInformationData: state.clientData,
    activeStep: state.activeStep,
    saveFormDataHandler: state.setData,
    setStateData: state.setStateData,
    setClientInvitationData: state.setClientInvitationData,
    CRMCaseData: state.CRMCaseData,
  }));

  const {
    isStateListLoading,
    countryList,
    countryStateList,
    onGetCountryListHandler,
    getStateListHandler,
    onGetCountryListHandlerState,
  } = useGeoData(false);

  const firstNameValue = firstName ? firstName : CRMCaseData?.client?.firstName || '';

  const lastNameValue = lastName ? lastName : CRMCaseData?.client?.lastName || '';

  const emailValue = email ? email : CRMCaseData?.client?.email || '';

  const countryValue = country ? country : '';

  const stateValue = state ? state : '';

  const [isAddNewUser, setIsAddNewUser] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isValid },
    setError,
  } = useForm<ClientSchema>({
    resolver: yupResolver(clientFormSchema),
    mode: 'onTouched',
    values: {
      firstName: firstNameValue,
      lastName: lastNameValue,
      email: emailValue,
      country: countryValue,
      state: stateValue,
    },
  });

  const onFormSubmitHandler = handleSubmit(formData => {
    saveFormDataHandler({
      step: activeStep,
      data: {
        ...formData,
        clientId,
        clientLogoURL,
        clientCaseCount,
        clientRating,
      },
      activeStep: CREATE_CASE_FORM_STEPS.CASE_INFO,
    });

    !clientId && setClientInvitationData({ country: formData.country, state: formData.state });
  });

  const [isSearching, setIsSearching] = useState(false);
  const [isNotFoundClient, setIsNotFoundClient] = useState(false);

  const [notFoundMessage, setNotFoundMessage] = useState('');

  const { searchClientByEmail } = myClientsApiService();

  const onClickAddNewUserHandler = async () => {
    setIsAddNewUser(true);
    setIsNotFoundClient(false);
    setNotFoundMessage('');

    await onGetCountryListHandler();

    if (!CRMCaseData.internalUser) {
      if (CRMCaseData?.client?.state?.['@id']) {
        await getStateListHandler();
        setValue('state', CRMCaseData?.client?.state?.['@id'], { shouldValidate: true });
      } else {
        await getStateListHandler();
      }
      CRMCaseData?.client?.country?.['@id'] &&
        setValue('country', CRMCaseData?.client?.country?.['@id'], { shouldValidate: true });
    }
  };

  const onClickFindClientButtonHandler = async () => {
    try {
      const email = watch('email');
      if (!email.match(emailRegExp)) {
        setError('email', { message: 'Please enter a valid email' });
        return;
      }
      setIsSearching(true);
      const { data } = await searchClientByEmail(email);

      setIsSearching(false);

      setStateData('clientData', {
        clientId: data?.['@id'],
        clientLogoURL: data?.logo?.publicUrl,
        clientCaseCount: data?.legalCaseCount,
        clientRating: data?.rating,
        firstName: data?.userProfile?.firstName,
        lastName: data?.userProfile?.lastName,
        country: data?.userProfile?.country?.['@id'],
        email: data?.email,
        state: data?.userProfile?.state?.['@id'],
      });

      isNotFoundClient && setIsNotFoundClient(false);
      isAddNewUser && setIsAddNewUser(false);
      notFoundMessage && setNotFoundMessage('');
    } catch (error: any) {
      setIsSearching(false);
      if (error?.response?.status === 404) {
        setIsNotFoundClient(true);
        setNotFoundMessage('We did not find this client`s email in our system');
      } else {
        paymentRequiredErrorHandler(error);
      }
      console.error(error);
    }
  };

  const onClickTryAgainButtonHandler = () => {
    setIsAddNewUser(false);
    setIsNotFoundClient(false);
    setNotFoundMessage('');
    setValue('email', emailValue);
  };

  const countryCurrentValue = watch('country');
  const stateCurrentValue = watch('state');

  const { getSearchCRMCases, getCRMCase } = crmIntegrationApiService();

  const [isCRMModalWindowOpen, setIsCRMModalWindowOpen] = useState(!CRMCaseData);
  const [searchValue, setSearchValue] = useState('');

  const [isSentReq, setIsSentReq] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [CRMCases, setCRMCases] = useState<CRMCasesSearch[]>([]);

  const onChangeInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value);

  const onClickFindButtonHandler = async () => {
    try {
      setIsLoading(true);
      const { data } = await getSearchCRMCases(searchValue);
      setCRMCases(data['hydra:member']);
      setIsLoading(false);
      setIsSentReq(true);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, plase try again!');
    }
  };
  const [selectedCRMCase, setSelectedCRMCase] = useState<CRMCasesSearch | null>(null);

  const onChangeRadioButtonHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedCase = CRMCases?.find(crmCase => crmCase?.id === event.target.value);
    setSelectedCRMCase(selectedCase);
  };

  const [isFetchingCRMCaseData, setIsFetchingCRMCaseData] = useState(false);

  const onClickNextButtonHandler = async () => {
    try {
      if (selectedCRMCase?.alreadySynced) {
        notice(ToastType?.ERROR, 'This matter has already been imported.');
        return;
      }

      setIsFetchingCRMCaseData(true);

      const { data } = await getCRMCase(selectedCRMCase?.id, selectedCRMCase?.provider);

      setStateData('CRMCaseData', data);

      if (data?.internalUser) {
        setStateData('clientData', {
          clientId: data.internalUser?.['@id'],
          clientLogoURL: data.internalUser?.logo?.publicUrl,
          clientCaseCount: data.internalUser?.legalCaseCount,
          clientRating: data.internalUser?.rating,
          firstName: data.internalUser?.userProfile?.firstName,
          lastName: data.internalUser?.userProfile?.lastName,
          country: data.internalUser?.userProfile?.country?.['@id'],
          email: data.internalUser?.email,
          state: data.internalUser?.userProfile?.state?.['@id'],
        });
      }

      setIsCRMModalWindowOpen(false);
      setIsFetchingCRMCaseData(false);
    } catch (error) {
      setIsFetchingCRMCaseData(false);
      console.error(error);
      if (error instanceof AxiosError) {
        if (error.response.status === 422) {
          notice(ToastType.ERROR, error?.response.data?.['hydra:description']);
          return;
        }
        paymentRequiredErrorHandler(error);
      }
    }
  };

  useEffect(() => {
    if (state && country) {
      onGetCountryListHandler();
      getStateListHandler(country);
    }
  }, [clientId]);

  const isShowInputs = email || isAddNewUser || !!clientId;

  const isShowAutocompleteFields = !!clientId || isAddNewUser || (!!state && !!country);

  const countryAutocompleteValue = getOptionById(countryCurrentValue, countryList);
  const stateAutocompleteValue = getOptionById(stateCurrentValue, countryStateList);

  return (
    <form onSubmit={onFormSubmitHandler}>
      <Card>
        <CardHeader title="Client Information" />
        <CardContent>
          <Stack direction={{ xs: 'column', sm: 'row' }} gap={{ xs: 0, sm: 3 }} width={1}>
            <Stack width={1}>
              <BaseFormField
                formInputProps={{
                  ...register('email'),
                  type: 'text',
                  label: 'Email',
                  autoComplete: 'new-email',
                  fullWidth: true,
                  InputLabelProps: { shrink: true },
                  disabled: isNotFoundClient || isAddNewUser || clientId || (email && !clientId),
                  InputProps: {
                    sx: {
                      'input:-webkit-autofill, input:-webkit-autofill:focus': {
                        transition: 'background-color 600000s 0s, color 600000s 0s',
                      },
                    },
                    endAdornment: (
                      <>
                        {!!clientId ? (
                          <InputAdornment position="end">
                            <DoneOutlinedIcon fontSize="medium" color="success" />
                          </InputAdornment>
                        ) : null}
                      </>
                    ),
                  },
                }}
                errorValue={errors['email']?.message || notFoundMessage}
              />
              {isShowInputs && (
                <>
                  <BaseFormField
                    formInputProps={{
                      ...register('firstName'),
                      type: 'text',
                      InputLabelProps: { shrink: true },
                      label: 'First name',
                      fullWidth: true,
                      disabled: !!clientId && !isAddNewUser,
                    }}
                  />
                  <BaseFormField
                    formInputProps={{
                      ...register('lastName'),
                      type: 'text',
                      InputLabelProps: { shrink: true },
                      label: 'Last name',
                      fullWidth: true,
                      disabled: !!clientId && !isAddNewUser,
                    }}
                  />
                </>
              )}
            </Stack>
            <Stack width={1}>
              <ButtonsBox
                isAddNewButtonDisabled={isSearching || !!errors['email']?.message}
                isFindClientButtonDisabled={isSearching || !!errors['email']?.message || !watch('email')}
                isLoading={isSearching}
                isAddNewButton={isNotFoundClient && !isAddNewUser && !email}
                isFindButton={!isAddNewUser && !clientId && !email}
                onClickAddNewUserHandler={onClickAddNewUserHandler}
                onClickFindClientButtonHandler={onClickFindClientButtonHandler}
                onClickTryAgainButtonHandler={onClickTryAgainButtonHandler}
              />

              {isShowAutocompleteFields && (
                <>
                  <Controller
                    control={control}
                    name="country"
                    render={({ field: { onChange } }) => {
                      const onChangeHandler = async (value: Country) => {
                        onChange(value['@id']);

                        !countryCurrentValue && !stateCurrentValue && (await getStateListHandler(value['@id']));
                      };
                      return (
                        <BaseAutocompleteField
                          options={countryList}
                          isDisabled={!!clientId && !isAddNewUser}
                          getOptionLabel={option => option.name}
                          value={countryAutocompleteValue || null}
                          onChangeHandler={onChangeHandler}
                          errorMessage={errors['country']?.message}
                          disableClearable
                          isLoading={onGetCountryListHandlerState.isPending()}
                          autocompleSxProps={{
                            width: 1,
                            '.MuiInputBase-root': {
                              py: '4.5px',
                            },
                          }}
                          formInputProps={{
                            label: 'Country',
                          }}
                          placeholder="Select a country"
                        />
                      );
                    }}
                  />

                  <Controller
                    control={control}
                    name="state"
                    render={({ field: { onChange } }) => {
                      const onChangeHandler = async (value: CountryState) => {
                        onChange(value['@id']);
                      };
                      return (
                        <BaseAutocompleteField
                          options={countryStateList}
                          isDisabled={!!clientId || (!stateCurrentValue && !countryCurrentValue)}
                          getOptionLabel={option => option.name}
                          value={stateAutocompleteValue || null}
                          onChangeHandler={onChangeHandler}
                          errorMessage={errors['country']?.message}
                          isLoading={isStateListLoading}
                          disableClearable
                          autocompleSxProps={{
                            width: 1,
                            '.MuiInputBase-root': {
                              py: '4.5px',
                            },
                          }}
                          formInputProps={{
                            label: 'State',
                          }}
                          placeholder={!countryCurrentValue ? 'Select a country first' : 'Select a state'}
                        />
                      );
                    }}
                  />
                </>
              )}
            </Stack>
          </Stack>
          <Stack alignItems="flex-end">
            <MDButton type="submit" variant="gradient" color="dark" disabled={!isValid}>
              Next
            </MDButton>
          </Stack>
        </CardContent>
      </Card>

      <ImportFromCRMDialogWindow
        isCRMModalWindowOpen={isCRMModalWindowOpen}
        searchValue={searchValue}
        isLoading={isLoading}
        onClickNextButtonHandler={onClickNextButtonHandler}
        onChangeInputHandler={onChangeInputHandler}
        onClickFindButtonHandler={onClickFindButtonHandler}
        CRMCases={CRMCases}
        onChangeRadioButtonHandler={onChangeRadioButtonHandler}
        isSentReq={isSentReq}
        isFetchingCRMCaseData={isFetchingCRMCaseData}
        selectedCRMCaseId={selectedCRMCase?.id}
      />
    </form>
  );
};
