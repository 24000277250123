import { FC } from 'react';
import { AgoraVideoPlayer, IAgoraRTCRemoteUser } from 'agora-rtc-react';
import { Stack } from '@mui/material';
import { MicOffRounded, VideocamOffRounded } from '@mui/icons-material';
import MDBox from 'components/MDBox';
import { getAvatarCharacters } from 'features/common/helpers/utilities';
import { AgoraEventType, EventParticipant } from 'features/common/services/agoraEventApiService';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import './animationCall.css';

type LocalParticipantProps = {
  remoteParticipant: IAgoraRTCRemoteUser;
  eventRemoteParticipant: EventParticipant;
  isRemoteUserAudioMuted: boolean;
  isRemoteUserVideoMuted: boolean;
  eventType: AgoraEventType;
  isCalling: boolean;
};

export const RemoteParticipant: FC<LocalParticipantProps> = ({
  eventRemoteParticipant,
  remoteParticipant,
  isRemoteUserAudioMuted,
  eventType,
  isCalling,
}) => {
  const avatarCharacters = getAvatarCharacters(
    eventRemoteParticipant?.user?.userProfile?.firstName,
    eventRemoteParticipant?.user?.userProfile?.lastName
  );

  return (
    <>
      {!!remoteParticipant ? (
        <MDBox
          key={remoteParticipant.uid}
          sx={{
            minHeight: '200px',
            width: 1,
            height: 1,
            display: 'flex',
            flex: 1,
            position: 'relative',
            video: {
              borderRadius: '10px',
            },
          }}
        >
          <MDBox position="relative" sx={{ width: 1, height: 1 }}>
            {(isRemoteUserAudioMuted || !remoteParticipant?.videoTrack) && (
              <Stack
                sx={{ position: 'absolute', top: '10px', left: '10px', zIndex: 11 }}
                direction="row"
                justifyContent="space-between"
                spacing={2}
                alignItems="center"
              >
                {isRemoteUserAudioMuted && (
                  <MDBox
                    sx={{
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white !important',
                      width: 40,
                      height: 40,
                    }}
                  >
                    <MicOffRounded />
                  </MDBox>
                )}

                {!remoteParticipant?.videoTrack && eventType === 'video_call' && (
                  <MDBox
                    sx={{
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white !important',
                      width: 40,
                      height: 40,
                    }}
                  >
                    <VideocamOffRounded />
                  </MDBox>
                )}
              </Stack>
            )}
            <MDBox
              sx={{
                width: 1,
                height: 1,
                display: !remoteParticipant.videoTrack ? 'flex' : 'none',
                position: 'absolute',
                backgroundColor: ({ palette }) => palette.grey[700],
                top: '0',
                left: '0',
                right: 0,
                bottom: 0,
                zIndex: 10,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '10px',
              }}
            >
              <MDAvatar
                src={eventRemoteParticipant?.user?.logo?.publicUrl}
                sx={{
                  height: '100px',
                  width: '100px',
                  borderRadius: '50%',
                  fontSize: '18px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white !important',
                  border: `2px solid white`,
                }}
              >
                {avatarCharacters}
              </MDAvatar>
            </MDBox>
            {remoteParticipant?.videoTrack && (
              <MDBox
                sx={{
                  height: 1,
                  width: 1,
                  'div > div': {
                    borderRadius: '10px',
                    backgroundColor: 'transparent !important',
                  },
                }}
              >
                <AgoraVideoPlayer
                  style={{ height: '100%', width: '100%', backgroundColor: 'none' }}
                  videoTrack={remoteParticipant.videoTrack}
                />
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      ) : isCalling ? (
        <Stack justifyContent="center" alignItems="center" flex={1} position="relative">
          <MDBox className="circle-animation" />
          <MDAvatar
            src={eventRemoteParticipant?.user?.logo?.publicUrl}
            size="xxl"
            sx={{ color: 'white !important', border: `2px solid white` }}
          >
            {avatarCharacters}
          </MDAvatar>
        </Stack>
      ) : eventType !== 'video_call' ? (
        <Stack justifyContent="center" alignItems="center" flex={1}>
          <MDTypography sx={{ color: 'white !important' }}>Only you at this conversation</MDTypography>
        </Stack>
      ) : null}
    </>
  );
};
