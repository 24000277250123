import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';

import { BaseFormFieldProps } from './types';

export const BaseFormField = ({ formFieldProps, formInputProps, errorValue }: BaseFormFieldProps): JSX.Element => {
  return (
    <MDBox position="relative" pb="18px" {...formFieldProps}>
      <MDInput {...formInputProps} error={Boolean(errorValue)} />
      {errorValue && (
        <MDTypography position="absolute" fontSize={10} mt={0.1} color="error">
          * {errorValue}
        </MDTypography>
      )}
    </MDBox>
  );
};
