import { FC, memo } from 'react';
import { CssBaseline, ThemeProvider as MUIThemeProvider, Theme } from '@mui/material';
import { useMaterialUIController } from 'context';

type ThemeProviderProps = {
  children: React.ReactNode;
  theme: Theme;
};

export const ThemeProvider: FC<ThemeProviderProps> = memo(({ children, theme }) => {
  const [controller] = useMaterialUIController();

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
});
