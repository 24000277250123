import { FC, memo, useCallback, useState } from 'react';
import { Card } from '@mui/material';
import MDBox from 'components/MDBox';
import { Loader } from 'features/common/components/Loader';
import { Videos } from './Components/Videos';
import { Controls } from './Components/Controls';
import { AudioVideoRoomProps } from './types';
import { Timer } from './Components/Timer';

export const AudioVideoRoom: FC<AudioVideoRoomProps> = memo(
  ({
    isLoading,
    eventRemoteParticipant,
    eventType,
    isCalling,
    isReconnecting,
    isRemoteUserAudioMuted,
    isRemoteUserVideoMuted,
    localParticipant,
    remoteParticipant,
    audioTrack,
    videoTrack,
    isRunning,
    timerRef,
    eventId,
    headerLink,
    isAttorney,
    isChatOpen,
    legalCaseId,
    isCaseOnConsultation,
    setRepresentationId,
    onLeaveChannel,
    onChatIconClickHandler,
    updateEventdata,
  }) => {
    const [isAudioMuted, setIsAudioMuted] = useState(false);
    const [isVideoMuted, setIsVideoMuted] = useState(eventType === 'voice_call' ? true : false);

    const handleMuteAudio = useCallback(async () => {
      try {
        const isMuted = isAudioMuted ? false : true;
        await audioTrack?.setMuted(isMuted);
        setIsAudioMuted(isMuted);
      } catch (error) {
        console.error(error);
      }
    }, [audioTrack, isAudioMuted]);

    const handleMuteVideo = useCallback(async () => {
      try {
        const isMuted = isVideoMuted ? false : true;
        await videoTrack?.setMuted(isMuted);
        setIsVideoMuted(isMuted);
      } catch (error) {
        console.error(error);
      }
    }, [videoTrack, isVideoMuted]);

    return (
      <MDBox sx={{ height: 1, width: 1 }}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: 1,
            width: 1,
            justifyContent: 'center',
            minHeight: 450,
            backgroundColor: ({ palette }) => palette.grey[700],
          }}
        >
          {
            <MDBox
              sx={{
                display: 'flex',
                flex: 1,
                height: 1,
                flexDirection: 'column',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              {isLoading ? (
                <MDBox
                  sx={{
                    width: 1,
                    height: 1,
                    display: 'flex',
                  }}
                >
                  <Loader />
                </MDBox>
              ) : (
                <>
                  <MDBox
                    sx={{
                      height: 1,
                      width: 1,
                      position: 'relative',
                      borderRadius: '10px',
                      backgroundColor: ({ palette }) => palette.grey[700],
                    }}
                  >
                    <Videos
                      isRemoteUserAudioMuted={isRemoteUserAudioMuted}
                      isRemoteUserVideoMuted={isRemoteUserVideoMuted}
                      eventRemoteParticipant={eventRemoteParticipant}
                      localParticipant={localParticipant}
                      remoteParticipant={remoteParticipant}
                      tracks={[audioTrack, videoTrack]}
                      eventType={eventType}
                      isReconnecting={isReconnecting}
                      isCalling={isCalling}
                    />
                  </MDBox>
                  <Controls
                    handleMuteVideo={handleMuteVideo}
                    handleMuteAudio={handleMuteAudio}
                    isMicDisabled={isAudioMuted}
                    isVideoDisabled={isVideoMuted}
                    isRemoteParticipantJoined={!!remoteParticipant}
                    isReconnecting={isReconnecting}
                    isChatOpen={isChatOpen}
                    onLeaveChannel={onLeaveChannel}
                    onChatIconClickHandler={onChatIconClickHandler}
                    eventType={eventType}
                    isAttorney={isAttorney}
                    isCaseOnConsultation={isCaseOnConsultation}
                    eventId={eventId}
                    legalCaseId={legalCaseId}
                    setRepresentationId={setRepresentationId}
                    remoteParticipantFirebaseId={eventRemoteParticipant?.user?.firebaseUser}
                    headerLink={headerLink}
                    updateEventdata={updateEventdata}
                    remoteParticipantData={
                      !!eventRemoteParticipant
                        ? {
                            firstName: eventRemoteParticipant?.user?.userProfile?.firstName,
                            lastName: eventRemoteParticipant?.user?.userProfile?.lastName,
                            logoUrl: eventRemoteParticipant?.user?.logo?.publicUrl,
                          }
                        : null
                    }
                  >
                    <Timer isRunning={isRunning} timerRef={timerRef} />
                  </Controls>
                </>
              )}
            </MDBox>
          }
        </Card>
      </MDBox>
    );
  }
);
