import { FC, ChangeEvent, useState, useEffect } from 'react';
import { PhoneAuthProvider, UserCredential } from '@firebase/auth';
import { AUTH_FLOW_STEPS, useAuth, useLockScreen } from 'features/store';
import {
  BaseFormField,
  IllustrationLayout,
  ToastType,
  createBackendUserService,
  notice,
  useRouter,
} from 'features/common';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { useCountdown } from 'features/common/hooks/useCountdown';
import { ROUTES } from 'features/common/variables/routes';
import { createFirebaseService } from '../services';
import { useCaptchaVerifier } from '../contexts';
import { AuthFlowBackgrounds } from '../variables/illustartion';
import { Illustrationtitle } from 'features/common/components/IllustatrionLogo';

export const VerifyPhoneStep: FC = () => {
  const { firebaseAuthData, resetAllStepsData, acceptAdminInvitationHandler, setBackendUser, toStep, setData } =
    useAuth(state => ({
      toStep: state.toStep,
      setData: state.setData,
      setBackendUser: state.setBackendUser,
      firebaseAuthData: state.firebasePhoneAuthData,
      acceptAdminInvitationHandler: state.acceptAdminInvitationHandler,
      resetAllStepsData: state.resetAllStepsData,
    }));

  const { params, push } = useRouter();

  const { getBackendUser } = createBackendUserService();
  const { sendVerificationCodeOnPhoneNumber, authWithCredential } = createFirebaseService();
  const { firebaseAppVerifier } = useCaptchaVerifier();
  const { isFinished: isCountDownFinished, countdown, startCountdown } = useCountdown(30);

  useEffect(() => {
    startCountdown();
  }, []);

  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState(firebaseAuthData.verificationId || '');

  const onChangeInputValueHandler = (event: ChangeEvent<HTMLInputElement>) => setVerificationCode(event.target.value);

  const onFirebaseUserConnected = async () => {
    try {
      const backendUser = await getBackendUser();

      if (backendUser) {
        const isAdmin = backendUser?.roles?.includes('ROLE_ADMIN');
        setBackendUser(backendUser);
        resetAllStepsData();
        push(isAdmin ? ROUTES.contentItems : ROUTES.myLegalCases).then(() => {
          notice(ToastType.SUCCESS, `Welcome!`);
        });
      } else {
        toStep(AUTH_FLOW_STEPS.WORK_TYPE);
      }
    } catch (error) {
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  };

  const onVerifyCodeButtonClickHandler = async (): Promise<UserCredential | void> => {
    try {
      const authCredential = PhoneAuthProvider.credential(verificationId, verificationCode);

      const response = await authWithCredential(authCredential);
      if (response && response.user?.uid) {
        await acceptAdminInvitationHandler(params.accessCode);
        await onFirebaseUserConnected();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSendCodeHandler = async () => {
    try {
      const confirmationResult = await sendVerificationCodeOnPhoneNumber(
        firebaseAuthData.phoneNumber,
        firebaseAppVerifier
      );
      if (confirmationResult) {
        notice(ToastType.SUCCESS, "We've sent verification message to your phone!", { autoClose: false });
        setVerificationId(confirmationResult.verificationId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const resendCodeHandler = async () => {
    startCountdown();
    await onSendCodeHandler();
  };

  const isVerifyCodeBtnDisabled = false;

  const onClickBackButton = () => toStep(AUTH_FLOW_STEPS.FIREBASE_AUTH);

  return (
    <IllustrationLayout
      title="Verification code"
      description={firebaseAuthData.phoneNumber && `Code is sent to ${firebaseAuthData.phoneNumber}`}
      onClickBack={onClickBackButton}
      illustration={AuthFlowBackgrounds.AUTH_ONE}
      illustrationLogo={<Illustrationtitle />}
    >
      <MDBox>
        <MDBox>
          <BaseFormField
            formInputProps={{
              value: verificationCode,
              onChange: onChangeInputValueHandler,
              type: 'text',
              label: 'Verification Code',
              fullWidth: true,
            }}
          />
          <MDBox display="flex" justifyContent="flex-end">
            {isCountDownFinished ? (
              <MDButton onClick={resendCodeHandler}>Resend sms</MDButton>
            ) : (
              <MDTypography sx={{ fontSize: '14px' }}>Resend sms after {countdown}</MDTypography>
            )}
          </MDBox>
          <MDBox mt={4} mb={1} display="flex" flexDirection="column" gap="20px">
            <MDButton
              variant="gradient"
              color="info"
              type="submit"
              fullWidth
              sx={{ textTransform: 'none' }}
              onClick={onVerifyCodeButtonClickHandler}
              disabled={isVerifyCodeBtnDisabled}
            >
              Next
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
};
