import axios from 'axios';
import { UseFormSetError } from 'react-hook-form';
import { ToastType, notice } from 'features/common';
import { CompanyInvitation, GetUserCompanyResponse, myCompanyApiService } from 'features/my-company/services';
import { createFileService } from 'features/common/services/filesService';
import {
  COMPANY_ROLES,
  CompanyUser,
  PutLawTypesPayload,
  UserPermissions,
  createCompanyService,
} from 'features/company-list';
import { createBackendAuthService } from 'features/auth';
import { RegisterCompanySchema } from 'features/my-company/components/RegisterCompanyForm';
import { Pagination } from 'features/case-law-types';
import { createStore } from '../createStore';
import { immer } from 'zustand/middleware/immer';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders/paymentRequiredErrorHandler';
import { baseBackendErrorHandler } from 'features/common/errorHanders';
import { isPhoneValid } from 'features/common/helpers/utilities';
import { GetContentItemCatagoryBySlugResponse, createContentItemService } from 'features/content-items';

export type MyCompanyState = {
  myCompany: GetUserCompanyResponse | null;
  companyUsersData: {
    users: CompanyUser[];
    totalItems: number | null;
  };
  companyInvitationData: {
    invitations: CompanyInvitation[];
    totalItems: number | null;
  };
  registerCompanyDescription: GetContentItemCatagoryBySlugResponse;
};

export type MyCompanyActions = {
  fetchUserCompanyHandler: () => Promise<void>;
  uploadCompanyLogo: (logoFormData: FormData) => Promise<void>;
  updateUserCompanyHandler: (
    formData: RegisterCompanySchema,
    setError: UseFormSetError<RegisterCompanySchema>
  ) => Promise<void>;
  updateCompanyCaseLawTypesHandler: (payload: PutLawTypesPayload) => Promise<void>;
  getCompanyUsersHandler: (params: Pagination) => Promise<void>;
  updateUserPermissions: (payload: UserPermissions) => Promise<void>;
  deleteCompanyUserHandler: (userId: string) => Promise<void>;
  updateCompanyUserHandler: (userId: string, role: COMPANY_ROLES) => Promise<void>;
  getCompanyInvitationsHandler: (params: Pagination) => Promise<void>;
  declineInvitationHandler: (invitationId: string) => Promise<void>;
  approveInvitationHandler: (invitationId: string) => Promise<void>;
  getMoreInvitationsHandler: (params: Pagination) => Promise<void>;
  getCreateCompanyDescription: () => Promise<void>;
};

const { getUserCompany, declineInvitation, approveInvitation, getMineInvitationToTheCompany } = myCompanyApiService();
const { createFile } = createFileService();
const {
  updateCompanyUser,
  deleteCompanyUser,
  attachCompanyLogo,
  patchCompanyById,
  updateCompanyInfo,
  updateCompanyCaseLawTypes,
  getCompanyUsers,
} = createCompanyService();
const { updatePostalAddress } = createBackendAuthService();

const { getContentItemCategoriesBySlug } = createContentItemService();

const initialState: MyCompanyState = {
  myCompany: {} as GetUserCompanyResponse,
  companyInvitationData: {
    invitations: [],
    totalItems: null,
  },
  companyUsersData: {
    users: [],
    totalItems: null,
  },
  registerCompanyDescription: { '@id': '', '@type': 'ContentItem', htmlContent: '', name: '' },
};

export const useMyCompany = createStore<MyCompanyState & MyCompanyActions>(
  immer((set, get) => ({
    ...initialState,
    getCreateCompanyDescription: async () => {
      try {
        const { data } = await getContentItemCategoriesBySlug();
        const registerCompanyDescription = data?.['hydra:member']?.find(el => el.name === 'register_firm_description');

        set({
          registerCompanyDescription,
        });
      } catch (error) {
        console.error(error);
        notice(ToastType.ERROR, 'Failed to get create company description, please try again!');
      }
    },
    getCompanyInvitationsHandler: async params => {
      try {
        const { data } = await getMineInvitationToTheCompany(params);

        set({
          companyInvitationData: { invitations: data['hydra:member'], totalItems: data['hydra:totalItems'] },
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch company invitation, please try again!');
        console.error(error);
      }
    },

    getMoreInvitationsHandler: async params => {
      try {
        const { data } = await getMineInvitationToTheCompany(params);

        set(state => {
          state.companyInvitationData.invitations.push(...data['hydra:member']);
        });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to fetch invitation offers, please try again!');
        console.error(error);
      }
    },

    approveInvitationHandler: async id => {
      try {
        await approveInvitation(id?.replace('/company_attorney_invitations/', ''));

        await get().fetchUserCompanyHandler();
        set(state => {
          state.companyInvitationData.invitations = [];
          state.companyInvitationData.totalItems = null;
        });

        notice(ToastType.SUCCESS, 'Successfully accepted!');
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to approve invitation offer, please try again!');
        console.error(error);
      }
    },
    declineInvitationHandler: async id => {
      try {
        await declineInvitation(id?.replace('/company_attorney_invitations/', ''));

        set(state => {
          const totalItems = state.companyInvitationData.totalItems;
          const invitations = state.companyInvitationData.invitations;

          state.companyInvitationData.invitations = invitations.filter(el => el?.['@id'] !== id);
          state.companyInvitationData.totalItems = totalItems ? state.companyInvitationData.totalItems - 1 : totalItems;
        });

        notice(ToastType.SUCCESS, 'Successfully declined!');
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to decline invitation offer, please try again!');
        console.error(error);
      }
    },
    updateCompanyUserHandler: async (userId, role) => {
      try {
        const { data } = await updateCompanyUser(userId?.replace('/company_users/', ''), role);

        set(state => ({
          companyUsersData: {
            ...state.companyUsersData,
            users: state.companyUsersData.users.map(companyUser =>
              companyUser?.['@id'] === userId ? data : companyUser
            ),
          },
        }));
        notice(ToastType.SUCCESS, 'Successfully updated!');
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to update user, please try again!');
        console.error(error);
      }
    },
    deleteCompanyUserHandler: async userId => {
      try {
        await deleteCompanyUser(userId);
        await get().fetchUserCompanyHandler();
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to delete user, please try again!');
        console.error(error);
      }
    },
    updateUserPermissions: async payload => {
      try {
        const companyId = get().myCompany?.id;
        const { data } = await patchCompanyById(companyId, { userPermissions: payload });

        set(state => ({ myCompany: { ...state.myCompany, userPermissions: data?.userPermissions } }));

        notice(ToastType.SUCCESS, 'Successfully updated!');
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to update permissions, please try again!');
        console.error(error);
      }
    },

    getCompanyUsersHandler: async params => {
      try {
        const company = get().myCompany;
        const { data } = await getCompanyUsers(company?.id, params);

        set({ companyUsersData: { users: data['hydra:member'], totalItems: data['hydra:totalItems'] } });
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to get company users, please try again!');
        console.error(error);
      }
    },
    updateCompanyCaseLawTypesHandler: async payload => {
      try {
        const company = get().myCompany;
        const { data } = await updateCompanyCaseLawTypes(company?.id, payload);

        set(state => ({ myCompany: { ...state.myCompany, caseLawTypes: data?.caseLawTypes } }));

        notice(ToastType.SUCCESS, 'Successfully updated!');
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to update the company law types, please try again!');
        console.error(error);
      }
    },
    updateUserCompanyHandler: async (formData, setError) => {
      try {
        const isValid = isPhoneValid(formData.companyPhone);

        if (!isValid) {
          setError('companyPhone', { message: 'Enter a valid company phone number' });
          return;
        }
        const company = get().myCompany;
        await updatePostalAddress(company?.headquarterAddress?.id, {
          addressLine1: formData.addressLine1,
          addressLine2: formData.addressLine2,
          country: formData.country,
          state: formData.state,
          town: formData.town,
          zipCode: formData.zipCode,
          title: `Company ${formData.fullName} headquarter`,
        });

        const { data } = await updateCompanyInfo(company?.id, {
          description: formData.description,
          fullName: formData.fullName,
          phone: formData.companyPhone,
          shortName: formData.shortName,
          site: formData.site,
        });
        set({ myCompany: data });

        notice(ToastType.SUCCESS, 'Successfully updated!');
      } catch (error) {
        baseBackendErrorHandler(error, { formError: { formData, setError } });
        console.error(error);
      }
    },
    fetchUserCompanyHandler: async () => {
      try {
        const myCompany = await getUserCompany();
        set({ myCompany });
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const { response } = err;
          response.status === 404
            ? set({ myCompany: null })
            : paymentRequiredErrorHandler(err, 'Failed to fetch company, please try again!');
        }
        console.error(err);
      }
    },
    uploadCompanyLogo: async formData => {
      try {
        const companyId = get().myCompany?.id;
        const { data: fileData } = await createFile(formData);
        const { data } = await attachCompanyLogo(companyId, { logo: fileData?.['@id'] });

        set({ myCompany: data });
      } catch (err) {
        paymentRequiredErrorHandler(err, 'Failed to upload company logo, please try again!');
        console.error(err);
      }
    },
  })),
  'myCompany',
  initialState
);
