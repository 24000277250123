import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Card, Stack } from '@mui/material';
import { PracticeAreaCategory } from 'features/clio-settings/ClioSettingsApiService';
import { clioSettingsApiService } from 'features/settings/ClioSettingsApiService';
import { TableHeader } from 'features/common/components/TableHeader';
import { SideMenu } from 'features/common/components/SideMenu';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { baseBackendErrorHandler } from 'features/common/errorHanders';
import MDBox from '../../../components/MDBox';
import { ButtonsBox, CaseLawTypesTable } from '../components';
import { useCaseLawTypes } from '../hooks/useCaseLawTypes';
import { BaseFormField, ToastType, notice, useBoolean, useClioEnums } from '../../common';
import { CreateCaseLawTypeFormSchema, createCaseLawTypeFormSchema, editCaseLawTypeFormSchema } from './formSchema';
import { TemplateWrapper } from './TemplateWrapper';

export const CaseLawTypeListTemplate = (): JSX.Element => {
  const {
    onGetClioMappingPracticeAreaCategoriesEnums,
    clioMappingPracticeAreaCategoriesEnum: { data, isLoading: isClioEnumsLoading },
  } = useClioEnums();

  const [isCaseLawTypesCreateModalOpen, openCaseLawTypesCreateModal, closeCaseLawTypesCreateModal] = useBoolean(false);
  const [isCaseLawTypesDeleteModalOpen, openCaseLawTypesDeleteModal, closeCaseLawTypesDeleteModal] = useBoolean(false);
  const [isCaseLawTypesEditModalOpen, openCaseLawTypesEditModal, closeCaseLawTypesEditModal] = useBoolean(false);

  const { updateClioCaseLawType } = clioSettingsApiService();

  const [selectedCaseId, setSelectedCaseId] = useState('');

  const {
    caseLawTypesList,
    caseLawTypesTotal,
    isLoading,
    currentPage,
    entriesPerPage,
    onChangeEntriesPerPageHandler,
    onChangePageHandler,
    deleteCaseLawTypeById,
    patchCaseLawTypeById,
    createCaseLawType,
    onDeleteItem,
    setIsLoading,
    getItemsHandler,
  } = useCaseLawTypes();

  const {
    reset,
    control,
    handleSubmit,
    register,
    setError,
    formState: { isValid, isSubmitting, errors },
    setValue,
    clearErrors,
  } = useForm<CreateCaseLawTypeFormSchema>({
    resolver: yupResolver(isCaseLawTypesEditModalOpen ? editCaseLawTypeFormSchema : createCaseLawTypeFormSchema),
    defaultValues: { title: '', clioLawTypes: [] },
  });

  const onAddLawTypeButtonClickHandler = () => {
    openCaseLawTypesCreateModal();
    onGetClioMappingPracticeAreaCategoriesEnums();
  };

  const onCreateCaseLawTypeHandler = async (data: CreateCaseLawTypeFormSchema) => {
    try {
      setIsLoading(true);
      const { data: response } = await createCaseLawType(data);

      const practiceAreaCategoriesIds = data?.clioLawTypes.map(el => el.id);
      await updateClioCaseLawType({
        caseLawType: response?.['@id'],
        practiceAreaCategories: practiceAreaCategoriesIds,
      });
      await getItemsHandler();
      reset();
      closeCaseLawTypesCreateModal();
      setIsLoading(false);
      notice(ToastType.SUCCESS, `Successfully created case law with title - ${data.title}`);
      clearErrors('title');
    } catch (error) {
      setIsLoading(false);
      baseBackendErrorHandler(error, { formError: { formData: data, setError } });
    }
  };

  const onEditCaseLawTypeHandler = async (data: CreateCaseLawTypeFormSchema) => {
    try {
      setIsLoading(true);
      await patchCaseLawTypeById(selectedCaseId, data);
      await getItemsHandler();
      setIsLoading(false);
      notice(ToastType.SUCCESS, `Successfully edited case law`);
      closeCaseLawTypesEditModal();
      setValue('title', '');
      clearErrors('title');
    } catch (error) {
      setIsLoading(false);
      baseBackendErrorHandler(error, { formError: { formData: data, setError } });
    }
  };

  const onDeleteCaseLawTypes = async () => {
    try {
      const nextPage = onDeleteItem();
      setIsLoading(true);
      closeCaseLawTypesDeleteModal();
      await deleteCaseLawTypeById(selectedCaseId);
      await getItemsHandler({ page: nextPage });
      setIsLoading(false);
      notice(ToastType.SUCCESS, 'Case law type successfully deleted!');
    } catch (error: any) {
      setIsLoading(false);
      console.error(error);

      if (error?.response?.status === 400) {
        notice(ToastType.ERROR, error?.response.data?.['hydra:description']);
      } else {
        notice(ToastType.ERROR, 'Something went wrong!');
      }
    }
  };

  const isSubmitButtonDisabled = !isValid || isSubmitting;

  const onClickDiscardEditButtonHandler = () => {
    reset();
    closeCaseLawTypesEditModal();
  };

  const onClickDiscardCreateButtonHandler = () => {
    reset();
    closeCaseLawTypesCreateModal();
  };

  return (
    <TemplateWrapper>
      <Card>
        <TableHeader
          title="Case law types"
          subtitle="Here you can work with current case law types by yourself"
          onButtonClickHandler={onAddLawTypeButtonClickHandler}
        />
        <CaseLawTypesTable
          totalItems={caseLawTypesTotal}
          onChangePage={onChangePageHandler}
          onChangeEntriesPerPage={onChangeEntriesPerPageHandler}
          isLoading={isLoading}
          entriesPerPage={entriesPerPage}
          currentPage={currentPage}
          caseLawTypesList={caseLawTypesList}
          onEditClick={(id, title) => {
            setSelectedCaseId(id);
            setValue('title', title);
            openCaseLawTypesEditModal();
          }}
          onDeleteClick={id => {
            setSelectedCaseId(id);
            openCaseLawTypesDeleteModal();
          }}
        />
      </Card>

      <DialogWindow
        dialogTitle="Are you sure want to delete this case law type?"
        isDialogOpen={isCaseLawTypesDeleteModalOpen}
        onCloseDialogHandler={closeCaseLawTypesDeleteModal}
        onApproveDialogHandler={onDeleteCaseLawTypes}
      />

      <SideMenu
        isOpen={isCaseLawTypesEditModalOpen}
        onClose={() => {
          clearErrors('title');
          setValue('title', '');
          closeCaseLawTypesEditModal();
        }}
        title="Edit case law type"
        titleDescription="Edit case law type title here."
        customWidth={430}
      >
        <MDBox
          py={4}
          display="flex"
          flexDirection="column"
          component="form"
          height={1}
          onSubmit={handleSubmit(onEditCaseLawTypeHandler)}
        >
          <Stack flex={1}>
            <BaseFormField
              errorValue={errors['title']?.message}
              formInputProps={{
                ...register('title'),
                type: 'text',
                label: 'Title',
                fullWidth: true,
                color: 'white',

                InputLabelProps: { shrink: true },
              }}
            />
          </Stack>
          <ButtonsBox
            onClickDiscardButtonHandler={onClickDiscardEditButtonHandler}
            isDisabledSubmitButton={isSubmitButtonDisabled}
            isLoading={isSubmitting}
          />
        </MDBox>
      </SideMenu>

      <SideMenu
        isOpen={isCaseLawTypesCreateModalOpen}
        onClose={() => {
          clearErrors('title');
          setValue('title', '');
          closeCaseLawTypesCreateModal();
        }}
        title="Add case law type"
        titleDescription="Add new case law type here."
        customWidth={430}
      >
        <MDBox
          py={4}
          display="flex"
          flexDirection="column"
          component="form"
          height={1}
          onSubmit={handleSubmit(onCreateCaseLawTypeHandler)}
        >
          <Stack flex={1} spacing={0.5}>
            <BaseFormField
              errorValue={errors['title']?.message}
              formInputProps={{
                ...register('title'),
                type: 'text',
                label: 'Title',
                fullWidth: true,
                color: 'white',
                InputLabelProps: { shrink: true },
              }}
            />

            <Controller
              control={control}
              name="clioLawTypes"
              render={({ field: { value, onChange } }) => {
                const onChangeHandler = async (
                  _: React.SyntheticEvent<Element, Event>,
                  options: PracticeAreaCategory[]
                ) => onChange(options);
                return (
                  <Autocomplete
                    value={value}
                    getOptionLabel={option => option?.value}
                    options={data}
                    loading={isClioEnumsLoading}
                    isOptionEqualToValue={(option, value) => option?.['@id'] === value?.['@id']}
                    onChange={onChangeHandler}
                    multiple
                    sx={{ width: 1 }}
                    freeSolo={false}
                    disableClearable
                    renderInput={params => (
                      <BaseFormField
                        formInputProps={{
                          ...params,
                          label: 'Clio case law type',
                          fullWidth: true,
                        }}
                        errorValue={errors?.clioLawTypes?.message}
                      />
                    )}
                  />
                );
              }}
            />
          </Stack>

          <ButtonsBox
            onClickDiscardButtonHandler={onClickDiscardCreateButtonHandler}
            isDisabledSubmitButton={isSubmitButtonDisabled}
            isLoading={isSubmitting}
          />
        </MDBox>
      </SideMenu>
    </TemplateWrapper>
  );
};
