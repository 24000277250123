import { FC } from 'react';
import { Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import { DashboardLayout } from 'features/common';
import { BaseSideNaveMenu } from 'features/common/components/BaseSideNaveMenu';
import { ROUTES } from 'features/common/variables/routes';
import { useAppStatisticContextState } from 'features/contexts/AppStatisticsContextProvider';

type TemplatesWrapperProps = {
  children: React.ReactNode;
};

export const TemplatesWrapper: FC<TemplatesWrapperProps> = ({ children }) => {
  const { adminStatistic } = useAppStatisticContextState();

  const sidenavItems = [
    { icon: 'apartment', label: 'company list', href: ROUTES.companyList },
    {
      icon: 'hourglass_empty',
      label: 'waiting for approval',
      href: ROUTES.companyListSuggestions,
      withBadge: true,
      badgeContent: adminStatistic?.companiesWaitingForApproval || 0,
    },
  ];

  return (
    <DashboardLayout>
      <MDBox mt={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <BaseSideNaveMenu sidenavItems={sidenavItems} />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>{children}</MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};
