import { FC } from 'react';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseFormField, ToastType, notice } from 'features/common';
import { baseBackendErrorHandler } from 'features/common/errorHanders';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { CreateFAQFormSchema, createFAQFormSchema } from '../templates/formSchema';
import { createFAQService } from '../services';

type CreateFAQItemMenuContentProps = {
  onCloseMenuHandler: () => void;
  loadQuestionsHandler: (page?: number) => Promise<void>;
};

export const CreateFAQItemMenuContent: FC<CreateFAQItemMenuContentProps> = ({
  onCloseMenuHandler,
  loadQuestionsHandler,
}) => {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, isValid, errors },
    setError,
  } = useForm<CreateFAQFormSchema>({
    resolver: yupResolver(createFAQFormSchema),
    mode: 'onTouched',
  });

  const { createQuestion } = createFAQService();

  const onSubmitFormHandler = handleSubmit(async (data: CreateFAQFormSchema) => {
    try {
      await createQuestion({
        question: data.question,
        answer: data.answer,
        publicVisibility: false,
      });
      onCloseMenuHandler();
      await loadQuestionsHandler();
      notice(ToastType.SUCCESS, `Question has been successfully created!`);
    } catch (error) {
      console.error(error);
      baseBackendErrorHandler(error, { formError: { formData: data, setError } });
    }
  });

  return (
    <MDBox
      component="form"
      onSubmit={onSubmitFormHandler}
      my={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <Stack spacing={1.5} flex={1}>
        <BaseFormField
          formInputProps={{
            ...register('question'),
            type: 'text',
            label: 'Question',
            fullWidth: true,
            color: 'white',
            InputLabelProps: { shrink: true },
          }}
          errorValue={errors['question']?.message}
        />
        <BaseFormField
          formInputProps={{
            ...register('answer'),
            type: 'text',
            multiline: true,
            maxRows: 8,
            label: 'Answer',
            fullWidth: true,
            color: 'white',
            InputLabelProps: { shrink: true },
          }}
          errorValue={errors['answer']?.message}
        />
      </Stack>
      <MDButton
        type="submit"
        variant="gradient"
        color="info"
        isLoading={isSubmitting}
        disabled={!isValid || isSubmitting}
      >
        Create
      </MDButton>
    </MDBox>
  );
};
