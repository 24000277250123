import { FC, memo } from 'react';
import { Chip, Stack } from '@mui/material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import MDTypography from 'components/MDTypography';
import { CountryState } from 'features/common';

type StateLawTypeItemProps = {
  lawTypeId: string;
  lawTypeTitle: string;
  states: CountryState[];
  isSelected: boolean;
  onClickLawTypeItemHandler: (event: React.MouseEvent<HTMLLIElement>) => void;
};

export const StateLawTypeItem: FC<StateLawTypeItemProps> = memo(
  ({ states, lawTypeId, lawTypeTitle, isSelected, onClickLawTypeItemHandler }) => {
    return (
      <Stack
        component="li"
        id={lawTypeId}
        onClick={onClickLawTypeItemHandler}
        sx={{
          p: 1,
          borderRadius: '6px',
          ':hover': {
            backgroundColor: ({ palette }) => palette.grey[200],
            cursor: 'pointer',
          },
          ...(isSelected && {
            backgroundColor: ({ palette }) => palette.grey[200],
          }),
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <MDTypography variant="h6" fontWeight="medium">
            {lawTypeTitle}
          </MDTypography>
          <ChevronRightRoundedIcon fontSize="medium" />
        </Stack>
        {states.length ? (
          <Stack component="ul" direction="row" flexWrap="wrap" ml={-1}>
            {states?.map(state => (
              <Stack component="li" direction="row" key={state?.['@id']} sx={{ pl: 1 }}>
                <Chip label={state?.name} sx={{ mb: 0.5, cursor: 'pointer' }} />
              </Stack>
            ))}
          </Stack>
        ) : (
          <MDTypography variant="button" color="error">
            No states connected
          </MDTypography>
        )}
      </Stack>
    );
  }
);
