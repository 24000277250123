import { useMemo, useEffect, useState, useCallback } from 'react';
//drag-n-drop components
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table';

// @mui material components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Icon from '@mui/material/Icon';
import Autocomplete from '@mui/material/Autocomplete';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDPagination from 'components/MDPagination';

// Material Dashboard 2 PRO React TS examples components
import DataTableHeadCell from 'examples/Tables/DataTable/DataTableHeadCell';
import DataTableBodyCell from 'examples/Tables/DataTable/DataTableBodyCell';
import { DIRECTIONS } from 'features/common/variables/variables';
import { DragHandle, DragIndicator } from '@mui/icons-material';

// Declaring props types for DataTable
interface Props {
  entriesPerPage?:
    | false
    | {
        defaultValue: number;
        entries?: number[];
      };
  canSearch?: boolean;
  showTotalEntries?: boolean;
  table: {
    columns: { [key: string]: any }[];
    rows: { [key: string]: any }[];
  };
  pagination?: {
    variant: 'contained' | 'gradient';
    color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'dark' | 'light';
  };
  onReorder?: ({ id, direction, steps }: { id: string; direction: string; steps: number }) => void;
  isSorted?: boolean;
  onChangeEntriesPerPage?: (value: number) => void;
}

export const DndDataTable = ({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  onChangeEntriesPerPage,
  onReorder,
}: Props): JSX.Element => {
  let defaultValue: any;
  let entries: any[];

  if (entriesPerPage) {
    defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : '10';
    entries = entriesPerPage.entries ? entriesPerPage.entries : [5, 10, 15, 20];
  }

  const columns = useMemo<any>(() => table.columns, [table]);
  const data = useMemo<any>(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  }: any = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value: any) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option: any) => (
    <MDPagination item key={option} onClick={() => gotoPage(Number(option))} active={pageIndex === option}>
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }: any) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option: any) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }: any) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value: any) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column: any) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? 'desc' : 'asce';
    } else if (isSorted) {
      sortedValue = 'none';
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const onDragEnd = (result: { source: any; destination: any; draggableId: string }) => {
    const { source, destination, draggableId } = result;

    if (!destination) return;
    if (source.index === destination.index) return;
    const reorderRequest = {
      steps: 0,
      direction: DIRECTIONS.down,
    };
    if (source.index !== destination.index) {
      if (source.index < destination.index) {
        reorderRequest.direction = DIRECTIONS.up;
        reorderRequest.steps = destination.index - source.index;
      } else {
        reorderRequest.direction = DIRECTIONS.down;
        reorderRequest.steps = source.index - destination.index;
      }
    }

    onReorder({
      id: draggableId,
      direction: reorderRequest.direction,
      steps: reorderRequest.steps,
    });
  };

  return (
    <TableContainer sx={{ boxShadow: 'none' }}>
      {entriesPerPage || canSearch ? (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                getOptionLabel={option => option.toString()}
                isOptionEqualToValue={(option, value) => option == value}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                  onChangeEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: '5rem' }}
                renderInput={params => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }: any) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup: any, key: any) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, key: any) => (
                <DataTableHeadCell
                  key={key}
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : 'auto'}
                  align={column.align ? column.align : 'left'}
                  sorted={setSortedValue(column)}
                >
                  {column.render('Header')}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppableItems" direction="vertical">
            {provided => (
              <TableBody {...getTableBodyProps()} ref={provided.innerRef} {...provided.droppableProps}>
                {page.map((row: any, idx: any) => {
                  prepareRow(row);
                  return (
                    <Draggable draggableId={row?.original.id} index={idx} key={row?.original.id}>
                      {dragProvided => (
                        <TableRow key={row?.original.id} {...row.getRowProps()} {...dragProvided.draggableProps}>
                          {row.cells.map((cell: any, idx: any) => (
                            <DataTableBodyCell
                              key={cell?.column?.id}
                              align={cell.column.align ? cell.column.align : 'center'}
                              {...cell.getCellProps()}
                            >
                              {cell.column.id === 'dragAndDrop' && (
                                <div
                                  ref={dragProvided.innerRef}
                                  {...dragProvided.dragHandleProps}
                                  style={{ width: 10 }}
                                >
                                  <DragIndicator key={row?.original.id} />
                                </div>
                              )}
                              {cell.render('Cell')}
                            </DataTableBodyCell>
                          ))}
                        </TableRow>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
};

// Declaring default props for DataTable
DndDataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: ['5', '10', '15', '20', '25'] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: 'gradient', color: 'info' },
  isSorted: true,
  noEndBorder: false,
};
