import { FC } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { AuthMethodType, AuthenticationMethodTabsProps } from '../types';

export const AuthenticationMethodTabs: FC<AuthenticationMethodTabsProps> = ({ authMethodType, handleSetTabValue }) => {
  return (
    <Tabs sx={{ mb: '16px' }} orientation="horizontal" value={authMethodType} onChange={handleSetTabValue}>
      <Tab sx={{ py: '12px' }} label="Email" value={AuthMethodType.EMAIL} />
      <Tab sx={{ py: '12px' }} label="Phone number" value={AuthMethodType.PHONE} />
    </Tabs>
  );
};
