import { Pagination } from 'features/case-law-types';
import {
  BaseData,
  CRMProvider,
  Country,
  CountryState,
  County,
  Integrations,
  ListOfItems,
  Logo,
  UserProfileResponse,
  backendApiClient,
} from 'features/common';
import { CreateFileResponse } from 'features/common/services/filesService';
import { CompanyInfo } from 'features/company-list';

export const BASE_LEGAL_CASES_API_URL: Readonly<string> = '/legal_cases';
export const BASE_LEGAL_CASE_NOTES_API_URL: Readonly<string> = '/legal_case_notes';

export type StatusType = 'todo' | 'in_progress' | 'done';

type AttorneyLogo = BaseData<'File'> & {
  createdAt: string;
  fileExtension: string;
  fileSize: number;
  id: string;
  owner: string;
  publicUrl: string;
  sign: null;
  title: string;
  type: string;
  updatedAt: string;
};

export type UserSigner = BaseData<'User'> & {
  firebaseUser: string;
  logo: null | Logo;
};

export type SignFile = BaseData<'FileSign'> & {
  id: string;
  signed: boolean;
  createdAt: string;
  userRequestSign: string;
  envelopId: string;
  callbackUrl: string;
  userSigner: UserSigner;
  userProfile: Pick<UserProfileResponse, '@id' | '@type' | 'firstName' | 'lastName'>;
};

export type CaseFile = CreateFileResponse & {
  fileMimeType: string;
  sign: null | SignFile;
  owner: BaseData<'User'> & {
    firebaseUser: string;
    userProfile: UserProfileResponse;
    integration: Integrations;
    company: CompanyInfo | null;
  };
};

export type CaseCompany =
  | (BaseData<'Company'> &
      Pick<CompanyInfo, 'fullName' | 'shortName'> & {
        logo: Logo | null;
      })
  | null;

export type User =
  | (BaseData<'User'> & {
      firebaseUser: string;
      company: CaseCompany;
      logo: null | AttorneyLogo;
      userProfile: UserProfileResponse;
      integration: Integrations;
      rating: number;
      legalCaseCount: number;
    })
  | null;

type LegalCaseStatusTitle =
  | 'Open'
  | 'Closed'
  | 'Pending'
  | 'Presuit'
  | 'Turndown'
  | 'Litigation'
  | 'Pending Litigation'
  | 'Referred out'
  | 'Terminated by client'
  | 'Accepted'
  | 'Retainer Received'
  | 'Under Review'
  | 'Signup in Progress';

export type LegalCaseStatus = BaseData<'LegalCaseStatus'> & {
  id: string;
  type: StatusType;
  darkThemeBgColor: string;
  darkThemeTextColor: string;
  lightThemeBgColor: string;
  lightThemeTextColor: string;
  title: LegalCaseStatusTitle;
};

export type CRMIntegrationData = BaseData<''> & {
  canBeUploadToClio: boolean;
  isClioLinked: boolean;
  canBeExport: boolean;
  clioOriginalNumber: string;
};

export type LegalCaseInvitation = BaseData<'ClientInvitation'> &
  Pick<UserProfileResponse, 'firstName' | 'lastName' | 'country' | 'state'> & {
    id: string;
    email: string;
    accessCode: string;
    expiredAt: string;
  };

export type LegalCaseActionDTO = {
  backToQueue: boolean;
  canBeDeleted: boolean;
  chat: boolean;
  docuSignRequest: boolean;
};

export type LegalCaseStage = 'consultation' | 'representation' | 'invitation' | 'unrepresented';

export type GetMyLegalCasesResponse = BaseData<'LegalCase'> & {
  id: string;
  attorney: User;
  client: User;
  caseNumber: string;
  country: Country;
  county: County;
  createdAt: string;
  description: string;
  state: CountryState;
  dependantFirstName: null | string;
  dependantLastName: null | string;
  caseForDependant: boolean;
  dependantStateOfResidence: null | CountryState;
  dependantUnder18: boolean;
  status: LegalCaseStatus;
  updatedAt: string | null;
  backToQueue: boolean;
  invitation: LegalCaseInvitation | null;
  crmIntegrationData: CRMIntegrationData;
  legalCaseActionDTO: LegalCaseActionDTO;
  stage: LegalCaseStage;
  docuSignRequest: boolean;
};

export type GetMyLegalCasesParams = Pagination &
  Partial<{
    'status.type': StatusType;
    search: string;
    'status.type[]': StatusType[];
    caseLawType: string;
    'caseLawType[]': string[];
    country: string;
    'country[]': string[];
    'state.isoCode': string;
    'state.isoCode[]': string[];
    county: string;
    'county[]': string[];
  }>;

export type CreateMyLegalCasePayload = {
  description: string;
  caseLawType: string;
  country: string;
  state: string;
  county: string;
  caseNumber?: string;
  client?: string;
  invitation?: string;
  caseForDependant?: boolean;
  dependantStateOfResidence?: string;
  dependantFirstName?: string;
  dependantLastName?: string;
  dependantUnder18?: boolean;
  backToQueue?: boolean;
  importId?: string;
  importProvider?: CRMProvider;
};

//notes
export type UpdateNotePayload = {
  title: string;
  description: string;
  visible: boolean;
  sendNotifications?: boolean;
};
export type CreateNotesPayload = UpdateNotePayload & {
  legalCase: string;
};

export type CreateLegalCaseNoteResponse = {
  '@context': '/contexts/LegalCaseNote';
  '@id': string;
  '@type': 'LegalCaseNote';
  id: string;
  createdAt: string;
  description: string;
  title: string;
  visible?: boolean | null;
  owner: {
    '@id': '/users/my_company';
    '@type': 'User';
    firebaseUser: string;
    userProfile: Pick<UserProfileResponse, '@id' | '@type' | 'firstName' | 'lastName'>;
  };
};

//files
export type PutFilePayload = {
  file: string;
  sendNotifications?: boolean;
  requestSign?: boolean;
};

export type PutCRMFilePayload = {
  files: { [x: string]: number };
};

export type CreateClientInvitationResponse = {
  '@context': string;
  '@id': string;
  '@type': string;
  accessCode: string;
  email: string;
  firstName: string;
  lastName: string;
  country: string;
  state: string;
  expiredAt: string;
};

export type CreateClientInvitationPayload = {
  email: string;
  firstName: string;
  lastName: string;
  country: string;
  state: string;
};

export const myLegalCasesApiService = () => {
  const getMyLegalCases = async (params?: GetMyLegalCasesParams) =>
    await backendApiClient({}).get<ListOfItems<GetMyLegalCasesResponse[]>>(`v2${BASE_LEGAL_CASES_API_URL}/mine`, {
      params,
    });

  const deleteMyLegalCase = async (id: string, statusType?: StatusType) => {
    const data = await backendApiClient({}).delete(`${BASE_LEGAL_CASES_API_URL}/${id}`, {
      params: {
        'status.type': statusType,
      },
    });
    return data;
  };

  const createMyLegalCase = async (payload: CreateMyLegalCasePayload) => {
    const data = await backendApiClient({}).post<GetMyLegalCasesResponse>(BASE_LEGAL_CASES_API_URL, payload);
    return data;
  };

  const createLegalCaseNotes = async (payload: CreateNotesPayload) => {
    const data = await backendApiClient({
      headers: {
        Encrypted: 'Encrypted',
      },
    }).post<CreateLegalCaseNoteResponse>(BASE_LEGAL_CASE_NOTES_API_URL, payload);
    return data;
  };

  const putLegalCaseFile = async (id: string, payload: PutFilePayload) => {
    return await backendApiClient({}).put<{ file: CaseFile }>(`${BASE_LEGAL_CASES_API_URL}/${id}/files`, payload);
  };

  const postCRMFilesToLegalCase = async (id: string, payload: PutCRMFilePayload) => {
    return await backendApiClient({}).post<{ files: PutCRMFilePayload }>(
      `${BASE_LEGAL_CASES_API_URL}/${id}/upload_import_files`,
      payload
    );
  };

  const createClientInvitation = async (payload: CreateClientInvitationPayload) =>
    await backendApiClient({}).post<CreateClientInvitationResponse>('/client_invitations', payload);

  return {
    getMyLegalCases,
    deleteMyLegalCase,
    createMyLegalCase,
    createLegalCaseNotes,
    putLegalCaseFile,
    createClientInvitation,
    postCRMFilesToLegalCase,
  };
};

export const ITEMS_PER_PAGE = 30;
export const fetchParams: Record<number, GetMyLegalCasesParams> = {
  0: {
    itemsPerPage: ITEMS_PER_PAGE,
    'status.type[]': ['in_progress', 'todo'],
  },
  1: {
    itemsPerPage: ITEMS_PER_PAGE,
    'status.type': 'done',
  },
};
