import { FC, useState } from 'react';
import { Stack } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { BaseAutocompleteField } from 'features/common/components/BaseAutocompleteField';
import { COMPANY_ROLES } from 'features/company-list';

type EditUserModalContentProps = {
  onCloseMenuHandler: () => void;
  role: COMPANY_ROLES;
  onUpdateCompanyUserHandler: (userRole: COMPANY_ROLES) => Promise<void>;
};

type ROLE_LIST_ITEM = { '@id': COMPANY_ROLES; title: string };
const ROLES_LIST: ROLE_LIST_ITEM[] = [
  {
    '@id': 'ROLE_COMPANY_ADMIN',
    title: 'Company admin',
  },
  {
    '@id': 'ROLE_COMPANY_ATTORNEY',
    title: 'Company attorney',
  },
];

export const EditUserModalContent: FC<EditUserModalContentProps> = ({
  onUpdateCompanyUserHandler,
  onCloseMenuHandler,
  role,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentRole, setCurrentRole] = useState<COMPANY_ROLES>(role);

  const onChangeHandler = (option: ROLE_LIST_ITEM) => {
    setCurrentRole(option?.['@id']);
  };

  const onEditClickButtonHandler = async () => {
    setIsLoading(true);
    await onUpdateCompanyUserHandler(currentRole);
    setIsLoading(false);
    onCloseMenuHandler();
  };

  return (
    <MDBox
      sx={{
        mt: 3,
        pb: 1.5,
        height: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack flex={1}>
        <BaseAutocompleteField
          value={
            currentRole
              ? ROLES_LIST.find(option => {
                  return currentRole === option?.['@id'];
                }) ?? null
              : null
          }
          disableClearable
          onChangeHandler={onChangeHandler}
          isOptionEqualToValue={(option, value) => option?.['@id'] === value?.['@id']}
          getOptionLabel={option => option?.title}
          options={ROLES_LIST}
          autocompleSxProps={{
            '.MuiInputBase-root': {
              py: '4.5px',
            },
          }}
          formFieldProps={{
            pb: 0,
          }}
          formInputProps={{
            label: 'Role',
            '.MuiInputBase-input': {
              pr: '20px',
            },
          }}
          placeholder="Select attorney"
        />
      </Stack>
      <MDButton
        disabled={isLoading || currentRole === role}
        isLoading={isLoading}
        variant="gradient"
        color="info"
        type="button"
        onClick={onEditClickButtonHandler}
      >
        Edit
      </MDButton>
    </MDBox>
  );
};
