import { FC, useEffect, useMemo, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { BaseFormField, ToastType, notice } from 'features/common';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';
import { Loader } from 'features/common/components/Loader';
import { sortArray } from 'features/common/helpers/utilities';
import { ClioCaseLawTypeValue, PracticeAreaCategory, clioUserSettingsApiService } from '../ClioSettingsApiService';

type LawTypeMenuContentProps = {
  selectedAttroneyLawTypeId: string;
  selectedAttorneyLawTypeTitle: string;
  matchedClioLawTypes: PracticeAreaCategory[];
  onCloseMenuHandler: () => void;
  onGetClioCaseLawTypes: () => Promise<void>;
};

export const LawTypeMenuContent: FC<LawTypeMenuContentProps> = ({
  selectedAttorneyLawTypeTitle,
  selectedAttroneyLawTypeId,
  matchedClioLawTypes,
  onCloseMenuHandler,
  onGetClioCaseLawTypes,
}) => {
  const { getClioMappingProxyPracticeArea, updateClioCaseLawType } = clioUserSettingsApiService();

  const [clioPracticeAreas, setClioPracticeAreas] = useState<ClioCaseLawTypeValue[]>([]);

  useState<(PracticeAreaCategory | ClioCaseLawTypeValue)[]>(matchedClioLawTypes);

  const [selectedClioPracticeAreas, setSelectedClioPracticeAreas] =
    useState<(PracticeAreaCategory | ClioCaseLawTypeValue)[]>(matchedClioLawTypes);

  const [isFetching, setIsFetching] = useState(true);

  const [isSaving, setIsSaving] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  const onGetClioMappingProxyPracticeAreasHandler = async () => {
    try {
      const { data } = await getClioMappingProxyPracticeArea();
      setClioPracticeAreas(data['hydra:member']);
      setIsFetching(false);
    } catch (error: any) {
      setIsFetching(false);
      console.error(error);
      if (error?.response?.status === 422) {
        notice(ToastType.ERROR, error?.response?.data?.['hydra:description']);
      } else {
        paymentRequiredErrorHandler(error);
      }
    }
  };

  useEffect(() => {
    onGetClioMappingProxyPracticeAreasHandler();
  }, []);

  const onChangeSearchValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value);

  const onChangeCheckboxHandler = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const checkedClioPracticeArea = clioPracticeAreas?.find(el => el['@id'] === event.target.id);

    setSelectedClioPracticeAreas(prevSelectedLawTypes => {
      if (checked) {
        return [...prevSelectedLawTypes, checkedClioPracticeArea];
      } else {
        return prevSelectedLawTypes.filter(el => el?.['@id'] !== checkedClioPracticeArea?.['@id']);
      }
    });
  };

  const searchedClioPracticeAreas = clioPracticeAreas.filter(lawType =>
    lawType.value.toLowerCase().includes(searchValue.toLowerCase())
  );

  const onSaveButtonClickHandler = async () => {
    try {
      setIsSaving(true);
      await updateClioCaseLawType({
        caseLawType: selectedAttroneyLawTypeId,
        practiceAreas: selectedClioPracticeAreas.map(el => el.id),
      });
      await onGetClioCaseLawTypes();
      setIsSaving(false);
      onCloseMenuHandler();
    } catch (error) {
      setIsSaving(false);
      console.error(error);
      paymentRequiredErrorHandler(error);
    }
  };

  const isDefaultArraySameAsCurrent = useMemo(() => {
    if (matchedClioLawTypes.length !== selectedClioPracticeAreas.length) return false;

    const sortedCurrent = sortArray(selectedClioPracticeAreas);
    const sortedDefault = sortArray(matchedClioLawTypes);

    for (let i = 0; i < sortedCurrent.length; i++) {
      if (sortedCurrent[i]?.['@id'] !== sortedDefault[i]?.['@id']) {
        return false;
      }
    }

    return true;
  }, [matchedClioLawTypes, selectedClioPracticeAreas]);

  return (
    <Stack height={1} spacing={1.5} mt={2} position="relative">
      {isFetching ? (
        <Loader />
      ) : (
        <>
          <BaseFormField
            formFieldProps={{
              pb: 0,
            }}
            formInputProps={{
              value: searchValue,
              label: 'Search',
              placeholder: 'Find the law types',
              fullWidth: true,
              onChange: onChangeSearchValueHandler,
              InputProps: {
                startAdornment: <SearchIcon fontSize="medium" />,
              },
            }}
          />
          <MDTypography variant="body2" sx={{ whiteSpace: 'initial' }}>
            Select the law type you would like this case listed under in Clio
          </MDTypography>

          <Stack
            spacing={1}
            sx={{
              maxHeight: `calc(100vh - 255px)`,
              overflowY: 'auto',
              height: 1,
            }}
          >
            {searchedClioPracticeAreas.length ? (
              searchedClioPracticeAreas?.map(lawType => (
                <FormControlLabel
                  key={lawType['@id']}
                  id={lawType['@id']}
                  control={
                    <Checkbox
                      id={lawType['@id']}
                      checked={selectedClioPracticeAreas?.some(el => el?.['@id'] === lawType?.['@id'])}
                      onChange={onChangeCheckboxHandler}
                    />
                  }
                  sx={{
                    ml: 0,
                  }}
                  label={
                    <MDTypography variant="button" color="text">
                      {lawType.value}
                    </MDTypography>
                  }
                />
              ))
            ) : (
              <Stack sx={{ justifyContent: 'center', height: 1 }}>
                <MDTypography
                  variant="subtitle2"
                  sx={{
                    pt: 2,
                    textAlign: 'center',
                  }}
                >
                  No Clio law types were found
                </MDTypography>
              </Stack>
            )}
          </Stack>

          {clioPracticeAreas.length > 0 && (
            <Stack spacing={2} pb={2} direction="column" position="absolute" sx={{ bottom: 0, width: 1 }}>
              <MDButton
                variant="gradient"
                color="info"
                disabled={isSaving || isDefaultArraySameAsCurrent}
                isLoading={isSaving}
                onClick={onSaveButtonClickHandler}
              >
                Save
              </MDButton>
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};
