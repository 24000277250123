import { styled } from '@mui/system';

export const StyledForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}));
