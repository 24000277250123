import { toast, ToastContainer, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export enum ToastType {
  'SUCCESS' = 'success',
  'INFO' = 'info',
  'ERROR' = 'error',
  'WARNING' = 'warning',
}
type Notice = (type: ToastType, message: string, toastProps?: Partial<ToastOptions>) => void;

export const notice: Notice = (type = ToastType.INFO, message, toastProps) => {
  const showToast = toast[type];

  showToast(message, {
    ...toastProps,
  });
};

export const ToastNotification = (): JSX.Element => (
  <ToastContainer
    position="top-right"
    autoClose={3000}
    hideProgressBar={true}
    newestOnTop={false}
    draggable={false}
    closeOnClick
    pauseOnHover
    toastStyle={{ position: 'relative' }}
    bodyStyle={{ alignItems: 'items-center' }}
  />
);
