import { useEffect, useMemo, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { CountryState, County, createAttorneyService, useGeoData, useUser } from 'features/common';
import { useLegalCasesFeed } from 'features/store';
import { GetMyLegalCasesParams } from 'features/my-legal-cases/MyLegalCasesApiService';
import { CaseLawTypeResponse } from 'features/case-law-types';

export const useFiltersState = (onCloseFiltersMenuHandler: () => void) => {
  const { backendUser } = useUser();
  const { getAttorneyProfileById } = createAttorneyService();

  const [currentAttorneyCaseLawTypes, setCurrentAttorneyCaseLawTypes] = useState<CaseLawTypeResponse[]>([]);
  const [isCaseLawTypesLoading, setIsCaseLawTypesLoading] = useState(true);

  const onGetAttorneyCurrentCaseLawTypes = async () => {
    if (!backendUser?.attorneyProfile) return;
    const { caseLawTypes } = await getAttorneyProfileById(
      // TODO conference hack
      backendUser?.attorneyProfile?.['@id']?.replace('/attorney_profiles/', '')
    );
    setIsCaseLawTypesLoading(false);
    setCurrentAttorneyCaseLawTypes(caseLawTypes);
  };

  useEffect(() => {
    onGetAttorneyCurrentCaseLawTypes();
  }, []);

  const { filtersData, setFiltersDataHandler } = useLegalCasesFeed(
    state => ({
      setFiltersDataHandler: state.setFiltersDataHandler,
      filtersData: state.filtersData,
    }),
    shallow
  );

  const {
    countryStateList,
    isCountyListLoading,
    countyList,
    isStateListLoading,
    getCountiesHandler,
    getStateListHandler,
  } = useGeoData(false);

  const [state, setState] = useState<CountryState | null>(null);
  const [county, setCounty] = useState<null | County>(null);
  const [lawTypes, setLawTypes] = useState<CaseLawTypeResponse[]>(currentAttorneyCaseLawTypes);
  const [selectedLawTypes, setSelectedLawTypes] = useState<string[]>(filtersData['caseLawType[]']);
  const [lawTypeSearchValue, setLawTypeSearchValue] = useState('');

  useEffect(() => {
    setLawTypesHandler();
  }, [currentAttorneyCaseLawTypes]);

  useEffect(() => {
    !state && !countryStateList.length && getStateListHandler();
    state && getCountiesHandler({ 'state.isoCode': state?.isoCode });
  }, [state]);

  const onChangeStateHandler = (_: React.SyntheticEvent<Element, Event>, value: CountryState) => {
    setState(value);
    setCounty(null);
  };

  const onChangeCountyHandler = (_: React.SyntheticEvent<Element, Event>, value: County) => {
    setCounty(value);
  };

  const onFilterLawTypesHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const filteredLawTypes = currentAttorneyCaseLawTypes.filter(lawType =>
      lawType.title.toLowerCase().includes(inputValue.toLowerCase())
    );

    setLawTypeSearchValue(inputValue);
    setLawTypes(filteredLawTypes);
  };

  const onChangeCaseLawTypeHandler = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const lawTypeId = event.target.id;

    setSelectedLawTypes(prevSelectedLawTypes => {
      const selectedLawTypesSet = new Set(prevSelectedLawTypes);

      if (checked) {
        selectedLawTypesSet.add(lawTypeId);
      } else {
        selectedLawTypesSet.delete(lawTypeId);
      }

      return Array.from(selectedLawTypesSet);
    });
  };

  const setLawTypesHandler = () => setLawTypes(currentAttorneyCaseLawTypes);

  const onApplyFiltersHandler = async () => {
    const params: GetMyLegalCasesParams = {
      'caseLawType[]': selectedLawTypes,
      county: county?.['@id'],
      'state.isoCode': state?.isoCode,
    };

    setFiltersDataHandler(params);
    onCloseFiltersMenuHandler();
  };

  const onResetButtonClickHandler = () => {
    state && setState(null);
    county && setCounty(null);

    selectedLawTypes.length && setSelectedLawTypes([]);
    if (lawTypeSearchValue) {
      setLawTypes(currentAttorneyCaseLawTypes);
      setLawTypeSearchValue('');
    }
  };

  const isDefaultArraySameAsCurrent = useMemo(() => {
    if (filtersData['caseLawType[]'].length !== selectedLawTypes.length) return false;

    const sortedCurrent = selectedLawTypes.sort((a, b) => (a > b ? 1 : -1));
    const sortedDefault = filtersData['caseLawType[]'].sort((a, b) => (a > b ? 1 : -1));

    for (let i = 0; i < sortedCurrent.length; i++) {
      if (sortedCurrent[i] !== sortedDefault[i]) {
        return false;
      }
    }

    return true;
  }, [selectedLawTypes, filtersData['caseLawType[]']]);

  const isResetButtonDisabled = !state && !selectedLawTypes.length;

  const isApplyButtonDisabled =
    isDefaultArraySameAsCurrent &&
    Boolean(state?.isoCode) === Boolean(filtersData?.['state.isoCode']) &&
    Boolean(county?.['@id']) === Boolean(filtersData?.county);

  return {
    filtersData,
    isApplyButtonDisabled,
    isResetButtonDisabled,
    isCaseLawTypesLoading,
    isCountyListLoading,
    countryStateList,
    currentAttorneyCaseLawTypes,
    countyList,
    lawTypeSearchValue,
    selectedLawTypes,
    county,
    state,
    isStateListLoading,
    lawTypes,
    setCounty,
    setState,
    onChangeCountyHandler,
    onChangeStateHandler,
    onApplyFiltersHandler,
    onResetButtonClickHandler,
    onFilterLawTypesHandler,
    onChangeCaseLawTypeHandler,
  };
};
