import { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Badge, Card, Divider, Icon, Stack, SxProps, Theme } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { ROUTES } from 'features/common/variables/routes';

type SideNavMenuRouteItem = {
  icon: string;
  label: string;
  href: string;
  isDisabled?: boolean;
  badgeContent?: number;
};

type BaseSideNaveMenuProps = {
  sidenavItems: SideNavMenuRouteItem[];
  sx?: SxProps<Theme>;
};

const projectVersion = process.env.REACT_APP_APP_VERSION || null;

export const BaseSideNaveMenu: FC<BaseSideNaveMenuProps> = ({ sidenavItems, sx }) => {
  const location = useLocation();

  const renderSidenavItems = sidenavItems.map(({ icon, label, href, isDisabled, badgeContent }, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <MDTypography
          component={NavLink}
          to={href}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light, grey },
            transitions,
          }: Theme) => ({
            display: 'flex',
            alignItems: 'center',
            backgroundColor: location.pathname === href ? light.main : light,
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create('background-color', {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            '&:hover': {
              backgroundColor: light.main,
            },

            ...(isDisabled && {
              pointerEvents: 'none',
              backgroundColor: grey[300],
            }),
          })}
        >
          <MDBox mr={1.5} lineHeight={1} color="dark">
            <Badge badgeContent={badgeContent} color="error">
              <Icon fontSize="small">{icon}</Icon>
            </Badge>
          </MDBox>
          {label}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: 'sticky',
        top: '1%',
        ...sx,
      }}
    >
      <MDBox component="ul" display="flex" flexDirection="column" p={2} m={0} sx={{ listStyle: 'none' }}>
        {renderSidenavItems}

        {location.pathname.includes(ROUTES.profile) && (
          <Stack component="li" textAlign="center">
            <Divider orientation="horizontal" />

            <MDTypography variant="button">Copyright &copy; Legal Beagle</MDTypography>
            {projectVersion && <MDTypography variant="button">{`Version: ${projectVersion}`}</MDTypography>}
          </Stack>
        )}
      </MDBox>
    </Card>
  );
};
