import { Autocomplete, CircularProgress, SxProps } from '@mui/material';
import MDBox from 'components/MDBox';
import { BaseFormField } from '../BaseFormField';
import { FormFieldProps, FormInputProps } from '../BasePasswordFormField';

type BaseAutocompleteFieldProps<ValueType, OptionType extends { '@id': string }> = {
  value: ValueType;
  options: OptionType[];
  getOptionLabel: (option: OptionType) => string;
  onChangeHandler?: (option: OptionType) => void;
  isLoading?: boolean;
  errorMessage?: string;
  formFieldProps?: FormFieldProps;
  formInputProps?: FormInputProps;
  autocompleSxProps?: SxProps;
  isDisabled?: boolean;
  placeholder?: string;
  disableClearable?: boolean;
  isOptionEqualToValue?: (option: OptionType, value: OptionType) => boolean;
};

export function BaseAutocompleteField<ValueType extends OptionType, OptionType extends { '@id': string }>(
  props: BaseAutocompleteFieldProps<ValueType, OptionType>
): JSX.Element {
  const {
    options,
    value,
    autocompleSxProps,
    errorMessage,
    isLoading,
    formFieldProps,
    formInputProps,
    isDisabled,
    placeholder,
    disableClearable,
    isOptionEqualToValue,
    getOptionLabel,
    onChangeHandler,
  } = props;

  const onChange = (_: React.SyntheticEvent<Element, Event>, value: OptionType | null) => {
    onChangeHandler?.(value);
  };

  return (
    <Autocomplete
      value={value}
      sx={autocompleSxProps}
      getOptionLabel={getOptionLabel}
      options={options}
      freeSolo={false}
      disabled={isDisabled}
      isOptionEqualToValue={
        isOptionEqualToValue ? isOptionEqualToValue : (option, value) => option?.['@id'] === value?.['@id']
      }
      disableClearable={disableClearable || false}
      onChange={onChange}
      renderInput={params => (
        <BaseFormField
          formFieldProps={formFieldProps}
          formInputProps={{
            ...params,
            ...formInputProps,
            InputLabelProps: { shrink: true },
            fullWidth: true,
            placeholder,
            InputProps: {
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <MDBox pr={0} display="flex">
                      <CircularProgress color="inherit" size={20} />
                    </MDBox>
                  ) : null}
                  {errorMessage ? null : params.InputProps.endAdornment}
                </>
              ),
            },
          }}
          errorValue={errorMessage}
        />
      )}
    />
  );
}
