import { useMemo } from 'react';
import { AddBoxOutlined } from '@mui/icons-material';
import { Stack, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import { Theme } from '@mui/material/styles';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { CATEGORY_IRI } from 'features/common/mocks/IRIvalues';
import { CategoryDropdown } from './CategoryDropdown';

type NavigationItem = { name: string; href?: string; '@id': string; slug: string };
type SubNavigationProps = {
  additionalItems?: ReadonlyArray<NavigationItem>;
  selectedCategoryData: {
    categoryId: string;
    categorySlug: string;
  };
  onCategoryAdd: () => void;
  onCategoryClick: (id: string, slug: string, categoryName: string) => void;
  onAddCategoryItem: (id: string) => void;
  onEditCategory: (categoryId: string) => void;
  onDeleteCategory: () => void;
};

export const CategoryNavigation = ({
  additionalItems = [],
  onCategoryAdd,
  onCategoryClick,
  onAddCategoryItem,
  onEditCategory,
  onDeleteCategory,
  selectedCategoryData,
}: SubNavigationProps): JSX.Element => {
  const sidenavItems: NavigationItem[] = useMemo(
    () => [
      {
        '@id': 'All',
        name: 'All',
        href: 'all',
        slug: 'all',
      },
      ...additionalItems,
    ],
    [additionalItems]
  );
  const renderSidenavItems = sidenavItems.map((navItem, key) => {
    const itemKey = `item-${key}`;

    const isSelected =
      navItem['@id'] === 'All' && !selectedCategoryData.categoryId
        ? true
        : CATEGORY_IRI + selectedCategoryData.categoryId === navItem['@id'];

    const onCategoryItemClick = () => onCategoryClick(navItem['@id'].split('/').at(-1), navItem?.slug, navItem.name);

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1} sx={{ width: '100%' }}>
        <Stack
          direction="row"
          onClick={onCategoryItemClick}
          component="a"
          href={`#${navItem.slug}`}
          textTransform="capitalize"
          sx={({ borders: { borderRadius }, functions: { pxToRem }, palette: { light }, transitions }: Theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: borderRadius.md,
            backgroundColor: isSelected ? light.main : light,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create('background-color', {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            '&:hover': {
              backgroundColor: light.main,
            },
          })}
        >
          <Stack>
            <MDTypography variant="button" fontWeight="regular">
              {navItem.name}
            </MDTypography>
            {key !== 0 && <MDTypography variant="button">{navItem.slug}</MDTypography>}
          </Stack>
          {key === 0 ? (
            <Tooltip title="Add category">
              <MDBox display="flex">
                <AddBoxOutlined fontSize="small" onClick={onCategoryAdd} />
              </MDBox>
            </Tooltip>
          ) : (
            <MDBox>
              <CategoryDropdown
                onAdd={() => onAddCategoryItem(navItem['@id'].split('/').at(-1))}
                onEdit={() => onEditCategory(navItem['@id'])}
                onDelete={onDeleteCategory}
              />
            </MDBox>
          )}
        </Stack>
      </MDBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: 'sticky',
        top: '1%',
        width: '100%',
        boxShadow: 0,
      }}
    >
      <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0} sx={{ listStyle: 'none', width: '100%' }}>
        {renderSidenavItems}
      </MDBox>
    </Card>
  );
};
