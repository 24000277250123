import { FC } from 'react';
import { Card, CardContent, CardHeader, CircularProgress, Grid, Stack } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { BaseFormField } from 'features/common';
import { EndInputAdornment } from '../Components';
import { useSettingForms } from '../hooks/useSettingForms';

export const GeneralSettingsTemplate: FC = () => {
  const {
    audioVideoRegister,
    onSubmitAudioVideoFormHandler,
    onSubmitSecurityFormHandler,
    register,
    audioVideoFormState,
    errors,
    isSubmitting,
    isSaveSecurityButtonDisabled,
    isLoading,
    isSaveAudioVideoButtonDisabled,
  } = useSettingForms();

  return (
    <Grid container spacing={2}>
      {isLoading ? (
        <Grid item xs={12}>
          <Card sx={{ width: 1, height: 1, minHeight: 300 }}>
            <Stack width={1} alignSelf="center" alignItems="center" justifyContent="center" height={1}>
              <CircularProgress />
            </Stack>
          </Card>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Security" />
              <CardContent>
                <form onSubmit={onSubmitSecurityFormHandler}>
                  <BaseFormField
                    formInputProps={{
                      ...register('clientinvitationTTL'),
                      label: 'Client invitation TTL',
                      type: 'number',
                      fullWidth: true,
                      InputLabelProps: { shrink: true },
                      InputProps: {
                        endAdornment: errors['clientinvitationTTL']?.message ? null : (
                          <EndInputAdornment title="hours" />
                        ),
                      },
                    }}
                    errorValue={errors['clientinvitationTTL']?.message}
                  />

                  <BaseFormField
                    formInputProps={{
                      ...register('idle'),
                      label: 'Idle timeout',
                      type: 'number',
                      fullWidth: true,
                      InputLabelProps: { shrink: true },
                      InputProps: {
                        endAdornment: errors['idle']?.message ? null : <EndInputAdornment />,
                      },
                    }}
                    errorValue={errors['idle']?.message}
                  />
                  <MDBox my={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      type="submit"
                      sx={{ textTransform: 'none', width: { xs: 1, sm: 'auto' } }}
                      isLoading={isSubmitting}
                      disabled={isSaveSecurityButtonDisabled}
                    >
                      Save
                    </MDButton>
                  </MDBox>
                </form>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardHeader title="Audio / Video Calls" />
              <CardContent>
                <form onSubmit={onSubmitAudioVideoFormHandler}>
                  <Stack spacing={1}>
                    <BaseFormField
                      formInputProps={{
                        ...audioVideoRegister('video'),
                        label: 'Video call duration limit',
                        type: 'number',
                        fullWidth: true,
                        InputLabelProps: { shrink: true },
                        InputProps: {
                          endAdornment: audioVideoFormState.errors['video']?.message ? null : <EndInputAdornment />,
                        },
                      }}
                      errorValue={audioVideoFormState.errors['video']?.message}
                    />
                    <BaseFormField
                      formInputProps={{
                        ...audioVideoRegister('audio'),
                        label: 'Voice call duration limit',
                        type: 'number',
                        fullWidth: true,
                        InputLabelProps: { shrink: true },
                        InputProps: {
                          endAdornment: audioVideoFormState.errors['audio']?.message ? null : <EndInputAdornment />,
                        },
                      }}
                      errorValue={audioVideoFormState.errors['audio']?.message}
                    />
                  </Stack>
                  <MDBox my={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      type="submit"
                      sx={{ textTransform: 'none', width: { xs: 1, sm: 'auto' } }}
                      disabled={isSaveAudioVideoButtonDisabled}
                      isLoading={audioVideoFormState.isSubmitting}
                    >
                      Save
                    </MDButton>
                  </MDBox>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
    </Grid>
  );
};
