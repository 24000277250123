import { FC, useEffect, useState } from 'react';
import { Card, Stack } from '@mui/material';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { chatApiService } from 'features/common/services/chatApiService';
import { MercureLinkMap, ToastType, notice, useUser } from 'features/common';
import { Loader } from 'features/common/components/Loader';
import MDTypography from 'components/MDTypography';
import { ChatContent } from 'features/chat/components/ChatContent/ChatContent';
import { useMercure } from 'features/common/hooks/useMercure';
import { ChatInput } from 'features/chat/components/ChatInput';
import { CreateAgoraEventResponse } from 'features/common/services/agoraEventApiService';
import { useChatState } from 'features/chat/hooks/useChatState';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { ChatMenuStyles as Styled } from './ChatMenuStyles';

type ChatMenuProps = {
  isChatListOpen: boolean;
  eventData: CreateAgoraEventResponse;
  onClickChatIconButtonHandler: () => void;
};

export const ChatMenu: FC<ChatMenuProps> = ({ onClickChatIconButtonHandler, eventData, isChatListOpen }) => {
  const [isChatLoading, setIsChatLoading] = useState(false);
  const { backendUser } = useUser();

  const {
    isChatMessagesLoading,
    firstMessageRef,
    isFetchMoreLoading,
    messagesWrapperRef,
    onApproveRepresentationRequestHandler,
    onDeclineRepresentationRequestHandler,
    selectedChatData,
    chatId,
    inputValue,
    isFetchingNewMessages,
    onReceiveEventNewMessageHandler,
    onChangeInputHandler,
    onEnterPressHandler,
    onSendMessageButtonHandler,
    isAttorney,
    selectedUserChat,
    setSelectedUserChat,
    resetState,
    aesDecrypt,
  } = useChatState();

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  const { getListOfChats } = chatApiService();

  const chatParticipant = eventData?.participants?.find(el => el?.user?.firebaseUser !== backendUser?.firebaseUser);

  const onGetChatHandler = async () => {
    try {
      setIsChatLoading(true);
      const { data } = await getListOfChats({
        'legalCase.caseNumber': eventData.legalCase?.caseNumber,
        [isAttorney ? 'client' : 'attorney']: chatParticipant?.user?.firebaseUser,
      });
      const chatData = data['hydra:member']?.[0];
      setSelectedUserChat(chatData);

      setIsChatLoading(false);
    } catch (error) {
      setIsChatLoading(false);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
      console.error(error);
    }
  };

  useEffect(() => {
    isChatListOpen && !selectedUserChat?.id && onGetChatHandler();
  }, [isChatListOpen]);

  useMercure({
    eventId: chatId,
    onReceiveEventHandler: onReceiveEventNewMessageHandler,
    mercureSubscribeURL: MercureLinkMap[process.env.REACT_APP_BASE_APP_URL || 'http://localhost:3000'],
    topicSubURL: 'legal_case_chats',
  });

  return (
    <MDBox
      sx={{
        ...Styled.ChatMenuWrapperSX,
        ...(isChatListOpen && {
          left: 0,
        }),
      }}
    >
      <Card sx={{ width: 1, height: 1, display: 'flex', flexDirection: 'column' }}>
        {isChatLoading ? (
          <Loader />
        ) : (
          <Stack flex={1}>
            <MDBox
              sx={{
                p: 1,
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: ({ palette }) => `1px solid ${palette.grey[100]}`,
              }}
            >
              <MDButton
                size="medium"
                iconOnly
                onClick={onClickChatIconButtonHandler}
                sx={{
                  '& > svg': {
                    fontSize: '25px !important',
                  },
                }}
              >
                <KeyboardBackspaceOutlinedIcon sx={{ fontSize: '25px !important' }} />
              </MDButton>
            </MDBox>
            <Stack height={1} sx={Styled.MessageWrapperSX}>
              {isChatMessagesLoading ? (
                <Loader />
              ) : !selectedChatData?.messages.length && !isChatMessagesLoading ? (
                <Stack justifyContent="center" alignItems="center" flex={1}>
                  <MDTypography>No messages yet...</MDTypography>
                </Stack>
              ) : (
                <ChatContent
                  messages={selectedChatData?.messages}
                  currentUserFirebaseId={backendUser?.firebaseUser}
                  messagesWrapperRef={messagesWrapperRef}
                  firstMessageRef={firstMessageRef}
                  isFetchMoreLoading={isFetchMoreLoading}
                  approveRepresentationRequest={onApproveRepresentationRequestHandler}
                  declineRepresentationRequest={onDeclineRepresentationRequestHandler}
                  isAttorney={isAttorney}
                  aesDecrypt={aesDecrypt}
                />
              )}
            </Stack>
            <MDBox>
              <ChatInput
                inputValue={inputValue}
                onChangeInputHandler={onChangeInputHandler}
                onSendMessageButtonHandler={onSendMessageButtonHandler}
                onEnterPressHandler={onEnterPressHandler}
                isFetchingNewMessages={isFetchingNewMessages}
              />
            </MDBox>
          </Stack>
        )}
      </Card>
    </MDBox>
  );
};
