import { immer } from 'zustand/middleware/immer';
import {
  AgoraEventType,
  CreateAgoraEventResponse,
  agoraEventsApiService,
} from 'features/common/services/agoraEventApiService';
import { createStore } from '../createStore';
import { AxiosError, AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders/paymentRequiredErrorHandler';

export type RTCprops = {
  channel: string;
  token: string;
  uid: number | null;
};

export type AudioVideoChatState = {
  RTCprops: RTCprops;
  eventData: CreateAgoraEventResponse;
  startCallPropsData: StartCallProps;
  eventId: string;
  headerLink: string;
  isScheduledCall: boolean;
  representationId: string;
  caseId: string;
};

export type StartCallProps = {
  eventType: AgoraEventType;
  caseId: string;
  endParticipantFirebaseId: string;
};

export type AudioVideoChatActions = {
  setRTCprops: (payload: RTCprops) => void;
  setIsScheduledCall: (value: boolean) => void;
  startCall: () => Promise<void>;
  setStartCallProps: (payload: StartCallProps) => void;
  joinCall: (firebaseId?: string | null) => Promise<void>;
  setEventId: (eventId: string) => void;
  setHeaderLink: (headers: RawAxiosResponseHeaders | AxiosResponseHeaders) => void;
  resetState: () => void;
  setRepresentationId: (id: string, isClearCaseId?: boolean) => void;
  updateEventdata: () => void;
};

const { createAgoraEvent, getAgoraEvent, createNotification } = agoraEventsApiService();

const initialState: AudioVideoChatState = {
  caseId: '',
  representationId: '',
  RTCprops: {
    channel: '',
    token: '',
    uid: null,
  },
  startCallPropsData: {
    caseId: '',
    endParticipantFirebaseId: '',
    eventType: 'video_call',
  },
  eventData: {} as CreateAgoraEventResponse,
  eventId: '',
  headerLink: '',
  isScheduledCall: false,
};

export const useAudioVideoChat = createStore<AudioVideoChatActions & AudioVideoChatState>(
  immer((set, get) => ({
    ...initialState,
    updateEventdata: () => {
      set(state => {
        state.eventData = { ...state.eventData, legalCase: { ...state.eventData.legalCase, onConsultation: false } };
      });
    },
    setRepresentationId: (representationId, isClearCaseId) => {
      set(state => {
        state.representationId = representationId;
        if (isClearCaseId) state.caseId = '';
      });
    },
    setIsScheduledCall: isScheduledCall => {
      set({ isScheduledCall });
    },

    resetState: () => {
      set(state => {
        const representationId = state.representationId;

        return {
          ...initialState,
          caseId: representationId ? state.caseId : '',
          representationId: representationId ? representationId : '',
        };
      });
    },
    setHeaderLink: headers => {
      const state = get();
      if (state.headerLink !== headers?.['link']) {
        state.headerLink = headers?.['link'] || '';
      }
    },
    setStartCallProps: payload => {
      set(state => {
        state.startCallPropsData = payload;
      });
    },
    setRTCprops: RTCprops => {
      set({ RTCprops });
    },

    startCall: async () => {
      try {
        const payload = get().startCallPropsData;
        const { data, headers } = await createAgoraEvent({
          type: payload.eventType,
          user: `/users/${payload.endParticipantFirebaseId}`,
          legalCase: `/legal_cases/${payload.caseId}`,
        });
        set({
          RTCprops: {
            channel: data.channelName,
            token: data.participants[0].token,
            uid: Number(data.participants[0].participantUid),
          },
          eventData: data,
          caseId: data?.legalCase?.['@id'],
        });
        get().setHeaderLink(headers);
        await createNotification(data?.id);
      } catch (error) {
        const errorResponse = error as AxiosError;

        if (errorResponse.response.status === 409) {
          throw errorResponse;
        }
        paymentRequiredErrorHandler(errorResponse, 'Failed to start the call, please try again!');
        console.error(errorResponse);
      }
    },
    setEventId: (eventId: string) => {
      set(state => {
        state.eventId = eventId;
      });
    },
    joinCall: async firebaseId => {
      try {
        const id = get().eventId;
        const { data, headers } = await getAgoraEvent(id);
        if (firebaseId) {
          const participant = data?.participants?.find(el => el?.user?.firebaseUser === firebaseId);

          set({
            RTCprops: {
              channel: data.channelName,
              token: participant?.token,
              uid: Number(participant?.participantUid),
            },
            eventData: data,
            caseId: data?.legalCase?.['@id'],
          });
        } else {
          set({
            RTCprops: {
              channel: data.channelName,
              token: data.participants[1].token,
              uid: Number(data.participants[1].participantUid),
            },
            eventData: data,
            caseId: data?.legalCase?.['@id'],
          });
        }

        get().setHeaderLink(headers);
      } catch (error) {
        paymentRequiredErrorHandler(error, 'Failed to connect to the call, please try again!');
        console.error(error);
      }
    },
  })),
  'audioVideoChat',
  initialState
);
