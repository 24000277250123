import { ROUTES } from 'features/common/variables/routes';

export const getPath = (searchParams: URLSearchParams) => {
  const caseId = searchParams.get('caseId');
  const eventId = searchParams.get('eventId');

  if (caseId) return `${ROUTES.openApp}?caseId=${caseId}`;

  if (eventId) return `${ROUTES.openApp}?eventId=true`;

  return ROUTES.openApp;
};
