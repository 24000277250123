import { backendApiClient } from '../../common';

export type GetInvitationsResponse = {
  '@context': string;
  '@id': string;
  '@type': string;
  'hydra:member': InvitationAcceptResponse[];
};

export type InvitationAcceptRequest = {
  accessCode: string;
};

export type InvitationAcceptResponse = {
  email: string;
  firstName: string;
  lastName: string;
  country: string;
  state: string;
};

export const createClientInvitationService = () => {
  const getClientInvitations = async () => {
    return await backendApiClient({}).get<GetInvitationsResponse>('/client_invitations');
  };

  const postClientInvitationAccept = async (accessCode: string) => {
    return await backendApiClient({}).post<InvitationAcceptRequest, { data: InvitationAcceptResponse }>(
      '/client_invitations/accept',
      { accessCode }
    );
  };
  return { postClientInvitationAccept, getClientInvitations };
};
