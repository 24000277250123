import { FC, useRef, useState } from 'react';
import { Menu, Stack } from '@mui/material';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { AgoraEvent } from 'features/common/services/agoraEventApiService';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { ListOfEventsItem } from './ListOfEvents/ListOfEventsItem';
import { EventContentProps } from './types';

export const EventContent: FC<EventContentProps> = ({
  event: { event, timeText },
  onDeleteEventIconClickHandler,
  onEditIconClickHandler,
  onClickConnectToCallButtonHandler,
  userFirebaseId,
}) => {
  const extendedProps = event.extendedProps as AgoraEvent;
  const eventTitle = event.title;

  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef<HTMLDivElement | null>(null);

  const popoverEnter = () => setOpenedPopover(true);

  const popoverLeave = () => setOpenedPopover(false);

  const eventOwnerFirebaseId = extendedProps?.participants?.[0]?.user?.firebaseUser;
  const isCurrentUserEventOwner = eventOwnerFirebaseId === userFirebaseId;

  const firstName = extendedProps?.participants?.[isCurrentUserEventOwner ? 1 : 0].user?.userProfile?.firstName;
  const lastName = extendedProps?.participants?.[isCurrentUserEventOwner ? 1 : 0].user?.userProfile?.lastName;
  const logoURL = extendedProps?.participants?.[isCurrentUserEventOwner ? 1 : 0].user?.logo?.publicUrl;

  return (
    <MDBox
      sx={{ display: 'flex', p: 0.5 }}
      aria-owns="mouse-over-popover"
      aria-haspopup="true"
      onMouseEnter={popoverEnter}
      onMouseLeave={popoverLeave}
      ref={popoverAnchor}
    >
      <Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          {extendedProps?.type === 'video_call' ? (
            <VideocamOutlinedIcon fontSize="medium" />
          ) : (
            <MicNoneOutlinedIcon fontSize="medium" />
          )}
          <MDTypography variant="button">{timeText}</MDTypography>
        </Stack>

        <MDTypography sx={{ whiteSpace: 'initial', fontSize: '14px' }}>{`${firstName} ${lastName}`}</MDTypography>
        <MDTypography sx={{ whiteSpace: 'initial', fontSize: '14px' }}>{eventTitle}</MDTypography>
      </Stack>

      <Menu
        anchorEl={popoverAnchor.current}
        open={openedPopover}
        onClose={popoverLeave}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{
          pointerEvents: 'none',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          onMouseEnter: popoverEnter,
          onMouseLeave: popoverLeave,
          sx: {
            pointerEvents: 'auto',
            borderRadius: '12px',
            padding: 0,
          },
        }}
      >
        <ListOfEventsItem
          onClickConnectToCallButtonHandler={onClickConnectToCallButtonHandler}
          firstName={firstName}
          lastName={lastName}
          caseNumber={extendedProps?.legalCase.caseNumber}
          eventStartTime={extendedProps?.startedAt}
          eventEndTime={extendedProps?.expiredAt}
          note={extendedProps?.note}
          eventId={extendedProps?.['@id']?.replace('/agora_events/', '')}
          onDeleteEventIconClickHandler={onDeleteEventIconClickHandler}
          onEditIconClickHandler={onEditIconClickHandler}
          eventType={extendedProps?.type}
          isEventOwner={isCurrentUserEventOwner}
          logoURL={logoURL}
        />
      </Menu>
    </MDBox>
  );
};
