import { FC } from 'react';
import { Stack, Switch } from '@mui/material';
import MDAvatar from 'components/MDAvatar';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { FileSignProvider } from 'features/common/services/fileSignApiService';

type IntegrationItemProps = {
  logoURL: string;
  title: string;
  description: string;
  isChecked: boolean;
  onToggleHandler: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  provider?: FileSignProvider;
  label?: React.ReactNode;
  isDisabled?: boolean;
};

export const IntegrationItem: FC<IntegrationItemProps> = ({
  onToggleHandler,
  isChecked,
  logoURL,
  description,
  title,
  provider,
  label,
  isDisabled,
}) => {
  return (
    <MDBox
      display="flex"
      justifyContent="space-between"
      alignItems={{ xs: 'flex-start', sm: 'center' }}
      flexDirection={{ xs: 'column', sm: 'row' }}
      sx={{ p: 1 }}
    >
      <Stack spacing={2} width={1}>
        <Stack flexDirection={{ xs: 'column', sm: 'row' }}>
          <MDBox display="flex" alignItems="center" flex={1}>
            <MDAvatar src={logoURL} variant="rounded" alt="Adobe logo" sx={{ width: 30, height: 30 }} />
            <MDBox ml={2} lineHeight={0}>
              <MDTypography variant="h5" fontWeight="medium">
                {title}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
            alignItems="center"
            width={{ xs: '100%', sm: 'auto' }}
            mt={{ xs: 1, sm: 0 }}
          >
            <MDBox lineHeight={0} mx={0}>
              <MDTypography variant="button" sx={{ fontWeight: '500' }} color={isChecked ? 'success' : 'error'}>
                {isChecked ? 'Connected' : 'Not connected'}
              </MDTypography>
            </MDBox>
            <Switch disabled={isDisabled} id={provider} checked={isChecked} onChange={onToggleHandler} />
          </MDBox>
        </Stack>
        <MDTypography variant="button">{description}</MDTypography>
        {label}
      </Stack>
    </MDBox>
  );
};
