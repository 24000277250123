import { FC, useEffect } from 'react';
import { match } from 'ts-pattern';
import { AUTH_FLOW_STEPS, useAuth } from 'features/store';
import {
  CreateAttorneyProfileStep,
  CreateUserProfileStep,
  SelectLawTypesStep,
  SignUpStep,
  UserWorkTypeStep,
  InvitationCodeStep,
  SelectLawTypesStateStep,
} from '../components/signUp';
import { SignInStep } from '../components/signIn';
import { VerifyPhoneStep } from '../components/VerifyPhoneStep';
import { AuthTemplateProps, AuthType } from '../types';

function getStepContent(step: AUTH_FLOW_STEPS, authType: AuthType): JSX.Element {
  return match({ step, authType })
    .with({ step: AUTH_FLOW_STEPS.FIREBASE_AUTH, authType: 'signIn' }, () => <SignInStep />)
    .with({ step: AUTH_FLOW_STEPS.FIREBASE_AUTH, authType: 'signUp' }, () => <SignUpStep />)
    .with({ step: AUTH_FLOW_STEPS.VERIFY_PHONE }, () => <VerifyPhoneStep />)
    .with({ step: AUTH_FLOW_STEPS.WORK_TYPE }, () => <UserWorkTypeStep />)
    .with({ step: AUTH_FLOW_STEPS.CREATE_USER_PROFILE }, () => <CreateUserProfileStep />)
    .with({ step: AUTH_FLOW_STEPS.INVITATION_CODE }, () => <InvitationCodeStep />)
    .with({ step: AUTH_FLOW_STEPS.CREATE_ATTORNEY_PROFILE }, () => <CreateAttorneyProfileStep />)
    .with({ step: AUTH_FLOW_STEPS.SELECT_LAW_TYPE }, () => <SelectLawTypesStep />)
    .with({ step: AUTH_FLOW_STEPS.SELECT_LAW_TYPE_STATE }, () => <SelectLawTypesStateStep />)
    .otherwise(() => null);
}

export const AuthTemplate: FC<AuthTemplateProps> = ({ authType }) => {
  const { activeStep, resetAllStepsData } = useAuth(state => ({
    activeStep: state.activeStep,
    resetAllStepsData: state.resetAllStepsData,
  }));

  useEffect(() => {
    const handlePopState = () => {
      if (activeStep !== AUTH_FLOW_STEPS.FIREBASE_AUTH) {
        resetAllStepsData();
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [activeStep]);

  return getStepContent(activeStep, authType);
};
