import { useCallback, useEffect, useRef, useState } from 'react';
import {
  ClientConfig,
  ConnectionState,
  IAgoraRTCRemoteUser,
  UID,
  createClient,
  IMicrophoneAudioTrack,
  ICameraVideoTrack,
} from 'agora-rtc-react';
import { ToastType, notice, useRouter } from 'features/common';
import {
  AgoraAudioVideoIncomingCallData,
  AgoraEventType,
  agoraEventsApiService,
} from 'features/common/services/agoraEventApiService';
import { RTCprops, useUserReviews } from 'features/store';
import { RemoteParticipantInfoUpdateMesseage, UseAudioVideoStateProps } from '../types';
import { useCountDown } from './useCountDown';

const APP_ID = process.env.REACT_APP_AGORA_APP_ID;
const CALLING_DURATION_SECONDS = 40;

const config: ClientConfig = {
  mode: 'rtc',
  codec: 'h265',
  role: 'host',
};

const useClient = createClient(config);

type Props = UseAudioVideoStateProps & {
  audioTrack: IMicrophoneAudioTrack | null;
  videoTrack: ICameraVideoTrack | null;
  eventType: AgoraEventType;
  RTCprops: RTCprops;
  isReady: boolean;
  backendUserFirebaseId: string;
  inviteCallData: AgoraAudioVideoIncomingCallData | null;
};

export const useAgoraState = ({
  resetState,
  isScheduledCall,
  currentUserFirebaseId,
  participants,
  eventId,
  isAttorney,
  representationId,
  eventStatus,
  setCallInvitationMessage,
  audioTrack,
  videoTrack,
  eventType,
  RTCprops,
  isReady,
  backendUserFirebaseId,
  inviteCallData,
}: Props) => {
  const { navigate, state } = useRouter();
  const { patchAgoraEventStatus, pushNotificationCallCancel, sendAgoraEventDuration } = agoraEventsApiService();

  const client = useClient();

  const setReviewedUserFirebaseId = useUserReviews(state => state.setReviewedUserFirebaseId);

  const resetStateHandler = () => {
    resetState();

    if (isAttorney) {
      navigate(state?.prevRoute, { replace: true });
    } else {
      !representationId && navigate(state?.prevRoute, { replace: true });
    }
  };

  const { secondsToLeft, startTimer, stopTimer } = useCountDown();

  const eventOwner = participants?.find(el => el?.role === 'role_agora_publisher');

  const localParticipant = participants?.find(el => el.user?.firebaseUser === currentUserFirebaseId);
  const remoteParticipant = participants?.find(el => el.user?.firebaseUser !== currentUserFirebaseId);

  const isCurrentUserEventOwner = eventOwner?.user?.firebaseUser === currentUserFirebaseId;
  const [start, setStart] = useState(false);
  const finishCallByUserRef = useRef(false);

  const [user, setUser] = useState<IAgoraRTCRemoteUser | null>(null);
  const [isRemoteUserAudioMuted, setIsRemoteUserAudioMuted] = useState(false);
  const [isRemoteUserVideoMuted, setIsRemoteUserVideoMuted] = useState(false);
  const [callEndingCount, setCallEndingCount] = useState<number | null>(null);

  const [isReconnecting, setIsReconnecting] = useState(false);
  const [isRunning, setIsRunning] = useState(false);

  const timerRef = useRef(0);

  const onCloseAgoraConnectionHandler = async () => {
    try {
      await client?.leave();
      client?.removeAllListeners();

      audioTrack?.close();
      videoTrack?.close();

      setStart(false);
    } catch (error) {
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong!');
    }
  };

  const onClickLeaveButtonHandler = useCallback(async () => {
    try {
      await onCloseAgoraConnectionHandler();
      finishCallByUserRef.current = true;

      if (isAttorney && !!user) {
        await sendAgoraEventDuration(eventId, timerRef?.current || 0);
      }

      if (isCurrentUserEventOwner) {
        if (eventStatus !== 'finished') {
          const status = !!user ? 'finished' : 'missed';

          !isScheduledCall && (await patchAgoraEventStatus(eventId, { status }));

          if (!user) {
            await pushNotificationCallCancel(eventId, { manualCallCancel: true });
          }
        }
      }

      !!user && setReviewedUserFirebaseId(remoteParticipant?.user?.firebaseUser);
      resetStateHandler();
    } catch (error) {
      resetStateHandler();
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  }, [user, eventId, representationId]);

  const onMissCallLeaveConversationHandler = async () => {
    try {
      await onCloseAgoraConnectionHandler();
      finishCallByUserRef.current = true;
      notice(
        ToastType.INFO,
        `${remoteParticipant?.user?.userProfile?.firstName} ${remoteParticipant?.user?.userProfile?.lastName} does not answear the call!`
      );

      await patchAgoraEventStatus(eventId, { status: 'missed' });
      await pushNotificationCallCancel(eventId, { manualCallCancel: true });

      stopTimer();
      resetState();

      navigate(state?.prevRoute, { replace: true });
    } catch (error) {
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  };

  const onDeclineCallLeaveConversationHandler = async () => {
    await onCloseAgoraConnectionHandler();
    setCallInvitationMessage(null);
    finishCallByUserRef.current = true;
    stopTimer();
    resetState();
    navigate(state?.prevRoute, { replace: true });
  };

  const onUserJoinedListener = async (user: IAgoraRTCRemoteUser) => {
    try {
      user?.hasAudio && setIsRemoteUserAudioMuted(user?.hasAudio);
      user?.hasVideo && setIsRemoteUserVideoMuted(user?.hasVideo);

      setUser(user);
      stopTimer();
      setIsRunning(true);

      if (isCurrentUserEventOwner) {
        await patchAgoraEventStatus(eventId, { status: 'in_progress' });
      }

      const remoteUserData = participants.find(el => el?.participantUid === user.uid)?.user;
      remoteUserData &&
        notice(
          ToastType.INFO,
          `${remoteUserData?.userProfile?.firstName} ${remoteUserData?.userProfile?.lastName} is joined`
        );
    } catch (error) {
      notice(ToastType.ERROR, 'Something went wrong!');
      console.error(error);
    }
  };

  const onUserInfoUpdatedListener = async (_: UID, message: RemoteParticipantInfoUpdateMesseage) => {
    if (message === 'mute-audio') {
      setIsRemoteUserAudioMuted(true);
    }
    if (message === 'unmute-audio') {
      setIsRemoteUserAudioMuted(false);
    }
  };

  const onUserPublishedListener = async (remoteUser: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
    await client.subscribe(remoteUser, mediaType);
    if (mediaType === 'video') {
      setUser(prevUser => ({ ...prevUser, videoTrack: remoteUser.videoTrack }));
    }
    if (mediaType === 'audio') {
      remoteUser.audioTrack?.play();

      setUser(prevUser => ({ ...prevUser, audioTrack: remoteUser.audioTrack }));
    }
  };

  const onUserUnPublishedListener = (remoteUser: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
    if (mediaType === 'audio') {
      remoteUser.audioTrack?.stop();
    }
    if (mediaType === 'video') {
      setUser(prevUser => ({ ...prevUser, videoTrack: null }));
    }
  };

  const onUserLeftListener = async (remoteUser: IAgoraRTCRemoteUser) => {
    const userToLeft = participants?.find(el => el.participantUid === remoteUser.uid)?.user;

    try {
      await onCloseAgoraConnectionHandler();
      finishCallByUserRef.current = true;

      if (isAttorney) await sendAgoraEventDuration(eventId, timerRef?.current || 0);

      if (isCurrentUserEventOwner && !isScheduledCall) await patchAgoraEventStatus(eventId, { status: 'finished' });
      setReviewedUserFirebaseId(userToLeft?.firebaseUser);
      resetStateHandler();
    } catch (error) {
      resetStateHandler();
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }

    setUser(null);
    notice(
      ToastType.INFO,
      `${userToLeft?.userProfile?.firstName} ${userToLeft?.userProfile?.lastName} left the conversation`
    );
  };

  const onConnectionStateChangeListener = (currState: ConnectionState) => {
    if (currState === 'RECONNECTING') {
      setIsReconnecting(true);
      notice(ToastType.ERROR, 'Connection is lost! Reconecting...');
    }
    if (currState === 'CONNECTED' && isReconnecting) {
      setIsReconnecting(false);
      notice(ToastType.INFO, 'Connection restored!');
    }
  };

  const onTokenPrivilegeWillExpireListener = () => {
    if (!callEndingCount) {
      setCallEndingCount(30);
      notice(ToastType.WARNING, '30 seconds left until the end of the call!');
    }
  };

  const onTokenPrivilegeDidExpireListener = async () => {
    notice(ToastType.WARNING, 'Ending a call...!');
    await onClickLeaveButtonHandler();
  };

  const cleanUpFinishCallHandler = async () => {
    stopTimer();
    await onCloseAgoraConnectionHandler();
    if (isCurrentUserEventOwner) {
      await patchAgoraEventStatus(eventId, { status: 'finished' });
    }
    resetStateHandler();
  };

  //init Agora connection
  useEffect(() => {
    const initCall = async () => {
      try {
        client.on('user-joined', onUserJoinedListener);
        client.on('user-info-updated', onUserInfoUpdatedListener);
        client.on('user-published', onUserPublishedListener);
        client.on('user-unpublished', onUserUnPublishedListener);

        await audioTrack?.setMuted(false);

        await videoTrack?.setMuted(eventType === 'voice_call' ? true : false);

        await client.join(APP_ID, RTCprops.channel, RTCprops.token, RTCprops.uid);

        if (eventType === 'video_call' && audioTrack?.enabled && videoTrack?.enabled) {
          await client.publish([audioTrack, videoTrack]);
        }

        if (eventType === 'voice_call' && audioTrack?.enabled) {
          await client.publish([audioTrack]);
        }
        setStart(true);

        if (eventOwner?.user?.firebaseUser === backendUserFirebaseId && !isScheduledCall) {
          startTimer(CALLING_DURATION_SECONDS);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (isReady && (eventType === 'voice_call' ? audioTrack : audioTrack && videoTrack) && !!RTCprops.token) {
      initCall();
    }
  }, [client, isReady, audioTrack, videoTrack, RTCprops.token]);

  useEffect(() => {
    client.on('connection-state-change', onConnectionStateChangeListener);
    client.on('token-privilege-will-expire', onTokenPrivilegeWillExpireListener);
    client.on('token-privilege-did-expire', onTokenPrivilegeDidExpireListener);
    client.on('user-left', onUserLeftListener);

    return () => {
      client.off('connection-state-change', onConnectionStateChangeListener);
      client.off('token-privilege-will-expire', onTokenPrivilegeWillExpireListener);
      client.off('token-privilege-did-expire', onTokenPrivilegeDidExpireListener);
      client.off('user-left', onUserLeftListener);
    };
  }, [isReconnecting, callEndingCount, audioTrack, videoTrack, representationId, RTCprops.token]);

  useEffect(() => {
    if (inviteCallData?.callCancel && !user && !secondsToLeft) {
      onCloseAgoraConnectionHandler();
      resetStateHandler();
      setCallInvitationMessage(null);
    }
    if (inviteCallData?.callCancel && !user && secondsToLeft) {
      onDeclineCallLeaveConversationHandler();
    }
  }, [inviteCallData, secondsToLeft]);

  useEffect(() => {
    if (secondsToLeft === 0 && !user) {
      onMissCallLeaveConversationHandler();
    }
  }, [secondsToLeft]);

  useEffect(() => {
    return () => {
      if (!finishCallByUserRef.current === true) {
        cleanUpFinishCallHandler();
      }
    };
  }, []);

  return {
    client,
    secondsToLeft,
    isReconnecting,
    user,
    remoteParticipant,
    localParticipant,
    start,
    isRunning,
    isRemoteUserAudioMuted,
    isRemoteUserVideoMuted,
    timerRef,
    onClickLeaveButtonHandler,
    onCloseAgoraConnectionHandler,
  };
};
