import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useMercure } from 'features/common/hooks/useMercure';
import { UserStatisticState, useUserStatistic } from 'features/store';
import { useUser } from 'features/common';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'features/common/variables/routes';

type AppStatisticContextValue = Pick<UserStatisticState, 'adminStatistic' | 'userStatistic'>;

const AppStatisticContext = createContext<AppStatisticContextValue>({
  adminStatistic: {},
  userStatistic: {},
});

export const AppStatisticContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { backendUser, isAuthorized, isAdmin } = useUser();
  const location = useLocation();

  const {
    getAdminStatisticHandler,
    getUserStatisticHandler,
    onReceiveEventHandler,
    adminHeaderLink,
    userHeaderLink,
    adminStatistic,
    userStatistic,
  } = useUserStatistic(state => ({
    getUserStatisticHandler: state.getUserStatisticHandler,
    getAdminStatisticHandler: state.getAdminStatisticHandler,
    userStatistic: state.userStatistic,
    adminStatistic: state.adminStatistic,
    userHeaderLink: state.userHeaderLink,
    adminHeaderLink: state.adminHeaderLink,
    onReceiveEventHandler: state.onReceiveEventHandler,
  }));

  const onReceiveUseStatisticEventHandler = useCallback(
    (message: MessageEvent) => onReceiveEventHandler(message, 'userStatistic'),
    []
  );
  const onReceiveAdminStatisticEventHandler = useCallback(
    (message: MessageEvent) => onReceiveEventHandler(message, 'adminStatistic'),
    []
  );

  useEffect(() => {
    if (location.pathname === ROUTES.lockScreen) return;
    if (backendUser && isAuthorized) {
      isAdmin && getAdminStatisticHandler();
      !isAdmin && getUserStatisticHandler(backendUser?.firebaseUser);
    }
  }, [backendUser, isAuthorized]);

  useMercure({
    eventId: backendUser?.firebaseUser,
    mercureSubscribeURL: userHeaderLink,
    topicSubURL: 'user_statistics',
    onReceiveEventHandler: onReceiveUseStatisticEventHandler,
    isDissallowSubscription: isAdmin,
  });

  useMercure({
    isWithOutEventId: true,
    mercureSubscribeURL: adminHeaderLink,
    topicSubURL: 'security/admin_statistics',
    onReceiveEventHandler: onReceiveAdminStatisticEventHandler,
    isDissallowSubscription: !isAdmin,
  });

  const contextValue = useMemo(
    () => ({
      userStatistic,
      adminStatistic,
    }),
    [adminStatistic, userStatistic]
  );

  return <AppStatisticContext.Provider value={contextValue}>{children}</AppStatisticContext.Provider>;
};

export const useAppStatisticContextState = (): AppStatisticContextValue => {
  const context = useContext(AppStatisticContext);

  if (context === undefined) {
    throw new Error('useAppStatisticContextState was used outside of its Provider');
  }

  return context;
};
