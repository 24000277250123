import { FC, memo, useEffect } from 'react';
import { Autocomplete, Checkbox, CircularProgress, FormControlLabel, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material//Search';
import { useFiltersState } from 'features/legal-cases-feed/hooks';
import { BaseFormField } from 'features/common';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';

type FiltersMenuContentProps = {
  onCloseFiltersMenuHandler: () => void;
};
export const FiltersMenuContent: FC<FiltersMenuContentProps> = memo(({ onCloseFiltersMenuHandler }) => {
  const {
    countryStateList,
    currentAttorneyCaseLawTypes,
    county,
    countyList,
    isApplyButtonDisabled,
    isCaseLawTypesLoading,
    isCountyListLoading,
    isResetButtonDisabled,
    isStateListLoading,
    lawTypeSearchValue,
    lawTypes,
    selectedLawTypes,
    filtersData,
    state,
    setCounty,
    setState,
    onFilterLawTypesHandler,
    onResetButtonClickHandler,
    onApplyFiltersHandler,
    onChangeCaseLawTypeHandler,
    onChangeCountyHandler,
    onChangeStateHandler,
  } = useFiltersState(onCloseFiltersMenuHandler);

  useEffect(() => {
    if (!state && countryStateList.length) {
      const state = countryStateList.find(el => el.isoCode === filtersData['state.isoCode']);

      setState(state || null);
    }
  }, [filtersData['state.isoCode'], countryStateList.length]);

  useEffect(() => {
    if (!county && countyList.length) {
      const county = countyList.find(el => el['@id'] === filtersData.county);

      setCounty(county || null);
    }
  }, [filtersData.county, countyList.length]);

  return (
    <Stack height={1} spacing={2} position="relative">
      <Stack spacing={2.5} mt={2} flexGrow={1}>
        <Stack spacing={1}>
          <MDTypography varaint="subtitle2">Location</MDTypography>

          <Autocomplete
            value={state}
            onChange={onChangeStateHandler}
            getOptionLabel={option => option.name}
            options={countryStateList}
            freeSolo={false}
            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
            disableClearable={false}
            renderInput={params => (
              <BaseFormField
                formFieldProps={{ pb: 1 }}
                formInputProps={{
                  ...params,
                  label: 'State',
                  placeholder: 'Choose a state',
                  InputLabelProps: { shrink: true },
                  fullWidth: true,
                  InputProps: {
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isStateListLoading ? (
                          <MDBox pr={0} display="flex">
                            <CircularProgress color="inherit" size={20} />
                          </MDBox>
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  },
                }}
              />
            )}
          />
          <Autocomplete
            value={county}
            onChange={onChangeCountyHandler}
            getOptionLabel={option => option?.shortName}
            options={countyList}
            disabled={!state}
            freeSolo={false}
            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
            disableClearable={false}
            renderInput={params => (
              <BaseFormField
                formFieldProps={{ pb: 0 }}
                formInputProps={{
                  ...params,
                  label: 'County',
                  placeholder: !state ? 'Select a state first' : 'Select a county',
                  InputLabelProps: { shrink: true },
                  fullWidth: true,
                  InputProps: {
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isCountyListLoading ? (
                          <MDBox pr={0} display="flex">
                            <CircularProgress color="inherit" size={20} />
                          </MDBox>
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  },
                }}
              />
            )}
          />
        </Stack>

        <Stack spacing={1}>
          <MDTypography varaint="subtitle2">Law types</MDTypography>
          <BaseFormField
            formFieldProps={{
              pb: 0,
            }}
            formInputProps={{
              value: lawTypeSearchValue,
              disabled: isCaseLawTypesLoading || !currentAttorneyCaseLawTypes.length,
              label: 'Search',
              placeholder: 'Search law types',
              fullWidth: true,
              onChange: onFilterLawTypesHandler,
              InputProps: {
                startAdornment: <SearchIcon fontSize="medium" />,
              },
            }}
          />
          {isCaseLawTypesLoading ? (
            <Stack alignItems="center" justifyContent="center" height={200}>
              <CircularProgress size={40} />
            </Stack>
          ) : (
            <Stack
              spacing={1}
              sx={{
                maxHeight: `calc(100vh - 480px)`,
                overflowY: 'auto',
              }}
            >
              {lawTypes?.length ? (
                lawTypes?.map(lawType => (
                  <FormControlLabel
                    key={lawType['@id']}
                    id={lawType['@id']}
                    control={
                      <Checkbox
                        id={lawType['@id']}
                        checked={selectedLawTypes.includes(lawType['@id'])}
                        onChange={onChangeCaseLawTypeHandler}
                      />
                    }
                    sx={{
                      ml: 0,
                    }}
                    label={
                      <MDTypography variant="button" color="text">
                        {lawType.title}
                      </MDTypography>
                    }
                  />
                ))
              ) : (
                <MDTypography
                  variant="subtitle2"
                  sx={{
                    pt: 2,
                    textAlign: 'center',
                  }}
                >
                  No law types were found
                </MDTypography>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack spacing={2} pb={2} direction="column" position="absolute" sx={{ bottom: 0, width: 1 }}>
        <MDButton
          variant="gradient"
          color="dark"
          disabled={isResetButtonDisabled}
          onClick={onResetButtonClickHandler}
          sx={{ textTransform: 'none' }}
        >
          Clear filters
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          disabled={isApplyButtonDisabled}
          onClick={onApplyFiltersHandler}
          sx={{ textTransform: 'none' }}
        >
          Apply filters
        </MDButton>
      </Stack>
    </Stack>
  );
});
