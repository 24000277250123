import { useEffect, useState } from 'react';
import { abortRequestErrorHandler } from '../errorHanders';

export type Errored = Error | string | readonly Error[] | readonly string[];

type AsyncStatus = 'idle' | 'pending' | 'successful' | 'failed';

abstract class AsyncStatePredicate<T, E extends Errored> {
  constructor(public readonly status: AsyncStatus) {
    status;
  }
  isIdle(): this is IdleState<T, E> {
    return this.status === 'idle';
  }
  isPending(): this is PendingState<T, E> {
    return this.status === 'pending';
  }
  isSuccessful(): this is SuccessfulState<T, E> {
    return this.status === 'successful';
  }
  isFailed(): this is FailedState<T, E> {
    return this.status === 'failed';
  }
  static createIdle<T, E extends Errored>(): IdleState<T, E> {
    return new IdleState();
  }
  static createPending<T, E extends Errored>(): PendingState<T, E> {
    return new PendingState();
  }
  static createSuccessful<T, E extends Errored>(data: T): SuccessfulState<T, E> {
    return new SuccessfulState(data);
  }
  static createFailed<T, E extends Errored>(error: E): FailedState<T, E> {
    return new FailedState(error);
  }
}

class IdleState<T, E extends Errored> extends AsyncStatePredicate<T, E> {
  constructor() {
    super('idle');
  }
}

class PendingState<T, E extends Errored> extends AsyncStatePredicate<T, E> {
  constructor() {
    super('pending');
  }
}
class SuccessfulState<T, E extends Errored> extends AsyncStatePredicate<T, E> {
  constructor(public readonly data: T) {
    super('successful');
  }
}
class FailedState<T, E extends Errored> extends AsyncStatePredicate<T, E> {
  constructor(public readonly error: E) {
    super('failed');
  }
}

export type AsyncState<T, E extends Errored = Errored> =
  | IdleState<T, E>
  | PendingState<T, E>
  | SuccessfulState<T, E>
  | FailedState<T, E>;

// type ExtendedAsyncState<T> = AsyncStatePredicate & AsyncState<T>;
type ExtendedAsyncState<T> = AsyncState<T>;

export const useAsync = <P extends readonly unknown[], R>(
  createAsyncProcess: (...arg: P) => Promise<R>,
  config?: {
    readonly immediateCall?: boolean;
    readonly immediateInvocationArgs: P;
  }
): readonly [AsyncState<R>, (...arg: P) => Promise<R>] => {
  const [asyncState, setAsyncState] = useState<ExtendedAsyncState<R>>(AsyncStatePredicate.createIdle());

  const init = async (...arg: P): Promise<R> => {
    setAsyncState(AsyncStatePredicate.createPending());
    try {
      const result_1 = await createAsyncProcess(...arg);
      setAsyncState(AsyncStatePredicate.createSuccessful(result_1));
      return result_1;
    } catch (e: any) {
      setAsyncState(AsyncStatePredicate.createFailed(e));
      abortRequestErrorHandler(e);
    }
  };

  useEffect(() => {
    if (config?.immediateCall) {
      init(...config.immediateInvocationArgs);
    }
  }, []);

  return [asyncState, init];
};
