import { FC } from 'react';
import { Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import { DashboardLayout, useUser } from 'features/common';
import { BaseSideNaveMenu } from 'features/common/components/BaseSideNaveMenu';
import { ROUTES } from 'features/common/variables/routes';

type TemplateWrapperProps = {
  children: React.ReactNode;
};

const sidenavItems = [
  { icon: 'folder_shared', label: 'profile', href: ROUTES.profile },
  { icon: 'gpp_good', label: 'security', href: ROUTES.security },
  { icon: 'edit_notifications', label: 'notifications', href: ROUTES.notifications },
  { icon: 'integration_instructions', label: 'integrations', href: ROUTES.integrations },
  { icon: 'work', label: `practice area's`, href: ROUTES.attorneyLawTypes },
];

export const TemplateWrapper: FC<TemplateWrapperProps> = ({ children }) => {
  const { isAdmin, isAttorney } = useUser();

  const sidenavRoutes = isAdmin ? sidenavItems.slice(0, 2) : !isAttorney ? sidenavItems.slice(0, 3) : sidenavItems;
  return (
    <DashboardLayout>
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <BaseSideNaveMenu sidenavItems={sidenavRoutes} />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {children}
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};
