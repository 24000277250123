import { FC, memo, useCallback, useEffect, useState } from 'react';
import { DialogActions, DialogContent, DialogTitle, Divider, Stack } from '@mui/material';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { FileSignTemplate, fileSignApiService } from 'features/common/services/fileSignApiService';
import { ToastType, notice } from 'features/common/modules';
import { TemplateItem } from './TemplateItem';
import { Loader } from '../Loader';
import { SelectFileSignTemplateModalContentProps } from './types';

export const SelectFileSignTemplateModalContent: FC<SelectFileSignTemplateModalContentProps> = memo(
  ({ onClickSelectTemplateItemHandler, onCloseWindowHandler, selectedFileSignProvider }) => {
    const [signTemplates, setSignTemplates] = useState<FileSignTemplate[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const { getSignProviderTemplates } = fileSignApiService();

    const onGetDocusignTemplatesHandler = async () => {
      try {
        const { data } = await getSignProviderTemplates(selectedFileSignProvider?.provider);

        setSignTemplates(data['hydra:member']);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
        notice(ToastType.ERROR, `Failed to fetch ${selectedFileSignProvider?.label} templates, please try again!`);
      }
    };

    useEffect(() => {
      onGetDocusignTemplatesHandler();
    }, []);

    const onSelectItemClickHandler = useCallback(
      (event: React.MouseEvent<HTMLLIElement>) => {
        const selectedTemplate = signTemplates?.find(el => el?.id === event.currentTarget.id);
        onClickSelectTemplateItemHandler(selectedTemplate);
      },
      [signTemplates]
    );

    return (
      <Stack width={1}>
        <DialogTitle textAlign="center">{selectedFileSignProvider?.label} templates</DialogTitle>

        {isLoading ? (
          <DialogContent sx={{ my: 4 }}>
            <Loader />
          </DialogContent>
        ) : (
          <>
            <DialogContent sx={{ pb: 0 }}>
              <Stack
                spacing={1}
                sx={{ maxHeight: 380, overflowY: 'auto', pr: '4px' }}
                divider={<Divider orientation="horizontal" />}
              >
                {signTemplates?.length ? (
                  signTemplates.map(template => (
                    <TemplateItem
                      key={template?.id}
                      onClickHandler={onSelectItemClickHandler}
                      id={template?.id}
                      title={template?.name}
                    />
                  ))
                ) : (
                  <Stack justifyContent="center" flex={1} py={2.5}>
                    <MDTypography sx={{ mx: 'auto' }}>No templates were found...</MDTypography>
                  </Stack>
                )}
              </Stack>
              {signTemplates?.length > 0 && <Divider sx={{ my: 1 }} />}
            </DialogContent>
            <DialogActions>
              <MDButton fullWidth variant="outlined" color="dark" onClick={onCloseWindowHandler}>
                Cancel
              </MDButton>
            </DialogActions>
          </>
        )}
      </Stack>
    );
  }
);
