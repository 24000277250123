import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSettings } from 'features/store';
import { shallow } from 'zustand/shallow';
import { formatMinutesToSeconds, formatSecondsToMinutes } from 'features/common/helpers/utilities';
import {
  AudioVideoCallSchema,
  audioVideoCallValidationSchema,
  securityValidationSchema,
  SecuritySchema,
} from '../form.config';
import { useConfigSettingsContextState } from '../context/ConfigSettingsContextProvider';

export const useSettingForms = () => {
  const [isLoading, setIsLoading] = useState(true);

  const { configMap } = useConfigSettingsContextState();

  const { fetchSettingsHandler, updateSettingsHandler } = useSettings(
    state => ({
      fetchSettingsHandler: state.fetchSettingsHandler,
      updateSettingsHandler: state.updateSettingsHandler,
    }),
    shallow
  );

  const onFetchSettingsHandler = async () => {
    await fetchSettingsHandler({ itemsPerPage: 50 });
    setIsLoading(false);
  };

  useEffect(() => {
    onFetchSettingsHandler();
  }, []);

  const idleTimeoutConfigValueInMinutes = formatSecondsToMinutes(
    configMap['security_pin_lock_expired_seconds']?.configValue
  );

  const {
    register,
    formState: { errors, isSubmitting, isValid, isDirty },
    handleSubmit,
    watch,
  } = useForm<SecuritySchema>({
    resolver: yupResolver(securityValidationSchema),
    values: {
      clientinvitationTTL: configMap['client_invitation_expired_hours']?.configValue || '',
      idle: idleTimeoutConfigValueInMinutes || '',
    },
    mode: 'onTouched',
  });

  const isSaveSecurityButtonDisabled = !isValid || isSubmitting || !isDirty;

  const onSubmitSecurityFormHandler = handleSubmit(async formValues => {
    const promise = [];

    if (watch('clientinvitationTTL') !== configMap['client_invitation_expired_hours']?.configValue) {
      promise.push(
        updateSettingsHandler(configMap['client_invitation_expired_hours']?.id, {
          configValue: formValues.clientinvitationTTL,
        })
      );
    }

    if (formValues.idle !== idleTimeoutConfigValueInMinutes) {
      promise.push(
        updateSettingsHandler(configMap['security_pin_lock_expired_seconds']?.id, {
          configValue: formatMinutesToSeconds(formValues.idle),
        })
      );
    }
    await Promise.all(promise);
  });

  const videoCallConfigValueInMinutes = formatSecondsToMinutes(configMap['agora_video_call_time_seconds']?.configValue);
  const audioCallConfigValueInMinutes = formatSecondsToMinutes(configMap['agora_voice_call_time_seconds']?.configValue);

  const {
    register: audioVideoRegister,
    formState: audioVideoFormState,
    handleSubmit: audioVideoHandleSubmit,
    watch: audioVideoWatch,
  } = useForm<AudioVideoCallSchema>({
    resolver: yupResolver(audioVideoCallValidationSchema),
    values: {
      video: videoCallConfigValueInMinutes || '',
      audio: audioCallConfigValueInMinutes || '',
    },
    mode: 'onTouched',
  });

  const audioFieldValue = audioVideoWatch('audio');
  const videoFieldValue = audioVideoWatch('video');

  const isSaveAudioVideoButtonDisabled =
    !audioVideoFormState.isValid || !audioVideoFormState.isDirty || audioVideoFormState.isSubmitting;

  const onSubmitAudioVideoFormHandler = audioVideoHandleSubmit(async formValues => {
    videoFieldValue !== videoCallConfigValueInMinutes &&
      (await updateSettingsHandler(configMap['agora_video_call_time_seconds']?.id, {
        configValue: formatMinutesToSeconds(formValues.video),
      }));

    audioFieldValue !== audioCallConfigValueInMinutes &&
      (await updateSettingsHandler(configMap['agora_voice_call_time_seconds']?.id, {
        configValue: formatMinutesToSeconds(formValues.audio),
      }));
  });

  return {
    onSubmitAudioVideoFormHandler,
    onSubmitSecurityFormHandler,
    isSaveAudioVideoButtonDisabled,
    isSaveSecurityButtonDisabled,
    isLoading,
    isSubmitting,
    errors,
    audioVideoFormState,
    audioVideoRegister,
    register,
  };
};
