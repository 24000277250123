import { useCallback, useState } from 'react';
import {
  GenerateSubscriptionURLPayload,
  SubscriptionStatus,
  UnsubscribeResponse,
  subscriptionsApiService,
} from 'features/subscriptions/services/subscriptionsApiService';
import { ToastType, notice } from '../modules';
import { ROUTES } from '../variables/routes';
import { useUser } from './useUser';
import { useRouter } from './useRouter';

const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL;
const SUCCESS_URL = `${BASE_APP_URL}${ROUTES.successPayment}?session_id={CHECKOUT_SESSION_ID}`;
const CANCEL_URL = `${BASE_APP_URL}${ROUTES.cancelPayment}`;

export const SUBSCRIPTION_PLAN_LABEL_MAP: Partial<Record<SubscriptionStatus, string>> = {
  paid: 'ATTORNEY',
  enterprise: 'ENTERPRISE',
};

export const useSubscription = () => {
  const { generateSubscriptionURL, checkSubscriptionStatus, unsubscribe } = subscriptionsApiService();

  const isAttorney = useUser().isAttorney;
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  const onGenerateSubscriptionUrlHandler = useCallback(
    async ({
      cancelUrl = CANCEL_URL,
      enterprise = false,
      successUrl = SUCCESS_URL,
      period,
    }: Partial<GenerateSubscriptionURLPayload>) => {
      try {
        setIsLoading(true);
        const { data } = await generateSubscriptionURL({
          enterprise,
          successUrl,
          cancelUrl,
          ...(period && { period }),
        });
        setIsLoading(false);
        if (data?.paymentUrl) {
          window.location.href = data.paymentUrl;
        }
      } catch (error: any) {
        setIsLoading(false);
        console.error(error);
        if (error?.response?.status === 422 || error?.response?.status === 403) {
          notice(ToastType.ERROR, error?.response?.data?.['hydra:description']);
        } else notice(ToastType.ERROR, 'Failed to get subscription URL, please try again!');
      }
    },
    []
  );

  const [subscriptionStatus, setSubscriptionStatus] = useState<{
    isSubscriptionActive: boolean;
    status: SubscriptionStatus | null;
  }>({
    isSubscriptionActive: true,
    status: null,
  });

  const [isCheckingSubscriptionStatus, setIsCheckingSubscriptionStatus] = useState(false);

  const onGetUserSubscriptionStatus = useCallback(async () => {
    try {
      setIsCheckingSubscriptionStatus(true);
      const { data } = await checkSubscriptionStatus();
      setSubscriptionStatus({ isSubscriptionActive: data?.hasSubscription, status: data?.status });
      setIsCheckingSubscriptionStatus(false);
    } catch (error: any) {
      setIsCheckingSubscriptionStatus(false);
      console.error(error);
      if (error?.code !== 'ERR_CANCELED') notice(ToastType.ERROR, 'Failed to get user subscription status!');
    }
  }, []);

  const isBuySubscriptionButton = !subscriptionStatus.isSubscriptionActive && isAttorney;

  const isCancellSubscriptionButton =
    subscriptionStatus.isSubscriptionActive &&
    (subscriptionStatus.status === 'paid' || subscriptionStatus.status === 'enterprise') &&
    isAttorney;

  const onClickBuySubscription = useCallback(() => router.navigate(ROUTES.subscription), []);

  const [isUnsubscribing, setIsUnsubscribing] = useState(false);

  const onClickCancellSubscription = useCallback(async () => {
    try {
      setIsUnsubscribing(true);
      const { data } = await unsubscribe();

      await onGetUserSubscriptionStatus();

      showToastMessage(data);
      setIsUnsubscribing(false);
    } catch (error: any) {
      setIsUnsubscribing(false);
      if (error?.response?.status === 422) {
        error?.response?.data?.['hydra:description'] &&
          notice(ToastType.ERROR, error?.response?.data?.['hydra:description']);
        return;
      }
      notice(ToastType.ERROR, 'Failed to cancel subscription, please try again!');
    }
  }, []);

  const subscriptionPlanLabel: string = SUBSCRIPTION_PLAN_LABEL_MAP[subscriptionStatus.status];

  return {
    isCancellSubscriptionButton,
    subscriptionStatus,
    isBuySubscriptionButton,
    onGetUserSubscriptionStatus,
    onGenerateSubscriptionUrlHandler,
    isLoading,
    onClickBuySubscription,
    onClickCancellSubscription,
    isUnsubscribing,
    isCheckingSubscriptionStatus,
    subscriptionPlanLabel,
  };
};

const showToastMessage = ({ deactivated, hasSubscriptionInAnotherStores }: UnsubscribeResponse) => {
  if (deactivated) {
    notice(
      ToastType.SUCCESS,
      'Subscription canceled. You can continue using the web app until the end of the current billing period!',
      { autoClose: 6000 }
    );

    if (hasSubscriptionInAnotherStores)
      return notice(ToastType.INFO, 'Please note that you have subscriptions purchased through the mobile app!', {
        autoClose: 6000,
      });
  } else {
    if (hasSubscriptionInAnotherStores)
      return notice(
        ToastType.INFO,
        'Subscription purchased on mobile. Please cancel your subscription through the mobile app.'
      );

    if (!hasSubscriptionInAnotherStores)
      return notice(
        ToastType.INFO,
        'Subscription already canceled. You can continue using the web app until the end of the current billing period.'
      );
  }
};
