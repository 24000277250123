import { ROUTES } from 'features/common/variables/routes';

export const sideNavMenuRoutes = (isClioConnected: boolean) => [
  { icon: 'settings outlined', label: 'settings', href: ROUTES.clioSettings },
  {
    icon: 'group_outlined',
    label: 'case law types mapping',
    href: ROUTES.clioCaseLawTypesMapping,
    isDisabled: !isClioConnected,
  },
];
