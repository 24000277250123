import { FC } from 'react';
import MDBox from 'components/MDBox';

export const HighlightBar: FC = () => {
  return (
    <MDBox
      component="span"
      sx={{
        content: "''",
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        width: 7,
        backgroundColor: theme => theme.palette.background.note,
        borderRadius: '0 12px 12px 0',
        zIndex: ({ zIndex }) => zIndex.fab,
      }}
    />
  );
};
