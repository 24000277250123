import { backendApiClient, BaseData } from '../../common';

const BASE_DOCUSIGN_API_URL = '/docu_sign';

export type DocusignAuthURL = BaseData<'DocuSignAuthDto'> & {
  authUrl: string;
};

export const docusignApiService = () => {
  const generateDocusignAuthURL = async (redirectUrl: string) =>
    backendApiClient({}).post<DocusignAuthURL>(`${BASE_DOCUSIGN_API_URL}/generate_auth_url`, { redirectUrl });

  return {
    generateDocusignAuthURL,
  };
};
