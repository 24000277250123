import { FC, memo } from 'react';
import { Stack } from '@mui/material';
import MDBox from 'components/MDBox';
import MDAvatar from 'components/MDAvatar';
import MDTypography from 'components/MDTypography';
import { getAvatarCharacters } from 'features/common/helpers/utilities';
import { dateToCustomFormat } from 'features/common/helpers/date';
import MDBadge from 'components/MDBadge';
import { SideMenu, useRouter } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';
import { useUserProfile } from 'features/common/hooks/useUserProfile';
import { MenuPopover } from 'features/common/components/MenuPopover';
import { UserOverviewContent } from 'features/case-details/components/CaseInfo/UserOverviewContent';
import { CompanyInfoSideMenuContent } from 'features/my-company/components/CompanyInfoSideMenuContent';

type LagalCaseFeedCardProps = {
  caseLawType: string;
  caseNumber: string;
  firstName: string;
  lastName: string;
  description: string;
  state: string;
  county: string;
  createdAt: string;
  country: string;
  caseId: string;
  avatarURL?: string;
  lastElementRef?: React.MutableRefObject<HTMLDivElement>;
  userFirebaseId: string;
};

export const LegalCaseFeedCard: FC<LagalCaseFeedCardProps> = memo(
  ({
    avatarURL,
    caseLawType,
    caseNumber,
    description,
    firstName,
    lastName,
    createdAt,
    county,
    state,
    country,
    lastElementRef,
    caseId,
    userFirebaseId,
  }) => {
    const avatarCharacters = getAvatarCharacters(firstName, lastName);
    const createdDate = dateToCustomFormat(createdAt);

    const router = useRouter();

    const onClickOnCardHandler = () => router.navigate(`${ROUTES.myLegalCases}/${caseId}`);

    const {
      isUserOverviewMenuOpen,
      isUserProfileOverviewLoading,
      onCloseUserOverviewHandler,
      onUserAvatarClickHandler,
      userRatings,
      userProfileOverview,
      isOpenCompanyInfoMenu,
      onClickViewCompanyProfileButtonHandler,
      onCloseCompanyInfoMenuHandler,
    } = useUserProfile();

    const onClickAvatarHandler = (event: React.MouseEvent<HTMLDivElement>) => {
      event?.stopPropagation();
      onUserAvatarClickHandler(event);
    };

    const onClosePopoverHandler = (event: React.MouseEvent<HTMLDivElement>) => {
      event?.stopPropagation();
      onCloseUserOverviewHandler();
    };

    return (
      <MDBox
        ref={lastElementRef}
        borderRadius="xl"
        p={1.5}
        pr={1.6}
        bgColor="white"
        sx={{
          fontSize: ({ typography: { size } }) => size.md,
          ':hover': {
            cursor: 'pointer',
          },
          height: 1,
        }}
        onClick={onClickOnCardHandler}
      >
        <Stack spacing={1.5} height={1}>
          <Stack direction="row" spacing={1.5} alignItems="center" position="relative">
            <Stack direction="row" spacing={1.5} alignItems="center" flex={1}>
              <MDAvatar
                src={avatarURL || ''}
                alt="user avatar"
                bgColor="info"
                id={userFirebaseId}
                onClick={firstName && lastName ? onClickAvatarHandler : undefined}
                sx={{
                  '& img': {
                    height: 1,
                    borderRadius: '50%',
                  },
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                {avatarCharacters}
              </MDAvatar>
              <Stack>
                <MDTypography variant="button" fontWeight="medium">
                  {`${firstName} ${lastName}`}
                </MDTypography>
                <MDTypography variant="button">{`${county}, ${state}, ${country}`}</MDTypography>
              </Stack>
            </Stack>

            <MDBadge size="xs" color="secondary" badgeContent={caseLawType} container />
          </Stack>

          <MDTypography
            variant="button"
            sx={{
              fontWeight: 800,
            }}
          >
            {caseNumber}
          </MDTypography>

          <MDTypography
            variant="button"
            sx={{
              fontWeight: 400,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 4,
              MozLineClamp: 4,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flex: 1,
            }}
          >
            {description}
          </MDTypography>

          <MDTypography variant="button">{createdDate}</MDTypography>
        </Stack>

        <MenuPopover open={isUserOverviewMenuOpen} onClose={onClosePopoverHandler} arrow="top-left">
          <UserOverviewContent
            attorneyDescription=""
            lawTypes={null}
            country={userProfileOverview?.userProfile?.country?.name}
            state={userProfileOverview?.userProfile?.state?.name}
            isLoading={isUserProfileOverviewLoading}
            userRatings={userRatings}
            companyInfo={{
              companyFullName: userProfileOverview?.company?.fullName,
              companyLogo: userProfileOverview?.company?.logo?.publicUrl,
            }}
            onClickViewCompanyProfileButtonHandler={onClickViewCompanyProfileButtonHandler}
          />
        </MenuPopover>

        <SideMenu
          customWidth={450}
          isOpen={isOpenCompanyInfoMenu}
          onClose={onCloseCompanyInfoMenuHandler}
          title="Company profile"
        >
          <CompanyInfoSideMenuContent companyId={userProfileOverview?.company?.['@id']} />
        </SideMenu>
      </MDBox>
    );
  }
);
