import { TextField } from '@mui/material';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
} from 'react-hook-form';

type BaseCodeInputFieldsProps<TField extends FieldValues> = {
  control: Control<TField>;
  register: UseFormRegister<TField>;
  setFocus: UseFormSetFocus<TField>;
  setValue: UseFormSetValue<TField>;
  numberOfFields?: number;
  isDisabled?: boolean;
  isMaskable?: boolean;
};

export const getPinCodeValue = (formData: { [key: string]: string }) =>
  Number(
    Object.values(formData).reduce((acc, el) => {
      acc += el;
      return acc;
    }, '')
  );

export const BaseCodeInputFields = <TField extends FieldValues>({
  control,
  register,
  setFocus,
  setValue,
  numberOfFields = 4,
  isDisabled,
  isMaskable = true,
}: BaseCodeInputFieldsProps<TField>): JSX.Element => {
  const inputs = [...Array(numberOfFields)].map((_, ind) => `code${ind + 1}`) as Path<TField>[];

  const handleChangeWithNextField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { maxLength, value, name } = event.target;

    const fieldIntIndex = Number(name.replace('code', ''));

    const nextFieldIndex = !value ? fieldIntIndex - 1 : fieldIntIndex + 1;

    const nextfield = inputs.find(el => el === `code${nextFieldIndex}`);

    if (value.length > maxLength) event.target.value = value[0];

    if ((value.length >= maxLength || !value) && fieldIntIndex <= inputs.length && nextfield) {
      setFocus(`code${nextFieldIndex}` as Path<TField>);
    }
  };

  const handlePaste = (event: any) => {
    const data = event.clipboardData.getData('text');
    const values = data.split('');

    inputs.map((input, index) => setValue(input, values[index], { shouldValidate: true }));
    event.preventDefault();
  };

  const onFocusHandler = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) =>
    event.currentTarget.select();

  return (
    <>
      {inputs.map((name, index) => (
        <Controller
          key={name}
          name={name}
          control={control}
          render={({ field, fieldState: { error }, ...other }) => {
            const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Backspace' && field.value === '') {
                event.preventDefault();
                const currentIndex = inputs.indexOf(name);
                const prevIndex = currentIndex - 1;

                if (prevIndex >= 0) {
                  const prevFieldName = inputs[prevIndex];
                  setFocus(prevFieldName);
                }
              }
            };

            const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeWithNextField(event);
              field.onChange(event);
            };

            return (
              <TextField
                {...field}
                value={isMaskable && field.value ? '*' : field.value}
                ref={register(name).ref}
                error={!!error}
                placeholder="-"
                onChange={onChangeHandler}
                onKeyDown={onKeyDown}
                onPaste={handlePaste}
                autoFocus={index === 0}
                onFocus={onFocusHandler}
                disabled={isDisabled}
                InputProps={{
                  sx: {
                    width: { xs: 36, sm: 56 },
                    height: { xs: 36, sm: 56 },
                    '& input': { p: 0, textAlign: 'center' },
                  },
                }}
                inputProps={{
                  maxLength: 1,
                  type: field.value ? 'text' : 'number',
                }}
                {...other}
              />
            );
          }}
        />
      ))}
    </>
  );
};
