import { Theme } from '@mui/material';

export const ChatInputStyles = {
  IconWrapperSX: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 1,
    borderRadius: '8px',
    width: 44,
    height: 44,
    border: (theme: Theme) => `1px solid ${theme.borders.borderColor}`,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: (theme: Theme) => theme.palette.grey[100],
    },
  },
};
