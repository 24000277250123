import { FC } from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import { ToastType, notice, useUser } from 'features/common';
import { createBackendAuthService } from 'features/auth';
import { ROUTES } from 'features/common/variables/routes';
import { ConnectClio, SettingsFields } from '../components';

export const ClioSettingsTemplate: FC = () => {
  const { isClioActive, connectBackendUserHandler } = useUser();

  const { disconnectClioFromProfile } = createBackendAuthService();

  const onClickConnectClioHandler = async () => {
    if (isClioActive) {
      try {
        await disconnectClioFromProfile();
        await connectBackendUserHandler();
        notice(ToastType.SUCCESS, 'Successfully disconnected Clio from your account');
      } catch (error) {
        console.error({ error });
        notice(ToastType.ERROR, 'Something went wrong with the Clio disconnection');
      }
      return;
    }
    window.location.href = `https://app.clio.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_BASE_APP_URL}/${ROUTES.clioCallback}`;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ minHeight: 400, height: 1 }}>
          <CardContent sx={{ p: 3, height: 1 }}>
            {isClioActive ? <SettingsFields /> : <ConnectClio onClickConnectClioHandler={onClickConnectClioHandler} />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
