import { FC, memo, useCallback, useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, CircularProgress, Stack } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { BaseStoreActionFunction } from 'features/store';
import { SideMenu, useBoolean } from 'features/common';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { GetCaseDetailsNotes } from 'features/case-details/CaseDetailsApiService';
import { CreateNotesPayload, UpdateNotePayload } from 'features/my-legal-cases/MyLegalCasesApiService';
import { Pagination } from 'features/case-law-types';
import { NoteForm } from './NoteForm';
import { NotesCard } from './NotesCard';
import { AllNotesMenuContent } from './AllNotesMenuContent';
import { CardAddButton } from '../CardAddButton';

export const NOTES_PER_PAGE = 30;

type MyNotesTestProps = {
  fetchLegalCaseNotesHandler: (caseId: string, params: Pagination) => Promise<void>;
  updateLegalCaseNoteHandler: (payload: Partial<UpdateNotePayload>) => Promise<void>;
  deleteLegalCaseNoteHandler: () => Promise<void>;
  fetchMoreMyLegalCaseNotesHandler: (params: Pagination) => Promise<void>;
  getSelectedNote: BaseStoreActionFunction;
  createLegaCaseNoteHandler: (payload: Omit<CreateNotesPayload, 'legalCase'>) => Promise<void>;
  selectedNote: GetCaseDetailsNotes;
  caseNotes: GetCaseDetailsNotes[];
  totalItems: number;
  isClosedCase: boolean;
  isCaseOnConsultation: boolean;
  isAttorney: boolean;
  caseId: string;
  aesEncrypt: (payload: string) => string;
  aesDecrypt: (payload: string) => string;
};

export const MyNotes: FC<MyNotesTestProps> = memo(
  ({
    caseId,
    isAttorney,
    caseNotes,
    isCaseOnConsultation,
    isClosedCase,
    selectedNote,
    totalItems,
    createLegaCaseNoteHandler,
    fetchMoreMyLegalCaseNotesHandler,
    getSelectedNote,
    deleteLegalCaseNoteHandler,
    fetchLegalCaseNotesHandler,
    updateLegalCaseNoteHandler,
    aesDecrypt,
    aesEncrypt,
  }) => {
    const [isCreateNoteMode, setIsCreateNoteMode] = useState(false);
    const [isNotesLoading, setIsNotesLoading] = useState(false);
    const [isAllNotesLoading, setIsAllNotesLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const pages: Readonly<number> = Math.ceil(totalItems / NOTES_PER_PAGE);

    const [isNoteFormMenuOpen, openNoteFormMenu, closeNoteFormMenu] = useBoolean(false);
    const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useBoolean(false);
    const [isViewAllModalOpen, openViewAllModal, closeViewAllModal] = useBoolean(false);

    const onFetchMyNotesHandler = async () => {
      setIsNotesLoading(true);
      await fetchLegalCaseNotesHandler(caseId, { page: 1, itemsPerPage: 3 });
      setIsNotesLoading(false);
    };

    useEffect(() => {
      onFetchMyNotesHandler();
    }, [caseId]);

    const onEditIconClickHandler = useCallback(
      (noteId: string) => {
        isCreateNoteMode && setIsCreateNoteMode(false);
        getSelectedNote(noteId);
        openNoteFormMenu();
      },
      [isCreateNoteMode]
    );

    const createNoteIconClickHandler = useCallback(() => {
      setIsCreateNoteMode(true);
      openNoteFormMenu();
    }, []);

    const onDeleteNoteIconClickHandler = useCallback((noteId: string) => {
      getSelectedNote(noteId);
      openDeleteModal();
    }, []);

    const onDeleteNoteHandler = async () => {
      if (isViewAllModalOpen && caseNotes.length > 3) {
        const caseNote = caseNotes?.slice(0, 3).find(note => note['@id'] === selectedNote['@id']);

        caseNote && setIsNotesLoading(true);
        setIsAllNotesLoading(true);
      } else {
        setIsNotesLoading(true);
      }
      closeDeleteModal();
      await deleteLegalCaseNoteHandler();

      setIsNotesLoading(prevValue => (prevValue ? !prevValue : prevValue));
      setIsAllNotesLoading(prevValue => (prevValue ? !prevValue : prevValue));
    };

    const onViewAllButtonClickHandler = useCallback(async () => {
      openViewAllModal();
      setIsAllNotesLoading(true);
      await fetchLegalCaseNotesHandler(caseId, { page: 1, itemsPerPage: NOTES_PER_PAGE });
      setIsAllNotesLoading(false);
    }, [caseId]);

    const onCloseViewAllModalWindowHandler = useCallback(() => {
      closeViewAllModal();
      setCurrentPage(1);
    }, []);

    const onCreateNoteIconClickHandler =
      isAttorney && isCaseOnConsultation ? null : isClosedCase ? null : createNoteIconClickHandler;

    return (
      <>
        <Card sx={{ width: 1, height: 1, minHeight: 200 }}>
          <CardHeader
            sx={{ px: 2 }}
            title="My Notes"
            action={<CardAddButton onClickButtonHandler={onCreateNoteIconClickHandler} />}
          />
          {isNotesLoading ? (
            <Stack alignItems="center" flex={1} justifyContent="center" minHeight={150}>
              <CircularProgress />
            </Stack>
          ) : !caseNotes?.length ? (
            <Stack flex={1} justifyContent="center" alignItems="center">
              <MDTypography>No notes were found...</MDTypography>
            </Stack>
          ) : (
            <CardContent sx={{ px: 2, pb: '10px !important' }}>
              <Stack
                spacing={2}
                sx={{
                  pb: 1,
                  pr: 0.5,
                  maxHeight: 400,
                  overflowY: 'auto',
                }}
              >
                {caseNotes?.slice(0, 3).map(note => {
                  const decryptedDescription = aesDecrypt(note?.description) || '';
                  return (
                    <NotesCard
                      key={note?.['@id']}
                      visible={note?.visible}
                      onEditIconClickHandler={onEditIconClickHandler}
                      onDeleteIconClickHandler={onDeleteNoteIconClickHandler}
                      title={note?.title}
                      id={note?.['@id']}
                      createdAt={note?.createdAt}
                      description={decryptedDescription}
                      isAttorneyView={isAttorney}
                      isDisabledActionButton={isClosedCase}
                    />
                  );
                })}
              </Stack>
              {totalItems > 3 && (
                <Stack alignItems="center" pt={1}>
                  <MDButton
                    variant="outlined"
                    color="info"
                    onClick={onViewAllButtonClickHandler}
                    sx={{
                      width: { xs: 1, sm: 120 },
                      textTransform: 'none',
                    }}
                  >
                    View all
                  </MDButton>
                </Stack>
              )}
            </CardContent>
          )}

          <SideMenu
            isOpen={isNoteFormMenuOpen}
            onClose={closeNoteFormMenu}
            title={isCreateNoteMode ? 'Create a case note' : 'Edit case note'}
            customWidth={450}
          >
            <NoteForm
              noteTitle={selectedNote?.title}
              noteBody={selectedNote?.description}
              isVisible={selectedNote?.visible}
              updateLegalCaseNoteHandler={updateLegalCaseNoteHandler}
              closeEditMenu={closeNoteFormMenu}
              createLegaCaseNoteHandler={createLegaCaseNoteHandler}
              isCreateNoteMode={isCreateNoteMode}
              isAttorney={isAttorney}
              aesEncrypt={aesEncrypt}
              aecDecrypt={aesDecrypt}
            />
          </SideMenu>
          <DialogWindow
            onCloseDialogHandler={closeDeleteModal}
            onApproveDialogHandler={onDeleteNoteHandler}
            isDialogOpen={isDeleteModalOpen}
            dialogTitle="Are you sure want to delete the note?"
          />
        </Card>
        <SideMenu
          isOpen={isViewAllModalOpen}
          onClose={onCloseViewAllModalWindowHandler}
          title="All my notes"
          customWidth={450}
        >
          <AllNotesMenuContent
            caseNotes={caseNotes}
            isFetchMore={currentPage < pages}
            isLoading={isAllNotesLoading}
            onEditIconClickHandler={onEditIconClickHandler}
            onDeleteIconClickHandler={onDeleteNoteIconClickHandler}
            fetchMoreLegalCaseNotesHandler={fetchMoreMyLegalCaseNotesHandler}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isAttorneyView={isAttorney}
            aesDecrypt={aesDecrypt}
          />
        </SideMenu>
      </>
    );
  }
);
