// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import { WorkOutlineOutlined } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';

import { FC, memo } from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { ROUTES } from 'features/common/variables/routes';
import { Link } from 'react-router-dom';

export const Sidenav: FC = memo(() => {
  const sidenavItems = [
    { icon: 'work', label: 'attorney list', href: ROUTES.attorneyManagement },
    {
      icon: 'person',
      label: 'clients list',
      href: ROUTES.clientManagement,
    },
    {
      icon: 'supervisor_account',
      label: 'admins list',
      href: ROUTES.adminManagement,
    },
  ];

  const renderSidenavItems = sidenavItems.map(({ label, href, icon }, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <MDTypography
          component={Link}
          to={href}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({ borders: { borderRadius }, functions: { pxToRem }, palette: { light }, transitions }: Theme) => ({
            display: 'flex',
            alignItems: 'center',
            backgroundColor: location.pathname === href ? light.main : light,
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create('background-color', {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            '&:hover': {
              backgroundColor: light.main,
            },
          })}
        >
          <MDBox mr={1.5} lineHeight={1}>
            <Icon fontSize="small">{icon}</Icon>
          </MDBox>
          {label}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: 'sticky',
        top: '1%',
      }}
    >
      <MDBox component="ul" display="flex" flexDirection="column" p={2} m={0} sx={{ listStyle: 'none' }}>
        {renderSidenavItems}
      </MDBox>
    </Card>
  );
});
