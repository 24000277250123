import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getMessaging, getToken, isSupported, onMessage, Messaging } from 'firebase/messaging';
import * as process from 'process';
import { FSW_SCOPE, getSWRegistration, isSWExist, isSupportedNotificationApi } from './helpers';

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(firebaseApp);

const getMessagingHandler = (): Messaging | null => {
  try {
    const messaging = getMessaging(firebaseApp);
    return messaging;
  } catch (err) {
    console.error('Failed to initialize Firebase Messaging.', err);
    return null;
  }
};

export const messaging = getMessagingHandler();

// export const messaging: any = null;

const UrlFirebaseConfig = new URLSearchParams(firebaseConfig.toString());

const swUrl = `${process.env.REACT_APP_BASE_APP_URL}/firebase-messaging-sw.js?${UrlFirebaseConfig}`;

// getOrRegisterServiceWorker function is used to try and get the service worker if it exists, otherwise it will register a new one.
export const getOrRegisterServiceWorker = async () => {
  if (isSWExist) {
    const serviceWorker = await getSWRegistration();
    if (serviceWorker) return serviceWorker;
    let scriptURL = 'firebase-messaging-sw.js';
    scriptURL += 'zalupa';
    return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
      scope: FSW_SCOPE,
    });
  }
  throw new Error('The browser doesn`t support service worker.');
};

// getFirebaseToken function generates the FCM token
export const getFirebaseToken = async () => {
  try {
    const isSupportedBrowser = await isSupported();

    if (isSupportedBrowser && !!messaging) {
      const serviceWorkerRegistration = await getOrRegisterServiceWorker();

      if (serviceWorkerRegistration) {
        return await getToken(messaging, {
          vapidKey: process.env?.REACT_APP_FB_VAPID_KEY || '',
          serviceWorkerRegistration,
        });
      }
    } else {
      console.info('Your browser does not support push notification');
    }
  } catch (error) {
    console.error('An error occurred while retrieving token. ', error);
  }
};

export const requestPermission = async () => {
  const permission = isSupportedNotificationApi() ? await Notification.requestPermission() : '';

  if (permission === 'granted') {
    try {
      const currentToken = await getFirebaseToken();
      if (currentToken) {
        return currentToken;
      } else {
        return null;
      }
    } catch (err) {
      console.error('An error occurred when requesting to receive the token.', err);
    }
  }
  if (permission === 'denied') console.info('User Permission Denied.');
};

export const onMessageListener = () => {
  if (messaging) return onMessage;
  return;
};
