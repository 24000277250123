import { ObjectWithValues } from '../index';
import { AxiosInstance, RawAxiosRequestHeaders, ResponseType } from 'axios';

export type RequestHeaders = ObjectWithValues<string>;
export type ApiInstance = (props: { headers?: RawAxiosRequestHeaders; responseType?: ResponseType }) => AxiosInstance;

export const MercureLinkMap: Record<string, string> = {
  'http://localhost:3000': '<http://localhost:8090/.well-known/mercure>; rel="mercure"',
  'https://app.stage.legalbeagle.net': '<https://socket.stage.legalbeagle.net/.well-known/mercure>; rel="mercure"',
  'https://app.legalbeagle.net': '<https://socket.legalbeagle.net/.well-known/mercure>; rel="mercure"',
};
