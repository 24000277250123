import { FC } from 'react';
import MDAvatar, { MDAvatarProps } from 'components/MDAvatar';
import { PersonOutline } from '@mui/icons-material';
import { getAvatarCharacters } from 'features/common/helpers/utilities';

type BaseAvatarProps = MDAvatarProps & {
  userFirstName: string;
  userLastName: string;
};

export const BaseAvatar: FC<BaseAvatarProps> = ({ userFirstName, userLastName, size, src, ...rest }) => {
  return (
    <MDAvatar
      src={src || ''}
      size={size}
      alt="user avatar"
      sx={{
        backgroundColor: theme => theme.palette.info.main,
        '& img': {
          height: 1,
        },
      }}
      {...rest}
    >
      {!userFirstName && !userLastName ? (
        <PersonOutline fontSize="medium" />
      ) : (
        getAvatarCharacters(userFirstName, userLastName)
      )}
    </MDAvatar>
  );
};
