import { GetMyLegalCaseDetailsResponse, User } from 'features/case-details/CaseDetailsApiService';
import { PracticeAreaCategory } from 'features/clio-settings/ClioSettingsApiService';
import {
  BaseData,
  CaseLawType,
  Country,
  CountryState,
  County,
  ListOfItems,
  Logo,
  UserProfileResponse,
  backendApiClient,
} from 'features/common';
import { CaseFile, LegalCaseStatus } from 'features/my-legal-cases/MyLegalCasesApiService';

const BASE_CRM_INTEGRATION_API_URL: Readonly<string> = '/crm_integrations';

export type CRMProvider = 'clio';

export type ClioExportMapping = {
  practiceAreas: PracticeAreaCategory[];
  practiceArea: PracticeAreaCategory;
};

export type ExportCaseToClioPayload = {
  legalCase: string;
  practiceArea: string;
};

export type CRMCasesSearch = BaseData<''> & {
  id: string;
  provider: CRMProvider;
  caseNumber: string;
  alreadySynced: boolean;
  displayName: string;
};

export type InternalUser = Omit<User, 'userProfile'> & {
  userProfile: Pick<UserProfileResponse, '@id' | '@type' | 'firstName' | 'lastName'> & {
    country: Country;
    state: CountryState;
  };
  rating: number;
  legalCaseCount: number;
  logo: Logo;
  email: string;
};

export type ClioClient = BaseData<'UserDto'> & {
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  type: 'Person';
  mappedStates: CountryState[];
  state: CountryState | null;
  country: Country | null;
};

export type Contacts = BaseData<'UserDto'> & {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  name: string;
  type: 'Person';
  relationDescription: string | null;
  mappedStates: CountryState[];
  state: null | CountryState;
};

export type CRMFile = Pick<CaseFile, '@id' | 'id' | 'fileExtension' | 'title'> & {
  '@type': 'FileDto';
  fileName: string;
  path: string;
  size: number;
  description: string;
};

export type MatchedCaseLawType = BaseData<'CaseLawType'> & {
  title: string;
};

export type CRMCase = BaseData<''> & {
  id: string;
  provider: CRMProvider;
  caseNumber: string;
  alreadySynced?: boolean;
  internalUser: InternalUser | null;
  client: ClioClient;
  description: string;
  caseLawType: CaseLawType;
  matchedCaseLawTypes: MatchedCaseLawType[];
  country: Country | null;
  county: County | null;
  state: CountryState | null;
  mappedCounty: County[];
  mappedState: CountryState[];
  caseForDependant: boolean;
  dependantFirstName: string;
  dependantLastName: string;
  dependantStateOfResidence: string;
  dependantUnder18: boolean;
  originalLawType: PracticeAreaCategory;
  status: LegalCaseStatus;
};

export type CRMNote = BaseData<'NoteDto'> & {
  createdAt: string;
  description: string;
  id: string;
  title?: string;
  contacts: Contacts[];
  files: CRMFile[];
};

export const crmIntegrationApiService = () => {
  const getClioExportMapping = async (caseId: string) =>
    await backendApiClient({}).get<ClioExportMapping>(
      `${BASE_CRM_INTEGRATION_API_URL}/clio/cases/${caseId}/export/mapping`
    );

  const exportCaseToClio = async (payload: ExportCaseToClioPayload) =>
    await backendApiClient({}).post<GetMyLegalCaseDetailsResponse>(
      `${BASE_CRM_INTEGRATION_API_URL}/clio/cases/export`,
      payload
    );

  const getSearchCRMCases = async (search: string) =>
    await backendApiClient({}).get<ListOfItems<CRMCasesSearch[]>>(`${BASE_CRM_INTEGRATION_API_URL}/cases/${search}`);

  const getCRMCase = async (crmCaseId: string, provider: 'clio') =>
    await backendApiClient({}).get<CRMCase>(`${BASE_CRM_INTEGRATION_API_URL}/${provider}/cases/${crmCaseId}`);

  const getCRMCaseContacts = async (crmCaseId: string, provider: 'clio') =>
    await backendApiClient({}).get<ListOfItems<Contacts[]>>(
      `${BASE_CRM_INTEGRATION_API_URL}/${provider}/cases/${crmCaseId}/contacts`
    );

  const getCRMCaseFiles = async (crmCaseId: string, provider: 'clio') =>
    await backendApiClient({}).get<ListOfItems<CRMFile[]>>(
      `${BASE_CRM_INTEGRATION_API_URL}/${provider}/cases/${crmCaseId}/files`
    );

  const getCRMCaseNotes = async (crmCaseId: string, provider: 'clio') =>
    await backendApiClient({}).get<ListOfItems<CRMNote[]>>(
      `${BASE_CRM_INTEGRATION_API_URL}/${provider}/cases/${crmCaseId}/notes`
    );

  return {
    exportCaseToClio,
    getClioExportMapping,
    getSearchCRMCases,
    getCRMCase,
    getCRMCaseContacts,
    getCRMCaseFiles,
    getCRMCaseNotes,
  };
};
