import * as yup from 'yup';

export type FirebaseSignInFormSchema = Partial<{
  email: string;
  password: string;
}>;

export type FirebaseSignUpFormSchema = Partial<{
  email: string;
  password: string;
  passwordConfirmation: string;
}>;

export type ResetPasswordFormSchema = Partial<{
  email: string;
}>;

export type SignUpPhoneFormSchema = Partial<{
  phone: string;
}>;

export type CreateBaseUserProfileSchema = Partial<{
  personTitle: string;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  state: string;
  town: string;
  phone: string;
}>;

export const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

export const emailRegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#.?!@$%^&*-]).{8,}$/;

export const PASSWORD_VALIDATION_HELPER_TEXT =
  'Password must contain at least 8 characters long and include a number, an uppercase and lowercase letter, and a special character.';

export const firebaseSignInFormValidationSchema: yup.ObjectSchema<FirebaseSignInFormSchema> = yup
  .object({
    email: yup
      .string()
      .required('Email field is required')
      .matches(emailRegExp, 'Please enter a valid email')
      .label('Email'),
    password: yup.string().required('Password field is required'),
  })
  .required();

export const firebaseSignUpFormValidationSchema: yup.ObjectSchema<FirebaseSignUpFormSchema> = yup
  .object({
    email: yup.string().required('Email field is required').matches(emailRegExp, 'Please enter a valid email'),
    password: yup
      .string()
      .required('Password field is required')
      .matches(passwordRegExp, PASSWORD_VALIDATION_HELPER_TEXT),
    passwordConfirmation: yup
      .string()
      .required('Password confirmation field is required')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })
  .required();

export const createBaseUserProfileSchema: yup.ObjectSchema<CreateBaseUserProfileSchema> = yup
  .object({
    personTitle: yup.string().label('Person title'),
    firstName: yup.string().required().label('First Name'),
    lastName: yup.string().required().label('Last Name'),
    email: yup.string().required().matches(emailRegExp, 'Please enter a valid email').label('Email'),
    country: yup.string().required().label('Country'),
    state: yup.string().required().label('State'),
    town: yup.string().required().label('Town/City'),
    phone: yup.string().required().label('Phone Number'),
  })
  .required();

export const resetPasswordFormSchema: yup.ObjectSchema<ResetPasswordFormSchema> = yup
  .object({
    email: yup.string().required('Email field is required').matches(emailRegExp, 'Please enter a valid email'),
  })
  .required();

export const signUpPhoneFormValidationSchema: yup.ObjectSchema<SignUpPhoneFormSchema> = yup.object({
  phone: yup.string().required('Phone field is required').label('Phone Number'),
});
