import { FC } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import { AssignmentTurnedInOutlined } from '@mui/icons-material';
import { DocumentItemContent } from 'features/common/components/BaseDocumentItem';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import { useFileSign } from 'features/common';
import { FileMessageProps } from './types';
import { SignDocumentWrapper } from './Message.styles';
import { MessageTimestamp } from './MessageTimestamp';

export const FileMessage: FC<FileMessageProps> = ({
  currentUserFirebaseId,
  isMine,
  fileSize,
  fileType,
  ownerFirstName,
  ownerLastName,
  userSignerFirebaseId,
  title,
  firstMessageRef,
  fileId,
  isSigned,
  isAttorney,
  createdAt,
}) => {
  const { isSendingSignReq, onSignDocumentHandler, onDeclineSignEnvelopeHandler } = useFileSign({
    fileSignData: null,
  });

  const onClickCancelRequestForSignButtonHandler = async () => {
    await onDeclineSignEnvelopeHandler(fileId);
  };

  const onClickSignDocumentHandler = async () => {
    await onSignDocumentHandler({ fileId, isOpenSignTabWindow: false });
  };

  const isCancelDocSign = !isSigned && userSignerFirebaseId && currentUserFirebaseId !== userSignerFirebaseId;

  const isShouldDocumentSign = !isSigned && userSignerFirebaseId && currentUserFirebaseId === userSignerFirebaseId;

  return (
    <Stack
      ref={firstMessageRef}
      sx={{
        borderRadius: '8px',
        alignSelf: isMine ? 'flex-end' : 'flex-start',
        display: 'flex',
        width: 'auto',
        maxWidth: '50%',
        mb: '4px',
      }}
    >
      <MDBox
        sx={{
          alignSelf: isMine ? 'flex-end' : 'flex-start',
          display: 'flex',
          alignItems: 'center',
          width: 1,
          p: 1,
          borderRadius: '8px',
          ...((isCancelDocSign || isShouldDocumentSign || isSigned) && {
            borderRadius: '8px 8px 0 0',
          }),
          ...(isMine
            ? {
                border: theme => `1px solid ${theme.palette.info.main}`,
              }
            : {
                backgroundColor: theme => theme.palette.grey[100],
              }),
        }}
      >
        <Stack spacing={0.5}>
          <DocumentItemContent
            fileType={fileType}
            fileSize={fileSize}
            title={title}
            ownerFirstName={ownerFirstName}
            ownerLastName={ownerLastName}
          />
          <MessageTimestamp createdAt={createdAt} isMine={isMine} />
        </Stack>
      </MDBox>

      {/* cancel request logic */}
      {isCancelDocSign && (
        <SignDocumentWrapper
          isCancelDocSign={isCancelDocSign}
          isMine={isMine}
          onClick={onClickCancelRequestForSignButtonHandler}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {isSendingSignReq ? (
              <CircularProgress size={20} />
            ) : (
              <AssignmentTurnedInOutlined fontSize="medium" sx={{ color: 'inherit !important' }} />
            )}
            <Stack>
              <MDTypography variant="button" fontSize={16}>
                Request to sign
              </MDTypography>
              <MDTypography color="error" variant="button" fontSize={16}>
                Cancel
              </MDTypography>
            </Stack>
          </Stack>
        </SignDocumentWrapper>
      )}

      {isShouldDocumentSign && (
        <SignDocumentWrapper isMine={isMine} onClick={onClickSignDocumentHandler}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <AssignmentTurnedInOutlined fontSize="medium" sx={{ color: 'inherit !important' }} />
            <Stack>
              <MDTypography variant="button">{isAttorney ? 'Client ' : 'Attorney '} needs your sign. </MDTypography>
            </Stack>
          </Stack>
        </SignDocumentWrapper>
      )}

      {isSigned && (
        <SignDocumentWrapper isMine={isMine} isSigned={isSigned}>
          <MDTypography variant="button">Document is signed</MDTypography>
        </SignDocumentWrapper>
      )}
    </Stack>
  );
};
