import { Divider, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { DocumentItemProps } from 'features/case-details/types';
import MDButton from 'components/MDButton';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';
import { ToastType, notice, useBoolean, useCookiesFileSignData } from 'features/common';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { DocumentMainInfo } from './DocumentMainInfo';
import { ROUTES } from 'features/common/variables/routes';
import { FileSignProvider, fileSignApiService } from 'features/common/services/fileSignApiService';
import { DocumentIcon } from './DocumentIcon';

export const DocumentsItemToSign: FC<
  Pick<DocumentItemProps, 'id' | 'title' | 'ownerFirstName' | 'ownerLastName' | 'fileSize' | 'fileType'> & {
    caseId: string;
    isRequestToSign?: boolean;
    userSignerFirebaseId?: string;
    closeSignDocumentsModal?: () => void;
    fileSignProvider?: FileSignProvider;
  }
> = ({
  caseId,
  fileSize,
  fileType,
  id,
  ownerFirstName,
  ownerLastName,
  title,
  isRequestToSign,
  userSignerFirebaseId,
  closeSignDocumentsModal,
  fileSignProvider,
}) => {
  const [isSendingSignReq, setIsSendingSignReq] = useState(false);

  const { signEnvelope, sendEnvelopeFileToSign } = fileSignApiService();

  const { setSignDataToCookiesHandler } = useCookiesFileSignData();

  const onClickSignDocumentHandler = async () => {
    try {
      closeDialogWindow();
      setIsSendingSignReq(true);

      const { data } = await signEnvelope({
        file: `/files/${id}`,
      });

      setSignDataToCookiesHandler({ fileId: id, path: ROUTES.signProcessCompleteRedirect });

      if (data.signUrl) window.open(data.signUrl, '_blank');
      closeSignDocumentsModal?.();
      setIsSendingSignReq(false);
    } catch (error: any) {
      setIsSendingSignReq(false);
      console.error(error);
      if (error?.response?.status === 422) {
        notice(ToastType.ERROR, error?.response?.data?.['hydra:description']);
      } else {
        paymentRequiredErrorHandler(error);
      }
    }
  };

  const onClickSendRequestToSignDocumentHandler = async () => {
    try {
      closeDialogWindow();
      setIsSendingSignReq(true);

      await sendEnvelopeFileToSign(fileSignProvider, {
        file: `/files/${id}`,
        object: `/legal_cases/${caseId}`,
        participant: `/users/${userSignerFirebaseId}`,
      });

      setIsSendingSignReq(false);
      closeSignDocumentsModal?.();

      notice(ToastType.SUCCESS, 'Successfully sent request to sign the document!');
    } catch (error: any) {
      setIsSendingSignReq(false);
      console.error(error);
      if (error?.response?.status === 422) {
        notice(ToastType.ERROR, error?.response?.data?.['hydra:description']);
      } else {
        paymentRequiredErrorHandler(error);
      }
    }
  };

  const [isDialogOpen, openDialogWindow, closeDialogWindow] = useBoolean(false);

  return (
    <Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <DocumentIcon fileType={fileType} />
        <DocumentMainInfo
          title={title}
          ownerFirstName={ownerFirstName}
          ownerLastName={ownerLastName}
          fileSize={fileSize}
        />
        <MDButton
          isLoading={isSendingSignReq}
          disabled={isSendingSignReq}
          onClick={openDialogWindow}
          variant="gradient"
          color="info"
        >
          {isRequestToSign ? 'Request to sign' : 'Sign'}
        </MDButton>
      </Stack>
      <Divider sx={{ my: '8px !important' }} />

      <DialogWindow
        onCloseDialogHandler={closeDialogWindow}
        onApproveDialogHandler={isRequestToSign ? onClickSendRequestToSignDocumentHandler : onClickSignDocumentHandler}
        isDialogOpen={isDialogOpen}
        dialogTitle="Are you sure?"
        paperPropsSX={{ minWidth: 420 }}
      />
    </Stack>
  );
};
