import { FC, useState } from 'react';
import { Card, CardContent, Stack } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import { getAvatarCharacters } from 'features/common/helpers/utilities';
import MDButton from 'components/MDButton';
import { useBoolean } from 'features/common';
import { DialogWindow } from 'features/common/components/DialogWindow';

export type ConsultationOfferCardProps = {
  logoURL?: string;
  lastElementRef?: React.MutableRefObject<HTMLDivElement> | null;
  firstName: string;
  lastName: string;
  isAllOffers?: boolean;
  offerId: string;
  createdAt: string;
  consultationOwnerFirebaseId: string;
  approveLegalCaseOfferHandler: (caseId: string) => Promise<void>;
  declineLegalCaseOfferHandler: (caseId: string) => Promise<void>;
  onUserAvatarClickHandler: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => Promise<void>;
};

export const ConsultationOfferCard: FC<ConsultationOfferCardProps> = ({
  firstName,
  lastName,
  logoURL,
  offerId,
  lastElementRef,
  isAllOffers,
  createdAt,
  consultationOwnerFirebaseId,
  approveLegalCaseOfferHandler,
  declineLegalCaseOfferHandler,
  onUserAvatarClickHandler,
}) => {
  const distanceToNow = formatDistanceToNow(new Date(createdAt), { addSuffix: true });
  const avatarCharacters = getAvatarCharacters(firstName, lastName);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejection, setIsRejection] = useState(false);

  const [isApproveDialogOpen, openApproveDialog, closeApproveDialog] = useBoolean(false);
  const [isRejectDialogOpen, openRejectDialog, closeRejectDialog] = useBoolean(false);

  const onAcceptCaseOfferHandler = async () => {
    setIsApproving(true);
    closeApproveDialog();
    await approveLegalCaseOfferHandler(offerId);
    setIsApproving(false);
  };

  const onDeclineCaseOfferHandler = async () => {
    setIsRejection(true);
    closeRejectDialog();
    await declineLegalCaseOfferHandler(offerId);
    setIsRejection(false);
  };

  return (
    <Card
      sx={{
        height: 1,
        boxShadow: theme => theme.boxShadows.md,
        ...(isAllOffers && {
          border: theme => `1px solid ${theme.borders.borderColor}`,
        }),
      }}
      ref={lastElementRef}
    >
      <CardContent sx={{ p: 2, pb: '16px !important', height: 1 }}>
        <Stack spacing={2} height={1}>
          <Stack spacing={0.5}>
            <Stack direction="row" spacing={1.5}>
              <MDAvatar
                src={logoURL || ''}
                size="lg"
                alt="user avatar"
                bgColor="info"
                id={consultationOwnerFirebaseId}
                sx={{
                  '& img': {
                    height: 1,
                  },
                  fontSize: 16,
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={onUserAvatarClickHandler}
              >
                {avatarCharacters}
              </MDAvatar>
              <Stack>
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  sx={{ whiteSpace: 'initial', wordBreak: 'break-all' }}
                >
                  {`${firstName} ${lastName}`}
                </MDTypography>

                <MDTypography
                  variant="button"
                  sx={{
                    fontWeight: 400,
                    textAlign: 'center',
                    flex: 1,
                  }}
                >
                  Accept an offer for counseling?
                </MDTypography>
              </Stack>
            </Stack>
          </Stack>

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ justifyContent: 'center', width: 1 }}
            spacing={{ xs: 1.5, sm: 3, md: 1.5 }}
          >
            <MDButton
              variant="outlined"
              color="info"
              isLoading={isApproving}
              onClick={openApproveDialog}
              sx={{
                width: 1,
                minHeight: 35,
                textTransform: 'none',
              }}
            >
              Accept
            </MDButton>
            <MDButton
              variant="outlined"
              color="dark"
              isLoading={isRejection}
              onClick={openRejectDialog}
              sx={{
                width: 1,
                minHeight: 35,
                textTransform: 'none',
              }}
            >
              Decline
            </MDButton>
          </Stack>
          <MDTypography variant="button">{distanceToNow}</MDTypography>
        </Stack>
      </CardContent>

      <DialogWindow
        onCloseDialogHandler={closeApproveDialog}
        onApproveDialogHandler={onAcceptCaseOfferHandler}
        isDialogOpen={isApproveDialogOpen}
        dialogTitle="Are you sure you want to accept offer for counseling?"
      />

      <DialogWindow
        onCloseDialogHandler={closeRejectDialog}
        onApproveDialogHandler={onDeclineCaseOfferHandler}
        isDialogOpen={isRejectDialogOpen}
        dialogTitle="Are you sure you want to decline offer for counseling?"
      />
    </Card>
  );
};
