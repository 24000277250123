import { FC, useEffect } from 'react';
import { Stack } from '@mui/material';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { setLayout, useMaterialUIController } from 'context';

export const SignSuccessProcessCallback: FC = () => {
  const closeTabHandler = () => {
    window.opener = null;
    window.open('', '_self');
    window.close();
  };

  const [_, dispatch] = useMaterialUIController();
  useEffect(() => {
    setLayout(dispatch, 'page');
  }, []);

  return (
    <Stack sx={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <MDTypography variant="h2" mb={2} textAlign="center">
        The document has been successfully signed!
      </MDTypography>
      <MDButton variant="gradient" color="info" onClick={closeTabHandler}>
        Close window
      </MDButton>
    </Stack>
  );
};
