import React, { useState } from 'react';
import { ToastType, notice, useBoolean, useRouter, useUser } from 'features/common';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';
import { legalCaseConsultationApiService } from 'features/common/services/legalCaseConsultationApiService';
import { ROUTES } from 'features/common/variables/routes';
import { dateToCustomFormat } from 'features/common/helpers/date';
import { useAudioVideoChat } from 'features/store';
import { AgoraEventType } from 'features/common/services/agoraEventApiService';
import { CaseInfoHeaderProps } from '../components/CaseInfo/CaseInfoHeader/types';
import { useSubscriptionModalsContextState } from 'features/contexts/SubscriptionModalsContextProvider';

type UseCaseInfoHeaderProps = Pick<
  CaseInfoHeaderProps,
  | 'approveRepresentationsHandler'
  | 'declineRepresentationsHandler'
  | 'updateLegalCaseHandler'
  | 'caseNumber'
  | 'caseId'
  | 'consultationData'
  | 'isClosedCase'
  | 'representationId'
> & {
  userFirstName: string;
  userLastName: string;
  userFirebaseId: string;
};

export const useCaseInfoHeaderState = ({
  updateLegalCaseHandler,
  approveRepresentationsHandler,
  declineRepresentationsHandler,
  caseId,
  consultationData,
  caseNumber,
  isClosedCase,
  representationId,
  userFirebaseId,
  userFirstName,
  userLastName,
}: UseCaseInfoHeaderProps) => {
  const { createConsultation } = legalCaseConsultationApiService();

  const isUserFullname = userFirstName && userLastName;

  const { navigate, pathname } = useRouter();
  const { isClient, isCanSendRepresentationRequest } = useUser();

  const onClickChatButtonHandler = async () =>
    navigate(ROUTES.legalCaseChat, {
      state: {
        caseNumber,
        userFirebaseId,
        prevRoute: pathname,
      },
    });

  const [isRejecting, setIsRejecting] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isApprovingRepresentation, setIsApprovingRepresentation] = useState(false);
  const [isSendingOffer, setIsSendingOffer] = useState(false);

  const isBackToQueueButton = consultationData?.isCaseOnConsultation && isUserFullname && isClient;

  const isBackToQueueButtonDisabled = isClosedCase || isApprovingRepresentation;
  const backToQueueButtonTooltipTitle = 'Back case to queue';

  const isSendConsultationOfferButtonDisabled = !!consultationData?.consultationCreatedTime || isSendingOffer;
  const formattedData = consultationData?.consultationCreatedTime
    ? dateToCustomFormat(consultationData?.consultationCreatedTime, 'MM.dd.yyyy hh:mm a')
    : '';

  const sendConsultationButtonTooltipTitle = consultationData?.consultationCreatedTime
    ? `Request was sent at ${formattedData}`
    : '';

  // TODO conference hack
  const { openBuySubscriptionWarningModal, openBuySubscriptionModal } = useSubscriptionModalsContextState();

  const onSendOfferConsultationButtonClickHandler = async () => {
    try {
      setIsSendingOffer(true);
      closeSendConsultationOfferDialogHandler();
      await createConsultation(`/legal_cases/${caseId}`);

      await consultationData?.getConsultationsHandler?.(caseId, { page: 1, itemsPerPage: 5 });

      setIsSendingOffer(false);
      notice(ToastType.SUCCESS, 'Successfully sent offer!');

      if (!isCanSendRepresentationRequest) openBuySubscriptionWarningModal();
    } catch (error) {
      closeSendConsultationOfferDialogHandler();
      setIsSendingOffer(false);
      paymentRequiredErrorHandler(error);
      console.error(error);
    }
  };

  const onDeclineOfferConsultationButtonClickHandler = async () => {
    try {
      setIsApproving(true);
      closeBackToQueueDialogHandler();
      await consultationData?.declineAcceptedConsultationHandler();
      setIsApproving(false);
    } catch (error) {
      setIsApproving(false);
      paymentRequiredErrorHandler(error);
      console.error(error);
    }
  };

  const onAcceptRepresentationOfferHandler = async () => {
    try {
      setIsApprovingRepresentation(true);
      await approveRepresentationsHandler?.(representationId);
      updateLegalCaseHandler({ onConsultation: false });

      setIsApprovingRepresentation(false);
    } catch (error) {
      setIsApprovingRepresentation(false);
      paymentRequiredErrorHandler(error);
      console.error(error);
    }
  };

  const onDeclineRepresentationOfferHandler = async () => {
    try {
      setIsRejecting(true);
      await declineRepresentationsHandler?.(representationId);

      setIsRejecting(false);
    } catch (error) {
      setIsRejecting(false);
      paymentRequiredErrorHandler(error);
      console.error(error);
    }
  };

  const { setStartCallProps } = useAudioVideoChat();

  const onAudioVideoButtonClickHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const eventType = event.currentTarget.id;

    setStartCallProps({
      caseId,
      eventType: eventType as AgoraEventType,
      endParticipantFirebaseId: userFirebaseId,
    });
    navigate(eventType === 'voice_call' ? ROUTES.audioRoom : ROUTES.videoRoom, {
      state: {
        prevRoute: pathname,
      },
    });
  };

  const [isBackToQueueDialogOpen, openBackToQueueDialogHandler, closeBackToQueueDialogHandler] = useBoolean(false);
  const [
    isSendConsultationOfferDialogOpen,
    openSendConsultationOfferDialogHandler,
    closeSendConsultationOfferDialogHandler,
  ] = useBoolean(false);

  const [isExportCaseToClioMenuOpen, openExportCaseToClioMenuHandler, closeExportCaseToClioMenuHandler] =
    useBoolean(false);
  const [isChooseCRMWindowOpen, openChooseCRMWindowHandler, closeChooseCRMWindowHandler] = useBoolean(false);

  //TODO conference hack
  const onClickChooseCRMButtonHandler = () => {
    if (!isCanSendRepresentationRequest) return openBuySubscriptionModal();
    openChooseCRMWindowHandler();
  };

  const onClickOnCRMOptionHandler = (event: React.MouseEvent<HTMLLIElement>) => {
    closeChooseCRMWindowHandler();
    openExportCaseToClioMenuHandler();
  };

  return {
    isClient,
    isApprovingRepresentation,
    isSendingOffer,
    isUserFullname,
    isBackToQueueButton,
    isBackToQueueButtonDisabled,
    isRejecting,
    isApproving,
    openBackToQueueDialogHandler,
    isSendConsultationOfferButtonDisabled,
    isSendConsultationOfferDialogOpen,
    backToQueueButtonTooltipTitle,
    sendConsultationButtonTooltipTitle,
    openSendConsultationOfferDialogHandler,
    closeSendConsultationOfferDialogHandler,
    isBackToQueueDialogOpen,
    closeBackToQueueDialogHandler,
    onAudioVideoButtonClickHandler,

    onClickChatButtonHandler,
    onAcceptRepresentationOfferHandler,
    onDeclineOfferConsultationButtonClickHandler,
    onDeclineRepresentationOfferHandler,
    onSendOfferConsultationButtonClickHandler,

    isExportCaseToClioMenuOpen,
    openExportCaseToClioMenuHandler,
    closeExportCaseToClioMenuHandler,
    isChooseCRMWindowOpen,
    openChooseCRMWindowHandler,
    closeChooseCRMWindowHandler,
    onClickOnCRMOptionHandler,
    onClickChooseCRMButtonHandler,
  };
};
