import { FC } from 'react';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import MDButton from 'components/MDButton';
import { SIGN_SERVICE_NOT_INTEGRATED_MESSAGE } from 'features/case-details/components/Documents/DocumentItem';

type NotConnectedFileSignProvidersWindowProps = {
  isOpen: boolean;
  onCloseDialogHandler: () => void;
};

export const NotConnectedFileSignProvidersWindow: FC<NotConnectedFileSignProvidersWindowProps> = ({
  isOpen,
  onCloseDialogHandler,
}) => {
  return (
    <Dialog open={isOpen} onClose={onCloseDialogHandler}>
      <DialogTitle sx={{ textAlign: 'center' }}>{SIGN_SERVICE_NOT_INTEGRATED_MESSAGE}</DialogTitle>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <MDButton variant="outlined" color="dark" onClick={onCloseDialogHandler}>
          Ok
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};
