import { useEffect, useState } from 'react';
import { usePagination } from 'features/common/hooks/usePagination';
import { ToastType, notice } from 'features/common';
import { CaseLawTypeResponse, Pagination, createCaseLawTypesService } from '../services';
import { abortRequestErrorHandler } from 'features/common/errorHanders';

export const useCaseLawTypes = () => {
  const { getCaseLawTypes, createCaseLawType, patchCaseLawTypeById, deleteCaseLawTypeById } =
    createCaseLawTypesService();

  const [caseLawTypesList, setCaseLawTypesList] = useState<ReadonlyArray<CaseLawTypeResponse>>([]);
  const [caseLawTypesTotal, setCaseLawTypesTotal] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const { currentPage, entriesPerPage, setCurrentPage, onChangeEntriesPerPageHandler, onDeleteItem } = usePagination({
    itemsLength: caseLawTypesList?.length,
    totalItems: caseLawTypesTotal,
  });

  const getItemsHandler = async (params?: Pagination) => {
    try {
      const { data } = await getCaseLawTypes({
        page: currentPage,
        itemsPerPage: entriesPerPage,
        ...params,
      });
      setCaseLawTypesList(data['hydra:member']);
      setCaseLawTypesTotal(Number(data['hydra:totalItems']));
    } catch (error) {
      abortRequestErrorHandler(error);
    }
  };

  const loadCaseLawTypesHandler = async (page: number = currentPage) => {
    setIsLoading(true);
    await getItemsHandler({ page });
    setIsLoading(false);
  };

  useEffect(() => {
    loadCaseLawTypesHandler();
  }, [entriesPerPage]);

  const onChangePageHandler = async (page: number) => {
    setCurrentPage(page);
    await loadCaseLawTypesHandler(page);
  };

  return {
    caseLawTypesTotal,
    caseLawTypesList,
    isLoading,
    currentPage,
    entriesPerPage,
    setIsLoading,
    onChangePageHandler,
    onChangeEntriesPerPageHandler,
    onDeleteItem,
    loadCaseLawTypesHandler,
    patchCaseLawTypeById,
    deleteCaseLawTypeById,
    createCaseLawType,
    getItemsHandler,
  };
};
