/* TODO conference hack */
import { createContext, useContext, useMemo } from 'react';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { Dialog, Stack } from '@mui/material';
import { useBoolean, useRouter, useUser } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';
import MDButton from 'components/MDButton';
import { CreateCaseModalContent } from 'features/my-clients/components';
import MDTypography from 'components/MDTypography';

type SubscriptionModalsContextValue = {
  openBuySubscriptionModal: () => void;
  openBuySubscriptionWarningModal: () => void;
};

const SubscriptionModalsContext = createContext<SubscriptionModalsContextValue>({
  openBuySubscriptionModal: () => {},
  openBuySubscriptionWarningModal: () => {},
});

export const SubscriptionModalsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { isCanSendRepresentationRequest } = useUser();
  const router = useRouter();

  const [isBuySubscriptionModalOpen, openBuySubscriptionModal, closeBuySubscriptionModal] = useBoolean(false);

  const [isBuySubscriptionModalWarningOpen, openBuySubscriptionWarningModal, closeBuySubscriptionWarningModal] =
    useBoolean(false);

  const contextValue = useMemo(
    () => ({
      openBuySubscriptionModal,
      openBuySubscriptionWarningModal,
    }),
    []
  );

  return (
    <SubscriptionModalsContext.Provider value={contextValue}>
      <>
        {children}

        <Dialog open={isBuySubscriptionModalOpen} onClose={closeBuySubscriptionModal}>
          <CreateCaseModalContent
            contentTitle="This action requires active subscription. Please subscribe to proceed."
            title={
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <MDTypography variant="h5">Restricted action</MDTypography>

                <ErrorOutlineOutlined fontSize="large" color="error" />
              </Stack>
            }
          >
            <MDButton
              variant="gradient"
              color="error"
              onClick={() => {
                closeBuySubscriptionModal();
                router.navigate(
                  router.pathname === ROUTES.myCompanyUsers ? ROUTES.myCompanySubscriptions : ROUTES.subscription
                );
              }}
            >
              Subscribe
            </MDButton>
            <MDButton variant="gradient" color="dark" onClick={closeBuySubscriptionModal}>
              Cancel
            </MDButton>
          </CreateCaseModalContent>
        </Dialog>

        <Dialog open={isBuySubscriptionModalWarningOpen} onClose={closeBuySubscriptionWarningModal}>
          <CreateCaseModalContent
            contentTitle="You already represent on free case and you won’t be able to offer a representation during the consultation. To get unlimited number of cases representation, please subscribe."
            title={
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <MDTypography variant="h5">Warning</MDTypography>

                <ErrorOutlineOutlined fontSize="large" color="error" />
              </Stack>
            }
          >
            <MDButton
              variant="gradient"
              color="error"
              onClick={() => {
                closeBuySubscriptionWarningModal();
                router.navigate(ROUTES.subscription);
              }}
            >
              Subscribe
            </MDButton>
            <MDButton variant="gradient" color="dark" onClick={closeBuySubscriptionWarningModal}>
              Continue
            </MDButton>
          </CreateCaseModalContent>
        </Dialog>
      </>
    </SubscriptionModalsContext.Provider>
  );
};

export const useSubscriptionModalsContextState = (): SubscriptionModalsContextValue => {
  const context = useContext(SubscriptionModalsContext);

  if (context === undefined) {
    throw new Error('useSubscriptionModalsContextState was used outside of its Provider');
  }

  return context;
};
