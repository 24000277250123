import { FC } from 'react';
import { Dialog, DialogContent, DialogTitle, Divider, Stack } from '@mui/material';
import ClioLogo from 'assets/images/icons/logo-clio.svg';
import { DialogWindowProps } from 'features/common/components/DialogWindow';
import MDAvatar from 'components/MDAvatar';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

type ChooseCRMToExportModalWindowProps = Pick<DialogWindowProps, 'isDialogOpen' | 'onCloseDialogHandler'> & {
  onOptionClickHandler: (event: React.MouseEvent<HTMLLIElement>) => void;
};

export const ChooseCRMToExportModalWindow: FC<ChooseCRMToExportModalWindowProps> = ({
  isDialogOpen,
  onCloseDialogHandler,
  onOptionClickHandler,
}) => {
  return (
    <Dialog PaperProps={{ sx: { width: '50%' } }} open={isDialogOpen} onClose={onCloseDialogHandler}>
      <DialogTitle sx={{ textAlign: 'center' }}>Export to CRM</DialogTitle>
      <Divider sx={{ my: 0.5 }} />
      <DialogContent>
        <Stack component="ul" divider={<Divider />}>
          <Stack
            component="li"
            onClick={onOptionClickHandler}
            id="clio"
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              p: 1,
              borderRadius: '6px',
              ':hover': {
                cursor: 'pointer',
                backgroundColor: ({ palette }) => palette.grey[200],
              },
            }}
          >
            <MDAvatar src={ClioLogo} variant="rounded" alt="Clio logo" sx={{ width: 24, height: 24 }} />
            <MDTypography>Clio</MDTypography>
          </Stack>
        </Stack>
        <Stack alignItems="center" mt={2}>
          <MDButton variant="gradient" color="dark" onClick={onCloseDialogHandler}>
            Cancel
          </MDButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
