import { FC } from 'react';
import { Stack } from '@mui/material';
import MDTypography from 'components/MDTypography';

type InformationItemProps = {
  iconElement: React.ReactNode;
  title: string;
  value: string;
};

export const InformationItem: FC<InformationItemProps> = ({ iconElement, title, value }) => {
  return (
    <Stack direction="row">
      <Stack direction="row" alignItems="flex-start" width={1} spacing={1} justifyContent="space-between">
        <Stack alignItems="center" spacing={0.5} direction="row">
          {iconElement}
          <MDTypography variant="body2">{title}</MDTypography>
        </Stack>
        <MDTypography variant="body2" sx={{ textAlign: 'end', fontWeight: 500, whiteSpace: 'initial' }}>
          {value}
        </MDTypography>
      </Stack>
    </Stack>
  );
};
