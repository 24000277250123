import { FC, memo, useCallback, useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, CircularProgress, Stack } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { SideMenu, useBoolean } from 'features/common';
import { CardAddButton } from '../CardAddButton';
import { DelayedNotificationCard } from './DelayedNotificationCard';
import { CreateDelayedNotificationMenuContent } from './CreateDelayedNotificationMenuContent';
import { DelayedNotificationProps } from './types';
import { DialogWindow } from 'features/common/components/DialogWindow';

export const DelayedNotification: FC<DelayedNotificationProps> = memo(
  ({
    caseId,
    delayedNotifications,
    fetchLegalCaseDelayedNotificationsHandler,
    createLegaCaseDelayedNotificationHandler,
    getSelectedDelayedNotification,
    selectedDelayedNotification,
    deleteLegalCaseDelayedNotificationHandler,
    updateLegalCaseDelayedNotificationHandler,
  }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isCreateNotificationMenuOpen, onOpenCreateNotificationMenuHandler, onCloseCreateNotificationMenuHandler] =
      useBoolean(false);
    const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useBoolean(false);

    const [isCreateMode, setIsCreateMode] = useState(false);

    const onEditIconClickHandler = useCallback(
      (id: string) => {
        isCreateMode && setIsCreateMode(false);
        getSelectedDelayedNotification(id);
        onOpenCreateNotificationMenuHandler();
      },
      [isCreateMode]
    );

    const createNoteIconClickHandler = useCallback(() => {
      setIsCreateMode(true);
      onOpenCreateNotificationMenuHandler();
    }, []);

    const onFetchLegalCaseDelayedNotificationsHandler = async () => {
      await fetchLegalCaseDelayedNotificationsHandler(caseId, { itemsPerPage: 1, page: 1 });
      setIsLoading(false);
    };

    useEffect(() => {
      onFetchLegalCaseDelayedNotificationsHandler();
    }, [caseId]);

    const onDeleteNotificationIconClickHandler = useCallback((id: string) => {
      getSelectedDelayedNotification(id);
      openDeleteModal();
    }, []);

    const onDeleteNoteHandler = async () => {
      setIsLoading(true);
      closeDeleteModal();
      await deleteLegalCaseDelayedNotificationHandler();
      setIsLoading(false);
    };

    return (
      <Card sx={{ width: 1, height: 1 }}>
        <CardHeader
          title="Delayed notifications"
          action={
            !delayedNotifications?.length ? <CardAddButton onClickButtonHandler={createNoteIconClickHandler} /> : null
          }
        />

        {isLoading ? (
          <Stack alignItems="center" flex={1} justifyContent="center" minHeight={150}>
            <CircularProgress />
          </Stack>
        ) : !delayedNotifications?.length ? (
          <Stack flex={1} justifyContent="center" alignItems="center" minHeight={150}>
            <MDTypography sx={{ textAlign: 'center' }}>No delayed notification was found...</MDTypography>
          </Stack>
        ) : (
          <CardContent sx={{ px: 2, pb: '18px !important', flex: 1 }}>
            <Stack
              spacing={2}
              sx={{
                pb: 1,
                pr: 0.5,
                maxHeight: 400,
                overflowY: 'auto',
              }}
            >
              {delayedNotifications?.map(notification => (
                <DelayedNotificationCard
                  key={notification?.id}
                  id={notification?.id}
                  message={notification?.message}
                  nextSendAt={notification?.nextSendAt}
                  regularity={notification?.type}
                  startedAt={notification?.startedAt}
                  onDeleteIconClickHandler={onDeleteNotificationIconClickHandler}
                  onEditIconClickHandler={onEditIconClickHandler}
                />
              ))}
            </Stack>
          </CardContent>
        )}

        <DialogWindow
          onCloseDialogHandler={closeDeleteModal}
          onApproveDialogHandler={onDeleteNoteHandler}
          isDialogOpen={isDeleteModalOpen}
          dialogTitle="Are you sure want to delete the delayed notification?"
        />

        <SideMenu
          isOpen={isCreateNotificationMenuOpen}
          onClose={onCloseCreateNotificationMenuHandler}
          title={`${isCreateMode ? 'Create' : 'Edit'} a delayed notification`}
          customWidth={450}
        >
          <CreateDelayedNotificationMenuContent
            createLegaCaseDelayedNotificationHandler={createLegaCaseDelayedNotificationHandler}
            onCloseMenuHandler={onCloseCreateNotificationMenuHandler}
            updateLegalCaseDelayedNotificationHandler={updateLegalCaseDelayedNotificationHandler}
            message={selectedDelayedNotification?.message}
            regularity={selectedDelayedNotification?.type}
            startedAt={selectedDelayedNotification?.startedAt}
            isCreateMode={isCreateMode}
          />
        </SideMenu>
      </Card>
    );
  }
);
