import { FC, useState } from 'react';
import { useInterval } from 'features/common/hooks/useInterval';
import MDTypography from 'components/MDTypography';
import { formatIntervalTime } from 'features/common/helpers/utilities';
import { TimerProps } from '../types';

export const Timer: FC<TimerProps> = ({ isRunning, timerRef }) => {
  const [timeElapsed, setTimeElapsed] = useState(0);

  useInterval(() => {
    setTimeElapsed(timeElapsed => {
      const newValue = timeElapsed + 1;
      timerRef.current = newValue;
      return newValue;
    }),
      isRunning ? 1000 : null;
  });

  const formattedCallTimeValue = formatIntervalTime(timeElapsed);

  return (
    <MDTypography variant="button" color="white">
      {formattedCallTimeValue || ''}
    </MDTypography>
  );
};
