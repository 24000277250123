import { useCallback, useEffect, useState } from 'react';
import { PauseCircle } from '@mui/icons-material';
import { Card, CircularProgress } from '@mui/material';
import { SideMenu } from 'features/common/components/SideMenu';
import MDButton from 'components/MDButton';
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import { CompanyResponse } from '../services';
import { useCompanyList } from '../hooks';
import { CompanyListTable } from '../components/CompanyListTable';
import { CompanyInfoCard, TemplatesWrapper } from '../components';
import { ToastType, notice, useBoolean } from '../../common';

export const CompanyListTemplate = (): JSX.Element => {
  const [selectedCompanyId, setSelectedCompanyId] = useState('');

  const [isDeactivating, setIsDeactivating] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<CompanyResponse>(null);
  const [isCompanyInfoModalOpen, openCompanyInfoModal, closeCompanyInfoModal] = useBoolean(false);

  const {
    getCompanyById,
    patchCompanyByIdActive,
    companiesList,
    companiesTotal,
    isLoading,
    entriesPerPage,
    currentPage,
    onChangeEntriesPerPageHandler,
    onDeleteItem,
    getItemsHandler,
    onChangePageHandler,
  } = useCompanyList(true);

  const connectSelectedCompany = async () => {
    setSelectedCompany(null);
    try {
      if (selectedCompanyId) {
        const company = await getCompanyById(selectedCompanyId);
        setSelectedCompany(company.data);
      }
    } catch (error) {
      notice(ToastType.ERROR, 'Something went wrong with getting additional company info.');
    }
  };

  const onDeactivate = async () => {
    try {
      if (selectedCompanyId) {
        const nextPage = onDeleteItem();
        setIsDeactivating(true);
        await patchCompanyByIdActive(selectedCompanyId, false);
        await getItemsHandler({ page: nextPage });
        setIsDeactivating(false);
        closeCompanyInfoModal();
        notice(ToastType.SUCCESS, 'Company is successfully deactivated!');
      }
    } catch (error) {
      setIsDeactivating(false);
      notice(ToastType.ERROR, 'Something went wrong with getting additional company info.');
    }
  };

  useEffect(() => {
    connectSelectedCompany();
  }, [selectedCompanyId]);

  const onMoreCompanyInfo = useCallback((id: string) => {
    setSelectedCompanyId(id);
    openCompanyInfoModal();
  }, []);

  return (
    <TemplatesWrapper>
      <Card>
        <MDBox p={3} lineHeight={1} pb={0}>
          <MDTypography variant="h5" fontWeight="medium">
            Company list
          </MDTypography>
          <MDTypography variant="button" color="text">
            Here you can work with current companies by yourself.
          </MDTypography>
        </MDBox>
        <CompanyListTable
          companiesList={companiesList}
          totalItems={companiesTotal}
          onChangePage={onChangePageHandler}
          onChangeEntriesPerPage={onChangeEntriesPerPageHandler}
          isLoading={isLoading}
          entriesPerPage={entriesPerPage}
          currentPage={currentPage}
          onMoreCompanyInfo={onMoreCompanyInfo}
        />
      </Card>

      <SideMenu
        isOpen={isCompanyInfoModalOpen}
        onClose={closeCompanyInfoModal}
        title="Company info"
        titleDescription="More info about company is here."
        customWidth={500}
      >
        <MDBox py={4} display="flex" flexDirection="column" height={1} justifyContent="center">
          {selectedCompany === null ? (
            <CircularProgress sx={{ mx: 'auto', mb: '16px' }} />
          ) : (
            <CompanyInfoCard
              info={{
                fullName: selectedCompany?.fullName,
                shortName: selectedCompany?.shortName,
                owner: `${selectedCompany?.owner?.userProfile?.firstName} ${selectedCompany?.owner?.userProfile?.lastName}`,
                phone: selectedCompany?.phone,
                email: selectedCompany?.email,
                site: selectedCompany?.site,
                description: selectedCompany?.description,
                country: selectedCompany?.headquarterAddress?.country?.name,
                state: selectedCompany?.headquarterAddress?.state?.name,
                town: selectedCompany?.headquarterAddress.town,
                zip: selectedCompany?.headquarterAddress.zipCode,
              }}
            />
          )}
          {selectedCompany && (
            <MDButton
              variant="outlined"
              color="error"
              startIcon={isDeactivating ? null : <PauseCircle />}
              sx={{ marginTop: 4 }}
              onClick={onDeactivate}
              isLoading={isDeactivating}
              disabled={isDeactivating}
            >
              Deactivate
            </MDButton>
          )}
        </MDBox>
      </SideMenu>
    </TemplatesWrapper>
  );
};
