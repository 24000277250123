import {
  CaseInformationSchema,
  ClientSchema,
  DependantInformationScheme,
  DocumentsScheme,
  NotesScheme,
} from 'features/my-legal-cases/components/CreateLegalCase/form.config';
import { createStore } from '../createStore';
import { CreateClientInvitationPayload } from 'features/my-legal-cases/MyLegalCasesApiService';
import { CRMCase, CRMFile, CRMNote } from 'features/common';

export enum CREATE_CASE_FORM_STEPS {
  CLIENT_INFO = 'Client Information',
  CASE_INFO = 'Case Information',
  DEPENDANT_INFO = 'Dependant Information',
  DOCUMENTS = 'Documents',
  NOTES = 'Notes',
  CASE_REVIEW = 'Case Review',
}

export const STEPS = [
  CREATE_CASE_FORM_STEPS.CLIENT_INFO,
  CREATE_CASE_FORM_STEPS.CASE_INFO,
  CREATE_CASE_FORM_STEPS.DEPENDANT_INFO,
  CREATE_CASE_FORM_STEPS.DOCUMENTS,
  CREATE_CASE_FORM_STEPS.NOTES,
];

const stepVariant: Record<CREATE_CASE_FORM_STEPS, string> = {
  [CREATE_CASE_FORM_STEPS.CLIENT_INFO]: 'clientData',
  [CREATE_CASE_FORM_STEPS.CASE_INFO]: 'caseData',
  [CREATE_CASE_FORM_STEPS.DEPENDANT_INFO]: 'dependantData',
  [CREATE_CASE_FORM_STEPS.DOCUMENTS]: 'documentsData',
  [CREATE_CASE_FORM_STEPS.NOTES]: 'notesData',
  [CREATE_CASE_FORM_STEPS.CASE_REVIEW]: 'caseReview',
};

export type CaseData =
  | (CaseInformationSchema & {
      caseCountyTitle: string;
      caseStateTitle: string;
      caseLawTypeTitle: string;
      caseNumber?: string;
    })
  | null;

export type DependantData =
  | (DependantInformationScheme & {
      dependantCaseStateTitle: string;
    })
  | null;

export type ClientData =
  | (ClientSchema & {
      clientId?: string;
      clientLogoURL?: string;
      clientCaseCount?: number;
      clientRating?: number;
    })
  | null;

type SetDataType =
  | {
      step: CREATE_CASE_FORM_STEPS;
      data: ClientData;
      activeStep: CREATE_CASE_FORM_STEPS;
    }
  | { step: CREATE_CASE_FORM_STEPS; data: CaseData; activeStep: CREATE_CASE_FORM_STEPS }
  | { step: CREATE_CASE_FORM_STEPS; data: DependantData; activeStep: CREATE_CASE_FORM_STEPS }
  | { step: CREATE_CASE_FORM_STEPS; data: DocumentsScheme; activeStep: CREATE_CASE_FORM_STEPS }
  | { step: CREATE_CASE_FORM_STEPS; data: NotesScheme; activeStep: CREATE_CASE_FORM_STEPS };

export type ClientInvitationData = Pick<CreateClientInvitationPayload, 'country' | 'state'>;

export type CreateCaseState = {
  activeStep: CREATE_CASE_FORM_STEPS;
  clientData: ClientData;
  caseData: CaseData;
  dependantData: DependantData;
  documentsData: DocumentsScheme | null;
  notesData: NotesScheme | null;
  clientInvitationData: ClientInvitationData;
  CRMCaseDocuments: CRMFile[] | null;
  CRMCasenotes: CRMNote[] | null;
  CRMCaseData: CRMCase | null;
};

export type CreateCaseActions = {
  setData: (data: SetDataType) => void;
  toStep: (step: CREATE_CASE_FORM_STEPS) => void;
  resetState: () => void;
  setClientInvitationData: (data: ClientInvitationData) => void;
  setIsImportFromCRM: (isImportFromCRM: boolean) => void;
  setStateData: <K extends keyof CreateCaseState>(key: K, value: CreateCaseState[K]) => void;
};

export const clientData: ClientData = {
  firstName: '',
  lastName: '',
  state: '',
  country: '',
  email: '',
  clientId: '',
  clientLogoURL: '',
  clientCaseCount: null,
  clientRating: null,
};

export const caseData: CaseData = {
  description: '',
  caseCounty: '',
  caseLawType: '',
  caseState: '',
  caseCountyTitle: '',
  caseLawTypeTitle: '',
  caseStateTitle: '',
  caseNumber: '',
};

export const dependantData: DependantData = {
  dependantCaseState: '',
  dependantFirstName: '',
  dependantLastName: '',
  isCaseForDependant: false,
  dependantCaseStateTitle: '',
  isUnder18: false,
};

const initialState: CreateCaseState = {
  activeStep: CREATE_CASE_FORM_STEPS.CLIENT_INFO,
  CRMCaseData: null,
  clientData,
  caseData,
  dependantData,
  CRMCaseDocuments: [],
  CRMCasenotes: [],
  documentsData: {
    isDocuments: false,
    documents: [],
  },
  notesData: {
    isAddNotes: false,
    notes: [{ noteBody: '', noteTitle: '', isVisible: false }],
  },
  clientInvitationData: {
    country: '',
    state: '',
  },
};

export const useCreateCase = createStore<CreateCaseState & CreateCaseActions>(
  set => ({
    ...initialState,
    setStateData: (key, value) => set(state => ({ ...state, [key]: value })),
    setIsImportFromCRM: isImportFromCRM => set(state => ({ ...state, isImportFromCRM })),
    setData: ({ step, data, activeStep }) =>
      set(state => ({
        ...state,
        [stepVariant[step]]: data,
        activeStep,
      })),
    resetState: () => {
      set(initialState);
    },
    toStep: step => {
      set({ activeStep: step });
    },
    setClientInvitationData: clientInvitationData => {
      set(state => ({
        ...state,
        clientInvitationData,
      }));
    },
  }),
  'createCase',
  initialState
);
