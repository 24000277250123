import { Card } from '@mui/material';
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import { SuggestionsTable } from '../components/SuggestionsTable';
import { TemplateWrapper } from './TemplateWrapper';

export const CaseLawTypeSuggestionsTemplate = (): JSX.Element => {
  return (
    <TemplateWrapper>
      <Card>
        <MDBox p={3} lineHeight={1} pb={0}>
          <MDTypography variant="h5" fontWeight="medium">
            Case Law Type Suggestions
          </MDTypography>
          <MDTypography variant="button" color="text">
            Here you can work with current case law type suggestions by yourself.
          </MDTypography>
        </MDBox>
        <SuggestionsTable />
      </Card>
    </TemplateWrapper>
  );
};
