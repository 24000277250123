import { FC } from 'react';
import MDTypography from 'components/MDTypography';
import { MessageWrapper } from './Message.styles';
import { MessageProps } from './types';
import { MessageTimestamp } from './MessageTimestamp';

export const Message: FC<MessageProps> = ({ isMine, createdAt, messageText, firstMessageRef }) => {
  return (
    <MessageWrapper isMine={isMine} ref={firstMessageRef}>
      <MDTypography
        variant="body2"
        sx={{
          textAlign: 'start',
          wordBreak: 'break-word',
          fontSize: 14,
          fontWeight: 400,
          color: isMine ? 'white !important' : 'inherit',
          width: 1,
        }}
      >
        {messageText || ''}
      </MDTypography>

      <MessageTimestamp createdAt={createdAt} isMine={isMine} />
    </MessageWrapper>
  );
};
