import * as yup from 'yup';

export const defaultValues = {
  title: '',
};

export type SuggestLawTypeValidationSchema = {
  title: string;
};

export const createLawTypeSuggestionFormSchema: yup.ObjectSchema<SuggestLawTypeValidationSchema> = yup
  .object({
    title: yup.string().required('This field is required').trim(),
  })
  .required();
