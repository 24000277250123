import { useCallback, useEffect, useMemo, useState } from 'react';

export const useAudio = (url: string) => {
  const audio = useMemo(() => new Audio(url), [url]);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = useCallback(() => setIsPlaying(true), []);
  const toggleStop = useCallback(() => setIsPlaying(false), []);

  const handleEnded = useCallback(() => {
    audio.currentTime = 0;
    audio?.play();
  }, []);

  useEffect(() => {
    if (isPlaying) {
      audio?.play().catch(error => {
        console.error('Error playing audio:', error);
      });
    } else {
      audio.currentTime = 0;
      audio?.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    audio.addEventListener('ended', handleEnded);
    return () => {
      audio.removeEventListener('ended', handleEnded);
    };
  }, []);

  return { isPlaying, togglePlay, toggleStop };
};
