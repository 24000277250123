import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { RecaptchaVerifier } from '@firebase/auth';
import { firebaseAuth } from '../firebase';

export const CAPTCHA_CONTAINER_ID = 'captcha-container';

const CaptchaContext = createContext<{
  firebaseAppVerifier: RecaptchaVerifier;
}>(null);

export const CaptchaContextProvider = ({ children }: PropsWithChildren) => {
  const [appVerifier, setAppVerifier] = useState(null);

  useEffect(() => {
    const firebaseAppVerifier = new RecaptchaVerifier(
      CAPTCHA_CONTAINER_ID,
      {
        size: 'invisible',
      },
      firebaseAuth
    );
    setAppVerifier(firebaseAppVerifier);

    return () => {
      firebaseAppVerifier.clear();
      setAppVerifier(null);
    };
  }, []);

  return (
    <CaptchaContext.Provider value={{ firebaseAppVerifier: appVerifier }}>
      {children}
      <div id={CAPTCHA_CONTAINER_ID}></div>
    </CaptchaContext.Provider>
  );
};

export const useCaptchaVerifier = () => useContext(CaptchaContext);
