import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { createBackendAuthService } from 'features/auth';
import { ToastType, notice, useRouter, useUser } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';

export const DocusignCallbackController = (): null => {
  const { connectBackendUserHandler } = useUser();
  const [searchParams, _] = useSearchParams();
  const router = useRouter();
  const { connectDocusignToProfile } = createBackendAuthService();

  const connectDocusign = async () => {
    const code = searchParams.get('code');

    if (!code) {
      router.back();
      return;
    }

    try {
      await connectDocusignToProfile(code);
      notice(ToastType.SUCCESS, 'Successfully connected DocuSign to your account');
    } catch (error) {
      console.error({ error });
      notice(ToastType.ERROR, 'Something went wrong with DocuSign connection');
    }
    router.push(ROUTES.integrations).then(() => {
      connectBackendUserHandler();
    });
  };

  useEffect(() => {
    connectDocusign();
  }, []);

  return null;
};
