import { FC, useEffect } from 'react';
import { CircularProgress, Grid, Stack } from '@mui/material';
import { DashboardLayout } from 'features/common';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { fetchParams } from './MyLegalCasesApiService';
import { LegalCaseCard } from './components/LegalCaseCard';
import { useMyLegalCasesState } from './hooks/useMyLegalCasesState';
import { Toolbar } from './components/Toolbar';

export const MyLegalCasesTemplate: FC = () => {
  const {
    currentPage,
    fetchMoreLegalCasesHandler,
    fetchMyLegalCasesHandler,
    handleSetTabValue,
    setCurrentPage,
    onAddIconClikcHandler,
    pages,
    isFetchMoreLoading,
    isLoading,
    isOnScreen,
    myLegalCases,
    tabValue,
    lastElementRef,
  } = useMyLegalCasesState();

  useEffect(() => {
    fetchMyLegalCasesHandler(fetchParams[tabValue]);
  }, [tabValue]);

  useEffect(() => {
    if (isOnScreen && currentPage < pages) {
      const nextPage = currentPage + 1;
      fetchMoreLegalCasesHandler({ ...fetchParams[tabValue], page: nextPage });
      setCurrentPage(nextPage);
    }
  }, [isOnScreen]);

  return (
    <DashboardLayout>
      <Grid container>
        <Grid item xs={12} mb={3}>
          <Toolbar
            tabValue={tabValue}
            onAddIconClickHandler={onAddIconClikcHandler}
            handleSetTabValue={handleSetTabValue}
          />
        </Grid>
      </Grid>
      {isLoading && (
        <Stack flex={1} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      {!isLoading && !myLegalCases.length && (
        <Stack flex={1} justifyContent="center" alignItems="center">
          <MDTypography>No cases were found...</MDTypography>
        </Stack>
      )}
      {!isLoading && (
        <>
          <Grid container spacing={2} position="relative" height={1}>
            {myLegalCases.map((legalCase, index) => (
              <Grid item key={legalCase.id} xs={12} md={6} lg={4}>
                <LegalCaseCard
                  caseId={legalCase.id}
                  lastElementRef={index === myLegalCases.length - 1 ? lastElementRef : null}
                  companyShortName={legalCase?.attorney?.company?.shortName}
                  caseNumber={legalCase.caseNumber}
                  description={legalCase.description}
                  caseForDependant={legalCase.caseForDependant}
                  dependantFirstName={legalCase.dependantFirstName}
                  dependantLastName={legalCase.dependantLastName}
                  createdAt={legalCase.createdAt}
                  stateName={legalCase.state.name}
                  countryId={legalCase.country.id}
                  firstName={legalCase?.attorney?.userProfile?.firstName}
                  lastName={legalCase?.attorney?.userProfile?.lastName}
                  statusTitle={legalCase.status.title}
                  statusBgColor={legalCase.status.lightThemeBgColor}
                  countyName={legalCase.county?.shortName}
                  attorneyLogo={legalCase?.attorney?.logo?.publicUrl}
                  statusType={legalCase.status.type}
                  attorneyFirebaseid={legalCase?.attorney?.firebaseUser}
                  legalCaseActions={legalCase?.legalCaseActionDTO}
                />
              </Grid>
            ))}
          </Grid>
          {isFetchMoreLoading && (
            <MDBox sx={{ position: 'absolute', bottom: 25, left: '50%', transform: 'translateY(-50%)' }}>
              <CircularProgress />
            </MDBox>
          )}
        </>
      )}
    </DashboardLayout>
  );
};
