import { FC } from 'react';
import { Stack } from '@mui/material';
import { DeleteOutline, ModeEdit } from '@mui/icons-material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { dateToCustomFormat } from 'features/common/helpers/date';
import { usePopover } from 'features/common/hooks/usePopover';
import { MenuPopover } from 'features/common/components/MenuPopover';
import { PopoverMenuItem } from 'features/common/components/PopoverMenuItem';
import { DelayedNotificationType } from 'features/case-details/DelayedNotificationsApiService';
import { ThreeDotsMenu } from '../ThreeDotsMenu';
import { DelayedNotificationCardProps } from './types';

const RegularityHashMap: Record<DelayedNotificationType, string> = {
  every_day: 'Every day',
  every_month: 'Every month',
  every_two_week: 'Every two week',
  every_week: 'Every week',
};

export const DelayedNotificationCard: FC<DelayedNotificationCardProps> = ({
  id,
  onDeleteIconClickHandler,
  onEditIconClickHandler,
  message,
  nextSendAt,
  regularity,
  startedAt,
}) => {
  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  const onEditMenuItemClickHandler = () => {
    handleClosePopover();
    onEditIconClickHandler(id);
  };

  const onDeleteMenuItemClickHandler = () => {
    handleClosePopover();
    onDeleteIconClickHandler(id);
  };

  const regularityValue = RegularityHashMap[regularity];
  const formattedCreatedAtDate = dateToCustomFormat(startedAt);
  const formattedNextSendAtDate = dateToCustomFormat(nextSendAt);

  return (
    <MDBox
      borderRadius="xl"
      p={1.5}
      bgColor="white"
      sx={{
        position: 'relative',
        fontSize: ({ typography: { size } }) => size.md,
        backgroundColor: ({ palette }) => palette.background.delayedNotification,
      }}
    >
      <ThreeDotsMenu sxProps={{ top: '7px' }} isDisabled={false} onClickHandler={handleOpenPopover} />
      <Stack spacing={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width={1} mb={1}>
          <MDTypography variant="button">{regularityValue}</MDTypography>
          <MDTypography mr={3.5} variant="button">
            {formattedCreatedAtDate}
          </MDTypography>
        </Stack>
        <MDTypography
          variant="button"
          sx={{
            fontWeight: 600,
            whiteSpace: 'initial',
            wordBreak: 'break-all',
          }}
        >
          {message}
        </MDTypography>

        <Stack direction="row" alignItems="center" width={1} spacing={2}>
          <MDTypography variant="button">Next message </MDTypography>
          <MDTypography variant="button">{formattedNextSendAtDate}</MDTypography>
        </Stack>
      </Stack>

      <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="top-right">
        <PopoverMenuItem
          onClickHandler={onEditMenuItemClickHandler}
          title="Edit"
          icon={<ModeEdit fontSize="medium" />}
        />
        <PopoverMenuItem
          onClickHandler={onDeleteMenuItemClickHandler}
          title="Delete"
          icon={<DeleteOutline fontSize="medium" />}
        />
      </MenuPopover>
    </MDBox>
  );
};
