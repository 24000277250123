import { ListOfItems, backendApiClient } from '../../common';

export type CreateQuestionPayload = {
  question: string;
  answer: string;
  publicVisibility: boolean;
};

export type PatchQuestionPayload = Partial<CreateQuestionPayload>;
export type GetQuestionsRequest = {
  page?: number;
  itemsPerPage?: number;
};
export type CreateQuestionResponse = {
  '@id': string;
  '@type': string;
  '@context': string;
  question: string;
  answer: string;
  publicVisibility: boolean;
  sorting: number;
  createdAt: string;
  updatedAt: string;
};

export type GetQuestionStaticResponse = {
  '@id': string;
  '@type': string;
  question: string;
  answer: string;
};

export type QuestionResponse = {
  '@id': string;
  '@type': string;
  question: string;
  publicVisibility: boolean;
  sorting: number;
  createdAt: string;
  updatedAt: string;
};

export const createFAQService = () => {
  const getQuestions = async (params: GetQuestionsRequest) => {
    return await backendApiClient({}).get<ListOfItems<QuestionResponse[]>>('/frequently_asked_questions', { params });
  };

  const createQuestion = async (createContentItemPayload: CreateQuestionPayload) => {
    return await backendApiClient({}).post<CreateQuestionResponse, CreateQuestionPayload>(
      '/frequently_asked_questions',
      createContentItemPayload
    );
  };

  const getQuestionStaticById = async (id: string) => {
    return await backendApiClient({}).get<GetQuestionStaticResponse>(`/frequently_asked_questions/static/${id}`);
  };

  const getQuestionById = async (id: string) => {
    return await backendApiClient({}).get<CreateQuestionResponse>(`/frequently_asked_questions/${id}`);
  };

  const patchQuestionById = async (id: string, patchPagePayload: PatchQuestionPayload) => {
    return await backendApiClient({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch<CreateQuestionResponse>(`/frequently_asked_questions/${id}`, patchPagePayload);
  };

  const deleteQuestionById = async (id: string) => {
    return await backendApiClient({}).delete(`/frequently_asked_questions/${id}`);
  };

  return {
    getQuestions,
    createQuestion,
    getQuestionStaticById,
    getQuestionById,
    patchQuestionById,
    deleteQuestionById,
  };
};
