import { useNavigate, useLocation, useParams } from 'react-router-dom';

type URLParams = {
  staticPageId?: string;
  contentCategoryName?: string;
  contentItemId?: string;
  caseId?: string;
  chatId?: string;
  accessCode?: string;
  sessionId?: string;
  id?: string;
};

export const useRouter = () => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const params = useParams<URLParams>();

  return {
    push: (to: string) => new Promise(resolve => resolve(navigate(to))),
    pathname,
    back: () => navigate(-1),
    params,
    state,
    navigate,
  };
};
