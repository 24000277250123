import { useCallback, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { ToastType, notice, useBoolean } from 'features/common';
import { useSettings } from 'features/store';
import { usePagination } from 'features/common/hooks/usePagination';
import { CriticalUpdateVersion, urgentVersionsApiService } from '../UrgentVersionsApiService';

export const useUrgentVersions = () => {
  const { getCriticalUpdateVersions, getLastCriticalUpdateVersions, deleteCriticalUpdateVersion } =
    urgentVersionsApiService();

  const [isCreateMenuOpen, openCreateMenu, closeCreateMenu] = useBoolean(false);
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBoolean(false);

  const {
    setLastUrgentVersions,
    lastUrgentVersions,
    urgentVersionsData: { totalItems, urgentVersions },
    setUrgentVersions,
  } = useSettings(
    state => ({
      setLastUrgentVersions: state.setLastUrgentVersions,
      setUrgentVersions: state.setUrgentVersions,
      lastUrgentVersions: state.lastUrgentVersions,
      urgentVersionsData: state.urgentVersionsData,
    }),
    shallow
  );

  const setDataToStoreHandler = ({
    lastUrgentVersions,
    totalItems,
    urgentVersions,
  }: {
    lastUrgentVersions: CriticalUpdateVersion[];
    urgentVersions: CriticalUpdateVersion[];
    totalItems: number;
  }) => {
    setLastUrgentVersions(lastUrgentVersions);
    setUrgentVersions(urgentVersions, totalItems);
  };

  const { onDeleteItem, currentPage, entriesPerPage, setCurrentPage, onChangeEntriesPerPageHandler } = usePagination({
    totalItems,
    itemsLength: urgentVersions.length,
  });

  const [isLoadingLastUrgentVersions, setIsLoadingLastUrgentVersions] = useState(true);

  const [isLoadingUrgentVersions, setIsLoadingUrgentVersions] = useState(true);

  const onGetLastCriticalUpdateVersionsHandler = async () => {
    try {
      !isLoadingLastUrgentVersions && setIsLoadingLastUrgentVersions(true);
      const { data } = await getLastCriticalUpdateVersions();

      setLastUrgentVersions(data['hydra:member']);
      setIsLoadingLastUrgentVersions(false);
    } catch (error) {
      setIsLoadingLastUrgentVersions(false);
      notice(ToastType.ERROR, 'Failed to fetch last critical update versions, please try again!');
      console.error(error);
    }
  };

  const onGetCriticalUpdateVersionsHandler = async (page: number = 1) => {
    try {
      !isLoadingUrgentVersions && setIsLoadingUrgentVersions(true);
      const { data } = await getCriticalUpdateVersions({ page, itemsPerPage: entriesPerPage });

      setUrgentVersions(data['hydra:member'], data['hydra:totalItems']);

      setIsLoadingUrgentVersions(false);
    } catch (error) {
      setIsLoadingUrgentVersions(false);
      notice(ToastType.ERROR, 'Failed to fetch critical update versions, please try again!');
      console.error(error);
    }
  };

  const onChangePageHandler = useCallback(
    async (page: number) => {
      setCurrentPage(page);
      await onGetCriticalUpdateVersionsHandler(page);
    },
    [entriesPerPage]
  );

  const [selectedItemId, setSelectedItemId] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  const onClickDeleteIconHandler = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedItemId(event.currentTarget.id);
    openDeleteDialog();
  }, []);

  const onApproveDeleteUrgentVersionHandler = async () => {
    try {
      setIsDeleting(true);
      const nextPage = onDeleteItem() || currentPage;
      await deleteCriticalUpdateVersion(selectedItemId);
      setIsDeleting(false);
      closeDeleteDialog();
      await Promise.all([onGetCriticalUpdateVersionsHandler(nextPage), onGetLastCriticalUpdateVersionsHandler()]);
      notice(ToastType.SUCCESS, 'Successfully deleted!');
    } catch (error) {
      setIsDeleting(false);
      notice(ToastType.ERROR, 'Failed to delete critical version, please try again!');
      console.error(error);
    }
  };

  return {
    onGetLastCriticalUpdateVersionsHandler,
    onGetCriticalUpdateVersionsHandler,
    isLoadingLastUrgentVersions,
    lastUrgentVersions,
    isLoadingUrgentVersions,
    onChangePageHandler,
    currentPage,
    entriesPerPage,
    onChangeEntriesPerPageHandler,
    totalItems,
    urgentVersions,
    isCreateMenuOpen,
    openCreateMenu,
    closeCreateMenu,
    setDataToStoreHandler,
    onClickDeleteIconHandler,
    onApproveDeleteUrgentVersionHandler,
    isDeleteDialogOpen,
    closeDeleteDialog,
    isDeleting,
  };
};
