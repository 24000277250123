/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { FC, ReactNode, forwardRef } from 'react';

// @mui material components
import { TypographyProps } from '@mui/material';

// Custom styles for MDTypography
import {
  TypoColor,
  TypoTextTransform,
  StyledTypography,
  TypoFontWeight,
  TypoVerticalAlign,
} from 'components/MDTypography/MDTypographyRoot';

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController } from 'context';

// Declaring props types for MDTypography
interface Props extends TypographyProps {
  color?: TypoColor;
  fontWeight?: TypoFontWeight;
  textTransform?: TypoTextTransform;
  verticalAlign?: TypoVerticalAlign;
  textGradient?: boolean;
  children?: ReactNode | string;
  opacity?: number;
  component?: React.ElementType;
  [key: string]: any;
}

const MDTypography: FC<Props> = forwardRef(
  ({ color, fontWeight, textTransform, verticalAlign, textGradient, opacity, children, ...rest }, ref) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
      <StyledTypography
        {...rest}
        ref={ref}
        ownerState={{
          color,
          textTransform,
          verticalAlign,
          fontWeight,
          opacity,
          textGradient,
          darkMode,
        }}
      >
        {children}
      </StyledTypography>
    );
  }
);

// Declaring default props for MDTypography
MDTypography.defaultProps = {
  color: 'dark',
  fontWeight: undefined,
  textTransform: 'none',
  verticalAlign: 'unset',
  textGradient: false,
  opacity: 1,
};

export default MDTypography;
