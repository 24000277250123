import { useState } from 'react';
import { Theme } from '@mui/material';
import { useRouter, notice, ToastType, useUser, useApiSecurity } from 'features/common';
import { GetFileByIdResponse } from 'features/common/services/filesService';
import { ROUTES } from 'features/common/variables/routes';
import { CREATE_CASE_FORM_STEPS, useCreateCase } from 'features/store';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';
import { myLegalCasesApiService, CreateNotesPayload, CreateMyLegalCasePayload } from '../MyLegalCasesApiService';
import { Note } from '../components/CreateLegalCase/form.config';

export const useReviewState = (isAttorneyView?: boolean) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    resetStateHandler,
    clientInvitationData,
    caseInformationData,
    clientData,
    toStepHandler,
    documentsData,
    notesData,
    dependantData,
    CRMCaseDocuments,
    CRMCasenotes,
    CRMCaseData,
  } = useCreateCase(state => ({
    dependantData: state.dependantData,
    notesData: state.notesData,
    caseInformationData: state.caseData,
    documentsData: state.documentsData,
    activeStep: state.activeStep,
    clientData: state.clientData,
    clientInvitationData: state.clientInvitationData,
    CRMCaseDocuments: state.CRMCaseDocuments,
    CRMCasenotes: state.CRMCasenotes,
    CRMCaseData: state.CRMCaseData,
    saveFormDataHandler: state.setData,
    resetStateHandler: state.resetState,
    toStepHandler: state.toStep,
  }));

  const { aesEncrypt } = useApiSecurity();

  const { createMyLegalCase, createLegalCaseNotes, putLegalCaseFile, postCRMFilesToLegalCase, createClientInvitation } =
    myLegalCasesApiService();
  const route = useRouter();
  const { backendUser } = useUser();

  const attachDocumentsToCaseHandler = async (caseId: string, filesData: GetFileByIdResponse[]) => {
    try {
      const attachDocumentsPromises = filesData.map(file => {
        return putLegalCaseFile(caseId, { file: file['@id'], sendNotifications: false });
      });

      await Promise.all(attachDocumentsPromises);
    } catch (error) {
      console.error(error);
      notice(ToastType.ERROR, 'Failed to attach documents to case, try again!');
    }
  };

  const creteNotesHandler = async (caseId: string, notes: Note[]) => {
    try {
      const createNotePromises = notes?.map(note => {
        const notePayload: CreateNotesPayload = {
          legalCase: caseId,
          title: note.noteTitle,
          description: aesEncrypt(note.noteBody),
          visible: note.isVisible,
          sendNotifications: false,
        };

        return createLegalCaseNotes(notePayload);
      });

      await Promise.all(createNotePromises);
    } catch (error) {
      console.error(error);
      notice(ToastType.ERROR, 'Failed to create notes, try again!');
    }
  };

  const createClientInvitationHandler = async () => {
    try {
      const { data } = await createClientInvitation({
        country: clientInvitationData?.country,
        state: clientInvitationData?.state,
        email: clientData.email,
        firstName: clientData.firstName,
        lastName: clientData.lastName,
      });
      return data?.['@id'];
    } catch (error) {
      console.error(error);
      notice(ToastType.ERROR, 'Failed to create invitation, try again!');
    }
  };

  const onCreateCaseHandler = async () => {
    try {
      setIsLoading(true);

      const inviteId = !clientData.clientId && isAttorneyView ? await createClientInvitationHandler() : null;

      const createLegalCasePayload: CreateMyLegalCasePayload = {
        country: clientData.country,
        state: caseInformationData.caseState,
        county: caseInformationData.caseCounty,
        caseLawType: caseInformationData.caseLawType,
        description: caseInformationData.description,
        ...(dependantData.isCaseForDependant && {
          caseForDependant: dependantData.isCaseForDependant,
          dependantFirstName: dependantData.dependantFirstName,
          dependantLastName: dependantData.dependantLastName,
          dependantStateOfResidence: dependantData.dependantCaseState,
          dependantUnder18: dependantData.isUnder18,
        }),
        ...(isAttorneyView &&
          clientData.clientId && {
            client: clientData.clientId,
          }),
        ...(inviteId && {
          invitation: inviteId,
        }),
        ...(caseInformationData.caseNumber && {
          caseNumber: caseInformationData.caseNumber,
        }),

        ...(CRMCaseData?.id && {
          importId: CRMCaseData?.id,
          importProvider: CRMCaseData?.provider,
        }),
      };

      const { data: caseData } = await createMyLegalCase(createLegalCasePayload);

      if (documentsData.isDocuments) {
        if (documentsData?.documents?.length) {
          const deviceFiles = documentsData?.documents?.filter(el => el['@type'] === 'File') as GetFileByIdResponse[];

          deviceFiles.length && attachDocumentsToCaseHandler(caseData?.id, deviceFiles);
        }
      }

      if (notesData.isAddNotes) {
        await creteNotesHandler(caseData['@id'], notesData.notes);
      }

      notice(ToastType.SUCCESS, 'Legal case successfully created!');
      resetStateHandler();
      setIsLoading(false);
      route.push(ROUTES.myLegalCases);
    } catch (error) {
      paymentRequiredErrorHandler(error, 'Failed to create legal case, try again!');
      setIsLoading(false);
      console.error(error);
    }
  };

  const onGoBackHandler = () =>
    toStepHandler(CRMCaseData?.id ? CREATE_CASE_FORM_STEPS.DEPENDANT_INFO : CREATE_CASE_FORM_STEPS.NOTES);

  const caseStatus = {
    title: CRMCaseData?.status ? CRMCaseData?.status?.title : 'Open',
    bgColor: CRMCaseData?.status
      ? CRMCaseData?.status?.lightThemeBgColor
      : (theme: Theme) => theme.palette.success.main,
  };

  return {
    onGoBackHandler,
    onCreateCaseHandler,
    CRMCaseDocuments,
    CRMCasenotes,
    clientData,
    backendUser,
    caseInformationData,
    isLoading,
    documentsData,
    dependantData,
    notesData,
    caseStatus,
    CRMCaseData,
  };
};
