import { Stack } from '@mui/material';
import MDTypography from 'components/MDTypography';
import { FC } from 'react';

type ContentItemProps = {
  title: string;
  value: string;
};
export const ContentItem: FC<ContentItemProps> = ({ title, value }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
      <MDTypography variant="button" sx={{ fontWeight: 500 }} fontSize={16}>
        {title}
      </MDTypography>
      <MDTypography variant="button" fontSize={16}>
        {value}
      </MDTypography>
    </Stack>
  );
};
