import { FC } from 'react';
import { isToday } from 'date-fns';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { dateToCustomFormat } from 'features/common/helpers/date';

type DateLabelProps = {
  date: Date | string;
};

export const DateLabel: FC<DateLabelProps> = ({ date }) => {
  const formattedDate = dateToCustomFormat(date, 'LLLL d, yyyy');

  const isTodayDate = isToday(new Date(date));

  const dateText = isTodayDate ? 'Today' : formattedDate;

  return (
    <MDBox
      sx={{
        display: 'flex',
        width: 1,
        justifyContent: 'center',
        position: 'sticky',
        top: '-5px',
        zIndex: theme => theme.zIndex.appBar,
        my: 1,
      }}
    >
      <MDTypography variant="subtitle2" sx={{ fontWeight: 500 }}>
        {dateText}
      </MDTypography>
    </MDBox>
  );
};
