import { FC } from 'react';
import { Stack } from '@mui/material';
import MDButton from 'components/MDButton';

type ButtonsFooterProps = {
  onClickBackButtonHandler?: () => void;
  isLoading?: boolean;
  saveButtonText?: string;
  isSubmitButtonDisabled: boolean;
};
export const ButtonsFooter: FC<ButtonsFooterProps> = ({
  onClickBackButtonHandler,
  isLoading,
  isSubmitButtonDisabled,
  saveButtonText = 'Next',
}) => {
  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mt={1} justifyContent="flex-end">
      {onClickBackButtonHandler && (
        <MDButton
          variant="gradient"
          color="light"
          onClick={onClickBackButtonHandler}
          sx={{
            width: { xs: 1, sm: 'auto' },
          }}
        >
          Back
        </MDButton>
      )}
      <MDButton
        type="submit"
        variant="gradient"
        color="dark"
        isLoading={isLoading}
        disabled={isSubmitButtonDisabled}
        sx={{
          width: { xs: 1, sm: 'auto' },
        }}
      >
        {saveButtonText}
      </MDButton>
    </Stack>
  );
};
