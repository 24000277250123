import { FC, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Stack } from '@mui/material';
import MDButton from 'components/MDButton';
import {
  AttorneyProfileCaseLawType,
  BaseFormField,
  CountryState,
  ToastType,
  createAttorneyService,
  notice,
} from 'features/common';
import { sortArray } from 'features/common/helpers/utilities';
import { Loader } from 'features/common/components/Loader';

type StatesMenuContentProps = {
  selectedAttroneyLawTypeId: string;
  states: CountryState[];
  matchedStates: CountryState[];
  onCloseMenuHandler: () => void;
  setAttoreyProfileCaseLawTypes: React.Dispatch<React.SetStateAction<AttorneyProfileCaseLawType[]>>;
  onOpenDeleteCaseLawTypeDialogHandler?: () => void;
  isDeleting?: boolean;
};

export const StatesMenuContent: FC<StatesMenuContentProps> = ({
  selectedAttroneyLawTypeId,
  onCloseMenuHandler,
  states,
  matchedStates,
  setAttoreyProfileCaseLawTypes,
  isDeleting,
  onOpenDeleteCaseLawTypeDialogHandler,
}) => {
  const { getAttorneyProfileCaseLawTypes, getAttorneyProfileCaseLawTypesById, updateAttorneyProfileCaseLawTypesById } =
    createAttorneyService();

  const lawTypeId = selectedAttroneyLawTypeId?.replace('/attorney_profile_case_law_types/', '');
  const [selectedStates, setSelectedStates] = useState<CountryState[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const onGetAttorneyProfileCaseLawTypesId = async () => {
    try {
      const { data } = await getAttorneyProfileCaseLawTypesById(lawTypeId);
      setSelectedStates(data?.states);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, try again!');
    }
  };

  const isDefaultArraySameAsCurrent = useMemo(() => {
    if (matchedStates?.length !== selectedStates?.length) return false;

    const sortedCurrent = sortArray(selectedStates);
    const sortedDefault = sortArray(matchedStates);

    for (let i = 0; i < sortedCurrent.length; i++) {
      if (sortedCurrent[i]?.['@id'] !== sortedDefault[i]?.['@id']) {
        return false;
      }
    }

    return true;
  }, [matchedStates, selectedStates]);

  const onChangeAutocompleteHandler = (_: React.SyntheticEvent<Element, Event>, value: CountryState[]) => {
    setSelectedStates(value);
  };

  const onSaveButtonClickHandler = async () => {
    try {
      setIsSaving(true);
      const statesIds = selectedStates?.map(el => el['@id']);
      await updateAttorneyProfileCaseLawTypesById(lawTypeId, { states: statesIds });
      const { data } = await getAttorneyProfileCaseLawTypes({ page: 1, itemsPerPage: 50 });
      setAttoreyProfileCaseLawTypes(data['hydra:member']);
      onCloseMenuHandler();
      notice(ToastType.SUCCESS, 'Case law type has been successfully updated!');
      setIsSaving(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, try again!');
    }
  };

  useEffect(() => {
    onGetAttorneyProfileCaseLawTypesId();
  }, []);

  const isSaveButtonDisabled = isSaving || !selectedStates.length || isDefaultArraySameAsCurrent;

  return (
    <Stack height={1} spacing={1.5} mt={2} position="relative">
      <Stack height={1}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Stack flex={1}>
              <Autocomplete
                value={selectedStates}
                getOptionLabel={option => option?.name}
                options={states}
                isOptionEqualToValue={(option, value) => option?.['@id'] === value?.['@id']}
                onChange={onChangeAutocompleteHandler}
                multiple
                sx={{ width: 1 }}
                freeSolo={false}
                disableClearable
                renderInput={params => (
                  <BaseFormField
                    formInputProps={{
                      ...params,
                      label: 'States',
                      fullWidth: true,
                    }}
                  />
                )}
              />
            </Stack>

            <Stack spacing={1} pb={2}>
              <MDButton
                variant="gradient"
                color="info"
                disabled={isSaveButtonDisabled}
                onClick={onSaveButtonClickHandler}
                isLoading={isSaving}
              >
                Save
              </MDButton>

              {onOpenDeleteCaseLawTypeDialogHandler && (
                <MDButton
                  variant="gradient"
                  color="error"
                  onClick={onOpenDeleteCaseLawTypeDialogHandler}
                  isLoading={isDeleting}
                >
                  Delete
                </MDButton>
              )}
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};
