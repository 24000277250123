import { BaseData, ListOfItems, backendApiClient } from 'features/common';

const BASE_ENUM_API_URL: Readonly<string> = '/clio/mapping/enums';

export type ClioEnum = BaseData<'KeyValueDto'> & {
  value: string;
  id: string;
};

export const clioEnumApiService = () => {
  const getClioMappingPracticeAreaCategoriesEnum = async () =>
    await backendApiClient({}).get<ListOfItems<ClioEnum[]>>(`${BASE_ENUM_API_URL}/practice_area_category_type`);

  const getClioMappingLegalCaseAddressPositionEnum = async () =>
    await backendApiClient({}).get<ListOfItems<ClioEnum[]>>(`${BASE_ENUM_API_URL}/legal_case_address_position`);

  const getClioMappingLegalCaseNumberPositionEnum = async () =>
    await backendApiClient({}).get<ListOfItems<ClioEnum[]>>(`${BASE_ENUM_API_URL}/legal_case_number_position`);

  const getClioMappingClientEmailPositionEnum = async () =>
    await backendApiClient({}).get<ListOfItems<ClioEnum[]>>(`${BASE_ENUM_API_URL}/client_email_position`);

  const getClioMappingClientPhonePositionEnum = async () =>
    await backendApiClient({}).get<ListOfItems<ClioEnum[]>>(`${BASE_ENUM_API_URL}/client_phone_position`);

  const getClioMappingClientAddressPositionEnum = async () =>
    await backendApiClient({}).get<ListOfItems<ClioEnum[]>>(`${BASE_ENUM_API_URL}/client_address_position`);

  return {
    getClioMappingPracticeAreaCategoriesEnum,
    getClioMappingLegalCaseAddressPositionEnum,
    getClioMappingClientAddressPositionEnum,
    getClioMappingClientPhonePositionEnum,
    getClioMappingClientEmailPositionEnum,
    getClioMappingLegalCaseNumberPositionEnum,
  };
};
