import { useCallback, useRef, useState } from 'react';
import FullCalendar, { DateSelectArg, EventClickArg, EventDropArg, MoreLinkArg } from '@fullcalendar/react';
import { addDays, endOfDay, isPast, startOfDay } from 'date-fns';
import { useBoolean, useRouter, useUser } from 'features/common';
import { dateToCustomFormat } from 'features/common/helpers/date';
import { AgoraEvent, AgoraEventType, GetAgoraEventsParams } from 'features/common/services/agoraEventApiService';
import { ROUTES } from 'features/common/variables/routes';
import { useAudioVideoChat, useCaseDetails, useMyEvents } from 'features/store';
import { ButtonName } from '../types';
import { getIsEventTime } from '../helpers';

export const useEventsState = () => {
  const caseId = useRouter()?.params?.caseId;

  const myLegalCase = useCaseDetails(state => state.case?.myLegalCase);

  const isCaseEvents = myLegalCase?.attorney && !!caseId;

  const { isAttorney, backendUser } = useUser();

  const [isAddEventModalOpen, openAddEventModal, closeAddEventModal] = useBoolean(false);
  const [isAllEventsModalOpen, openAllEventsModal, closeAllEventsModal] = useBoolean(false);
  const [isConfirmDeleteEventModalOpen, openConfirmDeleteEventModal, closeConfirmDeleteEventModal] = useBoolean(false);

  const [calendarState, setCalendatState] = useState<null | DateSelectArg>(null);
  const [formattedDate, setFormattedDate] = useState<null | string>(null);
  const [selectedDayRange, setSelectedDayRange] = useState<{ end: string; start: string }>({ end: '', start: '' });

  const {
    createEventHandler,
    setCurrentEvent,
    currentEvent,
    events,
    getEventByIdHandler,
    getEventsHandler,
    eventsByDateData,
    getEventsByDateHandler,
    deleteEventHandler,
    editEventHandler: patchEventHandler,
    getMoreEventsByDateHandler,
  } = useMyEvents();

  const [isLoading, setIsLoading] = useState(false);
  const [isEventDataLoading, setIsEventDataLoading] = useState(false);
  const [isEventsByDateLoading, setIsEventsByDateLoading] = useState(false);
  const [eventId, setEventId] = useState('');
  const [currentDate, setCurrentDate] = useState<Date | null>(null);

  //edit/delete/fetch handlers
  const onEditIconClickHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsEventDataLoading(true);
    openAddEventModal();
    await getEventByIdHandler(event.currentTarget.id);
    setIsEventDataLoading(false);
  };

  const onDeleteEventIconClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setEventId(event.currentTarget.id);
    openConfirmDeleteEventModal();
  };

  const onDeleteEventButtonClickHandler = async () => {
    closeConfirmDeleteEventModal();
    setIsEventsByDateLoading(true);
    await deleteEventHandler(eventId);
    setIsEventsByDateLoading(false);
  };

  const onCloseModalContentHandler = () => {
    closeAddEventModal();
    currentEvent && setCurrentEvent(null);
    currentDate && setCurrentDate(null);
  };

  const onFetchEventsByDateHandler = async (start: string, end: string) => {
    setIsEventsByDateLoading(true);
    openAllEventsModal();
    const queryParameters: GetAgoraEventsParams = {
      'startedAt[strictly_after]': start,
      'startedAt[strictly_before]': end,
    };
    await getEventsByDateHandler({ legalCase: caseId ? myLegalCase?.['@id'] : null, ...queryParameters });

    setIsEventsByDateLoading(false);
  };

  const userFullName = isAttorney
    ? `${myLegalCase?.client?.userProfile?.firstName} ${myLegalCase?.client?.userProfile?.lastName}`
    : `${myLegalCase?.attorney?.userProfile?.firstName} ${myLegalCase?.attorney?.userProfile?.lastName}`;

  const userFirebaseId = isAttorney ? myLegalCase?.client?.firebaseUser : myLegalCase?.attorney?.firebaseUser;

  const onFetchEventsHandler = async () => {
    const { activeEnd, activeStart } = getActiveViewDates();
    setIsLoading(true);
    await getEventsHandler({
      legalCase: caseId ? `/legal_cases/${caseId}` : null,
      'startedAt[after]': activeStart,
      'startedAt[before]': activeEnd,
      itemsPerPage: 500,
      page: 1,
    });
    setIsLoading(false);
  };

  //calendar API helpers
  const getActiveViewDates = () => {
    const viewApi = calendarRef?.current?.getApi()?.view;
    const activeStart = viewApi ? viewApi.activeStart.toISOString() : '';
    const activeEnd = viewApi ? viewApi.activeEnd.toISOString() : '';
    return {
      activeEnd,
      activeStart,
    };
  };

  const calendarRef = useRef<null | FullCalendar>(null);

  const handleDatesSet = async () => {
    const { activeStart, activeEnd } = getActiveViewDates();
    await getEventsHandler({
      legalCase: caseId ? myLegalCase?.['@id'] : null,
      'startedAt[strictly_after]': activeStart,
      'startedAt[strictly_before]': activeEnd,
    });
  };

  //calendar handlers
  const onEventDropHandler = async (event: EventDropArg) => {
    const deltaData = event.delta;
    const oldEvent = event.oldEvent;

    const newStartDate = addDays(oldEvent.start, deltaData.days);
    setIsLoading(true);

    await patchEventHandler(event.event._def.publicId, { startedAt: newStartDate });
    setIsLoading(false);
  };

  const handleCustomButtonClick = async (buttonName: ButtonName) => {
    const calendarApi = calendarRef?.current?.getApi();

    if (buttonName === 'customtoday') {
      new Promise(res => {
        res(calendarApi?.today());
      }).then(async () => {
        setIsLoading(true);
        await handleDatesSet();
        setIsLoading(false);
      });
    }

    if (buttonName === 'customprev') {
      new Promise(res => {
        res(calendarApi?.prev());
      }).then(async res => {
        setIsLoading(true);
        await handleDatesSet();
        setIsLoading(false);
      });
    }
    if (buttonName === 'customnext') {
      new Promise(res => {
        res(calendarApi?.next());
      }).then(async () => {
        setIsLoading(true);
        await handleDatesSet();
        setIsLoading(false);
      });
    }
  };

  const onEventClickHandler = async ({ event }: EventClickArg) => {
    const eventId = event._def.publicId;
    const extendedProps = event._def.extendedProps as AgoraEvent;
    const eventOwner = extendedProps?.participants?.find(el => el.role === 'role_agora_publisher');

    if (eventOwner?.user?.firebaseUser !== backendUser?.firebaseUser) return;
    const { isEventTime } = getIsEventTime(extendedProps?.startedAt, extendedProps?.expiredAt);

    if (eventId && !isEventTime) {
      setIsEventDataLoading(true);
      openAddEventModal();
      await getEventByIdHandler(event._def.publicId);
      setIsEventDataLoading(false);
    }
  };

  const onClickMoreEventsButtonHandler = (event: MoreLinkArg) => {
    event.jsEvent.preventDefault();
    const date = dateToCustomFormat(event.date, 'EEEE, MMM d');

    setFormattedDate(date);

    const startOfSelectedDay = startOfDay(event.date).toISOString();
    const endOfSelectedDay = endOfDay(event.date).toISOString();

    setSelectedDayRange({ start: startOfSelectedDay, end: endOfSelectedDay });
    openAllEventsModal();
    onFetchEventsByDateHandler(startOfSelectedDay, endOfSelectedDay);
  };

  const onCellClickHandler = (selectInfo: DateSelectArg) => {
    openAddEventModal();
    setCalendatState(selectInfo);
  };

  const onCreateEventButtonClickHandler = useCallback(() => {
    setCurrentDate(startOfDay(new Date()));
    openAddEventModal();
  }, []);

  const { navigate, pathname } = useRouter();
  const { setAudioVideoEventId, setIsScheduledCall, setStartCallProps } = useAudioVideoChat(state => ({
    setAudioVideoEventId: state.setEventId,
    setIsScheduledCall: state.setIsScheduledCall,
    setStartCallProps: state.setStartCallProps,
  }));

  const onClickConnectToCallButtonHandler = (eventId: string, eventType: AgoraEventType) => {
    setAudioVideoEventId(eventId);
    setIsScheduledCall(true);

    setStartCallProps({ caseId: '', endParticipantFirebaseId: '', eventType });
    navigate(eventType === 'voice_call' ? ROUTES.audioRoom : ROUTES.videoRoom, {
      state: {
        prevRoute: pathname,
      },
    });
  };

  return {
    onCreateEventButtonClickHandler,
    onFetchEventsHandler,
    handleCustomButtonClick,
    onEventClickHandler,
    onEventDropHandler,
    onCellClickHandler,
    onCloseModalContentHandler,
    onClickMoreEventsButtonHandler,
    closeAddEventModal,
    closeAllEventsModal,
    closeConfirmDeleteEventModal,
    getMoreEventsByDateHandler,
    onDeleteEventButtonClickHandler,
    onDeleteEventIconClickHandler,
    patchEventHandler,
    createEventHandler,
    onEditIconClickHandler,
    onClickConnectToCallButtonHandler,
    userFirebaseId,
    userFullName,
    calendarRef,
    caseId,
    isAddEventModalOpen,
    isAllEventsModalOpen,
    isConfirmDeleteEventModalOpen,
    isLoading,
    isEventDataLoading,
    isEventsByDateLoading,
    calendarState,
    formattedDate,
    selectedDayRange,
    eventsByDateData,
    events,
    currentEvent,
    myLegalCase,
    isAttorney,
    backendUser,
    isCaseEvents,
    currentDate,
  };
};
