import { FC, useEffect, useState } from 'react';
import { match } from 'ts-pattern';
import { FormControlLabel, Radio } from '@mui/material';
import { AuthFlowBackgrounds } from 'features/auth/variables/illustartion';
import { IllustrationLayout, notice, ToastType, useAsync, createAttorneyService } from 'features/common';
import { CaseLawType, createLawTypesService } from 'features/auth';
import { AUTH_FLOW_STEPS, useAuth } from 'features/store';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { Loader } from 'features/common/components/Loader';
import { Illustrationtitle } from 'features/common/components/IllustatrionLogo';

export const SelectLawTypesStep: FC = () => {
  const { attorneyProfileId, toStep } = useAuth(state => ({
    attorneyProfileId: state.attorneyProfileAuthData?.attorneyProfileId,
    toStep: state.toStep,
  }));

  const { getLawTypes } = createLawTypesService();
  const { updateAttorneyProfileLawTypes, getAttorneyProfileById } = createAttorneyService();
  const [selectedLawTypes, setSelectedLawTypes] = useState<CaseLawType[]>([]);

  const onGetAttorneyProfileLawTypes = async () => {
    const response = await getAttorneyProfileById(attorneyProfileId.replace('/attorney_profiles/', ''));
    setSelectedLawTypes(response?.caseLawTypes || []);
  };

  const [isLoading, setIsLoading] = useState(false);

  const [getLawTypeListState] = useAsync(getLawTypes, {
    immediateCall: true,
    immediateInvocationArgs: [],
  });

  const onLawTypeItemClickHandler = (selectedLawTypeItem: CaseLawType) => {
    const existingLawType = selectedLawTypes.find(item => item['@id'] === selectedLawTypeItem['@id']);

    if (existingLawType) {
      return setSelectedLawTypes(lawTypes => lawTypes.filter(lawType => lawType['@id'] !== selectedLawTypeItem['@id']));
    }

    return setSelectedLawTypes(lawTypes => [...lawTypes, selectedLawTypeItem]);
  };

  const isActiveLawType = (selectedLawTypeItem: CaseLawType) =>
    selectedLawTypes.some(item => item['@id'] === selectedLawTypeItem['@id']);

  const [submitLawTypesUpdateState, submitLawTypesUpdateHandler] = useAsync(async () => {
    try {
      const selectedLawTypesIds = selectedLawTypes.map(lawType => lawType['@id']);

      setIsLoading(true);
      await updateAttorneyProfileLawTypes(attorneyProfileId, selectedLawTypesIds);

      toStep(AUTH_FLOW_STEPS.SELECT_LAW_TYPE_STATE);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  });

  useEffect(() => {
    onGetAttorneyProfileLawTypes();
  }, []);

  const onBackButtonClickHandler = () => toStep(AUTH_FLOW_STEPS.CREATE_ATTORNEY_PROFILE);

  return (
    <IllustrationLayout
      title="Select Your Areas of Law"
      description="Choose the law types that align with your expertise to filter cases you're most suited for. This helps us match you with clients seeking your specific legal services."
      onClickBack={onBackButtonClickHandler}
      illustration={AuthFlowBackgrounds.AUTH_ONE}
      illustrationLogo={<Illustrationtitle />}
    >
      {match(getLawTypeListState.status)
        .with('pending', () => (
          <MDBox sx={{ display: 'flex', minHeight: 200 }}>
            <Loader />
          </MDBox>
        ))
        .with('successful', () => (
          <MDBox display="grid" gridTemplateColumns="repeat(2, minmax(0, 1fr))" gap="8px" p={{ xs: 2, sm: 3 }}>
            {getLawTypeListState.isSuccessful() &&
              getLawTypeListState.data.data['hydra:member'].map(lawTypeItem => {
                return (
                  <FormControlLabel
                    checked={isActiveLawType(lawTypeItem)}
                    control={<Radio onClick={() => onLawTypeItemClickHandler(lawTypeItem)} />}
                    label={lawTypeItem.title}
                    key={lawTypeItem.title}
                    sx={{ whiteSpace: 'nowrap' }}
                  />
                );
              })}
            <MDBox sx={{ gridColumn: 'span 2 / span 2' }}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                sx={{ textTransform: 'none' }}
                onClick={submitLawTypesUpdateHandler}
                isLoading={isLoading}
                disabled={isLoading || !selectedLawTypes.length || submitLawTypesUpdateState.isPending()}
              >
                Next
              </MDButton>
            </MDBox>
          </MDBox>
        ))
        .otherwise(() => null)}
    </IllustrationLayout>
  );
};
